
const randomString = (len, charSet) => {
    charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var randomString = '';
    for (var i = 0; i < len; i++) {
        var randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz,randomPoz+1);
    }
    return randomString;
}

const DefaultCallbacks = {
    // onChatMessage: undefined,
    // onControlMessage: undefined,
    onMessage: undefined,
    onAnnounceMessage: undefined,
    onJoin: undefined,
    onLeave: undefined,

    onConnecting: undefined,
    onConnectSuccess: undefined,
    onAlreadyJoined: undefined,
    onConnectError: undefined,
    onConnectClosed: undefined,
}

const LogPrefix = '[RoomChat] ';

export class RoomChat {
    constructor(janus, roomId, role, userId, displayName, callbacks) {
        this.janus = janus;
        this.roomId = roomId;
        this.role = role;
        this.userId = userId;
        this.displayName = displayName;

        this.pluginHandle = undefined;
        this.transactions = {};
        this.participants = {};

        this.callbacks = Object.assign(DefaultCallbacks, callbacks);
    }

    attachAndJoin = () => {
        if(this.callbacks.onConnecting) {
            this.callbacks.onConnecting();
        }

        const that = this;

        console.log(LogPrefix, 'Attaching textroom plugin...');
        this.janus.attach({
            plugin: 'janus.plugin.textroom',
            opaqueId: this.userId,
            success: function(pluginHandle) {
                console.log(LogPrefix, 'Textroom plugin attached successfully', pluginHandle);
                that.pluginHandle = pluginHandle;

                const msg = {request: 'setup'};
                console.log(LogPrefix, 'Sending message :', msg);
                that.pluginHandle.send({message: msg});
            },
            error: function(error) {
                console.log(LogPrefix, 'Textroom plugin attach failed', error);

                if(that.callbacks.onConnectError) {
                    that.callbacks.onConnectError();
                }
            },
            webrtcState: function(on) {
                console.log(LogPrefix, 'Textroom plugin webrtcState', on);
            },
            onmessage: function(msg, jsep) {
                console.log(LogPrefix, 'Textroom plugin onmessage', msg, jsep);

                if((msg.error !== undefined) && (msg.error !== null)) {
                    console.log(LogPrefix, 'Textroom plugin onmessage error');
                }
                if((jsep !== undefined) && (jsep !== null)) {
                    that.pluginHandle.createAnswer({
                        jsep: jsep,
                        media: { audio: false, video: false, data: true },	// We only use datachannels
                        success: function(jsep) {
                            console.log(LogPrefix, 'Textroom plugin createAnswer success', jsep);

                            const msg = {request: 'ack'};
                            that.pluginHandle.send({message: msg, jsep: jsep});
                        },
                        error: function(error) {
                            console.log(LogPrefix, 'Textroom plugin createAnswer error', error);

                            if(this.callbacks.onConnectError) {
                                this.callbacks.onConnectError();
                            }
                        }
                    })
                }
            },
            ondataopen: function(data) {
                console.log(LogPrefix, 'Textroom plugin ondataopen', data);

                that._checkRoom();
            },
            ondata: function(data) {
                console.log(LogPrefix, 'Textroom plugin ondata', data);
                const json = JSON.parse(data);
                const transaction = json.transaction;

                // It's transaction
                if(that.transactions[transaction]) {
                    console.log(LogPrefix, `Plugin transaction ${transaction}`);

                    that.transactions[transaction](json);
                    delete that.transactions[transaction];
                    return;
                }

                // It's message
                const what = json.textroom;
                if(what === 'message') {
                    const msgObj = JSON.parse(json.text);
                    const callbackData = {
                        from: json.from,
                        date: json.date,
                        whisper: json.whisper,
                        type: msgObj.type,
                        msg: msgObj.msg,
                    };

                    if (that.callbacks.onMessage) {
                        that.callbacks.onMessage(callbackData);
                    }
                } else if(what === 'announcement') {
                    const msg = json.text;
                    console.log(LogPrefix, `Plugin announcement`, msg);

                    if(that.callbacks.onAnnounceMessage) {
                        that.callbacks.onAnnounceMessage(JSON.parse(msg));
                    }
                } else if(what === 'join') {
                    const username = json.username;
                    const display = json.display;

                    if(that.callbacks.onJoin) {
                        that.callbacks.onJoin(username, display, new Date());
                    }
                } else if(what === 'leave') {
                    const username = json.username;

                    if(that.callbacks.onLeave) {
                        that.callbacks.onLeave(username, new Date());
                    }
                } else if(what === 'kicked') {
                    const username = json.username;
                    if(that.callbacks.onKicked) {
                        that.callbacks.onKicked(username);
                    }
                } else if(what === 'destroyed') {
                    // const room = json.room;
                }
            },
            oncleanup: function() {
                console.log(LogPrefix, 'Textroom plugin oncleanup');

                if(that.callbacks.onConnectClosed) {
                    that.callbacks.onConnectClosed();
                }
            }
        });
    }

    kickAndJoin = () => {
        const request = {
            textroom: "kick",
            room: this.roomId,
            username: this.userId,
        };

        const that = this;
        console.log(LogPrefix, 'Sending kick transaction...');
        this._sendDataWithTransaction(request,
            (response) => {
                if(response.textbridge === 'success') {
                    console.log(LogPrefix, 'kick transaction success', response);

                    that._join();
                } else {
                    console.log(LogPrefix, 'kick transaction error', response);
                }
            },
            () => {
                console.log(LogPrefix, "Kick transaction sent successfully");
            },
            (error) => {
                console.log(LogPrefix, "Kick transaction send failed", error);
            });
    }

    sendMessage = (type, msg, to, callbacks, ack) => {
        const msgObj = {
            type: type,
            msg: msg,
        }

        const request = {
            textroom: 'message',
            room: this.roomId,
            username: this.userId,
            text: JSON.stringify(msgObj),
            ack: ack === false ? false : true,
        };
        if(to) {
            request.to = to;
        }

        console.log(LogPrefix, 'Sending message transaction...');
        this._sendDataWithTransaction(request,
            (response) => {
                console.log(LogPrefix, 'Message transaction', response);

                const transactionResult = response.textroom;
                if(transactionResult === 'success') {
                    if(to) {
                        if (response.sent && response.sent[to]) {
                            const sentResult = response.sent[to];

                            if (sentResult) {
                                console.log(LogPrefix, 'Message sent success');

                                if (callbacks && callbacks.onSuccess) {
                                    callbacks.onSuccess();
                                }
                            } else {
                                console.log(LogPrefix, 'Message sent fail');

                                if (callbacks && callbacks.onError) {
                                    callbacks.onError();
                                }
                            }
                        } else {
                            console.log(LogPrefix, 'Message sent error');

                            if (callbacks && callbacks.onError) {
                                callbacks.onError();
                            }
                        }
                    } else {
                        console.log(LogPrefix, 'Message sent transaction success');

                        if (callbacks && callbacks.onSuccess) {
                            callbacks.onSuccess();
                        }
                    }
                } else {
                    console.log(LogPrefix, 'Message sent transaction error');

                    if(callbacks && callbacks.onError) {
                        callbacks.onError();
                    }
                }
            },
            () => {
                console.log(LogPrefix, 'Message transaction sent successfully');

                // Don't call user callbacks
                // Handle in transaction callback
            },
            (error) => {
                console.log(LogPrefix, 'Message transaction send failed', error);

                if(callbacks && callbacks.onError) {
                    callbacks.onError();
                }
            });
    }

    sendAnnounce = (announce, callbacks) => {
        const request = {
            textroom: 'announcement',
            room: this.roomId,
            text: JSON.stringify(announce),
        }

        console.log(LogPrefix, 'Sending announce transaction...');
        this._sendDataWithTransaction(request,
            (response) => {
                console.log(LogPrefix, 'Announce transaction', response);

                const transactionResult = response.textroom;
                if(transactionResult === 'success') {
                    console.log(LogPrefix, 'Control message sent transaction success');

                    if (callbacks && callbacks.onSuccess) {
                        callbacks.onSuccess();
                    }
                } else {
                    console.log(LogPrefix, 'Control message sent transaction error');

                    if(callbacks && callbacks.onError) {
                        callbacks.onError();
                    }
                }
            },
            () => {
                console.log(LogPrefix, 'Announce transaction sent successfully');

                // Don't call user callbacks
                // Handle in transaction callback
            },
            (error) => {
                console.log(LogPrefix, 'Announce transaction send failed', error);

                if(callbacks && callbacks.onError) {
                    callbacks.onError();
                }
            });
    }

    leave = () => {
        const that = this;
        const request = {
            textroom: 'leave',
            room: this.roomId,
        }

        console.log(LogPrefix, 'Sending leave transaction...');
        this._sendDataWithTransaction(request,
            (response) => {
                console.log(LogPrefix, 'Leave transaction');

                if(that.pluginHandle) {
                    that.pluginHandle.detach();
                }
            },
            () => {
                console.log(LogPrefix, 'Leave transaction sent successfully');
            },
            (error) => {
                console.log(LogPrefix, 'Leave transaction send failed', error);
            });
    }

    _checkRoom = () => {
        const transaction = randomString(12);
        const msg = {
            textroom: 'exists',
            transaction: transaction,
            room: this.roomId,
        };

        const that = this;
        console.log(LogPrefix, `Register exists transaction [${transaction}]`);
        this.transactions[transaction] = function(response) {
            if(response.textroom === 'success') {
                console.log(LogPrefix, 'Exists transaction success', response);
                if((response.room === that.roomId) && (response.exists)) {
                    that._join();
                } else {
                    that._createRoom();
                }
            } else {
                console.log(LogPrefix, 'Exists transaction error', response);

                if(that.callbacks.onConnectError) {
                    that.callbacks.onConnectError();
                }
            }
        }


        console.log(LogPrefix, 'Exists transaction sending...', msg);
        this.pluginHandle.data({
            text: JSON.stringify(msg),
            success: function(data) {
                console.log(LogPrefix, 'Exists transaction sended successfully', data);
            },
            error: function(error) {
                console.log(LogPrefix, 'Exists transaction send failed', error);

                if(that.callbacks.onConnectError) {
                    that.callbacks.onConnectError();
                }
            }
        });
    }

    _createRoom = () => {
        const transaction = randomString(12);
        const request = {
            textroom: 'create',
            transaction: transaction,
            room: this.roomId,
            permanent: false,
        };

        const that = this;
        this._sendDataWithTransaction(request,
            (response) => {
                if(response.textroom === 'success') {
                    console.log(LogPrefix, 'Create transaction success', response);

                    that._join();
                } else {
                    console.log(LogPrefix, 'Create transaction error', response);

                    if(that.callbacks.onConnectError) {
                        that.callbacks.onConnectError();
                    }
                }
            },
            () => {
                console.log(LogPrefix, 'Create transaction sended successfully');
            },
            (error) => {
                console.log(LogPrefix, 'Create transaction send failed', error);

                if(that.callbacks.onConnectError) {
                    that.callbacks.onConnectError();
                }
            });
    }

    _join = () => {
        const request = {
            textroom: "join",
            room: this.roomId,
            username: this.userId,
            display: this.displayName,
        };

        const that = this;
        this._sendDataWithTransaction(request,
            (response) => {
                if(response.textroom === 'success') {
                    console.log(LogPrefix, 'Join transaction success', response);

                    if((response.participants) && (response.participants.length > 0)) {
                        for(let i in response.participants) {
                            const p = response.participants[i];
                            console.log(LogPrefix, 'Participant joined', p);

                            if(that.callbacks.onJoin) {
                                that.callbacks.onJoin(p.username, p.display, new Date());
                            }
                        }
                    }

                    if(that.callbacks.onConnectSuccess) {
                        that.callbacks.onConnectSuccess();
                    }
                } else {
                    console.log(LogPrefix, 'Join transaction error', response);

                    if(response.error_code === 420) {
                        console.log(LogPrefix, 'Already user joined');

                        if(that.callbacks.onAlreadyJoined) {
                            that.callbacks.onAlreadyJoined();
                        }

                        return;
                    } else {
                        if(that.callbacks.onConnectError) {
                            that.callbacks.onConnectError();
                        }
                    }
                }
            },
            () => {
                console.log(LogPrefix, 'Join transaction sended successfully');
            },
            (error) => {
                console.log(LogPrefix, 'Join transaction send failed', error);

                if(that.callbacks.onConnectError) {
                    that.callbacks.onConnectError();
                }
            });
    }

    _sendDataWithTransaction = (msg, transactionCallback, successCallback, errorCallback) => {
        if(transactionCallback) {
            const transaction = randomString(12);
            console.log(LogPrefix, `Register transaction [${transaction}]`);

            msg.transaction = transaction;
            this.transactions[transaction] = transactionCallback;
        }

        const request = {
            text: JSON.stringify(msg)
        };
        if(successCallback) {
            request.success = successCallback;
        }
        if(errorCallback) {
            request.error = errorCallback;
        }

        console.log(LogPrefix, 'Sending data with transaction...', msg);
        this.pluginHandle.data(request);
    }
}
import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Avatar,
    Breadcrumbs,
    Button,
    Container,
    Dialog,
    DialogTitle,
    Drawer,
    FormControl,
    Grid,
    Link,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import {inject, observer} from "mobx-react";

import Footer from "../../components/Footer";
import QuizAddDialog from "./QuizAddDialog";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import QuizUpdateDialog from "./QuizUpdateDialog";
import {QuizType} from "../../stores/QuizStore";


const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        marginTop: theme.spacing(2),
    },
    content: {
        marginTop: theme.spacing(3),
    },
    listContainer: {

    },
    listTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.spacing(2),
        height: 48,
    },
    listAddButton: {
        fontSize: 14,
        borderRadius: 20,
        '& svg':{
            width:'18px',
            height:'18px',
            marginRight:'4px'
        }
    },
    listContentContainer: {
        // border: '1px solid #eee',
        '& li':{
            borderBottom: '1px solid #eee',
        },
        '& li:first-child':{
            borderTop: '1px solid #eee',
        }
    },
    detailContainer: {

    },
    detailTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        height: 48,
    },
    detailTitle: {
        fontSize: 20,
    },
    detailContentContainer: {
        border: '1px solid #eee',
    },
    contentHeader: {
        padding: theme.spacing(2),
        backgroundColor: '#fafafa'
    },
    contentValue: {
        padding: theme.spacing(2),
    },

    noHistoryContainer: {
        display: 'flex',
        width: '100%',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: theme.spacing(3),
    },
    noHistoryAvatar: {
        width: 160,
        height: 160,
        textAlign: 'center',
        border: 'solid 1px #d4d4d4',
        backgroundColor: '#FFFFFF',
    },
    noHistoryHeader: {
        fontSize: 16,
        color: '#333333',
        paddingTop: theme.spacing(5),
    },
    noHistoryBody: {
        fontSize: 14,
        color: '#b7b7b7',
        paddingTop: theme.spacing(1),
    },
    noHistoryButton: {
        borderRadius: 24,
        fontSize: 16,
        width: 200,
        height: 48,
        marginTop: theme.spacing(4),
    },


    helpContainer: {
        width: 350,
        padding: theme.spacing(4),
        boxSizing:'border-box'
    },
    helpLogoContainer: {
        display: 'flex',
        flexDirection:'column',
        textAlign:'center'

    },
    helpLogoImage: {
        padding: theme.spacing(2,0,1)
    },
    helpLogoText: {
        fontSize: 22,
        fontWeight: 800,
        margin:theme.spacing(2,0,1),
    },
    helpPageTitle:{
        textAlign:'center',
        fontSize:'1.1em',
    },
    helpTitleContainer: {
        paddingTop: theme.spacing(2),
        paddingLeft:'20px',
        lineHeight:'1.45',
        '& > li':{
            fontSize:'1em',
            marginBottom:'1.5em',
        },
        '& b':{
            borderBottom:'1px solid #333'
        }
    },
    helpBodyContainer: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        '& > li':{
            fontSize:'0.9em'
        }
    },
    helpIcon:{
        position: 'fixed',
        bottom: '35px',
        right: '35px',
        boxShadow: 'none',
        background:'none',
        width:'85px',
        fontSize: 15,
        '&:hover, &:focus, &:active':{
            background:'none',
            boxShadow:'none',
        },
        '& svg':{
            width:36,
            height:36,
            marginRight:4,
            boxShadow:'0 2px 3px #c1c1c1',
            borderRadius:'50px'
        },
        '& span':{
            color:'rgba(51,51,51,0.56)'
        }
    },
    helpCloseIcon: {
        position: 'absolute',
        right: '1rem',
        top: '1rem',
        color: 'rgba(51,51,51,0.37)',
        width:'40px',
        height:'40px',
        cursor: 'pointer',
    },
});


@inject('quizStore', 'helpStore')
@observer
class QuizList extends React.Component {
    componentDidMount() {
        this.props.quizStore.loadQuizList(this.props.userId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.quizStore.isLoadFailed) {
            this.props.enqueueSnackbar('퀴즈 목록 조회에 실패하였습니다.', {
                variant: 'error',
            });

            this.props.quizStore.clearListState();
        }

        if(this.props.quizStore.isInfoLoadFailed) {
            this.props.enqueueSnackbar('퀴즈 정보 조회에 실패하였습니다.', {
                variant: 'error',
            });

            this.props.quizStore.clearInfoState();
        }

        if(this.props.quizStore.isDeleteFailed) {
            this.props.enqueueSnackbar('퀴즈 정보 삭제에 실패하였습니다.', {
                variant: 'error',
            });

            this.props.quizStore.clearDeleteState();
        }
    }

    handleClickQuiz = (quizId) => {
        this.props.quizStore.loadQuizInfo(quizId);
    }

    handleClickAddQuiz = () => {
        this.props.quizStore.openAddDialog();
    }

    handleOpenDeleteDialog = (quizId) => {
        this.props.quizStore.openDeleteDialog(quizId);
    }

    handleCloseDeleteDialog = () => {
        this.props.quizStore.clearDeleteState();
    }

    handleClickDeleteQuiz = () => {
        this.props.quizStore.deleteQuiz(this.props.userId);
    }

    handleClickUpdateQuiz = () => {
        this.props.quizStore.openUpdateDialog();
    }

    render() {
        const { classes } = this.props;
        const { quizList, quizInfo, isOpenDeleteDialog } = this.props.quizStore;
        return (
            <React.Fragment>
                <Container component="main" className={classes.mainContainer}>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.mainContent} />
                    <Typography variant="h4" component="h2">
                        퀴즈 목록
                    </Typography>
                    <FormControl margin="dense">
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                            <Link color="inherit" underline="none">퀴즈</Link>
                            <Link color="inherit" underline="none" onClick={(event) => {event.preventDefault()}}>퀴즈 목록</Link>
                        </Breadcrumbs>
                    </FormControl>

                    <Grid container className={classes.content} spacing={2}>
                        <Grid container item xs={12} sm={12} md={6} alignContent="flex-start" className={classes.listContainer}>
                            <Grid item xs={12} className={classes.listTitleContainer}>
                                <div style={{flexGrow: 1}}></div>
                                <Button color="primary" variant="outlined" className={classes.listAddButton} onClick={this.handleClickAddQuiz}><AddRoundedIcon/>퀴즈추가</Button>
                            </Grid>

                            <Grid item xs={12} className={classes.listContentContainer}>
                                {quizList.length > 0 ?
                                    <List>
                                        {quizList.map((quiz) =>
                                            <ListItem key={`quiz-${quiz.quizId}`} button selected={quiz.quizId === quizInfo.quizId} onClick={() => this.handleClickQuiz(quiz.quizId)}>
                                                <ListItemText primary={quiz.quizTitle}></ListItemText>
                                                <ListItemSecondaryAction>
                                                    <Button startIcon={<DeleteOutlineIcon/>}
                                                            onClick={() => this.handleOpenDeleteDialog(quiz.quizId)}>
                                                        삭제
                                                    </Button>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        )}
                                    </List>
                                    :
                                    <div className={classes.noHistoryContainer}>
                                        <Avatar className={classes.noHistoryAvatar}><img src="/images/nohistory.png" alt="no history"/></Avatar>
                                        <Typography className={classes.noHistoryHeader}>
                                            아직 퀴즈가 없습니다.
                                        </Typography>
                                        <Typography className={classes.noHistoryBody}>
                                            퀴즈를 만드려면 퀴즈 추가 버튼을 클릭하세요.
                                        </Typography>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} sm={12} md={6} alignContent="flex-start" className={classes.detailContainer}>
                            <Grid item xs={12} className={classes.detailTitleContainer}>
                                <Typography variant="subtitle1" className={classes.detailTitle}>퀴즈 상세내용</Typography>
                                <div style={{flexGrow: 1}}></div>
                                <Button color="primary" variant="outlined" className={classes.listAddButton} disabled={quizInfo.quizId < 1} onClick={this.handleClickUpdateQuiz}><EditIcon/>퀴즈변경</Button>
                            </Grid>
                            <Grid item xs={12} className={classes.detailContentContainer}>
                                <Grid container>
                                    <Grid item xs={3} className={classes.contentHeader}>
                                        <Typography>퀴즈 타입</Typography>
                                    </Grid>
                                    <Grid item xs={9} className={classes.contentValue}>
                                        <Typography>
                                            {quizInfo.type === QuizType.SINGLE_CHOICE ? '객관식 단답형'
                                                : quizInfo.type === QuizType.MULTIPLE_CHOICE ? '객관식 다답형'
                                                    : quizInfo.type === QuizType.SHORT_ANSWER ? '주관식 단답형'
                                                        : quizInfo.type === QuizType.SUBJECTIVE ? '주관식 서술형'
                                                            : ''
                                            }
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>

                                    <Grid item xs={3} className={classes.contentHeader}>
                                        <Typography>퀴즈 명</Typography>
                                    </Grid>
                                    <Grid item xs={9} className={classes.contentValue}>
                                        <Typography>{quizInfo.quizTitle}</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>

                                    <Grid item xs={3} className={classes.contentHeader}>
                                        <Typography>퀴즈 질문</Typography>
                                    </Grid>
                                    <Grid item xs={9} className={classes.contentValue}>
                                        <Typography>{quizInfo.quizQuestion}</Typography>
                                        {quizInfo.quizImages && quizInfo.quizImages.map((quizImage, index) => (
                                            <Grid item xs={12/(quizInfo.quizImages.length >= 5 ? 4 : quizInfo.quizImages.length)} key={`quizInfoImagePreview-${index}`}>
                                                <img src={`data:${quizImage.type};base64,${quizImage.image}`} width={"100%"} alt="" />
                                            </Grid>
                                        ))}
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>

                                    {quizInfo.type === QuizType.SUBJECTIVE || quizInfo.type === QuizType.SHORT_ANSWER ? '' :
                                        <>
                                            <Grid item xs={3} className={classes.contentHeader}>
                                                <Typography>퀴즈 답변항목</Typography>
                                            </Grid>
                                            <Grid item xs={9} className={classes.contentValue} style={{padding: 0}}>
                                                <List style={{height: 340, overflow: 'auto', padding: 0}}>
                                                    {quizInfo.quizOptions && quizInfo.quizOptions.map((option) =>
                                                        <ListItem key={`quiz-info-option-item-${option.quizOptionNumber}`}>
                                                            <ListItemText primary={<Typography variant="body1" style={{fontSize: 14}}> {quizInfo.type === QuizType.SUBJECTIVE ? `${option.quizOptionContents}` : `${option.quizOptionNumber}번 - ${option.quizOptionContents}`}</Typography>}
                                                                          secondary={option.correctFlag ?
                                                                              <Typography variant="body2" color="primary">정답</Typography>
                                                                              :
                                                                              <Typography variant="body2">오답</Typography>
                                                                          }
                                                            />
                                                        </ListItem>
                                                    )}
                                                </List>
                                            </Grid>
                                        </>
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>

                <QuizAddDialog loginType={this.props.loginType} userId={this.props.userId} handleOk={() => this.props.quizStore.addNewQuiz(this.props.userId)} />
                <QuizUpdateDialog loginType={this.props.loginType} userId={this.props.userId} />

                <Dialog fullWidth
                        maxWidth="xs"
                        open={isOpenDeleteDialog}
                        onClose={this.handleCloseDeleteDialog}
                        aria-labelledby="quiz-delete-dialog-title" >
                    <DialogTitle id="quiz-delete-dialog-title">
                        퀴즈 삭제
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="create-group-dialog-description">
                            퀴즈를 삭제하면 관련된 정보가 모두 삭제되며, 복구 할 수 없습니다.<br/>
                            정말로 삭제하시겠습니까?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDeleteDialog} >
                            취소
                        </Button>
                        <Button onClick={this.handleClickDeleteQuiz} color="primary" autoFocus>
                            확인
                        </Button>
                    </DialogActions>
                </Dialog>

                <Footer/>

                <div>
                    {/*<Fab size="small" className={classes.helpIcon} onClick={() => this.props.helpStore.openHelp()} >*/}
                    {/*    <HelpIcon color="primary" viewBox="2 2 20 20" /> <span>도움말</span>*/}
                    {/*</Fab>*/}

                    <Drawer anchor="right" open={this.props.helpStore.open} onClose={() => this.props.helpStore.closeHelp()} >
                        <CloseRoundedIcon className={classes.helpCloseIcon} onClick={() => this.props.helpStore.closeHelp()}/>

                        <div className={classes.helpContainer}>
                            <div className={classes.helpLogoContainer}>
                                <div className={classes.helpLogoImage}><img src="/images/logo-vert.png" alt="ONTHELIVE" /></div>
                                <Typography className={classes.helpLogoText}>도움말</Typography>
                            </div>

                            <div>
                                <Typography className={classes.helpPageTitle} variant="h5" >퀴즈목록</Typography>
                                <ol className={classes.helpTitleContainer}>
                                    <li>교수님은 수업중 퀴즈를 내기 위해서는 미리 퀴즈를 등록하여야 합니다.</li>

                                    <li>'퀴즈 목록'의 <AddIcon />을 클릭하여 퀴즈를 생성합니다.
                                        <ul className={classes.helpBodyContainer}>
                                            <li>퀴즈의 제목, 문제를 입력하고 해당 문제를 보기까지 입력합니다.</li>
                                            <li>퀴즈 목록에서 방금 만든 퀴즈를 클릭하여 입력한 내용을 확인합니다.</li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </Drawer>
                </div>
            </React.Fragment>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (QuizList)));
import {observable, action, flow, computed, toJS} from "mobx";
import axios from "axios";
import _ from "lodash";

export const PollType = {
    OX: 'OX',
    Type3: 'Type3',
    Type4: 'Type4',
    Type5: 'Type5',
    Answer: 'Answer',
};

const LogPrefix = '[PollStore] ';

export default class PollStore {
    constructor() {
        this.onPollCreated = undefined;
        this.onPollSelected = undefined;
    }

    @observable isOpenPollCreateDialog = false;
    @observable channelId = '';
    @observable pollId = -1;
    @observable pollName = '';
    @observable pollType = PollType.OX;
    @observable isEditableItems = false;
    @observable pollItems = [
        {itemId: 1, name: '예'},
        {itemId: 2, name: '아니오'},
        ];
    @observable isCreating = false;

    @observable isOpenPollSelectDialog = false;
    @observable poll = {
        name: '',
        items: [],
    };
    @observable pollAnswer = '';
    @observable selectedPollItemId = 1;
    @observable isGetting = false;
    @observable isGetError = false;
    @observable isSelecting = false;

    @observable pollResultCancelToken = axios.CancelToken.source();
    @observable pollResult = {
        name: '',
        items: [],
    };

    @action initializeStore = () => {
        this.isOpenPollCreateDialog = false;
        this.channelId = '';
        this.pollId = -1;
        this.pollName = '';
        this.pollType = PollType.OX;
        this.isEditableItems = false;
        this.pollItems = [
            {itemId: 1, name: '예'},
            {itemId: 2, name: '아니오'},
        ];
        this.isCreating = false;

        this.isOpenPollSelectDialog = false;
        this.poll = {
            name: '',
            items: [],
        };
        this.pollAnswer = '';
        this.selectedPollItemId = 1;
        this.isGetting = false;
        this.isGetError = false;
        this.isSelecting = false;

        this.pollResultCancelToken = axios.CancelToken.source();
        this.pollResult = {
            name: '',
            items: [],
        };
    }

    setOnPollCreated = (callback) => {
        this.onPollCreated = callback;
    }

    setOnPollSelected = (callback) => {
        this.onPollSelected = callback;
    }

    @action openPollCreateDialog = (channelId) => {
        this.channelId = channelId;
        this.isOpenPollCreateDialog = true;
    }

    @action closePollCreateDialog = () => {
        this.isOpenPollCreateDialog = false;
    }

    @action setPollName = (name) => {
        this.pollName = name;
    }

    @action setPollType = (pollType) => {
        this.pollType = pollType;
        if(pollType === PollType.OX) {
            this.isEditableItems = false;
            this.pollItems = [
                {itemId: 1, name: '예'},
                {itemId: 2, name: '아니오'},
            ];
        } else if(pollType === PollType.Type3) {
            this.isEditableItems = true;
            this.pollItems = [
                {itemId: 1, name: ''},
                {itemId: 2, name: ''},
                {itemId: 3, name: ''},
            ];
        } else if(pollType === PollType.Type4) {
            this.isEditableItems = true;
            this.pollItems = [
                {itemId: 1, name: ''},
                {itemId: 2, name: ''},
                {itemId: 3, name: ''},
                {itemId: 4, name: ''},
            ];
        } else if(pollType === PollType.Type5) {
            this.isEditableItems = true;
            this.pollItems = [
                {itemId: 1, name: ''},
                {itemId: 2, name: ''},
                {itemId: 3, name: ''},
                {itemId: 4, name: ''},
                {itemId: 5, name: ''},
            ];
        } else {
            this.isEditableItems = false;
            this.pollItems = [];
        }
    }

    @action setPollItemName = (itemId, name) => {
        const item = this.pollItems.find((item) => item.itemId === itemId);

        if(item) {
            item.name = name;
        }
    }

    createNewPoll = flow(function *createNewPoll() {
        this.isCreating = true;

        try {
            const param = {
                channelId: this.channelId,
                type: this.pollType === PollType.Answer ? 'Answer' : 'Choice',
                name: this.pollName,
                items: this.pollItems,
            };
            const newPollResponse = yield axios.put(`/api/v1/polls/${this.channelId}`, param);
            console.log(LogPrefix, "New poll created", newPollResponse);

            const pollId = newPollResponse.data.pollId;

            this.pollId = pollId;
            this.isCreating = false;

            this.setPollName('');
            this.setPollType(PollType.OX);
            this.isOpenPollCreateDialog = false;

            if(this.onPollCreated) {
                this.onPollCreated(pollId);
            }
        } catch(error) {
            console.log(LogPrefix, "Can't create a new poll", error);

            this.isCreating = false;
        }
    });

    openPollSelectDialog = flow(function *openPollSelectDialog(channelId, pollId) {
        this.channelId = channelId;
        this.pollId = pollId;
        this.isGetting = true;
        this.isGetError = false;
        this.isOpenPollSelectDialog = true;

        try {
            const pollResponse = yield axios.get(`/api/v1/polls/${channelId}/${pollId}`);
            console.log(LogPrefix, "Got a poll", pollResponse);

            const poll = pollResponse.data;

            this.poll = poll;
            this.pollAnswer = '';
            if(poll.items && poll.items.length > 0) {
                this.selectedPollItemId = poll.items[0].itemId;
            }
            this.isGetting = false;
            this.isGetError = false;
        } catch(error) {
            console.log(LogPrefix, `Can't get a poll : channelId=${channelId}, pollId=${pollId}`, error);

            this.isGetting = false;
            this.isGetError = true;
        }
    });

    @action closePollSelectDialog = () => {
        this.isOpenPollSelectDialog = false;

        if(this.onPollSelected) {
            const channelId = toJS(this.channelId);
            const pollId = toJS(this.pollId);

            this.onPollSelected(channelId, pollId, undefined);
        }
    }

    @action setPollAnswer = (answer) => {
        this.pollAnswer = answer;
    }

    @action setSelectedPollItemId = (itemId) => {
        this.selectedPollItemId = itemId;
    }

    selectPollItem = flow(function *selectPollItem() {
        const channelId = toJS(this.channelId);
        const pollId = toJS(this.pollId);
        const pollType = toJS(this.poll.type);
        const pollAnswer = toJS(this.pollAnswer);
        const itemId = toJS(this.selectedPollItemId);

        this.isSelecting = true;

        try {
            if(pollType === 'Answer') {
                yield axios.put(`/api/v1/polls/${channelId}/${pollId}`, {answer: pollAnswer});
            } else {
                yield axios.put(`/api/v1/polls/${channelId}/${pollId}/${itemId}`);
            }

            this.isOpenPollSelectDialog = false;
            this.poll = {
                name: '',
                items: [],
            };
            this.selectedPollItemId = 1;
            this.isSelecting = false;

            if(this.onPollSelected) {
                this.onPollSelected(channelId, pollId, itemId);
            }
        } catch(error) {
            console.log(LogPrefix, `Can't select a poll item : channelId=${channelId}, pollId=${pollId}, itemId=${itemId}`);

            this.isSelecting = false;
        }
    });

    getPollResult = flow(function * showPollResult(channelId, pollId) {
        try {
            this.pollResultCancelToken.cancel();

            console.log(LogPrefix, 'Getting poll result...');
            const pollResultResponse = yield axios.get(`/api/v1/polls/${this.channelId}/${this.pollId}/results`);

            console.log(LogPrefix, 'Got a poll result', pollResultResponse);
            const pollResult = pollResultResponse.data;

            this.pollResult = pollResult;
        } catch(error) {
            if(axios.isCancel(error)) {
                console.log(LogPrefix, "Getting poll result canceled", error);
            } else {
                console.log(LogPrefix, "Can't get a poll result", error);
            }
        }
    });

    @computed get pollResultItems() {
        return this.pollResult && this.pollResult.items ?
            _.orderBy(this.pollResult.items, ['count'], ['asc'])
            :
            []
        ;
    }
}
import {action, computed, flow, observable} from "mobx";
import axios from "axios";

export default class AdminStore {
    @observable userId = 0;
    @observable isSignUpDialogOpen = false;
    @observable userList = [];

    @action setUserId = userId => this.userId = userId;
    @action changeSignUpDialogOpen = value => this.isSignUpDialogOpen = value;

    @computed
    get users() {
        return this.userList === undefined ? [] : this.userList;
    }

    loadUserList = flow(function* getUserList() {
        try {
            const response = yield axios.get(`/api/v1/admin/users?paging=no`)
            if(response.status === 200) {
                this.userList = response.data.filter(user => user.userId !== this.userId);
            }
        } catch(error) {
            console.log("error getUserList", error);
        }
    })

    changeUserStatus = flow(function* changeUserStatus(userId, statusCode) {
        try {
            const response = yield axios.put(`/api/v1/admin/users`, [{"userId": userId, "statusCode": statusCode}])
            if(response.status === 200) {
                this.loadUserList();
            }
        } catch(error) {
            console.log("error getUserList", error);
        }
    })

    deleteUser = flow(function* deleteUser(userId) {
        try {
            const response = yield axios.put(`api/v1/admin/users/${userId}`,)
            if(response.status === 200) {
                this.loadUserList();
            }
        }catch (error) {
            console.log("error getDeleteUser", error);
        }
    })
}
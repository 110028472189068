import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    Link,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";


const styles = (theme) => ({
    dialog: {
    },
    dialogTitle: {
    },
    dialogContent: {
    },
    itemHeader: {
    },
    itemBody: {
        paddingTop: theme.spacing(1),
    },
    leftAlignTextBody: {
        paddingTop: 32,
        paddingRight: theme.spacing(2),
        textAlign: 'left'

    },
    filebox: {
        paddingTop: 35,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    fileSelection: {
        position: 'absolute',
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: 'hidden',
        clip: 'rect(0,0,0,0)',
        border: 0,
        borderRadius: 12,
    },
    uploadButton: {
        marginTop: 35,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        height: 36,
        borderRadius: 25,
    },
    grow: {
        flexGrow: 1,
    },
});

@inject('channelFileStore')
@observer
class ChannelFileDialog extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.channelFileStore.isLoadFailed) {
            this.props.enqueueSnackbar('파일 목록 조회에 실패하였습니다.', {
                variant: 'error'
            });

            this.props.channelFileStore.clearFileDialogState(false);
        }

        if(this.props.channelFileStore.isUploadFailed) {
            this.props.enqueueSnackbar('파일 올리기에 실패하였습니다.', {
                variant: 'error'
            });

            this.props.channelFileStore.clearFileDialogState(true);
        }
    }

    handleClose = () => {
        this.props.channelFileStore.clearFileDialogState(false);
    }

    handleChangePagingFilter = (event) => {
        const filter = event.target.value;

        this.props.channelFileStore.changePagingFilter(filter);
    }

    handleChangePagingPage = (event, newPage) => {
        this.props.channelFileStore.changePagingPage(newPage);
    }

    handleChangePagingRowsPerPage = (event) => {
        const newValue = parseInt(event.target.value, 10);

        this.props.channelFileStore.changePagingRowsPerPage(newValue);
    }

    handleChangeUploadComment = (event) => {
        const comment = event.target.value;

        this.props.channelFileStore.changeUploadComment(comment);
    }

    handleChangeUploadFile = (event) => {
        const file = event.target.files[0];

        this.props.channelFileStore.changeUploadFile(file);
    }

    handleUpload = () => {
        this.props.channelFileStore.uploadFile(this.props.userId);
    }

    handleFileDownload = (fileId, fileName) => {
        this.props.channelFileStore.downloadFile(fileId, fileName);
    }

    render() {
        const {classes} = this.props;
        const {isOpenFileDialog, isUploadable, isUploading, channelUserId, paging, fileList, upload} = this.props.channelFileStore;
        // console.log("channelUserId : " + channelUserId);
        // console.log("userId : " + this.props.userId);
        return (
            <Dialog disableBackdropClick fullWidth
                    maxWidth="md"
                    className={classes.dialog}
                    open={isOpenFileDialog}
                    onClose={this.handleClose}
                    aria-labelledby="channel-file-dialog-title" >
                <DialogTitle id="channel-file-dialog-title" className={classes.dialogTitle}>
                    자료 목록
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Grid container>
                        {channelUserId === this.props.userId ?
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <TextField id="upload-comment"
                                                       margin="normal"
                                                       label={<Typography variant="subtitle1" >자료 설명</Typography>}
                                                       value={upload.comment}
                                                       onChange={this.handleChangeUploadComment}
                                                       InputLabelProps={{shrink: true, className: classes.itemHeader}}
                                                       InputProps={{className: classes.itemBody}}
                                                       autoFocus fullWidth />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} style={{display: 'flex'}}>
                                                <FormControl margin="normal" style={{flexGrow: 1}}>
                                                    <InputLabel id="channel-sub-label" className={classes.itemHeader} shrink>
                                                        <Typography variant="subtitle1" >파일</Typography>
                                                    </InputLabel>
                                                    <div className={classes.leftAlignTextBody}>
                                                        <Typography>{upload.file ? upload.file.name : ''}</Typography>
                                                    </div>
                                                </FormControl>

                                                <div className={classes.filebox}>
                                                    <Button component="label" variant="contained" htmlFor="file-upload" style={{borderRadius:'25px'}}>파일 선택</Button>
                                                    <input id="file-upload" type="file" className={classes.fileSelection} onChange={this.handleChangeUploadFile} />
                                                </div>

                                                <Button color="primary" variant="contained" className={classes.uploadButton} disabled={!isUploadable || isUploading} onClick={() => this.handleUpload()}>
                                                    {isUploading ? <CircularProgress style={{width: 16, height: 16}} /> : '자료 올리기' }
                                                </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            </React.Fragment>
                            :
                            ''
                        }
                        <Grid item xs={12}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" style={{width: '25%'}}><Typography variant="subtitle2">파일이름</Typography></TableCell>
                                        <TableCell align="left" style={{width: '75%'}}><Typography variant="subtitle2">설명</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {fileList.map(file =>
                                        <TableRow key={`file-table-row${file.fileId}`}>
                                            <TableCell align="left" component="th" scope="row">
                                                {file.downloading ?
                                                    <React.Fragment>
                                                        <Typography display="inline" variant="body2">{file.fileNameOriginal}</Typography>
                                                        <CircularProgress size="small" color="primary" style={{width: 15, height: 15, marginLeft: 6}} />
                                                    </React.Fragment>
                                                    :
                                                    <Link href="#" color="inherit" underline="hover" onClick={() => this.handleFileDownload(file.fileId, file.fileNameOriginal)}>
                                                        <Typography variant="body2">{file.fileNameOriginal}</Typography>
                                                    </Link>
                                                }
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="body2">{file.comment ? file.comment : ''}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        component="div"
                        count={paging.totalCount}
                        rowsPerPage={paging.rowsPerPage}
                        page={paging.page}
                        labelRowsPerPage=""
                        labelDisplayedRows={({from, to, count}) => `총 ${count}개 중 ${from} - ${to}`}
                        SelectProps={{renderValue: (value) => value + ' 개씩 보기'}}
                        onChangePage={this.handleChangePagingPage}
                        onChangeRowsPerPage={this.handleChangePagingRowsPerPage}
                    />
                    <div className={classes.grow} />
                    <Button onClick={this.handleClose}
                            style={{
                                borderRadius:'25px'
                            }}>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (ChannelFileDialog)));
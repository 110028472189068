import React from 'react';
import moment from "moment";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";

import {withSnackbar} from "notistack";
import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogContent, DialogTitle, Divider} from "@material-ui/core";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {QuizType} from "../../../stores/QuizStore";

const styles = (theme) => ({

});

@inject("broadcastQuizStore")
@observer
class BroadcastQuizResultDialog extends React.Component{
    componentDidMount() {
    }

    handleClose = () => {
        const {changeQuizResultDialogOpen} = this.props.broadcastQuizStore;
        changeQuizResultDialogOpen(false);
    }

    handleQuestionResultClick = (quizQuestionId) => {
        const {changeSelectedQuizResultId} = this.props.broadcastQuizStore;
        changeSelectedQuizResultId(quizQuestionId);
        this.props.broadcastQuizStore.getQuizResultQuestionInfo(quizQuestionId);
        this.props.broadcastQuizStore.getQuizResultQuestionAnswer(quizQuestionId);
        const dialogEl = document.getElementById("resultDialog");
        if(dialogEl) {
            dialogEl.scrollTop = 0;
        }
    }

    getAnswerTableData = () => {
        const { selectedQuizAnswer } = this.props.broadcastQuizStore;

        if(!selectedQuizAnswer) {
            return [];
        }

        return selectedQuizAnswer.map((item, index) => {
            let answerOption = null;
            let correctFlag = true;
            let createdDatetime = null;
            const isSubjective = item.type === QuizType.SUBJECTIVE || item.type === QuizType.SHORT_ANSWER;
            let answerContentList = item.channelQuizAnswerList.map(answer => {
                createdDatetime = (answer.createdDatetime) ? moment(answer.createdDatetime).format("YYYY-MM-DD HH:mm:ss") : null;
                answerOption = item.channelQuizOptions.find(option => option.channelQuizOptionId === answer.channelQuizOptionId);
                if(correctFlag) correctFlag = isSubjective ? true : answerOption.correctFlag;
                return isSubjective ? answer.quizOptionContents : answerOption.quizOptionContents;
            });

            return {
                userName: item.userName,
                answer: answerContentList ? answerContentList.toString() : "",
                correctFlag: isSubjective ? "주관식" : correctFlag ? "정답" : "오답",
                createdDatetime: createdDatetime,
            }
        });
    }

    render() {
        const { quizResultDialogOpen, quizResultQuestion, selectedQuizResultId } = this.props.broadcastQuizStore;
        const selectedQuizInfo = (selectedQuizResultId && quizResultQuestion) ? quizResultQuestion.find(result => result.quizQuestionId === selectedQuizResultId) : {};
        return (
            <Dialog open={quizResultDialogOpen} onClose={this.handleClose} fullWidth={true} maxWidth={'md'}>
                <DialogTitle>
                    퀴즈 결과
                </DialogTitle>
                <DialogContent id={"resultDialog"}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={4} sm={4} style={{border: '1px solid #ddd', padding: '6px'}}>
                            <Grid item xs={12} md={12} sm={12} style={{paddingLeft: '18px'}}>
                               퀴즈 목록
                            </Grid>
                            <div style={{margin: '10px'}} />
                            <Grid item xs={12} md={12} sm={12}>
                                <List component="nav" style={{padding:0}}>
                                    {quizResultQuestion ? quizResultQuestion.map((item, index) => {
                                        return (
                                            <React.Fragment key={"question-"+index}>
                                                <ListItem button
                                                          key={"question-list"+index}
                                                          selected={item.quizQuestionId === selectedQuizResultId}
                                                          onClick={() => this.handleQuestionResultClick(item.quizQuestionId)}
                                                >
                                                    <ListItemText primary={item.quizTitle} />
                                                </ListItem>
                                                <Divider/>
                                            </React.Fragment>
                                        );
                                    }) : <ListItem/>}
                                </List>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={8} sm={8} style={{paddingLeft: '12px', paddingRight: '12px'}}>
                            <Grid item xs={12} md={12} sm={12} style={{paddingLeft: '14px'}}>
                                퀴즈 결과
                                {(selectedQuizInfo && selectedQuizInfo.channelQuizImages) && selectedQuizInfo.channelQuizImages.map((quizImage, index) =>
                                    <Grid item xs={12/(selectedQuizInfo.channelQuizImages.length >= 5 ? 4 : selectedQuizInfo.channelQuizImages.length)} key={`channelQuizInfoImagePreview-${index}`}>
                                        <img src={`data:${quizImage.type};base64,${quizImage.image}`} width={"100%"} alt="" />
                                    </Grid>
                                )}
                            </Grid>
                            <div style={{margin: '10px'}} />
                            <Grid item xs={12} md={12} sm={12}>
                                <MaterialTable
                                    style={{boxShadow: 'none'}}
                                    options={{
                                        search: false,
                                        showTitle: false,
                                        toolbar: false,
                                        actionsColumnIndex: -1,
                                        pageSize: 10,
                                        pageSizeOptions: [5, 10, 15, 20, 25, 30, 40, 50],
                                        headerStyle: {
                                            backgroundColor: '#fafafa',
                                            color: 'rgba(51, 51, 51, 0.56)',
                                            borderTop: '1px solid #eee',
                                            padding: 8,
                                        }
                                    }}
                                    localization={{
                                        header: {
                                            actions: '',
                                        },
                                        body: {
                                            emptyDataSourceMessage: '퀴즈 결과가 없습니다.',
                                        },
                                        pagination: {
                                            labelRowsSelect: ' 개씩 보기',
                                            labelDisplayedRows: '총 {count}개 중 {from} - {to}',
                                        },
                                    }}
                                    columns={[
                                        {title: '이름', field: 'userName'},
                                        {title: '제출한 답', field: 'answer'},
                                        {title: '정답 여부', field: 'correctFlag'},
                                        {title: '제출 시간', field: 'createdDatetime' ,headerStyle: {textAlign:'center'} ,cellStyle: {minWidth: '175px'}}
                                    ]}
                                    data={this.getAnswerTableData()}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{
                            color: 'rgba(0, 0, 0, 0.56)'
                        }}
                        onClick={this.handleClose}
                    >
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withSnackbar(withRouter(withStyles(styles) (BroadcastQuizResultDialog)));

import {action, computed, flow, observable} from "mobx";
import axios from "axios";

export const State = {
    Authenticated: 'Authenticated',
    NotAuthenticated: 'NotAuthenticated',
    Pending: 'Pending',
    Failed: 'Failed',
};

export const LocalStorageSaveIdKey = "_OTL_AUTHENTICATION_SAVE_ID_";

// export const LocalStorageTokenKey = "_OTL_AUTHENTICATION_TOKEN_";
// export const LocalStorageChatTokenKey = "_OTL_AUTHENTICATION_CHAT_TOKEN_";
// export const LocalStorageSaveTypeKey = "_OTL_AUTHENTICATION_TYPE_";
// export const LocalStorageSaveTeacherTypeKey = "_OTL_AUTHENTICATION_TEACHER_TYPE_";
// export const LocalStorageSaveEmailKey = "_OTL_AUTHENTICATION_EMAIL_";
// export const LocalStorageSaveTeacherNickNameKey = "_OTL_AUTHENTICATION_TEACHER_NICK_NAME_";
// export const LocalStorageSaveNameKey = "_OTL_AUTHENTICATION_NAME_";

export const SessionStorageTokenKey = "_OTL_AUTHENTICATION_TOKEN_";
export const SessionStorageChatTokenKey = "_OTL_AUTHENTICATION_CHAT_TOKEN_";
export const SessionStorageSaveTypeKey = "_OTL_AUTHENTICATION_TYPE_";
export const SessionStorageSaveTeacherTypeKey = "_OTL_AUTHENTICATION_TEACHER_TYPE_";
export const SessionStorageSaveEmailKey = "_OTL_AUTHENTICATION_EMAIL_";
export const SessionStorageSaveTeacherNickNameKey = "_OTL_AUTHENTICATION_TEACHER_NICK_NAME_";
export const SessionStorageSaveNameKey = "_OTL_AUTHENTICATION_NAME_";


const EmptyLogin = {
    id: '',
    password: '',
};

const EmptyMember = {
    type: 'nickname',
    email: '',
    teacherNickName: '',
    name: '',
    password: '',
};

const EmptyUser = {
    userId: '',
    email: '',
    userName: '',
    nickName: '',
    typeCode: '',
    profileUrl: '',
    createdDatetime: '',
    modifiedDatetime: '',
};

export default class AuthStore {
    @observable loginType = sessionStorage.getItem(SessionStorageSaveTypeKey);
    @observable login = Object.assign({}, EmptyLogin);
    @observable member = Object.assign({}, EmptyMember);
    @observable saveId = false;
    @observable loginState = State.NotAuthenticated;
    @observable loginToken = '';
    @observable chatToken = '';
    @observable loginUser = Object.assign({}, EmptyUser);
    @observable isOpenEnabledUserDialog = false;

    @action checkLoginId = () => {
        const loginType = sessionStorage.getItem(SessionStorageSaveTypeKey);
        const savedId = localStorage.getItem(LocalStorageSaveIdKey);
        const saveTeacherIdType = sessionStorage.getItem(SessionStorageSaveTeacherTypeKey);
        const saveEmail = sessionStorage.getItem(SessionStorageSaveEmailKey);
        const teacherNickName = sessionStorage.getItem(SessionStorageSaveTeacherNickNameKey);
        const saveName = sessionStorage.getItem(SessionStorageSaveNameKey);

        if(loginType === 'USER') {
            this.login.id = savedId;
            this.saveId = true;
        } else if(loginType === 'MEMBER') {
            this.member.type = saveTeacherIdType ? saveTeacherIdType : 'email';
            this.member.email = saveEmail ? saveEmail : '';
            this.member.teacherNickName = teacherNickName ? teacherNickName : '';
            this.member.name = saveName;
            this.saveId = true;
        } else if(loginType === 'ADMIN') {
            this.loginType = 'ADMIN';
            this.login.id = savedId;
            this.saveId = true;
        } else {
            this.loginType = 'USER';
        }
    };

    @action clearLoginType = () => {
        sessionStorage.setItem(SessionStorageSaveTypeKey, '');
        this.loginType = '';
    }


    @action changeLoginType = (type) => {
        sessionStorage.setItem(SessionStorageSaveTypeKey, type);
        this.loginType = type;
    }

    @action changeLoginId = (id) => {
        this.login.id = id;
    };

    @action changeLoginPassword = (password) => {
        this.login.password = password;
    };

    @action changeMemberTeacherIdType = (type) => {
        this.member.type = type;
    }

    @action changeMemberEmail = (email) => {
        this.member.email = email;
    }

    @action changeMemberTeacherNickName = (teacherNickName) => {
        this.member.teacherNickName = teacherNickName;
    }

    @action changeMemberName = (name) => {
        this.member.name = name;
    }

    @action changeMemberPassword = (password) => {
        this.member.password = password;
    }

    @action changeLoginMemberId = (memberId) => {
        this.login.memberId = memberId;
    }

    @action changeSaveId = (saveId) => {
        this.saveId = saveId;
    };

    @action invalidateLogin = () => {
        this.login = Object.assign({}, EmptyLogin);
        this.loginState = State.NotAuthenticated;
        this.loginToken = '';
        this.loginUser = Object.assign({}, EmptyUser);
    };

    @action logOut = pathname => {
        // if(pathname.startsWith("/broadcast/")) {
            if (window.confirm("로그아웃 하시겠습니까?")) {
                this.doLogout();
            }
        // } else {
        //     this.doLogout();
        // }
    };

    @action changeEnabledUserDialog = isOpenEnabledUserDialog => this.isOpenEnabledUserDialog = isOpenEnabledUserDialog;

    @computed get isLoginTypeAdmin() {
        return this.loginType === 'ADMIN';
    }

    @computed get isLoginTypeUser() {
        return this.loginType === 'USER';
    }

    @computed get isLoginTypeMember() {
        return this.loginType === 'MEMBER';
    }

    @computed get isLoggedIn() {
        return this.loginState === State.Authenticated;
    }

    @action loginSuccessAction = response => {
        const token = response.data.token;
        const chatToken = response.data.chatToken;
        const user = response.data.user;

        sessionStorage.setItem(SessionStorageTokenKey, token);
        sessionStorage.setItem(SessionStorageChatTokenKey, chatToken);

        this.loginState = State.Authenticated;
        this.loginToken = token;
        this.chatToken = chatToken;
        this.loginUser = user;
        this.loginType = user.typeCode;
    }

    // doLogin = flow(function* doLogin() {
    //     this.loginState = State.Pending;
    //
    //    if(this.loginType === 'ADMIN' || this.loginType === 'USER') {
    //         if (this.saveId) {
    //             localStorage.setItem(LocalStorageSaveIdKey, this.login.id);
    //         } else {
    //             localStorage.setItem(LocalStorageSaveIdKey, '');
    //             sessionStorage.setItem(SessionStorageSaveEmailKey, '');//session
    //             sessionStorage.setItem(SessionStorageSaveNameKey, ''); //session
    //         }
    //
    //         try {
    //             const param = {
    //                 email: this.login.id.trim(),
    //                 password: this.login.password,
    //             };
    //             const url = this.loginType === 'ADMIN' ? '/api/v1/admin/signin' : '/api/v1/authentications/signin';
    //             const response = yield axios.post(url, param);
    //             const token = response.data.token;
    //             const chatToken = response.data.chatToken;
    //             const user = response.data.user;
    //
    //             sessionStorage.setItem(SessionStorageTokenKey, token); //session
    //             sessionStorage.setItem(SessionStorageChatTokenKey, chatToken); //session
    //
    //             this.loginState = State.Authenticated;
    //             this.loginToken = token;
    //             this.chatToken = chatToken;
    //             this.loginUser = user;
    //         } catch (e) {
    //             this.loginState = State.Failed;
    //             if(e.response && e.response.data && e.response.data.message && e.response.data.message.startsWith("User is not enabled")) {
    //                 this.isOpenEnabledUserDialog = true;
    //             }
    //             this.loginToken = '';
    //             this.chatToken = '';
    //             this.loginUser = Object.assign({}, EmptyUser);
    //         }
    //     } else if(this.loginType === 'MEMBER') {
    //         if (this.saveId) {
    //             sessionStorage.setItem(SessionStorageSaveTeacherTypeKey, this.member.type);
    //             sessionStorage.setItem(SessionStorageSaveEmailKey, this.member.email);
    //             sessionStorage.setItem(SessionStorageSaveTeacherNickNameKey, this.member.teacherNickName);
    //             sessionStorage.setItem(SessionStorageSaveNameKey, this.member.name);
    //         } else {
    //             localStorage.setItem(LocalStorageSaveIdKey, '');
    //             sessionStorage.setItem(SessionStorageSaveTeacherTypeKey, '');
    //             sessionStorage.setItem(SessionStorageSaveEmailKey, '');
    //             sessionStorage.setItem(SessionStorageSaveTeacherNickNameKey, '');
    //             sessionStorage.setItem(SessionStorageSaveNameKey, '');
    //         }
    //
    //         try {
    //             const param = {
    //                 type: this.member.type,
    //                 email: this.member.email.trim(),
    //                 teacherNickName: this.member.teacherNickName.trim(),
    //                 userName: this.member.name.trim(),
    //                 password: this.member.password,
    //             };
    //             const response = yield axios.post('/api/v1/authentications/members/signin', param);
    //             const token = response.data.token;
    //             const chatToken = response.data.chatToken;
    //             const user = response.data.user;
    //
    //             sessionStorage.setItem(SessionStorageTokenKey, token);
    //             sessionStorage.setItem(SessionStorageChatTokenKey, chatToken);
    //
    //             this.loginState = State.Authenticated;
    //             this.loginToken = token;
    //             this.chatToken = chatToken;
    //             this.loginUser = user;
    //         } catch (e) {
    //             this.loginState = State.Failed;
    //             if(e.response.data.message.startsWith("User is not enabled")) {
    //                 this.isOpenEnabledUserDialog = true;
    //             }
    //             this.loginToken = '';
    //             this.chatToken = '';
    //             this.loginUser = Object.assign({}, EmptyUser);
    //         }
    //     }
    // });

    doLogin = flow(function* doLogin() {
        console.log("AuthStore", "doLogin entered.", this.loginType);

        if(this.loginType === 'ADMIN' || this.loginType === 'USER') {
            if (this.saveId) {
                localStorage.setItem(LocalStorageSaveIdKey, this.login.id);
            } else {
                localStorage.setItem(LocalStorageSaveIdKey, '');
                sessionStorage.setItem(SessionStorageSaveEmailKey, '');//session
                sessionStorage.setItem(SessionStorageSaveNameKey, ''); //session
            }

            try {
                this.loginState = State.Pending;
                console.log("AuthStore", "doLogin make param.", this.login.id);
                const param = {
                    email: this.login.id ? this.login.id.trim() : '',
                    password: this.login.password,
                };
                const url = this.loginType === 'ADMIN' ? '/api/v1/admin/signin' : '/api/v1/authentications/signin';
                const response = yield axios.post(url, param);
                this.loginSuccessAction(response);

            } catch (e) {
                console.log("AuthStore", "doLogin error.", e);
                this.loginState = State.Failed;
                if(e.response && e.response.data && e.response.data.message && e.response.data.message.startsWith("User is not enabled")) {
                    this.isOpenEnabledUserDialog = true;
                }
                this.loginToken = '';
                this.chatToken = '';
                this.loginUser = Object.assign({}, EmptyUser);
            }
        } else if(this.loginType === 'MEMBER') {
            if (this.saveId) {
                sessionStorage.setItem(SessionStorageSaveTeacherTypeKey, this.member.type);
                sessionStorage.setItem(SessionStorageSaveEmailKey, this.member.email);
                sessionStorage.setItem(SessionStorageSaveTeacherNickNameKey, this.member.teacherNickName);
                sessionStorage.setItem(SessionStorageSaveNameKey, this.member.name);
            } else {
                localStorage.setItem(LocalStorageSaveIdKey, '');
                sessionStorage.setItem(SessionStorageSaveTeacherTypeKey, '');
                sessionStorage.setItem(SessionStorageSaveEmailKey, '');
                sessionStorage.setItem(SessionStorageSaveTeacherNickNameKey, '');
                sessionStorage.setItem(SessionStorageSaveNameKey, '');
            }

            try {
                this.loginState = State.Pending;
                console.log("AuthStore", "doLogin make member param.", this.member.email);
                const param = {
                    type: this.member.type,
                    email: this.member.email ? this.member.email.trim() : '',
                    teacherNickName: this.member.teacherNickName ? this.member.teacherNickName.trim() : '',
                    userName: this.member.name ? this.member.name.trim() : '',
                    password: this.member.password,
                };
                const response = yield axios.post('/api/v1/authentications/members/signin', param);
                this.loginSuccessAction(response);
            } catch (e) {
                console.log("AuthStore", "doLogin error.", e);
                this.loginState = State.Failed;
                if(e.response && e.response.data && e.response.data.message && e.response.data.message.startsWith("User is not enabled")) {
                    this.isOpenEnabledUserDialog = true;
                }
                this.loginToken = '';
                this.chatToken = '';
                this.loginUser = Object.assign({}, EmptyUser);
            }
        }
    });

    checkLogin = flow(function* checkLogin() {
        this.checkLoginId();
        const token = sessionStorage.getItem(SessionStorageTokenKey);
        const loginType = sessionStorage.getItem(SessionStorageSaveTypeKey);
        this.loginType = loginType;
        if(token) {
            try {
                const response = yield axios.get('/api/v1/authentications/signcheck');
                const user = response.data;

                this.loginState = State.Authenticated;
                this.loginUser = user;
            } catch(e) {
                this.loginState = State.NotAuthenticated;
                this.loginToken = '';
                this.chatToken = '';
                this.loginUser = Object.assign({}, EmptyUser);
            }
        }
    });

    doLogout = flow(function* doLogout() {
        sessionStorage.removeItem(SessionStorageTokenKey);
        sessionStorage.removeItem(SessionStorageChatTokenKey);
        try {
            yield axios.post('/api/v1/authentications/signout');

            console.log(this);
            this.login = Object.assign({}, EmptyLogin);
            this.loginState = State.NotAuthenticated;
            this.loginToken = '';
            this.chatToken = '';
            this.loginUser = Object.assign({}, EmptyUser);
        } catch (e) {
            this.login = Object.assign({}, EmptyLogin);
            this.loginState = State.NotAuthenticated;
            this.loginToken = '';
            this.chatToken = '';
            this.loginUser = Object.assign({}, EmptyUser);
        }

        window.location.href = "/";
    });
}
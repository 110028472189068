import React from "react";
import {Container, Grid, Typography} from "@material-ui/core";
import {withSnackbar} from "notistack";
import withStyles from "@material-ui/core/styles/withStyles";
import Box from "@material-ui/core/Box";
import {withRouter} from "react-router-dom";
import ImagePopupModal from "../../../components/ImagePopupModal";
import {inject, observer} from "mobx-react";

const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
        position: 'relative'
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        marginTop: theme.spacing(2),
    },
    divideContainer: {
        marginBottom: theme.spacing(5),
    },
});

@inject("dashboardStore")
@observer
class DashBoardTemplate extends React.Component {
    render() {
        const { classes, dashboardChannelTable, dashboardChannelInfo, dashboardChannelPlayTime, dashboardChannelAttendStatus, dashboardChannelConnectStatus, dashboardChannelDeviceStatus, dashboardChannelPresentationStatus, dashboardChannelQuestionStatus, dashboardChannelQuizResult, dashboardChannelMemberStatus } = this.props;
        const renderContents = (component, minHeight) => {
            if(!minHeight) minHeight = '0px';
            return (
                <Box border={1} borderRadius={"borderRadius"} borderColor={"grey.500"} style={{margin: '5px', padding: '5px', minHeight: minHeight, position: 'relative', justifyItems: 'center', alignItems: 'center'}}>
                    {component}
                </Box>
            );
        }
        return (
            <Container component="main" maxWidth="lg" className={classes.mainContainer}>
                <div className={classes.appBarSpacer} />
                <div className={classes.mainContent} >
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="h4" component="h2">강의 목록</Typography>
                            {renderContents(dashboardChannelTable)}
                        </Grid>
                        <Grid item xs={12} className={classes.divideContainer}/>

                        <Grid item xs={12}>
                            <Typography variant="h4" component="h2">강의 개설 정보</Typography>
                            {renderContents(dashboardChannelInfo)}
                        </Grid>
                        <Grid item xs={12} className={classes.divideContainer}/>

                        <Grid item xs={5}>
                            <Typography variant="h4" component="h2">강의 진행 시간</Typography>
                            {renderContents(dashboardChannelPlayTime, '430px')}
                        </Grid>
                        <Grid item xs={7}>
                            <Typography variant="h4" component="h2">출석 체크 현황</Typography>
                            {renderContents(dashboardChannelAttendStatus, '430px')}
                        </Grid>
                        <Grid item xs={12} className={classes.divideContainer}/>

                        <Grid item xs={6}>
                            <Typography variant="h4" component="h2">강의 접속 현황</Typography>
                            {renderContents(dashboardChannelConnectStatus)}
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h4" component="h2">접속 기기 현황</Typography>
                            {renderContents(dashboardChannelDeviceStatus)}
                        </Grid>
                        <Grid item xs={12} className={classes.divideContainer}/>

                        <Grid item xs={6}>
                            <Typography variant="h4" component="h2">발표 현황</Typography>
                            {renderContents(dashboardChannelPresentationStatus)}
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="h4" component="h2">질문 현황</Typography>
                            {renderContents(dashboardChannelQuestionStatus)}
                        </Grid>
                        <Grid item xs={12} className={classes.divideContainer}/>

                        <Grid item xs={12}>
                            <Typography variant="h4" component="h2">퀴즈 참여 현황</Typography>
                            {renderContents(dashboardChannelQuizResult)}
                        </Grid>
                        <Grid item xs={12} className={classes.divideContainer}/>

                        <Grid item xs={12}>
                            <Typography variant="h4" component="h2">학생 개별 활동 결과</Typography>
                            {renderContents(dashboardChannelMemberStatus)}
                        </Grid>
                        <Grid item xs={12} className={classes.divideContainer}/>
                    </Grid>
                </div>
                <ImagePopupModal store={this.props.dashboardStore} />
            </Container>
        )
    }
}

export default withSnackbar(withRouter(withStyles(styles) (DashBoardTemplate)));
import {action, computed, flow, observable, toJS} from "mobx";
import axios from "axios";
import _ from "lodash";

const ListState = {
    Loading: 'Loading',
    Loaded: 'Loaded',
    LoadFailed: 'LoadFailed',
};

const AddState = {
    Close: 'Close',
    Open: 'Open',
    Adding: 'Adding',
    Added: 'Added',
    AddFailed: 'AddFailed',
};

const UpdateState = {
    Close: 'Close',
    Loading: 'Loading',
    Loaded: 'Loaded',
    LoadFailed: 'LoadFailed',
    Updating: 'Updating',
    Updated: 'Updated',
    UpdateFailed: 'UpdateFailed',
};

const EmptyGroup = {
    groupId: '',
    userId: 0,
    groupName: '',
    groupDesc: '',
    groupUserCount: 0,
    isOwner: true,
    createdDatetime: '',
    updatedDatetime: '',
};

export default class GroupStore {
    @observable listState = ListState.Loading;
    @observable groupList = [];
    @observable paging = {
        filter: 'all',
    };
    @observable group = {...EmptyGroup};
    @observable lastNewGroupId = '';
    @observable addState = AddState.Close;
    @observable updateState = UpdateState.Close;

    @action changePagingFilter = (filter) => {
        this.paging.filter = filter;
    }

    @action clearAddState = (open) => {
        if(open) {
            this.addState = AddState.Open;
        } else {
            this.addState = AddState.Close;
        }
    }

    @action openAddGroupDialog = (userId) => {
        this.group = Object.assign({}, EmptyGroup);
        this.addState = AddState.Open;
    };

    @action clearUpdateState = (open) => {
        if(open) {
            this.updateState = UpdateState.Loaded;
        } else {
            this.updateState = UpdateState.Close;
        }
    }

    @action changeGroupName = (name) => {
        this.group.groupName = name;
    }

    @action changeGroupDesc = (desc) => {
        this.group.groupDesc = desc;
    }

    @computed get filteredGroupList() {
        const filter = this.paging.filter;

        return _.chain(toJS(this.groupList))
            .filter((group) => {
                if(filter === 'all') {
                    return true;
                } else if(filter === 'my') {
                    return group.owner;
                } else {
                    return !group.owner;
                }
            })
            .orderBy(['createdDatetime'], ['desc'])
            .value();
    }

    @computed get isOpenAddDialog() {
        return this.addState !== AddState.Close;
    }

    @computed get isAdded() {
        return this.addState === AddState.Added;
    }

    @computed get isAddFailed() {
        return this.addState === AddState.AddFailed;
    }

    @computed get isOpenUpdateDialog() {
        return this.updateState !== UpdateState.Close;
    }

    @computed get isUpdated() {
        return this.updateState === UpdateState.Updated;
    }

    @computed get isUpdateFailed() {
        return this.updateState === UpdateState.UpdateFailed;
    }

    loadGroupList = flow(function* loadGroupList(userId) {
        this.groupState = ListState.Loading;

        try {
            const response = yield axios.get(`/api/v1/groups?user-id=${userId}&paging=no`);
            const groups = response.data;
            this.groupList = groups;
            this.groupList.forEach(group => this.loadGroupMemberList(group));
            this.listState = ListState.Loaded;
        } catch (e) {
            this.groupList = [];
            this.listState = ListState.LoadFailed;
        }
    });

    loadGroupMemberList = flow(function* loadGroupMemberList(group) {
        const compareUserNumber = (a, b) => {
            const aNum = a.userNumber.match(/\d+/);
            const bNum = b.userNumber.match(/\d+/);

            if (aNum && bNum) {
                return Number(aNum) - Number(bNum);
            } else {
                return a.userNumber.localeCompare(b.userNumber);
            }
        }

        try {
            const groupResponse = yield axios.get(`/api/v1/groups/users?group-id=${group.groupId}`);
            const groupMembers = groupResponse.data;
            group.memberNames = groupMembers.sort(compareUserNumber) ? groupMembers.sort(compareUserNumber).map(member => member.userName).reduce((a, b) => a ? a + ", "  + b : b ? b : '', '') : '';
        } catch(error) { }
    })


    openUpdateGroupDialog = flow(function* openUpdateGroupDialog(userId, groupId) {
        try {
            const response = yield axios.get(`/api/v1/groups?group-id=${groupId}`);
            const group = _.find(response.data.resultList, (group) => group.groupId === groupId);

            this.group = group;
            this.updateState = UpdateState.Loaded;
        } catch(error) {
            this.updateState = UpdateState.LoadFailed;
        }
    });

    addNewGroup = flow(function* addNewGroup(userId) {
        this.addState = AddState.Adding;

        try {
            const param = {
                userId: userId,
                groupName: this.group.groupName,
                groupDesc: this.group.groupDesc,
            };

            const response = yield axios.post('/api/v1/groups', param);
            const newGroup = response.data;

            this.loadGroupList(userId);
            this.group = Object.assign({}, EmptyGroup);
            this.lastNewGroupId = newGroup.groupId;
            this.addState = AddState.Added;
        } catch(error) {
            this.addState = AddState.AddFailed;
        }
    })

    updateGroup = flow(function* updateGroup(userId) {
        this.updateState = UpdateState.Updating;

        try {
            const param = toJS(this.group);
            yield axios.put('/api/v1/groups', param);

            this.loadGroupList(userId);
            this.group = Object.assign({}, EmptyGroup);
            this.updateState = UpdateState.Updated;
        } catch(error) {
            this.updateState = UpdateState.UpdateFailed;
        }
    })

    removeGroup = flow(function* removeGroup(groupId, userCount, userId) {
        try {
            const param = {
                groupIdList: [groupId],
                userCount : userCount,
            };
            yield axios.post(`/api/v1/groups/delete`, param);

            this.loadGroupList(userId);
        } catch(error) {

        }
    })
}
export const singInFaqData = [
    {
        title: '학생 회원 가입은 어떻게 하나요?',
        content: '학생은 회원가입 할 수 없습니다. 담당교수님께 문의하세요.'
    },
    // {
    //     title: '발표중이였는데, 갑자기 화면이 꺼졌어요. 어떻게 해야하나요?',
    //     content: '놀러가고싶다...'
    // },
    // {
    //     title: '출석부관리는 어디서 보나요?',
    //     content: '맛있는거먹고싶다...'
    // }
];

export const broadcastFaqData = [
    // {
    //     title: '수업하기 faq1',
    //     content: '수업하기1 대답입니다.'
    // },
];

export const groupFaqData = [
    // {
    //     title: '그룹 faq1',
    //     content: '그룹1 대답입니다.'
    // },
]
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogContent, Typography} from "@material-ui/core";

export default function BroadcastScreenOffDialog(props) {
    const { open, handleClose, offHandle} = props;

    const handleOff = () => {
        offHandle();
        handleClose();
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'xs'} >
            <DialogContent>
                <Typography variant="subtitle1" component="h2">
                    방송을 종료하시겠습니까?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button style={{
                            color: 'rgba(0, 0, 0, 0.56)'
                        }}
                        onClick={() => {handleClose()}}
                >
                    취소
                </Button>
                <Button style={{
                            color: '#d64e4e'
                        }}
                        onClick={handleOff}
                >
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    );
}
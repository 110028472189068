/*global _CONST_TYPES*/
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import * as store from "../../../stores/BroadcastStore";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SmsIcon from "@material-ui/icons/Sms";
import Badge from "@material-ui/core/Badge";
import PeopleIcon from "@material-ui/icons/People";
import * as Chat from "../BroadcastChat";
import Button from "@material-ui/core/Button";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Divider from "@material-ui/core/Divider";
import moment from "moment";
import {msgType} from "../BroadcastChat";

const useStyles = () => {
    return makeStyles((theme) => ({
        chatInput: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        chatGrid: {
            borderTop: 'none',
        },
        chatMessage: {
            //flexGrow: 1,
            display: 'block',
            width: '100%',
            height: '100%',
            maxHeight: '100%',
            overflow: 'auto',
            wordBreak: 'break-all',
            // backgroundColor: '#f7f7f7',
            backgroundColor: '#ffffff',
            [theme.breakpoints.down("xs")]:{
                order:3,
            },
        },
        toolbar: theme.mixins.toolbar,
        chatInputDiv: {
            width: '100%',
            padding: '2px 4px',
            display: 'flex',
            borderTop:'1px solid #eee',
            [theme.breakpoints.down("xs")]:{
                order:4,
                borderTop:'1px solid #eee',
                // display:'block',
                // float:'left',
                display: 'flex',
                height:'48px',
                minHeight: '48px',
                lineHeight:'48px'
            },
        },
        msgSendIcon:{
            [theme.breakpoints.down("xs")]:{
                float:'right'
            }
        },
        chatUserList:{
            alignItems:'flex-start',
        },
        chatUserMineList:{
            alignItems:'flex-start',
            flexDirection: 'row-reverse',
            textAlign: 'right',
        },
        chatMsgSystem: {
            position:'relative',
            '&::before':{
                // content:'" "',
                position:'absolute',
                top:'calc(1.5em - 2px)',
                left:0,
                borderTop:'1px solid #e2e2e2',
                width:'100%'
            },
            '& li div span':{
                // color:'#c1010a',
                color:'#333333',
                // color:'#c1010a',
                // backgroundColor:'#fff',
                backgroundColor:'#f0f0f0',
                // border:'1px solid #e2e2e2',
                fontWeight:'800',
                display:'inline-block',
                borderRadius: '50px',
                padding: '4px 20px',
                fontSize: '12px'
            },
        },
        chatMsgPresentation: {
            position:'relative',
            '&::before':{
                // content:'" "',
                position:'absolute',
                top:'calc(1.5em - 2px)',
                left:0,
                borderTop:'1px solid #e2e2e2',
                width:'100%'
            },
            '& li div span':{
                // color:'#c1010a',
                color:'#333333',
                // backgroundColor:'#fff',
                backgroundColor:'#fdea82',
                // border:'1px solid #e2e2e2',
                fontWeight:'800',
                display:'inline-block',
                borderRadius: '50px',
                padding: '4px 20px',
                fontSize: '12px'
            },
        },
        chatMsgQuestion: {
            position:'relative',
            '&::before':{
                // content:'" "',
                position:'absolute',
                top:'calc(1.5em - 2px)',
                left:0,
                borderTop:'1px solid #e2e2e2',
                width:'100%'
            },
            '& li div span':{
                // color:'#c1010a',
                color:'#ffffff',
                // backgroundColor:'#fff',
                backgroundColor:'#c41c1b',
                // border:'1px solid #e2e2e2',
                fontWeight:'800',
                display:'inline-block',
                borderRadius: '50px',
                padding: '4px 20px',
                fontSize: '12px'
            },
        },
        chatUser:{
            '& p:first-child':{
                fontWeight:'600',
                fontSize:'12px',
                color:'rgba(0, 0, 0, 0.54)',
                marginBottom:'4px',
                textAlign: 'left'
            },
            '& p:nth-child(2)':{
                float : 'left'
                // backgroundColor: '#fff',
                // padding: '12px 8px',
                // borderRadius: '0px 10px 10px 10px',
                // boxShadow:'0 1px 4px #eaeaea',
                // display:'inline-block',
                // fontWeight:'500',
                // fontSize:'13px',
                // color:'#333',
            },
            // '& p:nth-child(2) p':{
            //     fontWeight:'500',
            //     fontSize:'13px',
            //     color:'#333',
            //     marginBottom: 0,
            // },
        },
        chatUserMine:{
            paddingRight: '16px',
            '& p:first-child':{
                fontWeight:'600',
                fontSize:'12px',
                color:'rgba(0, 0, 0, 0.54)',
                marginBottom:'4px',
                textAlign: 'right'
            },
            '& p:nth-child(2)':{
                float : 'right'
                // backgroundColor: '#fff',
                // padding: '12px 8px',
                // borderRadius: '0px 10px 10px 10px',
                // boxShadow:'0 1px 4px #eaeaea',
                // display:'inline-block',
                // fontWeight:'500',
                // fontSize:'13px',
                // color:'#333',
            },
            // '& p:nth-child(2) p':{
            //     fontWeight:'500',
            //     fontSize:'13px',
            //     color:'#333',
            //     marginBottom: 0,
            // },
        },
        chatTime:{
            fontSize: '5px',
            float: 'left',
            flexDirection: 'column',
            // marginTop: 'auto',
            alignItems: 'flex-end',
            // display: 'flex',
            color: '#9b9b9b',
            marginRight: '6px',
            marginTop: '6px',
            width: '100%',
            textAlign: 'left',
        },
        chatTimeMine:{
            fontSize: '5px',
            float: 'right',
            flexDirection: 'column',
            // marginTop: 'auto',
            alignItems: 'flex-end',
            // display: 'flex',
            color: '#9b9b9b',
            marginRight: '6px',
            marginTop: '6px',
            width: '100%',
            textAlign: 'right',
        },
        micIcon:{
            fontSize:'1rem',
            color:'#7b7b7b',
            // border:'1px solid #bfbfbf',
            borderRadius:'50px',
            padding:'8px',
            height:'20px',
            width:'20px',
            position:'absolute',
            bottom:theme.spacing(1),
            right:theme.spacing(1),
            boxShadow: '0px 3px 10px 0 rgba(165, 156, 156, 0.56)',
            backgroundColor:'rgba(255,255,255,1)',
            cursor:'pointer',
            zIndex:900,
            '&:hover, &:focus':{
                backgroundColor:'rgba(255,255,255,0.87)',
                boxShadow:'none',
            }
        },
        chatOwnerMsg: {
            backgroundColor: '#3c3c3c',
            padding: '12px 8px',
            borderRadius: '0px 10px 10px 10px',
            boxShadow:'0 1px 4px #eaeaea',
            display:'inline-block',
            fontWeight:'500',
            fontSize:'13px',
            color: '#ffffff',
        },
        chatMemberMsg: {
            backgroundColor: '#fff',
            padding: '12px 8px',
            borderRadius: '0px 10px 10px 10px',
            boxShadow:'0 1px 4px #eaeaea',
            display:'inline-block',
            fontWeight:'500',
            fontSize:'13px',
            color:'#333',
        },
        chatOwnerMineMsg: {
            backgroundColor: '#3c3c3c',
            padding: '12px 8px',
            borderRadius: '10px 0px 10px 10px',
            boxShadow:'0 1px 4px #eaeaea',
            display:'inline-block',
            fontWeight:'500',
            fontSize:'13px',
            color: '#ffffff',
        },
        chatMemberMineMsg: {
            backgroundColor: '#fff',
            padding: '12px 8px',
            borderRadius: '10px 0px 10px 10px',
            boxShadow:'0 1px 4px #eaeaea',
            display:'inline-block',
            fontWeight:'500',
            fontSize:'13px',
            color:'#333',
        },
    }));
};

export default function MobileBroadcastChat(props) {
    const classes = useStyles()();
    const { userInfo, chatMsgList, chatMsg, chatTabSelected, chatIssueCount, inPresentation, inQuestion, chatPresentationObj, chatQuestionObjList, chatUserList, channel} = props;
    const { handleChangeChatMsg, handleChatScroll, handleKeyUpChatMsg, handleChatMsgSend, handleChatTab, handleDoStopQuestion, handleDoEndPresentation, handleDoAnnounce, handleAllowQuestion, handleDoResetPresentation} = props;

    const getIssueCount = () => {
        return chatIssueCount;
    }

    const renderDrawChatUserList = () => {
        const renderButton = (item) => {
            const renderPresentationBtn = () => {
                let disabled = true;
                if(!(inPresentation || inQuestion) && item.presentationAvailableFlag === Chat.presentationAvailableFlag.Y) {
                    disabled = false;
                }

                if(chatPresentationObj && chatPresentationObj.statusCode === _CONST_TYPES._PRESENTATION_STATUS.START && chatPresentationObj.userId === item.userId) {
                    return (
                        <Button onClick={handleDoEndPresentation}>
                            발표중지
                        </Button>
                    );
                }

                return (
                    <Button disabled={disabled}
                            onClick={() => handleDoAnnounce(item.userId)}
                    >
                        발표요청
                    </Button>
                );
            };

            const renderQuestionBtn = () => {
                let disabled = true;
                let isQuestionRequest = false;
                let stopBtnActive = false;
                let stopQObj = null;
                if(chatQuestionObjList) {
                    chatQuestionObjList.forEach(qObj => {
                        if(item.userId === qObj.userId) {
                            isQuestionRequest = (qObj.statusCode === _CONST_TYPES._PRESENTATION_STATUS.REQUEST);
                            if(qObj.statusCode === _CONST_TYPES._PRESENTATION_STATUS.START) {
                                stopBtnActive = true;
                                stopQObj = qObj;
                            }
                        }
                    });
                }

                if(stopBtnActive) {
                    return (
                        <Button onClick={() => handleDoStopQuestion(stopQObj)}>
                            질문중지
                        </Button>
                    );
                }

                if(isQuestionRequest && !(inPresentation || inQuestion) && item.questionAvailableFlag === Chat.questionAvailableFlag.Y) {
                    disabled = false;
                }

                return (
                    <Button disabled={disabled}
                            onClick={() => handleAllowQuestion(item.userId)}
                    >
                        질문수락
                    </Button>
                );
            };

            if(channel && channel.userId === item.userId) {
                return (
                    <ListItemSecondaryAction>
                        <Button onClick={handleDoResetPresentation}>리셋</Button>
                        {/*<CircularProgress size={22}/>*/}
                    </ListItemSecondaryAction>
                );
            }

            if(channel && channel.userId !== item.userId) {
                return (
                    <ListItemSecondaryAction>
                        <React.Fragment>
                            {renderPresentationBtn()}
                            {renderQuestionBtn(item)}
                        </React.Fragment>
                    </ListItemSecondaryAction>
                );
            }
        }

        const renderUserList = () => {
            if(chatUserList) {
                let ownerRender = false;
                return chatUserList.map((item, index) => {
                    if(item.typeCode === store.chatUserType.OWNER) {
                        if(ownerRender) {
                            return null;
                        }
                        ownerRender = true;
                    }
                    return (
                        <div key={"chat-user-list"+index}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar className={classes.avatarSmall}
                                        // style={{backgroundColor: item.chatForegroundColor}}
                                            src={item.chatIconUrl ? item.chatIconUrl : ""} />
                                    {/*    {item.userName.substring(0, 1)}*/}
                                </ListItemAvatar>
                                <ListItemText
                                    primary={item.userName}
                                />
                                {renderButton(item)}
                            </ListItem>
                        </div>
                    );
                })
            }
        }

        return (
            <React.Fragment>
                <Divider/>
                <List dense={true} style={{
                    display: 'block',
                    width: '100%',
                    height: '100%',
                    maxHeight: '100%',
                    overflow: 'auto',
                    wordBreak: 'break-all',
                    order: 3,
                }}>
                    {renderUserList()}
                </List>
            </React.Fragment>
        );
    };

    const renderDrawChat = () => {
        return (
            <React.Fragment>
                <div className={classes.chatMessage} id={"chatMsgArea"}>
                    <List dense={true}>
                        { chatMsgList ? chatMsgList.map((item, index) => {
                            setTimeout(() => handleChatScroll(), 1000);
                            return (
                                <div key={"chat-message"+index}>
                                    {item.msgType === msgType.CHAT_MSG ?
                                        <ListItem className={item.sentUser.userId === userInfo.userId ? classes.chatUserMineList : classes.chatUserList}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    // style={{backgroundColor: item.sentUser.chatForegroundColor}}
                                                        src={item.sentUser.chatIconUrl ? item.sentUser.chatIconUrl : ""} />
                                                {/*    {item.sentUser.userName.substring(0, 1)}*/}
                                            </ListItemAvatar>
                                            <ListItemText
                                                className={item.sentUser.userId === userInfo.userId ? classes.chatUserMine : classes.chatUser}
                                                disableTypography={true}
                                                primary={<Typography variant={"body2"} color={"textSecondary"}>{item.sentUser.userName}</Typography>}
                                                secondary={
                                                    <React.Fragment>
                                                        {/*{item.msg}*/}
                                                        <Typography
                                                            className={item.sentUser.typeCode === store.chatUserType.OWNER
                                                                ? item.sentUser.userId === userInfo.userId ? classes.chatOwnerMineMsg : classes.chatOwnerMsg
                                                                : item.sentUser.userId === userInfo.userId ? classes.chatMemberMineMsg : classes.chatMemberMsg}
                                                            //color="textPrimary"
                                                        >
                                                            {item.msg}
                                                        </Typography>
                                                        <div className={item.sentUser.userId === userInfo.userId ? classes.chatTimeMine : classes.chatTime}>
                                                            {moment().format("a h:mm")}
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            />
                                        </ListItem>
                                        :
                                        <div className={item.msgType === msgType.SYSTEM_MSG ? classes.chatMsgSystem : item.msgType === msgType.PRESENTATION_MSG ? classes.chatMsgPresentation : item.msgType === msgType.QUESTION_MSG ? classes.chatMsgQuestion : classes.chatMsgSystem}>
                                            <ListItem>
                                                <ListItemText align={"center"}>{item.msg}</ListItemText>
                                            </ListItem>
                                        </div>
                                    }
                                </div>
                            );
                        }) : "" }
                    </List>
                </div>

                <div className={classes.chatInputDiv}>
                    <InputBase
                        className={classes.chatInput}
                        value={chatMsg}
                        onChange={handleChangeChatMsg}
                        onKeyUp={handleKeyUpChatMsg}
                        inputProps={{ 'aria-label': 'naked' }}
                        placeholder='메세지를 입력하세요'
                    />
                    <IconButton onClick={handleChatMsgSend} className={classes.msgSendIcon}>
                        <SendIcon/>
                    </IconButton>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className={classes.toolbar} style={{
                // display: 'flex',
                // alignItems: "flex-end",
                borderBottom:'1px solid rgba(0, 0, 0, 0.12)',
                order: 3
            }}>
                <Tabs
                    value={chatTabSelected}
                    variant={"fullWidth"}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="disabled tabs example"
                >
                    <Tab name={store.chatTabSelectType.CHAT} icon={<SmsIcon/>} onClick={handleChatTab} value={store.chatTabSelectType.CHAT} />
                    <Tab name={store.chatTabSelectType.CHAT_USER_LIST} icon={
                        <Badge badgeContent={getIssueCount()} color={"primary"}>
                            <PeopleIcon/>
                        </Badge>
                    } onClick={handleChatTab} value={store.chatTabSelectType.CHAT_USER_LIST} />
                </Tabs>
            </div>
            {(chatTabSelected === store.chatTabSelectType.CHAT) ? renderDrawChat() : renderDrawChatUserList()}
        </React.Fragment>
    );
}
import _ from "lodash";
import {MaxPublishers, FirFreq} from "./RoomManager";

const DefaultCallbacks = {
    onPublishing: undefined,
    onPublished: undefined,
    onLocalStreamAttached: undefined,
    onPublishError: undefined,
    onUnpublishing: undefined,
    onUnpublished: undefined,

    onSubscribing: undefined,
    onRemoteStreamAttached: undefined,
    onRemoteStreamEmpty: undefined,
    onRemoteAudioAttached: undefined,
    onRemoteAudioEmpty: undefined,
    onStarted: undefined,
    onSubscribed: undefined,
    onSubscribeError: undefined,
    onUnsubscribing: undefined,
    onUnsubscribed: undefined,
    onSubstreamChanged: undefined,
}

const PollingTime = 1000;
const MaxRetryCount = 15;

export class RoomVideo {
    constructor(videoMode, janus, roomId, role, userId, displayName, videoRef, callbacks) {
        this.videoMode = videoMode;
        this.janus = janus;
        this.roomId = roomId;
        this.role = role;
        this.userId = userId;
        this.displayName = displayName;
        this.videoRef = videoRef;
        this.pluginHandle = undefined;

        this.callbacks = Object.assign({}, DefaultCallbacks, callbacks);

        this.isAutoPlay = false;
    }

    setAutoPlay = (isAutoPlay) => {
        this.isAutoPlay = isAutoPlay;
    }

    publish = (id, bitrate, simulcast, audio, video, stream, callbacks) => {
        console.log(`[RoomVideo ${this.videoMode}]`, `Publish : id=${id}, bitrate=${bitrate}, simulcast=${simulcast}`, audio, video, stream);

        if(this.callbacks.onPublishing) {
            this.callbacks.onPublishing();
        }

        const that = this;
        console.log(`[RoomVideo ${this.videoMode}]`, 'Attaching videoroom plugin to publisher...');
        this.janus.attach({
            plugin: 'janus.plugin.videoroom',
            opaqueId: id,
            success: (pluginHandle) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin attached successfully', pluginHandle);

                that.pluginHandle = pluginHandle;
                that._createRoom(id);
            },
            error: (error) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin attach failed', error);

                if(that.callbacks.onPublishError) {
                    that.callbacks.onPublishError("Can't attach videoroom plugin");
                }
            },
            consentDialog: (on) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin consentDialog', on);
            },
            mediaState: (medium, on) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin mediaState', medium, on);
            },
            webrtcState: (on) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Vidoeroom plugin webrtcState', on);
            },
            onmessage: (msg, jsep) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin onmessage', msg, jsep);

                const event = msg.videoroom;
                if(event !== undefined && event !== null) {
                    if(event === 'joined') {
                        that._createOffer(bitrate, simulcast, audio, video, stream);
                    } else if(event === 'event') {
                        const errorCode = msg['error_code'];

                        if(errorCode === 436) {
                            console.log(`[RoomVideo ${that.videoMode}]`, "User already exists. so kick...");

                            that._kickAndJoinRoomToPublisher(id);
                        }

                    }

                    if(msg.unpublished) {
                        console.log(`[RoomVideo ${that.videoMode}]`, 'unpublished', msg.unpublished);

                        if(that.callbacks.onReceivedUnpublished) {
                            that.callbacks.onReceivedUnpublished(msg.unpublished);
                        }
                    }

                    if(msg.publishers) {
                        const list = msg.publishers;

                        console.log(`[RoomVideo ${that.videoMode}]`, 'publishers', list);
                        for(let f in list) {
                            let id = list[f]["id"];
                            // let display = list[f]["display"];
                            // let audio = list[f]["audio_codec"];
                            // let video = list[f]["video_codec"];
                            if(that.callbacks.onSubscribe) {
                                that.callbacks.onSubscribe(id);
                            }
                        }
                    }
                }

                if(jsep !== undefined && jsep !== null) {
                    const pluginHandle = that.pluginHandle;

                    pluginHandle.handleRemoteJsep({
                        jsep: jsep,
                        success: () => {
                            console.log(`[RoomVideo ${that.videoMode}]`, 'HandleRemoteJsep sended successfully');

                            if(that.callbacks.onPublished) {
                                that.callbacks.onPublished(id);
                            }
                            if(callbacks && callbacks.onSuccess) {
                                callbacks.onSuccess();
                            }
                        },
                        error: (error) => {
                            console.log(`[RoomVideo ${that.videoMode}]`, 'HandleRemoteJsep send error', error);
                        }
                    });
                }
            },
            onlocalstream: (stream) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin onlocalstream', stream);

                const {videoRef} = that;
                if(videoRef && videoRef.current) {
                    videoRef.current.autoplay = true;
                    //videoRef.current.muted = true;

                    window.Janus.attachMediaStream(videoRef.current, stream);

                    if(that.callbacks.onLocalStreamAttached) {
                        that.callbacks.onLocalStreamAttached(stream);
                    }

                    console.log(`[RoomVideo ${that.videoMode}]`, 'Audio tracks', stream.getAudioTracks());
                    console.log(`[RoomVideo ${that.videoMode}]`, 'Video tracks', stream.getVideoTracks());
                }  else {
                    if(that.callbacks.onLocalStreamAttached) {
                        that.callbacks.onLocalStreamAttached(stream);
                    }
                }
            },
            onremotestream: (stream) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin onremotestream', stream);
            },
            slowLink: (data) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin slowLink', data);
            },
            oncleanup: () => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin oncleanup');

                console.log(`[RoomVideo ${that.videoMode}]`, 'Stopping all tracks in stream', stream);
                if(stream) {
                    stream.getTracks().forEach((track) => {
                        console.log(`[RoomVideo ${that.videoMode}]`, 'Stopping a track', track);
                        track.stop()
                    });
                }

                if(that.callbacks.onUnpublished) {
                    that.callbacks.onUnpublished();
                }
            },
        });
    };

    unpublish = (callbacks) => {
        const {pluginHandle} = this;

        if(pluginHandle) {
            if(this.callbacks.onUnpublishing) {
                this.callbacks.onUnpublishing();
            }

            const that = this;
            const unpublishRequest = {
                message: {
                    request: 'unpublish',
                },
                success: (data) => {
                    console.log(`[RoomVideo ${that.videoMode}]`, 'Unpublish command sended successfully', data);

                    if (callbacks && callbacks.onSuccess) {
                        callbacks.onSuccess();
                    }
                },
                error: (error) => {
                    console.log(`[RoomVideo ${that.videoMode}]`, 'Unpublish command send failed', error);

                }
            };

            console.log(`[RoomVideo ${this.videoMode}]`, 'Sending unpublish command', unpublishRequest.message);
            pluginHandle.send(unpublishRequest);
        }
    };

    subscribe = (feed, callbacks) => {
        if(this.callbacks.onSubscribing) {
            this.callbacks.onSubscribing(feed);
        }

        const {roomId, userId} = this;
        const that = this;

        console.log(`[RoomVideo ${this.videoMode}]`, 'Attaching videoroom plugin to subscriber...');
        this.janus.attach({
            plugin: 'janus.plugin.videoroom',
            opaqueId: userId,
            success: (pluginHandle) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin attached successfully', pluginHandle);

                that.pluginHandle = pluginHandle;
                that._checkRoom(feed);
            },
            error: (error) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin attach failed', error);

                if(that.callbacks.onSubscribeError) {
                    that.callbacks.onSubscribeError();
                }
            },
            consentDialog: (on) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin consentDialog', on);
            },
            mediaState: (medium, on) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin mediaState', medium, on);
            },
            webrtcState: (on) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Vidoeroom plugin webrtcState', on);
            },
            onmessage: (msg, jsep) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin onmessage', msg, jsep);

                const event = msg.videoroom;
                if(event !== undefined && event !== null) {
                    if(event === 'attached') {
                        console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin attached successfully');
                    } else if(event === 'event') {
                        const started = msg['started'];
                        if(started === 'ok') {
                            if(that.callbacks.onStarted) {
                                that.callbacks.onStarted();
                            }
                            return;
                        }

                        const substream = msg['substream'];
                        if((substream !== undefined) && (substream !== null)) {
                            if(that.callbacks.onSubstreamChanged) {
                                that.callbacks.onSubstreamChanged(substream);
                            }
                            return;
                        }
                    }
                }

                if(jsep !== undefined && jsep !== null) {
                    const {pluginHandle} = that;

                    console.log(`[RoomVideo ${that.videoMode}]`, 'Creating answer...', jsep);
                    pluginHandle.createAnswer({
                        media: {
                            audioSend: false,
                            videoSend: false,
                        },
                        jsep: jsep,
                        success: (jsep) => {
                            console.log(`[RoomVideo ${that.videoMode}]`, 'Create answer success', jsep);

                            const startRequest = {
                                message: {
                                    request: 'start',
                                    room: roomId,
                                },
                                jsep: jsep,
                                success: () => {
                                    console.log(`[RoomVideo ${that.videoMode}]`, 'Start command sended successfully');

                                    if(callbacks && callbacks.onSuccess) {
                                        callbacks.onSuccess();
                                    }
                                },
                                error: (error) => {
                                    console.log(`[RoomVideo ${that.videoMode}]`, 'Start command send failed', error);
                                }
                            };

                            console.log(`[RoomVideo ${that.videoMode}]`, 'Sending start command...', startRequest.message);
                            pluginHandle.send(startRequest);
                        },
                        error: (error) => {
                            console.log(`[RoomVideo ${that.videoMode}]`, 'Create answer error', error);

                            if(that.callbacks.onSubscribeError) {
                                that.callbacks.onSubscribeError();
                            }
                        },
                    });
                }
            },
            onlocalstream: (stream) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin onlocalstream', stream);
            },
            onremotestream: (stream) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin onremotestream', stream);

                const {videoRef, isAutoPlay} = that;
                if(videoRef && videoRef.current) {
                    if(isAutoPlay) {
                        videoRef.current.autoplay = true;
                        //videoRef.current.muted = false;
                        window.Janus.attachMediaStream(videoRef.current, stream);

                        if(that.callbacks.onSubscribed) {
                            that.callbacks.onSubscribed(feed, stream);
                        }
                    } else {
                        videoRef.current.autoplay = false;
                        //videoRef.current.muted = true;
                        window.Janus.attachMediaStream(videoRef.current, stream);

                        if (that.callbacks.onRemoteStreamAttached) {
                            that.callbacks.onRemoteStreamAttached(feed, stream);
                        }
                    }

                    const videoTracks = stream.getVideoTracks();
                    if(videoTracks.length > 0) {

                    } else {
                        if (that.callbacks.onRemoteStreamEmpty) {
                            that.callbacks.onRemoteStreamEmpty(feed);
                        }
                    }

                    const audioTracks = stream.getAudioTracks();
                    if(audioTracks.length > 0) {
                    } else {
                        if(that.callbacks.onRemoteAudioEmpty) {
                            that.callbacks.onRemoteAudioEmpty(feed);
                        }
                    }

                    console.log(`[RoomVideo ${that.videoMode}]`, 'Audio tracks', stream.getAudioTracks());
                    console.log(`[RoomVideo ${that.videoMode}]`, 'Video tracks', stream.getVideoTracks());
                }  else {
                    if(that.callbacks.onRemoteStreamAttached) {
                        that.callbacks.onRemoteStreamAttached(feed, stream);
                    }
                }
            },
            slowLink: (data) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin slowLink', data);
            },
            oncleanup: function() {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Videoroom plugin oncleanup');

                if(that.callbacks.onUnsubscribed) {
                    that.callbacks.onUnsubscribed();
                }
            },
        });
    }

    changeFeed = (feed) => {
        const {pluginHandle} = this;

        if(pluginHandle) {
            const that = this;
            const switchRequest = {
                message: {
                    request: 'switch',
                    room: this.roomId,
                    feed: feed,
                },
                success: (data) => {
                    console.log(`[RoomVideo ${that.videoMode}]`, 'Switch command sended successfully', data);
                },
                error: (error) => {
                    console.log(`[RoomVideo ${that.videoMode}]`, 'Switch command send failed', error);
                }
            };

            console.log(`[RoomVideo ${this.videoMode}]`, `Sending switch command... : feed=${feed}`, switchRequest.message);
            pluginHandle.send(switchRequest);
        }
    }

    leave = (callbacks) => {
        const {pluginHandle} = this;

        if(pluginHandle) {
            if(this.callbacks.onUnsubscribing) {
                this.callbacks.onUnsubscribing();
            }

            const that = this;
            const leaveRequest = {
                message: {
                    request: 'leave',
                },
                success: (data) => {
                    console.log(`[RoomVideo ${that.videoMode}]`, 'Leave command sended successfully', data);

                    if (callbacks && callbacks.onSuccess) {
                        callbacks.onSuccess();
                    }
                },
                error: (error) => {
                    console.log(`[RoomVideo ${that.videoMode}]`, 'Leave command send failed', error);
                }
            };

            console.log(`[RoomVideo ${this.videoMode}]`, 'Sending leave command...', leaveRequest.message);
            pluginHandle.send(leaveRequest);
        }
    }

    setSimulcastSubstream = (substream) => {
        const {pluginHandle} = this;

        if(pluginHandle) {
            const that = this;
            const configureRequest = {
                message: {
                    request: 'configure',
                    substream: substream,
                },

                success: (data) => {
                    console.log(`[RoomVideo ${that.videoMode}]`, 'Configure command sended successfully', data);
                },
                error: (error) => {
                    console.log(`[RoomVideo ${that.videoMode}]`, 'Configure command send failed', error);
                }
            }

            console.log(`[RoomVideo ${this.videoMode}]`, `Sending configure command... : substream=${substream}`, configureRequest.message);
            pluginHandle.send(configureRequest);
        }
    }

    _createRoom = (id) => {
        const {roomId, pluginHandle} = this;

        const that = this;
        const existsRequest = {
            message: {
                request: 'exists',
                room: roomId,
            },

            success: (data) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Exists command sended successfully', data);

                if(data.exists) {
                    console.log(`[RoomVideo ${that.videoMode}]`, `Room is already exists : ${roomId}`);

                    this._joinRoomToPublisher(id);
                } else {
                    const createRequest = {
                        message: {
                            request: 'create',
                            room: roomId,
                            videocodec: 'vp9,vp8,h264',
                            publishers: MaxPublishers,
                            permanent: false,
                            description: `OnTheLive ${roomId}`,
                            notify_joining: false,
                            videoorient_ext: false,
                            fir_freq: FirFreq,
                        },
                        success: (data) => {
                            console.log(`[RoomVideo ${that.videoMode}]`, 'Create command sended successfully', data);

                            this._joinRoomToPublisher(id);
                        },
                        error: (error) => {
                            console.log(`[RoomVideo ${that.videoMode}]`, 'Create command send failed', error);

                            if(that.callbacks.onPublishError) {
                                that.callbacks.onPublishError("Create command failed");
                            }
                        }
                    }

                    console.log(`[RoomVideo ${that.videoMode}]`, 'Sending create command...', createRequest.message);
                    pluginHandle.send(createRequest);
                }
            },
            error: (error) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Exists command send failed', error);

                if(that.callbacks.onPublishError) {
                    that.callbacks.onPublishError("Exists command failed");
                }
            }
        };

        console.log(`[RoomVideo ${this.videoMode}]`, 'Sending exists command...', existsRequest.message);
        pluginHandle.send(existsRequest);
    };

    _joinRoomToPublisher = (id) => {
        const {roomId, displayName, pluginHandle} = this;

        const that = this;
        const joinRequest = {
            message: {
                request: 'join',
                room: roomId,
                ptype: 'publisher',
                id: id,
                display: displayName,
            },
            success: () => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Join command sended successfully');
            },
            error: (error) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Join command send failed', error);

                if(that.callbacks.onPublishError) {
                    that.callbacks.onPublishError(error);
                }
            }
        };

        console.log(`[RoomVideo ${this.videoMode}]`, 'Sending join command...', joinRequest.message);
        pluginHandle.send(joinRequest);
    };

    _kickAndJoinRoomToPublisher = (id) => {
        const {roomId, pluginHandle} = this;

        const that = this;
        const kickRequest = {
            message: {
                request: 'kick',
                room: roomId,
                id: id,
            },
            success: (data) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Kick command sended successfully', data);

                if(data.videoroom === 'success') {
                    that._joinRoomToPublisher(id);
                } else {
                    if(data['error_code'] === 428) {
                        console.log(`[RoomVideo ${that.videoMode}]`, 'Kick command sended successfully but failed. Ignore fail because already user not exists');

                        that._joinRoomToPublisher(id);
                    } else {
                        console.log(`[RoomVideo ${that.videoMode}]`, 'Kick command sended successfully but failed');

                        if (that.callbacks.onPublishError) {
                            that.callbacks.onPublishError('Kick command sended successfully but failed');
                        }
                    }
                }
            },
            error: (error) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Kick command send failed', error);

                if(that.callbacks.onPublishError) {
                    that.callbacks.onPublishError(error);
                }
            }
        };

        console.log(`[RoomVideo ${this.videoMode}]`, 'Sending kick command...', kickRequest.message);
        pluginHandle.send(kickRequest);
    };

    _createOffer = (bitrate, simulcast, audio, video, stream) => {
        const {pluginHandle} = this;

        const that = this;
        const media = {
            audioRecv: false,
            videoRecv: false,
        };
        if(audio) {
            media.audioSend = true;
            media.audio = audio;
        } else {
            media.audioSend = false;
        }
        if(video) {
            media.videoSend = true;
            if(video.deviceId === 'screen') {
                media.video = 'screen';
                media.screenshareWidth = video.width;
                media.screenshareHeight = video.height;
                media.captureDesktopAudio = true;
            } else {
                media.video = video;
            }
        } else {
            media.videoSend = false;
        }

        const createOfferRequest = {
            simulcast: simulcast,
            simulcast2: false,
            success: (jsep) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Create offer sended successfully', jsep);

                const publishRequest = {
                    message: {
                        request: "publish",
                        audio: true,
                        video: true,
                        bitrate: bitrate,
                        videocodec: 'vp8',
                    },
                    jsep: jsep,
                    success: (data) => {
                        console.log(`[RoomVideo ${that.videoMode}]`, 'Publish command sended successfully', data);
                    },
                    error: (error) => {
                        console.log(`[RoomVideo ${that.videoMode}]`, 'Publish command send failed', error);

                        if(that.callbacks.onPublishError) {
                            that.callbacks.onPublishError(error);
                        }
                    }
                };

                console.log(`[RoomVideo ${that.videoMode}]`, 'Sending publish command', publishRequest.message);
                pluginHandle.send(publishRequest);
            },
            error: (error) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Create offer send failed', error);

                if(that.callbacks.onPublishError) {
                    that.callbacks.onPublishError(error);
                }
            }
        };

        if(stream) {
            createOfferRequest.media = {
                audioRecv: false,
                videoRecv: false,
                audio: true,
                video: true,
            };
            createOfferRequest.stream = stream;
        } else {
            createOfferRequest.media = media;
        }

        console.log(`[RoomVideo ${this.videoMode}]`, 'Sending create offer...', createOfferRequest.media, createOfferRequest.stream);
        pluginHandle.createOffer(createOfferRequest);
    };

    _checkRoom = (feed) => {
        const {roomId, pluginHandle} = this;
        const that = this;

        let retryCount = 10;
        const doCheckRoom = () => {
            const existsRequest = {
                message: {
                    request: 'exists',
                    room: roomId,
                },
                success: (data) => {
                    console.log(`[RoomVideo ${that.videoMode}]`, 'Exists command sended successfully', data);

                    if(data.exists) {
                        that._findParticipants(feed);
                    } else {
                        if(retryCount < MaxRetryCount) {
                            retryCount++;
                            setTimeout(() => doCheckRoom(), PollingTime);
                        } else {
                            if (that.callbacks.onSubscribeError) {
                                that.callbacks.onSubscribeError();
                            }
                        }
                    }
                },
                error: (error) => {
                    console.log(`[RoomVideo ${that.videoMode}]`, 'Exists command send failed', error);

                    if(retryCount < MaxRetryCount) {
                        retryCount++;
                        setTimeout(() => doCheckRoom(), PollingTime);
                    } else {
                        if (that.callbacks.onSubscribeError) {
                            that.callbacks.onSubscribeError();
                        }
                    }
                }
            };

            console.log(`[RoomVideo ${this.videoMode}]`, 'Sending exists command...', existsRequest.message);
            pluginHandle.send(existsRequest);
        }

        doCheckRoom();
    }

    _findParticipants = (feed) => {
        const {pluginHandle, roomId} = this;
        const that = this;

        let retryCount = 0;
        const doFindParticipants = () => {
            const participantsCommand = {
                message: {
                    request: 'listparticipants',
                    room: roomId,
                },
                success: (data) => {
                    console.log(`[RoomVideo ${that.videoMode}]`, 'Listparticipants command success : data = ', data);

                    const publisher = _.find(data.participants, (p) => p.publisher && String(p.id) === String(feed));
                    if(publisher) {
                        console.log(`[RoomVideo ${that.videoMode}]`, 'Find a publisher', publisher);

                        this._joinRoomToSubscriber(feed);
                    } else {
                        if(retryCount < MaxRetryCount) {
                            retryCount++;
                            setTimeout(() => doFindParticipants(), PollingTime);
                        } else {
                            if(that.callbacks.onSubscribeError) {
                                that.callbacks.onSubscribeError();
                            }
                        }

                    }
                },
                error: (error) => {
                    console.log(`[RoomVideo ${that.videoMode}]`, 'Listparticipants command error : error = ', error);

                    if(retryCount < MaxRetryCount) {
                        retryCount++;
                        setTimeout(() => doFindParticipants(), PollingTime);
                    } else {
                        if(that.callbacks.onSubscribeError) {
                            that.callbacks.onSubscribeError();
                        }
                    }
                },
            };

            console.log(`[RoomVideo ${this.videoMode}]`, 'Sending listparticipants command', participantsCommand.message);
            pluginHandle.send(participantsCommand);
        }

        doFindParticipants();
    };

    _joinRoomToSubscriber = (feed) => {
        const {roomId, pluginHandle} = this;

        const that = this;
        const joinRequest = {
            message : {
                request: 'join',
                room: roomId,
                ptype: 'subscriber',
                feed: feed,
                'close_pc': false,              // For switch command
            },
            success: (data) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Join command sended successfully', data);
            },
            error: (error) => {
                console.log(`[RoomVideo ${that.videoMode}]`, 'Join command send failed', error);

                if(that.callbacks.onSubscribeError) {
                    that.callbacks.onSubscribeError();
                }
            }
        };

        console.log(`[RoomVideo ${this.videoMode}]`, 'Sending join command...', joinRequest.message);
        pluginHandle.send(joinRequest);
    }
}
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#3437CE',
    },
})(Tabs);

const AntTab = withStyles(theme => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#3437CE',
            opacity: 1,
        },
        '&$selected': {
            // color: '#3437CE',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            // color: '#3437CE',
        },
    },
    selected: {},
}))(props => <Tab disableRipple {...props} />);

// const StyledTabs = withStyles({
//     indicator: {
//         display: 'flex',
//         justifyContent: 'center',
//         backgroundColor: 'transparent',
//         '& > div': {
//             maxWidth: 40,
//             width: '100%',
//             backgroundColor: '#635ee7',
//         },
//     },
// })(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

// const StyledTab = withStyles(theme => ({
//     root: {
//         textTransform: 'none',
//         color: '#fff',
//         fontWeight: theme.typography.fontWeightRegular,
//         fontSize: theme.typography.pxToRem(15),
//         marginRight: theme.spacing(1),
//         '&:focus': {
//             opacity: 1,
//         },
//     },
// }))(props => <Tab disableRipple {...props} />);

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    padding: {
        padding: theme.spacing(3),
    },
    demo1: {
        backgroundColor: theme.palette.background.paper,
    },
    demo2: {
        backgroundColor: '#2e1534',
    },
}));

export default function CustomizedTabs(props) {
    const classes = useStyles();
    const handleChange = (event, newValue) => {
        props.setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <div className={classes.demo1}>
                <AntTabs value={props.value} onChange={handleChange} aria-label="faq tab">
                    <AntTab label="회원가입" />
                    <AntTab label="강의하기" />
                    <AntTab label="그룹" />
                </AntTabs>
                <Typography className={classes.padding} />
            </div>
        </div>
    );
}
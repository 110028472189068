import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
    withStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";


const styles = (theme) => ({
    dialog: {
    },
    dialogTitle: {
    },
    dialogContent: {
    },
    grow: {
        flexGrow: 1,
    },
    contentSubTitle: {
        paddingTop: 32,
        paddingLeft: theme.spacing(1),
        fontSize: 20,
    },
    itemHeader: {
    },
    itemBody: {
        paddingTop: theme.spacing(1),
    },
    listContainer: {
        height: 320,
        overflow: 'auto',
        boxShadow:'none',
        border:'1px solid #eee',
        '& ul li > div > div > span ':{
            fontSize:'14px',
            fontWeight:'700',
            color:'#333'
        }
    },
    commandButton: {
        borderRadius: 24,
    },
});

@inject('memberInfoStore')
@observer
class MemberAddDialog extends React.Component {
    handleClose = () => {
        this.props.memberInfoStore.clearDialogState(false);
    }

    render() {
        const { classes } = this.props;
        const { isDialogOpen, groupMemberList } = this.props.memberInfoStore;

        return (
            <Dialog disableBackdropClick fullWidth
                    maxWidth="xs"
                    className={classes.dialog}
                    open={isDialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="member-info-dialog-title" >
                <DialogTitle id="member-info-dialog-title" className={classes.dialogTitle}>
                    학생 정보
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography className={classes.contentSubTitle}>{`그룹에 포함된 학생 (${groupMemberList.length} 명)`}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Paper className={classes.listContainer}>
                                <List dense>
                                    {groupMemberList.map(member =>
                                        <ListItem key={`user-member-${member.email}`} >
                                            <ListItemText primary={member.userName} secondary={member.userNumber}></ListItemText>
                                        </ListItem>
                                    )}
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <div className={classes.grow} />
                    <Button onClick={this.handleClose}>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (MemberAddDialog)));
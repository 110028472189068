import {action, computed, flow, observable} from "mobx";
import axios from "axios";

const MinPassowrd = 4;
const State = {
    Certificating: 'Certificating',
    Certificated: 'Certificated',
    Changing: 'Changing',
    Changed: 'Changed',
    Failed: 'Failed',
}

export default class PassCertificationStore {
    @observable state = State.Certificating;
    @observable email = '';
    @observable password = '';
    @observable passwordConfirm = '';

    @action setCertificationFailed = () => {
        this.state = State.CertificationFailed;
    }

    @action changePassword = (password) => {
        this.password = password;
    }

    @action changePasswordConfirm = (passwordConfirm) => {
        this.passwordConfirm = passwordConfirm;
    }


    @computed get isCertificated() {
        return this.state === State.Certificated;
    }

    @computed get isFailed() {
        return this.state === State.Failed;
    }

    @computed get isChanging() {
        return this.state === State.Changing;
    }

    @computed get isChanged() {
        return this.state === State.Changed;
    }

    @computed get isValidPassword() {
        return this.password.length >= MinPassowrd;
    }

    @computed get isPasswordConfirmed() {
        return this.password === this.passwordConfirm;
    }

    @computed get canChangePassword() {
        const passwordConfirm = this.password === this.passwordConfirm;
        const passwordLen = this.password.length >= MinPassowrd;

        return passwordConfirm && passwordLen;
    }

    certificate = flow(function* certificate(email, authKey) {
        this.email = email;
        this.state = State.Certificating;

        try {
            const param = {
                email: email,
                authKey: authKey
            };
            yield axios.post(`/api/v1/users/password`, param);

            this.state = State.Certificated;
        } catch(error) {
            this.state = State.Failed;
        }
    })

    updatePassword = flow(function* updatePassword() {
        this.state = State.Changing;

        try {
            const param = {
                email: this.email,
                password: this.password,
                userName: ''
            };
            yield axios.put('/api/v1/users/password', param);

            this.state = State.Changed;
        } catch (error) {
            this.state = State.Failed;
        }
    })
}
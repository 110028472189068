export const channelStatusType = {
    'UNKNOWN': 'UNKNOWN',
    'WAIT': 'WAIT',
    'OPENED': 'OPENED',
    'ONAIR': 'ONAIR',
    'OFFAIR': 'OFFAIR',
    'FAILED': 'FAILED',
    'EXITED': 'EXITED',
    'CLOSED': 'CLOSED'
};

export const getChannelStatusType = () => (
    {
        'UNKNOWN': '● UNKNOWN',
        'WAIT': '● 시작예정',
        'OPENED': '● 강의준비중',
        'ONAIR': '● 강의중',
        'OFFAIR': '● 강의완료중',
        'FAILED': '● FAILED',
        'EXITED': '● 강의완료',
        'CLOSED': '● 강의종료'
    }
);

export const settingStatusStyle = (status) => {
    let style = {};
    switch (status) {
        case "● UNKNOWN" :
            style = { color:"#0096ff", borderRadius: "2px", transition: "all .2s ease-out", wordBreak:"keep-all", textAlign: "center"};
            break;

        case "● 시작예정" :
            style = { color:"#737373", borderRadius: "2px", transition: "all .2s ease-out", wordBreak:"keep-all", textAlign: "center" };
            break;

        case "● 강의준비중" :
            style = { color:"#3437CE", borderRadius: "2px", transition: "all .2s ease-out", wordBreak:"keep-all", textAlign: "center" };
            break;

        case "● 강의중" :
            style = { color:"#3437CE", borderRadius: "2px", transition: "all .2s ease-out", wordBreak:"keep-all", textAlign: "center" };
            break;

        case "● 강의완료중" :
            style = { color:"#3437CE", borderRadius: "2px", transition: "all .2s ease-out", wordBreak:"keep-all", textAlign: "center" };
            break;

        case "● FAILED" :
            style = { color:"#0096ff", borderRadius: "2px", transition: "all .2s ease-out", wordBreak:"keep-all", textAlign: "center" };
            break;

        case "● 강의완료" :
            style = { color:"#737373", borderRadius: "2px", transition: "all .2s ease-out", wordBreak:"keep-all", textAlign: "center" };
            break;

        case "● 강의종료" : case "● Booking Cancelation" : case "● 申込取消" : case "● 预定取消" :
            style = { color:"#737373", borderRadius: "2px", transition: "all .2s ease-out", wordBreak:"keep-all", textAlign: "center" };
            break;
        default :
            return "";
    }
    return style;
}
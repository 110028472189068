import React from "react";
import {inject, observer} from "mobx-react";
import Grid from "@material-ui/core/Grid";

@inject("dashboardStore")
@observer
class DashBoardChannelDeviceStatus extends React.Component {
    render() {
        const { deviceStatus} = this.props.dashboardStore;
        this.props.dashboardStore.makeBarChart("device_status_chart_div", deviceStatus);
        return (
            <Grid container
                  // direction="column"
                  justify="center"
                  alignItems="center"
            >
                <Grid item xs={12}>
                    <div id="device_status_chart_div" />
                </Grid>
            </Grid>
        );
    }
}

export default DashBoardChannelDeviceStatus;
import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogContent} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

export default function BroadcastAttendRequestDialog(props) {
    const { open, isFirst, changeIsFirst, handleClose, handleChatAttendance, handleChatAttendanceLate } = props;
    const [value, setValue] = React.useState('all');
    console.log(isFirst);
    return (
        <Dialog open={open} fullWidth={true} maxWidth={'xs'} onClose={handleClose}>
            <DialogTitle>
                출석요청
            </DialogTitle>
            <DialogContent>
                <FormControl component="fieldset">
                    <FormLabel component="legend">출석 요청 유형</FormLabel>
                    <RadioGroup aria-label="attendance" name="attendance" value={value} onChange={event => setValue(event.target.value)}>
                        <FormControlLabel value="all" control={<Radio />} label="전체" />
                        {isFirst ? '' : <FormControlLabel value="late" control={<Radio />} label="미출석자만" />}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button style={{
                    color: 'rgba(0, 0, 0, 0.56)'
                }}
                        onClick={handleClose}
                >
                    취소
                </Button>
                <Button style={{
                    color: '#d64e4e'
                }}
                        onClick={() => {
                            value === 'all' ? handleChatAttendance() : handleChatAttendanceLate();
                            changeIsFirst();
                            handleClose();
                        }}
                >
                    출석
                </Button>
            </DialogActions>
        </Dialog>
    );
}
/*global Janus*/

let serverAddr = null;
let channelId = null;
// let userId = null;
let userName = null;
let pluginHandleObj = null;
// let channelOwnerUserId = null;
// let recordFlag = false;
let videoElId = null;
let isTeacher = false;
let lookAroundRemotePluginHandleList = null;
// let maxPublishers = 100;
let cameraDeviceId = null;

let changePluginHandle = null;
let changeJanusObj = null;
let addLookAroundRemotePluginHandle = null;

let substream = 2;

export const janusRequestType = {
    exists: "exists",
    join: "join",
    create: "create",
    leave: "leave",
    kick: "kick",
    destroy: "destroy",
}

export const janusJoinPType = {
    publisher: "publisher",
    subscriber: "subscriber",
}

export const janusPluginHandlerType = {
    main: "main",
    sub: "sub",
}

export const publishType = {
    SCREEN_SHARING: "SCREEN_SHARING",
    WEB_CAM: "WEB_CAM",
};

let joinType = janusJoinPType.subscriber;
let pType = publishType.WEB_CAM;

export const initJanus = (options) => {
    if(!(options.serverAddr && options.channelId && options.userName && options.userId && options.channelOwnerUserId && options.feed)) {
        console.error('check options');
        console.log(options);
        return null;
    }

    if(typeof options.changeJanusObj !== "function") {
        console.error('options changeJanusObj must be function');
        return null;
    }

    if(typeof options.changePluginHandle !== "function") {
        console.error('options changePluginHandle must be function');
        return null;
    }

    serverAddr = options.serverAddr;
    channelId = options.channelId;
    // userId = options.userId.toString();
    userName = options.userName;
    joinType = options.type;
    pType = options.publishType;
    // channelOwnerUserId = options.channelOwnerUserId.toString();
    // recordFlag = options.recordFlag;
    videoElId = options.videoElId;
    isTeacher = options.isTeacher;
    lookAroundRemotePluginHandleList = options.lookAroundRemotePluginHandleList;
    // maxPublishers = options.maxPublishers;
    cameraDeviceId = options.cameraDeviceId;

    changePluginHandle = options.changePluginHandle;
    changeJanusObj = options.changeJanusObj;
    addLookAroundRemotePluginHandle = options.addLookAroundRemotePluginHandle;

    initCallBack(options.feed.toString(), joinType);
}

export const doUnPublish = (pluginHandle) => {
    if(!pluginHandle) { return null; }

    pluginHandle.send({
        message: {
            request: 'unpublish',
        },
        success: () => {
        },
        error: (err) => {
            console.log('unpublish error');
            console.log(err);
        }
    });
};

export const publishOwnFeed = (pluginHandle, isPlayAudio, isPlayVideo) => {
    if(!pluginHandle) { return null; }
    // const deviceBrowserType = getDeviceBrowserType();
    pluginHandle.createOffer({
        trickle: true,
        media: pType === publishType.SCREEN_SHARING ?
                { video: "screen", audioSend: isPlayAudio, videoRecv: false, audio: isPlayAudio, captureDesktopAudio: true, replaceAudio: true, screenshareWidth: 1280, screenshareHeight: 720 }
                :
                {video: cameraDeviceId ? {deviceId: cameraDeviceId, width: 320, height: 320} : {width: 320, height: 320} , audioRecv: false, videoRecv: false, audioSend: isPlayAudio, videoSend: isPlayVideo }
                ,
        simulcast: pType !== publishType.SCREEN_SHARING,
        //simulcast: true,
        success: function(jsep) {
            let publish = { request: "configure", audio: isPlayAudio, video: isPlayVideo, bitrate: (pType === publishType.SCREEN_SHARING ? 2048 : 125) * 1000};
            publish["videocodec"] = "vp8";
            pluginHandle.send({
                message: publish,
                jsep: jsep
            });
        },
        error: function(error) {
            console.log("WebRTC error:", error);
        }
    });
}

const initCallBack = (feed, joinType) => {
    const janusObj = new Janus({
        server: serverAddr,
        success: () => {
            if(joinType === janusJoinPType.publisher) {
                janusObjAttach(janusObj, feed);
            } else if(joinType === janusJoinPType.subscriber) {
                newRemoteFeed(janusObj, channelId, userName, false, true, feed);
            }
        },
        error: (error) => {
            console.log('look-around janus init error', error);
        },
        destroyed: () => {
        },
        destroyOnUnload: true,
    });
}

export const janusObjAttach = (janusObj, feed) => {
    attach(
        janusObj,
        (pluginHandle) => {
            changePluginHandle(pluginHandle);
            pluginHandleObj = pluginHandle;
            joinRoomProc(pluginHandle, feed);
        },
        (msg, jsep) => {
            console.log(msg);
            if(msg["videoroom"] === "joined") {
                publishOwnFeed(pluginHandleObj, true, true);
            } else if(msg["videoroom"] === "event") {
                if(msg["error_code"] === 436) {
                    kickUserInRoom(pluginHandleObj, feed);
                }
            }

            if(jsep !== undefined && jsep !== null) {
                pluginHandleObj.handleRemoteJsep({jsep: jsep});
            }

            if(msg["publishers"] !== undefined && msg["publishers"] !== null) {
                const list = msg["publishers"];
                for(let f in list) {
                    let id = list[f]["id"];
                    let display = list[f]["display"];
                    // let audio = list[f]["audio_codec"];
                    // let video = list[f]["video_codec"];
                    if(isTeacher) newRemoteFeed(janusObj, channelId, display, true, true, id);
                    else {
                        if(id.startsWith("look-around-screen-share")) {
                            newRemoteFeed(janusObj, channelId, display, true, true, id);
                        }
                    }
                }
            }

            if(msg["unpublished"] !== undefined && msg["unpublished"] !== null) {
                // One of the publishers has unpublished?
                const unpublished = msg["unpublished"];
                if (unpublished === 'ok') {
                    // That's us
                    pluginHandleObj.hangup();
                    return null;
                }
            }
        },
        (stream) => {
            if(isTeacher) {
                if (pType !== publishType.SCREEN_SHARING) playStream(videoElId, pType, stream);
            } else playStream(videoElId, pType, stream);
        },
        (stream) => {
        },
        (error) => {
            console.log(`look-around janus error`);
            console.log(error);
        },
        (on) => {
        },
        (type, on) => {
        },
        (on) => {
        },
        () => {

        }
    );
}

export const newRemoteFeed = (janusObj, channelId, userName, audio, video, feed) => {
    attach(
        janusObj,
        pluginHandle => {
            addLookAroundRemotePluginHandle(feed, pluginHandle);
            const subscribe = { "request": "join", "room": "look-around-" + channelId, "ptype": janusJoinPType.subscriber, "display": userName, "feed": feed, "audio": audio, "video": video, "close_pc": true };
            if(Janus.webRTCAdapter.browserDetails.browser === "safari" && (video === "vp9" || (video === "vp8" && !Janus.safariVp8))) {
                if(video) video = video.toUpperCase();
                subscribe["offer_video"] = false;
            }
            pluginHandle.send({"message": subscribe});
        },
        (msg, jsep) => {
            const event = msg["videoroom"];
            const remotePluginHandleObj = lookAroundRemotePluginHandleList.find((item) => item.feed === feed);
            if(!remotePluginHandleObj) {
                console.error("not remote plugin handle");
                return null;
            }

            let plugin = remotePluginHandleObj.pluginHandle;

            if(msg["error"] !== undefined && msg["error"] !== null) {
                console.log("error");
            } else if(event !== undefined) {
                if(event === "attached") {
                } else if(event === "event") {
                    const sub = msg["substream"];
                    if((sub !== undefined) && (sub != null)) {
                        console.log(`substream sub:  ${sub}`);
                        if(substream > sub) {
                            const configure = {
                                "request": "configure",
                                "substream": substream
                            }
                            setTimeout(() => {
                                plugin.send({message: configure});
                                if(sub !== 0) substream = sub;
                            }, 1000);
                            // console.log(`substream type: ${handlerType}, substeam:  ${substream}, recieved substeam:  ${sub}`);
                        } else if(substream < sub) {
                            substream = sub;
                            // console.log(`substream type: ${handlerType}, set substeam:  ${substream}`);
                        }
                    }
                } else {
                }
            }
            if(jsep !== undefined && jsep !== null) {
                createAnswer(plugin, jsep, "start", false, false, true, true);
            }
        },
        stream => {
        },
        stream => {
            if(isTeacher) {
                if (pType !== publishType.SCREEN_SHARING) playStream(feed, pType, stream);
            } else playStream(feed, pType, stream);
        },
error => {
            console.log(`newRemoteFeed error`);
            console.log(error);
        },
        on => {
        },
        on => {
        },
        on => {
        },
        () => {
        }
    );
}

const attach = (janusObj, success, onMessage, onLocalStream, onRemoteStream, error, webrtcState, mediaState, consentDialog, onCleanup) => {
    janusObj.attach({
        plugin: "janus.plugin.videoroom",
        webrtcState: on => webrtcState(on),
        mediaState: on => mediaState(on),
        consentDialog: on => consentDialog(on),
        success: pluginHandle => success(pluginHandle),
        onmessage: (msg, jsep) => onMessage(msg, jsep),
        onlocalstream: stream => onLocalStream(stream),
        onremotestream: stream => onRemoteStream(stream),
        error: error => error(error),
        oncleanup: () => onCleanup(),
    });
    changeJanusObj(janusObj);
}

const playStream = (videoId, pType, stream) => {
    const parentEl = document.getElementById(isTeacher ? "teacher-video-list" : "student-video-list");
    const videoTag = document.getElementById(videoId);
    const isScreenSharing = videoId.startsWith("look-around-screen-share");
    if(!videoTag) {
        const targetEl = document.createElement('video');
        targetEl.setAttribute("id", videoId);
        parentEl.appendChild(targetEl);
        targetEl.width = isScreenSharing ? 1280 : 320;
        targetEl.height = isScreenSharing ? 720 : 320;
        targetEl.muted = true;
        targetEl.setAttribute("autoplay", "true");
        targetEl.setAttribute("playsinline", "true");
        Janus.attachMediaStream(targetEl, stream);
        const videoTracks = stream.getVideoTracks();
        console.log(videoTracks);
    }
}

const joinRoomProc = (pluginHandle, feed) => {
    pluginHandle.send({
        message: {request: "list"},
        success: (data) => {
        },
        error: (err) => {
        }
    });

    const reqMsgExistsRoom = {
        request : janusRequestType.exists,
        room : "look-around-" + channelId
    }

    pluginHandle.send({
        message: reqMsgExistsRoom,
        success: (data) => {
            if(data.exists) {
                joinRoom(pluginHandle, feed);
            } else {
                roomCreate(pluginHandle, feed);
            }
        }
    });
}

const joinRoom = (pluginHandle, feed) => {
    const requestMsgJoin = {
        request: janusRequestType.join,
        room: "look-around-" + channelId,
        id: feed,
        ptype: janusJoinPType.publisher,
        display: userName,
    };

    pluginHandle.send({message: requestMsgJoin});
};

const roomCreate = (pluginHandle, feed) => {
    const create = {
        request : janusRequestType.create,
        room : "look-around-" + channelId,
        videocodec: 'vp9,vp8,h264',
        audiocodec: 'opus,g722,pcmu,pcma,isac32,isac16',
        bitrate_cap: false,
        bitrate: 2048 * 1000,
        notify_joining: true,
        permanent: false,
        // publishers: maxPublishers,
        publishers: 32,
    };

    pluginHandle.send({
        message: create,
        success: (data) => {
            joinRoom(pluginHandle, feed);
        }
    });
};

export const roomLeave = (pluginHandle) => {
    const leave = {
        request: janusRequestType.leave,
    };

    pluginHandle.send({
        message: leave,
    });
}

export const kickUserInRoom = (pluginHandle, feed) => {
    const kick = {
        request : janusRequestType.kick,
        room : "look-around-" + channelId,
        id : feed
    };

    pluginHandle.send({
        message: kick,
        success: (data) => {
            joinRoom(pluginHandle, feed);
        }
    });
}

export const createAnswer = (pluginHandle, jsep, request, video, audio, videoRecv, audioRecv) => {
    pluginHandle.createAnswer(
        {
            jsep: jsep,
            media: { audioSend: audio, videoSend: video, audioRecv: audioRecv, videoRecv: videoRecv },
            success: jsep => {
                Janus.debug(jsep);
                const body = { "request": request, "room": "look-around-" + channelId };
                pluginHandle.send({"message": body, "jsep": jsep});
            },
            error: error => {}
        }
    );
}

export const getCameraDeviceList = (callback) => {
    Janus.listDevices(devices => {
        const videoDeviceList = devices.filter(device => device.kind === "videoinput" && device.deviceId !== "default");
        if(videoDeviceList && videoDeviceList.length > 0) {
            callback(videoDeviceList);
        }
    });
};

export const roomDestroy = (pluginHandle) => {
    if(!pluginHandle || !channelId) {
        return null;
    }

    pluginHandle.send({
        message: {
            request: janusRequestType.destroy,
            room: "look-around-" + channelId,
        },
        success: (data) => {
        },
        error: (err) => {
            console.log('room destroy error');
            console.log(err);
        }
    });
}
// /*global Janus,adapter, _CONST_TYPES*/
/*global Janus*/
import {janusRoomType} from "../../stores/BroadcastStore";
// import {getDeviceBrowserType} from "../../common/Params";

let serverAddr = null;
let channelId = null;
let userId = null;
let userName = null;
let pluginHandleObj = null;
// let pluginRemoteHandleObj = null;
let channelOwnerUserId = null;
// let recordFlag = false;
let videoElId = null;
let chatObj = null;
let lookAroundRemotePluginHandleList = null;
// let maxPublishers = 100;
let cameraDeviceId = null;

let changePluginHandle = null;
let changeJanusObj = null;
let handleWebRtcPublisherRoomJoined = null;
let addLookAroundRemotePluginHandle = null;

let substream = 2;

export const janusRequestType = {
    exists: "exists",
    join: "join",
    create: "create",
    leave: "leave",
    kick: "kick",
    destroy: "destroy",
}

export const janusJoinPType = {
    publisher: "publisher",
    subscriber: "subscriber",
}

export const janusPluginHandlerType = {
    main: "main",
    sub: "sub",
}

let pType = janusJoinPType.subscriber;

export const initJanus = (options) => {
    if(!(options.serverAddr && options.channelId && options.userName && options.userId && options.channelOwnerUserId && options.feed)) {
        console.error('check options');
        console.log(options);
        return null;
    }

    if(typeof options.changePluginHandle !== "function") {
        console.error('options changePluginHandle must be function');
        return null;
    }

    if(typeof options.changeJanusObj !== "function") {
        console.error('options changeJanusObj must be function');
        return null;
    }

    if(typeof options.handleWebRtcPublisherRoomJoined !== "function") {
        console.error('options getSubPublishType must be function');
        return null;
    }

    serverAddr = options.serverAddr;
    channelId = options.channelId;
    userId = options.userId.toString();
    userName = options.userName;
    pType = options.type;
    channelOwnerUserId = options.channelOwnerUserId.toString();
    // recordFlag = options.recordFlag;
    videoElId = options.videoElId;
    lookAroundRemotePluginHandleList = options.lookAroundRemotePluginHandleList;
    // maxPublishers = options.maxPublishers;
    cameraDeviceId = options.cameraDeviceId;

    changePluginHandle = options.changePluginHandle;
    changeJanusObj = options.changeJanusObj;
    handleWebRtcPublisherRoomJoined = options.handleWebRtcPublisherRoomJoined;
    addLookAroundRemotePluginHandle = options.addLookAroundRemotePluginHandle;

    chatObj = options.chatObj;

    initCallBack(options.feed.toString(), pType);
}

export const doUnPublish = (pluginHandle) => {
    if(!pluginHandle) {
        return null;
    }

    pluginHandle.send({
        message: {
            request: 'unpublish',
        },
        success: () => {
        },
        error: (err) => {
            console.log('unpublish error');
            console.log(err);
        }
    });
    //pluginHandle.
    //unpublish
};

export const publishOwnFeed = (pluginHandle, isPlayAudio, isPlayVideo, feed) => {
    //console.log(isPlay);
    if(!pluginHandle) {
        return null;
    }

    // const deviceBrowserType = getDeviceBrowserType();

    pluginHandle.createOffer({
        trickle: true,
        media: {video: cameraDeviceId ? {deviceId: cameraDeviceId, width: 320, height: 320} : {width: 320, height: 320} , audioRecv: false, videoRecv: false, audioSend: isPlayAudio, videoSend: isPlayVideo },
        // simulcast: deviceBrowserType.deviceType === deviceType.ios,
        simulcast: false,
        success: function(jsep) {
            //BandwidthHandler
            let publish = { request: "configure", audio: isPlayAudio, video: isPlayVideo, bitrate: 10 * 1000};

            publish["videocodec"] = "vp8";
            pluginHandle.send({
                message: publish,
                jsep: jsep
            });

            handleWebRtcPublisherRoomJoined(JSON.stringify({
                roomType: janusRoomType.LOOK_AROUND,
                joinType: janusJoinPType.subscriber,
                closed: false,
                handlerType: null,
                feed: feed,
                publisherUserId: userId,
            }));
        },
        error: function(error) {
            console.log("WebRTC error:", error);
            console.log(chatObj);
        }
    });
}

const initCallBack = (feed, pType) => {
    const janusObj = new Janus({
        server: serverAddr,
        success: () => {
            if(pType === janusJoinPType.publisher) {
                janusObjAttach(janusObj, feed);
            } else if(pType === janusJoinPType.subscriber) {
                newRemoteFeed(janusObj, channelId, userName, false, true, feed);
            }
        },
        error: (error) => {
            console.log('lookAround janus init error');
            console.log(error);
        },
        destroyed: () => {
        },
        destroyOnUnload: true,
    });
}

export const janusObjAttach = (janusObj, feed) => {
    attach(
        janusObj,
        (pluginHandle) => {
            changePluginHandle(pluginHandle);
            pluginHandleObj = pluginHandle;

            joinRoomProc(pluginHandle, feed);
        },
        (msg, jsep) => {
            console.log(msg);
            if(msg["videoroom"] === "joined") {
                publishOwnFeed(pluginHandleObj, true, true, feed);
            } else if(msg["videoroom"] === "event") {
                if(msg["error_code"] === 436) {
                    kickUserInRoom(pluginHandleObj, feed);
                }
            }


            if(jsep !== undefined && jsep !== null) {
                pluginHandleObj.handleRemoteJsep({jsep: jsep});
            }

            if(msg["publishers"] !== undefined && msg["publishers"] !== null) {
                const list = msg["publishers"];
                for(let f in list) {
                    let id = list[f]["id"];
                    let display = list[f]["display"];
                    // let audio = list[f]["audio_codec"];
                    // let video = list[f]["video_codec"];
                    // newRemoteFeed(janusObj, channelId, display, false, true, "lookAround-" + id);
                    if(channelOwnerUserId === userId) newRemoteFeed(janusObj, channelId, display, true, true, id);
                    else {
                        if(channelOwnerUserId === id) {
                            newRemoteFeed(janusObj, channelId, display, true, true, id);
                        }
                    }
                }
            }

            if(msg["unpublished"] !== undefined && msg["unpublished"] !== null) {
                // One of the publishers has unpublished?
                const unpublished = msg["unpublished"];
                if (unpublished === 'ok') {
                    // That's us
                    pluginHandleObj.hangup();
                    return null;
                }
            }
        },
        (stream) => {
            const targetEl = document.getElementById(videoElId);
            if(targetEl) {
                targetEl.muted = true;
                // targetEl.setAttribute("muted", "true");
                targetEl.setAttribute("autoplay", "true");
                targetEl.setAttribute("playsinline", "true");
                Janus.attachMediaStream(targetEl, stream);
            }

            const videoTrack = stream.getVideoTracks();
            console.log(videoTrack);
        },
        (stream) => {
        },
        (error) => {
            console.log(`lookAround janus error`);
            console.log(error);
        },
        (on) => {
        },
        (on) => {
        },
        (on) => {
        },
        () => {

        }
    );
}

export const newRemoteFeed = (janusObj, channelId, userName, audio, video, feed) => {
    attach(
        janusObj,
        pluginHandle => {
            addLookAroundRemotePluginHandle(feed, pluginHandle);
            //changePluginHandle(pluginHandle);

            const subscribe = { "request": "join", "room": "lookAround-" + channelId, "ptype": janusJoinPType.subscriber, "display": userName, "feed": feed, "audio": audio, "video": video, "close_pc": true };
            if(Janus.webRTCAdapter.browserDetails.browser === "safari" && (video === "vp9" || (video === "vp8" && !Janus.safariVp8))) {
                if(video) video = video.toUpperCase();
                subscribe["offer_video"] = false;
            }

            pluginHandle.send({"message": subscribe});
        },
        (msg, jsep) => {
            const event = msg["videoroom"];
            const remotePluginHandleObj = lookAroundRemotePluginHandleList.find((item) => item.feed === feed);
            if(!remotePluginHandleObj) {
                console.error("not remote plugin handle");
                return null;
            }

            let plugin = remotePluginHandleObj.pluginHandle;

            if(msg["error"] !== undefined && msg["error"] !== null) {
                console.log("error");
            } else if(event !== undefined) {
                if(event === "attached") {
                    // plugin.rfid = msg["id"];
                    // plugin.rfdisplay = msg["display"];
                } else if(event === "event") {
                    const sub = msg["substream"];
                    if((sub !== undefined) && (sub != null)) {
                        console.log(`substream sub:  ${sub}`);
                        if(substream > sub) {
                            const configure = {
                                "request": "configure",
                                "substream": substream
                            }
                            setTimeout(() => {
                                plugin.send({message: configure});
                                if(sub !== 0) substream = sub;
                            }, 1000);
                            // console.log(`substream type: ${handlerType}, substeam:  ${substream}, recieved substeam:  ${sub}`);
                        } else if(substream < sub) {
                            substream = sub;
                            // console.log(`substream type: ${handlerType}, set substeam:  ${substream}`);
                        }
                    }
                    // const configured = msg["configured"];
                    // if(configured !== undefined && configured !== null && configured === "ok") {
                    //     console.log("configured");
                    //     const pause = { "request": "pause", "room": channelId };
                    //     pluginHandle.send({"message": pause});
                    //     // const start = { "request": "start", "room": channelId };
                    //     // pluginRemoteHandleObj.send({"message": start});
                    // }
                    //
                    // const paused = msg["paused"];
                    // if(paused !== undefined && paused !== null && paused === "ok") {
                    //     console.log("paused");
                    //     const start = { "request": "start", "room": channelId };
                    //     pluginRemoteHandleObj.send({"message": start});
                    // }
                    //
                    // const started = msg["started"];
                    // if(started !== undefined && started !== null && started === "ok") {
                    //     console.log("started");
                    // }
                } else {
                }
            }
            if(jsep !== undefined && jsep !== null) {
                createAnswer(plugin, jsep, "start", false, false);
            }
        },
        stream => {
        },
        stream => {
            console.log('remote stream');
            const target = document.getElementById("lookAround-" + feed);
            if(target) {
                target.muted = true;
                // target.setAttribute("muted", "true");
                target.setAttribute("autoplay", "true");
                target.setAttribute("playsinline", "true");
                Janus.attachMediaStream(target, stream);
            }

            //broadcastSwitch(stream, target);

            const videoTracks = stream.getVideoTracks();
            console.log(videoTracks);
        },
error => {
            console.log(`newRemoteFeed error`);
            console.log(error);
        },
        on => {
        },
        on => {
        },
        on => {
        },
        () => {
        }
    );
}

const attach = (janusObj, success, onMessage, onLocalStream, onRemoteStream, error, webrtcState, mediaState, consentDialog, onCleanup) => {
    janusObj.attach({
        plugin: "janus.plugin.videoroom",
        webrtcState: on => webrtcState(on),
        mediaState: on => mediaState(on),
        consentDialog: on => consentDialog(on),
        success: pluginHandle => success(pluginHandle),
        onmessage: (msg, jsep) => onMessage(msg, jsep),
        onlocalstream: stream => onLocalStream(stream),
        onremotestream: stream => onRemoteStream(stream),
        error: error => error(error),
        oncleanup: () => onCleanup(),
    });
    changeJanusObj(janusObj);
}

const joinRoomProc = (pluginHandle, feed) => {
    pluginHandle.send({
        message: {request: "list"},
        success: (data) => {
        },
        error: (err) => {
        }
    });

    const reqMsgExistsRoom = {
        request : janusRequestType.exists,
        room : "lookAround-" + channelId
    }

    pluginHandle.send({
        message: reqMsgExistsRoom,
        success: (data) => {
            if(data.exists) {
                // kickUserInRoom(pluginHandle, feed);
                joinRoom(pluginHandle, feed);
                //roomLeave(pluginHandle);
                //joinRoom(pluginHandle, handlerType, feed);
            } else {
                roomCreate(pluginHandle, feed);
            }
        }
    });
}

const joinRoom = (pluginHandle, feed) => {
    const requestMsgJoin = {
        request: janusRequestType.join,
        room: "lookAround-" + channelId,
        id: feed,
        ptype: janusJoinPType.publisher,
        display: userName,
    };

    pluginHandle.send({message: requestMsgJoin});
};

const roomCreate = (pluginHandle, feed) => {
    const create = {
        request : janusRequestType.create,
        room : "lookAround-" + channelId,
        videocodec: 'vp9,vp8,h264',
        audiocodec: 'opus,g722,pcmu,pcma,isac32,isac16',
        bitrate_cap: false,
        bitrate: 125 * 1000,
        notify_joining: true,
        permanent: false,
        // publishers: maxPublishers,
        publishers: 500,
    };

    pluginHandle.send({
        message: create,
        success: (data) => {
            joinRoom(pluginHandle, feed);
        }
    });
};

export const roomLeave = (pluginHandle) => {
    const leave = {
        request: janusRequestType.leave,
    };

    pluginHandle.send({
        message: leave,
    });
}

export const kickUserInRoom = (pluginHandle, feed) => {
    const kick = {
        request : janusRequestType.kick,
        room : "lookAround-" + channelId,
        id : feed
    };

    pluginHandle.send({
        message: kick,
        success: (data) => {
            joinRoom(pluginHandle, feed);
        }
    });
}

export const createAnswer = (pluginHandle, jsep, request, video, audio) => {
    pluginHandle.createAnswer(
        {
            jsep: jsep,
            media: { audioSend: audio, videoSend: video, audioRecv: audio, videoRecv: video },
            // media: { audioRecv: audio, videoRecv: video },
            success: jsep => {
                Janus.debug(jsep);
                const body = { "request": request, "room": channelId };
                pluginHandle.send({"message": body, "jsep": jsep});
            },
            error: error => {}
        }
    );
}

export const getCameraDeviceList = (callback) => {
    Janus.listDevices(devices => {
        const videoDeviceList = devices.filter(device => device.kind === "videoinput" && device.deviceId !== "default");
        if(videoDeviceList && videoDeviceList.length > 0) {
            callback(videoDeviceList);
        }
    });
};

export const roomDestroy = (pluginHandle) => {
    if(!pluginHandle || !channelId) {
        return null;
    }

    pluginHandle.send({
        message: {
            request: janusRequestType.destroy,
            room: "lookAround-" + channelId,
        },
        success: (data) => {
        },
        error: (err) => {
            console.log('room destroy error');
            console.log(err);
        }
    });
}
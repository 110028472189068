import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Breadcrumbs,
    Button,
    Container,
    Divider,
    Drawer,
    FormControl,
    Hidden,
    IconButton,
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import MailIcon from "@material-ui/icons/Mail";
import DraftsIcon from "@material-ui/icons/Drafts";
import CreateIcon from "@material-ui/icons/Create";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Footer from "../../components/Footer";
import {inject, observer} from "mobx-react";
import moment from "moment";
import BoardAddDialog from "./BoardAddDialog";
import BoardDetailDialog from "./BoardDetailDialog";
import BoardUpdateDialog from "./BoardUpdateDialog";


const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        marginTop: theme.spacing(2),
    },

    createButtonContainer: {
        margin: theme.spacing(1),
        textAlign: 'right',
        justifyContent: 'right',
    },
    createButton: {
        fontSize: 14,
        borderRadius: 20,
    },
    createText: {
        color: theme.palette.primary.main,
        '@media screen and (max-width: 600px)': {
            display: 'none'
        },
    },


    helpContainer: {
        width: 350,
        padding: theme.spacing(4),
        boxSizing:'border-box'
    },
    helpLogoContainer: {
        display: 'flex',
        flexDirection:'column',
        textAlign:'center'

    },
    helpLogoImage: {
        padding: theme.spacing(2,0,1)
    },
    helpLogoText: {
        fontSize: 22,
        fontWeight: 800,
        margin:theme.spacing(2,0,1),
    },
    helpPageTitle:{
        textAlign:'center',
        fontSize:'1.1em',
    },
    helpTitleContainer: {
        paddingTop: theme.spacing(2),
        paddingLeft:'20px',
        lineHeight:'1.45',
        '& > li':{
            fontSize:'1em',
            marginBottom:'1.5em',
        },
        '& b':{
            borderBottom:'1px solid #333'
        }
    },
    helpBodyContainer: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        '& > li':{
            fontSize:'0.9em'
        }
    },
    helpIcon:{
        position: 'fixed',
        bottom: '35px',
        right: '35px',
        boxShadow: 'none',
        background:'none',
        width:'85px',
        fontSize: 15,
        '&:hover, &:focus, &:active':{
            background:'none',
            boxShadow:'none',
        },
        '& svg':{
            width:36,
            height:36,
            marginRight:4,
            boxShadow:'0 2px 3px #c1c1c1',
            borderRadius:'50px'
        },
        '& span':{
            color:'rgba(51,51,51,0.56)'
        }
    },
    helpCloseIcon: {
        position: 'absolute',
        right: '1rem',
        top: '1rem',
        color: 'rgba(51,51,51,0.37)',
        width:'40px',
        height:'40px',
        cursor: 'pointer',
    },
});


@inject('boardStore', 'helpStore')
@observer
class BoardList extends React.Component {
    componentDidMount() {
        this.props.boardStore.loadBoardList(this.props.userId, this.props.loginType);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    handleChangePagingPage = (event, newPage) => {
        this.props.boardStore.changePagingPage(newPage, this.props.userId, this.props.loginType);
    }

    handleChangePagingRowsPerPage = (event) => {
        const newValue = parseInt(event.target.value, 10);

        this.props.boardStore.changePagingRowsPerPage(newValue, this.props.userId, this.props.loginType);
    }

    handleOpenAddDialog = () => {
        this.props.boardStore.openAddDialog();
    }

    handleClickBoard = (postId) => {
        this.props.boardStore.openDetailDialog(postId, this.props.userId, this.props.loginType);
    }

    handleClickUpdateBoard = (postId) => {
        this.props.boardStore.openUpdateDialog(postId);
    }

    handleClickDeleteBoard = (postId) => {
        this.props.boardStore.deleteBoard(postId, this.props.userId, this.props.loginType);
    }

    render() {
        const {classes} = this.props;
        const {boardList, paging} = this.props.boardStore;

        return (
            <React.Fragment>
                <Container component="main" maxWidth="lg" className={classes.mainContainer}>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.mainContent} >
                        <Typography variant="h4" component="h2">알림장 목록</Typography>
                        <FormControl margin="dense">
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                                <Link color="inherit" underline="none">알림장</Link>
                                <Link color="inherit" underline="none">알림장 목록</Link>
                            </Breadcrumbs>
                        </FormControl>

                        <div className={classes.createButtonContainer}>
                            {this.props.loginType === 'USER' ?
                                <Button color="primary" variant="outlined" className={classes.createButton} onClick={this.handleOpenAddDialog} ><AddRoundedIcon/><Typography className={classes.createText}>알림 추가하기</Typography></Button>
                                :
                                <div style={{height: 14}}></div>
                            }
                        </div>

                        <TableContainer>
                            <Divider />
                            <Table>
                                <TableHead style={{backgroundColor: '#fafafa'}}>
                                    <TableRow>
                                        <Hidden xsDown>
                                            <TableCell align="center" style={this.props.loginType === 'USER' ? {display: 'none'} : {width: '5%'}}></TableCell>
                                            <TableCell align="left"   style={this.props.loginType === 'USER' ? {width: '30%'} : {width: '35%'}}>제목</TableCell>
                                            <TableCell align="left"   style={this.props.loginType === 'USER' ? {width: '35%'} : {width: '40%'}}>내용</TableCell>
                                            <TableCell align="center" style={this.props.loginType === 'USER' ? {width: '20%'} : {width: '20%'}}>생성일시</TableCell>
                                            <TableCell align="center" style={this.props.loginType === 'USER' ? {width: '15%'} : {display: 'none'}}></TableCell>
                                        </Hidden>
                                        <Hidden smUp>
                                            <TableCell align="center" style={this.props.loginType === 'USER' ? {display: 'none'} : {display: '5%'}}></TableCell>
                                            <TableCell align="left"   style={this.props.loginType === 'USER' ? {width: '45%'}: {display: '50%'}}>제목</TableCell>
                                            <TableCell align="center" style={this.props.loginType === 'USER' ? {width: '45%'}: {display: '45%'}}>생성일시</TableCell>
                                            <TableCell align="center" style={this.props.loginType === 'USER' ? {width: '10%'} : {display: 'none'}}></TableCell>
                                        </Hidden>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {boardList.length > 0 ?
                                        boardList.map((board) =>
                                            <TableRow key={`board-row-${board.postId}`}>
                                                <Hidden xsDown>
                                                    <TableCell align="center" style={this.props.loginType === 'MEMBER' ? {} : {display: 'none'}}>{board.view ? <DraftsIcon /> : <MailIcon color="primary" />}</TableCell>
                                                    <TableCell align="left"><Link href="#" color="inherit" underline="hover" onClick={() => this.handleClickBoard(board.postId)}>{board.title}</Link></TableCell>
                                                    <TableCell align="left"><Link href="#" color="inherit" underline="hover" onClick={() => this.handleClickBoard(board.postId)}>{board.contents}</Link></TableCell>
                                                    <TableCell align="center">{moment(board.createdDatetime).format('LLL')}</TableCell>
                                                    <TableCell align="center" style={this.props.loginType === 'USER' ? {} : {display: 'none'}}>
                                                        <IconButton onClick={() => this.handleClickUpdateBoard(board.postId)}><CreateIcon /></IconButton>
                                                        <IconButton onClick={() => this.handleClickDeleteBoard(board.postId)}><DeleteOutlineIcon /></IconButton>
                                                    </TableCell>
                                                </Hidden>
                                                <Hidden smUp>
                                                    <TableCell align="center" style={this.props.loginType === 'MEMBER' ? {} : {display: 'none'}} >{board.view ? <DraftsIcon /> : <MailIcon />}</TableCell>
                                                    <TableCell align="left" ><Link href="#" color="inherit" underline="always" onClick={() => this.handleClickBoard(board.postId)}>{board.title}</Link></TableCell>
                                                    <TableCell align="center" >{moment(board.createdDatetime).format('LLL')}</TableCell>
                                                    <TableCell align="center" style={this.props.loginType === 'USER' ? {} : {display: 'none'}}>
                                                        <IconButton onClick={() => this.handleClickUpdateBoard(board.postId)}><CreateIcon /></IconButton>
                                                        <IconButton onClick={() => this.handleClickDeleteBoard(board.postId)}><DeleteOutlineIcon /></IconButton>
                                                    </TableCell>
                                                </Hidden>
                                            </TableRow>
                                        )
                                        :
                                        <TableRow>
                                            <Hidden xsDown>
                                                <TableCell colSpan={4} align="center">아직 알림장이 없습니다.</TableCell>
                                            </Hidden>
                                            <Hidden smUp>
                                                <TableCell colSpan={3} align="center">아직 알림장이 없습니다.</TableCell>
                                            </Hidden>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>

                            <TablePagination
                                rowsPerPageOptions={[5, 10, 15, 20, 25]}
                                component="div"
                                count={paging.totalCount}
                                rowsPerPage={paging.rowsPerPage}
                                page={paging.page}
                                labelRowsPerPage=""
                                labelDisplayedRows={({from, to, count}) => `총 ${count}개 중 ${from} - ${to}`}
                                SelectProps={{renderValue: (value) => value + ' 개씩 보기'}}
                                onChangePage={this.handleChangePagingPage}
                                onChangeRowsPerPage={this.handleChangePagingRowsPerPage}
                            />
                        </TableContainer>
                    </div>
                </Container>

                <BoardAddDialog loginType={this.props.loginType} userId={this.props.userId} />
                <BoardDetailDialog />
                <BoardUpdateDialog loginType={this.props.loginType} userId={this.props.userId} />

                <Footer />

                <div>
                    {/*<Fab size="small" className={classes.helpIcon} onClick={() => this.props.helpStore.openHelp()} >*/}
                    {/*    <HelpIcon color="primary" viewBox="2 2 20 20" /> <span>도움말</span>*/}
                    {/*</Fab>*/}

                    <Drawer anchor="right" open={this.props.helpStore.open} onClose={() => this.props.helpStore.closeHelp()} >
                        <CloseRoundedIcon className={classes.helpCloseIcon} onClick={() => this.props.helpStore.closeHelp()}/>

                        <div className={classes.helpContainer}>
                            <div className={classes.helpLogoContainer}>
                                <div className={classes.helpLogoImage}><img src="/images/logo.png" alt="LIVEONAIR" /></div>
                                <Typography className={classes.helpLogoText}>도움말</Typography>
                            </div>

                            <div>
                                <Typography className={classes.helpPageTitle} variant="h5" >알림장 목록</Typography>

                                {this.props.loginType === 'USER' ?
                                    <React.Fragment>
                                        <ol className={classes.helpTitleContainer}>
                                            <li>학생에게 알릴 내용이 있을 경우 알림장을 사용해 보세요
                                                <ul className={classes.helpBodyContainer}>
                                                    <li>'알림장 추가하기' 버튼을 통해 새로운 알림을 생성할 수 있습니다.</li>
                                                    <li>알림에 파일도 첨부할 수 있습니다.</li>
                                                    <li>첨부할 파일이 여러개 일 경우, 알림장을 생성한 후 <CreateIcon /> 버튼을 클릭하면 나타나는 창에서 파일을 업로드 할 수 있습니다.</li>
                                                </ul>
                                            </li>
                                            <li>새로운 알림을 생성하면 학생이 로그인 할 경우 새로운 알림이 있음을 확인하고, 읽어 볼 수 있습니다.
                                            </li>
                                            <li><DeleteOutlineIcon />을 클릭하여, 알림을 삭제 할 수 있습니다.
                                                <ul className={classes.helpBodyContainer}>
                                                    <li>파일이 첨부되어 있거나, 글을 읽은 기록이 존재하는 경우 삭제가 되지 않습니다.</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <ol className={classes.helpTitleContainer}>
                                            <li>살펴 보아야 할 새로운 알림이 있을 경우 상단의 <NotificationsIcon />에 표시가 됩니다.</li>
                                            <li><NotificationsIcon />을 클릭하면 새로운 알림을 살펴 볼 수 있습니다.
                                                <ul className={classes.helpBodyContainer}>
                                                    <li><DraftsIcon />은 이미 읽은 알림을 나타내고</li>
                                                    <li><MailIcon />은 읽지 않은 알림을 나타냅니다.</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </Drawer>
                </div>
            </React.Fragment>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (BoardList)));
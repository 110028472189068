import React from "react";
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const styles = makeStyles((theme) => {
    return {
        // footer:{
        //     position: 'fixed',
        //     bottom: 0,
        //     left: 0,
        //     width: '100%',
        //     backgroundColor:'#fff'
        // },
        // divider: {
        //     marginTop: theme.spacing(2),
        // },
        footer:{
            backgroundColor:'#fff',
        },
        links: {
            padding: theme.spacing(3,1,1),
            marginBottom:theme.spacing(1),
            borderTop:'1px solid #eee'
        },
        linkSeparator: {
            display: 'inline',
            padding: theme.spacing(1),
        },
        ftInfo:{
            '& div' :{
                fontWeight:'600',
                fontSize:'13px',
                color:'#bdbdbd',
                padding:'3px 0',
            }
        },
        copyRight: {
            padding: theme.spacing(1),
            '& p':{
                fontWeight:'600',
                fontSize:'12px',
                color:'rgba(189, 189, 189, 0.87);'
            },
        },
        imagesOf: {
            padding: theme.spacing(1),
            '& p':{
                fontWeight:'600',
                fontSize:'10px',
                color:'rgba(189, 189, 189, 0.87);'
            },
        },
        inline: {
            display: 'inline',
        },
    };
});

export default function Footer(props) {
    const classes = styles();

    return (
        <div className={classes.footer}>
            <div className={props.topMargin === undefined ? classes.divider : ''} style={props.topMargin === undefined ? {} : {marginTop: props.margin}}>
                {/* <Divider /> */}
            </div>
            <div align="center" className={classes.links}>
                {/*<Typography variant="subtitle2" className={classes.inline}><Link color="inherit" href="/terms/terms">이용약관</Link></Typography>*/}
                {/*<Typography variant="subtitle2" className={classes.linkSeparator}>|</Typography>*/}
                {/*<Typography variant="subtitle2" className={classes.inline}><Link color="inherit" href="/terms/privacy">개인정보처리방침</Link></Typography>*/}
                {/*<Typography variant="subtitle2" className={classes.linkSeparator}>|</Typography>*/}
                {/*<Typography variant="subtitle2" className={classes.inline}><Link color="inherit" href="/ticket/list">이용권 구매</Link></Typography>*/}
            </div>
            {/*<div className={classes.ftInfo}>*/}
            {/*    <div align="center">*/}
            {/*    사업자등록번호: 673-86-01271 아이테르정보기술대표이사: 양성욱*/}
            {/*</div>*/}
            {/*<div align="center">*/}
            {/*   서울시 송파구 법원로 114 엠스테이트빌딩 A동 401호 대표전화: 02-6941-1535 이메일주소: support@onthe.live*/}
            {/*</div>*/}
            {/*</div>*/}
            <div align="center" className={classes.copyRight}>
                <Typography color="textSecondary">2020 © Opinion Live & AETHER IT CO., Ltd. All rights reserved.</Typography>
            </div>
            {/*<div align="center" className={classes.imagesOf}>*/}
            {/*    <Typography color="textSecondary">This site use images of <Link color="inherit" href="https://www.flaticon.com/kr/authors/itim2101">itim2101</Link>,<Link color="inherit" href="https://www.flaticon.com/kr/authors/inipagistudio">inipagistudio</Link>, from <Link color="inherit" href="https://www.flaticon.com/kr/">www.flaticon.com</Link></Typography>*/}
            {/*</div>*/}
        </div>
    );
}
import React from "react";
import {inject, observer} from "mobx-react";
import Grid from "@material-ui/core/Grid";

@inject("dashboardStore")
@observer
class DashBoardChannelPresentationStatus extends React.Component {
    render() {
        const { presentationStatus} = this.props.dashboardStore;
        if(presentationStatus.length > 1) this.props.dashboardStore.makeBarChart("presentation_status_chart_div", presentationStatus);
        else this.props.dashboardStore.makeMaterialBarChart("presentation_status_chart_div", presentationStatus);
        return (
            <Grid container
                  // direction="column"
                  justify="center"
                  alignItems="center"
            >
                <Grid item xs={12}>
                    <div id="presentation_status_chart_div" />
                </Grid>
            </Grid>
        );
    }
}

export default DashBoardChannelPresentationStatus;
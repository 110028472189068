import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import IconButton from "@material-ui/core/IconButton";
import RemoveIcon from "@material-ui/icons/Remove";

const styles = (theme) => ({
    dialog: {
    },
    dialogTitle: {
    },
    dialogContent: {
    },
    grow: {
        flexGrow: 1,
    },
    contentSubTitle: {
        paddingTop: 32,
        paddingLeft: theme.spacing(1),
        fontSize: 20,
    },
    itemHeader: {
    },
    itemBody: {
        paddingTop: theme.spacing(1),
    },
    listContainer: {
        height: 320,
        overflow: 'auto',
    },
    commandButton: {
        borderRadius: 24,
    },
});

@inject('shareAddStore')
@observer
class ShareAddDialog extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        // if(this.props.shareAddStore.isAdded) {
        //     this.props.enqueueSnackbar('공유 계정이 추가되었습니다', {
        //         variant: 'info'
        //     });
        //
        //     this.props.shareAddStore.clearDialogState(false);
        // }

        if(this.props.shareAddStore.isAddFailed) {
            this.props.enqueueSnackbar('공유 계정 추가에 실패하였습니다', {
                variant: 'error'
            });

            this.props.shareAddStore.clearDialogState(true);
        }

        if(this.props.shareAddStore.isDeleteFailed) {
            this.props.enqueueSnackbar('공유 계정 삭제에 실패하였습니다', {
                variant: 'error'
            });

            this.props.shareAddStore.clearDialogState(true);
        }
    }

    handleCancel = () => {
        this.props.shareAddStore.clearDialogState(false);
    }

    handleChangeUserFilter = (event) => {
        this.props.shareAddStore.changeUserFilter(event.target.value);
    }

    handleClickAddShare = () => {
        this.props.shareAddStore.addUserToShare();
    }

    handleClickRemoveShare = (email) => {
        this.props.shareAddStore.removeUserFromShare(email);
    }

    render() {
        const { classes } = this.props;
        const { isDialogOpen, isAdding, isDeleting, shareList, userFilter, userHelperText } = this.props.shareAddStore;

        return (
            <Dialog disableBackdropClick fullWidth
                    maxWidth="sm"
                    className={classes.dialog}
                    open={isDialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="share-add-dialog-title" >
                <DialogTitle id="share-add-dialog-title" className={classes.dialogTitle}>
                    그룹공유하기
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={1}>
                        <Grid item xs={9}>
                            <TextField id="upload-comment"
                                       margin="normal"
                                       label={<Typography variant="subtitle1" >공유할 계정 이메일</Typography>}
                                       value={userFilter}
                                       helperText={userHelperText}
                                       error={userHelperText && (userHelperText.length > 0)}
                                       onChange={this.handleChangeUserFilter}
                                       InputLabelProps={{shrink: true, className: classes.itemHeader}}
                                       InputProps={{className: classes.itemBody}}
                                       fullWidth
                            />
                        </Grid>
                        <Grid item xs={3} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingTop: 31}}>
                            <Button variant="outlined" color="primary" disabled={isAdding} onClick={this.handleClickAddShare}>공유하기</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography className={classes.contentSubTitle} style={{paddingBottom: 16}}>{`공유한 계정 (${shareList.length} 명)`}</Typography>
                            <Paper className={classes.listContainer}>
                                <List dense>
                                    {shareList.map(share =>
                                        <ListItem key={`group-member-${share.email}`} >
                                            <ListItemText primary={share.email} secondary={share.userName} />
                                            <ListItemSecondaryAction>
                                                <IconButton size="small" color="primary" disabled={isDeleting} onClick={() => this.handleClickRemoveShare(share.email)} >
                                                    <RemoveIcon />
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <div className={classes.grow} />
                    <Button disabled={isAdding || isDeleting} onClick={this.handleCancel}>
                        닫기
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (ShareAddDialog)));
import React from "react";
import {NavLink, withRouter} from "react-router-dom";

import {
    AppBar,
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    createMuiTheme,
    Grid,
    Hidden,
    IconButton, Menu, MenuItem,
    Popover,
    Toolbar,
    Typography,
    withStyles
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleSharpIcon from "@material-ui/icons/AccountCircleSharp";
import {inject, observer} from "mobx-react";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";

const theme = createMuiTheme();
const logoWidth = 120;
const useStyles = {
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        borderBottom:'1px solid #eee',
        // width: `calc(100%-${drawerWidth}px`,
        // marginRight: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${theme.drawerWidth}px)`,
            // width: `calc(100% - ${theme.drawerWidth + drawerWidth}px)`,
            // marginRight: theme.drawerWidth,
        },
        [theme.breakpoints.down('xs')]: {
        display:'flex',
        borderBottom:'1px solid #ddd'
        },
    },
    appBarWrap:{
        [theme.breakpoints.down('xs')]: {
            // display:'grid',
            // gridAutoColumns: '33.33%',
            '& > button, & > div':{
                display:'flex',
                // justifyContent:'center',
            }
        },
        [theme.breakpoints.down("xs")]: {
            minHeight: '60px'
        }
    },
    toolBar: {
        [theme.breakpoints.down("xs")]: {
            minHeight: '60px'
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            // gridColumn:1,
            order:3,
            justifyContent:'flex-end',
            marginRight:'12px',
            marginLeft:0,
            padding:0
        },
    },
    title: {
        marginLeft: (theme.sideMenuWidth - logoWidth) / 2,
        //paddingLeft: theme.spacing(3),
        flexGrow: 1,
        [theme.breakpoints.down('xs')]: {
            // gridColumn:2,
            order:2,
            paddingLeft: 0,
            // display:'none',
            '& > div':{
                justifyContent:'center',
            },
            '& img':{
                width:'85%'
            }
        },
    },
    iconMenu:{
        [theme.breakpoints.down('xs')]: {
            // gridColumn:3,
            order:1,
            justifyContent:'flex-start',
            width:'auto',
        },
    },
    unreadText: {
        color: '#ffffff',
        width: '100%',
        textAlign: 'center',
    },

    titleBtn: {
        fontSize: '16px',
        fontFamily: 'NanumSquareR',
        '&:hover, &:focus, &:active':{
            background:'none',
        },
        '& span:nth-child(2)':{
            display:'none',
        }
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
        
    },
    loginBtn: {
        borderRadius: 50,
        fontWeight: 400,
        fontSize: 13,
        minWidth: 86,
        height: 32,
        boxShadow:'none',
        '&:hover':{
            boxShadow:'none',
            backgroundColor:'#d5d5e7'
        }
    },
    logOut:{
        fontWeight: 400,
        fontSize: 14,
        borderRadius: 50,
    },
    signUpBtn: {
        backgroundColor: '#191dc5',
        color: '#ffffff',
        borderRadius: 50,
        fontWeight: 400,
        fontSize: 13,
        minWidth: 86,
        height: 34,
        boxShadow:'none',
    },
    boardBadgeIcon: {
        color: '#e7e7e7',
        fontSize: 30,
    },
    myPage:{
        boxShadow: 'none',
        borderRadius:'50px',
        fontWeight:'400',
        fontSize: '13px',
    },
    media:{
        fontSize:300,
    },
    detailInform : {
        background: "#fafafa",
        minWidth: 250,
    },
    modifyButton: {
        borderRadius: '15px',
        boxShadow: 'none',
        width : 60,
        height: 30,
        lineHeight:1,
        fontSize : 14,
    },
    mobileMenuItem: {
        minWidth:'80px',
        justifyContent:'center',
        fontWeight:'600',
    },
    helpIcon:{
        zIndex: theme.zIndex.drawer + 2,
        paddingLeft: '16px',
        boxShadow: 'none',
        background:'none',
        width:'36px',
        fontSize: 15,
        '&:hover, &:focus, &:active':{
            background:'none',
            boxShadow:'none',
        },
        '& svg':{
            width:26,
            height:26,
            marginRight:4,
            // boxShadow:'0 2px 3px #c1c1c1',
            boxShadow:'none',
            borderRadius:'50px'
        },
        '& span':{
            color:'rgba(51,51,51,0.56)'
        }
    },
};

@inject("mainMenuStore", "authStore", "userStore")
@observer
class AdminTopBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            memberLoginHover: false,
            userLoginHover: false,
        };
    }

    handleClickMyPage = (event) => {
        let anchorEl = null;
        if(!Boolean(this.props.mainMenuStore.menuData.myPage.anchorEl)){
            anchorEl = event.currentTarget;
        }
        const name = event.currentTarget.name;
        this.props.mainMenuStore.changeMenuData(name, "anchorEl", anchorEl);
        this.props.userStore.getUserData();
    };

    handleCloseMypagePopover = (e) =>{
        this.props.mainMenuStore.changeMenuData("myPage", "anchorEl", null);
    };

    handleClickInfoModify = () =>{
        this.props.history.push('/mypage');
        this.handleCloseMypagePopover();
    };

    handleToggleMenu = (event) => {
        this.props.setMobileOpen(!this.props.mobileOpen);
        this.props.mainMenuStore.changeMenuBarAnchorEl(event.currentTarget);
    };

    handlePushLink = link => this.props.history.push(link);

    handleClickAnchorMenu = (link) => {
        this.props.mainMenuStore.changeMenuBarAnchorEl(null);
        if(this.props.location.pathname.startsWith("/broadcast/")) {
            if(this.props.location.pathname !== link) {
                if(window.confirm("다른 메뉴로 이동하시겠습니까?")) {
                    this.props.history.push(link);
                }
            }
        } else {
            this.props.history.push(link);
        }
    }

    handleLogout = () => {
        this.props.authStore.changeLoginType('');
        this.props.doLogout(this.props.location.pathname);
    }

    render() {
        const { classes } = this.props;
        const { isLoggedIn, doLogout } = this.props;
        const { menuBarAnchorEl } = this.props.mainMenuStore;
        const { myPage } = this.props.mainMenuStore.menuData;
        const { userData } = this.props.userStore;

        const myPagePopoverOpen = Boolean(myPage.anchorEl);
        const profileUrl = userData.profileUrl || "/images/login-logo.png";

        return (
            <AppBar position="fixed" color={"inherit"} elevation={0} className={classes.appBar}>
                <Toolbar className={classes.appBarWrap}>
                    <IconButton color="inherit" aria-label="open drawer" edge="start" className={classes.menuButton} onClick={this.handleToggleMenu}>
                        <MenuRoundedIcon />
                    </IconButton>
                    <div className={classes.title}>
                        <Grid container alignItems={"center"} spacing={1} className={classes.menuTitle}>
                            <Hidden smUp>
                                <Menu id="simple-menu" anchorEl={menuBarAnchorEl} open={Boolean(menuBarAnchorEl)} onClose={this.handleCloseMenu} keepMounted>
                                    {isLoggedIn &&
                                        <>
                                            <MenuItem className={classes.mobileMenuItem} onClick={() => this.handleClickAnchorMenu('/admin')}>계정</MenuItem>
                                            <MenuItem className={classes.mobileMenuItem} onClick={() => this.handleClickAnchorMenu('/channel/list')}>강의</MenuItem>
                                        </>
                                    }
                                </Menu>
                            </Hidden>

                            <NavLink className={classes.link} to={"/admin"}>
                                <img src="/images/logo.png" alt="" />
                            </NavLink>
                            {isLoggedIn &&
                                <Hidden xsDown>
                                    <Button className={classes.titleBtn} style={{marginLeft: 16}} name="managementMenu" onClick={() => this.handlePushLink('/admin')}> 계정 </Button>
                                    <Button className={classes.titleBtn} name="channelMenu" onClick={() => this.handlePushLink('/channel/list')}> 강의 </Button>
                                </Hidden>
                            }
                        </Grid>
                    </div>

                    {isLoggedIn && (
                        <Grid className={classes.iconMenu}>
                            <Hidden smUp>
                                <IconButton style={{paddingTop: 12, paddingBottom: 12, paddingLeft: 6, paddingRight: 6}} onClick={() => doLogout(this.props.location.pathname)}>
                                    <ExitToAppIcon />
                                </IconButton>

                                <IconButton style={{paddingTop: 12, paddingBottom: 12, paddingLeft: 6, paddingRight: 6}} onClick={this.handleClickMyPage} name="myPage" variant="contained">
                                    <AccountCircleSharpIcon />
                                </IconButton>
                            </Hidden>
                            <Hidden xsDown>
                                <Button className={classes.logOut} style={{marginLeft: 3, marginRight: 3}} onClick={this.handleLogout}>
                                    로그아웃
                                </Button>

                                <Button className={classes.myPage} style={{marginLeft: 3, marginRight: 3}} onClick={this.handleClickMyPage} name="myPage" variant="contained">
                                    마이페이지
                                </Button>
                            </Hidden>
                            <Popover open={myPagePopoverOpen}
                                     onClose={this.handleCloseMypagePopover}
                                     anchorEl={myPage.anchorEl}
                                     disablePortal={false}
                                     transition ="true"
                                     anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
                                     transformOrigin={{vertical: 'top', horizontal: 'right',}}>
                                <Card >
                                    <CardHeader avatar={<Avatar aria-label="recipe" src={profileUrl} className={classes.avatar}></Avatar>}
                                                title={userData.email}
                                    />
                                    <CardContent className={classes.detailInform}>
                                        <Grid container spacing={2} justify="center" direction="row" alignItems="center" >
                                            <Grid item xs={2}>
                                                <Typography variant="body1" color="textSecondary" component="p">
                                                    이름
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography variant="body1" color="textSecondary" component="p">
                                                    {userData.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button variant="outlined" className={classes.modifyButton} onClick={this.handleClickInfoModify}>수정</Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Popover>
                        </Grid>
                        )}
                </Toolbar>
            </AppBar>
        );
    }
}

export default withRouter(withStyles(useStyles) (AdminTopBar));

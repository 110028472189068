import {action, computed, flow, observable, toJS} from "mobx";
import _ from "lodash";
import axios from "axios";
import moment from "moment";

export const State = {
    None: 'None',
    Loading: 'Loading',
    Loaded: 'Loaded',
    LoadError: 'LoadError',
};

const DayFormat = 'YYYY-MM-DD';
const LogPrefix = '[MemberAttendanceStateStore] ';

export default class MemberAttendanceStateStore {
    @observable userId = '';
    @observable lateMinutes = 0;
    @observable selectedDay = moment();
    @observable weekDays = [];

    @observable loadingCancelToken = undefined;
    @observable state = State.None;
    @observable attendanceBook = undefined;


    @action initialize = (userId, lateMinutes) => {
        this.userId = userId;
        this.lateMinutes = lateMinutes;

        this.setSelectedDay();
    }

    @action setSelectedDay = (day) => {
        if(day) {
            this.selectedDay = moment(day);
        } else {
            this.selectedDay = moment();
        }
        const firstWeekDay = moment(this.selectedDay).day(0);

        const days = [];
        for(let i=0; i<7; i++) {
            days.push(moment(firstWeekDay).add(i, 'days'));
        }
        this.weekDays = days;

        this.loadAttendanceBook(this.selectedDay);
    }

    @action toPreviousWeek = () => {
        const newDay = moment(toJS(this.selectedDay)).subtract(7, 'days');

        this.setSelectedDay(newDay);
    }

    @action toNextWeek = () => {
        const newDay = moment(toJS(this.selectedDay)).add(7, 'days');

        this.setSelectedDay(newDay);
    }

    @action clearLoadError = () => {
        this.state = State.None;
    }

    @computed get isLoading() {
        return this.state === State.Loading;
    }

    @computed get memberAttendanceBookColumns() {
        const columns = [
            {
                title: '번호',
                field: 'userNumber',
                type: 'header',
                align: 'center',
            },
            {
                title: '이름',
                field: 'userName',
                type: 'header',
                align: 'left',
            },
        ];

        if(this.attendanceBook && this.attendanceBook.memberAttendances && this.attendanceBook.memberAttendances.length > 0 && this.attendanceBook.memberAttendances[0].channelAttendances) {
            const additionalColumns = _.chain(this.attendanceBook.memberAttendances[0].channelAttendances)
                .map((channelAttendance) => ({startDatetime : channelAttendance.startDatetime, channelId: channelAttendance.channelId, channelName: channelAttendance.channelName}))
                .orderBy(['startDatetime'], ['asc'])
                .map((channel) => ({
                    title: channel.channelName,
                    field: channel.channelId,
                    type: 'value',
                    width: 'auto',
                    align: 'center',
                }))
                .value();

            return columns.concat(additionalColumns);
        } else {
            return columns;
        }
    }

    @computed get memberAttendanceBookRows() {
        if(this.attendanceBook && this.attendanceBook.memberAttendances) {
            return _.chain(this.attendanceBook.memberAttendances)
                .map((memberAttendance) => {
                    const newMemberAttendance = {
                        userId: memberAttendance.userId,
                        userNumber: memberAttendance.userNumber,
                        userName: memberAttendance.userName,
                    }
                    memberAttendance.channelAttendances.forEach((channelAttendance) => {
                        newMemberAttendance[channelAttendance.channelId] = channelAttendance.result;
                    });

                    return newMemberAttendance;
                })
                .orderBy(['userId'], ['asc'])
                .value();
        } else {
            return [];
        }
    }

    loadAttendanceBook = flow(function *loadAttendanceBook(day) {
        this.state = State.Loading;
        this.attendanceBook = undefined;

        console.log(LogPrefix, 'Loading attendance book...', day.format(DayFormat));
        try {
            const {userId, lateMinutes, loadingCancelToken} = this;
            if(loadingCancelToken) {
                console.log(LogPrefix, 'Canceling previous loading attendance book request');
                loadingCancelToken.cancel();
            }

            const dayString = day.format(DayFormat);
            const cancelToken = axios.CancelToken.source();

            this.loadingCancelToken = cancelToken;
            const response = yield axios.get(`/api/v1/attendancebooks?user-id=${userId}&date=${dayString}&late-minutes=${lateMinutes}`, {cancelToken: cancelToken.token});
            console.log(LogPrefix, 'Loading attendance book success', day.format(DayFormat), response);

            this.loadingCancelToken = undefined;
            this.attendanceBook = response.data;
            this.state = State.Loaded;
        } catch(error) {
            if(axios.isCancel(error)) {
                console.log(LogPrefix, 'Loading attendance book canceled', day.format(DayFormat));
            } else {
                this.state = State.LoadError;
                console.warn(LogPrefix, 'Loading attendance book error', day.format(DayFormat));
            }
        }
    });
}
import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

export default function MemberAddConfirmDialog(props) {
    const {open} = props;
    const {closeDialog, onOk} = props;

    return (
        <Dialog open={open}
                onClose={closeDialog}
                maxWidth="xs"
                disableBackdropClick disableEscapeKeyDown fullWidth >
            <DialogTitle>
                학생 추가 확인
            </DialogTitle>
            <DialogContent>
                <DialogContentText>지금 만들어진 그룹에 포함된 학생이 없습니다.</DialogContentText>
                <DialogContentText>지금 학생을 추가하려면 <b>'지금 추가'</b> 버튼을 누르세요.</DialogContentText>
                <DialogContentText>나중에 추가하시려면 <b>'나중에 하기'</b> 버튼을 누르세요. 언제든 '학생변경하기' 기능을 통해 학생을 추가로 포함하거나 제외 할 수 있습니다.</DialogContentText>
            </DialogContent>
            <DialogActions style={{paddingRight: 16}}>
                <Button style={{borderRadius:'25px', paddingLeft: 16, paddingRight: 16}} onClick={closeDialog}>
                    나중에 하기
                </Button>
                <Button color="primary" style={{borderRadius:'25px', paddingLeft: 16, paddingRight: 16}} onClick={onOk}>
                    지금 추가
                </Button>
            </DialogActions>
        </Dialog>
    );
}
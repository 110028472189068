const loginColor = "rgb(52, 55, 206)";
const confirmColor = "rgb(32, 191, 231)";
const modifyColor = "rgb(34, 195, 174)";
const registerColor = "rgb(34, 195, 174)";
const selectColor = "rgb(52, 55, 206)";
const cancelColor = "rgb(177, 154, 218)";
const searchColor = "rgb(34, 195, 174)";
const closeColor = "rgb(235, 229, 224)";
const deleteColor = "rgb(177, 154, 218)";
const downloadColor = "rgb(0, 80, 141)";
const uploadColor = "rgb(0, 80, 141)";

const BUTTON_COLOR = {
    LOGIN: 'LOGIN',
    CONFIRM: 'CONFIRM',
    MODIFY: 'MODIFY',
    REGISTER: 'REGISTER',
    SELECT:'SELECT',
    CANCEL: 'CANCEL',
    SEARCH: 'SEARCH',
    CLOSE: 'CLOSE',
    DELETE: 'DELETE',
    DOWNLOAD: 'DOWNLOAD',
    UPLOAD: 'UPLOAD'
};

export {
    loginColor,
    confirmColor,
    modifyColor,
    registerColor,
    selectColor,
    cancelColor,
    searchColor,
    closeColor,
    deleteColor,
    downloadColor,
    uploadColor,
    BUTTON_COLOR
};
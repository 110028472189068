import React from 'react';
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {Avatar, Box, FormControl, InputLabel, Select, Typography} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {ReactComponent as PersonIcon} from "../../../common/images/PersonIcon.svg";
import {green, grey} from "@material-ui/core/colors";
import Badge from "@material-ui/core/Badge";
import {TINY_GROUP_CHAT_DROP_ZONE_ID} from "../../../stores/TinyGroupStore";

const style = theme => ({
    selectedCount : {
        right: `-8px`,
        top: `-8px`,
        color: 'red',
        background: 'white',
        borderRadius: '50%',
        height: `36px`,
        width: `36px`,
        lineHeight: `36px`,
        position: 'absolute',
        textAlign: 'center',
        fontSize: '0.8rem',
        overflow: 'visible'
    },
    badge:{
        "& .MuiBadge-badge":{
            // minWidth: '13px',
            // height: `30px`,
            // width: `30px`,
            // fontSize: '0.8rem',
            // fontWeight:'300',
            // color: 'red',
            // background: 'white',
            // lineHeight: `30px`,
            // textAlign: 'center',
            // borderRadius: '50%',

            color: 'red',
            background: 'white',

            minWidth: '13px',
            height:'13px',
            fontSize:'3px' ,
            padding:3,
            fontWeight:'300'
        }
    },
    notJoinedAvatar: {
        marginRight: theme.spacing(1),
    },
    joinedAvatar: {
        // backgroundColor: theme.palette.primary.main,
        background:'#9cc779',
        marginRight: theme.spacing(1),
    },
    mediaOn: {
        width: 20,
        height: 20,
        color: green[300],
    },
    mediaOff: {
        width: 20,
        height: 20,
        color: grey[300],
    },

    box: {
        width: '50%',
        "& .MuiLinearProgress-colorPrimary": {
            background: 'grey'
        },
        "& .MuiLinearProgress-root": {
            height: '13px'
        },
        "& .MuiLinearProgress-barColorPrimary": {
            background: 'rgb(44,196,44)',
        }
    },
    selectBox: {
        height: '37px',
        borderColor: '#cbcaca',
        "& .MuiSelect-select:focus": {
            backgroundColor: 'transparent',
        },
    },
});

const TEXT_LENGTH = 6;

@inject("tinyGroupStore")
@observer
class HtmlDragDrop extends React.Component {
    onKeyDown = (event) => {
        if (event.defaultPrevented) return;
        if (event.keyCode !== 13) return;

        event.preventDefault();
        this.performAction(event);
    };

    onClick = (event, memberIndex) => {
        if (event.defaultPrevented) return;
        if (event.button !== 0) return;

        event.preventDefault();
        this.performAction(event, memberIndex);
    };

    wasToggleInSelectionGroupKeyUsed = (event) => {
        const isUsingWindows = navigator.platform.indexOf('Win') >= 0;
        return isUsingWindows ? event.ctrlKey : event.metaKey;
    };

    wasMultiSelectKeyUsed = (event) => event.shiftKey;

    performAction = (event, memberIndex) => {
        const { tinyGroupStore, droppableId } = this.props;

        if (this.wasToggleInSelectionGroupKeyUsed(event)) {
            tinyGroupStore.toggleSelectionInGroup(memberIndex, droppableId);
            return;
        }

        if (this.wasMultiSelectKeyUsed(event)) {
            tinyGroupStore.multiSelectTo(memberIndex, droppableId);
            return;
        }

        tinyGroupStore.toggleSelection(memberIndex, droppableId);
    };

    draggingOver = isOver => {
        const { tinyGroupStore, droppableId } = this.props;

        if(tinyGroupStore.isDropMembers(droppableId)) {
            tinyGroupStore.setMembersDragOver(isOver);
        } else {
            const tinyGroupIndex = tinyGroupStore.getTinyGroupIndex(droppableId);
            tinyGroupStore.setTinyGroupDragOver(tinyGroupIndex, isOver);
        }
    }

    getShortenedText = (length, text) => text.length > length ? text.substr(0, length - 2) + '...' : text;

    render() {
        const { classes, tinyGroupStore, droppableId, isDragOver, members, minHeight } = this.props;

        const getBackgroundColor = ({ isSelected, isGhosting }) => {
            if (isGhosting) { return 'rgba(0,0,0,.2)' }
            if (isSelected) { return '#cbf6a8' }
            return '#eefbe3';
        };

        const getColor = ({ isSelected, isGhosting }) => {
            if (isGhosting) { return 'rgba(40,40,45,.2)' }
            if (isSelected) { return 'rgba(40,40,45,.8)' }
            return 'rgba(40 ,40 ,45 , .8)';
        };

        const getItemStyle = (isDragging, isSelected, isGhosting) => ({
            backgroundColor: getBackgroundColor({isSelected, isGhosting}),
            color: getColor({isSelected, isGhosting}),
            boxShadow: isDragging ? `2px 2px 1px rgba(0,0,0,.2)` : 'none',
            opacity: isGhosting ? '0.8' : '1',
            position: 'relative',
            // border: '2px solid #666',
            // border: '1px solid rgb(102, 102, 102, 0.2)',
            borderRadius: `.5em`,
            cursor: 'move',
            margin: '7px',
            fontFamily: 'NotoSansCJKkr',
            userSelect: 'none',
            // textAlign: "center"
        });

        const selectedCount = tinyGroupStore.selectedMembersInfo.membersIndex.length;

        console.log("tinyGroup member",tinyGroupStore.tinyGroups);

        return (
            <div id={droppableId}
                 style={{
                     width: '93%',
                     // height: `calc( (2em * ${members.length + 1}px) + ${members.length + 1}px + 2em )`,
                     minHeight: minHeight ? minHeight : '50px',
                     // height: 46 * (members.length + 1) + (members.length + 1) + 50,
                     // border: '2px solid #666',
                     border: '1px solid rgb(102, 102, 102, 0.2)',
                     borderRadius: `.5em`,
                     // backgroundColor: isDragOver ? '#fff1ee' : 'transparent'
                     backgroundColor: isDragOver ? 'transparent' : 'transparent',
                     margin:'0 auto'
                 }}
                 onDragEnter={() => this.draggingOver(true)}
                 onDragOver={e => {
                     e.preventDefault();
                     this.draggingOver(true);
                 }}
                 onDragLeave={() => this.draggingOver(false)}
                 onDrop={e => {
                     e.preventDefault();
                     const id = e.dataTransfer.getData('text');
                     e.dataTransfer.clearData('text');
                     const el = document.getElementById(id);
                     const sourceDroppableId = el.parentElement.id;

                     tinyGroupStore.assignment(sourceDroppableId, id, droppableId, tinyGroupStore.destinationIndex);
                 }}
            >
                {members.map((m, i) => {
                    const shouldShowSelection = m.isDragging && selectedCount > 1;
                    return (
                        <React.Fragment key={`tiny-group-member-${m.user.userId}`}>
                            <div id={String(m.user.userId)} draggable
                                style={getItemStyle(m.isDragging, m.isSelected, m.isGhosting)}
                                onDragEnter={() => tinyGroupStore.setDestinationIndex(i)}
                                onDragOver={() => tinyGroupStore.setDestinationIndex(i)}
                                onDragLeave={() => tinyGroupStore.setDestinationIndex(-1)}
                                onDragEnd={() => {
                                    tinyGroupStore.clearDragging();
                                    tinyGroupStore.clearGhosting();
                                }}
                                onDragStart={e => {
                                    tinyGroupStore.dragStart({
                                        source: {
                                            droppableId: droppableId,
                                            index: i,
                                            id: e.target.id
                                        }
                                    });
                                    e.dataTransfer.setData('text', e.target.id);
                                }}
                                onClick={e => this.onClick(e, i)}
                                onKeyDown={(event) => this.onKeyDown(event)}
                            >
                                <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="stretch" p={1}>

                                    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" flexGrow={1}>
                                        {/*<Avatar className={m.joined ? classes.joinedAvatar : classes.notJoinedAvatar}>*/}
                                            {/*{m.joined && m.avatar ?*/}
                                            {/*    <img src={m.avatar} style={{width: 40, height: 40}} alt={""}/>*/}
                                            {/*    :*/}
                                            {/*    <PersonIcon/>*/}
                                            {/*}*/}
                                        <Avatar className={m.joined ? classes.joinedAvatar : classes.notJoinedAvatar}>
                                            <PersonIcon/>
                                        </Avatar>
                                        {/*</Avatar>*/}

                                        <Badge className={classes.badge} invisible={!shouldShowSelection} badgeContent={selectedCount} color="primary">
                                            <Typography variant={'subtitle1'} color="inherit">
                                                {m.user.userName}
                                            </Typography>
                                        </Badge>

                                        <Box className={classes.box} display="flex" flexDirection="row" flexGrow={1} justifyContent="flex-end" alignItems="stretch" pl={2}>
                                            <FormControl variant="outlined" style={{width: '120px'}}>
                                                <InputLabel htmlFor="outlined-tiny-group-move-native" className={classes.selectLabel}>모둠 이동</InputLabel>
                                                <Select
                                                    native
                                                    fullWidth
                                                    value={m.destinationGroupId}
                                                    label="TinyGroup"
                                                    inputProps={{
                                                        name: 'TinyGroup',
                                                        id: 'outlined-tiny-group-move-native',
                                                    }}
                                                    onChange={e => tinyGroupStore.setTinyGroupMoveId(m, droppableId, m.user.userId, e.target.value)}
                                                    className={classes.selectBox}
                                                >
                                                    <option key={`tiny-group-move-none`} value={"none"}>
                                                     ...
                                                    </option>
                                                    {tinyGroupStore.tinyGroups.filter((tg, index) => droppableId !== `dropTinyGroupMembers-${index}`).map(tg =>
                                                        <option key={`tiny-group-move-${tg.id}`} value={tg.id}>
                                                            {tg.name ? this.getShortenedText(TEXT_LENGTH, tg.name) : '알수없는 그룹'}
                                                        </option>
                                                    )}
                                                    {droppableId !== TINY_GROUP_CHAT_DROP_ZONE_ID ?
                                                        <option key={`tiny-group-move-chat-member`} value={TINY_GROUP_CHAT_DROP_ZONE_ID}>
                                                            제외
                                                        </option> : null
                                                    }
                                                </Select>
                                            </FormControl>
                                        </Box>

                                    </Box>

                                    {/*<Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" flexGrow={1}>*/}
                                    {/*    {m.mediaOn ?*/}
                                    {/*        <VideocamIcon className={classes.mediaOn} />*/}
                                    {/*        :*/}
                                    {/*        <VideocamOffIcon className={classes.mediaOff} />*/}
                                    {/*    }*/}
                                    {/*    {m.audioSetup && m.audioOn ?*/}
                                    {/*        <MicIcon className={classes.mediaOn} />*/}
                                    {/*        :*/}
                                    {/*        <MicOffIcon className={classes.mediaOff} />*/}
                                    {/*    }*/}
                                    {/*    {m.soundOn ?*/}
                                    {/*        <VolumeUpIcon className={classes.mediaOn} />*/}
                                    {/*        :*/}
                                    {/*        <VolumeOffIcon className={classes.mediaOff} />*/}
                                    {/*    }*/}
                                    {/*</Box>*/}
                                </Box>
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
        );
    }
}

export default withSnackbar(withRouter(withStyles(style) (HtmlDragDrop)));
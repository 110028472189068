import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
    Button, CircularProgress,
    Container, DialogActions, FormControlLabel,
    TextField,
    Typography
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {inject, observer} from "mobx-react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";

const style = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        // marginTop: theme.spacing(6),
        marginBottom:'40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:'center',
        // padding: '20px 80px 20px 80px',
        // border:'1px solid #ddd',
        padding:'0',
        border:'0 none',
        //marginBottom:theme.spacing(2),
        backgroundColor:'#fff',
        [theme.breakpoints.down("sm")]: {
            padding:'0',
            border:'0 none',
        },
    },
    mainTitle: {
        textAlign: 'center',
        // margin: theme.spacing(2, 0, 0),
        // padding: theme.spacing(1, 0),
        fontWeight:'700'
    },
    mainContent: {
        width: '100%',
        // marginTop: theme.spacing(1),
    },
    mainComment:{
        textAlign: 'right',
    },
    checkAgreeMargin: {
        marginTop: theme.spacing(3),
    },
    titleArea:{
        position:'relative'
    },
    checkAgreeTotal:{
        padding:'0 4px 0 0',
    },
    termsCaption:{
        fontSize:'12px',
        color:'#c1c1c1',
        fontWeight:'600'
    },
    gutterMargin:{
        margin:theme.spacing(3, 0, 2),
        '& input[type=password]':{
            fontFamily:'initial',
        }
    },
    okButton: {
        borderRadius: 24,
    },
    successContainer: {
        textAlign: 'center',
    },
    successTitle: {
        fontSize: 38,
        color: '#333333',
        paddingTop: theme.spacing(6),
    },
    successHeader: {
        fontSize: 16,
        color: '#333333',
        paddingTop: theme.spacing(5),
    },
    successHeader2: {
        fontSize: 16,
        color: '#333333',
    },
    successBody: {
        fontSize: 14,
        color: '#b7b7b7',
        paddingTop: theme.spacing(1),
    },
    successButton: {
        borderRadius: 24,
        fontSize: 16,
        width: 200,
        height: 48,
        marginTop: theme.spacing(4),
    },
});

const decodeURLParams = search => {
    const hashes = search.slice(search.indexOf("?") + 1).split("&");
    return hashes.reduce((params, hash) => {
        const split = hash.indexOf("=");

        if (split < 0) {
            return Object.assign(params, {
                [hash]: null
            });
        }

        const key = hash.slice(0, split);
        const val = hash.slice(split + 1);

        return Object.assign(params, { [key]: decodeURIComponent(val) });
    }, {});
};

@inject('signUpStore', 'adminStore')
@observer
class SignUpDialog extends React.Component {
    componentDidMount() {
        const params = decodeURLParams(this.props.location.search);

        this.props.signUpStore.initialize(params.email);
        this.props.signUpStore.getServerMode();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.signUpStore.isSignUpFailed) {
            this.props.enqueueSnackbar('회원 가입에 실패하였습니다.', {
                variant: 'error'
            });

            this.props.signUpStore.clearState();
        }

        if(this.props.signUpStore.isNotAvailableEmail) {
            this.props.enqueueSnackbar('이미 존재하는 이메일 혹은 별칭 입니다.', {
                variant: 'error'
            });

            this.props.signUpStore.clearState();
        }
    }

    handleChangeTypeCode = (event) => {
        this.props.signUpStore.changeNewMemberTypeCode(event.target.value);
    }

    handleChangeEmail = (event) => {
        this.props.signUpStore.changeNewMemberEmail(event.target.value);
    }

    handleChangePassword = (event) => {
        this.props.signUpStore.changeNewMemberPassword(event.target.value);
    }

    handleChangePasswordConfirm = (event) => {
        this.props.signUpStore.changeNewMemberPasswordConfirm(event.target.value);
    }

    handleChangeUserName = (event) => {
        this.props.signUpStore.changeNewMemberUserName(event.target.value);
    }

    handleChangeNickName = (event) => {
        this.props.signUpStore.changeNewMemberNickName(event.target.value);
    }

    handleChangeAllAgreements = (event) => {
        this.props.signUpStore.changeAgreementsAll(event.target.checked);
    }

    handleChangeServiceAgreements = (event) => {
        this.props.signUpStore.changeAgreementsService(event.target.checked);
    }

    handleChangePrivacyAgreements = (event) => {
        this.props.signUpStore.changeAgreementsPrivacy(event.target.checked);
    }

    handleClickOK = () => {
        this.props.signUpStore.doSignUpNoCaptcha(() => this.props.adminStore.loadUserList());
        this.props.handleClose();
    }

    render() {
        const { classes, open, handleClose } = this.props;
        const { isEmailInputed, isValidEmail, isValidPassword, isPasswordConfirmed, isValidUsername, isValidNickName, isPending, canAdminSignUp, newMember } = this.props.signUpStore;
        return (
            <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
                <DialogContent>
                    <Container component="main" maxWidth="sm">
                        <div className={classes.paper}>
                            {/*<img src={"/images/login-logo.png"} alt={""}/>*/}
                            <form className={classes.mainContent}>
                                <Typography className={classes.mainTitle} component="h1" variant="h3" >
                                    계정생성
                                </Typography>

                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" className={classes.mainComment}>* 필수입력</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1">
                                            계정타입
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RadioGroup value={newMember.typeCode} onChange={this.handleChangeTypeCode} row>
                                            <FormControlLabel style={{marginLeft:0, marginRight: 0}} value={'USER'} control={<Radio />} label={'교수'} />
                                            <FormControlLabel style={{marginLeft:0, marginRight: 0}} value={'ADMIN'} control={<Radio />} label={'관리자'} />
                                        </RadioGroup>
                                    </Grid>
                                </Grid>

                                <TextField id="email"
                                           name="email"
                                           label="이메일 주소"
                                           margin="dense"
                                           value={newMember.email}
                                           onChange={this.handleChangeEmail}
                                           className={classes.gutterMargin}
                                           InputLabelProps={{shrink: true}}
                                           helperText={isValidEmail ? '' : '이메일 형식이 아닙니다.'}
                                           autoFocus={!isEmailInputed} required fullWidth/>

                                <TextField id="password"
                                           type="password"
                                           name="password"
                                           label="비밀번호"
                                           margin="dense"
                                           autoComplete={"off"}
                                           value={newMember.password}
                                           onChange={this.handleChangePassword}
                                           className={classes.gutterMargin}
                                           InputLabelProps={{shrink: true}}
                                           helperText={isValidPassword ? '' : '최소 4 글자 이상을 입력해 주세요.'}
                                           autoFocus={!!isEmailInputed} required fullWidth/>
                                <TextField id="passwordConfirm"
                                           type="password"
                                           name="passwordConfirm"
                                           label="비밀번호 확인"
                                           margin="dense"
                                           autoComplete={"off"}
                                           value={newMember.passwordConfirm}
                                           onChange={this.handleChangePasswordConfirm}
                                           className={classes.gutterMargin}
                                           InputLabelProps={{shrink: true}}
                                           helperText={isPasswordConfirmed ? '' : '패스워드가 일치하지 않습니다.'}
                                           required fullWidth/>
                                <TextField id="name"
                                           name="name"
                                           label="이름"
                                           margin="dense"
                                           value={newMember.userName}
                                           onChange={this.handleChangeUserName}
                                           className={classes.gutterMargin}
                                           InputLabelProps={{shrink: true}}
                                           helperText={isValidUsername ? '' : '최소 2 글자 이상을 입력해 주세요.'}
                                           required fullWidth/>
                                <TextField id="nickName"
                                           name="nickName"
                                           label="별칭"
                                           margin="dense"
                                           value={newMember.nickName}
                                           onChange={this.handleChangeNickName}
                                           className={classes.gutterMargin}
                                           InputLabelProps={{shrink: true}}
                                           helperText={isValidNickName ? '' : '최소 2 글자 이상을 입력해 주세요.'}
                                           required fullWidth/>
                                <Grid item xs={12} align={"center"}>
                                    <Button color="primary" variant="contained" className={classes.okButton}
                                            disabled={(!canAdminSignUp) || (isPending)}
                                            onClick={this.handleClickOK}
                                            fullWidth>
                                        {isPending ? <CircularProgress size={16}/> : '계정생성'}
                                    </Button>
                                </Grid>
                            </form>
                        </div>
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button style={{
                        color: 'rgba(0, 0, 0, 0.56)'
                    }}
                            onClick={() => {handleClose()}}
                    >
                        취소
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withSnackbar(withRouter(withStyles(style) (SignUpDialog)));
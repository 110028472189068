
const LogPrefix = '[RoomAudio] ';

const DefaultCallbacks = {
    onJoining: undefined,
    onJoined: undefined,
    onJoinError: undefined,
    onExit: undefined,
    onParticipant: undefined,
    onLeaveParticipant: undefined,
    onPublishing: undefined,
    onPublished: undefined,
    onPublishError: undefined,
};

const OperationState = {
    None: 'None',
    Joining: 'Joining',
    Joined: 'Joined',
    JoinError: 'JoinError',
    Publishing: 'Publishing',
    Published: 'Published',
    PublishError: 'PublishError',
};


export default class RoomAudio {
    constructor(janus, audioRef, callbacks) {
        this.janus = janus;
        this.audioRef = audioRef;
        this.pluginName = 'janus.plugin.audiobridge';
        this.pluginHandle = undefined;

        this.roomId = undefined;
        this.userId = undefined;

        this.isPlayValue = false;
        this.isMutedValue = true;
        this.operationState = OperationState.None;
        this.callbacks = Object.assign({}, DefaultCallbacks, callbacks);
        this.callbackMap = {
            None: this.callbacks.onExit,
            Joining: this.callbacks.onJoining,
            Joined: this.callbacks.onJoined,
            JoinError: this.callbacks.onJoinError,
            Publishing: this.callbacks.onPublishing,
            Published: this.callbacks.onPublished,
            PublishError: this.callbacks.onPublishError,
        }
    }

    initialize = () => {
        this.pluginHandle = undefined;

        this.roomId = undefined;
        this.userId = undefined;

        this.isPlayValue = false;
        this.isMutedValue = true;
        this.operationState = OperationState.None;
    }

    _handleCallback = (callbackName, ...args) => {
        const callback = this.callbacks[callbackName];
        if(callback) {
            try {
                callback(...args);
            } catch (error) {
                console.warn(LogPrefix, `Error in ${callbackName} callback`, error);
            }
        } else {
            console.warn(LogPrefix, `Can not found ${callbackName} callback`);
        }
    }

    _handleCallbackWithOperationState = (operationState, ...args) => {
        this.operationState = operationState;

        const callback = this.callbackMap[operationState];
        if(callback) {
            try {
                callback(...args);
            } catch(error) {
                console.warn(LogPrefix, `Error in ${operationState} callback`, error);
            }
        } else {
            console.warn(LogPrefix, `Can not found ${operationState} callback`);
        }
    }

    attachPlugin = (roomId, userId) => {
        this.roomId = roomId;
        this.userId = userId;

        const {janus, pluginName} = this;
        const that = this;

        console.log(LogPrefix, `Attaching ${pluginName} plugin`);
        janus.attach({
            plugin: pluginName,
            opaqueId: userId,
            success: (pluginHandle) => {
                console.log(LogPrefix, 'Plugin attach success : pluginHandle = ', pluginHandle);

                that.pluginHandle = pluginHandle;
                that._createRoom();
            },
            error: (error) => {
                console.warn(LogPrefix, 'Plugin attach error : error = ', error);

                that._handleCallbackWithOperationState(OperationState.JoinError);
            },
            consentDialog: (on) => {
                console.log(LogPrefix, 'ConsentDialog : on = ', on);
            },
            mediaState: (medium, on) => {
                console.log(LogPrefix, 'MediaState : medium = ', medium, ', on = ', on);
            },
            webrtcState: (on) => {
                console.log(LogPrefix, 'WebRTCState : on = ', on);
            },
            onmessage: (msg, jsep) => {
                console.log(LogPrefix, 'OnMessage : msg = ', msg, ', jsep = ', jsep);

                const event = msg['audiobridge'];
                if(event !== undefined && event !== null) {
                    if(event === 'joined') {
                        if(msg['id']) {
                            console.log(LogPrefix, `Successfully joined room ${msg['room']} with ID ${msg['id']}`);

                            that._handleCallbackWithOperationState(OperationState.Joined);
                        }

                        if(msg['participants'] !== undefined && msg['participants'] !== null) {
                            console.log(LogPrefix, 'Got a list of participants : ', msg['participants']);

                            msg['participants'].forEach((p) => {
                                console.log(LogPrefix, 'Participant : ', p);

                                that._handleCallback('onParticipant', p);
                            });
                        }
                    } else if(event === 'destroyed') {
                        console.log(LogPrefix, 'The room has been destroyed');
                    } else if(event === 'configured') {
                        console.log(LogPrefix, 'The room has been configured');
                    } else if(event === 'left') {
                        console.log(LogPrefix, `User ${userId} left`, msg['id']);

                        if(that.pluginHandle) {
                            console.log(LogPrefix, 'Detaching plugin...');
                            that.pluginHandle.detach();
                        }
                    } else if(event === 'event') {
                        if(msg['participants'] !== undefined && msg['participants'] !== null) {
                            console.log(LogPrefix, 'Got a list of participants : ', msg['participants']);

                            msg['participants'].forEach((p) => {
                                console.log(LogPrefix, 'Participant : ', p);

                                that._handleCallback('onParticipant', p);
                            });
                        }

                        if(msg['leaving'] !== undefined && msg['leaving'] !== null) {
                            console.log(LogPrefix, 'Leaving a participant : ', msg['leaving']);
                            const leavingId = msg['leaving'];

                            that._handleCallback('onLeaveParticipant', leavingId);
                        }

                        if(msg['error'] !== undefined && msg['error'] !== null) {
                            console.warn(LogPrefix, 'Got a error message : ', msg['error']);

                            if(msg['error_code'] === 490) {
                                that._kickAndJoinRoom();
                            }
                        }
                    }
                }

                if(jsep !== undefined && jsep !== null) {
                    const {pluginHandle} = that;

                    if(pluginHandle) {
                        console.log(LogPrefix, "Handling RemoteJsep", jsep);
                        pluginHandle.handleRemoteJsep({
                            jsep: jsep,
                            success: () => {
                                console.log(LogPrefix, "Handle RemoteJsep success");

                                that._handleCallbackWithOperationState(OperationState.Published, userId, that.isMutedValue);
                            },
                            error: (error) => {
                                console.log(LogPrefix, "Handle RemoteJsep error", error);

                                that._handleCallbackWithOperationState(OperationState.PublishError, error);
                            }
                        });
                    } else {
                        console.warn("Got a jsep but not exits pluginHandle : ", jsep);
                    }
                }
            },
            onlocalstream: (stream) => {
                console.log(LogPrefix, 'OnLocalStream : stream = ', stream);
            },
            onremotestream: (stream) => {
                console.log(LogPrefix, 'OnRemoteStream : stream = ', stream);

                window.Janus.attachMediaStream(that.audioRef.current, stream);
                if(this.isPlayValue) {
                    this.play();
                } else {
                    this.stop();
                }

                const audioTracks = stream.getAudioTracks();
                if(audioTracks && audioTracks.length > 0) {
                    console.log(LogPrefix, 'Audio track exists', audioTracks.length);
                } else {
                    console.warn(LogPrefix, 'Audio track is empty');
                }
            },
            ondataopen: (data) => {
                console.log(LogPrefix, 'OnDataOpen', data);
            },
            ondata: (data) => {
                console.log(LogPrefix, 'OnData', data);
            },
            oncleanup: () => {
                console.log(LogPrefix, 'OnCleanUp');

                that._handleCallbackWithOperationState(OperationState.None);
                that.initialize();
            },
            detached: () => {
                console.log(LogPrefix, 'OnDetached');
            }
        });
    };

    _createRoom = () => {
        const {pluginHandle, roomId} = this;
        const that = this;

        if(!pluginHandle) {
            console.warn(LogPrefix, 'PluginHandle not exists');
            return;
        }

        const existsRequest = {
            message: {
                request: 'exists',
                room: roomId,
            },
            success: (data) => {
                console.log(LogPrefix, 'Exists command success : ', data);

                if(data.exists) {
                    console.log(LogPrefix, 'Room is already exists : ', roomId);

                    that._joinRoom();
                } else {
                    const createRequest = {
                        message: {
                            request: 'create',
                            room: roomId,
                            description: `AudioBridgeDemo-${roomId}`,
                            permanent: false,
                            sampling_rate: 16000,
                        },
                        success: (data) => {
                            console.log(LogPrefix, 'Create command success : ', data);

                            if(data['audiobridge'] === 'created' && data['room'] === roomId) {
                                that._joinRoom()
                            } else if(data['audiobridge'] === 'event' && data['error_code'] === 427) {
                                console.log(LogPrefix, 'Room is already exists. so joining...', roomId);

                                that._joinRoom();
                            } else {
                                console.warn(LogPrefix, 'Create command success. but roomId does not match.', roomId);
                                that._handleCallbackWithOperationState(OperationState.JoinError);
                            }
                        },
                        error: (error) => {
                            console.warn(LogPrefix, 'Create command error : ', error);

                            that._handleCallbackWithOperationState(OperationState.JoinError);
                        }
                    }

                    console.log(LogPrefix, 'Sending create command : ', createRequest.message);
                    pluginHandle.send(createRequest);
                }
            },
            error: (error) => {
                console.warn(LogPrefix, 'Exists command error : error = ', error);

                that._handleCallbackWithOperationState(OperationState.JoinError);
            }
        };

        this._handleCallbackWithOperationState(OperationState.Joining);

        console.log(LogPrefix, 'Sending exists command : ', existsRequest.message);
        pluginHandle.send(existsRequest);
    };

    _joinRoom = () => {
        const {pluginHandle, roomId, userId} = this;

        if(!pluginHandle) {
            console.warn(LogPrefix, 'PluginHandle not exists');
            return;
        }

        const that = this;
        const joinRequest = {
            message: {
                request: 'join',
                room: roomId,
                id: userId,
                display: `AudioBridgeDemoUser-${userId}`,
                muted: false,
            },
            success: (data) => {
                console.log(LogPrefix, "Send join command success", data);
            },
            error: (error) => {
                console.warn(LogPrefix, "Send join command error", error);

                that._handleCallbackWithOperationState(OperationState.JoinError);
            }
        };

        console.log(LogPrefix, 'Sending join command : ', joinRequest.message);
        pluginHandle.send(joinRequest);
    }

    _kickAndJoinRoom = () => {
        const {pluginHandle, roomId, userId} = this;

        if(!pluginHandle) {
            console.warn(LogPrefix, 'PluginHandle not exists');
            return;
        }

        const that = this;
        const kickRequest = {
            message: {
                request: 'kick',
                room: roomId,
                id: userId,
            },
            success: (data) => {
                console.log(LogPrefix, "Send kick command success", data);

                if(data['audiobridge'] === 'success') {
                    that._joinRoom();
                } else if(data['error_code'] === 492) {
                    console.log(LogPrefix, 'Kick command sended successfully but failed. Ignore fail because already user not exists');

                    that._joinRoom();
                } else {
                    console.log(LogPrefix, 'Kick command sended successfully but failed', data);

                    that._handleCallbackWithOperationState(OperationState.JoinError);
                }
            },
            error: (error) => {
                console.log(LogPrefix, "Send kick command error", error);

                that._handleCallbackWithOperationState(OperationState.JoinError);
            }
        };

        console.log(LogPrefix, "Sending kick command", kickRequest.message);
        pluginHandle.send(kickRequest);
    }

    publish = (audio) => {
        const {pluginHandle, isMutedValue, operationState} = this;

        if(!pluginHandle) {
            console.warn(LogPrefix, 'PluginHandle not exists');
            return;
        }

        const that = this;
        const media = audio ? {
            video: false,
            audio: audio,
            replaceAudio: true,
        } : {
            video: false,
            audioSend: false,
            audioRecv: true,
        };
        if(operationState === OperationState.None || operationState === OperationState.Joined || operationState === OperationState.Published || operationState === OperationState.PublishError) {
            const createOfferRequest = {
                media: media,
                success: (jsep) => {
                    console.log(LogPrefix, 'Create offer success : jsep = ', jsep);

                    const publishRequest = {
                        message: {
                            request: 'configure',
                            muted: isMutedValue,
                        },
                        jsep: jsep,
                        success: (data) => {
                            console.log(LogPrefix, 'Configure command success', data);
                        },
                        error: (error) => {
                            console.log(LogPrefix, 'Configure command error', error);

                            that._handleCallbackWithOperationState(OperationState.PublishError);
                        }
                    }

                    console.log(LogPrefix, 'Configure command sending...', publishRequest.message);
                    pluginHandle.send(publishRequest);

                },
                error: (error) => {
                    console.warn(LogPrefix, 'Create offer error', error);

                    that._handleCallbackWithOperationState(OperationState.PublishError);
                }
            }

            this._handleCallbackWithOperationState(OperationState.Publishing);

            console.log(LogPrefix, 'Creating offer...', createOfferRequest.media);
            pluginHandle.createOffer(createOfferRequest);
        } else {
            console.warn(LogPrefix, 'Invalid state for publish : ', operationState);
        }
    }

    isPlay = () => {
        return this.isPlayValue;
    }

    play = (callback) => {
        this.isPlayValue = true;

        if(this.audioRef && this.audioRef.current) {
            //this.audioRef.current.muted = false;
            this.audioRef.current.play();

            if(callback && callback.success) {
                try {
                    callback.success();
                } catch(error) {
                    console.warn(LogPrefix, 'Error in callback', error);
                }
            }
        }
    }

    stop = (callback) => {
        this.isPlayValue = false;

        if(this.audioRef && this.audioRef.current) {
            //this.audioRef.current.muted = true;
            this.audioRef.current.pause();

            if(callback && callback.success) {
                try {
                    callback.success();
                } catch(error) {
                    console.warn(LogPrefix, 'Error in callback', error);
                }
            }

        }
    }

    isMuted = () => {
        return this.isMutedValue;
    }

    mute = (mute, callback) => {
        const {pluginHandle, operationState} = this;

        if(!pluginHandle) {
            console.warn(LogPrefix, 'PluginHandle not exists');
            return;
        }

        if(operationState === OperationState.Published) {
            this.isMutedValue = mute;

            const configureRequest = {
                message: {
                    request: 'configure',
                    muted: mute,
                },
                success: (data) => {
                    console.log(LogPrefix, 'Configure command success', data);

                    if (callback && callback.success) {
                        try {
                            callback.success();
                        } catch (error) {
                            console.warn(LogPrefix, 'Error in callback', error);
                        }
                    }
                },
                error: (error) => {
                    console.log(LogPrefix, 'Configure command error', error);
                }
            }

            console.log(LogPrefix, 'Configure command sending...', configureRequest.message);
            pluginHandle.send(configureRequest);
        } else {
            console.warn(LogPrefix, 'Invalid state for mute : ', operationState);
        }
    }

    leave = () => {
        const {pluginHandle} = this;

        if(!pluginHandle) {
            console.warn(LogPrefix, 'PluginHandle not exists');
            return;
        }

        const leaveRequest = {
            message: {
                request: 'leave',
            },
            success: (data) => {
                console.log(LogPrefix, 'Leave command success', data);
            },
            error: (error) => {
                console.log(LogPrefix, 'Leave command error', error);
            }
        }

        console.log(LogPrefix, 'Leave command sending...', leaveRequest.message);
        pluginHandle.send(leaveRequest);
    }
}
import {createMuiTheme} from "@material-ui/core";
import {koKR} from "@material-ui/core/locale";

export default function configureTheme() {
    return createMuiTheme({
        props: {
            // Name of the component
            MuiButtonBase: {
                // The properties to apply
                disableRipple: true, // No more ripple, on the whole application
            },
        },
        palette: {
            common:{
                black: '#333333',
            },
            primary: {
                // light: will be calculated from palette.primary.main,
                // main: '#cf1a1b',
                main: '#3437CE'
                // dark:'#c1010a',
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            secondary: {
                main:'#3437CE',
            },
        },
        typography: {
            fontFamily: [
            '"NanumSquareR"', 
            '"Roboto"',
            '"Helvetica"',
            '"Arial"', 
            'sans-serif'].join(','),
            fontWeightLight: 400,
            fontWeightRegular: 500,
            fontWeightMedium: 600,
            fontWeightBold: 800,
            boxSizing:'borderBox',
            button:{
                fontSize:'14px',
            },
            h3:{
                fontSize: '24px',
                fontWeight:'700',
                margin: '16px 0 0 0',
                padding: '8px 0 0 0',
            },
            h4:{
                fontSize: '28px',
                fontWeight:'700',
            },
            h5:{
                fontSize: '16px',
                fontWeight:'700',
                borderBottom:'2px solid #333333',
                paddingBottom:'24px',
                marginTop:'30px',
                marginBottom:'16px'
            },
            body1 :{
                fontSize:'14px',
                color:'#333333',
            },
            body2 :{
                fontSize:'13px',
                color:'rgba(0,0,0,0.56)'
            },
        },
        drawerWidth: 240,
        chatWidth: 320,
        videoMaxWidth: 640,
        transitions: {
            // So we have `transition: none;` everywhere
            create: () => 'none',
          },
    }, koKR);
};



import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    IconButton,
    MenuItem,
    MenuList,
    Popover,
    Popper,
    Tooltip,
    Typography,
    withStyles,
    withWidth,
    Hidden
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import FlipToBackIcon from '@material-ui/icons/FlipToBack';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';
import HighQualityIcon from '@material-ui/icons/HighQuality';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import PhotoSizeSelectSmallIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import RemoveIcon from '@material-ui/icons/Remove';
import StopIcon from '@material-ui/icons/Stop';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PauseIcon from '@material-ui/icons/Pause';
import { ReactComponent as FullscreenIcon } from '../../common/images/FullscreenIcon.svg';
import { ReactComponent as FullscreenExitIcon } from '../../common/images/FullscreenExitIcon.svg';

import {inject, observer} from "mobx-react";

import {AudioState, ChatState, RoomMode, StreamType, VideoMode, VideoState} from "./api/RoomPresenter2";
import _ from "lodash";
import {toJS} from "mobx";
import ConfirmDialog from "./ConfirmDialog";
import ThreeSixtyIcon from '@material-ui/icons/ThreeSixty';
import PictureInPictureIcon from "@material-ui/icons/PictureInPicture";
import PictureInPictureAltIcon from "@material-ui/icons/PictureInPictureAlt";
import {VideoPosition} from "./api/RoomPresenter2";

const styles = theme => ({
    mainContainer: {
        width: '100%',
        padding: theme.spacing(1),
        backgroundColor: 'white',
        // background:'red'
    },
    broadcastControlBox: {
        paddingRight: theme.spacing(1),
    },
    broadcastControlBox1: {
        paddingRight: theme.spacing(1),
        width:'100%',
    },
    broadcastControlLastBox: {
        paddingRight: theme.spacing(0),
    },
    broadcastPaddingBox: {
        padding: theme.spacing(1),
    },

    videoStreamsContainer: {
        zIndex: 30,
        backgroundColor: 'white',
        padding: theme.spacing(1),
        borderRadius: '5px',
        overflow: 'auto',
    },
    videoStreamMessage: {
        padding: theme.spacing(1),
    },
    videoStreamElement: {
        paddingBottom: theme.spacing(1),
    },
    videoStreamVideo: {
        backgroundColor: 'white',
        marginRight: theme.spacing(1),
    },
    videoStreamControl: {
        marginBottom: theme.spacing(1),
    },
    subscriberVideoStream: {
        backgroundColor: 'white',
        borderTopLeftRadius: 3,
        borderBottomLeftRadius: 3,
    },

    maxWidthTypography: {
        overflow: 'hidden',
        maxWidth: 120,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    iconButtonBox: {
        // paddingRight: theme.spacing(1),
    },
    dialogTitle: {
        fontSize: 16,
        fontWeight: 600,
    },
    videoBottomContainer: {
        [theme.breakpoints.down('xs')]: {
            backgroundColor: '#fff',
        },
        padding: theme.spacing(1),
        backgroundColor: '#fff',
    },
    mobileVideoBottomContainer:{
        padding: theme.spacing(1),
        backgroundColor: '#fff',
    },
    videoBottomContainer1: {
        [theme.breakpoints.down('xs')]: {
            backgroundColor: '#fff',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(2),
            borderBottom:'1px solid #eee',
        },
    },
    mobileVideoBottomContainer1:{
        backgroundColor: '#fff',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(2),
        // borderBottom:'1px solid #eee',
    },
});

const MaxVideoStreamCount = 5;
const MaxSubScreenSize = 55;
const MinSubScreenSize = 15;
const MaxLookAround = 81;
const PUBLISH_BUTTON_TIMEOUT = 3000;
const LogPrefix = '[RoomBroadcastControl] ';

@inject('roomStore', 'publishStore', 'recordStore')
@observer
class RoomBroadcastControlComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modeMenuOpen: false,
            videoStreamMenuOpen: false,
            addVideoStreamMenuOpen: false,
            audioDevicesMenuOpen: false,
            alertAudioDialogOpen: false,
            subscriberVideoMenuOpen: false,

            loadingAudioDevices: false,
            audioDevices: [],

            loadingVideoDevices: false,
            videoDevices: [],

            resizeSubScreenMenuOpen: false,
            qualitySubScreenMenuOpen: false,

            lookaroundWarningOpen: false,

        }

        this.modeButtonRef = React.createRef();
        this.videoStreamsPopperRef = React.createRef();
        this.videoStreamsButtonRef = React.createRef();
        this.addVideoStreamButtonRef = React.createRef();
        this.audioDevicesButtonRef = React.createRef();

        this.resizeSubScreenButtonRef = React.createRef();
        this.qualitySubScreenButtonRef = React.createRef();
    }

    initialize = () => {
        const init = () => {
            const {role, chatState} = this.props.roomStore;

            if (chatState === ChatState.Connected) {
                if (!this.initialized) {
                    // this.setState({loadingAudioDevices: true, audioDevices: []});
                    //
                    // const that = this;
                    // this.props.getDevices((devices) => {
                    //     console.log(LogPrefix, 'Device detected', devices);
                    //
                    //     const audioDevices = _.filter(devices, (d) => d.deviceId !== 'default' && d.kind === 'audioinput');
                    //     const selectedDevice = audioDevices && audioDevices.length > 0 ? audioDevices[0] : undefined;
                    //
                    //     that.setState({
                    //         loadingAudioDevices: false,
                    //         audioDevices: audioDevices,
                    //     });
                    //     that.props.onChangeAudioDevice(selectedDevice);
                    // });

                    if (role === 'publisher') {

                    } else {
                        const {videoStreams} = this.props.publishStore;

                        if (!videoStreams || videoStreams.length < 1) {
                            if (this.props.onChangeVideoStream) {
                                this.props.onChangeVideoStream();
                            }
                        }
                    }
                }
            } else {
                setTimeout(() => init(), 1000);
            }
        }

        init();
    }

    componentDidMount() {
        this.initialized = false;

        this.initialize();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {videoStreams} = this.props.publishStore;

        for(let i in videoStreams) {
            const stream = videoStreams[i];
            if(stream.stream) {
                const videoEl = document.getElementById(`video-${stream.stream.id}`);
                if (videoEl) {
                    //console.log(LogPrefix, 'Exists video tag', stream.stream.id);
                    const attached = videoEl.getAttribute('attached');

                    if (!attached) {
                        videoEl.setAttribute('attached', 'true');
                        videoEl.srcObject = stream.stream;
                        videoEl.onloadedmetadata = () => {
                            videoEl.play();
                        }
                    } else {
                        //console.log(LogPrefix, 'But already attached', stream.stream.id);
                    }
                } else {
                    //console.log(LogPrefix, 'Not Exists video tag', stream.stream.id);
                }
            }
        }

        if(this.videoStreamsPopperRef && this.videoStreamsPopperRef.current) {
            this.videoStreamsPopperRef.current.scrollTop = this.videoStreamsPopperRef.current.scrollHeight;
        }
    }

    handleChangeMode = (mode) => {
        this.setState({modeMenuOpen: false});
        this.props.onChangeMode(mode);
    }

    handleModeMenuToggle = () => {
        const open = !this.state.modeMenuOpen;

        this.setState({modeMenuOpen: open});
    }

    handleCloseModeMenu = () => {
        this.setState({modeMenuOpen: false});
    }

    // handleClickLookAround = () => {
    //     this.props.onLookAround();
    // }

    handleVideoStreamMenuToggle = () => {
        const open = !this.state.videoStreamMenuOpen;

        this.setState({videoStreamMenuOpen: open});
    }


    toggleAddVideoStreamMenu = () => {
        const open = !this.state.addVideoStreamMenuOpen;

        if(open) {
            this.setState({loadingVideoDevices: true, videoDevices: []});

            const that = this;
            const {isSupportScreenDevice} = this.props;
            this.props.getDevices((devices) => {
                console.log(LogPrefix, 'Device detected', devices);

                const videoDevices = _.filter(devices, (d) => d.deviceId !== 'default' && d.kind === 'videoinput');
                // videoDevices.push({
                //     deviceId: 'lookaround',
                //     label: '둘러보기',
                // });

                if(isSupportScreenDevice) {
                    videoDevices.push({
                        deviceId: 'screen',
                        label: '화면공유',
                    });
                }
                that.setState({loadingVideoDevices: false, videoDevices: videoDevices, addVideoStreamMenuOpen: true});
            });
        } else {
            this.setState({addVideoStreamMenuOpen: false});
        }
    }

    handleCloseAddVideoStreamMenu = () => {
        this.setState({addVideoStreamMenuOpen: false});
    }

    handleAddVideoStream = (deviceId) => {
        if(this.props.onAddVideoStream) {
            this.props.onAddVideoStream(deviceId);
        }

        this.setState({addVideoStreamMenuOpen: false});
    }

    handleRemoveVideStream = (streamId) => {
        if(this.props.onRemoveVideoStream) {
            this.props.onRemoveVideoStream(streamId);
        }
    }

    handlePublishVideoStream = (videoMode, streamId, deviceId) => {
        this.props.publishStore.setPublishButtonClicked(true);
        setTimeout(() => this.props.publishStore.setPublishButtonClicked(false), PUBLISH_BUTTON_TIMEOUT);

        if(videoMode === VideoMode.Primary) {
            const {mode, primaryState, primaryStream, audioState} = this.props.publishStore;

            if(primaryState === VideoState.Published && primaryStream && primaryStream.streamId === streamId) {
                this.props.onUnpublishVideo(VideoMode.Primary);
            } else if((primaryState === VideoState.Subscribed || primaryState === VideoState.StreamAttached) && primaryStream && primaryStream.streamId === streamId) {
                // if(deviceId && deviceId === 'lookaround') {
                //     this.props.onEndXVideo();
                // }

                this.props.onUnsubscribe(VideoMode.Primary);
            }
            else {
                if(audioState === VideoState.None) {
                    this.setState({alertAudioDialogOpen: true});
                }

                // if(mode === RoomMode.LookAround && !deviceId) {
                //     this.props.onEndXVideo();
                // }
                //
                // if(deviceId && deviceId === 'lookaround') {
                //     this.props.onStartXVideo();
                // }

                this.props.onPublishVideo(VideoMode.Primary, streamId);
            }
        } else {
            const {secondaryState, secondaryStream, audioState} = this.props.publishStore;

            if(secondaryState === VideoState.Published && secondaryStream && secondaryStream.streamId === streamId) {
                this.props.onUnpublishVideo(VideoMode.Secondary);
            } else if((secondaryState === VideoState.Subscribed || secondaryState === VideoState.StreamAttached) && secondaryStream && secondaryStream.type === StreamType.Remote && secondaryStream.streamId === streamId) {
                this.props.onUnsubscribe(VideoMode.Secondary);
            }else {
                if(audioState === VideoState.None) {
                    this.setState({alertAudioDialogOpen: true});
                }
                this.props.onPublishVideo(VideoMode.Secondary, streamId);
            }
        }
    }

    handleAudioButtonMenuToggle = () => {
        const open = !this.state.audioDevicesMenuOpen;
        if(open) {
            this.setState({loadingAudioDevices: true, audioDevices: []});

            const that = this;
            this.props.getDevices((devices) => {
                const audioDevices = _.filter(devices, (d) => d.deviceId !== 'default' && d.kind === 'audioinput');

                that.setState({loadingAudioDevices: false, audioDevices: audioDevices, audioDevicesMenuOpen: true});
            });
        } else {
            this.setState({audioDevicesMenuOpen: false});
        }
    }

    handleChangeSelectedAudioDevice = (device) => {
        this.setState({audioDevicesMenuOpen: false});
        this.props.onChangeAudioDevice(device);
    }

    handleCloseAudioButtonMenu = () => {
        this.setState({audioDevicesMenuOpen: false});
    }


    handlePublishAudio = () => {
        this.props.onPublishAudio();
        // const {audioState} = this.props.roomStore;
        // const {selectedAudioId} = this.state;
        //
        // if(audioState === AudioState.Published) {
        //     this.props.onUnpublishAudio();
        // } else {
        //     this.props.onPublishAudio(selectedAudioId);
        // }
    }

    handleSoundPlay = () => {
        this.props.onChangeSoundPlay();
    }

    handleCloseAlertAudioDialog = () => {
        this.setState({alertAudioDialogOpen: false});
    }


    handleResizeSubScreenMenuToggle = () => {
        this.setState({resizeSubScreenMenuOpen: !this.state.resizeSubScreenMenuOpen});
    }

    handleCloseResizeSubScreenMenu = () => {
        this.setState({resizeSubScreenMenuOpen: false});
    }

    handleIncreaseSubScreenSize = () => {
        const {subScreenSize} = this.props.publishStore;
        if(subScreenSize < MaxSubScreenSize) {
            if(this.props.onChangeSubScreenSize) {
                this.props.onChangeSubScreenSize(subScreenSize + 5);
            }
        }
    }

    handleDecreaseSubScreenSize = () => {
        const {subScreenSize} = this.props.publishStore;
        if(subScreenSize > MinSubScreenSize) {
            if(this.props.onChangeSubScreenSize) {
                this.props.onChangeSubScreenSize(subScreenSize - 5);
            }
        }
    }

    handlePrimaryHighQuality = () => {
        const {primarySubstream} = this.props.publishStore;
        if(primarySubstream < 1) {
            if(this.props.onPrimaryHighQuality) {
                this.props.onPrimaryHighQuality();
            }
        }
    }

    handleQualitySubScreenMenuToggle = () => {
        this.setState({qualitySubScreenMenuOpen: !this.state.qualitySubScreenMenuOpen});
    }

    handleCloseQualitySubScreenMenu = () => {
        this.setState({qualitySubScreenMenuOpen: false});
    }

    handleHighQualityValueChanged = (event) => {
        const newValue = event.target.checked;

        if(this.props.onChangeSubScreenHighQuality) {
            this.props.onChangeSubScreenHighQuality(newValue);
        }
    }


    toggleSubscriberVideoButtonMenu = () => {
        const open = !this.state.subscriberVideoMenuOpen;

        if(open) {
            this.setState({loadingVideoDevices: true, videoDevices: []});

            const that = this;
            const {isSupportScreenDevice} = this.props;
            this.props.getDevices((devices) => {
                console.log(LogPrefix, 'Device detected', devices);

                const videoDevices = _.filter(devices, (d) => d.deviceId !== 'default' && d.kind === 'videoinput');
                if(isSupportScreenDevice) {
                    videoDevices.push({
                        deviceId: 'screen',
                        label: '화면 공유',
                    });
                }
                that.setState({loadingVideoDevices: false, videoDevices: videoDevices, subscriberVideoMenuOpen: true});
            });
        } else {
            this.setState({subscriberVideoMenuOpen: false});
        }
    }

    handleCloseSubscriberVideoMenu = () => {
        this.setState({subscriberVideoMenuOpen: false});
    }

    changeSubscriberVideoStream = (deviceId) => {
        const {videoStreams} = this.props.publishStore;

        if(videoStreams && videoStreams.length > 0) {
            if(this.props.onChangeVideoStream) {
                this.props.onChangeVideoStream(videoStreams[0].stream.id, deviceId);
            }
        } else {
            if(this.props.onChangeVideoStream) {
                this.props.onChangeVideoStream(undefined, deviceId);
            }
        }

        this.setState({subscriberVideoMenuOpen: false});
    }

    handleCloseLookAroundWarning = () => {
        this.setState({lookaroundWarningOpen: false});
    }

    onClickRecording = () => {
        const {isRecording} = this.props.recordStore;

        if(isRecording) {
            this.props.onStopRecord();
        } else {
            this.props.onStartRecord();
        }
    }

    onClickPauseRecording = () => {
        const {isRecordingPaused} = this.props.recordStore;

        if(isRecordingPaused) {
            this.props.onResumeRecord();
        } else {
            this.props.onPauseRecord();
        }
    }

    render() {
        const {classes, width, onChangeMemberMic, onChangeSubScreenHidden, onChangeVideoRotate, onChangeSubPosition, onChangeFullScreen} = this.props;
        const {isRecordSupported, isRecording, isRecordingPaused, recordingTime} = this.props.recordStore;

        const {channel, users, videoViewHeight, isPublisher, role, landscape, isVideoRotateX, isVideoRotateY, isPublishButtonClicked, isMobileLandscape} = this.props.roomStore;
        const {mode, videoStreams, subVideoHidden, memberMic, audioDevice, audioState, soundPlayed, audioMuted, primaryState, secondaryState, primaryStream, secondaryStream, primaryFeed, secondaryFeed, primaryHighQuality, primarySubstream, subScreenSize} = this.props.publishStore;
        const smallWidth = width === 'xs' || width === 'sm' || width === 'md';
        const existsLookAroundStream = _.find(videoStreams, (stream) => stream.type === StreamType.Remote && stream.deviceId === 'lookaround') ? true : false;
        const isActivateStream = (videoMode, stream) => {
            if(stream.type === StreamType.LocalCamera || stream.type === StreamType.LocalScreen) {
                const currentState = videoMode === VideoMode.Primary ? primaryState : secondaryState;
                const currentStream = videoMode === VideoMode.Primary ? primaryStream : secondaryStream;

                return currentState === VideoState.Published && currentStream && currentStream.streamId === stream.streamId
            } else if(stream.type === StreamType.Remote) {
                const currentState = videoMode === VideoMode.Primary ? primaryState : secondaryState;
                const currentFeed = videoMode === VideoMode.Primary ? primaryFeed : secondaryFeed;

                return (currentState === VideoState.Subscribed || currentState === VideoState.StreamAttached) && currentFeed && currentFeed === stream.streamId;
            } else {
                return false;
            }
        }

        console.log(LogPrefix, 'VideoStreams', toJS(videoStreams));
        console.log(LogPrefix, `RoomMode = ${toJS(mode)}, MemberMic = ${toJS(memberMic)}`);
        console.log(LogPrefix, 'Audio state=', toJS(audioState), ',muted=', toJS(audioMuted));
        console.log(LogPrefix, 'Primary state=', toJS(primaryState), ',feed=', toJS(primaryFeed), ',stream=', toJS(primaryStream));
        console.log(LogPrefix, 'Secondary state=', toJS(secondaryState), ',feed=', toJS(secondaryFeed), ',stream=', toJS(secondaryStream));

        const publisherControls = [
            <Box key='publisher-video-stream-control-box' display="flex" flexDirection="row" className={classes.broadcastControlBox}>
                <ButtonGroup ref={this.videoStreamsButtonRef} variant="contained">
                    <Button color={primaryState === VideoState.Published || secondaryState === VideoState.Published ? 'primary' : 'default'} startIcon={smallWidth ? null : (primaryState === VideoState.Published || secondaryState === VideoState.Published ? <VideocamIcon /> : <VideocamOffIcon />)} onClick={this.handleVideoStreamMenuToggle}>
                        {smallWidth ? (primaryState === VideoState.Published || secondaryState === VideoState.Published ? <VideocamIcon /> : <VideocamOffIcon />) : <Typography color="inherit">방송 영상 목록</Typography>}
                    </Button>
                </ButtonGroup>

                <Popper ref={this.videoStreamsPopperRef}
                        open={this.state.videoStreamMenuOpen}
                        anchorEl={this.videoStreamsButtonRef.current}
                        className={classes.videoStreamsContainer}
                        placement='top-start'
                        keepMounted
                        style={{maxHeight: videoViewHeight - 25}}
                >
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center">
                        {videoStreams && videoStreams.length > 0 ?
                            videoStreams.map((stream) =>
                                <Box key={`video-stream-box-${stream.streamId}`} display="flex" flexDirection="row" justifyContent="center" alignItems="center" className={classes.videoStreamElement}>
                                    {stream.type === StreamType.Remote ?
                                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className={classes.videoStreamVideo} style={{width: 200, height: 120}}>
                                            <Typography><b>둘러보기</b></Typography>
                                            <Typography>둘러보기 중에는 <b>질문 기능이 제한</b>됩니다. 질문 기능 사용을 위해서는 둘러보기를 종료 해 주세요.</Typography>
                                        </Box>
                                        :
                                        <video id={`video-${stream.streamId}`} width="200px" height="120px" playsInline
                                               autoPlay muted className={classes.videoStreamVideo}/>
                                    }

                                    <Box display="flex" flexDirection="column" justifyContent="center" >
                                        {stream.type === StreamType.LocalCamera || stream.type === StreamType.LocalScreen ?
                                            [
                                                <Button key={`video-cmd-primary-${stream.streamId}`}
                                                        variant="contained"
                                                        color={isActivateStream(VideoMode.Primary, stream) ? 'primary' : 'default'}
                                                        startIcon={isActivateStream(VideoMode.Primary, stream) ?
                                                                <StopIcon/>
                                                                :
                                                                <PlayArrowIcon/>
                                                        }
                                                        //disabled={primaryState === VideoState.Subscribing || primaryState === VideoState.Subscribed}
                                                        disabled={isPublishButtonClicked}
                                                        className={classes.videoStreamControl}
                                                        onClick={() => this.handlePublishVideoStream(VideoMode.Primary, stream.streamId)}
                                                >주</Button>,
                                                <Button key={`video-cmd-secondary-${stream.streamId}`}
                                                        variant="contained"
                                                        color={isActivateStream(VideoMode.Secondary, stream) ? 'primary' : 'default'}
                                                        startIcon={isActivateStream(VideoMode.Secondary, stream) ?
                                                                <StopIcon/>
                                                                :
                                                                <PlayArrowIcon/>
                                                        }
                                                        className={classes.videoStreamControl}
                                                        //disabled={primaryState === VideoState.Subscribing || primaryState === VideoState.Subscribed}
                                                        disabled={isPublishButtonClicked}
                                                        onClick={() => this.handlePublishVideoStream(VideoMode.Secondary, stream.streamId)}
                                                >부</Button>,
                                                <Button key={`video-cmd-remove-${stream.streamId}`}
                                                        startIcon={<RemoveIcon/>}
                                                        disabled={(primaryStream && primaryStream.streamId === stream.streamId) || (secondaryStream && secondaryStream.streamId === stream.streamId)}
                                                        onClick={() => this.handleRemoveVideStream(stream.streamId)}
                                                >삭제</Button>
                                            ]
                                            :
                                            stream.type === StreamType.Remote ?
                                            [
                                                <Button key={`video-cmd-primary-${stream.streamId}`}
                                                        variant="contained"
                                                        color={isActivateStream(VideoMode.Primary, stream) ? 'primary' : 'default'}
                                                        startIcon={isActivateStream(VideoMode.Primary, stream) ?
                                                            <StopIcon/>
                                                            :
                                                            <PlayArrowIcon/>
                                                        }
                                                        //disabled={primaryState === VideoState.Subscribing || primaryState === VideoState.Subscribed}
                                                        disabled={isPublishButtonClicked}
                                                        className={classes.videoStreamControl}
                                                        onClick={() => {
                                                            // this.props.monitoringStore.getXParameter(channel.channelId);
                                                            this.handlePublishVideoStream(VideoMode.Primary, stream.streamId, stream.deviceId)
                                                        }}
                                                >주</Button>,
                                                <Button key={`video-cmd-remove-${stream.streamId}`}
                                                        startIcon={<RemoveIcon/>}
                                                        disabled={(primaryStream && primaryStream.streamId === stream.streamId) || (secondaryStream && secondaryStream.streamId === stream.streamId)}
                                                        onClick={() => this.handleRemoveVideStream(stream.streamId)}
                                                >삭제</Button>
                                            ]
                                            :
                                            [
                                                <Button key={`video-cmd-unpublish-${stream.streamId}`} variant="contained" startIcon={<StopIcon/>}>중지</Button>
                                            ]
                                        }
                                    </Box>
                                </Box>
                            )
                            :
                            <Box display="flex" flexDirection="column" className={classes.videoStreamMessage}>
                                <Typography>수업에 사용 할 영상이 아직 없습니다.</Typography>
                                <Typography>아래의 <b>영상 추가</b> 버튼을 눌러 수업에 사용할 영상을 준비해 주세요.</Typography>
                                <Typography>영상은 최대 {MaxVideoStreamCount}개 까지 가능합니다.</Typography>
                            </Box>
                        }

                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" alignSelf="stretch">
                            <Button ref={this.addVideoStreamButtonRef} disabled={videoStreams.length >= MaxVideoStreamCount} onClick={this.toggleAddVideoStreamMenu} >영상 추가...</Button>

                            <Typography>{videoStreams.length} / {MaxVideoStreamCount}</Typography>

                            <Popover open={this.state.addVideoStreamMenuOpen}
                                     anchorEl={this.addVideoStreamButtonRef.current}
                                     anchorOrigin={{
                                         vertical: 'bottom',
                                         horizontal: 'right',
                                     }}
                                     transformOrigin={{
                                         vertical: 'bottom',
                                         horizontal: 'left',
                                     }}
                                     onClose={this.handleCloseAddVideoStreamMenu}
                            >
                                <MenuList>
                                    {this.state.videoDevices.map(device =>
                                        <MenuItem key={`video-device-${device.deviceId}`} disabled={(device.deviceId === 'lookaround' && existsLookAroundStream) || (device.deviceId === 'lookaround' && users.length > MaxLookAround)} onClick={() => this.handleAddVideoStream(device.deviceId)}>
                                            {device.label ? device.label : '알수없는 장치'}
                                        </MenuItem>
                                    )}
                                </MenuList>
                            </Popover>
                        </Box>
                    </Box>
                </Popper>
            </Box>,

            <Box key='publisher-audio-stream-control-box' display="flex" flexDirection="row" className={classes.broadcastControlBox}>
                <ButtonGroup ref={this.audioDevicesButtonRef} variant="contained" >
                    <Tooltip title={"마이크"}>
                        <Button color={audioState === VideoState.Published && !audioMuted ? 'primary' : 'default'}  startIcon={smallWidth ? null : (audioState === AudioState.Published && !audioMuted ? <MicIcon /> : <MicOffIcon />)} onClick={this.handlePublishAudio} >
                            {smallWidth ?
                                (audioState === AudioState.Published && !audioMuted ? <MicIcon/> : <MicOffIcon/>)
                                :
                                <Typography color="inherit" className={classes.maxWidthTypography}>{audioDevice ? audioDevice.label ? audioDevice.label : '알수없는 장치' : ''}</Typography>
                            }
                        </Button>
                    </Tooltip>

                    <Button disabled={false} size="small" onClick={this.handleAudioButtonMenuToggle} >
                        {this.state.loadingAudioDevices ?
                            <CircularProgress size={24} />
                            :
                            <ArrowDropDownIcon />
                        }
                    </Button>
                </ButtonGroup>
                <Popover open={this.state.audioDevicesMenuOpen}
                         anchorEl={this.audioDevicesButtonRef.current}
                         anchorOrigin={{
                             vertical: 'top',
                             horizontal: 'left',
                         }}
                         transformOrigin={{
                             vertical: 'bottom',
                             horizontal: 'left',
                         }}
                         onClose={this.handleCloseAudioButtonMenu}
                >

                    <MenuList id="audio-devices-button-menu">
                        {this.state.audioDevices.map((device, index) => (
                            <MenuItem key={`audio-item-${device.deviceId}`}
                                      selected={audioDevice.deviceId === device.deviceId}
                                      onClick={() => this.handleChangeSelectedAudioDevice(device)}
                            >
                                {device.label ? device.label : '알수없는 장치'}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Popover>
            </Box>,
            <Hidden xsDown key='publisher-broadcast-control-box-hidden'>
                {this.props.roomStore.isMobile ||
                    <Box key='publisher-broadcast-control-box' display="flex" flexDirection="row" className={classes.broadcastControlBox}>
                        <Tooltip title={memberMic ? '학생 마이크 끄기' : '학생 마이크 켜기'}>
                            <IconButton onClick={onChangeMemberMic}>
                                {memberMic ?
                                    <MicIcon color="primary" />
                                    :
                                    <MicOffIcon color="inherit" />
                                }
                            </IconButton>
                        </Tooltip>
                        {isRecordSupported &&
                            <>
                                <Tooltip title={isRecording ? '녹화중지' : '녹화시작'}>
                                    <IconButton onClick={this.onClickRecording}>
                                        {isRecording ?
                                            <StopIcon color="primary" />
                                            :
                                            <FiberManualRecordIcon color="inherit" />
                                        }
                                    </IconButton>
                                </Tooltip>
                                {isRecording && <Typography variant="body2" style={{paddingTop: 16}}>{recordingTime}</Typography>}
                                <Tooltip title={isRecordingPaused ? '녹화계속' : '녹화일시정지'}>
                                    <IconButton onClick={this.onClickPauseRecording} disabled={!isRecording}>
                                        <PauseIcon color={isRecordingPaused ? 'primary' : 'inherit'} />
                                    </IconButton>
                                </Tooltip>
                            </>
                        }
                        <Tooltip title={subVideoHidden ? '작은화면 보이기' : '작은화면 숨기기'}>
                            <IconButton onClick={onChangeSubScreenHidden}>
                                {subVideoHidden ?
                                    <FlipToFrontIcon style={{transform: 'scaleX(-1)'}}/>
                                    :
                                    <FlipToBackIcon style={{transform: 'scaleX(-1)'}}/>
                                }
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'좌우반전'}>
                            <IconButton onClick={() => onChangeVideoRotate(isVideoRotateX, !isVideoRotateY)}>
                                <ThreeSixtyIcon color={isVideoRotateY ? 'primary' : 'inherit'} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'상하반전'}>
                            <IconButton onClick={() => onChangeVideoRotate(!isVideoRotateX, isVideoRotateY)}>
                                <ThreeSixtyIcon color={isVideoRotateX ? 'primary' : 'inherit'} style={{transform: 'rotate(90deg)'}}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
            </Hidden>,

            <ConfirmDialog key="publisher-broadcast-alert-dialog"
                           maxWidth="sm"
                           dialogOpen={this.state.alertAudioDialogOpen}
                           title={<Typography className={classes.dialogTitle}>마이크 알림</Typography>}
                           content={
                               <Box key={'mic-alert-box'} display="flex" flexDirection="column">
                                   <Typography key={'mic-alert-text-1'}>현재 마이크가 꺼져있습니다. 영상을 방송하여도 학생에게 소리는 나오지 않습니다.</Typography>
                                   <Typography key={'mic-alert-text-2'}>영상 목록 버튼 옆의 마이크 아이콘을 클릭하면 음성 방송을 시작할 수 있습니다.</Typography>
                               </Box>
                           }

                           onOk={() => this.handleCloseAlertAudioDialog(true)}
            />
        ];
        const publisherButtonControls = [
            
            <Box key='publisher-broadcast-control-box' display="flex" flexDirection="row" className={classes.broadcastControlBox1}>
                <Box style={{width:'16%', textAlign:'center'}}>
                    <Tooltip title={memberMic ? '학생 마이크 끄기' : '학생 마이크 켜기'}>
                        <IconButton onClick={onChangeMemberMic}>
                            {memberMic ?
                                <MicIcon color="primary" />
                                :
                                <MicOffIcon color="inherit" />
                            }
                        </IconButton>
                    </Tooltip>
                </Box>
                {isRecordSupported &&
                    <>
                        <Box style={{width:'16%', textAlign:'center'}}>
                            <Tooltip title={isRecording ? '녹화중지' : '녹화시작'}>
                                <IconButton onClick={this.onClickRecording}>
                                    {isRecording ?
                                        <StopIcon color="primary" />
                                        :
                                        <FiberManualRecordIcon color="inherit" />
                                    }
                                </IconButton>
                            </Tooltip>
                        </Box>
                        {isRecording && <Typography variant="body2" style={{paddingTop: 16}}>{recordingTime}</Typography>}
                        <Box style={{width:'16%', textAlign:'center'}}>
                            <Tooltip title={isRecordingPaused ? '녹화계속' : '녹화일시정지'}>
                                <IconButton onClick={this.onClickPauseRecording} disabled={!isRecording}>
                                    <PauseIcon color={isRecordingPaused ? 'primary' : 'inherit'} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </>
                }
                <Box style={{width:'16%',textAlign:'center'}}>
                    <Tooltip title={subVideoHidden ? '작은화면 보이기' : '작은화면 숨기기'}>
                        <IconButton onClick={onChangeSubScreenHidden}>
                            {subVideoHidden ?
                                <FlipToFrontIcon style={{transform: 'scaleX(-1)'}}/>
                                :
                                <FlipToBackIcon style={{transform: 'scaleX(-1)'}}/>
                            }
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box style={{width:'16%',textAlign:'center'}}>
                    <Tooltip title={'좌우반전'}>
                        <IconButton onClick={() => onChangeVideoRotate(isVideoRotateX, !isVideoRotateY)}>
                            <ThreeSixtyIcon color={isVideoRotateY ? 'primary' : 'inherit'} />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box style={{width:'16%',textAlign:'center'}}>
                    <Tooltip title={'상하반전'}>
                        <IconButton onClick={() => onChangeVideoRotate(!isVideoRotateX, isVideoRotateY)}>
                            <ThreeSixtyIcon color={isVideoRotateX ? 'primary' : 'inherit'} style={{transform: 'rotate(90deg)'}}/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        ];

        const subscriberControls = [
            <Box key='subscriber-video-stream-control-box' display="flex" flexDirection="row" className={classes.broadcastControlBox}>
                {videoStreams && videoStreams.length > 0 && videoStreams[0].stream ?
                    <video key={`video-${videoStreams[0].streamId}`} id={`video-${videoStreams[0].streamId}`} width="60px" height="33px" playsInline autoPlay muted className={classes.subscriberVideoStream} />
                    :
                    null
                }
                <ButtonGroup ref={this.addVideoStreamButtonRef} variant="contained" >
                    <Tooltip title={"비디오"}>
                        <Button size="small" onClick={this.toggleSubscriberVideoButtonMenu} style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0}} >
                            {this.state.loadingVideoDevices ?
                                <CircularProgress size={24} />
                                :
                                <ArrowDropDownIcon />
                            }
                        </Button>
                    </Tooltip>

                    {primaryState === VideoState.Published ?
                        smallWidth ?
                            <Button size="small" disabled={isPublishButtonClicked} onClick={() => this.props.onUnpublishVideo(VideoMode.Primary)}>
                                <StopIcon color="primary" />
                            </Button>
                            :
                            <Button size="small" startIcon={<StopIcon color="primary" />} disabled={isPublishButtonClicked} onClick={() => this.props.onUnpublishVideo(VideoMode.Primary)}>
                                중지
                            </Button>
                        :
                        null
                    }
                </ButtonGroup>

                <Popover open={this.state.subscriberVideoMenuOpen}
                         anchorEl={this.addVideoStreamButtonRef.current}
                         anchorOrigin={{
                             vertical: 'bottom',
                             horizontal: 'left',
                         }}
                         transformOrigin={{
                             vertical: 'bottom',
                             horizontal: 'left',
                         }}
                         onClose={this.handleCloseSubscriberVideoMenu}
                >
                    <MenuList>
                        {this.state.videoDevices.map(device =>
                            <MenuItem key={`video-device-${device.deviceId}`} onClick={() => this.changeSubscriberVideoStream(device.deviceId)}>
                                {device.label ? device.label : '알수없는 장치'}
                            </MenuItem>
                        )}
                    </MenuList>
                </Popover>
            </Box>,

            <Box key='subscriber-audio-stream-control-box' display="flex" flexDirection="row" className={classes.broadcastControlBox}>
                <ButtonGroup ref={this.audioDevicesButtonRef} variant="contained" >
                    <Tooltip title={"마이크"}>
                        <Button disabled={!memberMic && primaryState !== VideoState.Published} color={audioState === VideoState.Published && !audioMuted ? 'primary' : 'default'}  startIcon={smallWidth ? null : (audioState === AudioState.Published && !audioMuted ? <MicIcon /> : <MicOffIcon />)} onClick={this.handlePublishAudio} >
                            {smallWidth ?
                                (audioState === AudioState.Published && !audioMuted ? <MicIcon/> : <MicOffIcon/>)
                                :
                                <Typography color="inherit" className={classes.maxWidthTypography}>{audioDevice ? audioDevice.label ? audioDevice.label : '알수없는 장치' : ''}</Typography>
                            }
                        </Button>
                    </Tooltip>
                    <Button size="small" onClick={this.handleAudioButtonMenuToggle} >
                        {this.state.loadingAudioDevices ?
                            <CircularProgress size={24} />
                            :
                            <ArrowDropDownIcon />
                        }
                    </Button>
                </ButtonGroup>
                <Popover open={this.state.audioDevicesMenuOpen}
                         anchorEl={this.audioDevicesButtonRef.current}
                         anchorOrigin={{
                             vertical: 'top',
                             horizontal: 'left',
                         }}
                         transformOrigin={{
                             vertical: 'bottom',
                             horizontal: 'left',
                         }}
                         onClose={this.handleCloseAudioButtonMenu}
                >

                    <MenuList id="audio-devices-button-menu">
                        {this.state.audioDevices.map((device, index) => (
                            <MenuItem key={`audio-item-${device.deviceId}`}
                                      selected={audioDevice.deviceId === device.deviceId}
                                      onClick={() => this.handleChangeSelectedAudioDevice(device)}
                            >
                                {device.label ? device.label : '알수없는 장치'}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Popover>
            </Box>
        ];

        const subScreenControls = [
            <Box key='subscreen-control-box' display="flex" flexDirection="row" className={classes.broadcastControlLastBox}>
                <Box key='subscriber-volume-control-box' display="flex" flexDirection="row" className={classes.iconButtonBox}>
                    <Tooltip title="소리 조절">
                        <IconButton onClick={this.handleSoundPlay} >
                            {audioState === VideoState.Published && soundPlayed ?
                                <VolumeUpIcon color="primary" />
                                :
                                <VolumeOffIcon color="disabled" />
                            }
                        </IconButton>
                    </Tooltip>

                </Box>

                {primaryState === VideoState.Subscribed ?
                    <Box key='subscreen-quality-control-box' display='flex' flexDirection='row' className={classes.iconButtonBox}>
                        <Tooltip title="화질 설정">
                            <IconButton ref={this.qualitySubScreenButtonRef} color={primarySubstream > 0 ? 'primary' : 'default'} onClick={this.handlePrimaryHighQuality}>
                                <HighQualityIcon/>
                            </IconButton>
                        </Tooltip>
                        <Popover open={this.state.qualitySubScreenMenuOpen}
                                 anchorEl={this.qualitySubScreenButtonRef.current}
                                 anchorOrigin={{
                                     vertical: 'top',
                                     horizontal: 'right',
                                 }}
                                 transformOrigin={{
                                     vertical: 'bottom',
                                     horizontal: 'right',
                                 }}
                                 onClose={this.handleCloseQualitySubScreenMenu}
                        >
                            <Box display="flex" flexDirection="row" style={{padding: 6}}>
                                <FormControlLabel control={<Checkbox checked={primaryHighQuality} onChange={this.handleHighQualityValueChanged} />}
                                                  label='화질유지'
                                />
                            </Box>
                        </Popover>
                    </Box>
                    :
                    null
                }

                {landscape && !subVideoHidden ?
                    <Box key='subscreen-size-control-box' display="flex" flexDirection="row" className={classes.iconButtonBox}>
                        <Tooltip title="작은화면 위치 및 크기 조정">
                            <IconButton ref={this.resizeSubScreenButtonRef} onClick={this.handleResizeSubScreenMenuToggle} >
                                <PhotoSizeSelectSmallIcon style={{transform: 'scaleX(-1)'}} />
                            </IconButton>
                        </Tooltip>
                        <Popover open={this.state.resizeSubScreenMenuOpen}
                                 anchorEl={this.resizeSubScreenButtonRef.current}
                                 anchorOrigin={{
                                     vertical: 'top',
                                     horizontal: 'right',
                                 }}
                                 transformOrigin={{
                                     vertical: 'bottom',
                                     horizontal: 'right',
                                 }}
                                 onClose={this.handleCloseResizeSubScreenMenu}
                        >

                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexShrink={1}>

                                <Box display="flex" flexDirection="row" flexShrink={1}>
                                    <IconButton onClick={() => onChangeSubPosition(VideoPosition.TopLeft)}>
                                        <PictureInPictureIcon style={{transform: 'scaleX(-1)'}} />
                                    </IconButton>
                                    <IconButton onClick={() => onChangeSubPosition(VideoPosition.TopRight)}>
                                        <PictureInPictureIcon />
                                    </IconButton>

                                    <IconButton disabled={subScreenSize >= MaxSubScreenSize} onClick={this.handleIncreaseSubScreenSize}>
                                        <AddIcon />
                                    </IconButton>
                                </Box>

                                <Box display="flex" flexDirection="row" flexShrink={1}>
                                    <IconButton onClick={() => onChangeSubPosition(VideoPosition.BottomLeft)}>
                                        <PictureInPictureAltIcon style={{transform: 'scaleX(-1)'}} />
                                    </IconButton>
                                    <IconButton onClick={() => onChangeSubPosition(VideoPosition.BottomRight)}>
                                        <PictureInPictureAltIcon />
                                    </IconButton>

                                    <IconButton disabled={subScreenSize <= MinSubScreenSize} onClick={this.handleDecreaseSubScreenSize}>
                                        <RemoveIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </Popover>
                    </Box>
                    :
                    null
                }
            </Box>,
            (isPublisher || landscape) &&
            <Box key='subscreen-fullscreen-control-box' className={classes.iconButtonBox}>
                <Tooltip title={'전체화면'}>
                    <IconButton className={classes.iconButton} onClick={() => onChangeFullScreen()} >
                        {this.props.roomStore.fullScreen ?
                            <FullscreenExitIcon />
                            :
                            <FullscreenIcon />
                        }
                    </IconButton>
                </Tooltip>
            </Box>,
        ]

        if(role === 'publisher') {
            return (
                <React.Fragment>
                    {/*<Box display="flex" flexDirection="column" justifyContent="flex-start" style={{width:'100%'}}>*/}
                        <Box ref={this.videoBottomRef} display="flex" flexDirection="row" justifyContent="stretch" alignItems="center" className={this.props.roomStore.isMobile ? classes.mobileVideoBottomContainer : classes.videoBottomContainer}>
                            {publisherControls}

                            <Box key='flex-grow-box' display="flex" flexGrow={1}/>

                            {subScreenControls}
                        </Box>
                        {this.props.roomStore.isMobile &&
                            <Box ref={this.videoBottomRef} display="flex" flexDirection="column" justifyContent="flex-start" alignItems="center" className={this.props.roomStore.isMobile ? classes.mobileVideoBottomContainer1 : classes.videoBottomContainer1}>
                                {publisherButtonControls}
                            </Box>
                            // :
                            // <Box ref={this.videoBottomRef} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" className={this.props.roomStore.isMobile ? classes.mobileVideoBottomContainer1 : classes.videoBottomContainer1}>
                            //     <Hidden smUp>{publisherButtonControls}</Hidden>
                            // </Box>
                        }
                    {/*</Box>*/}
                    <ConfirmDialog maxWidth="xs"
                                   dialogOpen={this.state.lookaroundWarningOpen}
                                   title={<Typography className={classes.dialogTitle}>둘러보기 안내</Typography>}
                                   content={
                                       <Box display="flex" flexDirection="column">
                                           <Typography>학생이 수업에 입장하면 둘러보기 사용이 가능합니다.</Typography>
                                       </Box>
                                   }

                                   onOk={this.handleCloseLookAroundWarning}
                    />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Box ref={this.videoBottomRef} display="flex" flexDirection="row" justifyContent="stretch" alignItems="center" className={isMobileLandscape ? classes.mobileVideoBottomContainer : classes.videoBottomContainer}>
                        {subscriberControls}

                        <Box key='flex-grow-box' display="flex" flexGrow={1} />

                        {subScreenControls}
                    </Box>
                </React.Fragment>
            );
        }
    }
};

export default withSnackbar(withRouter(withWidth() (withStyles(styles) (RoomBroadcastControlComponent))));
import React from "react";
import {
    Avatar,
    Breadcrumbs,
    Button,
    Container,
    FormControl,
    Grid,
    Link,
    TextField,
    Typography
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
// import MemberAttendanceStateTableComponent from "./MemberAttendanceStateTableComponent";
import Footer from "../../components/Footer";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";
// import TableContainer from "@material-ui/core/TableContainer";
// import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

import CloseIcon from '@material-ui/icons/Close';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Tooltip from "@material-ui/core/Tooltip";
import _ from "lodash";

const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        marginTop: theme.spacing(2),
    },
    mainBodyContainer: {

    },


    dateContainer: {
        marginTop: theme.spacing(6),
        boxShadow: '2px 3px 3px #c1c1c1',
    },
    dateCommandGrid: {
        backgroundColor: '#191dc5',

    },
    dateNoCommandGrid: {
        backgroundColor: '#191dc5',
        textAlign: 'center',
    },
    datePicker: {
        '& input': {
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 700,
            color: '#ffffff',
            backgroundColor: '#191dc5',
        },
        '@media screen and (max-width: 600px)': {
            '& input': {
                fontSize: 14,
            },
        },
    },
    dateTextField: {
        '&::before': {
            borderBottom: 'none !important',
        },
        '& > input': {
            cursor: 'pointer',
        }
    },
    weekButton: {
        width: '100%',
        height: '100%',
    },

    cardContainer: {
        backgroundColor: '#191dc5',
        display: 'flex',
        justifyContent: 'space-evenly',

    },
    card: {
        color: '#ffffff',
        backgroundColor: '#191dc5',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        cursor: 'pointer',
        borderRadius: 16,
    },
    cardWeekContainer: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 16,
    },
    cardAvatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 16,
    },
    cardTextWeek: {
        color: '#ffffff',
        fontSize: 14,
        '@media screen and (max-width: 600px)': {
            fontSize: 12,
        }
    },
    cardTextDay: {
        color: '#ffffff',
        backgroundColor: '#c31d1f',
        fontSize: 14,
        width: 30,
        height: 30,
        '@media screen and (max-width: 600px)': {
            fontSize: 12,
        }
    },
    selectedCardTextWeek: {
        color: '#ffffff',
        fontSize: 14,
        fontWeight: 700,
    },
    selectedCardTextDay: {
        color: '#c31d1f',
        backgroundColor: '#ffffff',
        fontSize: 14,
        fontWeight: 700,
        width: 30,
        height: 30,
    },



    tableContainer: {
        paddingTop: 5,
    },
    tableHeader: {
        backgroundColor: '#fafafa',
        color: 'rgba(51, 51, 51, 0.56)',
        padding: theme.spacing(1),
        height: 56,
    },


    noHistoryContainer: {
        display: 'flex',
        width: '100%',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: theme.spacing(3),
    },
    noHistoryAvatar: {
        width: 160,
        height: 160,
        textAlign: 'center',
        border: 'solid 1px #d4d4d4',
        backgroundColor: '#FFFFFF',
    },
    noHistoryHeader: {
        fontSize: 16,
        color: '#333333',
        paddingTop: theme.spacing(5),
    },
    noHistoryEmptyBody: {
        fontSize: 14,
        color: '#b7b7b7',
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(5),
    },
});

@inject('memberAttendanceStateStore')
@observer
class MemberAttendanceState extends React.Component {
    componentDidMount() {
        this.props.memberAttendanceStateStore.initialize(this.props.userId, 5);
    }

    handleChangeSelectedDay = (day) => {
        this.props.memberAttendanceStateStore.setSelectedDay(day);
    }

    handleChangePreviousWeek = () => {
        this.props.memberAttendanceStateStore.toPreviousWeek();
    }

    handleChangeNextWeek = () => {
        this.props.memberAttendanceStateStore.toNextWeek();
    }

    render() {
        const {classes} = this.props;
        const {selectedDay, weekDays, isLoading, memberAttendanceBookColumns, memberAttendanceBookRows} = this.props.memberAttendanceStateStore;
        const dayCard = (day) => (
            <div key={`day-card-${day.format('YYYYMMDD')}`}
                 className={classes.card} onClick={() => this.handleChangeSelectedDay(day)}>
                <Grid container>
                    <Grid item xs={12} className={classes.cardWeekContainer}>
                        <Typography className={moment(day).isSame(moment(selectedDay), 'day') ? classes.selectedCardTextWeek : classes.cardTextWeek}>{day.format('dd')}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.cardAvatarContainer}>
                        <Avatar className={moment(day).isSame(moment(selectedDay), 'day') ? classes.selectedCardTextDay : classes.cardTextDay}>{day.format('DD')}</Avatar>
                    </Grid>
                </Grid>
            </div>
        );

        const columnWidth = (column) => {
            const headerCount = _.chain(memberAttendanceBookColumns).filter(c => c.type === 'header').size();
            const valueCount = _.chain(memberAttendanceBookColumns).filter(c => c.type === 'value').size();
            const headerPercent = 40;
            const valuePercent = 60;
            if(column) {
                if (column.type === 'header') {
                    return headerPercent / headerCount + '%';
                } else {
                    if(valueCount > 0) {
                        return valuePercent /valueCount + '%';
                    } else {
                        return valuePercent + '%';
                    }
                }
            } else {
                return valuePercent + '%';
            }
        }

        const columnValue = (column, data) => {
            if(column.type === 'value') {
                const result = data[column.field];
                if(result === 'Attendance') {
                    return <Tooltip title="출석"><RadioButtonUncheckedIcon /></Tooltip>;
                } else if(result === 'Lateness') {
                    return <Tooltip title="지각"><ChangeHistoryIcon /></Tooltip>;
                } else if(result === 'Absence') {
                    return <Tooltip title="결석"><CloseIcon /></Tooltip>;
                } else {
                    return '';
                }
            } else {
                return data[column.field];
            }
        }


        return (
            <React.Fragment>
                <Container component="main" className={classes.mainContainer}>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.mainContent} />
                    <Typography variant="h4" component="h2">
                        출석 현황
                    </Typography>
                    <FormControl margin={"dense"}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                            <Link color={"inherit"} underline={"none"}>출석 현황</Link>
                            <Link color={"inherit"} href={"#"} onClick={(event) => {event.preventDefault()}}>출석 현황</Link>
                        </Breadcrumbs>
                    </FormControl>

                    <div className={classes.mainBodyContainer}>
                        <Grid container className={classes.dateContainer}>
                            <Grid container item xs={12}>
                                <Grid item xs={3} className={classes.dateNoCommandGrid}>
                                    <Button onClick={this.handleChangePreviousWeek} className={classes.weekButton}>
                                        <NavigateBeforeIcon style={{color: '#ffffff'}} />
                                    </Button>
                                </Grid>
                                <Grid item xs={6} className={classes.dateCommandGrid}>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="ko">
                                        <DatePicker format="LL"
                                                    variant="inline"
                                                    className={classes.datePicker}
                                                    value={selectedDay}
                                                    onChange={this.handleChangeSelectedDay}
                                                    TextFieldComponent={(props) => <TextField {...props} disabled InputProps={{className: classes.dateTextField}} />}
                                                    autoOk fullWidth disableToolbar
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} className={classes.dateNoCommandGrid}>
                                    <Button onClick={this.handleChangeNextWeek} className={classes.weekButton}>
                                        <NavigateNextIcon style={{color: '#ffffff'}} />
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} className={classes.cardContainer}>
                                {weekDays.map((day) =>
                                    dayCard(day)
                                )}
                            </Grid>
                        </Grid>

                        <Box display="flex" justifyContent="center" className={classes.tableContainer}>
                            {isLoading ?
                                <CircularProgress/>
                                :
                                memberAttendanceBookRows && memberAttendanceBookRows.length > 0 ?
                                    <Table>
                                        <TableHead>
                                            <TableRow className={classes.tableHeader}>
                                                {memberAttendanceBookColumns.map((column) =>
                                                    <TableCell key={`member-attendance-header-${column.field}`}
                                                               align={column.align}
                                                               style={{width: columnWidth(column)}}>{column.type === 'value' ? column.title : ''}</TableCell>
                                                )}
                                                {memberAttendanceBookColumns.length <= 2 ?
                                                    <TableCell key={`member-attendance-header-empty`}
                                                               align='center'
                                                               style={{width: columnWidth()}}>
                                                    </TableCell>
                                                    :
                                                    null
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {memberAttendanceBookRows.map((row) =>
                                                <TableRow key={`member-attendance-row-${row.userId}`}>
                                                    {memberAttendanceBookColumns.map((column) =>
                                                        <TableCell key={`member-attendance-row-${row.userId}-${column.field}`}
                                                                   align={column.align}
                                                                   style={{width: columnWidth(column)}}>
                                                            {columnValue(column, row)}
                                                        </TableCell>
                                                    )}
                                                    {memberAttendanceBookColumns.length <= 2 ?
                                                        <TableCell key={`member-attendance-row-${row.userId}-empty`}
                                                                   align='center'
                                                                   style={{width: columnWidth()}}>
                                                        </TableCell>
                                                        :
                                                        null
                                                    }
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                    :
                                    <div className={classes.noHistoryContainer}>
                                        <Avatar className={classes.noHistoryAvatar}><img src="/images/nohistory.png" alt="no history"/></Avatar>
                                        <Typography className={classes.noHistoryHeader}>아직 학생이 없습니다.</Typography>
                                        <Typography className={classes.noHistoryEmptyBody}></Typography>
                                    </div>
                            }
                        </Box>
                    </div>
                </Container>

                <Footer/>
            </React.Fragment>

        )
    }
};

export default withSnackbar(withRouter(withStyles(styles) (MemberAttendanceState)));
import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {QuizType} from "../../../stores/QuizStore";
import Box from "@material-ui/core/Box";
import {List, ListItem, ListItemText} from "@material-ui/core";

export default function QuizDetailDialog(props) {
    const handleClose = () => props.setOpen(false, []);

    const getQuizTypeString = type => {
        switch (type) {
            case QuizType.SINGLE_CHOICE:
                return "객관식 단답형";
            case QuizType.MULTIPLE_CHOICE:
                return "객관식 다답형";
            case QuizType.SHORT_ANSWER:
                return "주관식 단답형";
            case QuizType.SUBJECTIVE:
                return "주관식 서술형";
            default:
                return "";
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            scroll={'paper'}
            maxWidth={'lg'}
            fullWidth={true}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle id="scroll-dialog-title">퀴즈 결과</DialogTitle>
            <DialogContent dividers={true}>
                <Grid container>
                    <Grid container item xs={1} alignItems={'center'} justify={'center'} >
                        <Typography variant={'subtitle1'}> 결과 </Typography>
                    </Grid>
                    <Grid container item xs={6} alignItems={'center'} justify={'center'} >
                        <Typography variant={'subtitle1'}> 문항 </Typography>
                    </Grid>
                    <Grid container item xs={2} alignItems={'center'} justify={'center'}>
                        <Typography variant={'subtitle1'}> 입력 </Typography>
                    </Grid>
                    <Grid container item xs={2} alignItems={'center'} justify={'center'}>
                        <Typography variant={'subtitle1'}> 답안 </Typography>
                    </Grid>
                    <Grid container item xs={1} alignItems={'center'} justify={'center'}>
                        <Typography variant={'subtitle1'}>유형 </Typography>
                    </Grid>

                    <Grid container item xs={12} alignItems={'center'} justify={'center'}>
                        <Box borderBottom={1} borderColor={"grey.500"} style={{width: '100%', marginTop: '10px', marginBottom: '10px'}} />
                    </Grid>

                    {props.data.map((quiz, i) => {
                        const correctOptionNumberList = quiz.channelQuizOptionList.filter(option => option.correctFlag).map(option => option.quizOptionNumber);
                        // const correctOptionContentList = quiz.channelQuizOptionList.filter(option => option.correctFlag).map(option => option.quizOptionContents);
                        const answerOptionList = quiz.channelQuizOptionList.filter(option => quiz.channelQuizAnswerList.filter(answer => answer.userId === props.userId).some(answer => option.channelQuizOptionId === answer.channelQuizOptionId));
                        const answerOptionNumberList = answerOptionList.map(option => option.quizOptionNumber);
                        // const answerOptionContentList = answerOptionList.filter(option => option.correctFlag).map(option => option.quizOptionContents);
                        const isCorrect = correctOptionNumberList.length === answerOptionNumberList.length && answerOptionList.every(option => option.correctFlag);
                        return (
                            <Grid container key={"quiz-detail-" + quiz.quizQuestionId + "-" + (i + 1)} style={{marginBottom: "20px"}} direction="row" justify={'flex-start'} alignItems={'center'}>
                                <Grid container item xs={1} style={{color: isCorrect ? "#0000ff" : "#ff0000"}} alignItems={'center'} justify={'center'}>
                                    <Typography component={'span'} color={"inherit"}>
                                        {(quiz.quizType === QuizType.SINGLE_CHOICE || quiz.quizType === QuizType.MULTIPLE_CHOICE) ? isCorrect ? "O" : "X" : "•"}
                                    </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                    <Typography component={'span'}>
                                        {quiz.quizQuestion}
                                    </Typography>
                                    {quiz.channelQuizImageList.map((quizImage, index) => (
                                        <Grid item xs={1} key={`quizDetailImagePreview-${index}`}>
                                            <img src={`data:${quizImage.type};base64,${quizImage.image}`} width={"100%"} alt="" onDoubleClick={e => props.onImageDoubleClick(quizImage)} />
                                        </Grid>
                                    ))}
                                    {(quiz.quizType === QuizType.SINGLE_CHOICE || quiz.quizType === QuizType.MULTIPLE_CHOICE) &&
                                        <Grid item xs={12}>
                                            <List style={{overflow: 'auto'}}>
                                                {quiz.channelQuizOptionList.map((option) =>
                                                    <ListItem key={`update-quiz-option-item-${option.quizOptionNumber}`}>
                                                        <ListItemText primary={
                                                            <Grid container style={{margin: 0, paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 16}}>
                                                                <Grid item xs={1} style={{display: 'flex', alignItems: 'center'}}>
                                                                    <Typography variant="body1" style={{fontSize: 14}}> {`${option.quizOptionNumber}번.`}</Typography>
                                                                </Grid>
                                                                <Grid item xs={11}>
                                                                    <Typography variant="body1" style={{fontSize: 14}}> {option.quizOptionContents}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                            // secondary={
                                                            //     <Typography variant="body1" style={{fontSize: 14}}> {option.correctFlag ? '정답' : '오답'}</Typography>
                                                            // }
                                                        />
                                                    </ListItem>
                                                )}
                                            </List>
                                        </Grid>
                                    }
                                </Grid>
                                {quiz.quizType === QuizType.SINGLE_CHOICE || quiz.quizType === QuizType.MULTIPLE_CHOICE ?
                                    <>
                                        <Grid container item xs={2} alignItems={'center'} justify={'center'}>
                                            <Typography component={'span'}>
                                                {answerOptionNumberList.join(",")}
                                            </Typography>
                                        </Grid>
                                        <Grid container item xs={2} alignItems={'center'} justify={'center'}>
                                            <Typography component={'span'}>
                                                {correctOptionNumberList.join(",")}
                                            </Typography>
                                        </Grid>
                                    </>
                                    :
                                    <>
                                        {quiz.quizType === QuizType.SHORT_ANSWER ?
                                            <Grid container item xs={2} alignItems={'center'} justify={'center'}>
                                                <Typography component={'span'}>
                                                    {quiz.channelQuizAnswerList[0].quizOptionContents}
                                                </Typography>
                                            </Grid>
                                            :
                                            <Grid container item xs={2}>
                                                <Typography component={'span'}>
                                                    {quiz.channelQuizAnswerList[0].quizOptionContents}
                                                </Typography>
                                            </Grid>
                                        }
                                        <Grid container item xs={2} alignItems={'center'} justify={'center'}>
                                            <Typography component={'span'}>
                                                •
                                            </Typography>
                                        </Grid>
                                    </>
                                }

                                <Grid container item xs={1} alignItems={'center'} justify={'center'}>
                                    <Typography component={'span'}>
                                        {getQuizTypeString(quiz.quizType)}
                                    </Typography>
                                </Grid>

                                <Grid container item xs={12} alignItems={'center'} justify={'center'} >
                                    <Box borderBottom={1} borderColor={"grey.500"} style={{width: '100%', marginTop: '10px'}} />
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    );
}
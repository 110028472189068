import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Box, Button, DialogActions, DialogContent, Typography} from "@material-ui/core";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {makeStyles} from "@material-ui/core/styles";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Paper from "@material-ui/core/Paper";
import Draggable from 'react-draggable';
import DialogTitle from "@material-ui/core/DialogTitle";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";

const useStyles = makeStyles({
    presentation: {
        // '& button': {
        //     display: 'none'
        // },
        // '&:hover, &:focus': {
        //     border: "solid 5px #fdea82",
        //     // '& button:first-child': {
        //     '& button': {
        //         display: 'inline-block'
        //     }
        // },
    }
    // btn: {
    //     color: 'rgba(255, 255, 255, 1.0)',
    //     "$:hover": {
    //         cursor: 'help',
    //         color: '#fdea82',
    //     }
    // }
});

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export default function BroadcastTeacherLookAroundDialog(props) {
    const classes = useStyles();
    const { open, userInfo, chatUserList, handleClose, channel, chatSendControlLookAroundUnderstand, lookAroundUnderstandList, mutedControl, chatDoAnnounce, isLookAroundSoundOn } = props;

    const getBorder = (userId) => {
        let understandObj = lookAroundUnderstandList.find((item) => item.userId === userId);
        if(understandObj) {
            return understandObj.value ? "solid 5px blue" : "solid 5px red";
        }

        return "none"
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"lg"}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                disableBackdropClick
        >
            <DialogTitle style={{
                cursor: 'move',
            }} id="draggable-dialog-title">
                둘러보기
            </DialogTitle>
            <DialogContent
                // id="draggable-dialog-title"
                style={{
                backgroundColor: '#000000',
                // cursor: 'move'
            }}>
                <div style={{ display: 'flex', flexWrap: "wrap" }}>
                    {(chatUserList !== undefined && chatUserList !== null) && chatUserList.map(user => (
                        <React.Fragment key={"lookAround-dialog-" + user.userId}>
                            <div
                                key={user.userId}
                                style={{
                                    position: 'relative',
                                    display: 'flex',
                                    border: getBorder(user.userId),
                                }}
                                className={classes.presentation}
                                onMouseOver={(event) => {
                                    if(channel && userInfo && channel.userId === userInfo.userId && channel.userId !== user.userId) {
                                        event.currentTarget.style.border = "solid 5px #fdea82";
                                        document.getElementById("presentationBtn" + user.userId).style.display = '';
                                    }
                                }}
                                onMouseOut={(event) => {
                                    if(channel && userInfo && channel.userId === userInfo.userId && channel.userId !== user.userId) {
                                        document.getElementById("presentationBtn" + user.userId).style.display = 'none';
                                        event.currentTarget.style.border = getBorder(user.userId);
                                    }
                                }}
                            >
                                <video id={`lookAround-${user.userId}`}
                                       style={{
                                           maxWidth: '160px',
                                           maxHeight: '160px',
                                       }}
                                />
                                <Button
                                    id={"presentationBtn" + user.userId}
                                    // className={'preButton'}
                                    style={{
                                        display: 'none',
                                        position: 'absolute',
                                        left: '50%',
                                        top: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-26px',
                                        opacity: '0.76',
                                        backgroundColor: '#fdea82',
                                        color: '#333333',
                                        textAlign: 'center',
                                        padding: '6px',
                                        borderRadius: '18px',
                                        boxShadow: '0px 2px 5px 0 rgba(193, 191, 178, 0.45)'
                                    }}
                                    onClick={() => {
                                        chatDoAnnounce(user.userId);
                                        handleClose();
                                    }}
                                >
                                    발표
                                </Button>
                                <div
                                    style={{
                                        position: 'absolute',
                                        display: 'inline-block',
                                        opacity: '0.76',
                                        backgroundColor: '#000000',
                                        color: '#ffffff',
                                        textAlign: 'center',
                                        padding: '6px',
                                        bottom: '0px',
                                        left: '0px'
                                    }}
                                >{user.userName}</div>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </DialogContent>
            <DialogActions
                style={{
                    justifyContent: "flex-start",
                    backgroundColor: '#353535'
                }}
            >

                <div style={{
                    textAlign: 'center',
                    display: 'flex'
                }}
                >
                    <Button style={{
                        alignItems: 'center',
                        color: 'rgba(255, 255, 255, 1.0)',
                    }}
                            onClick={isLookAroundSoundOn ? () => mutedControl(true) : () => mutedControl(false)}
                            onMouseOver={(event) => event.currentTarget.style.color = '#fdea82'}
                            onMouseOut={(event) => event.currentTarget.style.color = 'rgba(255, 255, 255, 1.0)'}
                    >
                        <div>
                            {isLookAroundSoundOn ? <VolumeUpIcon fontSize={"large"} style={{"color":"white"}} onClick={() => mutedControl(true)}/> : <VolumeOffIcon fontSize={"large"} style={{"color":"white"}} onClick={() => mutedControl(false)}/>}
                            <br/>
                            {isLookAroundSoundOn ?  "끄기" : "켜기"}
                        </div>
                    </Button>
                </div>
                <Box display="flex" justifyContent={channel.userId !== userInfo.userId ? 'center' : 'flex-start'}  style={{width: '100%'}}>
                    {channel.userId !== userInfo.userId ?
                        <React.Fragment>
                            <Button
                                style={{color: 'rgba(255, 255, 255, 1.0)'}}
                                onClick={() => chatSendControlLookAroundUnderstand(true)}
                                onMouseOver={(event) => event.currentTarget.style.color = '#fdea82'}
                                onMouseOut={(event) => event.currentTarget.style.color = 'rgba(255, 255, 255, 1.0)'}
                            >
                                <div>
                                    <CheckIcon fontSize={"large"}/><br/>예
                                </div>
                            </Button>

                            <Button style={{ marginLeft: '50px', color: 'rgba(255, 255, 255, 1.0)'}}
                                    onClick={() => chatSendControlLookAroundUnderstand(false)}
                                    onMouseOver={(event) => event.currentTarget.style.color = '#fdea82'}
                                    onMouseOut={(event) => event.currentTarget.style.color = 'rgba(255, 255, 255, 1.0)'}
                            >
                                <div>
                                    <CloseIcon fontSize={"large"}/><br/>아니오
                                </div>
                            </Button>
                        </React.Fragment>
                        :
                        <React.Fragment>
                            <Box display="flex" flexDirection="column">
                                <Typography style={{ marginLeft: '50px', color: 'rgba(255, 255, 255, 1.0)'}}>둘러보기 중 학생을 선택하면 <b>발표하기</b>가 가능합니다.</Typography>
                                <Typography style={{ marginLeft: '50px', color: 'rgba(255, 255, 255, 1.0)'}}>또한, 발표가 진행 중인 부화면을 클릭하면 발표를 중지시킬 수 있습니다.</Typography>
                            </Box>
                        </React.Fragment>
                    }
                </Box>
                <div style={{
                        textAlign: 'center',
                        display: 'flex'
                    }}
                >
                    <Button style={{
                                alignItems: 'center',
                                color: 'rgba(255, 255, 255, 1.0)',
                            }}
                            onClick={() => {handleClose()}}
                            onMouseOver={(event) => event.currentTarget.style.color = '#fdea82'}
                            onMouseOut={(event) => event.currentTarget.style.color = 'rgba(255, 255, 255, 1.0)'}
                    >
                        <div>
                            <ExitToAppIcon fontSize={"large"}/><br/>
                            닫기
                        </div>
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}
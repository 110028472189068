import React from "react";
import {inject, observer} from "mobx-react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {Drawer, Typography} from "@material-ui/core";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

const styles = theme => ({
    helpContainer: {
        width: 350,
        padding: theme.spacing(4),
        boxSizing:'border-box'
    },
    helpLogoContainer: {
        display: 'flex',
        flexDirection:'column',
        textAlign:'center'

    },
    helpLogoImage: {
        padding: theme.spacing(2,0,1)
    },
    helpLogoText: {
        fontSize: 22,
        fontWeight: 800,
        margin:theme.spacing(2,0,1),
    },
    helpPageTitle:{
        textAlign:'center',
        fontSize:'1.1em',
    },
    helpTitleContainer: {
        paddingTop: theme.spacing(2),
        paddingLeft:'20px',
        lineHeight:'1.45',
        '& > li':{
            fontSize:'1em',
            marginBottom:'1.5em',
        },
        '& b':{
            borderBottom:'1px solid #333'
        }
    },
    helpBodyContainer: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        '& > li':{
            fontSize:'0.9em'
        }
    },
    helpIcon:{
        zIndex: theme.zIndex.drawer + 2,
        position: 'fixed',
        top: '40px',
        right: '8px',
        boxShadow: 'none',
        background:'none',
        width:'36px',
        fontSize: 15,
        '&:hover, &:focus, &:active':{
            background:'none',
            boxShadow:'none',
        },
        '& svg':{
            width:36,
            height:36,
            marginRight:4,
            boxShadow:'0 2px 3px #c1c1c1',
            borderRadius:'50px'
        },
        '& span':{
            color:'rgba(51,51,51,0.56)'
        }
    },
    helpCloseIcon: {
        position: 'absolute',
        right: '1rem',
        top: '1rem',
        color: 'rgba(51,51,51,0.37)',
        width:'40px',
        height:'40px',
        cursor: 'pointer',
    },
});

@inject("helpStore", "broadcastStore")
@observer
class BroadcastHelp extends React.Component {
    componentDidMount() {
    }

    render() {
        const {classes} = this.props;
        const {channel, userId} = this.props.broadcastStore;

        return(
            <React.Fragment>
                <Drawer anchor="right" open={this.props.helpStore.open} onClose={() => this.props.helpStore.closeHelp()} >
                    <CloseRoundedIcon className={classes.helpCloseIcon} onClick={() => this.props.helpStore.closeHelp()}/>

                    <div className={classes.helpContainer}>
                        <div className={classes.helpLogoContainer}>
                            <div className={classes.helpLogoImage}><img src="/images/logo.png" alt="ONTHELIVE" /></div>
                            <Typography className={classes.helpLogoText}>도움말</Typography>
                        </div>

                        <div>
                            <Typography className={classes.helpPageTitle} variant="h5" >수업 보기</Typography>
                            { channel && channel.userId === userId ?
                                <React.Fragment>
                                    <ol className={classes.helpTitleContainer}>
                                        <li>영상을 재생하려면 비디오 영역을 클릭해주세요
                                            <ul className={classes.helpBodyContainer}>
                                                <li>영상 재생은 주 화면과 부 화면이 동시에 재생되고 동시에 멈춥니다.</li>
                                            </ul>
                                        </li>
                                        <li>학생들에게 출석 요청을 하려면 화면 왼쪽의 '출석 요청' 버튼을 눌러주세요.
                                            <ul className={classes.helpBodyContainer}>
                                                <li>채팅 메세지에서 몇 명에게 출석 요청을 보냈는지 확인 할 수 있습니댜.</li>
                                                <li>학생이 출석을 완료할 경우 채팅 메세지에 누가 출석했는지 확인됩니다.</li>
                                            </ul>
                                        </li>
                                        <li>부화면 전환 클릭시 주 화면과 부 화면이 전환됩니다.
                                            <ul className={classes.helpBodyContainer}>
                                                <li>주 화면은 화면 왼쪽 부 화면은 채팅 영역 위에 작은 사각형으로 표시됩니다.</li>
                                                <li>부 화면은 채널 생성시 부화면 사용을 선택하여야만 표시됩니다.</li>
                                            </ul>
                                        </li>
                                        <li>수업 종료 버튼 클릭시 수업이 완전히 종료 되고 수업에 다시 입장 할 수 없습니다.</li>
                                        <li>나가기 버튼 클릭 시 수업 목록 화면으로 돌아갑니다.</li>
                                    </ol>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <ol className={classes.helpTitleContainer}>
                                        <li>전체화면 클릭시 주 화면이 전체화면으로 전환됩니다.</li>
                                        <li>주화면과 부화면은 선생님이 전환 할 수 있습니다.</li>
                                        <li>선생님이 출석 요청을 하면 출석 하기 버튼이 활성화됩니다
                                            <ul className={classes.helpBodyContainer}>
                                                <li>채팅 창에 표시되는 출석 체크 인증 번호를 입력하여야 출석이 완료됩니다</li>
                                            </ul>
                                        </li>
                                    </ol>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </Drawer>
            </React.Fragment>
        );
    }
}

export default withSnackbar(withRouter(withStyles(styles) (BroadcastHelp)));
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";

import {
    Breadcrumbs,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogTitle,
    Drawer,
    Fab,
    FormControl,
    Grid,
    Link,
    Typography
} from "@material-ui/core";
import {Alert, AlertTitle} from '@material-ui/lab';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import HelpIcon from "@material-ui/icons/Help";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {inject, observer} from "mobx-react";
import {toJS} from "mobx";
import moment from "moment";

import Footer from "../../components/Footer";
import ChannelSummaryComponent from "./ChannelSummaryComponent";
import ChannelInputComponent from "./ChannelInputComponent";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";


const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        marginTop: theme.spacing(2),
    },
    tipArea: {
        padding: '16px',
        margin: theme.spacing(5,0,3),
        '& div:nth-child(2) > div' :{
            color : theme.palette.primary.main,
            fontSize:'1rem'
        },
        '& div ol' :{
            padding:'0 0 0 1em',
        },
        '& div ol li' :{
            marginTop:theme.spacing(1)
        },
        '& div ol li:first-child' :{
            marginTop:0
        },
    },
    commandButton: {
        borderRadius: 24,
    },
    createButton: {
        padding: '12px 0',
        borderRadius: '50px',
        width:'180px',
        fontSize:'16px',
        fontWeight:'800',
    },

    helpContainer: {
        width: 350,
        padding: theme.spacing(4),
        boxSizing:'border-box'
    },
    helpLogoContainer: {
        display: 'flex',
        flexDirection:'column',
        textAlign:'center'

    },
    helpLogoImage: {
        padding: theme.spacing(2,0,1)
    },
    helpLogoText: {
        fontSize: 22,
        fontWeight: 800,
        margin:theme.spacing(2,0,1),
    },
    helpPageTitle:{
        textAlign:'center',
        fontSize:'1.1em',
    },
    helpTitleContainer: {
        paddingTop: theme.spacing(2),
        paddingLeft:'20px',
        lineHeight:'1.45',
        '& > li':{
            fontSize:'1em',
            marginBottom:'1.5em',
        },
        '& b':{
            borderBottom:'1px solid #333'
        }
    },
    helpBodyContainer: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        '& > li':{
            fontSize:'0.9em'
        }
    },
    helpIcon:{
        position: 'fixed',
        bottom: '35px',
        right: '35px',
        boxShadow: 'none',
        background:'none',
        width:'85px',
        fontSize: 15,
        '&:hover, &:focus, &:active':{
            background:'none',
            boxShadow:'none',
        },
        '& svg':{
            width:36,
            height:36,
            marginRight:4,
            boxShadow:'0 2px 3px #c1c1c1',
            borderRadius:'50px'
        },
        '& span':{
            color:'rgba(51,51,51,0.56)'
        }
    },
    helpCloseIcon: {
        position: 'absolute',
        right: '1rem',
        top: '1rem',
        color: 'rgba(51,51,51,0.37)',
        width:'40px',
        height:'40px',
        cursor: 'pointer',
    },
});


@inject('channelCreateStore', 'helpStore')
@observer
class ChannelCreate extends React.Component {
    componentDidMount() {
        this.props.channelCreateStore.clearCreateState();
        this.props.channelCreateStore.getUserPoint(this.props.userId);
        this.props.channelCreateStore.getGroupList(this.props.userId);
        this.props.channelCreateStore.initLiveStartDateTime(this.props.match.params.selectedDay);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.channelCreateStore.isCreated) {
            this.props.enqueueSnackbar('새로운 강의을 생성 하였습니다.', {
                variant: 'info'
            });

            const startDate = moment(this.props.channelCreateStore.channel.liveStartDatetime).format('YYYY-MM-DD');
            this.props.channelCreateStore.clearCreateState();
            this.props.history.push(`/channel/list?date=${startDate}`);
        }

        if(this.props.channelCreateStore.isCreateFailed) {
            this.props.enqueueSnackbar('새로운 강의 생성에 실패하였습니다.', {
                variant: 'error'
            });
        }
    }

    handleClickCreate = () => {
        this.props.channelCreateStore.toSummary();
    }

    handleClickPrevious = () => {
        this.props.channelCreateStore.toEdit();
    }

    handleClickOk = () => {
        this.props.channelCreateStore.createChannel();
    }

    handleCreateGroupDialogClose = () => {
        this.props.channelCreateStore.changeCreateGroupDialogOpen(false);
    }


    render() {
        const { classes  } = this.props;
        const isBeforeStartTime = toJS(this.props.channelCreateStore.isBeforeStartTime);
        const isOverMember = toJS(this.props.channelCreateStore.isOverMember);
        const isCreatable = toJS(this.props.channelCreateStore.isCreatable);
        const isCreating = toJS(this.props.channelCreateStore.isCreating);
        const isSummary = toJS(this.props.channelCreateStore.isSummary);
        const channel = toJS(this.props.channelCreateStore.channel);
        const memberCount = toJS(this.props.channelCreateStore.memberCount);
        const createGroupDialogOpen = toJS(this.props.channelCreateStore.createGroupDialogOpen);
        const filteredGroupList = toJS(this.props.channelCreateStore.filteredGroupList);
        const selectedGroupId = toJS(this.props.channelCreateStore.selectedGroupId);
        const availablePoint = toJS(this.props.channelCreateStore.availablePoint);
        // console.log('isCreatable : ' + isCreatable);
        // console.log(channel);
        return (
            <React.Fragment>
                <Container component="main" maxWidth={"md"} className={classes.mainContainer}>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.mainContent} />
                    <Typography variant="h4" component="h2">
                        강의 만들기
                    </Typography>
                    <FormControl margin={"dense"}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                            <Link color={"inherit"} underline={"none"}>강의</Link>
                            <Link color={"inherit"} href={"#"} onClick={(event) => {event.preventDefault()}}>강의 만들기</Link>
                        </Breadcrumbs>
                    </FormControl>

                    {isSummary ?
                        <ChannelSummaryComponent channel={channel}
                                                 memberCount={memberCount}
                                                 availablePoint={availablePoint}
                        />
                        :
                        <ChannelInputComponent userId={this.props.userId}
                                               channel={channel}
                                               memberCount={memberCount}
                                               groupList={filteredGroupList}
                                               selectedGroupId={selectedGroupId}
                                               isBeforeStartTime={isBeforeStartTime}
                                               isOverMember={isOverMember}

                                               changeChannelName={(name) => this.props.channelCreateStore.changeChannelName(name)}
                                               changeChannelDesc={(desc) => this.props.channelCreateStore.changeChannelDesc(desc)}
                                               changeChannelStartDate={(date) => this.props.channelCreateStore.changeChannelStartDate(date)}
                                               changeChannelStartAmPm={(isAm) => this.props.channelCreateStore.changeChannelStartAmPm(isAm)}
                                               changeChannelStartHour={(hour) => this.props.channelCreateStore.changeChannelStartHour(hour)}
                                               changeChannelStartMinute={(minute) => this.props.channelCreateStore.changeChannelStartMinute(minute)}
                                               changeChannelLiveTime={(liveTime) => this.props.channelCreateStore.changeChannelLiveTime(liveTime)}
                                               changeSelectedGroup={(group) => this.props.channelCreateStore.changeSelectedGroup(group)}
                                               removeGroup={(groupId) => this.props.channelCreateStore.removeGroup(groupId)}
                                               changeChannelJoinLimitCount={(limitCount) => this.props.channelCreateStore.changeChannelJoinLimitCount(limitCount)}
                                               changeChannelSubScreenAvailableFlag={(flag) => this.props.channelCreateStore.changeChannelSubScreenAvailableFlag(flag)}
                                               changeChannelVersion={(version) => this.props.channelCreateStore.changeChannelVersion(version)}

                        />
                    }

                    <Grid container>
                        <Grid item xs={12}>
                            <Alert severity="info" className={classes.tipArea} margin={"normal"}>
                                <AlertTitle style={{color: 'RGBA(0, 0, 0, 1)'}}>주의사항</AlertTitle>
                                <ol>
                                    <li>강의 시작 시각은 현재 시각 이후로 설정할 수 있습니다. 실제 강의 시각에 맞게 선택하여 주세요.</li>
                                    <li>강의 생성 이후에 '강의정보변경'을 통해 강의 시각, 시간 및 참여 인원 수를 변경 할 수 있습니다.</li>
                                    <li>v2.0의 강의 화면을 선택하시면, 새로운 강의 화면을 사용하실 수 있습니다.</li>
                                    {/*<li>강의 생성 시 ‘최대 참여자 수‘ 와 '시간'을 고려하여 과금됩니다.</li>*/}
                                    {/*<li>단, 현재는 무료 체험기간으로 이용권 구매시 별도 비용이 발생하지 않습니다.</li>*/}
                                    {/*<li>무료체험 기간이 종료되면, 보유한 포인트는 모두 소멸됩니다.</li>*/}
                                </ol>
                            </Alert>
                        </Grid>

                        {isSummary ?
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} style={{textAlign: 'right'}}>
                                        <FormControl margin="normal">
                                            <Button variant="contained" className={classes.commandButton} onClick={this.handleClickPrevious}>
                                                이전
                                            </Button>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={6}style={{textAlign: 'left'}}>
                                        <FormControl margin="normal">
                                                <Button color="primary" variant="contained" disabled={(!isCreatable) || (isCreating)} className={classes.commandButton} onClick={this.handleClickOk}>
                                                    {isCreating ? <CircularProgress size={22}/> : '확인'}
                                                </Button>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            :
                            <Grid item xs={12} style={{textAlign: 'center'}}>
                                <FormControl margin="normal">
                                    <Button color="primary" variant="contained" className={classes.createButton} disabled={!isCreatable} onClick={this.handleClickCreate}>
                                        강의 만들기
                                    </Button>
                                </FormControl>
                            </Grid>
                        }
                    </Grid>

                    <Dialog fullWidth
                            maxWidth="xs"
                            className={classes.dialog}
                            open={createGroupDialogOpen}
                            onClose={this.handleCreateGroupDialogClose}
                            aria-labelledby="create-group-dialog-title" >
                        <DialogTitle id="create-group-dialog-title" className={classes.dialogTitle}>
                            그룹 목록으로 이동
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="create-group-dialog-description">
                                현재 그룹이 없습니다.<br/>강의에 학생을 참여시키기 위해서는 먼저 그룹 생성이 필요합니다.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleCreateGroupDialogClose} >
                                취소
                            </Button>
                            <Button onClick={() => this.props.history.push("/group/list")} color="primary" autoFocus>
                                확인
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Container>

                <Footer/>

                <div>
                    <Fab size="small" className={classes.helpIcon} onClick={() => this.props.helpStore.openHelp()} >
                        <HelpIcon color="primary" viewBox="2 2 20 20" /> <span>도움말</span>
                    </Fab>

                    <Drawer anchor="right" open={this.props.helpStore.open} onClose={() => this.props.helpStore.closeHelp()}>
                        <CloseRoundedIcon className={classes.helpCloseIcon} onClick={() => this.props.helpStore.closeHelp()}/>

                        <div className={classes.helpContainer}>
                            <div className={classes.helpLogoContainer}>
                                <div className={classes.helpLogoImage}><img src="/images/logo-vert.png" alt="ONTHELIVE" /></div>
                                <Typography className={classes.helpLogoText}>도움말</Typography>
                            </div>
                            <div>
                                <Typography className={classes.helpPageTitle} variant="h5" >강의 만들기</Typography>
                                <ol className={classes.helpTitleContainer}>
                                    <li>강의명, 강의설명, 시작 시각, 강의 시간, 최대 참여자 수, 부화면 사용 여부까지 강의에 적합하게 입력하고 <b>'강의 만들기' 버튼을 클릭</b>하여 강의을 만듭니다.
                                        <ul className={classes.helpBodyContainer}>
                                            <li>강의 시작 시각은 현재 시각 이후로 지정하여야 합니다.</li>
                                        </ul>
                                    </li>
                                    <li>참여대상은 <b>그룹으로 추가</b>할 수 있습니다.
                                        <ul className={classes.helpBodyContainer}>
                                            <li>미리 만들어진 그룹을 불러와서 간단하게 참여 대상 추가가 가능합니다.</li>
                                            <li>만약, 그룹이 없다면 그룹을 먼저 생성해 주세요.</li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </Drawer>
                </div>
            </React.Fragment>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (ChannelCreate)));

import _ from "lodash";

const DefaultCallbacks = {
    onPublishing: undefined,
    onPublished: undefined,
    onLocalStreamAttached: undefined,
    onPublishError: undefined,
    onUnpublishing: undefined,
    onUnpublished: undefined,

    onSubscribing: undefined,
    onRemoteStreamAttached: undefined,
    onRemoteStreamEmpty: undefined,
    onRemoteAudioAttached: undefined,
    onRemoteAudioEmpty: undefined,
    onStarted: undefined,
    onSubscribed: undefined,
    onSubscribeError: undefined,
    onUnsubscribing: undefined,
    onUnsubscribed: undefined,
    onSubstreamChanged: undefined,
}

const LogPrefix = '[MONITORING VIDEO] ';
const PollingTime = 1000;
const MaxRetryCount = 20;

export class MonitoringVideo {
    constructor(videoMode, janus, pluginHandle, roomId, displayName, videoRef, callbacks) {
        this.videoMode = videoMode;
        this.janus = janus;
        this.roomId = roomId;
        this.displayName = displayName;
        this.videoRef = videoRef;
        this.pluginHandle = pluginHandle;

        this.callbacks = Object.assign({}, DefaultCallbacks, callbacks);
    }

    leave = (callbacks) => {
        if(this.callbacks.onUnsubscribing) {
            this.callbacks.onUnsubscribing();
        }
        const {pluginHandle} = this;
        const leaveRequest = {
            message: {
                request: 'leave',
            },
            success: (data) => {
                console.log(LogPrefix, 'Leave command sended successfully', data);

                if(callbacks && callbacks.onSuccess) {
                    callbacks.onSuccess();
                }
            },
            error: (error) => {
                console.log(LogPrefix, 'Leave command send failed', error);
            }
        };

        console.log(LogPrefix, 'Sending leave command...');
        pluginHandle.send(leaveRequest);
    }

    setSimulcastSubstream = (substream) => {
        const {pluginHandle} = this;
        const configureRequest = {
            message: {
                request: 'configure',
                substream: substream,
            },

            success: (data) => {
                console.log(LogPrefix, 'Configure command for substream sended successfully', data);
            },
            error: (error) => {
                console.log(LogPrefix, 'Configure command for send failed', error);
            }
        }

        console.log(LogPrefix, 'Sending configure command... substream=' + substream);
        pluginHandle.send(configureRequest);
    }

    _createRoom = (id) => {
        const {roomId, pluginHandle} = this;

        const that = this;
        const existsRequest = {
            message: {
                request: 'exists',
                room: roomId,
            },

            success: (data) => {
                console.log(LogPrefix, 'Exists command sended successfully', data);

                if(data.exists) {
                    console.log(LogPrefix, `Room is already exists : ${roomId}`);

                    that._joinRoomToSubscriber(id);
                } else {
                    const createRequest = {
                        message: {
                            request: 'create',
                            room: roomId,
                            publishers: 300,
                            permanent: false,
                            description: `OnTheLive Monitoring ${roomId}`,
                            notify_joining: false,
                        },
                        success: (data) => {
                            console.log(LogPrefix, 'Create command sended successfully', data);

                            that._joinRoomToSubscriber(id);
                        },
                        error: (error) => {
                            console.log(LogPrefix, 'Create command send failed', error);

                            if(that.callbacks.onPublishError) {
                                that.callbacks.onPublishError("Create command failed");
                            }
                        }
                    }

                    console.log(LogPrefix, 'Sending create command...');
                    pluginHandle.send(createRequest);
                }
            },
            error: (error) => {
                console.log(LogPrefix, 'Exists command send failed', error);

                if(that.callbacks.onPublishError) {
                    that.callbacks.onPublishError("Exists command failed");
                }
            }
        };

        console.log(LogPrefix, 'Sending exists command...', roomId, id);
        pluginHandle.send(existsRequest);
    };

    _checkRoom = (feed) => {
        const {roomId, pluginHandle} = this;
        const that = this;
        const existsRequest = {
            message: {
                request: 'exists',
                room: roomId,
            },
            success: (data) => {
                console.log(LogPrefix, 'Exists command sended successfully', data);

                if(data.exists) {
                    that._findParticipants(feed);
                } else {
                    console.log(LogPrefix, 'onSubscribeError', data.error);
                    setTimeout(() => this._checkRoom(feed), PollingTime);
                }
            },
            error: (error) => {
                console.log(LogPrefix, 'Exists command send failed', error);
            }
        };

        console.log(LogPrefix, 'Sending exists command...', roomId, feed);
        pluginHandle.send(existsRequest);
    }

    _findParticipants = (feed) => {
        const {pluginHandle, roomId} = this;
        const that = this;

        let retryCount = 0;
        const doFindParticipants = () => {
            const participantsCommand = {
                message: {
                    request: 'listparticipants',
                    room: roomId,
                },
                success: (data) => {
                    console.log(`[MonitoringVideo ${that.videoMode}]`, 'Listparticipants command success : data = ', data);

                    const publisher = _.find(data.participants, (p) => p.publisher && p.id === feed);
                    if(publisher) {
                        console.log(`[MonitoringVideo ${that.videoMode}]`, 'Find a publisher', publisher);

                        this._joinRoomToSubscriber(feed);
                    } else {
                        if(retryCount < MaxRetryCount) {
                            retryCount++;
                            setTimeout(() => doFindParticipants(), PollingTime);
                        } else {
                            if(that.callbacks.onSubscribeError) {
                                that.callbacks.onSubscribeError();
                            }
                        }

                    }
                },
                error: (error) => {
                    console.log(`[MonitoringVideo ${that.videoMode}]`, 'Listparticipants command error : error = ', error);

                    if(retryCount < MaxRetryCount) {
                        retryCount++;
                        setTimeout(() => doFindParticipants(), PollingTime);
                    } else {
                        if(that.callbacks.onSubscribeError) {
                            that.callbacks.onSubscribeError();
                        }
                    }
                },
            };

            console.log(`[MonitoringVideo ${this.videoMode}]`, 'Sending listparticipants command', participantsCommand.message);
            pluginHandle.send(participantsCommand);
        }

        doFindParticipants();
    };

    _joinRoomToSubscriber = (feed) => {
        const {roomId, pluginHandle} = this;
        const joinRequest = {
            message : {
                request: 'join',
                room: roomId,
                ptype: 'subscriber',
                feed: feed,
                'close_pc': false,              // For switch command
            },
            success: (data) => {
                console.log(LogPrefix, 'Join command sended successfully', data);
            },
            error: (error) => {
                console.log(LogPrefix, 'Join command send failed', error);
            }
        };

        console.log(LogPrefix, 'Sending join command...', roomId, feed);
        pluginHandle.send(joinRequest);
    }
}
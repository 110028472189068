import React, {useEffect} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Box, Button, DialogActions, DialogContent} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Paper from "@material-ui/core/Paper";
import Draggable from 'react-draggable';
import DialogTitle from "@material-ui/core/DialogTitle";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import Grid from "@material-ui/core/Grid";

// const useStyles = makeStyles({
    // presentation: {
        // '& button': {
        //     display: 'none'
        // },
        // '&:hover, &:focus': {
        //     border: "solid 5px #fdea82",
        //     // '& button:first-child': {
        //     '& button': {
        //         display: 'inline-block'
        //     }
        // },
    // }
    // btn: {
    //     color: 'rgba(255, 255, 255, 1.0)',
    //     "$:hover": {
    //         cursor: 'help',
    //         color: '#fdea82',
    //     }
    // }
// });

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export default function BroadcastStudentLookAroundDialog(props) {
    // const classes = useStyles();
    const [width, setWidth] = React.useState(320);
    const [height, setHeight] = React.useState(320);
    const { open, userInfo, chatUserList, handleClose, channel, chatSendControlLookAroundUnderstand, lookAroundUnderstandList, mutedControl, isLookAroundSoundOn } = props;

    useEffect(() => {
        if(window.screen.orientation) {
            window.screen.orientation.onchange = () => calcVideoSize();
        } else {
            document.addEventListener("orientationchange", () => calcVideoSize())
        }
        calcVideoSize();
    })

    const teacherName = () => {
        if(chatUserList && chatUserList.length > 0) {
            return chatUserList.find(user => user.userId === channel.userId) !== undefined ? chatUserList.find(user => user.userId === channel.userId).userName : "";
        }
        return "";
    };

    const getBorder = (userId) => {
        let understandObj = lookAroundUnderstandList.find((item) => item.userId === userId);
        if(understandObj) {
            return understandObj.value ? "solid 5px blue" : "solid 5px red";
        }

        return "none"
    }

    const calcVideoSize = () => {
        setWidth(320);
        setHeight(320);
        const orientation = window.screen.orientation;
        if(orientation) {
            if(orientation.angle === 0 || orientation.angle === 360) {
                setWidth(window.innerWidth < 400 ? 200 : 320);
                setHeight(window.innerHeight < 400 ? 200 : 320);
            } else if(orientation.angle === 90 || orientation.angle === -90) {
                setWidth(120);
                setHeight(120);
            }
        } else {
            const portrait = window.matchMedia("(orientation: portrait)")
            if(portrait) {
                setWidth(window.innerWidth < 400 ? 200 : 320);
                setHeight(window.innerHeight < 400 ? 200 : 320);
            } else {
                setWidth(120);
                setHeight(120);
            }
        }
        return {"width" : width, "height": height};
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={false}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                disableBackdropClick
        >
            <DialogTitle style={{
                cursor: 'move',
            }} id="draggable-dialog-title">
                둘러보기
            </DialogTitle>
            <DialogContent
                // id="draggable-dialog-title"
                style={{
                backgroundColor: '#000000',
                // cursor: 'move'
            }}>
                <Grid container>
                    <Grid item xs={width === 200 ? 12 : 6}>
                        <div
                            key={channel.userId}
                            style={{
                                border: getBorder(channel.userId),
                            }}
                        >
                            <video id={`lookAround-${channel.userId}`}
                                   style={{
                                       width: width + "px",
                                       height: height + "px",
                                   }}
                            />
                            <div
                                style={{
                                    display: 'table',
                                    opacity: '0.76',
                                    backgroundColor: '#c41c1b',
                                    color: '#ffffff',
                                    textAlign: 'center',
                                    padding: '6px',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >{teacherName()}</div>
                        </div>
                    </Grid>
                    <Grid item xs={width === 200 ? 12 : 6}>
                        <div
                            key={userInfo.userId}
                            style={{
                                paddingRight: '12px',
                                border: getBorder(userInfo.userId),
                            }}
                        >
                            <video id={`lookAround-${userInfo.userId}`}
                                   style={{
                                       width: width + "px",
                                       height: height + "px",
                                   }}
                            />
                            <div
                                style={{
                                    display: 'table',
                                    opacity: '0.76',
                                    backgroundColor: '#000000',
                                    color: '#ffffff',
                                    textAlign: 'center',
                                    padding: '6px',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                }}
                            >{userInfo.userName}</div>
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                style={{
                    justifyContent: "flex-start",
                    backgroundColor: '#353535'
                }}
            >
                <div style={{
                    textAlign: 'center',
                    display: 'flex'
                }}
                >
                    <Button style={{
                        alignItems: 'center',
                        color: 'rgba(255, 255, 255, 1.0)',
                    }}
                            onClick={isLookAroundSoundOn ? () => mutedControl(true) : () => mutedControl(false)}
                            onMouseOver={(event) => event.currentTarget.style.color = '#fdea82'}
                            onMouseOut={(event) => event.currentTarget.style.color = 'rgba(255, 255, 255, 1.0)'}
                    >
                        <div>
                            {isLookAroundSoundOn ? <VolumeUpIcon fontSize={"large"} style={{"color":"white"}} onClick={() => mutedControl(true)}/> : <VolumeOffIcon fontSize={"large"} style={{"color":"white"}} onClick={() => mutedControl(false)}/>}
                            <br/>
                            {isLookAroundSoundOn ?  "끄기" : "켜기"}
                        </div>
                    </Button>
                </div>
                <Box display="flex" justifyContent={channel.userId !== userInfo.userId ? 'center' : 'flex-start'}  style={{width: '100%'}}>
                    <Button
                        style={{color: 'rgba(255, 255, 255, 1.0)'}}
                        onClick={() => chatSendControlLookAroundUnderstand(true)}
                        onMouseOver={(event) => event.currentTarget.style.color = '#fdea82'}
                        onMouseOut={(event) => event.currentTarget.style.color = 'rgba(255, 255, 255, 1.0)'}
                    >
                        <div>
                            <CheckIcon fontSize={"large"}/><br/>예
                        </div>
                    </Button>

                    <Button style={{ marginLeft: '50px', color: 'rgba(255, 255, 255, 1.0)'}}
                            onClick={() => chatSendControlLookAroundUnderstand(false)}
                            onMouseOver={(event) => event.currentTarget.style.color = '#fdea82'}
                            onMouseOut={(event) => event.currentTarget.style.color = 'rgba(255, 255, 255, 1.0)'}
                    >
                        <div>
                            <CloseIcon fontSize={"large"}/><br/>아니오
                        </div>
                    </Button>
                </Box>
                {/*<div style={{*/}
                {/*        textAlign: 'center',*/}
                {/*        display: 'flex'*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Button style={{*/}
                {/*                alignItems: 'center',*/}
                {/*                color: 'rgba(255, 255, 255, 1.0)',*/}
                {/*            }}*/}
                {/*            onClick={() => {handleClose()}}*/}
                {/*            onMouseOver={(event) => event.currentTarget.style.color = '#fdea82'}*/}
                {/*            onMouseOut={(event) => event.currentTarget.style.color = 'rgba(255, 255, 255, 1.0)'}*/}
                {/*    >*/}
                {/*        <div>*/}
                {/*            <ExitToAppIcon fontSize={"large"}/><br/>*/}
                {/*            닫기*/}
                {/*        </div>*/}
                {/*    </Button>*/}
                {/*</div>*/}
            </DialogActions>
        </Dialog>
    );
}
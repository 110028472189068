import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Grid,
    withStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";

import * as Params from "../../../common/Params";
import {JanusDemo} from "../../JanusDemo";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";


const styles = (theme) => ({
    dialog: {
    },
    dialogTitle: {
    },
    dialogContent: {
    },
    commandButton: {
        borderRadius: 24,
    },

    demo: {
        // maxWidth: theme.ContentWidth,
        margin:'0 auto',
        //backgroundColor: '#f4f4f4',
        // paddingTop: 90,
        paddingBottom: 80,
    },
    demoTitleContainer: {
        paddingBottom: 60,
    },
    demoTitle: {
        fontSize: 32,
        fontWeight: 700,
        color: '#333333',
        [theme.breakpoints.down("sm")]: {
            fontSize:'2em',
            padding:'0 4vw'
        },
    },
    demoComment: {
        paddingTop: 16,
        paddingBottom: 8,
        fontSize: 22,
    },
    demoTeacher: {
        width: 500,
        height: 277,
        // backgroundImage: 'url(/images/img-teacher-view-k.png)',
        backgroundImage: 'url(/images/img-student-view-k.png)',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
    },
    demoTeacherVideo: {
        width: 384,
        height: 214,
        marginTop: 35,
        marginLeft: 66,
        backgroundColor: 'black',
    },
    demoStudent: {
        width: 500,
        height: 277,
        backgroundImage: 'url(/images/img-student-view-k.png)',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
        padding: 0,
    },
    demoStudentVideo: {
        width: 384,
        height: 214,
        marginTop: 35,
        marginLeft: 66,
        backgroundColor: 'black',
    },
    demoButtonContainer: {
        paddingTop: 16,
    },
    demoButton: {
        width: 300,
        height: 60,
        borderRadius: 40,
        fontSize: 26,
        textAlign: 'center'
    },
});


@inject('broadcastStore')
@observer
class BroadcastWebCamSettingDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            types: Params.getDeviceBrowserType(),
            janusDemo: undefined,
            demoStarted: false,

            demoDialogOpen: false,
            videoDevices: [],
            selectedVideoDevice: '',
            publishType: 'camera',
            audioDevices: [],
            selectedAudioDevice: '',
        };

        this.teacherVideoRef = React.createRef();
        this.studentVideoRef = React.createRef();
    }

    componentWillUnmount() {
        if(this.state.janusDemo && this.state.demoStarted) {
            this.state.janusDemo.stop();
        }
    }

    handleClickDemo = () => {
        if(this.state.demoStarted) {
            this.state.janusDemo.stop();
            this.setState({janusDemo: undefined, demoStarted: false});
            this.props.broadcastStore.changeBroadcastWebCamSettingDialog(false);
        } else {
            this.openDemoDialog();
        }
    }

    startDemo = () => {
        const janusDemo = new JanusDemo(this.teacherVideoRef, this.studentVideoRef, { isReload: false, audio: true });

        if(this.state.publishType === 'camera') {
            janusDemo.start({deviceId: this.state.selectedVideoDevice, width: 960, height: 540}, 1024);
        } else if(this.state.publishType === 'audio') {
            janusDemo.start({deviceId: this.state.selectedAudioDevice, width: 960, height: 540}, 1024);
        } if(this.state.publishType === 'screen') {
            janusDemo.start('screen', 1024, false);
        }

        this.setState({demoDialogOpen: false, janusDemo: janusDemo, demoStarted: true});
    }

    openDemoDialog = () => {
        JanusDemo.listVideoDevices((videoDevices) => {
            if(videoDevices && videoDevices.length > 0) {
                this.setState({demoDialogOpen: true, videoDevices: videoDevices, selectedVideoDevice: videoDevices[0].deviceId});
            } else {
                window.alert('장치 검색을 할 수 없습니다.');
            }
        });

        // JanusDemo.listAudioDevices((audioDevices) => {
        //     if(audioDevices && audioDevices.length > 0) {
        //         this.setState({audioDevices: audioDevices, selectedAudioDevice: audioDevices[0].deviceId});
        //     } else {
        //         window.alert('장치 검색을 할 수 없습니다.');
        //     }
        // });
    }

    closeDemoDialog = () => {
        this.setState({demoDialogOpen: false});
    }

    handleChangePublishType = (event) => {
        this.setState({publishType: event.target.value});
    }

    handleChangeSelectedVideoDevice = (event) => {
        this.setState({selectedVideoDevice: event.target.value});
    }

    // handleChangeSelectedAudioDevice = (event) => {
    //     this.setState({selectedAudioDevice: event.target.value});
    // }

    render() {
        const { classes  } = this.props;
        return (
            <>
                <Dialog disableBackdropClick fullWidth
                        maxWidth="md"
                        className={classes.dialog}
                        open={this.props.broadcastStore.isOpenBroadcastWebCamSettingDialog}
                        onClose={() => {
                            if(this.state.demoStarted) {
                                this.setState({janusDemo: undefined, demoStarted: false});
                            }
                            this.props.broadcastStore.changeBroadcastWebCamSettingDialog(false);
                        }}
                        aria-labelledby="channel-detail-dialog-title" >
                    <DialogTitle id="channel-detail-dialog-title" className={classes.dialogTitle}>
                        웹캠 점검하기
                    </DialogTitle>

                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.demo}>
                            {/*<div className={classes.demoTitleContainer}>*/}
                            {/*    <Typography className={classes.demoTitle}>*/}
                            {/*        카메라가 동작하는지 확인해 보세요.*/}
                            {/*    </Typography>*/}
                            {/*</div>*/}

                            <Grid container>
                                <Grid container item xs={12} sm={12} md={12} >
                                    <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                        <div className={classes.demoTeacher}>
                                            <div ref={this.teacherVideoRef} className={classes.demoTeacherVideo} />
                                        </div>
                                    </Grid>
                                    {/*<Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>*/}
                                    {/*    <Typography className={classes.demoComment}>내 화면</Typography>*/}
                                    {/*</Grid>*/}
                                </Grid>
                                {/*<Grid container item xs={12} sm={12} md={6} >*/}
                                {/*    <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>*/}
                                {/*        <div className={classes.demoStudent}>*/}
                                {/*            <div ref={this.studentVideoRef} className={classes.demoStudentVideo} />*/}
                                {/*        </div>*/}
                                {/*    </Grid>*/}
                                {/*    <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>*/}
                                {/*        <Typography className={classes.demoComment}>학생 수업 화면</Typography>*/}
                                {/*    </Grid>*/}
                                {/*</Grid>*/}
                                <Grid container item xs={12} sm={12} md={4} />
                                <Grid container item xs={12} sm={12} md={4}>
                                    <div className={classes.demoButtonContainer}>
                                        <Button variant="outlined" color="primary" className={classes.demoButton} onClick={this.handleClickDemo}>{this.state.demoStarted ? '수업듣기' : '웹캠 & 마이크 점검하기'}</Button>
                                    </div>
                                </Grid>
                                <Grid container item xs={12} sm={12} md={4} />
                            </Grid>
                        </div>
                    </DialogContent>

                    {/*<DialogActions>*/}
                    {/*    <Button color="primary" className={classes.commandButton} onClick={() => {*/}
                    {/*        if(this.state.demoStarted) {*/}
                    {/*            this.setState({janusDemo: undefined, demoStarted: false});*/}
                    {/*        }*/}
                    {/*        this.props.broadcastStore.changeChannelSettingDialog(false);*/}
                    {/*    }} >*/}
                    {/*        수업듣기*/}
                    {/*    </Button>*/}
                    {/*</DialogActions>*/}
                </Dialog>


                <Dialog open={this.state.demoDialogOpen} onClose={this.closeDemoDialog} fullWidth={true} maxWidth={'xs'}>
                    <DialogTitle>
                        웹캠 & 마이크 점검하기
                    </DialogTitle>
                    <DialogContent>
                        <FormControl component="fieldset">
                            <RadioGroup value={this.state.publishType} onChange={this.handleChangePublishType}>
                                <FormControlLabel value="camera" control={<Radio />} label="웹 캠" />
                                {this.state.publishType === 'camera' ?
                                    <Select value={this.state.selectedVideoDevice}
                                            onChange={this.handleChangeSelectedVideoDevice}
                                            style={{marginBottom: '16px'}}
                                    >
                                        {this.state.videoDevices.map((device) =>
                                            <MenuItem key={`video-device-item-${device.deviceId}`} value={device.deviceId}>{device.label}</MenuItem>
                                        )}
                                    </Select>
                                    :
                                    ''
                                }
                                {/*<FormControlLabel value="audio" control={<Radio />} label="소리" />*/}
                                {/*{this.state.publishType === 'audio' ?*/}
                                {/*    <Select value={this.state.selectedAudioDevice}*/}
                                {/*            onChange={this.handleChangeSelectedAudioDevice}*/}
                                {/*            style={{marginBottom: '16px'}}*/}
                                {/*    >*/}
                                {/*        {this.state.audioDevices.map((device) =>*/}
                                {/*            <MenuItem key={`audio-device-item-${device.deviceId}`} value={device.deviceId}>{device.label}</MenuItem>*/}
                                {/*        )}*/}
                                {/*    </Select>*/}
                                {/*    :*/}
                                {/*    ''*/}
                                {/*}*/}
                                {/*{this.state.types.deviceType === 'etc' && this.state.types.browserType === 'chrome' ?*/}
                                {/*    <FormControlLabel value={'screen'} control={<Radio />} label="화면 공유" />*/}
                                {/*    :*/}
                                {/*    ''*/}
                                {/*}*/}
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{color: 'rgba(0, 0, 0, 0.56)'}}
                                onClick={this.closeDemoDialog}
                        >
                            취소
                        </Button>
                        <Button style={{color: '#d64e4e'}}
                                onClick={this.startDemo}
                        >
                            확인
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (BroadcastWebCamSettingDialog)));
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogContent} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import * as store from "../../../stores/BroadcastStore";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

export default function BroadcastLookAroundPublishDialog(props) {
    const { open, handleClose, webRtcCameraList, selectedLookAroundCamera, changeWebRtcCamera, handleConfirm} = props;

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'xs'} disableBackdropClick={true}>
            <DialogTitle>
                둘러보기
            </DialogTitle>
            <DialogContent>
                <FormControl component="fieldset">
                    <RadioGroup value={store.publishType.WEB_CAM} onChange={() => {}}>
                        <FormControlLabel value={store.publishType.WEB_CAM} disabled={!webRtcCameraList || (webRtcCameraList && webRtcCameraList.length === 0)} control={<Radio />} label="웹 캠" />
                            <FormControl variant={"outlined"}>
                                <InputLabel id={"selectLabel"}>카메라</InputLabel>
                                <Select
                                    label={"카메라"}
                                    labelId={"selectLabel"}
                                    id={"selectLabel"}
                                    //defaultValue={""}
                                    value={selectedLookAroundCamera ? selectedLookAroundCamera : ""}
                                    onChange={(event) => changeWebRtcCamera(event.target.value)}
                                >
                                    {webRtcCameraList ? webRtcCameraList.map((item) => <MenuItem key={item.deviceId} value={item.deviceId}>{item.label}</MenuItem>) : ""}
                                </Select>
                            </FormControl>
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button style={{
                            color: 'rgba(0, 0, 0, 0.56)'
                        }}
                        onClick={() => {handleClose()}}
                >
                    취소
                </Button>
                <Button style={{
                            color: '#d64e4e'
                        }}
                        onClick={handleConfirm}
                >
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    );
}
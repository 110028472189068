import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import * as authStore from "../../stores/AuthStore";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";

export const styles = (theme) => ({

});

@inject("lookAroundStore", "authStore")
@observer
class StudentLookAround extends React.Component {

    componentDidMount() {
        const {channelId} = this.props.match.params;
        const {loginUser} = this.props.authStore;
        const token = sessionStorage.getItem(authStore.SessionStorageTokenKey);
        const userId = loginUser? loginUser.userId : null;

        this.props.lookAroundStore.initData();
        this.props.lookAroundStore.getUser(userId);
        this.props.lookAroundStore.getChannel(channelId, userId, token, false);
    }

    render() {
        const { isLookAroundSoundOn } = this.props.lookAroundStore;
        const { lookAroundStudentMutedControl } = this.props.lookAroundStore;
        return (
            <Grid container>
                <Grid item xs={12}>
                    <div style={{
                        textAlign: 'center',
                        display: 'flex'
                    }}
                    >
                        <Button style={{
                            alignItems: 'center',
                            color: 'rgba(0, 0, 0, 1.0)',
                        }}
                                onClick={isLookAroundSoundOn ? () => lookAroundStudentMutedControl(true) : () => lookAroundStudentMutedControl(false)}
                            // onMouseOver={(event) => event.currentTarget.style.color = '#fdea82'}
                            // onMouseOut={(event) => event.currentTarget.style.color = 'rgba(0, 0, 0, 1.0)'}
                        >
                            <div>
                                {isLookAroundSoundOn ? <VolumeUpIcon fontSize={"large"} style={{"color":"black"}} onClick={() => lookAroundStudentMutedControl(true)}/> : <VolumeOffIcon fontSize={"large"} style={{"color":"black"}} onClick={() => lookAroundStudentMutedControl(false)}/>}
                                <br/>
                                {isLookAroundSoundOn ?  "끄기" : "켜기"}
                            </div>
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div id={"student-video-list"} style={{ display: 'flex', flexWrap: "wrap" }} />
                </Grid>
            </Grid>
        );
    }
}

export default withSnackbar(withRouter(withStyles(styles) (StudentLookAround)));
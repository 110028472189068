import React from "react";
import DashBoardTemplate from "./templates/DashBoardTemplate";
import {inject, observer} from "mobx-react";
import DashBoardChannelTable from "./container/DashBoardChannelTable";
import DashBoardChannelInfo from "./container/DashBoardChannelInfo";
import DashBoardChannelPlayTimeTable from "./container/DashBoardChannelPlayTimeTable";
import DashBoardChannelAttendStatus from "./container/DashBoardChannelAttendStatus";
import DashBoardChannelConnectStatus from "./container/DashBoardChannelConnectStatus";
import DashBoardChannelDeviceStatus from "./container/DashBoardChannelDeviceStatus";
import DashBoardChannelPresentationStatus from "./container/DashBoardChannelPresentationStatus";
import DashBoardChannelQuestionStatus from "./container/DashBoardChannelQuestionStatus";
import DashBoardChannelQuizResult from "./container/DashBoardChannelQuizResult";
import DashBoardChannelMemberStatus from "./container/DashBoardChannelMemberStatus";

@inject("dashboardStore")
@observer
class DashBoard extends React.Component {

    componentDidMount() {
        this.props.dashboardStore.setUserId(this.props.userId);
    }

    render() {
        return (
            <DashBoardTemplate
                dashboardChannelTable={<DashBoardChannelTable userId={this.props.userId}/>}
                dashboardChannelInfo={<DashBoardChannelInfo userId={this.props.userId}/>}
                dashboardChannelPlayTime={<DashBoardChannelPlayTimeTable userId={this.props.userId}/>}
                dashboardChannelAttendStatus={<DashBoardChannelAttendStatus userId={this.props.userId}/>}
                dashboardChannelConnectStatus={<DashBoardChannelConnectStatus userId={this.props.userId}/>}
                dashboardChannelDeviceStatus={<DashBoardChannelDeviceStatus userId={this.props.userId}/>}
                dashboardChannelPresentationStatus={<DashBoardChannelPresentationStatus userId={this.props.userId}/>}
                dashboardChannelQuestionStatus={<DashBoardChannelQuestionStatus userId={this.props.userId}/>}
                dashboardChannelQuizResult={<DashBoardChannelQuizResult userId={this.props.userId}/>}
                dashboardChannelMemberStatus={<DashBoardChannelMemberStatus userId={this.props.userId}/>}
            />
        )
    }
}

export default DashBoard;
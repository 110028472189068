import _ from "lodash";
import {VideoMode, VideoPosition} from "./RoomPresenter2";

const SUB_TOP_MARGIN_PERCENT = 4;
const SUB_LEFT_MARGIN_PERCENT = 2;
const VIDEO_MIN_HEIGHT = 36;
const MIN_CHAT_AREA_WIDTH = 420;
const LogPrefix = '[LayoutManager] ';

export class RoomLayoutManager {
    constructor(config, onResize) {
        this.config = Object.assign({}, {
            mainVideo: '',
            subVideoHidden: false,
            subVideoPosition: VideoPosition.BottomRight,
            subVideoSizePercent: 25,

            mainZIndex: 10,
            landscape: true,
            isMobile: false,
            videoContainerRef: undefined,
            primaryVideoAreaRef: undefined,
            secondaryVideoAreaRef: undefined,
            whiteBoardRef: undefined,
            primaryLayerRef: undefined,
            secondaryLayerRef: undefined,
            secondaryLocatorRef: undefined,
            additionalVideoRefs: [],
            chatAreaRef: undefined,
        }, config);

        this.onResize = onResize;
    }

    // For VideoArea size
    setLandscape = (landscape) => {
        if(this.config.landscape !== landscape) {
            this.config.landscape = landscape;

            if(!landscape) {
                this.setChatAreaHidden(false);
            }
        }
    }


    initVideoArea = () => {
        console.log(LogPrefix, `Initializing video area`);

        if(this.config.primaryVideoAreaRef && this.config.primaryVideoAreaRef.current) {
            this.config.primaryVideoAreaRef.current.style.left = '0px';
            this.config.primaryVideoAreaRef.current.style.top = '0px';
            this.config.primaryVideoAreaRef.current.style.width = '1px';
            this.config.primaryVideoAreaRef.current.style.height = '1px';
        }

        if(this.config.primaryLayerRef && this.config.primaryLayerRef.current) {
            this.config.primaryLayerRef.current.style.left = '0px';
            this.config.primaryLayerRef.current.style.top = '0px';
            this.config.primaryLayerRef.current.style.width = '1px';
            this.config.primaryLayerRef.current.style.height = '1px';
        }

        if(this.config.secondaryVideoAreaRef && this.config.secondaryVideoAreaRef.current) {
            this.config.secondaryVideoAreaRef.current.style.left = '0px';
            this.config.secondaryVideoAreaRef.current.style.top = '0px';
            this.config.secondaryVideoAreaRef.current.style.width = '1px';
            this.config.secondaryVideoAreaRef.current.style.height = '1px';
        }

        if(this.config.secondaryLayerRef && this.config.secondaryLayerRef.current) {
            this.config.secondaryLayerRef.current.style.left = '0px';
            this.config.secondaryLayerRef.current.style.top = '0px';
            this.config.secondaryLayerRef.current.style.width = '1px';
            this.config.secondaryLayerRef.current.style.height = '1px';
        }

        if(this.config.whiteBoardRef && this.config.whiteBoardRef.current) {
            this.config.whiteBoardRef.current.style.left = '0px';
            this.config.whiteBoardRef.current.style.top = '0px';
            this.config.whiteBoardRef.current.style.width = '1px';
            this.config.whiteBoardRef.current.style.height = '1px';
        }
    }

    resizeVideoArea = (needInit) => {
        console.log(LogPrefix, `Resizing video area : init=${needInit}`, this.config);

        const {videoContainerRef, secondaryLocatorRef, additionalVideoRefs, mainVideo, subVideoHidden, subVideoPosition, isMobile, landscape} = this.config;

        const tryMaxSize = isMobile && landscape;
        const mainVideoAreaRef = mainVideo === VideoMode.Primary ? this.config.primaryVideoAreaRef : this.config.secondaryVideoAreaRef;
        const mainLayerRef = mainVideo === VideoMode.Primary ? this.config.primaryLayerRef : this.config.secondaryLayerRef;
        const subVideoAreaRef = mainVideo === VideoMode.Primary ? this.config.secondaryVideoAreaRef : this.config.primaryVideoAreaRef;
        const subLayerRef = mainVideo === VideoMode.Primary ? this.config.secondaryLayerRef : this.config.primaryLayerRef;
        // const whiteBoardRef = this.config.whiteBoardRef;

        if(videoContainerRef && videoContainerRef.current) {
            // 크기를 초기화 시켜야 VideoContainer의 사이즈가 화면 크기에 맞게 줄어듬...
            if(needInit) {
                this.initVideoArea();
            }
            const additionalHeight = _.chain(additionalVideoRefs).map((ref) => ref.current ? ref.current.clientHeight : 0).reduce((sum, height) => sum + height + 10, 0).value();
            const containerRect = videoContainerRef.current.getBoundingClientRect();
            console.log(LogPrefix, 'additionalHeight', additionalHeight);
            console.log(LogPrefix, 'containerRect', containerRect);
            console.log(LogPrefix, 'isMobile', isMobile);
            console.log(LogPrefix, 'landscape', landscape);
            console.log(LogPrefix, 'tryMaxSize', tryMaxSize);

            const calculatedWidth = tryMaxSize ? window.innerWidth : (landscape ? containerRect.width : window.innerWidth);
            const calculatedHeight = (9 / 16) * calculatedWidth;
            const maxHeight = tryMaxSize ? window.innerHeight : (landscape ? window.innerHeight - containerRect.top - additionalHeight : calculatedHeight);

            let mainWidth = calculatedWidth;
            let mainHeight = calculatedHeight;
            let mainMargin = 0;

            if(mainHeight > maxHeight) {
                mainWidth = (16 / 9) * maxHeight;
                mainHeight = maxHeight;
                mainMargin = (calculatedWidth - mainWidth) / 2;

            } else if(mainHeight < VIDEO_MIN_HEIGHT) {
                mainWidth = (16 / 9) * VIDEO_MIN_HEIGHT;
                mainHeight = VIDEO_MIN_HEIGHT;
                mainMargin = (calculatedWidth - mainWidth) / 2;

            }

            if(mainVideoAreaRef && mainVideoAreaRef.current) {
                mainVideoAreaRef.current.style.width = `${mainWidth}px`;
                mainVideoAreaRef.current.style.height = `${mainHeight}px`;
                mainVideoAreaRef.current.setAttribute('width', `${mainWidth}px`);
                mainVideoAreaRef.current.setAttribute('height', `${mainHeight}px`);
            }

            if(mainLayerRef && mainLayerRef.current) {
                const mainRect = tryMaxSize ? {left: mainMargin, top: 0, width: mainWidth, height: mainHeight} : mainVideoAreaRef.current.getBoundingClientRect();

                mainLayerRef.current.style.left = `${mainRect.left}px`;
                mainLayerRef.current.style.top = `${mainRect.top}px`;
                mainLayerRef.current.style.width = `${mainWidth}px`;
                mainLayerRef.current.style.height = `${mainHeight}px`;
                mainLayerRef.current.setAttribute('width', `${mainWidth}px`);
                mainLayerRef.current.setAttribute('height', `${mainHeight}px`);
            }

            // if(whiteBoardRef && whiteBoardRef.current) {
            //     const mainRect = tryMaxSize ? {left: mainMargin, top: 0, width: mainWidth, height: mainHeight} : mainVideoAreaRef.current.getBoundingClientRect();
            //
            //     whiteBoardRef.current.style.left = `${mainRect.left}px`;
            //     whiteBoardRef.current.style.top = `${mainRect.top}px`;
            //     whiteBoardRef.current.style.width = `${mainWidth}px`;
            //     whiteBoardRef.current.style.height = `${mainHeight}px`;
            //     whiteBoardRef.current.setAttribute('width', `${mainWidth}px`);
            //     whiteBoardRef.current.setAttribute('height', `${mainHeight}px`);
            // }

            if(subVideoAreaRef && subVideoAreaRef.current) {
                if(subVideoHidden) {
                    subVideoAreaRef.current.style.left = '0px';
                    subVideoAreaRef.current.style.top = '0px';
                    subVideoAreaRef.current.style.width = '1px';
                    subVideoAreaRef.current.style.height = '1px';
                    subVideoAreaRef.current.setAttribute('width', '1px');
                    subVideoAreaRef.current.setAttribute('height', '1px');

                    if(subLayerRef && subLayerRef.current) {
                        subLayerRef.current.style.left = '0px';
                        subLayerRef.current.style.top = '0px';
                        subLayerRef.current.style.width = '1px';
                        subLayerRef.current.style.height = '1px';
                        subLayerRef.current.setAttribute('width', '1px');
                        subLayerRef.current.setAttribute('height', '1px');
                    }
                } else {
                    const parentRect = tryMaxSize ? {left: mainMargin, top: 0, width: mainWidth, height: mainHeight} : mainVideoAreaRef.current.getBoundingClientRect();
                    console.log(LogPrefix, 'Parent rect of sub', parentRect);

                    const rect = this.getSubVideoRect(parentRect, subVideoPosition);

                    subVideoAreaRef.current.style.left = rect.left + 'px';
                    subVideoAreaRef.current.style.top = rect.top + 'px';
                    subVideoAreaRef.current.style.width = `${rect.width}px`;
                    subVideoAreaRef.current.style.height = `${rect.height}px`;
                    subVideoAreaRef.current.setAttribute('width', `${rect.width}px`);
                    subVideoAreaRef.current.setAttribute('height', `${rect.height}px`);

                    console.log(LogPrefix, 'set subvideo', rect);
                    if(subLayerRef && subLayerRef.current) {
                        subLayerRef.current.style.left = rect.left + 'px';
                        subLayerRef.current.style.top = rect.top + 'px';
                        subLayerRef.current.style.width = `${rect.width}px`;
                        subLayerRef.current.style.height = `${rect.height}px`;
                        subLayerRef.current.setAttribute('width', `${rect.width}px`);
                        subLayerRef.current.setAttribute('height', `${rect.height}px`);
                    }
                }
            }

            if(secondaryLocatorRef && secondaryLocatorRef.current) {
                secondaryLocatorRef.current.style.visibility = 'hidden';
            }

            console.log(LogPrefix, `Width & Height = ${mainWidth}, ${mainHeight}`);
            if (this.onResize) {
                try {
                    const mainRect = tryMaxSize ? {left: mainMargin, top: 0, width: mainWidth, height: mainHeight} : mainVideoAreaRef.current.getBoundingClientRect();

                    this.onResize(mainWidth, mainHeight, mainRect.left, mainRect.top);
                } catch (error) {
                    console.warn(LogPrefix, 'Error in onResize callback', error);
                }
            }
        }
    }

    setMainVideo = (mainVideo, isPublisher) => {
        const {mainZIndex, secondaryLocatorRef} = this.config;

        const mainVideoAreaRef = mainVideo === VideoMode.Primary ? this.config.primaryVideoAreaRef : this.config.secondaryVideoAreaRef;
        const mainLayerRef = mainVideo === VideoMode.Primary ? this.config.primaryLayerRef : this.config.secondaryLayerRef;
        const subVideoAreaRef = mainVideo === VideoMode.Primary ? this.config.secondaryVideoAreaRef : this.config.primaryVideoAreaRef;
        const subLayerRef = mainVideo === VideoMode.Primary ? this.config.secondaryLayerRef : this.config.primaryLayerRef;
        const whiteBoardRef = this.config.whiteBoardRef;

        if(mainVideoAreaRef && mainVideoAreaRef.current) {
            mainVideoAreaRef.current.style.position = 'static';
            mainVideoAreaRef.current.style.zIndex = mainZIndex;
            mainVideoAreaRef.current.style.left = '0px';
            mainVideoAreaRef.current.style.top = '0px';
            mainVideoAreaRef.current.style.width = '1px';
            mainVideoAreaRef.current.style.height = '1px';
        }

        if(mainLayerRef && mainLayerRef.current) {
            mainLayerRef.current.style.position = 'absolute';
            mainLayerRef.current.style.zIndex = mainZIndex + 3;
            mainLayerRef.current.style.left = '0px';
            mainLayerRef.current.style.top = '0px';
            mainLayerRef.current.style.width = '1px';
            mainLayerRef.current.style.height = '1px';
        }

        if(subVideoAreaRef && subVideoAreaRef.current) {
            subVideoAreaRef.current.style.position = 'absolute';
            subVideoAreaRef.current.style.zIndex = mainZIndex + 4;
            subVideoAreaRef.current.style.left = '0px';
            subVideoAreaRef.current.style.top = '0px';
            subVideoAreaRef.current.style.width = '1px';
            subVideoAreaRef.current.style.height = '1px';
        }

        if(subLayerRef && subLayerRef.current) {
            subLayerRef.current.style.position = 'absolute';
            subLayerRef.current.style.zIndex = mainZIndex + 6;
            subLayerRef.current.style.left = '0px';
            subLayerRef.current.style.top = '0px';
            subLayerRef.current.style.width = '1px';
            subLayerRef.current.style.height = '1px';
        }

        if(secondaryLocatorRef && secondaryLocatorRef.current) {
            secondaryLocatorRef.current.style.visibility = 'hidden';
            secondaryLocatorRef.current.style.position = 'absolute';
            secondaryLocatorRef.current.style.zIndex = mainZIndex + 5;
            secondaryLocatorRef.current.style.left = '0px';
            secondaryLocatorRef.current.style.top = '0px';
            secondaryLocatorRef.current.style.width = '0px';
            secondaryLocatorRef.current.style.height = '0px';
        }

        if(whiteBoardRef && whiteBoardRef.current) {
            whiteBoardRef.current.style.position = 'absolute';
            whiteBoardRef.current.style.zIndex = isPublisher ? mainZIndex + 6 : mainZIndex + 1;
            whiteBoardRef.current.style.left = '0px';
            whiteBoardRef.current.style.top = '0px';
            whiteBoardRef.current.style.width = '1px';
            whiteBoardRef.current.style.height = '1px';
        }

        this.config.mainVideo = mainVideo;
    }

    setSubVideoHidden = (hidden) => {
        this.config.subVideoHidden = hidden;
        this.resizeVideoArea(true);
    }

    setSubVideoPosition = (position) => {
        if(!this.config.subVideoHidden) {
            this.initVideoArea();
            this.config.subVideoPosition = position;
            this.resizeVideoArea(false);
        }
    }

    showSubVideoLocator = (show, position) => {
        const {mainVideo, primaryVideoAreaRef, secondaryVideoAreaRef, mainZIndex, subVideoHidden, subVideoPosition, secondaryLocatorRef} = this.config;
        const mainVideoAreaRef = mainVideo === VideoMode.Primary ? primaryVideoAreaRef : secondaryVideoAreaRef;

        if(show && (!subVideoHidden) && (position !== subVideoPosition)) {
            if(mainVideoAreaRef && mainVideoAreaRef.current && secondaryLocatorRef && secondaryLocatorRef.current) {
                const parentRect = mainVideoAreaRef.current.getBoundingClientRect();
                const rect = this.getSubVideoRect(parentRect, position);

                secondaryLocatorRef.current.style.visibility = 'visible';
                secondaryLocatorRef.current.style.position = 'absolute';
                secondaryLocatorRef.current.style.zIndex = mainZIndex + 1;
                secondaryLocatorRef.current.style.left = rect.left + 'px';
                secondaryLocatorRef.current.style.top = rect.top + 'px' ;
                secondaryLocatorRef.current.style.width = rect.width + 'px';
                secondaryLocatorRef.current.style.height = rect.height + 'px';
            }
        } else {
            if(secondaryLocatorRef && secondaryLocatorRef.current) {
                secondaryLocatorRef.current.style.visibility = 'hidden';
                secondaryLocatorRef.current.style.position = 'absolute';
                secondaryLocatorRef.current.style.zIndex = mainZIndex + 1;
                secondaryLocatorRef.current.style.left = '0px';
                secondaryLocatorRef.current.style.top = '0px';
                secondaryLocatorRef.current.style.width = '0px';
                secondaryLocatorRef.current.style.height = '0px';
            }
        }
    }

    getSubVideoSize = () => {
        return this.config.subVideoSizePercent;
    }

    setSubVideoSize = (size) => {
        this.initVideoArea();
        this.config.subVideoSizePercent = size;
        this.resizeVideoArea(false);
    }

    getSubVideoRect = (parentRect, position) => {
        console.log(LogPrefix, 'getSubVideoReact', parentRect, position, this.config.subVideoSizePercent);

        let top, left;
        const width = parentRect.width * (this.config.subVideoSizePercent / 100);
        const height = parentRect.height * (this.config.subVideoSizePercent / 100);

        switch(position) {
            case 'TopLeft':
                top = parentRect.top + (parentRect.height * SUB_TOP_MARGIN_PERCENT / 100);
                left = parentRect.left + (parentRect.width * SUB_LEFT_MARGIN_PERCENT / 100);
                break;
            case 'TopRight':
                top = parentRect.top + (parentRect.height * SUB_TOP_MARGIN_PERCENT / 100);
                left = parentRect.left + (parentRect.width - width - (parentRect.width * SUB_LEFT_MARGIN_PERCENT / 100));
                break;
            case 'BottomLeft':
                top = parentRect.top + (parentRect.height - height -(parentRect.height * SUB_TOP_MARGIN_PERCENT / 100));
                left = parentRect.left + (parentRect.width * SUB_LEFT_MARGIN_PERCENT / 100);
                break;
            default:
                top = parentRect.top + (parentRect.height - height -(parentRect.height * SUB_TOP_MARGIN_PERCENT / 100));
                left = parentRect.left + (parentRect.width - width - (parentRect.width * SUB_LEFT_MARGIN_PERCENT / 100));
                break;
        }

        const result = {top, left, width, height};
        console.log(LogPrefix, 'getSubVideoReact result', result);
        return result;
    }

    // For Chat sizing
    setChatAreaWidth = (width) => {
        if(width > MIN_CHAT_AREA_WIDTH) {
            const chatAreaRef = this.config.chatAreaRef;
            if(chatAreaRef && chatAreaRef.current) {
                this.initVideoArea();
                chatAreaRef.current.style.display = 'flex';
                chatAreaRef.current.style.width = `${width}px`;
                this.resizeVideoArea(false);
            }

            return true;
        } else {
            const chatAreaRef = this.config.chatAreaRef;
            if(chatAreaRef && chatAreaRef.current) {
                this.initVideoArea();
                chatAreaRef.current.style.display = 'none';
                this.resizeVideoArea(false);
            }

            return false;
        }
    }

    setChatAreaHidden = (hidden) => {
        if(hidden) {
            const chatAreaRef = this.config.chatAreaRef;
            if(chatAreaRef && chatAreaRef.current) {
                this.initVideoArea();
                chatAreaRef.current.style.display = 'none';
                this.resizeVideoArea(false);
            }
        } else {
            const chatAreaRef = this.config.chatAreaRef;
            if(chatAreaRef && chatAreaRef.current) {
                this.initVideoArea();
                chatAreaRef.current.style.display = 'flex';
                chatAreaRef.current.style.width = `${MIN_CHAT_AREA_WIDTH}px`;
                this.resizeVideoArea(false);
            }
        }
    }
}
import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {withStyles} from "@material-ui/core/styles";

import axios from "axios";
import moment from "moment";
import "moment/locale/ko";

import TopBar from "./components/TopBar";
import Home from "./views/Home";
import Intro from "./views/Intro";
import SignIn from "./views/SignIn";
import SignUp from "./views/SignUp";
import ChannelCreate from "./views/channel/ChannelCreate";
import CalendarChannelList from "./views/channel/CalendarChannelList";
import Broadcast from "./views/broadcast/Broadcast";
import * as store from "./stores/AuthStore";
import GroupList from "./views/group/GroupList";
import TicketList from "./views/ticket/TicketList";
import TicketSummary from "./views/ticket/TicketSummary";
import FindPassword from "./views/etc/FindPassword";
import MailCertification from "./views/etc/MailCertification";
import PassCertification from "./views/etc/PasswordCertification";
import Terms from "./views/etc/Terms";
import Payment from "./views/Payment/Payment";
import MyPage from "./views/MyPage/MyPage";
import BoardList from "./views/board/BoardList";
import QuizList from "./views/quiz/QuizList";
import Admin from "./views/admin/Admin";
import AdminSignIn from "./views/AdminSignIn";
import AdminTopBar from "./components/AdminTopBar";
import LookAroundTest from "./views/lookaround/LookAroundTest";
import Faq from "./views/faq/Faq";
import AttendanceList from "./views/attendance/AttendanceList";
import TeacherLookAround from "./views/lookaround/TeacherLookAround";
import StudentLookAround from "./views/lookaround/StudentLookAround";
import DashBoard from "./views/dashboard/DashBoard";
import MemberAttendanceState from "./views/attendance/MemberAttendanceState";
import Room2 from "./views/room/Room2";
import Monitoring from "./views/monitoring/Monitoring";

const style = () => ({
    root: {
        backgroundColor: '#ffffff',

        "& .MuiButton-containedSecondary" : {
            backgroundColor: '#3437CE',
            "&:hover":{
            backgroundColor: '#191dc5',
            
        }
            
        },
        
    },

    
});

const Janus = window.Janus;

@inject('authStore', 'helpStore')
@observer
class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileOpen: false,
            deviceType: '',
            browserType: '',
            janusInitialized: false,
            isLookAround: false,
        };

        moment.updateLocale('ko');
    }

    componentDidMount() {
        console.log("========== OnTheLive App did mount ==========");
        // this.checkBrowser();
        this.checkWebRTC();

        const axiosRequestInterceptors = (config) => {
            const token = sessionStorage.getItem(store.SessionStorageTokenKey);
            const chatToken = sessionStorage.getItem(store.SessionStorageTokenKey);

            if(token) {
                config.headers['X-Auth-Token'] = token;
            }

            if(chatToken) {
                config.headers['C-Auth-Token'] = chatToken;
            }

            return config;
        };

        const axiosRequestErrorHandler = (error) => {
            return Promise.reject(error);
        };

        const axiosResponseInterceptor = (response) => {
            if(response.status === 403) {
                this.props.authStore.invalidateLogin();
            }

            return response;
        };

        const axiosResponseErrorHandler = (error) => {
            if((error.response) && (error.response.status === 403)) {
                this.props.authStore.invalidateLogin();
            }

            return Promise.reject(error);
        };

        axios.interceptors.request.use(axiosRequestInterceptors, axiosRequestErrorHandler);
        axios.interceptors.response.use(axiosResponseInterceptor, axiosResponseErrorHandler);

        this.props.authStore.checkLogin();

        if(window.location.pathname.startsWith("/lookaround")) {
            this.setState({
                isLookAround: true
            });
        } else {
            this.setState({
                isLookAround: false
            });
        }
    }

    // checkBrowser = () => {
    //     const isAlert = false;
    //     const type = Params.getDeviceBrowserType(isAlert);
    //     if(isAlert) {
    //         alert(JSON.stringify(type));
    //     } else {
    //         console.log('type : ', type);
    //     }
    //
    //     if(type.deviceType === Params.deviceType.android) {
    //         if(type.browserType !== Params.browserType.chrome) {
    //             window.location.href = '/ie.html';
    //         }
    //     } else if(type.deviceType === Params.deviceType.ios) {
    //         if(type.browserType !== Params.browserType.safari) {
    //             window.location.href = '/ie.html';
    //         }
    //     } else {
    //         if(type.browserType !== Params.browserType.edge) {
    //             window.location.href = '/ie.html';
    //         }
    //     }
    // }

    checkWebRTC = () => {
        Janus.init({
            debug: [
                // 'error',
                // 'warn',
                // 'log',
                //'debug',
                //'trace',
            ], callback: () => {
                console.log('[Demo] Initialized janus');

                if(Janus && Janus.isWebrtcSupported()) {
                    this.setState({janusInitialized: true});
                } else {
                    window.location.href = '/ie.html';
                }
            }
        });
    }

    setMobileOpen = (mobileOpen) => {
        this.setState({mobileOpen: mobileOpen});
    }



    render() {
        const { classes } = this.props;
        const { isLoggedIn, loginType, loginUser, isLoginTypeAdmin } = this.props.authStore;

        const renderComponent = (renderTopbar, component) => {
            return (
                <React.Fragment>
                    {renderTopbar ?
                        ( isLoginTypeAdmin ?
                                <AdminTopBar
                                    mobileOpen={this.state.mobileOpen}
                                    setMobileOpen={this.setMobileOpen}
                                    isLoggedIn={isLoggedIn}
                                    userId={loginUser.userId}
                                    doLogout={pathname => this.props.authStore.logOut(pathname)} />
                                :
                                <TopBar mobileOpen={this.state.mobileOpen}
                                        setMobileOpen={this.setMobileOpen}
                                        helpStore={this.props.helpStore}
                                        isLoggedIn={isLoggedIn}
                                        loginType={loginType}
                                        userId={loginUser.userId}
                                        doLogout={pathname => this.props.authStore.logOut(pathname)} />
                        )
                        :
                        ''
                    }
                    {component ?
                        component
                        :
                        ''
                    }
                </React.Fragment>
            )
        }

        return (
            <div className={classes.root} id={"root"}>
                <Router>
                    {isLoggedIn ? (
                        <Switch>
                            <Route exact path="/"                                               render={() => renderComponent(true, <Home loginType={loginType} userId={loginUser.userId} />) } />
                            <Route exact path="/intro"                                          render={() => renderComponent(true, <Intro loginType={loginType} userId={loginUser.userId}  />) } />
                            <Route exact path="/channel/create/:selectedDay"                    render={() => renderComponent(true, <ChannelCreate loginType={loginType} userId={loginUser.userId} />) } />
                            <Route exact path="/channel/list"                                   render={() => renderComponent(true, <CalendarChannelList loginType={loginType} userId={loginUser.userId} />) } />
                            <Route       path="/broadCast/:channelId"                           render={() => renderComponent(true, <Broadcast loginType={loginType} userId={loginUser.userId} janusInitialized={this.state.janusInitialized} />) } />
                            <Route exact path="/group/list"                                     render={() => renderComponent(true, <GroupList loginType={loginType} userId={loginUser.userId} />) } />
                            <Route exact path="/attendance/list"                                render={() => renderComponent(true, <AttendanceList loginType={loginType} userId={loginUser.userId} />) } />
                            <Route exact path="/attendance/state"                               render={() => renderComponent(true, <MemberAttendanceState loginType={loginType} userId={loginUser.userId} />) } />
                            <Route exact path="/ticket/list"                                    render={() => renderComponent(true, <TicketList loginType={loginType} userId={loginUser.userId} />) } />
                            <Route       path="/ticket/summary/:amount"                         render={() => renderComponent(true, <TicketSummary loginType={loginType} userId={loginUser.userId} />) } />
                            <Route       path="/terms/:value"                                   render={() => renderComponent(true, <Terms loginType={loginType} userId={loginUser.userId} />) } />
                            <Route exact path="/Payment/payment/:itemId"                        render={() => renderComponent(true, <Payment loginType={loginType} userId={loginUser.userId} />) } />
                            <Route exact path="/mypage"                                         render={() => renderComponent(true, <MyPage loginType={loginType} userId={loginUser.userId} />) } />
                            <Route exact path="/board/list"                                     render={() => renderComponent(true, <BoardList loginType={loginType} userId={loginUser.userId} />) } />
                            <Route exact path="/quiz/list"                                      render={() => renderComponent(true, <QuizList loginType={loginType} userId={loginUser.userId} />) } />
                            <Route exact path="/lookaround/teacher/:channelId/:selectedCamera"  render={() => renderComponent(false, <TeacherLookAround loginType={loginType} userId={loginUser.userId} />) } />
                            <Route exact path="/lookaround/student/:channelId"                  render={() => renderComponent(false, <StudentLookAround loginType={loginType} userId={loginUser.userId} />) } />
                            <Route exact path="/faq"                                            render={() => renderComponent(true, <Faq loginType={loginType} />) } />
                            <Route exact path="/room/:channelId"                                render={() => renderComponent(false, <Room2 loginType={loginType} userId={loginUser.userId} janusInitialized={this.state.janusInitialized} />)} />
                            <Route exact path="/monitoring/:channelId"                          render={() => renderComponent(false, <Monitoring loginType={loginType} userId={loginUser.userId} janusInitialized={this.state.janusInitialized} />)} />
                            <Route exact path="/dashboard"                                      render={() => renderComponent(true, <DashBoard loginType={loginType} userId={loginUser.userId} />) } />

                            <Route exact path="/admin"                                          render={() => renderComponent(true, <Admin loginType={loginType} isLoginTypeAdmin={isLoginTypeAdmin} userId={loginUser.userId} />) } />

                            {/*<Route exact path="/lookaround"                 render={() => <LookAroundTest loginType={loginType} userId={loginUser.userId} janusInitialized={this.state.janusInitialized} />} />*/}
                        </Switch>
                    ) : (
                        <Switch>
                            <Route exact path="/"                           render={() => renderComponent(true, <Home loginType={loginType} />)} />
                            <Route exact path="/admin"                      render={() => renderComponent(true, <AdminSignIn loginType={loginType} />)} />
                            <Route exact path="/intro"                      render={() => renderComponent(true, <Intro loginType={loginType} />)} />
                            <Route       path="/signup"                     render={() => renderComponent(true, <SignUp loginType={loginType} />)} />
                            <Route       path="/terms/:value"               render={() => renderComponent(true, <Terms loginType={loginType} />)} />
                            <Route exact path="/findpassword"               render={() => renderComponent(true, <FindPassword loginType={loginType} />)} />
                            <Route exact path="/certification/email"        render={() => renderComponent(true, <MailCertification loginType={loginType} />)} />
                            <Route exact path="/certification/password"     render={() => renderComponent(true, <PassCertification loginType={loginType} />)} />
                            <Route exact path="/faq"                        render={() => renderComponent(true, <Faq loginType={loginType} />)} />

                            <Route exact path="/lookaround"                 render={() => renderComponent(true, <LookAroundTest loginType={loginType} userId={loginUser.userId} janusInitialized={this.state.janusInitialized} />)} />

                            <Route                                          render={() => renderComponent(true, <SignIn loginType={loginType} />)} />
                        </Switch>
                    )}
                </Router>
            </div>
        );
    }
};

export default withStyles(style) (App);

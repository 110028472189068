import {observable, action, flow, computed} from "mobx";
import moment from "moment";

const BlobType = 'video/webm';
const RecordNamePostFix = '.webm';
const ScreenWidth = 1280;
const ScreenHeight = 720;

const LogPrefix = '[RecordStore] ';
export default class RecordStore {
    constructor() {
        this.recordingOptions = {mimeType: 'video/webm;codecs=vp8,opus'};
        this.recorder = undefined;
        this.recordStream = undefined;
        this.recordInterval = undefined;
        this.blobs = [];
    }

    @observable isRecording = false;
    @observable isRecordingPaused = false;
    @observable recordDuration = undefined;
    @observable recordName = 'record';

    @computed get isRecordSupported() {
        let supported = false;
        try {
            // var contentTypes = ["video/webm",
            //     "video/webm;codecs=vp8",
            //     "video/webm;codecs=vp8,opus",
            //     "video/x-matroska;codecs=avc1",
            //     "audio/webm",
            //     "video/mp4",
            //     "video/mp4;codecs=avc1",
            //     "video/ogg",
            //     "video/x-msvideo",
            //     "video/mpeg",
            //     "video/invalid"];
            // contentTypes.forEach(contentType => {
            //     console.log(LogPrefix, contentType + ' is '
            //         + (MediaRecorder.isTypeSupported(contentType) ?
            //             'supported' : 'NOT supported '));
            // });

            supported = MediaRecorder.isTypeSupported(this.recordingOptions.mimeType);
        } catch(error) {
            supported = false;
        }
        return supported;
    }

    @computed get recordingTime() {
        let recordingTimeStr = '00:00';

        if(this.recordDuration) {
            const seconds = this.recordDuration.seconds()
            const minutes = this.recordDuration.minutes()
            const hours = this.recordDuration.hours()

            const hourStr = hours > 0 ? hours.toString() : '';
            const minuteStr = minutes > 9 ? minutes.toString() : '0' + minutes.toString();
            const secondStr = seconds > 9 ? seconds.toString() : '0' + seconds.toString();

            recordingTimeStr = hours > 0 ? `${hourStr}:${minuteStr}:${secondStr}` : `${minuteStr}:${secondStr}`;
        }

        return recordingTimeStr;
    }

    @action setIsRecording = (isRecording) => {
        this.isRecording = isRecording;
        this.isRecordingPaused = false;

        if(isRecording) {
            this.recordDuration = moment.duration(0);

            this.recordInterval = setInterval(() => {
                if(!this.isRecordingPaused) {
                    this.addRecordDurationSeconds();
                }
            }, 1000);
        } else {
            if(this.recordInterval) {
                clearInterval(this.recordInterval);
                this.recordInterval = undefined;
            }

            this.recordDuration = undefined;
        }
    }

    @action setIsRecordingPaused = (isRecordingPaused) => {
        this.isRecordingPaused = isRecordingPaused;
    }

    @action addRecordDurationSeconds = () => {
        this.recordDuration = moment.duration(1, 'seconds').add(this.recordDuration);
    }

    @action setRecordName = (recordName) => {
        this.recordName = recordName;
    }

    startRecord = flow(function* startRecord() {
        const recordName = this.recordName + '-' + moment().format("YYYYMMDD-HHmm") + RecordNamePostFix;
        const deviceId = 'screen';
        const blobs = [];
        const mergeAudioStreams = (desktopStream, voiceStream) => {
            const context = new AudioContext();
            const destination = context.createMediaStreamDestination();
            let hasDesktop = false;
            let hasVoice = false;
            if (desktopStream && desktopStream.getAudioTracks().length > 0) {
                // If you don't want to share Audio from the desktop it should still work with just the voice.
                const source1 = context.createMediaStreamSource(desktopStream);
                const desktopGain = context.createGain();
                desktopGain.gain.value = 0.8;
                source1.connect(desktopGain).connect(destination);
                hasDesktop = true;
            }

            if (voiceStream && voiceStream.getAudioTracks().length > 0) {
                const source2 = context.createMediaStreamSource(voiceStream);
                const voiceGain = context.createGain();
                voiceGain.gain.value = 0.8;
                source2.connect(voiceGain).connect(destination);
                hasVoice = true;
            }

            return (hasDesktop || hasVoice) ? destination.stream.getAudioTracks() : [];
        };

        const that = this;
        if(navigator.mediaDevices.getDisplayMedia && navigator.mediaDevices.getUserMedia) {
            try {
                console.log(LogPrefix, 'Getting DisplayMedia for Record ...', deviceId);
                const desktopStream = yield navigator.mediaDevices.getDisplayMedia({audio: true, video: {width: ScreenWidth, height: ScreenHeight}});
                const voiceStream = yield navigator.mediaDevices.getUserMedia({audio: true, video: false});

                const tracks = [
                    ...desktopStream.getVideoTracks(),
                    ...mergeAudioStreams(desktopStream, voiceStream)
                ];

                const mediaStream = new MediaStream(tracks);
                let recorder = undefined;
                try {
                    recorder = new MediaRecorder(mediaStream, this.recordingOptions);
                    blobs.splice(0);
                } catch(error) {
                    console.warn(LogPrefix, 'Can not create MediaRecorder', error);

                    return;
                }

                recorder.onstart = (event) => {
                    console.log(LogPrefix, 'MediaRecorder started', event);

                    that.setIsRecording(true);
                }
                recorder.onstop = (event) => {
                    console.log(LogPrefix, 'MediaRecorder stopped', event);

                    that.setIsRecording(false);

                    const recordedBlobs = new Blob(blobs.splice(0), {type: BlobType});
                    console.log(LogPrefix, 'Recorded Blobs', recordedBlobs);

                    const localDownloadUrl = window.URL.createObjectURL(recordedBlobs);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = localDownloadUrl;
                    a.download = recordName;
                    document.body.appendChild(a);
                    a.click();
                }
                recorder.ondataavailable = (event) => {
                    console.log(LogPrefix, 'MediaRecorder data available', event);
                    if(event.data && event.data.size > 0) {
                        blobs.push(event.data);
                    }
                }
                recorder.onerror = (event) => {
                    console.warn(LogPrefix, 'MediaRecorder error : ', event);
                }

                try {
                    recorder.start();
                } catch(error) {
                    console.warn(LogPrefix, 'MediaRecorder start error', error);
                }

                this.recorder = recorder;
                this.recordStream = mediaStream;
            } catch(error) {
                console.warn("Can't handle media", error);
            }
        } else {
            console.warn('GetDisplayMedia or GetUserMedia not supported');
        }
    });

    @action stopRecord = () => {
        if(this.recorder) {
            this.recorder.stop();
        }
        if(this.recordStream) {
            this.recordStream.getTracks().forEach((t) => t.stop());
        }
        this.recordStream = undefined;
        this.recorder = undefined;
    }

    @action pauseRecord = () => {
        if(this.recorder) {
            try {
                this.recorder.pause();
                this.isRecordingPaused = true;
            } catch(error) {
                console.warn(LogPrefix, 'Recorder pause error', error);
            }
        }
    }

    @action resumeRecord = () => {
        if(this.recorder) {
            try {
                this.recorder.resume();
                this.isRecordingPaused = false;
            } catch(error) {
                console.warn(LogPrefix, 'Recorder resume error', error);
            }
        }
    }
}
import {observable, action, flow, computed} from "mobx";
import {
    ChatState,
    VideoState,
    VideoMode,
    MemberPublishState,
    RoomMode,
    VideoPosition
} from "../views/room/api/RoomPresenter2";
import axios from "axios";
import _ from "lodash";
import {getDeviceBrowserType} from "../common/Params";
import {DrawingType, ModeType, WhiteBoard} from "../views/room/api/WhiteBoard";
import * as Params from "../common/Params";

const LogPrefix = '[RoomStore] ';

export const PUBLISH_BUTTON_TIMEOUT = 3000;

const ChatMemberState = {
    JOIN: 'JOIN',
    EXIT: 'EXIT',
}

export const PresentationType = {
    NONE: 'NONE',
    PRESENTATION: 'PRESENTATION',
    QUESTION: 'QUESTION',
}

export const PresentationStateType = {
    REQUEST: 'REQUEST',
    ALLOW: 'ALLOW',
    READY: 'READY',
    START: 'START',
    RESET: 'RESET',
    END: 'END'
}

export const PresentationEndType = {
    SELF: 'SELF',
    OWNER: 'OWNER'
}

const initWhisperTarget = {
    id: 'all'
}

export default class RoomStore {
    @observable storeInitialized = false;
    @observable server = undefined;
    @observable channel = undefined;
    @observable activationId = 0;
    @observable isActivated = false;
    @observable activationHistories = [];
    @observable user = undefined;
    @observable users = [];

    @observable chatState = ChatState.None;
    @observable chatAreaHidden = false;
    @observable isNewChatBadgeInvisible = true;
    // @observable mode = RoomMode.Normal;
    // @observable mainVideo = VideoMode.Primary;
    // @observable subVideoHidden = false;
    // @observable subVideoPosition = '';
    // @observable memberMic = false;
    // @observable audioDevice = '';
    // @observable videoStreams = [];

    // @observable audioState = VideoState.None;
    // @observable soundPlayed = false;
    // @observable audioMuted = true;
    // @observable primaryState = VideoState.None;
    // @observable secondaryState = VideoState.None;
    // @observable lookAroundPublishState = VideoState.None;
    // // @observable readyWatch = false;
    // @observable isPublishButtonClicked = false;
    //
    // @observable audioPlayConfirm = false;
    // @observable primaryPlayConfirm = false;
    // @observable secondaryPlayConfirm = false;
    //
    // @observable audioFeed = '';
    // @observable primaryFeed = '';
    // @observable secondaryFeed = '';
    // @observable audioStream = undefined;
    // @observable primaryStream = undefined;
    // @observable secondaryStream = undefined;
    // @observable lookAroundStream = undefined;
    // @observable primaryHighQuality = true;
    // @observable secondaryHighQuality = false;
    // @observable primarySubstream = -1;
    // @observable secondarySubstream = -1;
    // @observable primarySubstreamLock = -1;
    // @observable secondarySubstreamLock = -1;
    // @observable subScreenSize = 25;
    // @observable primaryRecord = false;
    // @observable secondaryRecord = false;
    // @observable primaryRecorder = undefined;
    // @observable secondaryRecorder = undefined;
    @observable notificationMsg = '';

    @observable roomId = '';
    @observable roomName = '';
    @observable roomUserId = '';
    @observable role = '';
    @observable userId = '';
    @observable displayName = '';

    @observable videoViewLeft = 0;
    @observable videoViewTop = 0;
    @observable videoViewHeight = 0;
    @observable videoViewWidth = 0;
    @observable landscape = true;
    @observable fullScreen = false;
    @observable chatScrollHeight = 0;
    @observable chatMessages = [];
    @observable messages = [];
    @observable chatMembers = [];
    @observable commandOpen = false;
    @observable memberPublishDialogOpen = false;
    @observable publishOptionDialogOpen = false;
    @observable publishVideoMode = VideoMode.Primary;
    @observable publishDevices = [];
    @observable audioPlayDialogOpen = false;
    @observable reconnectDialogOpen = false;
    @observable kickDialogOpen = false;
    @observable endRoomDialogOpen = false;
    @observable roomEnded = false;
    @observable selectedChatTab = 0;

    // attendance
    @observable isFirstAttendRequest = true;
    @observable isAttendRequestDialogOpen = false;
    @observable attendDialogStatus = false;
    @observable attendDialogMsg = "";
    @observable attendDialogMsgError = false;
    @observable attendDialogMsgHelperText = "출석코드가 일치하지 않습니다";
    @observable attendMsg = null;

    //presentation
    @observable presentation = {};

    // //whiteboard
    // @observable whiteBoard = undefined;
    // @observable lineWidth = 2;
    // @observable isBold = false;
    // @observable isUnderline = false;
    // @observable isStroke = false;
    // @observable fontSize = 26;
    // @observable canvasAlpha = 0;
    // @observable whiteBoardMode = ModeType.DRAWING;
    // @observable whiteBoardDrawingType = DrawingType.PEN;

    //whisper
    @observable whisperTarget = {...initWhisperTarget};

    @observable isChattingPossible = true;

    @observable isVideoRotateX = false;
    @observable isVideoRotateY = false;

    @action initialize = () => {
        this.storeInitialized = false;
        this.server = undefined;
        this.channel = undefined;
        this.activationId = 0;
        this.isActivated = false;
        this.activationHistories = [];
        this.user = undefined;
        this.users = [];

        this.chatState = ChatState.None;
        this.chatAreaHidden = false;
        this.isNewChatBadgeInvisible = true;
        // this.mode = RoomMode.Normal;
        // this.mainVideo = VideoMode.Primary;
        // this.subVideoHidden = false;
        // this.subVideoPosition = VideoPosition.BottomRight;
        // this.memberMic = false;
        // this.audioDevice = '';
        // this.videoStreams = [];

        // this.audioState = VideoState.None;
        // this.soundPlayed = false;
        // this.audioMuted = true;
        // this.primaryState = VideoState.None;
        // this.secondaryState = VideoState.None;
        // this.lookAroundPublishState = VideoState.None;
        // // this.readyWatch = false;
        // this.isPublishButtonClicked = false;
        //
        // this.audioPlayConfirm = false;
        // this.primaryPlayConfirm = false;
        // this.secondaryPlayConfirm = false;
        //
        // this.audioFeed = '';
        // this.primaryFeed = '';
        // this.secondaryFeed = '';
        // this.audioStream = undefined;
        // this.primaryStream = undefined;
        // this.secondaryStream = undefined;
        // this.lookAroundStream = undefined;
        // this.primaryHighQuality = true;
        // this.secondaryHighQuality = false;
        // this.primarySubstream = -1;
        // this.secondarySubstream = -1;
        // this.primarySubstreamLock = -1;
        // this.secondarySubstreamLock = -1;
        // this.subScreenSize = 25;
        // this.primaryRecord = false;
        // this.secondaryRecord = false;
        // this.primaryRecorder = undefined;
        // this.secondaryRecorder = undefined;
        this.notificationMsg = '';

        this.roomId = '';
        this.roomName = '';
        this.roomUserId = '';
        this.role = '';
        this.userId = '';
        this.displayName = '';

        this.videoViewLeft = 0;
        this.videoViewTop = 0;
        this.videoViewHeight = 0;
        this.videoViewWidth = 0;
        this.landscape = true;
        this.fullScreen = false;
        this.chatScrollHeight = 0;
        this.chatMessages = [];
        this.chatMembers = [];
        this.commandOpen = false;
        this.memberPublishDialogOpen = false;
        this.publishOptionDialogOpen = false;
        this.publishVideoMode = VideoMode.Primary;
        this.publishDevices = [];
        this.audioPlayDialogOpen = false;
        this.reconnectDialogOpen = false;
        this.kickDialogOpen = false;
        this.endRoomDialogOpen = false;
        this.roomEnded = false;
        this.selectedChatTab = 0;

        // this.whiteBoard = undefined;
        // this.lineWidth = 2;
        // this.isBold = false;
        // this.isUnderline = false;
        // this.isStroke = false;
        // this.fontSize = 26;
        // this.canvasAlpha = 0;
        // this.whiteBoardMode = ModeType.DRAWING;
        // this.whiteBoardDrawingType = DrawingType.PEN;

        this.whisperTarget = {...initWhisperTarget};

        this.isChattingPossible = true;

        this.isVideoRotateX = false;
        this.isVideoRotateY = false;

    }

    @computed get memberPublishRequestCount() {
        return _.chain(this.chatMembers).map((m) => m.publishState === MemberPublishState.Request ? 1 : 0).reduce((sum, n) => sum + n, 0).value();
    }

    @computed get joinedMemberCount() {
        return _.chain(this.chatMembers).filter((m) => m.joined).size().value();
    }

    @computed get exitUrl() {
        return '/channel/list';
    }

    @action setStoreInitialized = (initialized) => {
        this.storeInitialized = initialized;
    }

    @action setChannel = (channel) => {
        this.channel = channel;
    }

    @action setUser = (user) => {
        this.user = user;
    }

    @action setUsers = (users) => {
        this.users = users;
    }

    @action setChatState = (state) => {
        this.chatState = state;
    }

    @action setChatAreaHidden = (chatAreaHidden) => {
        this.chatAreaHidden = chatAreaHidden;
    }

    @action setNewChatBadgeInvisible = isNewChatBadgeInvisible => this.isNewChatBadgeInvisible = isNewChatBadgeInvisible;

    @action setMode = (mode) => {
        this.mode = mode;
    }

    @action setMainVideo = (mainVideo) => {
        this.mainVideo = mainVideo;
    }

    @action setSubVideoHidden = (subVideoHidden) => {
        this.subVideoHidden = subVideoHidden;
    }

    @action setSubVideoPosition = (position) => {
        this.subVideoPosition = position;
    }

    @action setMemberMic = (on) => {
        this.memberMic = on;
    }

    @action setAudioDevice = (device) => {
        this.audioDevice = device;
    }

    @action addVideoStream = (stream) => {
        this.videoStreams.push(stream);
    }

    @action removeVideoStream = (streamId) => {
        let index = -1;
        for(let i in this.videoStreams) {
            const stream = this.videoStreams[i];
            if(stream.streamId === streamId) {
                index = i;
                break;
            }
        }

        this.videoStreams.splice(index, 1);
    }

    @action removeAllVideoStream = () => {
        this.videoStreams = [];
    }

    // @action changeVideoStream = (streamId, stream) => {
    //     console.log(LogPrefix, 'ChangeVideoStream', streamId, stream);
    //
    //     let index = -1;
    //     for(let i in this.videoStreams) {
    //         const stream = this.videoStreams[i];
    //         if(stream.stream.id === streamId) {
    //             index = i;
    //             break;
    //         }
    //     }
    //
    //     this.videoStreams.splice(index, 1);
    //     this.videoStreams.push(stream);
    // }

    @action setAudioState = (state) => {
        this.audioState = state;
    }
    //
    // @action setSoundPlayed = (played) => {
    //     this.soundPlayed = played;
    // }
    //
    // @action setAudioMuted = (muted) => {
    //     this.audioMuted = muted;
    // }
    //
    // @action setPrimaryState = (state) => {
    //     this.primaryState = state;
    // }
    //
    // @action setSecondaryState = (state) => {
    //     this.secondaryState = state;
    // }
    //
    // @action setLookAroundPublishState = (state) => {
    //     this.lookAroundPublishState = state;
    // }
    //
    // // @action setReadyWatch = (ready) => {
    // //     this.readyWatch = ready;
    // // }
    //
    // @action setPublishButtonClicked = (isPublishButtonClicked) => {
    //     this.isPublishButtonClicked = isPublishButtonClicked;
    // }
    //
    // @action setAudioPlayConfirm = (confirm) => {
    //     this.audioPlayConfirm = confirm;
    // }
    //
    // @action setPrimaryPlayConfirm = (confirm) => {
    //     this.primaryPlayConfirm = confirm;
    // }
    //
    // @action setSecondaryPlayConfirm = (confirm) => {
    //     this.secondaryPlayConfirm = confirm;
    // }
    //
    //
    // @action setAudioStream = (stream) => {
    //     this.audioStream = stream;
    // }
    //
    // @action setPrimaryStream = (stream) => {
    //     this.primaryStream = stream;
    // }
    //
    // @action setSecondaryStream = (stream) => {
    //     this.secondaryStream = stream;
    // }
    //
    // @action setLookAroundStream = (stream) => {
    //     this.lookAroundStream = stream;
    // }
    //
    // @action setAudioFeed = (feed) => {
    //     this.audioFeed = feed;
    // }
    //
    // @action setPrimaryFeed = (feed) => {
    //     this.primaryFeed = feed;
    // }
    //
    // @action setSecondaryFeed = (feed) => {
    //     this.secondaryFeed = feed;
    // }
    //
    // @action setPrimaryStream = (stream) => {
    //     this.primaryStream = stream;
    // }
    //
    // @action setSecondaryStream = (stream) => {
    //     this.secondaryStream = stream;
    // }
    //
    // @action setPrimaryHighQuality = (highQuality) => {
    //     this.primaryHighQuality = highQuality;
    // }
    //
    // @action setSecondaryHighQuality = (highQuality) => {
    //     this.primaryHighQuality = highQuality;
    // }
    //
    // @action setPrimarySubstream = (substream) => {
    //     this.primarySubstream = substream;
    // }
    //
    // @action setSecondarySubstream = (substream) => {
    //     this.secondarySubstream = substream;
    // }
    //
    // @action setPrimarySubstreamLock = (substream) => {
    //     this.primarySubstreamLock = substream;
    // }
    //
    // @action setSecondarySubstreamLock = (substream) => {
    //     this.secondarySubstreamLock = substream;
    // }
    //
    // @action setSubScreenSize = (size) => {
    //     this.subScreenSize = size;
    // }
    //
    // @action setPrimaryRecord = (record) => {
    //     this.primaryRecord = record;
    // }
    //
    // @action setSecondaryRecord = (record) => {
    //     this.secondaryRecord = record;
    // }
    //
    // @action setPrimaryRecorder = (recorder) => {
    //     this.primaryRecorder = recorder;
    // }
    //
    // @action setSecondaryRecorder = (recorder) => {
    //     this.secondaryRecorder = recorder;
    // }

    @action setNotificationMsg = notificationMsg => this.notificationMsg = notificationMsg;


    @action setInformation = (roomId, role, userId, displayName) => {
        this.roomId = roomId;
        this.role = role;
        this.userId = userId;
        this.displayName = displayName;
    }

    @action changeFullscreen = () => {
        if(this.fullScreen) {
            if(document.exitFullscreen) {
                document.exitFullscreen();
            } else if(document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if(document.mozExitFullScreen) {
                document.mozExitFullScreen();
            } else if(document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        } else {
            if(document.body.requestFullscreen) {
                document.body.requestFullscreen();
            } else if(document.body.webkitRequestFullscreen) {
                document.body.webkitRequestFullscreen();
            } else if(document.body.mozRequestFullScreen) {
                document.body.mozRequestFullScreen();
            } else if(document.body.msRequestFullscreen) {
                document.body.msRequestFullscreen();
            }
        }
    }


    @action setVideoViewLeft = left => this.videoViewLeft = left;
    @action setVideoViewTop = top => this.videoViewTop = top;

    @action setVideoViewHeight = (height) => {
        console.log(LogPrefix, 'setVieoViewHeight', height);
        this.videoViewHeight = height;
    }

    @action setVideoViewWidth = (width) => {
        this.videoViewWidth = width;
    }

    @action setRoomEnded = (roomEnded) => {
        this.roomEnded = roomEnded;
    }

    @action setLandscape = (landscape) => {
        this.landscape = landscape;
    }

    @action setFullscreen = (fullScreen) => {
        this.fullScreen = fullScreen;
    }

    @action setChatHeight = (chatHeight) => {
        this.chatScrollHeight = chatHeight;
    }

    @action addChatMessage = (message) => {
        this.chatMessages.push(message);
    }

    @action setMessages = (message) => {
        this.messages.push(message);
    }
    @action chatMemberJoined = (userId) => {
        const member = _.find(this.chatMembers, (m) => m.id === userId);
        if(member) {
            member.joined = true;
        }
    }

    @action chatMemberLeaved = (userId) => {
        const member = _.find(this.chatMembers, (m) => m.id === userId);
        if(member) {
            member.joined = false;
            member.mediaOn = false;
            member.audioSetup = false;
            member.audioOn = false;
            member.soundOn = false;
            member.publishType = PresentationType.NONE;
            member.publishState = MemberPublishState.None;
            member.deviceType = '';
            member.browserType = '';
        }
    }

    @action setChatMemberSoundOn = (userId, soundOn) => {
        const member = _.find(this.chatMembers, (m) => m.id === userId);
        if(member) {
            member.soundOn = soundOn;
        }
    }

    @action setChatMemberAudioOn = (userId, setup, muted) => {
        const member = _.find(this.chatMembers, (m) => m.id === userId);
        if(member) {
            member.audioSetup = setup;
            member.audioOn = !muted;
        }
    }

    @action setChatMemberMediaOn = (userId, mediaOn, deviceType, browserType) => {
        const member = _.find(this.chatMembers, (m) => m.id === userId);
        if(member) {
            member.mediaOn = mediaOn;
            member.deviceType = deviceType;
            member.browserType = browserType;
            if(mediaOn) {
                member.publishType = PresentationType.PRESENTATION;
            }
        }
    }

    @action setChatMemberPublishType = (userId, type) => {
        for(let i in this.chatMembers) {
            const m = this.chatMembers[i];

            if(String(m.id) === String(userId)) {
                m.publishType = type;
                break;
            }
        }
    }

    @action setMemberPublishState = (userId, state) => {
        for(let i in this.chatMembers) {
            const m = this.chatMembers[i];

            if(state === MemberPublishState.Published) {
                if(m.id === userId) {
                    m.publishState = state;
                } else {
                    if (m.publishType === PresentationType.PRESENTATION && m.mediaOn) m.publishType = PresentationType.None;
                }
            } else if(state === MemberPublishState.None) {
                if(m.id === userId) {
                    m.publishState = state;
                    m.publishType = PresentationType.PRESENTATION;
                }
                if(m.publishType === PresentationType.None && m.mediaOn) m.publishType = PresentationType.PRESENTATION;
            } else {
                if(m.id === userId) {
                    m.publishState = state;
                    break;
                }
            }
        }
    }

    @action removeRequestPublish = (userId) => {
        for(let i in this.chatMembers) {
            const m = this.chatMembers[i];

            if(m.id === userId) {
                m.publishState = MemberPublishState.None;
                break;
            }
        }
    }

    @action setCommandOpen = (open) => {
        this.commandOpen = open;
    }

    @action setMemberPublishDialogOpen = (open) => {
        this.memberPublishDialogOpen = open;
    }

    @action setPublishOptionDialogOpen = (open, publishVideoMode, publishDevices) => {
        if(open) {
            this.publishOptionDialogOpen = true;
            this.publishVideoMode = publishVideoMode;
            this.publishDevices = publishDevices;
        } else {
            this.publishOptionDialogOpen = false;
            this.publishVideoMode = VideoMode.Primary;
            this.publishDevices = [];
        }
    }

    @action setAudioPlayDialogOpen = (open) => {
        this.audioPlayDialogOpen = open;
    }

    @action setReconnectDialogOpen = (open) => {
        this.reconnectDialogOpen = open;
    }

    @action setKickDialogOpen = (open) => {
        this.kickDialogOpen = open;
    }

    @action setEndRoomDialogOpen = (open) => {
        this.endRoomDialogOpen = open;
    }

    // @action initWhiteBoard = (whiteBoardRef, syncData) => {
    //     this.whiteBoard = new WhiteBoard(whiteBoardRef, syncData);
    //     if(this.role === 'publisher') {
    //         whiteBoardRef.current.style.zIndex = 19;
    //     }
    // }

    // @action setWhiteBoardData = (boundingClientRect, whiteBoardData) => {
    //     this.whiteBoard.onResize(boundingClientRect);
    //     if(whiteBoardData) this.whiteBoard.drawingHistory = whiteBoardData.data;
    //     this.whiteBoard.onRedraw();
    // }

    // @action setLineWidth = lineWidth => {
    //     this.setWhiteBoardMode(ModeType.DRAWING);
    //     this.lineWidth = lineWidth;
    //     this.whiteBoard.changeLineWidth(lineWidth);
    // }

    // @action setBold = isBold => {
    //     this.setWhiteBoardMode(ModeType.TYPING);
    //     this.isBold = isBold;
    //     this.whiteBoard.setBold(isBold);
    // }

    // @action setUnderline = isUnderline => {
    //     this.setWhiteBoardMode(ModeType.TYPING);
    //     this.isUnderline = isUnderline;
    //     this.whiteBoard.setUnderline(isUnderline);
    // }

    // @action setStroke = isStroke => {
    //     this.setWhiteBoardMode(ModeType.TYPING);
    //     this.isStroke = isStroke;
    //     this.whiteBoard.setStroke(isStroke);
    // }

    // @action setFontSize = fontSize => {
    //     this.setWhiteBoardMode(ModeType.TYPING);
    //     this.fontSize = fontSize;
    //     this.whiteBoard.changeFontSize(fontSize);
    // }

    // @action setCanvasAlpha = canvasAlpha => {
    //     this.setWhiteBoardMode(ModeType.CANVAS_ALPHA);
    //     this.canvasAlpha = canvasAlpha;
    //     this.whiteBoard.changeCanvasAlpha(canvasAlpha);
    // }

    // @action setWhiteBoardMode = mode => {
    //     this.whiteBoardMode = mode;
    //     this.whiteBoard.changeModeType(mode);
    // }

    // @action setWhiteBoardDrawingType = drawingType => {
    //     this.setWhiteBoardMode(ModeType.DRAWING);
    //     this.whiteBoardDrawingType = drawingType;
    //     this.whiteBoard.changeDrawingType(drawingType);
    // }

    // @action onClean = () => {
    //     this.whiteBoard.initDrawingHistory();
    //     this.whiteBoard.onClear();
    // }

    @action setWhisperTarget = whisperTargetId => this.whisperTarget = whisperTargetId === "all" ? {...initWhisperTarget} : this.chatMembers.find(m => String(m.id) === String(whisperTargetId));

    @action setChattingPossible = isChattingPossible => this.isChattingPossible = isChattingPossible;

    @action setVideoRotate =  (isVideoRotateX, isVideoRotateY, videoRef) => {
        this.isVideoRotateX = isVideoRotateX;
        this.isVideoRotateY = isVideoRotateY;
        const degX = isVideoRotateX ? 180 : 0;
        const degY = isVideoRotateY ? 180 : 0;
        const rotate = `rotateX(${degX}deg) rotateY(${degY}deg)`;
        videoRef.current.style.transform = rotate;
        videoRef.current.style.webkitTransform = rotate;
        videoRef.current.style.msTransform = rotate;
        videoRef.current.style.MozTransform = rotate;
        videoRef.current.style.OTransform = rotate;
    }

    @action setSelectedChatTab = (tabIndex) => {
        this.selectedChatTab = tabIndex;
    }

    @action questionInit = () => {
        this.chatMembers.forEach(m => {
            if(m.publishState === MemberPublishState.Request) {
                m.publishType = PresentationType.PRESENTATION;
                m.publishState = MemberPublishState.None
            }
        });
    }

    // attendance
    @action changeIsFirstAttendRequest = isFirstAttendRequest => this.isFirstAttendRequest = isFirstAttendRequest;
    @action changeAttendRequestDialogOpen = isAttendRequestDialogOpen => this.isAttendRequestDialogOpen = isAttendRequestDialogOpen;
    @action changeAttendDialogStatus = attendDialogStatus => this.attendDialogStatus = attendDialogStatus;
    @action changeAttendDialogMsgError = attendDialogMsgError => this.attendDialogMsgError = attendDialogMsgError;
    @action changeAttendDialogMsg = attendDialogMsg =>  this.attendDialogMsg = attendDialogMsg;
    @action changeAttendMsg = data => {
        this.attendMsg = data;
        if(data && this.channel && String(this.channel.userId) !== String(this.userId)) {
            this.attendMsgInit();
        }
    };

    @action attendMsgInit = () => {
        this.attendDialogStatus = false;
        this.attendDialogMsg = "";
        this.attendDialogMsgError = false;
        this.attendDialogMsgHelperText = "출석코드가 일치하지 않습니다";
        this.attendDialogStatus = true;
    }

    //presentation
    @action changePresentation = presentation => this.presentation = presentation;

    @computed
    get isPublisher() {
        return this.role === 'publisher';
    }

    @computed
    get whisperTargetMembers() {
        return this.chatMembers.filter(m => String(m.id) !== String(this.userId)).filter(m => m.joined);
    }

    @computed
    get isMobileLandscape() {
        const deviceBrowserType = Params.getDeviceBrowserType(false);
        return this.landscape && (deviceBrowserType.deviceType === 'android' || deviceBrowserType.deviceType === 'ios');
    }

    @computed
    get isMobile() {
        const deviceBrowserType = Params.getDeviceBrowserType(false);
        return (deviceBrowserType.deviceType === 'android' || deviceBrowserType.deviceType === 'ios');
    }

    initializeStore = flow(function* (channelId, userId, types) {
        console.log(LogPrefix, "Initializing room store");
        this.initialize();

        try {
            const channelResponse = yield axios.get(`/api/v1/channels/channel-info?channel-id=${channelId}`);
            const usersResponse = yield axios.get(`/api/v1/channels/${channelId}/chat/users`);
            const channel = channelResponse.data;
            const users = usersResponse.data;
            console.log(LogPrefix, 'Channel', channel);
            console.log(LogPrefix, 'Users', users);

            let mainEndpoint = '';

            if(channel) {
                if(channel.channelBroadcastEndpointList) {
                    const endPointResult = _.find(channel.channelBroadcastEndpointList, (endpoint) => endpoint.typeCode === 'MAIN');
                    if(endPointResult && endPointResult.broadcastEndpoint) {
                        mainEndpoint = endPointResult.broadcastEndpoint;
                    } else {
                        throw new Error("Can't find main endpoint");
                    }
                } else {
                    throw new Error("Can't find endpoint list");
                }

                if(channel.userId === userId) {
                    const putData = {
                        channelId: channel.channelId,
                        statusCode: 'OPENED',
                    };

                    yield axios.put("/api/v1/channels/status", putData);
                }


            } else {
                throw new Error("Can't find channel information");
            }

            const userResponse = yield axios.get(`/api/v1/users/user-info?user-id=${userId}`);
            const user = userResponse.data;
            console.log(LogPrefix, 'User', user);

            const chatMembers = users.map((u) => ({
                id: String(u.userId),
                owner: false,
                self: u.userId === user.userId,
                joined: false,
                mediaOn: false,
                publishState: MemberPublishState.None,
                user: u,
            }));

            if(channel.userId === userId) {
                chatMembers.push({
                    id: String(userId),
                    owner: true,
                    self: true,
                    joined: false,
                    mediaOn: false,
                    publishState: MemberPublishState.None,
                    user: Object.assign({}, user),
                });
            } else {
                const ownerResponse = yield axios.get(`/api/v1/users/user-info?user-id=${channel.userId}`);
                const owner = ownerResponse.data;
                console.log(LogPrefix, 'Owner', owner);

                chatMembers.push({
                    id: String(owner.userId),
                    owner: true,
                    self: false,
                    joined: false,
                    mediaOn: false,
                    audioSetup: false,
                    audioOn: false,
                    soundOn: false,
                    publishType: PresentationType.NONE,
                    publishState: MemberPublishState.None,
                    user: owner,
                });
            }

            chatMembers.sort((a, b) => {
                if(a.owner) {
                    return -1;
                } else if(b.owner) {
                    return 1;
                } else if(a.self) {
                    return -1;
                } else if(b.self) {
                    return 1;
                } else {
                    return a.user.userId - b.user.userId;
                }
            })
            console.log(LogPrefix, 'ChatMembers', chatMembers);

            if(channel.userId !== user.userId) {
                this.setUserState(channel.channelId, user, ChatMemberState.JOIN);
            }

            this.server = mainEndpoint;
            this.channel = channel;
            this.user = user;
            this.users = users;
            this.chatMembers = chatMembers;

            this.roomId = channel.channelId;
            this.roomName = channel.channelName;
            this.roomUserId = String(channel.userId);
            this.role = channel.userId === userId ? 'publisher' : 'subscriber';
            this.userId = String(user.userId);
            this.displayName = user.userName;

            this.setStoreInitialized(true);

            console.log(LogPrefix, "Initialized room store");
        } catch (error) {
            console.log(LogPrefix, "Can't initialize room store", error);
        }
    });

    exitRoom = flow(function* exitRoom() {
        const channel = this.channel;
        const user = this.user;

        try {
            if(this.channel.userId === user.userId) {
                const param = {
                    channelId: channel.channelId,
                    statusCode: 'EXITED',
                };
                console.log(LogPrefix, "Putting a exit room information");
                yield axios.put("/api/v1/channels/status", param);
                console.log(LogPrefix, "Putted a exit room information");
            } else {
                console.log(LogPrefix, "logChannelExitHistory", user.userId);
                this.logChannelExitHistory(user.userId);
                this.setUserState(channel.channelId, user, ChatMemberState.EXIT);
            }

            this.endRoomDialogOpen = false;
            this.roomEnded = true;
        } catch(error) {
            console.log(LogPrefix, "Can't put a exit room information", channel);
        }
    });

    endRoom = flow(function* endRoom() {
        const channel = this.channel;
        const user = this.user;
        try {
            if (channel.userId === user.userId) {

                const param = {
                    channelId: channel.channelId,
                    statusCode: 'CLOSED',
                };

                console.log(LogPrefix, "Putting a end room information");
                yield axios.put("/api/v1/channels/status", param);
                console.log(LogPrefix, "Putted a end room information");

                this.endRoomDialogOpen = false;
                this.roomEnded = true;
            } else {
                this.logChannelExitHistory(user.userId);
                this.endRoomDialogOpen = false;
            }
        } catch(error) {
            console.log(LogPrefix, "Can't put a end room information", channel);
        }
    });

    activateChannel = flow(function* () {
        console.log(LogPrefix, 'activateChannel');
        try {
            const channelId = this.channel.channelId;
            const response = yield axios.post(`/api/v1/activations/${channelId}`);
            console.log(LogPrefix, "activation response", response);
            const activation = response.data;

            this.activationId = activation.activationId;
            this.isActivated = true;
            if(this.activationHistories) {
                this.activationHistories.forEach((joinedUserId) => {
                    this.logChannelEnterHistory(joinedUserId);
                })
            }
            this.activationHistories = [];

            console.log(LogPrefix, "Channel activated");
        } catch(error) {
            console.warn(LogPrefix, "Can't activate channel", error);
        }
    });

    getActivationChannel = flow(function* () {
        try {
            const channelId = this.channel.channelId;
            const response = yield axios.get(`/api/v1/activations/${channelId}`);
            console.log(LogPrefix, "activation response", response);
            const activation = response.data;

            this.activationId = activation.activationId;
            console.log(LogPrefix, "Channel activated");
        } catch(error) {
            console.warn(LogPrefix, "Can't activate channel", error);
        }
    });

    deactivateChannel = flow(function*() {
        try {
            const channelId = this.channel.channelId;
            const activationId = this.activationId;

            yield axios.put(`/api/v1/activations/${channelId}/${activationId}`);
            console.log(LogPrefix, "Channel deactivated",this.activationHistories);
        } catch(error) {
            console.warn(LogPrefix, "Can't deactivate channel", error);
        }
    });

    logChannelEnterHistory = flow(function*(joinedUserId) {
        if(this.isActivated) {
            try {
                const channelId = this.channel.channelId;
                const activationId = this.activationId;
                const userId = Number(joinedUserId);

                yield axios.post(`/api/v1/activations/${channelId}/${activationId}/${userId}`);
                console.log(LogPrefix, "Logged channel enter history", joinedUserId);
            } catch(error) {
                console.warn(LogPrefix, "Can't log channel enter history", error);
            }
        } else {
            console.log(LogPrefix, "Pushed logChannelEnterHistory");
            this.activationHistories.push(joinedUserId);
        }
    });

    logChannelExitHistory = flow(function*(leavedUserId) {
        try {
            const channelId = this.channel.channelId;
            const activationId = this.activationId;
            const userId = Number(leavedUserId);
            console.log("activationId",activationId);
            yield axios.put(`/api/v1/activations/${channelId}/${activationId}/${userId}`);
            console.log(LogPrefix, "Logged channel exit history", leavedUserId);
        } catch(error) {
            console.warn(LogPrefix, "Can't log channel exit history", error);
        }
    })

    setUserState = flow(function* (channelId, user, type) {
        try {
            const userInfo = {...user};
            const deviceBrowser = getDeviceBrowserType(false);
            userInfo.deviceType = deviceBrowser.deviceType;
            userInfo.browserType = deviceBrowser.browserType;

            yield axios.post(`/api/v1/channels/user/${type}?channel-id=${channelId}`, userInfo);
        } catch(error) {
            console.log(LogPrefix, "Can't set user type", error);
        }
    });

    //attend
    requestAttend = flow(function* (sendAttendanceControlMessage) {
        try {
            const userList = this.chatMembers.filter(u => u.joined).filter(u => !u.owner).map(u => u.user);
            const response = yield axios.post(`/api/v1/attend/channel/${this.channel.channelId}/request?user-id=${this.userId}`, userList);
            console.log("received attend request id", response);
            if(response.status === 200) {
                sendAttendanceControlMessage(this.userId, response.data);
            }
        } catch (error) {
            console.log(LogPrefix, "Can't attend request", error);
        }
    });

    requestAttendLate = flow(function* (sendAttendanceControlMessage) {
        try {
            const userList = this.chatMembers.filter(u => u.joined).filter(u => !u.owner).map(u => u.user);
            const response = yield axios.post(`/api/v1/attend/channel/${this.channel.channelId}/request/late?user-id=${this.userId}`, userList);
            console.log("received attend request id", response);
            if(response.status === 200) {
                sendAttendanceControlMessage(this.userId, response.data);
            }
        } catch (error) {
            console.log(LogPrefix, "Can't attend request", error);
        }
    });

    getAuthCode = flow(function* (attendMsg) {
        try {
            const response = yield axios.get(`/api/v1/attend/channel/${this.channel.channelId}/request/${attendMsg.attendRequestId}/type/${attendMsg.attendRequestTypeId}?user-id=${this.userId}`);
            console.log(LogPrefix, "received auth code", response);
            if(response.status === 200) {
                console.log(response.data);
                if(response.data) {
                    this.changeAttendMsg(response.data);
                    this.changeAttendDialogStatus(true);
                }
            }
        } catch (error) {
            console.log(LogPrefix, "Can't get auth code", error);
        }
    });

    updateAttendResponse = flow(function* () {
        try {
            const response = yield axios.put(`/api/v1/attend/channel/${this.channel.channelId}/request/${this.attendMsg.attendRequestId}/type/${this.attendMsg.attendRequestTypeId}/code/${this.attendDialogMsg}?user-id=${this.userId}`);
            if(response.status === 200) {
                this.attendMsg = null;
            }
        } catch (error) {
            console.log(LogPrefix, "Can't update attend response", error);
        }
    });

    //presentation
    requestPresentation = flow(function* (presentationType, sendRequestPublishControlMessage, userId, comment) {
        try {
            if(!comment) comment = "";
            const response = yield axios.post(`/api/v1/presentation/${presentationType}/channel/${this.channel.channelId}/request?user-id=${userId}&comment=${comment}`);
            console.log(LogPrefix, "received presentation request id", response);
            if(response.status === 200) {
                this.presentation = response.data;

                if(presentationType === PresentationType.QUESTION) {
                    sendRequestPublishControlMessage(response.data);
                }else if(presentationType === PresentationType.PRESENTATION) {
                    sendRequestPublishControlMessage(userId, response.data);
                }
            }
        } catch (error) {
            console.log(LogPrefix, "Can't presentation request", error);
        }
    });

    updatePresentationState = flow(function* (presentationType, presentationStateType, comment) {
       try {
           if(!comment) comment = "";
           yield axios.put(`/api/v1/presentation/${presentationType}/channel/${this.channel.channelId}/request/${this.presentation.presentationId}/state/${presentationStateType}?user-id=${this.user.userId}&comment=${comment}`)
       } catch (error) {
           console.log(LogPrefix, "Can't update presentation state", error);
       }
    });

    // //whiteboard
    // getWhiteBoardHistory = flow(function* (currentBoundingClientRect) {
    //     try {
    //         const response = yield axios.get(`/api/v1/whiteboard/${this.channel.channelId}`);
    //         if(response.status === 200) {
    //             const whiteBoardData = JSON.parse(response.data.history);
    //             this.setWhiteBoardData(currentBoundingClientRect, whiteBoardData);
    //             if(this.whiteBoard.syncData) this.whiteBoard.syncData(true);
    //             if(!whiteBoardData.visible && this.role !== 'publisher') {
    //                 this.whiteBoard.onResize({width: 1, height: 1});
    //             }
    //         } else {
    //             console.log(LogPrefix, "Can't get whiteboard history", response);
    //         }
    //     } catch (error) {
    //         console.log(LogPrefix, "Can't get whiteboard history", error);
    //     }
    // });
    //
    // saveWhiteBoardHistory = flow(function* (isVisible, syncData) {
    //     try {
    //         const param = {
    //             channelId: this.channel.channelId,
    //             history: JSON.stringify(this.whiteBoard.getSyncData(isVisible))
    //         }
    //         const response = yield axios.post(`/api/v1/whiteboard/${this.channel.channelId}`, param);
    //         if(response.status === 200) {
    //             if(!isVisible) this.whiteBoard.onResize({width: 1, height: 1});
    //             if(syncData) syncData();
    //         } else {
    //             console.log(LogPrefix, "Can't save whiteboard history", response);
    //         }
    //     } catch (error) {
    //         console.log(LogPrefix, "Can't save whiteboard history", error);
    //     }
    // });
    //
    // clearWhiteBoardHistory = flow(function* () {
    //     try {
    //         const response = yield axios.delete(`/api/v1/whiteboard/${this.channel.channelId}`);
    //         if(response.status !== 200) {
    //             console.log(LogPrefix, "Can't clear whiteboard history", response);
    //         }
    //     } catch (error) {
    //         console.log(LogPrefix, "Can't clear whiteboard history", error);
    //     }
    // });
}
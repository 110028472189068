import React from "react";
import {inject, observer} from "mobx-react";
import Grid from "@material-ui/core/Grid";
import QuizResultExpansionPanels from "./QuizResultExpansionPanels";

@inject("dashboardStore")
@observer
class DashBoardChannelQuizResult extends React.Component {
    render() {
        const { quizResult} = this.props.dashboardStore;
        return (
            <Grid container
                  // direction="column"
                  justify="center"
                  alignItems="center"
            >
                <Grid item xs={12}>
                   <QuizResultExpansionPanels
                       data={quizResult}
                       makeQuizChartData={quiz => this.props.dashboardStore.makeQuizChartData(quiz)}
                       barChart={(chartDivName, barChartData) => this.props.dashboardStore.makeBarChart(chartDivName, barChartData)}
                       pieChart={(chartDivName, pieChartData) => this.props.dashboardStore.makePieChart(chartDivName, pieChartData)}
                       onImageDoubleClick={image => this.props.dashboardStore.onImageDoubleClick(image)}
                   />
                </Grid>
            </Grid>
        );
    }
}

export default DashBoardChannelQuizResult;
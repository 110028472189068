import React from 'react';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>({
    wrap: {
        zIndex: 1050,
        position: 'absolute',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down("xs")]: {
            top: 0,
            height: '210px',
        },
    },
    container: {
        zIndex: 1051,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: 'absolute',
        top: 0,
        left: 0,
        margin: 0,
        width: '100%',
        height: '100%',
    },
    text: {
        display: 'block',
        zIndex: 1051,
        height: '23px',
        objectFit: 'contain',
        fontSize: '24px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '0.5',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#fff2ac',
        [theme.breakpoints.down("xs")]: {
            fontSize: '14px',
        }
    }
}));

export default function BroadcastVideoTrackEmpty(props) {
    const classes = useStyles();

    return (
        <div className={classes.wrap}>
            <div className={classes.container}>
                <div className={classes.text}>
                    방송 송출이 중단되었습니다.
                </div>
                <div className={classes.text} style={{marginTop: '20px'}}>
                    잠시 기다려 주세요.
                </div>
            </div>
        </div>
    );
}
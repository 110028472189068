import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";

import {Avatar, Button, Container, Drawer, Fab, Grid, Link, Typography} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import HelpIcon from "@material-ui/icons/Help";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import NotificationsIcon from "@material-ui/icons/Notifications";
import Footer from "../components/Footer";
import {inject, observer} from "mobx-react";
import axios from "axios";
import fileDownload from "js-file-download";


const styles = theme => ({
    mainContainer: {
        padding:0,
        width: '100%',
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        width: '100%',
        textAlign: 'center',
    },

    startButton: {
        width: 210,
        height: 48,
        fontSize: 16,
        borderRadius: 24,
    },
    body1: {
        paddingTop: 80,
        backgroundColor: '#fafafa',
        paddingBottom: theme.spacing(5),
    },
    body1TitleContainer: {

    },
    body1Title: {
        fontSize: '30px',
        lineHeight: 1.33,
        color: '#333333',
        fontWeight:'300',
        '& b':{
          fontWeight:800  
        },
    },
    body1StartContainer: {
        paddingTop: theme.spacing(7),
        paddingBottom: theme.spacing(3),

    },
    body1UserImageContainer: {
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            '& img':{
                width:'90vw',
            }
        }

    },
    body1MemberImageContainer: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.down('xs')]: {
            '& img':{
                width:'90vw',
            }
        }

    },
    body1CardImage: {
    },

    body2: {
        paddingTop: 100,
        paddingBottom: 100,
    },
    body2TitleContainer: {

    },
    body2Avatar: {
        width: 100,
        height: 100,
        border: 'solid 1px #d4d4d4',
        backgroundColor: '#FFFFFF',
    },
    body2Title: {
        fontSize: 28,
        lineHeight: 1.71,
        color: '#3c3c3c',
    },
    body2CardContainer: {
        paddingTop: theme.spacing(6),
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
        paddingBottom: theme.spacing(6),
    },
    body2CardTitle: {
        fontSize: 16,
        color: '#3c3c3c',
        textAlign: 'left',
        fontWeight:'800',
        '& b':{
            color:'#cf1a1b',
            fontSize:30,
        }
    },
    body2CardBody: {
        width: '100%',
        fontSize: 14,
        color: '#9d9d9d',
        textAlign: 'left',
    },

    footerContainer: {
        backgroundImage: 'url(/images/bg-parttern.png)',
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'top 0',
        backgroundColor:'#fafafa',
        paddingBottom: 50,
        paddingTop: 60,
        
    },
    footerTitleContainer: {
    },
    footerTitle: {
        fontSize: 28,
        lineHeight: 1.71,
        letterSpacing: -0.56,
        color: '#3c3c3c',
    },
    footerBody1: {
        paddingTop: theme.spacing(3),
        fontSize: 14,
        lineHeight: 1.43,
        color: '#3c3c3c',
    },
    footerBody2: {
        fontSize: 14,
        lineHeight: 1.43,
        color: '#3c3c3c',
    },
    footerButton: {
        margin: theme.spacing(3,1,2,1),
        borderRadius: 50,
        padding: theme.spacing(1,0,1),
        minWidth:'192px',
        '&:first-of-type':{
            backgroundColor:'#fff'
        }
    },

    helpContainer: {
        width: 350,
        padding: theme.spacing(4),
        boxSizing:'border-box'
    },
    helpLogoContainer: {
        display: 'flex',
        flexDirection:'column',
        textAlign:'center'

    },
    helpLogoImage: {
        padding: theme.spacing(2,0,1)
    },
    helpLogoText: {
        fontSize: 22,
        fontWeight: 800,
        margin:theme.spacing(2,0,1),
    },
    helpPageTitle:{
        textAlign:'center',
        fontSize:'1.1em',
    },
    helpTitleContainer: {
        paddingTop: theme.spacing(2),
        paddingLeft:'20px',
        lineHeight:'1.45',
        '& > li':{
            fontSize:'1em',
            marginBottom:'1.5em',
        },
        '& b':{
            borderBottom:'1px solid #333'
        }
    },
    helpBodyContainer: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        '& > li':{
            fontSize:'0.9em'
        }
    },
    helpIcon:{
        position: 'fixed',
        bottom: '35px',
        right: '35px',
        boxShadow: 'none',
        background:'none',
        width:'85px',
        fontSize: 15,
        '&:hover, &:focus, &:active':{
            background:'none',
            boxShadow:'none',
        },
        '& svg':{
            width:36,
            height:36,
            marginRight:4,
            boxShadow:'0 2px 3px #c1c1c1',
            borderRadius:'50px',
        },
        '& span':{
            color:'rgba(51,51,51,0.56)'
        }
    },
    helpCloseIcon: {
        position: 'absolute',
        right: '1rem',
        top: '1rem',
        color: 'rgba(51,51,51,0.37)',
        width:'40px',
        height:'40px',
        cursor: 'pointer',
    },
});


@inject('helpStore', 'authStore')
@observer
class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imageIndex: 0,
        };
    }

    handleClickStart = () => {
        this.props.history.push("/channel/list");
    }

    handleClickTicket = () => {
        this.props.history.push("/ticket/list");
    }

    handleClickSwipeableView = () => {
        const newIndex = (this.state.imageIndex + 1) % 3;

        this.setState({imageIndex: newIndex});
    }


    handlePlayStoreDownload = () => {
        axios.get('/api/v1/files/app/app-debug.apk', {responseType: 'blob'})
            .then(response => {
                console.log(response);
                fileDownload(response.data, 'app-debug.apk');
            })
            .catch(error => {

            });
    }

    render() {
        const { classes } = this.props;
        const { isLoginTypeUser } = this.props.authStore;

        return (
            <React.Fragment>
                <Container component="main" maxWidth="lg" className={classes.mainContainer}>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.mainContent}>

                        <div className={classes.body1} >
                            {isLoginTypeUser ?
                                <React.Fragment>
                                    <div className={classes.body1TitleContainer}>
                                        <Typography className={classes.body1Title}>라이브온에어 <b>이용 안내</b></Typography>
                                    </div>

                                    <div className={classes.body1StartContainer}>
                                        <Button color="primary" variant="outlined" className={classes.startButton} onClick={() => this.props.history.push('/channel/list')}>강의 시작하기</Button>
                                    </div>

                                    <div className={classes.body1UserImageContainer}>
                                        <img src="/images/intro-guide.jpg" alt="ONTHELIVE Usage Guide" />
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className={classes.body1TitleContainer}>
                                        <Typography className={classes.body1Title}>쉽고 편하게 <b>실시간 원격 강의</b>을 들을 수 있습니다.</Typography>
                                    </div>

                                    <div>
                                        <div className={classes.body1MemberImageContainer}>
                                            <SwipeableViews index={this.state.imageIndex}>
                                                <img className={classes.body1CardImage} src="/images/img-student-step1.png" alt="student step 1" onClick={this.handleClickSwipeableView} />
                                                <img className={classes.body1CardImage} src="/images/img-student-step1.png" alt="student step 2" onClick={this.handleClickSwipeableView} />
                                                <img className={classes.body1CardImage} src="/images/img-student-step2.png" alt="student step 3" onClick={this.handleClickSwipeableView} />
                                            </SwipeableViews>
                                        </div>

                                        <Button color="primary" variant="contained" className={classes.startButton} onClick={() => this.props.history.push('/channel/list')}>강의 시작하기</Button>
                                    </div>
                                </React.Fragment>
                            }
                        </div>

                        <div className={classes.body2}>
                            <div className={classes.body2TitleContainer}>
                                <Typography className={classes.body2Title}>관리형 원격 강의을 통해</Typography>
                                <Typography className={classes.body2Title}>학생들에게 <b>교육 서비스를 제공</b>해주세요.</Typography>
                            </div>

                            <Grid container spacing={5} className={classes.body2CardContainer}>
                                <Grid item container spacing={3} xs={12} md={6} >
                                    <Grid item xs={4} style={{display: 'flex', justifyContent: 'center'}}>
                                        <Avatar className={classes.body2Avatar}><img src="/images/icon-1.png" alt="icon1"/></Avatar>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography className={classes.body2CardTitle}>실시간 영상 커뮤니케이션<b>.</b></Typography>
                                        <Typography className={classes.body2CardBody}>영상 커뮤니케이션 도중 언제든 메시지 전송 가능하고 다른 사람과 자유롭게 채팅 가능합니다.</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={3} xs={12} md={6} >
                                    <Grid item xs={4} style={{display: 'flex', justifyContent: 'center'}}>
                                        <Avatar className={classes.body2Avatar}><img src="/images/icon-2.png" alt="icon2"/></Avatar>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography className={classes.body2CardTitle}>언제나 어디서나 완벽한 강의<b>.</b></Typography>
                                        <Typography className={classes.body2CardBody}>핸드폰과 LIVEONAIR만 있다면 학생들을 가르칠 수 있습니다. 어디든 상관없습니다.</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={3} xs={12} md={6} >
                                    <Grid item xs={4} style={{display: 'flex', justifyContent: 'center'}}>
                                        <Avatar className={classes.body2Avatar}><img src="/images/icon-3.png" alt="icon3"/></Avatar>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography className={classes.body2CardTitle}>휼륭한 교육 서비스 구축<b>.</b></Typography>
                                        <Typography className={classes.body2CardBody}>강의자료 문서 공유, 최대 3개 화면 지원 서비스를 사용하여 최고의 교육 서비스를 제공하세요.</Typography>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={3} xs={12} md={6} >
                                    <Grid item xs={4} style={{display: 'flex', justifyContent: 'center'}}>
                                        <Avatar className={classes.body2Avatar}><img src="/images/icon-4.png" alt="icon4"/></Avatar>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography className={classes.body2CardTitle}>한 번에 강의참석, 관리 제공<b>.</b></Typography>
                                        <Typography className={classes.body2CardBody}>클릭 한번으로 여러명에게 강의 초대를 보내세요. PC, 스마트폰을 통해 강의에 참여 하실 수 있습니다.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>

                        <div className={classes.footerContainer}>
                            <div className={classes.footerTitleContainer}>
                                <Typography className={classes.footerTitle} display="inline">라이브온에어는</Typography>
                                <Typography className={classes.footerTitle} display="inline"><b> 교수 관리형 온라인 학습 시스템</b>입니다</Typography>
                            </div>

                            <Typography className={classes.footerBody1}>라이브온에어 교수 관리형 온라인 학습 시스템에 대해 궁금한게 있으시면 support@onthe.live 로 연락 주십시요.</Typography>
                            {/*<Typography className={classes.footerBody2}>이용권에 대해 궁금하시면 이용권구매를 먼저 확인해주세요</Typography>*/}

                            {/*<Button variant="outlined" color="primary" className={classes.footerButton} onClick={() => this.handleClickTicket()}>이용권 구매</Button>*/}
                            {/*<Button variant="contained" color="primary" className={classes.footerButton} onClick={() => this.handleClickStart()}>온라인 강의 시작하기</Button>*/}
                        </div>
                    </div>
                </Container>

                <Footer topMargin={0}/>

                <div>
                    <Fab size="small" className={classes.helpIcon} onClick={() => this.props.helpStore.openHelp()} >
                        <HelpIcon color="primary" viewBox="2 2 20 20" /> <span>도움말</span>
                    </Fab>

                    <Drawer anchor="right" open={this.props.helpStore.open} onClose={() => this.props.helpStore.closeHelp()} >
                        <CloseRoundedIcon className={classes.helpCloseIcon} onClick={() => this.props.helpStore.closeHelp()}/>

                        <div className={classes.helpContainer}>
                            <div className={classes.helpLogoContainer}>
                                <div className={classes.helpLogoImage}><img src="/images/logo-vert.png" alt="ONTHELIVE" /></div>
                                <Typography className={classes.helpLogoText}>도움말</Typography>
                            </div>

                            <div>
                                <Typography className={classes.helpPageTitle} variant="h5" >라이브온에어</Typography>
                                {this.props.loginType === 'USER' ?
                                    <React.Fragment>
                                        <ol className={classes.helpTitleContainer}>
                                            <li>라이브온에어 사용에 어려움이 있을 때에는 <HelpIcon color="primary" />을 눌러주세요
                                                <ul className={classes.helpBodyContainer}>
                                                    <li>사용법에 대한 도움말을 살펴 볼 수 있습니다.</li>
                                                </ul>
                                            </li>

                                            <li>강의을 만든 후 강의을 촬영하기 위해서는 스마트폰 앱이 필요합니다.
                                                <ul className={classes.helpBodyContainer}>
                                                    <li>안드로이드 앱은 <Link href="https://onestore.co.kr/userpoc/apps/view?pid=0000747575" target="_blank" style={{cursor: 'pointer'}}>여기</Link>를 클릭하면 다운로드 받으실 수 있습니다.</li>
                                                    <li>원스토어(https://onestore.co.kr)에서 '라이브온에어' 혹은 'liveonair'로 검색하시면 안드로이드 앱을 다운로드 받으실 수 있습니다.</li>
                                                    <li>구글플레이와 애플앱스토어에는 심사가 끝나는 대로 서비스될 예정입니다.</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <ol className={classes.helpTitleContainer}>
                                            <li>라이브온에어 사용에 어려움이 있을 때에는 <HelpIcon color="primary" />을 눌러주세요
                                                <ul className={classes.helpBodyContainer}>
                                                    <li>사용법에 대한 도움말을 살펴 볼 수 있습니다.</li>
                                                </ul>
                                            </li>

                                            <li>새로운 알림이 있을 경우 상단의 <NotificationsIcon />에 표시가 됩니다.
                                                <ul className={classes.helpBodyContainer}>
                                                    <li><NotificationsIcon />을 클릭하여 알림장 내용을 확인하세요.</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </React.Fragment>
                                }

                            </div>
                        </div>
                    </Drawer>
                </div>
            </React.Fragment>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (Home)));
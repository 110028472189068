import {action, computed, flow, observable, toJS} from "mobx";
import axios from "axios";
import moment from "moment";
import _ from "lodash";

export default class CalendarChannelStore {
    @observable userId = '';
    @observable userType = '';
    @observable selectedDay = moment();
    @observable weekDays = [];
    @observable channelList = [];
    @observable isOpenDeleteDialog = false;
    @observable deleteChannelId = '';
    @observable channelResultChannelId = '';
    @observable isOpenChannelResultDialog = false;
    @observable channelResultMemberList = [];
    @observable paging = {
        list: [],
        totalCount: 0,
        page: 0,
        rowsPerPage: 10,
    }

    @action initialize = (userId, userType, day) => {
        this.userId = userId;
        this.userType = userType;

        this.setSelectedDay(day);
    }

    @action setSelectedDay = (day) => {
        if(day) {
            this.selectedDay = moment(day);
        } else {
            this.selectedDay = moment();
        }
        const firstWeekDay = moment(this.selectedDay).day(0);

        const days = [];
        for(let i=0; i<7; i++) {
            days.push(moment(firstWeekDay).add(i, 'days'));
        }
        this.weekDays = days;

        this.loadingChannelList();
    }

    @action toPreviousWeek = () => {
        const newDay = moment(toJS(this.selectedDay)).subtract(7, 'days');

        this.setSelectedDay(newDay);
    }

    @action toNextWeek = () => {
        const newDay = moment(toJS(this.selectedDay)).add(7, 'days');

        this.setSelectedDay(newDay);
    }

    @action changeDeleteChannelId = (channelId) => {
        this.deleteChannelId = channelId;
        this.isOpenDeleteDialog = true;
    }

    @action closeDeleteChannelDialog = () => {
        this.isOpenDeleteDialog = false;
    }

    @action changeChannelResult = channel => {
        this.channelResultChannelId = channel.channelId;
        this.loadChannelResults(channel);
        this.isOpenChannelResultDialog = true;
    }

    @action closeChannelResultDialog = () => this.isOpenChannelResultDialog = false;

    @action changePagingPage = (page) => {
        this.paging.page = page;
        this.paging.totalCount = this.channelResultMemberList.length;
        const sortedList = _.chain(this.channelResultMemberList).sortBy(["userName"]).value();
        this.paging.list = sortedList.splice(this.paging.page * this.paging.rowsPerPage, this.paging.rowsPerPage);
    }

    @action changePagingRowsPerPage = (rowsPerPage) => {
        this.paging.rowsPerPage = rowsPerPage;
        this.paging.totalCount = this.channelResultMemberList.length;
        const sortedList = _.chain(this.channelResultMemberList).sortBy(["userName"]).value();
        this.paging.list = sortedList.splice(this.paging.page * this.paging.rowsPerPage, this.paging.rowsPerPage);
    }

    @computed
    get resultTargetChannel() {
        return this.channelList.find(c => c.channelId === this.channelResultChannelId);
    }

    loadingChannelList = flow(function* loadingChannelList() {
        try {
            const type = this.userType === 'ADMIN' ? 'admin' : this.userType === 'USER' ? 'created' : 'invited';
            const response = yield axios.get(`/api/v1/channels?user-id=${this.userId}&type=${type}&search-date=${moment(this.selectedDay).format('YYYY-MM-DD')}`);
            const channels = _.sortBy(response.data, ['liveStartDatetime']);

            const current = moment();
            let currentChecked = false;
            for(let i=0; i<channels.length; i++) {
                const channel = channels[i];
                if(moment(channel.liveStartDatetime).isBefore(current)) {
                    channel.timeType = 'past';
                } else {
                    if(moment(channel.liveStartDatetime).isSame(current, 'day')) {
                        if(currentChecked) {
                            channel.timeType = 'future';
                        } else {
                            channel.timeType = 'current';
                            currentChecked = true;
                        }
                    } else {
                        channel.timeType = 'future';
                    }
                }

            }

            // console.log(channels);
            this.channelList = channels;
        } catch(error) {

        }
    })

    deleteChannel = flow(function* deleteChannel() {
        try {
            yield axios.delete(`/api/v1/channels?channel-id=${this.deleteChannelId}`);

            this.deleteChannelId = '';
            this.isOpenDeleteDialog = false;

            this.loadingChannelList();
        } catch (error) {
            console.log("Can't delete channel");
        }
    })

    loadChannelResults = flow(function* loadChannelResults(channel) {
        try {
            const version = channel.version ? channel.version === 1 ? "v1_0" : "v2_0" : "v2_0";
            const response = yield axios.get(`/api/v1/channels/results?channel-id=${channel.channelId}&version=${version}`);
            if(response.status === 200) {
                this.channelResultMemberList = _.uniqBy(response.data, e => e.userId);
                this.paging.totalCount = this.channelResultMemberList.length;
                const sortedList = _.chain(this.channelResultMemberList).sortBy(["userName"]).value();
                this.paging.list = sortedList.splice(this.paging.page * this.paging.rowsPerPage, this.paging.rowsPerPage);
            }
        } catch (error) {

        }
    })
}
import _ from "lodash";
import {RoomChat} from "./RoomChat";
import {RoomVideo} from "./RoomVideo";
import {RoomLayoutManager} from "./RoomLayoutManager";
import {toJS} from "mobx";
import axios from "axios";


export const ChatState = {
    None: 'None',
    Connecting: 'Connecting',
    Connected: 'Connected',
    AlreadyJoined: 'AlreadyJoined',
    Error: 'Error',
};

export const AudioState = {
    None: 'None',
    Publishing: 'Publishing',
    Published: 'Published',
    PublishError: 'PublishError',
};

export const RoomMode = {
    Normal: 'Normal',
    LookAround: 'LookAround',
};

export const VideoState = {
    None: 'None',
    Connecting: 'Connecting',
    Connected: 'Connected',
    ConnectError: 'ConnectError',
    Publishing: 'Publishing',
    Published: 'Published',
    PublishError: 'PublishError',
    Unpublishing: 'Unpublishing',
    Subscribing: 'Subscribing',
    StreamAttached: 'StreamAttached',
    StreamEmpty: 'StreamEmpty',
    Subscribed: 'Subscribed',
    SubscribeError: 'SubscribeError',
    Unsubscribing: 'Unsubscribing',
};

export const VideoMode = {
    None: 'None',
    Primary: 'Primary',
    Secondary: 'Secondary',
    LookAround: 'LookAround',
};

export const VideoPosition = {
    TopLeft: 'TopLeft',
    TopRight: 'TopRight',
    BottomLeft: 'BottomLeft',
    BottomRight: 'BottomRight',
};

export const MemberPublishState = {
    None: 'None',
    Request: 'Request',
    Confirmed: 'Confirmed',
    Published: 'Published',
};

export const MessageType = {
    Message: 'Message',
    Link: 'Link',
    Announce: 'Announce',
    RequestAnnounce: 'RequestAnnounce',
    NotifyMedia: 'NotifyMedia',
    RequestPublish: 'RequestPublish',
    ConfirmPublish: 'ConfirmPublish',
    PublishComplete: 'PublishComplete',
    PublishStopped: 'PublishStopped',
    Attendance: 'Attendance',
    AttendanceLate: 'AttendanceLate',
    Quiz: 'Quiz',
    StartPoll: 'StartPoll',
    PollResponse: 'PollResponse',
    EndPoll: 'EndPoll',
};

export const StreamType = {
    LocalCamera: 'LocalCamera',
    LocalScreen: 'LocalScreen',
    Remote: 'Remote',
};

export const ChatTabIndex = {
    Chat: 0,
    Member: 1,
    Poll: 2,
};

const LogPrefix = '[RoomPresenter] ';
const CameraWidth = 800;
const CameraHeight = 450;
const ScreenWidth = 1280;
const ScreenHeight = 720;
const SmallWidth = 200;
const SmallHeight = 135;
const LookAroundWidth = 256;
const LookAroundHeight = 144;

export class RoomPresenter {
    constructor(config, callbacks, roomStore) {
        this.config = Object.assign({}, {
            subVideoSizePercent: 15,
            mainZIndex: 10,
            landscape: true,

            primaryAudioRef: undefined,
            videoContainerRef: undefined,
            primaryVideoAreaRef: undefined,
            secondaryVideoAreaRef: undefined,
            primaryLayerRef: undefined,
            secondaryLayerRef: undefined,
            secondaryLocatorRef: undefined,
            additionalVideoRefs: [],
            chatAreaRef: undefined,
        }, config);
        this.callbacks = Object.assign({}, {
            onRequestPublish: undefined,
            onConfirmPublish: undefined,
            onReceivedAttendance: undefined,
            onReceivedQuiz: undefined,
            onReceivedStartPoll: undefined,
            onReceivedPollResponse: undefined,
            onReceivedEndPoll: undefined,
        }, callbacks);
        this.roomStore = roomStore;
        this.chatJanus = undefined;
        this.janus = undefined;
        this.chat = undefined;
        this.primaryVideo = undefined;
        this.secondaryVideo = undefined;

        this.lookAroundPublishVideo = undefined;
        this.lookAroundPrimaryVideo = undefined;
        this.layoutManager = new RoomLayoutManager(config);

        this.connect();
    }

    static getDevices = (callback) => {
        console.log(LogPrefix, 'Enumerating devices...');

        if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
            navigator.mediaDevices.enumerateDevices()
                .then(devices => {
                    console.log(LogPrefix, 'Enumerate devices success', devices);

                    callback(devices);
                })
                .catch(error => {
                    console.log(LogPrefix, 'Enumerate devices error', error);

                    callback([]);
                });
        }
    }

    _processAnnounce = (announce) => {
        const {role} = this.roomStore;

        if(role === 'publisher') {

        } else {
            const {mode, mainVideo, subVideoHidden, audioState, primaryState, primaryFeed, secondaryState, secondaryFeed} = this.roomStore;

            if(mainVideo !== announce.mainVideo) {
                this.setMainVideo(announce.mainVideo);
            }

            if(subVideoHidden !== announce.subVideoHidden) {
                this.setSubVideoHidden(announce.subVideoHidden);
            }

            if(announce.audioState === VideoState.Published) {
                if(audioState !== VideoState.Subscribed) {
                    this.subscribeAudio(announce.audioFeed);
                }
            } else if(announce.audioState === VideoState.None) {
                if(audioState === VideoState.Subscribed) {
                    this.unsubscribeAudio();
                }
            }

            if((announce.primaryState === VideoState.Published) || (announce.primaryState === VideoState.StreamAttached) || (announce.primaryState === VideoState.Subscribed)) {
                if (announce.primaryFeed !== primaryFeed) {
                    if(primaryState === VideoState.Published) {
                        const that = this;
                        this.unpublish(VideoMode.Primary, {
                            onSuccess: () => {
                                setTimeout(() => that.subscribe(VideoMode.Primary, announce.primaryFeed), 1000);
                            }
                        });
                    } else if (primaryState === VideoState.Subscribed || primaryState === VideoState.StreamAttached || primaryState === VideoState.StreamEmpty) {
                        this.changeFeed(VideoMode.Primary, announce.primaryFeed);
                        this._setVideoFeed(VideoMode.Primary, announce.primaryFeed);
                    } else {
                        this.subscribe(VideoMode.Primary, announce.primaryFeed);
                    }
                }  else {
                    if(primaryState === VideoState.Publishing || primaryState === VideoState.Published) {
                        console.log(LogPrefix, 'Already published', primaryState, primaryFeed);
                    } else if(primaryState === VideoState.Subscribing || primaryState === VideoState.Subscribed) {
                        console.log(LogPrefix, 'Already subscribed', primaryState, primaryFeed);
                    } else {
                        this.subscribe(VideoMode.Primary, announce.primaryFeed);
                    }
                }
            } else if(announce.primaryState === VideoState.None) {
                if(primaryState === VideoState.Published) {
                    this.unpublish(VideoMode.Primary);
                } else if(primaryState === VideoState.Subscribed || primaryState === VideoState.StreamAttached || primaryState === VideoState.StreamEmpty) {
                    this.unsubscribe(VideoMode.Primary);
                }
            }

            if((announce.secondaryState === VideoState.Published) || (announce.secondaryState === VideoState.StreamAttached) || (announce.secondaryState === VideoState.Subscribed)) {
                if(announce.secondaryFeed !== secondaryFeed) {
                    if(secondaryState === VideoState.Published) {
                        const that = this;
                        this.unpublish(VideoMode.Secondary, {
                            onSuccess: () => {
                                setTimeout(() => that.subscribe(VideoMode.Secondary, announce.secondaryFeed), 1000);
                            }
                        });
                    } else if(secondaryState === VideoState.Subscribed || secondaryState === VideoState.StreamAttached || secondaryState === VideoState.StreamEmpty) {
                        this.changeFeed(VideoMode.Secondary, announce.secondaryFeed);
                        this._setVideoFeed(VideoMode.Secondary, announce.secondaryFeed);
                    } else {
                        this.subscribe(VideoMode.Secondary, announce.secondaryFeed);
                    }
                } else {
                    if(secondaryState === VideoState.Publishing || secondaryState === VideoState.Published) {
                        console.log(LogPrefix, 'Already published', secondaryState, secondaryFeed);
                    } else if(secondaryState === VideoState.Subscribing || secondaryState === VideoState.Subscribed) {
                        console.log(LogPrefix, 'Already subscribed', secondaryState, secondaryFeed);
                    } else {
                        this.subscribe(VideoMode.Secondary, announce.secondaryFeed);
                    }
                }
            } else if(announce.secondaryState === VideoState.None) {
                const {secondaryState} = this.roomStore;

                if(secondaryState === VideoState.Published) {
                    this.unpublish(VideoMode.Secondary);
                } else if(secondaryState === VideoState.Subscribed || secondaryState === VideoState.StreamAttached || secondaryState === VideoState.StreamEmpty) {
                    this.unsubscribe(VideoMode.Secondary);
                }
            }
        }
    }

    _chatHandler = () => {
        const that = this;
        return ({
            onMessage: (param) => {
                console.log(LogPrefix, 'Got a message', param);
                const {from, date, type, msg} = param;

                if(type === MessageType.Message || type === MessageType.Link) {              // It's a chat message
                    const {chatMembers} = that.roomStore;
                    const member = _.find(chatMembers, (m) => m.id === from);
                    if (member) {
                        const displayName = member.user.userName;
                        const chatMessage = {
                            type: type,
                            from: from,
                            fromName: displayName,
                            date: date,
                            msg: msg,
                        };

                        that.roomStore.addChatMessage(chatMessage);
                    }
                } else {                                        // It's a control message
                    const {role, chatMembers} = that.roomStore;

                    if(role === 'publisher') {                  // It's a control message for publisher
                        if(type === MessageType.NotifyMedia) {
                            const {id, mediaOn, deviceType, browserType} = msg;

                            that.roomStore.setChatMemberMediaOn(id, mediaOn, deviceType, browserType);
                        } else if (type === MessageType.RequestPublish) {
                            const {primaryState, primaryFeed} = that.roomStore;
                            const {data} = msg;

                            if((primaryState !== VideoState.Subscribed) || (primaryFeed !== from)) {
                                that.roomStore.setMemberPublishState(from, MemberPublishState.Request);

                                if (that.callbacks.onRequestPublish) {
                                    const member = _.find(chatMembers, (m) => m.id === from);
                                    that.callbacks.onRequestPublish(member, data);
                                }
                            }
                        } else if(type === MessageType.PublishComplete) {
                            that.roomStore.setMemberPublishState(from, MemberPublishState.Published);

                            const {primaryState} = that.roomStore;
                            if (primaryState === VideoState.Published) {
                                that.unpublish(VideoMode.Primary, {
                                    onSuccess: () => {
                                        setTimeout(() => that.subscribe(VideoMode.Primary, from), 1000);
                                    }
                                });
                            } else if (primaryState === VideoState.StreamAttached || primaryState === VideoState.Subscribed) {
                                that.unsubscribe(VideoMode.Primary, {
                                    onSuccess: () => {
                                        setTimeout(() => that.subscribe(VideoMode.Primary, from), 1000);
                                    }
                                });
                            } else {
                                that.subscribe(VideoMode.Primary, from);
                            }
                        } else if(type === MessageType.PublishStopped) {
                            that.roomStore.setMemberPublishState(from, MemberPublishState.None);

                            const {primaryState} = that.roomStore;
                            if (primaryState === VideoState.Published) {
                                that.unpublish(VideoMode.Primary, {
                                    onSuccess: () => {
                                        setTimeout(() => that.unsubscribe(VideoMode.Primary), 1000);
                                    }
                                });
                            } else if (primaryState === VideoState.StreamAttached || primaryState === VideoState.Subscribed) {
                                that.unsubscribe(VideoMode.Primary, {
                                    onSuccess: () => {
                                        setTimeout(() => that.unsubscribe(VideoMode.Primary), 1000);
                                    }
                                });
                            } else {
                                that.unsubscribe(VideoMode.Primary);
                            }
                        } else if(type === MessageType.RequestAnnounce) {
                            setTimeout(() => that.sendAnnounce(from, {
                                onSuccess: () => {
                                    console.info(`Sending announce to ${from} success.`);
                                },
                                onError: () => {
                                    console.warn(`Sending announce to ${from} error. retry...`);
                                    that.sendAnnounce(from, {
                                        onSuccess: () => {
                                            console.info(`Sending 2nd announce to ${from} success.`);
                                        },
                                        onError: () => {
                                            console.warn(`Sending 2nd announce to ${from} error.`);
                                        }
                                    });
                                }
                            }), 1000);
                        } else if(type === MessageType.PollResponse) {
                            const {pollId} = msg;

                            if(that.callbacks.onReceivedPollResponse) {
                                that.callbacks.onReceivedPollResponse(pollId);
                            }
                        }
                    } else {                                    // It's control message for subscriber.
                        if(type === MessageType.ConfirmPublish) {
                            const {primaryState} = that.roomStore;
                            if(primaryState !== VideoState.Published) {
                                this.roomStore.setMemberPublishDialogOpen(true);
                            } else {
                                console.log(LogPrefix, 'Already published');
                            }
                        } else if(type === MessageType.Announce) {
                            const {announce} = msg;

                            that._processAnnounce(announce);
                        } else if(type === MessageType.Attendance) {
                            const {data} = msg;

                            this.callbacks.onReceivedAttendance(data);
                        } else if(type === MessageType.AttendanceLate) {
                            const {data} = msg;

                            this.callbacks.onReceivedAttendance(data);
                        } else if(type === MessageType.Quiz) {
                            const {data} = msg;

                            this.callbacks.onReceivedQuiz(data);
                        } else if(type === MessageType.StartPoll) {
                            const {pollId} = msg;

                            this.callbacks.onReceivedStartPoll(pollId);
                        } else if(type === MessageType.PollResponse) {
                            const {pollId} = msg;

                            if(that.callbacks.onReceivedPollResponse) {
                                that.callbacks.onReceivedPollResponse(pollId);
                            }
                        } else if(type === MessageType.EndPoll) {
                            const {pollId} = msg;

                            if(that.callbacks.onReceivedEndPoll) {
                                that.callbacks.onReceivedEndPoll(pollId);
                            }
                        }
                    }
                }
            },
            onControlMessage: (msg) => {
                console.log(LogPrefix, 'Got a control message', msg);
            },
            onAnnounceMessage: (announce) => {
                console.log(LogPrefix, 'Got a announce', announce);

                const {role} = that.roomStore;

                if(role !== 'publisher') {
                    that._processAnnounce(announce);
                }
            },
            onJoin: (joinUserId, displayName, when) => {
                console.log(LogPrefix, `User joined : joinUserId=${joinUserId}, displayName=${displayName}, when=${when}`);

                that.roomStore.chatMemberJoined(joinUserId);

                const {roomUserId, role, userId} = that.roomStore;

                if(userId === joinUserId) {                 // 내가 조인 했을 경우,
                    if(role === 'publisher') {
                        that._startXVideo();
                    }
                } else {                                    // 다른 이가 조인했을 경우,
                    if (role === 'publisher') {
                        setTimeout(() => that.sendAnnounce(joinUserId, {
                            onSuccess: () => {
                                console.info(`Sending announce to ${joinUserId} success.`);
                            },
                            onError: () => {
                                console.warn(`Sending announce to ${joinUserId} error. retry...`);
                                that.sendAnnounce(joinUserId, {
                                    onSuccess: () => {
                                        console.info(`Sending 2nd announce to ${joinUserId} success.`);
                                    },
                                    onError: () => {
                                        console.warn(`Sending 2nd announce to ${joinUserId} error.`);
                                    }
                                });
                            }
                        }), 1000);
                    } else {
                        if (joinUserId === roomUserId) {     // 교수님이 뒤늦게 입장하면...
                            that._sendNotifyMedia();
                        }
                    }
                }
            },
            onLeave: (leaveUserId) => {
                console.log(LogPrefix, `User leaved : userId=${leaveUserId}`);

                that.roomStore.chatMemberLeaved(leaveUserId);

                const {role, userId} = that.roomStore;
                if(userId === leaveUserId) {                // 내가 나갈 경우.
                    console.info('===== Leaved =====');

                    if(role === 'publisher') {
                        that._endXVideo();
                    }
                }

                if(role === 'publisher') {
                    const {primaryState, primaryFeed} = that.roomStore;
                    if((primaryState === VideoState.Subscribed || primaryState === VideoState.StreamEmpty) && (primaryFeed === leaveUserId)) {
                        console.log(LogPrefix, 'Publishing user leaved');

                        that.unsubscribe(VideoMode.Primary, {
                            onSuccess: () => {
                                setTimeout(() => that.sendAnnounce(), 1000);
                            }
                        });
                    }
                } else {
                    const {roomUserId, audioState, primaryState, secondaryState} = that.roomStore;

                    if(roomUserId === leaveUserId) {
                        if(audioState === VideoState.Subscribed || audioState === VideoState.Subscribing || audioState === VideoState.StreamEmpty) {
                            that.unsubscribeAudio();
                        }

                        if(primaryState === VideoState.Published || primaryState === VideoState.Publishing) {
                            that.unpublish(VideoMode.Primary);
                        }

                        if(primaryState === VideoState.Subscribed || primaryState === VideoState.Subscribing || primaryState === VideoState.StreamEmpty) {
                            that.unsubscribe(VideoMode.Primary);
                        }

                        if(secondaryState === VideoState.Subscribed || secondaryState === VideoState.Subscribing || secondaryState === VideoState.StreamEmpty) {
                            that.unsubscribe(VideoMode.Secondary);
                        }
                    }
                }
            },
            onConnecting: () => {
                that.chatState = ChatState.Connecting;
                that.roomStore.setChatState(ChatState.Connecting);
            },
            onConnectSuccess: () => {
                that.chatState = ChatState.Connected;
                that.roomStore.setChatState(ChatState.Connected);
            },
            onAlreadyJoined: () => {
                that.roomStore.setChatState(ChatState.AlreadyJoined);
            },
            onConnectError: () => {
                that.chatState = ChatState.Error;
                that.roomStore.setChatState(ChatState.Error);
            },
        });
    }

    _audioHandler = () => {
        console.log(LogPrefix, ' AudioHandler');

        const that = this;
        return ({
            onPublishing: () => {
                console.log(LogPrefix, 'onPublishing');

                that.roomStore.setAudioState(VideoState.Publishing);
            },
            onPublished: (feed) => {
                console.log(LogPrefix, 'onPublished', feed);

                that.roomStore.setAudioFeed(feed);
                that.roomStore.setAudioState(VideoState.Published);

                const {role} = that.roomStore;
                if(role === 'publisher') {
                    that.sendAnnounce();
                } else {
                    that.sendPublishCompleteControlMessage();
                }
            },
            onLocalStreamAttached: (stream, recording) => {
                console.log(LogPrefix, 'onLocalStreamAttached', stream, recording);
            },
            onPublishError: (error) => {
                console.log(LogPrefix, 'onPublishError', error);
            },
            onUnpublished: () => {
                console.log(LogPrefix, 'onUnpublished');

                that.roomStore.setAudioFeed('');
                that.roomStore.setAudioState(VideoState.None);

                const {role} = that.roomStore;
                if(role === 'publisher') {
                    that.sendAnnounce();
                }
            },
            onSubscribing: () => {
                console.log(LogPrefix, 'onSubscribing');

                that.roomStore.setAudioState(VideoState.Subscribing);
            },
            onRemoteStreamAttached: (feed) => {
                console.log(LogPrefix, 'onRemoteRemoteAttached', feed);

                that.roomStore.setAudioState(VideoState.StreamAttached);
            },
            onRemoteAudioEmpty: (feed) => {
                console.log(LogPrefix, 'onRemoteAudioEmpty', feed);

                const {audioState} = that.roomStore;
                if(audioState === VideoState.Subscribed || audioState === VideoState.StreamEmpty) {
                    that.unsubscribeAudio({
                        onSuccess: () => {
                            console.warn(LogPrefix, `Unsubscribe audio success. Retry subscribe...`);
                            that.roomStore.setAudioState(VideoState.Subscribing);
                            setTimeout(() => that.subscribeAudio(feed), 1000);
                        },
                        onError: () => {
                            console.warn(LogPrefix, `Unsubscribe audio error`);

                            that.roomStore.setAudioState(VideoState.StreamEmpty);
                            that.roomStore.setAudioFeed(feed);
                        }
                    });
                } else {
                    console.warn(LogPrefix, `StreamEmpty audio event. but no operating... already operating... maybe...`);
                }
            },
            onStarted: () => {
                console.log(LogPrefix, 'onStarted');
            },
            onSubscribed: (feed) => {
                console.log(LogPrefix, 'onSubscribed', feed);

                that.roomStore.setAudioState(VideoState.Subscribed);
            },
            onSubscribeError: (error) => {
                console.log(LogPrefix, 'onSubscribeError', error);

                that.roomStore.setAudioState(VideoState.SubscribeError);
            },
            onUnsubscribed: () => {
                console.log(LogPrefix, 'onUnscribed');

                that.roomStore.setAudioState(VideoState.None);
            },
            onSubstreamChanged: (substream) => {
                console.log(LogPrefix, 'onSubstreamChanged', substream);
            }
        });
    }

    _videoHandler = (videoMode) => {
        console.log(LogPrefix, 'VideoHandler : ' + videoMode);

        const that = this;
        return ({
            onPublishing: () => {
                console.log(LogPrefix, 'onPublishing', videoMode);
                that._setVideoState(videoMode, VideoState.Publishing)
            },
            onPublished: (feed) => {
                console.log(LogPrefix, 'onPublished', videoMode, feed);
                that._setVideoState(videoMode, VideoState.Published);
                that._setVideoFeed(videoMode, feed);

                const {role} = that.roomStore;
                if(role === 'publisher') {
                    that.sendAnnounce();
                } else {
                    that.sendPublishCompleteControlMessage();
                }
            },
            onLocalStreamAttached: (stream, recording) => {
                console.log(LogPrefix, 'onLocalStreamAttached', videoMode, stream, recording);
                //that._setVideoStream(videoMode, stream);

                if(recording) {
                    that.record(videoMode, stream);
                }
            },
            onPublishError: (error) => {
                console.log(LogPrefix, 'onPublishError', videoMode, error);
                that._setVideoState(videoMode, VideoState.PublishError)
            },
            onUnpublishing: () => {
                console.log(LogPrefix, 'onUnpublishing', videoMode);

                that._setVideoState(videoMode, VideoState.Unpublishing);
            },
            onUnpublished: () => {
                console.log(LogPrefix, 'onUnpublished', videoMode);
                that._setVideoState(videoMode, VideoState.None);
                that._setVideoFeed(videoMode, '');
                that._setVideoStream(videoMode, undefined);
                that._setVideoSubstream(videoMode, -1);
                that._setVideoSubstreamLock(videoMode, -1);

                const {role} = that.roomStore;
                if(role === 'publisher') {
                    that.sendAnnounce();
                }
            },
            onSubscribing: (feed) => {
                console.log(LogPrefix, 'onSubscribing', videoMode, feed);
                that._setVideoState(videoMode, VideoState.Subscribing);
                that._setVideoFeed(videoMode, feed);
                that._setVideoSubstream(videoMode, -1);
                that._setVideoSubstreamLock(videoMode, -1);
            },
            onRemoteStreamAttached: (feed) => {
                console.log(LogPrefix, 'onRemoteStreamAttached', videoMode, feed);
                const {role} = that.roomStore;

                that._setVideoState(videoMode, VideoState.StreamAttached);
                that._setVideoFeed(videoMode, feed);

                if((role === 'publisher') && (videoMode === VideoMode.Primary)) {
                    that.sendAnnounce();
                }
            },
            onRemoteStreamEmpty: (feed) => {
                console.warn(LogPrefix, 'onRemoteStreamEmpty', videoMode, feed);

                const videoState = videoMode === VideoMode.Primary ? that.roomStore.primaryState : that.roomStore.secondaryState;
                if (videoState === VideoState.Subscribed || videoState === VideoState.StreamEmpty) {
                    that.unsubscribe(videoMode, {
                        onSuccess: () => {
                            console.warn(LogPrefix, `Unsubscribe ${videoMode} success. Retry subscribe...`);
                            that._setVideoState(videoMode, VideoState.Subscribing);
                            setTimeout(() => that.subscribe(videoMode, feed), 1000);
                        },
                        onError: () => {
                            console.warn(LogPrefix, `Unsubscribe ${videoMode} error`);

                            that._setVideoState(videoMode, VideoState.StreamEmpty);
                            that._setVideoFeed(videoMode, feed);
                            that._setVideoSubstream(videoMode, -1);
                        }
                    });
                } else {
                    console.warn(LogPrefix, `StreamEmpty ${videoMode} event. but no operating... already operating... maybe...`);
                }
            },
            onStarted: () => {
                console.log(LogPrefix, 'onStarted', videoMode);
            },
            onSubscribed: (feed) => {
                console.log(LogPrefix, 'onSubscribed', videoMode, feed);

                const {role} = that.roomStore;
                const videoState = videoMode === VideoMode.Primary ? that.roomStore.primaryState : that.roomStore.secondaryState;

                if(videoState !== VideoState.Subscribed) {
                    that._setVideoState(videoMode, VideoState.Subscribed);
                    that._setVideoFeed(videoMode, feed);

                    if ((role === 'publisher') && (videoMode === VideoMode.Primary)) {
                        that.sendAnnounce();
                    }
                }
            },
            onSubscribeError: (error) => {
                console.log(LogPrefix, 'onSubscribeError', videoMode, error);
                that._setVideoState(videoMode, VideoState.SubscribeError);
            },
            onUnsubscribing: () => {
                console.log(LogPrefix, 'onUnsubscribing', videoMode);

                that._setVideoState(videoMode, VideoState.Unsubscribing);
            },
            onUnsubscribed: () => {
                console.log(LogPrefix, 'onUnsubscribed', videoMode);
                const {role, primaryFeed} = that.roomStore;

                that._setVideoState(videoMode, VideoState.None);
                that._setVideoFeed(videoMode, '');
                that._setVideoStream(videoMode, undefined);
                that._setVideoSubstream(videoMode, -1);
                that._setVideoSubstreamLock(videoMode, -1);

                if ((role === 'publisher') && (videoMode === VideoMode.Primary)) {
                    that.sendAnnounce();
                }
            },
            onSubstreamChanged: (substream) => {
                console.log(LogPrefix, 'onSubstreamChanged', videoMode, substream);

                const {primaryHighQuality, primarySubstreamLock, primarySubstream} = that.roomStore;
                if(primaryHighQuality && (primarySubstream < 0)) {
                    setTimeout(() => this.setSubstream(videoMode, 2), 1000);
                }

                if((primaryHighQuality) && (videoMode === VideoMode.Primary)) {
                    if(substream > primarySubstream) {
                        that.roomStore.setPrimarySubstream(substream);
                        that.roomStore.setPrimarySubstreamLock(substream);
                    } else if(substream < primarySubstream) {
                        that.roomStore.setPrimarySubstream(substream);
                        setTimeout(() => that.setSubstream(VideoMode.Primary, primarySubstreamLock), 1000);
                    } else {
                        //Do nothing.
                        //that.roomStore.setPrimarySubstream(substream);
                    }
                }
            }
        });
    }

    _lookAroundHandler = (videoMode) => {
        console.log(LogPrefix, 'VideoHandler : ' + videoMode);

        const that = this;
        return ({
            onPublishing: () => {
                console.log(LogPrefix, 'onPublishing', videoMode);

                that.roomStore.setLookAroundPublishState(VideoState.Publishing);
            },
            onPublished: (feed) => {
                console.log(LogPrefix, 'onPublished', videoMode, feed);

                that.roomStore.setLookAroundPublishState(VideoState.Published);
            },
            onLocalStreamAttached: (stream, recording) => {
                console.log(LogPrefix, 'onLocalStreamAttached', videoMode, stream, recording);
            },
            onPublishError: (error) => {
                console.log(LogPrefix, 'onPublishError', videoMode, error);

                that.roomStore.setLookAroundPublishState(VideoState.PublishError);
            },
            onUnpublishing: () => {
                console.log(LogPrefix, 'onUnpublishing', videoMode);
            },
            onUnpublished: () => {
                console.log(LogPrefix, 'onUnpublished', videoMode);

                that.roomStore.setLookAroundPublishState(VideoState.None);
            },
            onSubscribing: (feed) => {
                console.log(LogPrefix, 'onSubscribing', videoMode, feed);
            },
            onRemoteStreamAttached: (feed) => {
                console.log(LogPrefix, 'onRemoteStreamAttached', videoMode, feed);
            },
            onRemoteStreamEmpty: (feed) => {
                console.warn(LogPrefix, 'onRemoteStreamEmpty', videoMode, feed);
            },
            onStarted: () => {
                console.log(LogPrefix, 'onStarted', videoMode);
            },
            onSubscribed: (feed) => {
                console.log(LogPrefix, 'onSubscribed', videoMode, feed);
            },
            onSubscribeError: (error) => {
                console.log(LogPrefix, 'onSubscribeError', videoMode, error);
            },
            onUnsubscribing: () => {
                console.log(LogPrefix, 'onUnsubscribing', videoMode);
            },
            onUnsubscribed: () => {
                console.log(LogPrefix, 'onUnsubscribed', videoMode);
            },
            onSubstreamChanged: (substream) => {
                console.log(LogPrefix, 'onSubstreamChanged', videoMode, substream);
            }
        });
    }

    _startXVideo = () => {
        const {roomId} = this.roomStore;

        axios.post(`/api/v1/xvideos/${roomId}`)
            .then(response => {
                console.log("XVideo start success");
            })
            .catch(error => {
                console.log("XVideo start error", error);
            })
    }

    _endXVideo = () => {
        const {roomId} = this.roomStore;

        axios.delete(`/api/v1/xvideos/${roomId}`)
            .then(response => {
                console.log("XVideo end success");
            })
            .catch(error => {
                console.log("XVideo end error", error);
            })
    }

    connect = () => {
        const {server, iceServers} = this.roomStore;
        const chatServer = 'wss://bs001.onthe.live:8989/janus';

        console.log(LogPrefix, 'Janus creating...');

        const that = this;
        console.log(LogPrefix, 'Connecting...', chatServer);
        const chatJanus = new window.Janus({
            server: chatServer,
            success: function() {
                console.log(LogPrefix, 'ChatJanus connected successfully');

                const {roomId, role, userId, displayName} = that.roomStore;

                that.chat = new RoomChat(chatJanus, roomId, role, userId, displayName, that._chatHandler());
                that.chat.attachAndJoin();
            },
            error: function(error) {
                console.log(LogPrefix, 'ChatJanus connect failed', error);

                that.roomStore.setReconnectDialogOpen(true);
            },
            destroy: function() {
                console.log(LogPrefix, 'ChatJanus destroyed');
            },
        });
        this.chatJanus = chatJanus;

        console.log(LogPrefix, 'Connecting...', server, iceServers);
        const janus = new window.Janus({
            server: server,
            iceServers: iceServers,
            success: function() {
                console.log(LogPrefix, 'VideoJanus connected successfully');

                const {roomId, role, userId, displayName} = that.roomStore;

                that.audio = new RoomVideo(VideoMode.None, janus, roomId, role, userId, displayName, that.config.primaryAudioRef, that._audioHandler());
                that.primaryVideo = new RoomVideo(VideoMode.Primary, janus, roomId, role, userId, displayName, that.config.primaryVideoAreaRef, that._videoHandler(VideoMode.Primary));
                that.secondaryVideo = new RoomVideo(VideoMode.Secondary, janus, roomId, role, userId, displayName, that.config.secondaryVideoAreaRef, that._videoHandler(VideoMode.Secondary));
                that.lookAroundPublishVideo = new RoomVideo(VideoMode.LookAround, janus, roomId, role, userId, displayName, undefined, that._lookAroundHandler(VideoMode.LookAround));
            },
            error: function(error) {
                console.log(LogPrefix, 'VideoJanus connect failed', error);

                that.roomStore.setReconnectDialogOpen(true);
            },
            destroyed: function() {
                console.log(LogPrefix, 'VideoJanus destroyed');
            }
        });

        this.janus = janus;
    }

    leave = () => {
        this.chat.leave();
    }

    kickAndJoin = () => {
        this.chat.kickAndJoin();
    }

    sendChatMessage = (msg) => {
        this.chat.sendMessage(MessageType.Message, msg);
    }

    sendChatLink = (link) => {
        this.chat.sendMessage(MessageType.Link, link);
    }

    sendAnnounce = (to, callbacks) => {
        const announce = this._getCurrentState();

        console.log(LogPrefix, 'SendAnnounce', announce, to);
        if(to) {
            this.chat.sendMessage(MessageType.Announce, {announce: announce}, to, callbacks);
        } else {
            this.chat.sendAnnounce(announce, callbacks);
        }
    }

    sendRequestAnnounce = () => {
        const {roomUserId} = this.roomStore;

        this.chat.sendMessage(MessageType.RequestAnnounce, {}, roomUserId);
    }

    _sendNotifyMedia = () => {
        const {chatState, roomUserId, userId, videoStreams} = this.roomStore;

        if(this.chat && chatState === ChatState.Connected) {
            this.chat.sendMessage(MessageType.NotifyMedia, {
                id: userId,
                mediaOn: videoStreams && videoStreams.length > 0 ? true : false,
                deviceType: this.config.deviceType,
                browserType: this.config.browserType,
            }, roomUserId);
        }
    }

    sendRequestPublishControlMessage = (data) => {
        const {roomUserId} = this.roomStore;

        this.chat.sendMessage(MessageType.RequestPublish, {data: data}, roomUserId);
    }

    sendConfirmPublishControlMessage = (userId) => {
        this.roomStore.setMemberPublishState(userId, MemberPublishState.Confirmed);

        const that = this;
        const {primaryState} = this.roomStore;
        if(primaryState === VideoState.Published) {
            this.unpublish(VideoMode.Primary, {
                onSuccess: () => {
                    setTimeout(() => that.chat.sendMessage(MessageType.ConfirmPublish, {}, userId), 1000);
                }
            });
        } else {
            this.chat.sendMessage(MessageType.ConfirmPublish, {}, userId);
        }
    }

    sendPublishCompleteControlMessage = () => {
        const {roomUserId} = this.roomStore;

        this.chat.sendMessage(MessageType.PublishComplete, {}, roomUserId);
    }

    sendPublishStoppedControlMessage = () => {
        const {roomUserId} = this.roomStore;

        this.chat.sendMessage(MessageType.PublishStopped, {}, roomUserId);
    }

    sendAttendanceControlMessage = (userId, data) => {
        this.roomStore.removeRequestPublish(userId);
        this.chat.sendMessage(MessageType.Attendance, {data: data});
    }

    sendAttendanceLateControlMessage = (userId, toUserId) => {
        this.roomStore.removeRequestPublish(userId);
        this.chat.sendMessage(MessageType.AttendanceLate, {}, toUserId);
    }

    sendQuizControlMessage = (userId, data) => {
        this.roomStore.removeRequestPublish(userId);
        this.chat.sendMessage(MessageType.Quiz, {data: data});
    }

    sendStartPollControlMessage = (pollId) => {
        this.chat.sendMessage(MessageType.StartPoll, {pollId: pollId});
        this.roomStore.setSelectedChatTab(ChatTabIndex.Poll);
    }

    sendPollResponseMessage = (pollId) => {
        this.chat.sendMessage(MessageType.PollResponse, {pollId: pollId});
        this.roomStore.setSelectedChatTab(ChatTabIndex.Poll);
    }

    sendEndPollControlMessage = (pollId) => {
        this.chat.sendMessage(MessageType.EndPoll, {pollId: pollId});
    }

    openPublishDialog = (videoMode) => {
        // const that = this;
        window.Janus.listDevices((devices) => {
            console.log(LogPrefix, 'Device detected', devices);

            const videoDevices = _.filter(devices, (d) => d.kind === 'videoinput');

            this.roomStore.setPublishOptionDialogOpen(true, videoMode, videoDevices);
        });
    }

    closePublishDialog = () => {
        this.roomStore.setPublishOptionDialogOpen(false);
    }

    _logMediaStreamTrack = (mediaStream) => {
        mediaStream.getTracks().forEach((track) => {
            console.log(LogPrefix, 'Track', track);
            console.log(LogPrefix, "Track's constraints", track.getConstraints());
            console.log(LogPrefix, "Track's settings", track.getSettings());
        });
    }

    addLocalVideoStream = (deviceId) => {
        console.log(LogPrefix, 'AddLocalVideoStream', deviceId);
        if(navigator.mediaDevices && navigator.mediaDevices.getSupportedConstraints) {
            const supportedConsts = navigator.mediaDevices.getSupportedConstraints();
            console.log(LogPrefix, 'GetUserMedia supported constraints', supportedConsts);
        }

        const that = this;
        if(!deviceId) {
            navigator.mediaDevices.getUserMedia({audio: false, video: {width: CameraWidth, height: CameraHeight}})
                .then(mediaStream => {
                    console.log(LogPrefix, 'Get UserMedia success', mediaStream);
                    that._logMediaStreamTrack(mediaStream);

                    that.roomStore.addVideoStream({type: StreamType.LocalCamera, streamId: mediaStream.id, deviceId: undefined, stream: mediaStream});
                })
                .catch((error) => {
                    console.log(LogPrefix, 'Get UserMedia error', error);

                    navigator.mediaDevices.getUserMedia({audio: false, video: true})
                        .then(mediaStream => {
                            console.log(LogPrefix, "Get UserMedia success without constraint", mediaStream);

                            that._logMediaStreamTrack(mediaStream);

                            that.roomStore.addVideoStream({type: StreamType.LocalCamera, streamId: mediaStream.id, deviceId: undefined, stream: mediaStream});
                        })
                        .catch(error => {
                            console.warn("Get UserMedia error without constraint", error);
                        });
                });
        } else if(deviceId === 'lookaround') {
            const {roomId} = that.roomStore;

            axios.get(`/api/v1/xvideos/${roomId}/x-param`)
                .then(response => {
                    const param = response.data;

                    that.roomStore.addVideoStream({type: StreamType.Remote, streamId: param.xpublishId, deviceId: deviceId, stream: undefined});
                })
                .catch(error => {
                    console.warn("Can't get a xvideo parameter", error);
                });

        } else if(deviceId === 'screen') {
            console.log(LogPrefix, 'Getting DisplayMedia...', deviceId);
            if(navigator.mediaDevices.getDisplayMedia) {
                navigator.mediaDevices.getDisplayMedia({audio: true, video: {width: ScreenWidth, height: ScreenHeight}})
                    .then(mediaStream => {
                        console.log(LogPrefix, 'Get DisplayMedia success', mediaStream);
                        that._logMediaStreamTrack(mediaStream);

                        that.roomStore.addVideoStream({type: StreamType.LocalScreen, streamId: mediaStream.id, deviceId: deviceId, stream: mediaStream});
                    })
                    .catch(error => {
                        console.warn(LogPrefix, 'Get DisplayMedia error', error);
                    });
            } else {
                console.warn(LogPrefix, 'GetDisplayMedia not supported');
            }
        } else {
            console.log(LogPrefix, 'Getting UserMedia...', deviceId);
            navigator.mediaDevices.getUserMedia({audio: false, video: {deviceId: deviceId, width: CameraWidth, height: CameraHeight}})
                .then(mediaStream => {
                    console.log(LogPrefix, 'Get UserMedia success', mediaStream);
                    that._logMediaStreamTrack(mediaStream);

                    that.roomStore.addVideoStream({type: StreamType.LocalCamera, streamId: mediaStream.id, deviceId: deviceId, stream: mediaStream});
                })
                .catch((error) => {
                    console.log(LogPrefix, 'Get UserMedia error', error);

                    navigator.mediaDevices.getUserMedia({audio: false, video: {deviceId: deviceId}})
                        .then(mediaStream => {
                            console.log(LogPrefix, 'Get UserMedia success without constraints', mediaStream);
                            that._logMediaStreamTrack(mediaStream);

                            that.roomStore.addVideoStream({type: StreamType.LocalCamera, streamId: mediaStream.id, deviceId: deviceId, stream: mediaStream});
                        })
                        .catch((error) => {
                            console.warn(LogPrefix, 'Get UserMedia error without constraints', error);
                        });
                });
        }
    }

    changeVideoStream = (streamId, deviceId) => {
        console.log(LogPrefix, 'ChangeVideoStream', streamId, deviceId);
        if(navigator.mediaDevices && navigator.mediaDevices.getSupportedConstraints) {
            const supportedConsts = navigator.mediaDevices.getSupportedConstraints();
            console.log(LogPrefix, 'GetUserMedia supported constraints', supportedConsts);
        }

        const {videoStreams} = this.roomStore;
        const that = this;

        const changeStream = (mediaStream, deviceId) => {
            const oldStream = _.find(videoStreams, (s) => s.streamId === streamId);
            if(oldStream) {
                that._stopTrackInStream(oldStream.stream);
            }
            that.roomStore.changeVideoStream(streamId, {type: StreamType.LocalCamera, streamId: mediaStream.id, deviceId: deviceId ? deviceId : undefined, stream: mediaStream});
            that._sendNotifyMedia();
        }
        const addStream = (mediaStream) => {
            that._logMediaStreamTrack(mediaStream);

            that.roomStore.addVideoStream({type: StreamType.LocalCamera, streamId: mediaStream.id, deviceId: deviceId ? deviceId : undefined, stream: mediaStream});
            that._sendNotifyMedia();
        }

        const publishLookAround = (mediaStream) => {
            const {userId, lookAroundPublishState} = this.roomStore;

            if(lookAroundPublishState === VideoState.Published) {
                this.lookAroundPublishVideo.unpublish({
                    onSuccess: () => {
                        setTimeout(() => {
                            const clonedStream = mediaStream.clone();

                            that._setStreamResolution(clonedStream, VideoMode.LookAround, StreamType.Remote);
                            that.lookAroundPublishVideo.publish(userId, 128 * 1000, false, false, undefined, clonedStream);
                        }, 1000);
                    }
                });
            } else {
                setTimeout(() => {
                    const clonedStream = mediaStream.clone();

                    that._setStreamResolution(clonedStream, VideoMode.LookAround, StreamType.Remote);
                    that.lookAroundPublishVideo.publish(userId, 128 * 1000, false, false, undefined, clonedStream);
                }, 1000);
            }
        }

        if(!deviceId) {
            navigator.mediaDevices.getUserMedia({audio: true, video: {width: CameraWidth, height: CameraHeight}})
                .then(mediaStream => {
                    console.log(LogPrefix, 'Get UserMedia success', mediaStream);

                    if(streamId) {
                        changeStream(mediaStream);
                        publishLookAround(mediaStream);
                    } else {
                        addStream(mediaStream);
                        publishLookAround(mediaStream);
                    }
                })
                .catch((error) => {
                    console.log(LogPrefix, 'Get UserMedia error', error);

                    navigator.mediaDevices.getUserMedia({audio: true, video: true})
                        .then(mediaStream => {
                            console.log(LogPrefix, 'Get UserMedia success without constraints', mediaStream);

                            if(streamId) {
                                changeStream(mediaStream);
                                publishLookAround(mediaStream);
                            } else {
                                addStream(mediaStream);
                                publishLookAround(mediaStream);
                            }
                        })
                        .catch((error) => {
                            console.warn(LogPrefix, 'Get UserMedia error without constraints', error);
                        });
                });
        } else if(deviceId === 'screen') {
            console.log(LogPrefix, 'Getting DisplayMedia...', deviceId);
            if(navigator.mediaDevices.getDisplayMedia) {
                navigator.mediaDevices.getDisplayMedia({audio: true, video: {width: ScreenWidth, height: ScreenHeight}})
                    .then(mediaStream => {
                        console.log(LogPrefix, 'Get DisplayMedia success', mediaStream);

                        if(streamId) {
                            changeStream(mediaStream, deviceId);
                            publishLookAround(mediaStream);
                        } else {
                            addStream(mediaStream, deviceId);
                            publishLookAround(mediaStream);
                        }
                    })
                    .catch(error => {
                        console.log(LogPrefix, 'Get DisplayMedia error', error);
                    });
            } else {
                console.log(LogPrefix, 'GetDisplayMedia not supported');
            }
        } else {
            console.log(LogPrefix, 'Getting UserMedia...', deviceId);
            navigator.mediaDevices.getUserMedia({audio: true, video: {deviceId: deviceId, width: CameraWidth, height: CameraHeight}})
                .then(mediaStream => {
                    console.log(LogPrefix, 'Get UserMedia success', mediaStream);

                    if(streamId) {
                        changeStream(mediaStream, deviceId);
                        publishLookAround(mediaStream);
                    } else {
                        addStream(mediaStream, deviceId);
                        publishLookAround(mediaStream);
                    }
                })
                .catch((error) => {
                    console.log(LogPrefix, 'Get UserMedia error', error);

                    navigator.mediaDevices.getUserMedia({audio: true, video: {deviceId: deviceId}})
                        .then(mediaStream => {
                            console.log(LogPrefix, 'Get UserMedia success without constraints', mediaStream);

                            if(streamId) {
                                changeStream(mediaStream, deviceId);
                                publishLookAround(mediaStream);
                            } else {
                                addStream(mediaStream, deviceId);
                                publishLookAround(mediaStream);
                            }
                        })
                        .catch((error) => {
                            console.warn(LogPrefix, 'Get UserMedia error without constraints', error);
                        });
                });
        }
    }

    removeVideoStream = (streamId) => {
        const {role} = this.roomStore;

        this.roomStore.removeVideoStream(streamId);
        if(role === 'subscriber') {
            this._sendNotifyMedia();
        }
    }

    _stopTrackInStream = (stream) => {
        if(stream) {
            stream.getTracks().forEach((track) => track.stop());
        }
    }

    _setStreamResolution = (stream, videoMode, type) => {
        let constraints = {};

        if(videoMode === VideoMode.Primary) {
            if(type === StreamType.LocalScreen) {
                constraints = {width: ScreenWidth, height: ScreenHeight};
            } else {
                constraints = {width: CameraWidth, height: CameraHeight};
            }
        } else if(videoMode === VideoMode.Secondary) {
            constraints = {width: SmallWidth, height: SmallHeight};
        } else if(videoMode === VideoMode.LookAround) {
            constraints = {width: LookAroundWidth, height: LookAroundHeight};
        }

        const videoTracks = stream.getVideoTracks();
        for(let i in videoTracks) {
            const track = videoTracks[i];
            track.applyConstraints(constraints)
                .then(() => {
                    console.log(LogPrefix, `Video Constraints apply success : ${stream.id}`, videoMode, constraints);
                })
                .catch(() => {
                    console.log(LogPrefix, `Video Constraints apply error : ${stream.id}`, videoMode, constraints);
                });
        }
    }

    changeMode = (mode) => {
    }

    lookAround = () => {
        const {primaryState} = this.roomStore;

        const that = this;
        const subscribeLookAround = () => {
            const {roomId} = that.roomStore;

            axios.get(`/api/v1/xvideos/${roomId}/x-param`)
                .then(response => {
                    const xparam = response.data;
                    const feed = xparam.xpublishId;

                    that.roomStore.setMode(RoomMode.LookAround);
                    that.primaryVideo.subscribe(feed);
                })
                .catch(error => {
                    console.warn("Can't get a xvideo parameter", error);
                });
        }

        if(primaryState === VideoState.Published) {
            this.unpublish(VideoMode.Primary, {
                onSuccess: () => {
                    setTimeout(() => {
                        subscribeLookAround();
                    }, 1000);
                }
            })
        } else if (primaryState === VideoState.Subscribed) {
            this.unsubscribe(VideoMode.Primary, {
                onSuccess: () => {
                    setTimeout(() => {
                        subscribeLookAround();
                    }, 1000);
                }
            })
        } else {
            subscribeLookAround();
        }
    }

    publishAudio = (deviceId) => {
        console.log(LogPrefix, 'Publish Audio');

        const audio = {
            deviceId: deviceId,
            echoCancellation: true,
            noiseSuppression: true,
        };

        this.audio.publish('audio', 48 * 1000, false, audio, undefined, undefined);
    }

    publishStream = (videoMode, streamId) => {
        console.log(LogPrefix, 'Publish stream', videoMode);
        const {roomId, userId, videoStreams} = this.roomStore;
        const stream = streamId ? _.find(videoStreams, (stream) => stream.streamId === streamId) : undefined;

        const that = this;
        const publish = () => {
            if(stream.type === StreamType.LocalCamera || stream.type === StreamType.LocalScreen) {
                const id = videoMode === VideoMode.Primary ? userId : roomId;
                const bitrate = videoMode === VideoMode.Primary ? (768 * 1000) : (128 * 1000);
                const videoHandle = videoMode === VideoMode.Primary ? that.primaryVideo : that.secondaryVideo;

                const clonedStream = stream.stream.clone();
                that.roomStore.setMode(RoomMode.Normal);
                that._setStreamResolution(clonedStream, videoMode, stream.type);
                that._setVideoStream(videoMode, stream);
                videoHandle.publish(id, bitrate, stream.type === StreamType.LocalCamera, false, undefined, clonedStream);
            } else if(stream.type === StreamType.Remote) {
                const videoHandle = videoMode === VideoMode.Primary ? that.primaryVideo : that.secondaryVideo;

                that.roomStore.setMode(RoomMode.LookAround);
                videoHandle.subscribe(stream.streamId);
            }
        }

        if(videoMode === VideoMode.Primary) {
            const {primaryState} = this.roomStore;
            if(primaryState === VideoState.Published) {
                this.unpublish(videoMode, {
                    onSuccess: () => {
                        setTimeout(() => {
                            publish();
                        }, 1000);
                    }
                })
            } else if (primaryState === VideoState.Subscribed) {
                this.unsubscribe(videoMode, {
                    onSuccess: () => {
                        setTimeout(() => {
                            publish();
                        }, 1000);
                    }
                })
            } else {
                publish();
            }
        } else {
            const {secondaryState} = this.roomStore;
            if(secondaryState === VideoState.Published) {
                this.unpublish(videoMode, {
                    onSuccess: () => {
                        setTimeout(() => {
                            publish();
                        }, 1000);
                    }
                });
            } else if(secondaryState === VideoState.Subscribed) {
                this.unsubscribe(videoMode, {
                    onSuccess: () => {
                        setTimeout(() => {
                            publish();
                        }, 1000);
                    }
                })
            } else {
                publish();
            }
        }
    }

    unpublishAudio = () => {
        console.log(LogPrefix, 'Unpublish Audio');
        this.audio.unpublish();
    }

    unpublish = (videoMode, callbacks) => {
        console.log(LogPrefix, 'Unpublish', videoMode);

        const {role} = this.roomStore;
        const video = videoMode === VideoMode.Primary ? this.primaryVideo : this.secondaryVideo;
        video.unpublish(callbacks);

        if(role === 'subscriber') {
            this.sendPublishStoppedControlMessage();
        }
    }

    closeMemberPublishDialog = () => {
        this.roomStore.setMemberPublishDialogOpen(false);
    }

    startMemberPublish = () => {
        console.log(LogPrefix, 'Starting MemberPublish');
        const {primaryState, videoStreams} = this.roomStore;

        this.roomStore.setMemberPublishDialogOpen(false);

        if(primaryState === VideoState.Published) {
            console.log(LogPrefix, 'Aleready published');

        } else if(primaryState === VideoState.Subscribed || primaryState === VideoState.StreamAttached || primaryState === VideoState.StreamEmpty) {
            console.log(LogPrefix, 'Unsubscrbing');

            const that = this;
            this.unsubscribe(VideoMode.Primary, {
                onSuccess: () => {
                    setTimeout(() => that.publishStream(VideoMode.Primary, videoStreams[0].streamId), 1000);
                }
            });
        } else {
            this.publishStream(VideoMode.Primary, videoStreams[0].streamId);
        }


    }

    stopMemberPublish = (memberId) => {
        this.roomStore.setMemberPublishState(memberId, MemberPublishState.None);

        const {primaryState} = this.roomStore;

        if(primaryState === VideoState.Subscribed || primaryState === VideoState.StreamEmpty) {
            const that = this;
            this.unsubscribe(VideoMode.Primary, {
                onSuccess: () => {
                    setTimeout(() => that.sendAnnounce(), 1000);
                }
            })
        }
    }

    subscribeAudio = (feed) => {
        this.audio.subscribe(feed);
    }

    subscribe = (videoMode, feed, callbacks) => {
        const video = videoMode === VideoMode.Primary ? this.primaryVideo : this.secondaryVideo;
        video.subscribe(feed, callbacks);
    }

    changeFeed = (videoMode, feed) => {
        const video = videoMode === VideoMode.Primary ? this.primaryVideo : this.secondaryVideo;
        video.changeFeed(feed);
    }

    unsubscribe = (videoMode, callbacks) => {
        const video = videoMode === VideoMode.Primary ? this.primaryVideo : this.secondaryVideo;
        video.leave(callbacks);
    }

    unsubscribeAudio = (callbacks) => {
        this.audio.leave(callbacks);
    }

    setSubstream = (videoMode, substream) => {
        console.log(LogPrefix, `Set substream to ${substream}`);

        const video = videoMode === VideoMode.Primary ? this.primaryVideo : this.secondaryVideo;
        video.setSimulcastSubstream(substream);
    }

    setSubstreamLock = (videoMode, substream) => {
        console.log(LogPrefix, `Set substream lock to ${substream}`);

        this._setVideoSubstreamLock(videoMode, substream);
    }

    record = (videoMode, stream) => {
        console.log(LogPrefix, 'Recording...', videoMode);
        const {roomId} = this.roomStore;
        const options = {mimeType: 'video/webm;codec=vp8,opus'};
        const recordName = roomId + "-" + (videoMode === VideoMode.Primary ? 'main' : 'sub') + '.webm';
        const blobs = [];

        if(!MediaRecorder.isTypeSupported(options.mimeType)) {
            console.log(LogPrefix, 'Recording(video/webm;codec=vp3,opus) not supported');
            return;
        }

        let recorder = undefined;

        try {
            recorder = new MediaRecorder(stream, options);
            blobs.splice(0);
        } catch(error) {
            console.log(LogPrefix, 'Can not create MediaRecorder', error);
            return;
        }
        // const that = this;
        recorder.onstop = (event) => {
            console.log(LogPrefix, 'MediaRecorder stopped', event);
            console.log(LogPrefix, 'Recorded Blobs', blobs);

            const recordedBlobs = new Blob(blobs.splice(0), {type: 'video/webm'});
            const localDownloadUrl = window.URL.createObjectURL(recordedBlobs);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = localDownloadUrl;
            a.download = recordName;
            document.body.appendChild(a);
            a.click();

            this._setVideoRecord(videoMode, false);
            this._setVideoRecorder(videoMode, undefined);
        }
        recorder.ondataavailable = (event) => {
            console.log(LogPrefix, 'MediaRecorder data available', event);
            if(event.data && event.data.size > 0) {
                blobs.push(event.data);
            }
        }
        recorder.start();

        this._setVideoRecord(videoMode, true);
        this._setVideoRecorder(videoMode, recorder);
    }

    stopRecord = (videoMode) => {
        const {primaryRecorder, secondaryRecorder} = this.roomStore;

        if(videoMode === VideoMode.Primary) {
            if(primaryRecorder) {
                primaryRecorder.stop();
            }
        } else {
            if(secondaryRecorder) {
                secondaryRecorder.stop();
            }
        }
    }

    play = (videoMode) => {
        const video = videoMode === VideoMode.Primary ? this.config.primaryVideoAreaRef : this.config.secondaryVideoAreaRef;

        if((video) && (video.current)) {
            video.current.muted = false;
            video.current.play();

            this._setVideoState(videoMode, VideoState.Subscribed);
            if(videoMode === VideoMode.Primary) {
                this.roomStore.setPrimaryPlayConfirm(true);
                this.primaryVideo.setAutoPlay(true);
            } else {
                this.roomStore.setSecondaryPlayConfirm(true);
                this.secondaryVideo.setAutoPlay(true);
            }
        }
    }

    playAudio = () => {
        if(this.config.primaryAudioRef && this.config.primaryAudioRef.current) {
            this.config.primaryAudioRef.current.muted = false;
            this.config.primaryAudioRef.current.play();

            this.roomStore.setAudioState(VideoState.Subscribed);
            this.roomStore.setAudioPlayConfirm(true);
            this.audio.setAutoPlay(true);
        }
    }

    destroy = () => {
        const {primaryState, secondaryState, lookAroundPublishState, chatState} = this.roomStore;

        let cleanPrimary = false;
        let cleanSecondary = false;
        if(primaryState === VideoState.Published) {
            this.unpublish(VideoMode.Primary, {
                onSuccess: () => {
                    cleanPrimary = true;
                },
                onError: () => {
                    cleanPrimary = true;
                }
            });
        } else if(primaryState === VideoState.StreamAttached || primaryState === VideoState.Subscribed) {
            this.unsubscribe(VideoMode.Primary);
            cleanPrimary = true;
        } else {
            cleanPrimary = true;
        }

        if(secondaryState === VideoState.Published) {
            this.unpublish(VideoMode.Secondary, {
                onSuccess: () => {
                    cleanSecondary = true;
                },
                onError: () => {
                    cleanSecondary = true;
                }
            });
        } else if(secondaryState === VideoState.StreamAttached || secondaryState === VideoState.Subscribed) {
            this.unsubscribe(VideoMode.Secondary);
            cleanSecondary = true;
        } else {
            cleanSecondary = true;
        }

        let cleanLookAroundPublish = false;
        if(lookAroundPublishState === VideoState.Published) {
            this.lookAroundPublishVideo.unpublish({
                onSuccess: () => {
                    cleanLookAroundPublish = true;
                },
                onError: () => {
                    cleanLookAroundPublish = true;
                }
            });
        }

        if(chatState === ChatState.Connected) {
            this.leave();
        }

        const videoStreams = toJS(this.roomStore.videoStreams);
        console.log(LogPrefix, 'removing streams', videoStreams);
        this.roomStore.removeAllVideoStream();
        videoStreams.forEach((stream) => {
            console.log(LogPrefix, 'removing stream', stream.streamId);

            if(stream && stream.stream && stream.stream.getTracks) {
                stream.stream.getTracks().forEach((track) => {
                    console.log(LogPrefix, 'stop track', track);
                    track.stop()
                });
            }
        })

        if(this.janus) {
            let checkCount = 0;
            const checkAndDestroy = () => {
                console.log('Check for destroy', cleanPrimary, cleanSecondary);

                if(checkCount > 10) {
                    return;
                } else if(cleanPrimary && cleanSecondary && cleanLookAroundPublish) {
                    console.log(LogPrefix, 'Destroy video janus');
                    this.janus.destroy();
                } else {
                    checkCount++;
                    setTimeout(() => checkAndDestroy(), 1000);
                }
            }

            checkAndDestroy();
        }

        if(this.chatJanus) {
            console.log(LogPrefix, 'Destroy chat janus');
            this.chatJanus.destroy();
        }
    }

    openEndRoomDialog = () => {
        this.roomStore.setEndRoomDialogOpen(true);
    }

    closeEndRoomDialog = () => {
        this.roomStore.setEndRoomDialogOpen(false);
    }

    exitRoom = () => {
        this.roomStore.exitRoom();
    }

    endRoom = () => {
        this.roomStore.endRoom();
    }

    _getCurrentState = () => {
        const {mode, mainVideo, subVideoHidden, audioState, audioFeed, primaryState, primaryFeed, secondaryState, secondaryFeed} = this.roomStore;

        return {
            mode: mode,

            mainVideo: mainVideo,
            subVideoHidden: subVideoHidden,

            audioState: audioState,
            audioFeed: audioFeed,

            primaryState: primaryState,
            primaryFeed: primaryFeed,

            secondaryState: secondaryState,
            secondaryFeed: secondaryFeed,
        };
    }

    _setVideoState = (videoMode, state) => {
        if(videoMode === VideoMode.Primary) {
            this.roomStore.setPrimaryState(state);
        } else if(videoMode === VideoMode.Secondary) {
            this.roomStore.setSecondaryState(state);
        }
    }

    _setVideoStream = (videoMode, stream) => {
        if(videoMode === VideoMode.Primary) {
            this.roomStore.setPrimaryStream(stream);
        } else if(videoMode === VideoMode.Secondary) {
            this.roomStore.setSecondaryStream(stream);
        }
    }

    _setVideoFeed = (videoMode, feed) => {
        if(videoMode === VideoMode.Primary) {
            this.roomStore.setPrimaryFeed(feed);
        } else if(videoMode === VideoMode.Secondary) {
            this.roomStore.setSecondaryFeed(feed);
        }
    }

    _setVideoSubstream = (videoMode, substream) => {
        if(videoMode === VideoMode.Primary) {
            this.roomStore.setPrimarySubstream(substream);
        } else if(videoMode === VideoMode.Secondary) {
            this.roomStore.setSecondarySubstream(substream);
        }
    }

    _setVideoSubstreamLock = (videoMode, substream) => {
        if(videoMode === VideoMode.Primary) {
            this.roomStore.setPrimarySubstreamLock(substream);
        } else if(videoMode === VideoMode.Secondary) {
            this.roomStore.setSecondarySubstreamLock(substream);
        }
    }

    _setVideoRecord = (videoMode, record) => {
        if(videoMode === VideoMode.Primary) {
            this.roomStore.setPrimaryRecord(record);
        } else if(videoMode === VideoMode.Secondary) {
            this.roomStore.setSecondaryRecord(record);
        }
    }

    _setVideoRecorder = (videoMode, recorder) => {
        if(videoMode === VideoMode.Primary) {
            this.roomStore.setPrimaryRecorder(recorder);
        } else if(videoMode === VideoMode.Secondary) {
            this.roomStore.setSecondaryRecorder(recorder);
        }
    }

    // For VideoArea size
    setFullScreen = (fullscreen) => {
        this.roomStore.setFullscreen(fullscreen);
    }

    setLandscape = (landscape) => {
        this.layoutManager.setLandscape(landscape);
        this.roomStore.setLandscape(landscape);
    }

    initVideoArea = () => {
        this.layoutManager.initVideoArea();
    }

    resizeVideoArea = (needInit) => {
        console.log(LogPrefix, `Resizing video area : init=${needInit}`);

        const height = this.layoutManager.resizeVideoArea(needInit);
        this.roomStore.setVideoViewHeight(height);
    }

    changeMainVideo = () => {
        if(this.roomStore.mainVideo === VideoMode.Primary) {
            this.setMainVideo(VideoMode.Secondary);
        } else {
            this.setMainVideo(VideoMode.Primary);
        }
    }

    setMainVideo = (mainVideo) => {
        if((mainVideo === VideoMode.Primary) || (mainVideo === VideoMode.Secondary)) {
            this.layoutManager.setMainVideo(mainVideo);
            this.roomStore.setMainVideo(mainVideo);

            const {role} = this.roomStore;

            if(this.chat && (role === 'publisher')) {
                this.sendAnnounce();
            }
        } else {
            console.log(LogPrefix, `Invalid mainVideo value : ${mainVideo}`);
        }
    }

    setSubVideoHidden = (hidden) => {
        this.layoutManager.setSubVideoHidden(hidden);
        this.roomStore.setSubVideoHidden(hidden);

        const {role} = this.roomStore;

        if(this.chat && (role === 'publisher')) {
            this.sendAnnounce();
        }
    }

    setSubVideoPosition = (position) => {
        this.layoutManager.setSubVideoPosition(position);
        this.roomStore.setSubVideoPosition(position);
    }

    showSubVideoLocator = (show, position) => {
        this.layoutManager.showSubVideoLocator(show, position);
    }

    getSubVideoSize = () => {
        return this.layoutManager.getSubVideoSize();
    }

    setSubVideoSize = (size) => {
        this.roomStore.setSubScreenSize(size);
        this.layoutManager.setSubVideoSize(size);
    }

    setSubVideoHighQualityValue = (newValue) => {
        const {primaryState} = this.roomStore;

        this.roomStore.setPrimaryHighQuality(newValue);
        if(newValue && primaryState === VideoState.Subscribed) {
            this.primaryVideo.setSimulcastSubstream(2);
        }
    }

    primaryHighQuality = () => {
        this.primaryVideo.setSimulcastSubstream(2);
    }

    // For Chat sizing
    setChatAreaWidth = (width) => {
        const drawed = this.layoutManager.setChatAreaWidth(width);
        this.roomStore.setChatAreaHidden(!drawed);

    }

    setChatAreaHidden = (hidden) => {
        this.layoutManager.setChatAreaHidden(hidden);
        this.roomStore.setChatAreaHidden(hidden);
    }
}
import {action, flow, observable} from "mobx";
import axios from "axios";

const initState = {
    userData : {
        profileUrl : undefined,
        email: '',
        oldPassword: '', 
        password: '',
        passwordConfirm: '',
        name: '',
        nickName: '',
        checkAgreeTotal: false,
        checkAgreeService: false,
        checkAgreePersonal: false,
    },
    userDataModifyResult: null,
};

export default class UserStore {
    @observable userData = initState.userData;
    @observable userDataModifyResult = initState.userDataModifyResult;

    @action init = () => {
        this.userData = initState.userData;
        this.userDataModifyResult = initState.userDataModifyResult;
        this.getUserData();
    };

    @action changeUserData = (target, value) => {
        this.userData[target] = value;
    };

    @action changeUserDataModifyResult = (value) => {
        this.userDataModifyResult = value;
    };


    getUserData = flow(function* getUserData() {
        try{
            const response = yield axios.get(`/api/v1/users/user-info`);

            if(response.status !== 200) {
                new Error("get User Data failed");
            }

            const user = response.data;
            const name = user.userName;
            const email = user.email;
            const nickName = user.nickName;
            const profileUrl = user.profileUrl;
            this.userData = {
                ...this.userData, 
                name : name,
                email : email,
                nickName: nickName,
                profileUrl : profileUrl
            }

        } catch (e) {
        }
    })

    changeUserProfileImage = flow(function* changeUserProfileImage(meta) {
        console.log('meta!!! :', meta);
        try{
            const formData = new FormData();
            formData.append('file', meta);

            const response = yield axios.post(`/api/v1/files/user-profile`, formData, { headers : {'Content-Type' : 'multipart/form-data'}} );

            console.log('response :', response);
    
        }catch(e){
            console.log('e :', e);
        }
    })

    

    doUserDataModify = flow(function* doUserDataModify() {
        this.userDataModifyResult = "pending";
        try {
            const data = {
                email: this.userData.email,
                password: this.userData.passwordConfirm,
                userName: this.userData.name,
                nickName: this.userData.nickName
            };

            const response = yield axios.put('/api/v1/users', data);

            if(response.status !== 200) {
                new Error("Modify failed");
            }

            this.userDataModifyResult = true;
            this.userData.oldPassword = "";
            this.userData.password = "";
            this.userData.passwordConfirm = "";
            // response
        } catch (e) {
            console.log('error', e);
            this.userDataModifyResult = false;
        }
    });
}
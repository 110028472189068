import {action, computed, flow, observable, toJS} from "mobx";
import axios from "axios";
import _ from "lodash";
import fileDownload from "js-file-download";

const ListState = {
    Loading: 'Loading',
    Loaded: 'Loaded',
    LoadFailed: 'LoadFailed',
};

const AddState = {
    Closed: 'Closed',
    Opened: 'Opened',
    Adding: 'Adding',
    Added: 'Added',
    AddFailed: 'AddFailed',
};

const DetailState = {
    Closed: 'Closed',
    Loading: 'Loading',
    Loaded: 'Loaded',
    LoadFailed: 'LoadFailed',
};

const UpdateState = {
    Closed: 'Closed',
    Loading: 'Loading',
    Loaded: 'Loaded',
    LoadFailed: 'LoadFailed',
    Updating: 'Updating',
    Updated: 'Updated',
    UpdateFailed: 'UpdateFailed',
    Uploading: 'Uploading',
    Uploaded: 'Uploaded',
    UploadFailed: 'UploadFailed',
};

const EmptyBoard = {
    postId: '',
    title: '',
    contents: '',
    createdDateime: '',
};

export default class BoardStore {
    @observable unreadCount = 0;
    @observable listState = ListState.Loaded;
    @observable paging = {
        page: 0,
        rowsPerPage: 10,
        totalCount: 0,
    }
    @observable boardList = [];

    @observable board = {...EmptyBoard};
    @observable fileList = [];
    @observable uploadFile = '';
    @observable addState = AddState.Closed;
    @observable detailState = DetailState.Closed;
    @observable updateState = UpdateState.Closed;


    @action changePagingPage = (page, userId, loginType) => {
        this.paging.page = page;
        this.loadBoardList(userId, loginType);
    }

    @action changePagingRowsPerPage = (rowsPerPage, userId, loginType) => {
        this.paging.rowsPerPage = rowsPerPage;
        this.loadBoardList(userId, loginType);
    }

    @action clearAddDialogState = (open) => {
        if(open) {
            this.addState = AddState.Loaded;
        } else {
            this.addState = AddState.Closed;
        }
    }

    @action changeBoardTitle = (title) => {
        this.board.title = title;
    }

    @action changeBoardContents = (contents) => {
        this.board.contents = contents;
    }

    @action changeUploadFile = (file) => {
        this.uploadFile = file;
    }

    @action openAddDialog = () => {
        this.board = {...EmptyBoard};
        this.fileList = [];
        this.uploadFile = '';
        this.addState = AddState.Opened;
    }

    @action closeDetailDialog = () => {
        this.detailState = DetailState.Closed;
    }

    @action clearUpdateDialogState = (open) => {
        if(open) {
            this.updateState = UpdateState.Loaded;
        } else {
            this.updateState = UpdateState.Closed;
        }
    }


    @computed get isOpenAddDialog() {
        return this.addState !== AddState.Closed;
    }

    @computed get isAddable() {
        return this.board.title.length > 0 && this.board.contents.length > 0;
    }

    @computed get isAdding() {
        return this.addState === AddState.Adding;
    }

    @computed get isAdded() {
        return this.addState === AddState.Added;
    }

    @computed get isAddFailed() {
        return this.addState === AddState.AddFailed;
    }

    @computed get isOpenDetailDialog() {
        return this.detailState !== DetailState.Closed;
    }

    @computed get isOpenUpdateDialog() {
        return this.updateState !== UpdateState.Closed;
    }

    @computed get isUpdateLoadingFailed() {
        return this.updateState === UpdateState.LoadFailed;
    }

    @computed get isUpdatable() {
        return this.board.title.length > 0 && this.board.contents.length > 0;
    }

    @computed get isUpdating() {
        return this.updateState === UpdateState.Updating;
    }

    @computed get isUpdated() {
        return this.updateState === UpdateState.Updated;
    }

    @computed get isUpdateFailed() {
        return this.updateState === UpdateState.UpdateFailed;
    }

    @computed get isUploadable() {
        return this.uploadFile !== undefined && this.uploadFile != null && this.uploadFile !== '';
    }

    @computed get isUploading() {
        return this.updateState === UpdateState.Uploading;
    }

    @computed get firstFile() {
        if(this.fileList.length > 0) {
            return this.fileList[0];
        } else {
            return undefined;
        }
    }

    @computed get otherFileList() {
        const otherFiles = toJS(this.fileList);

        if(otherFiles.length > 1) {
            return otherFiles.splice(1);
        } else {
            return [];
        }
    }

    loadUnreadBoardCount = flow(function * loadUnreadBoardCount(userId, loginType) {
        try {
            if(loginType === 'MEMBER') {
                const response = yield axios.get(`/api/v1/boards/member?paging=yes&rows-per-page=100&page=1`);
                const unreadList = _.filter(response.data.resultList, (board) => !board.view);

                // console.log('unread');
                // console.log(response.data);
                // console.log(unreadList);

                this.unreadCount = unreadList.length;
            } else {
                this.unreadCount = 0;
            }
        } catch(error) {
            this.unreadCount = 0;
        }
    })

    loadBoardList = flow(function* loadBoardList(userId, loginType) {
        this.listState = ListState.Loading;

        try {
            let response = null;
            if(loginType === 'USER') {
                response = yield axios.get(`/api/v1/boards?paging=yes&rows-per-page=${this.paging.rowsPerPage}&page=${this.paging.page + 1}`);
            } else {
                response = yield axios.get(`/api/v1/boards/member?paging=yes&rows-per-page=${this.paging.rowsPerPage}&page=${this.paging.page + 1}`)
            }
            const totalCount = response.data.totalCount;
            const boards = response.data.resultList;

            // console.log(response.data);

            this.paging.totalCount = totalCount;
            this.boardList = boards;

            this.listState = ListState.Loaded;
        } catch(error) {
            this.listState = ListState.LoadFailed;
        }

    })

    addNewBoard = flow(function* addNewBoard(userId, loginType) {
        this.addState = AddState.Adding;

        try {
            const param = {
                userId: userId,
                typeCode: 'NOTICE',
                title: this.board.title,
                contents: this.board.contents,
                viewCount: 0,
                availableFlag: true,
                createdUserId: userId,
            };

            const response = yield axios.post(`/api/v1/boards`, param);
            const newBoard = response.data;

            if(this.uploadFile) {
                const fileParam = new FormData();
                fileParam.append('boardAttach', JSON.stringify({
                    postId: newBoard.postId,
                    userId: userId,
                    comment: '',
                    sortOrder: 1,
                }));
                fileParam.append('file', this.uploadFile);

                yield axios.post('/api/v1/files/board-attach', fileParam);
            }

            this.loadBoardList(userId, loginType)

            this.board = {...EmptyBoard};
            this.file = '';
            this.addState = AddState.Added;
        } catch(error) {
            this.addState = AddState.AddFailed;
        }
    })

    openDetailDialog = flow(function* openDetailDialog(postId, userId, loginType) {
        this.board = {...EmptyBoard};
        this.fileList = [];
        this.uploadFile = '';
        this.detailState = DetailState.Loading;

        try {
            const response = yield axios.get(`/api/v1/boards/board-info?post-id=${postId}`);
            const board = response.data;
            const fileResponse = yield axios.get(`/api/v1/boards/files?post-id=${postId}`);
            const files = fileResponse.data;

            const viewParam = {
                postId: postId,
                userId: userId,
            };

            if(loginType === 'MEMBER') {
                axios.post('/api/v1/boards/view', viewParam)
                    .then(() => {
                        console.log(`View history recorded : ${postId}, ${userId}`);

                        this.loadUnreadBoardCount(userId, loginType);
                        this.loadBoardList(userId, loginType);
                    })
                    .catch(() => {
                        console.log(`View history record failed: ${postId}, ${userId}`);
                    })
            }


            this.board = board;
            this.fileList = files;
            this.uploadFile = '';
            this.detailState = DetailState.Loaded;
        } catch(error) {
            this.board = {...EmptyBoard};
            this.fileList = [];
            this.uploadFile = '';
            this.detailState = DetailState.LoadFailed;
        }
    })

    downloadFile = flow(function* downloadFile(fileId, fileName) {
        const fileInfo = _.find(this.fileList, (file) => file.postFileId === fileId);

        if(fileInfo) {
            fileInfo.downloading = true;

            try {
                const response = yield axios.get(`/api/v1/files/board-attach?post-file-id=${fileId}`, {responseType: 'blob'});
                const file = response.data;

                fileDownload(file, fileName);
                fileInfo.downloading = false;
            } catch (error) {

            }
        }
    })

    openUpdateDialog = flow(function* openUpdateDialog(postId) {
        this.board = {...EmptyBoard};
        this.fileList = [];
        this.uploadFile = '';
        this.updateState = UpdateState.Loading;

        try {
            const response = yield axios.get(`/api/v1/boards/board-info?post-id=${postId}`);
            const board = response.data;
            const fileResponse = yield axios.get(`/api/v1/boards/files?post-id=${postId}`);
            const files = fileResponse.data;

            this.board = board;
            this.fileList = files;
            this.uploadFile = '';
            this.updateState = UpdateState.Loaded;
        } catch(error) {
            this.board = {...EmptyBoard};
            this.fileList = [];
            this.uploadFile = '';
            this.updateState = UpdateState.LoadFailed;
        }
    })

    uploadBoardFile = flow(function* uploadFile(userId) {
        this.updateState = UpdateState.Uploading;

        try {
            const fileParam = new FormData();
            fileParam.append('boardAttach', JSON.stringify({
                postId: this.board.postId,
                userId: userId,
                comment: '',
                sortOrder: 1,
            }));
            fileParam.append('file', this.uploadFile);

            yield axios.post('/api/v1/files/board-attach', fileParam);

            const fileResponse = yield axios.get(`/api/v1/boards/files?post-id=${this.board.postId}`);
            const files = fileResponse.data;

            this.uploadFile = '';
            this.fileList = files;
            this.updateState = UpdateState.Uploaded;
        } catch (error) {
            this.updateState = UpdateState.UploadFailed;
        }
    })

    updateBoard = flow(function* updateBoard(userId, loginType) {
        this.updateState = UpdateState.Updating;

        try {
            yield axios.put('/api/v1/boards', this.board);

            this.updateState = UpdateState.Updated;
            this.loadBoardList(userId, loginType);
        } catch(error) {
            this.updateState = UpdateState.UpdateFailed;
        }
    })

    deleteBoard = flow(function* deleteBoard(postId, userId, loginType) {
        try {
            yield axios.delete(`/api/v1/boards?post-id=${postId}`);

            this.loadBoardList(userId, loginType);
        } catch (error) {
            console.log(`Can't delete board : ${postId}`);

        }
    })
}
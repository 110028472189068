import _ from "lodash";
import {RoomChat} from "./RoomChat";
import {RoomVideo} from "./RoomVideo";
import {RoomLayoutManager} from "./RoomLayoutManager";
import {toJS} from "mobx";
import axios from "axios";
import * as Params from "../../../common/Params";
import RoomAudio from "./RoomAudio";
import {RoomManager} from "./RoomManager";
import moment from "moment";

export const ChatState = {
    None: 'None',
    Connecting: 'Connecting',
    Connected: 'Connected',
    AlreadyJoined: 'AlreadyJoined',
    Error: 'Error',
};

export const AudioState = {
    None: 'None',
    Publishing: 'Publishing',
    Published: 'Published',
    PublishError: 'PublishError',
};

export const RoomMode = {
    Normal: 'Normal',
    LookAround: 'LookAround',
};

export const VideoState = {
    None: 'None',
    Connecting: 'Connecting',
    Connected: 'Connected',
    ConnectError: 'ConnectError',

    Publishing: 'Publishing',
    Published: 'Published',
    PublishError: 'PublishError',
    Unpublishing: 'Unpublishing',

    Subscribing: 'Subscribing',
    StreamAttached: 'StreamAttached',
    Subscribed: 'Subscribed',
    StreamEmpty: 'StreamEmpty',
    SubscribeError: 'SubscribeError',
    Unsubscribing: 'Unsubscribing',
};

export const VideoMode = {
    None: 'None',
    Primary: 'Primary',
    Secondary: 'Secondary',
    LookAround: 'LookAround',
    Monitoring: 'Monitoring',
};

export const VideoPosition = {
    TopLeft: 'TopLeft',
    TopRight: 'TopRight',
    BottomLeft: 'BottomLeft',
    BottomRight: 'BottomRight',
};

export const MemberPublishState = {
    None: 'None',
    Request: 'Request',
    Confirmed: 'Confirmed',
    Published: 'Published',
};

export const MessageType = {
    Message: 'Message',
    Whisper: 'Whisper',
    Link: 'Link',
    Announce: 'Announce',
    RequestAnnounce: 'RequestAnnounce',
    NotifyMedia: 'NotifyMedia',
    NotifySound: 'NotifySound',
    RequestPublish: 'RequestPublish',
    ConfirmPublish: 'ConfirmPublish',
    PublishComplete: 'PublishComplete',
    PublishStopped: 'PublishStopped',
    Attendance: 'Attendance',
    AttendanceLate: 'AttendanceLate',
    Quiz: 'Quiz',
    StartPoll: 'StartPoll',
    PollResponse: 'PollResponse',
    EndPoll: 'EndPoll',
    WhiteBoardData: 'WhiteBoardDataSend',
    RequestTinyGroup: 'RequestTinyGroup',
    ChangeParticipateTinyGroup: 'ChangeParticipateTinyGroup',
    NotifyTinyGroup: 'NotifyTinyGroup',
    CallOwnerTinyGroup: 'CallOwnerTinyGroup',
};

export const StreamType = {
    LocalCamera: 'LocalCamera',
    LocalScreen: 'LocalScreen',
    Remote: 'Remote',
};

export const ChatTabIndex = {
    Chat: 0,
    Member: 1,
    Poll: 2,
    TinyGroup : 3,
    WhiteBoard: 4,
};

const LogPrefix = '[RoomPresenter] ';
const CameraWidth = 800;
const CameraHeight = 450;
const ScreenWidth = 1280;
const ScreenHeight = 720;
const SmallWidth = 200;
const SmallHeight = 113;
const LookAroundWidth = 384;
const LookAroundHeight = 216;

export class RoomPresenter2 {
    constructor(history, config, stores, callbacks) {
        this.config = Object.assign({}, {
            server: '',
            iceServers: [],
            roomId: '',
            role: '',
            userId: '',
            displayName: '',
            deviceType: '',
            browserType: '',

            subVideoSizePercent: 15,
            mainZIndex: 10,
            landscape: true,
            isMobile: false,

            primaryAudioRef: undefined,
            videoContainerRef: undefined,
            primaryVideoAreaRef: undefined,
            secondaryVideoAreaRef: undefined,
            whiteBoardRef: undefined,
            primaryLayerRef: undefined,
            secondaryLayerRef: undefined,
            secondaryLocatorRef: undefined,
            additionalVideoRefs: [],
            chatAreaRef: undefined,
        }, config);
        this.callbacks = Object.assign({}, {
            onRequestPublish: undefined,
            onConfirmPublish: undefined,
            onReceivedAttendance: undefined,
            onReceivedQuiz: undefined,
            onReceivedStartPoll: undefined,
            onReceivedPollResponse: undefined,
            onReceivedEndPoll: undefined,
        }, callbacks);
        this.history = history;
        this.roomStore = stores.roomStore;
        this.monitoringStore = stores.monitoringStore;
        this.whiteBoardStore = stores.whiteBoardStore;
        this.tinyGroupStore = stores.tinyGroupStore;
        this.publishStore = stores.publishStore;

        this.chatJanus = undefined;
        this.janus = undefined;
        this.chat = undefined;
        this.videoManager = undefined;
        this.primaryVideo = undefined;
        this.secondaryVideo = undefined;

        this.lookAroundPublishVideo = undefined;
        this.lookAroundPrimaryVideo = undefined;
        this.layoutManager = new RoomLayoutManager(config, this.onVideoResize);

        this.connect();
    }

    static getDevices = (callback) => {
        console.log(LogPrefix, 'Enumerating devices...');

        if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
            navigator.mediaDevices.enumerateDevices()
                .then(devices => {
                    console.log(LogPrefix, 'Enumerate devices success', devices);

                    callback(devices);
                })
                .catch(error => {
                    console.log(LogPrefix, 'Enumerate devices error', error);

                    callback([]);
                });
        }
    }

    onVideoResize = (width, height, left, top) => {
        const {roomStore} = this;

        if(roomStore) {
            console.log(LogPrefix, `onVideoResize : width=${width}, height=${height}`);
            this.roomStore.setVideoViewLeft(left);
            this.roomStore.setVideoViewTop(top);
            this.roomStore.setVideoViewHeight(height);
            this.roomStore.setVideoViewWidth(width);

            this.whiteBoardStore.redraw({width: width, height: height, left: left, top: top});
        }
    }

    connect = () => {
        const {server, iceServers} = this.roomStore;
        const that = this;

        console.log(LogPrefix, 'Connecting...', server, iceServers);
        const janus = new window.Janus({
            server: server,
            iceServers: iceServers,
            success: function() {
                console.log(LogPrefix, 'VideoJanus connected successfully');

                const {roomId, role, userId, displayName} = that.roomStore;

                that.janus = janus;
                that.chat = new RoomChat(janus, roomId, role, userId, displayName, that._chatHandler());
                //that.audio = new RoomVideo(VideoMode.None, janus, roomId, role, userId, displayName, that.config.primaryAudioRef, that._audioHandler());
                that.audio = new RoomAudio(janus, that.config.primaryAudioRef, that._audioBridgeHandler());
                if(role === 'publisher') {
                    that.videoManager = new RoomManager(janus, roomId);
                }
                that.primaryVideo = new RoomVideo(VideoMode.Primary, janus, roomId, role, userId, displayName, that.config.primaryVideoAreaRef, that._videoHandler(VideoMode.Primary));
                that.secondaryVideo = new RoomVideo(VideoMode.Secondary, janus, roomId, role, userId, displayName, that.config.secondaryVideoAreaRef, that._videoHandler(VideoMode.Secondary));
                that.lookAroundPublishVideo = new RoomVideo(VideoMode.LookAround, janus, roomId, role, userId, displayName, undefined, that._lookAroundHandler(VideoMode.LookAround));

                that.chat.attachAndJoin();
            },
            error: function(error) {
                console.log(LogPrefix, 'VideoJanus connect failed', error);

                that.roomStore.setReconnectDialogOpen(true);
            },
            destroyed: function() {
                console.log(LogPrefix, 'VideoJanus destroyed');
            }
        });
    }

    _processAnnounce = (announce) => {
        const {role} = this.roomStore;

        if(role === 'publisher') {

        } else {
            const {notificationMsg, isChattingPossible, isVideoRotateX, isVideoRotateY, activation} = this.roomStore;

            const {mode, mainVideo, subVideoHidden, memberMic, primaryState, primaryFeed, secondaryState, secondaryFeed} = this.publishStore;
            console.log(LogPrefix, `ProcessAnnounce : primaryState=${primaryState}, primaryFeed=${primaryFeed}, secondaryState=${secondaryState}, secondaryFeed=${secondaryFeed}`);

            if(activation) {
                if(activation.activationId !== announce.activationId) {
                    this.roomStore.getActivationChannel();
                }
            } else {
                this.roomStore.getActivationChannel();
            }

            if(mode !== announce.mode) {
                this.publishStore.setMode(announce.mode);

                if(announce.mode === RoomMode.LookAround) {
                    this.publishLookAround();
                    this.closeMemberPublishDialog();
                } else {
                    this.unpublishLookAround();
                }
            }

            if(mainVideo !== announce.mainVideo) {
                this.setMainVideo(announce.mainVideo);
            }

            if(subVideoHidden !== announce.subVideoHidden) {
                this.setSubVideoHidden(announce.subVideoHidden);
            }

            if(announce.memberMic !== memberMic) {
                const {audioDevice} = this.publishStore;

                this.publishStore.setMemberMic(announce.memberMic);
                if(audioDevice) {
                    if (announce.memberMic) {
                        this.publishAudio();
                    } else {
                        this.unpublishAudio();
                    }
                }
            }

            if(notificationMsg !== announce.notificationMsg) {
                this.roomStore.setNotificationMsg(announce.notificationMsg);
            }

            if(isChattingPossible !== announce.isChattingPossible) {
                this.roomStore.setChattingPossible(announce.isChattingPossible);
            }

            if(isVideoRotateX !== announce.isVideoRotateX || isVideoRotateY !== announce.isVideoRotateY) {
                this.roomStore.setVideoRotate(announce.isVideoRotateX, announce.isVideoRotateY, this.config.primaryVideoAreaRef)
            }

            if(announce.isTinyGroupDialogOpen) {
                this.setTinyGroup(announce.ownerGroupId);
            }

            if((announce.primaryState === VideoState.Published) || (announce.primaryState === VideoState.StreamAttached) || (announce.primaryState === VideoState.Subscribed)) {
                this.closeMemberPublishDialog();
                if (announce.primaryFeed !== primaryFeed) {
                    this.subscribeVideo(VideoMode.Primary, announce.mode === RoomMode.LookAround, announce.primaryFeed);
                }  else {
                    if(primaryState === VideoState.Publishing || primaryState === VideoState.Published) {
                        console.log(LogPrefix, 'Already published', primaryState, primaryFeed);
                    } else if(primaryState === VideoState.Subscribing || primaryState === VideoState.Subscribed) {
                        console.log(LogPrefix, 'Already subscribed', primaryState, primaryFeed);
                    } else {
                        this.subscribeVideo(VideoMode.Primary, announce.mode === RoomMode.LookAround, announce.primaryFeed);
                    }
                }
            } else if(announce.primaryState === VideoState.None) {
                if(primaryState === VideoState.Published) {
                    this.unpublish(VideoMode.Primary);
                } else if(primaryState === VideoState.Subscribed || primaryState === VideoState.StreamAttached || primaryState === VideoState.StreamEmpty) {
                    this.unsubscribe(VideoMode.Primary);
                }
            }

            if((announce.secondaryState === VideoState.Published) || (announce.secondaryState === VideoState.StreamAttached) || (announce.secondaryState === VideoState.Subscribed)) {
                if(announce.secondaryFeed !== secondaryFeed) {
                    this.subscribeVideo(VideoMode.Secondary, false, announce.secondaryFeed);
                } else {
                    if(secondaryState === VideoState.Publishing || secondaryState === VideoState.Published) {
                        console.log(LogPrefix, 'Already published', secondaryState, secondaryFeed);
                    } else if(secondaryState === VideoState.Subscribing || secondaryState === VideoState.Subscribed) {
                        console.log(LogPrefix, 'Already subscribed', secondaryState, secondaryFeed);
                    } else {
                        this.subscribeVideo(VideoMode.Secondary, false, announce.secondaryFeed);
                    }
                }
            } else if(announce.secondaryState === VideoState.None) {
                const {secondaryState} = this.publishStore;

                if(secondaryState === VideoState.Published) {
                    this.unpublish(VideoMode.Secondary);
                } else if(secondaryState === VideoState.Subscribed || secondaryState === VideoState.StreamAttached || secondaryState === VideoState.StreamEmpty) {
                    this.unsubscribe(VideoMode.Secondary);
                }
            }
        }
    }

    _chatHandler = () => {
        const that = this;
        return ({
            onMessage: (param) => {
                console.log(LogPrefix, 'Got a message', param);
                const {from, date, type, msg} = param;

                if(type === MessageType.Message || type === MessageType.Whisper || type === MessageType.Link) {              // It's a chat message
                    const {chatMembers, isPublisher, setMessages} = that.roomStore;
                    const member = _.find(chatMembers, (m) => m.id === from);
                    if (member) {
                        const displayName = member.user.userName;
                        const chatMessage = {
                            type: type,
                            from: from,
                            fromName: displayName,
                            date: date,
                            msg: msg,
                        };

                        if(this.roomStore.selectedChatTab !== ChatTabIndex.Chat) {
                            this.roomStore.setNewChatBadgeInvisible(false);
                        }

                        that.roomStore.addChatMessage(chatMessage);

                        if (type === MessageType.Message) {
                            if(isPublisher) {
                                const message = `[${moment(date).format("YYYY-MM-DD a hh:mm")}] ${displayName} : ${msg}\n`;
                                setMessages(message);
                            }
                        } else if(type === MessageType.Whisper) {
                            if(isPublisher) {
                                const message = `[${moment(date).format("YYYY-MM-DD a hh:mm")}] ${displayName} : [ 귓속말 : ${msg} ]\n`;
                                setMessages(message);
                            }
                        } else if (type === MessageType.Link) {
                            if(isPublisher) {
                                const message = `[${moment(date).format("YYYY-MM-DD a hh:mm")}] ${displayName} : [ 웹링크 : ${msg} ]\n`;
                                setMessages(message);
                            }
                        }
                    }
                } else {                                        // It's a control message
                    const {role, chatMembers} = that.roomStore;

                    if(role === 'publisher') {                  // It's a control message for publisher
                        if(type === MessageType.NotifySound) {
                            const {id, soundOn} = msg;

                            that.publishStore.setChatMemberSoundOn(id, soundOn);
                            that.roomStore.setChatMemberSoundOn(id, soundOn);
                            that.tinyGroupStore.setChatMemberSoundOn(id, soundOn);
                        } else if(type === MessageType.NotifyMedia) {
                            const {id, mediaOn, deviceType, browserType} = msg;

                            that.publishStore.setChatMemberMediaOn(id, mediaOn, deviceType, browserType);
                            that.roomStore.setChatMemberMediaOn(id, mediaOn, deviceType, browserType);
                            that.tinyGroupStore.setChatMemberMediaOn(id, mediaOn, deviceType, browserType);
                        } else if (type === MessageType.RequestPublish) {
                            const {primaryState, primaryFeed} = that.publishStore;
                            const {data} = msg;

                            if((primaryState !== VideoState.Subscribed) || (primaryFeed !== from)) {
                                that.roomStore.setMemberPublishState(from, MemberPublishState.Request);

                                if (that.callbacks.onRequestPublish) {
                                    const member = _.find(chatMembers, (m) => m.id === from);
                                    that.callbacks.onRequestPublish(member, data);
                                }
                            }
                        } else if(type === MessageType.PublishComplete) {
                            that.roomStore.setMemberPublishState(from, MemberPublishState.Published);
                            that.subscribeVideo(VideoMode.Primary, false, from);
                        } else if(type === MessageType.PublishStopped) {
                            that.roomStore.setMemberPublishState(from, MemberPublishState.None);
                            that.unsubscribe(VideoMode.Primary);
                        } else if(type === MessageType.RequestAnnounce) {
                            setTimeout(() => that.sendAnnounce(from, {
                                onSuccess: () => {
                                    console.info(`Sending announce to ${from} success.`);
                                },
                                onError: () => {
                                    console.warn(`Sending announce to ${from} error. retry...`);
                                    that.sendAnnounce(from, {
                                        onSuccess: () => {
                                            console.info(`Sending 2nd announce to ${from} success.`);
                                        },
                                        onError: () => {
                                            console.warn(`Sending 2nd announce to ${from} error.`);
                                        }
                                    });
                                }
                            }), 1000);
                        } else if(type === MessageType.PollResponse) {
                            const {pollId} = msg;

                            if(that.callbacks.onReceivedPollResponse) {
                                that.callbacks.onReceivedPollResponse(pollId);
                            }
                        } else if(type === MessageType.CallOwnerTinyGroup) {
                            const { groupId } = msg;
                            that.tinyGroupStore.calledOwner(groupId);
                        }
                    } else if (role === 'subscriber') {                                    // It's control message for subscriber.
                        if (type === MessageType.ConfirmPublish) {
                            const {primaryState} = that.publishStore;
                            if (primaryState !== VideoState.Published) {
                                this.roomStore.setMemberPublishDialogOpen(true);
                            } else {
                                console.log(LogPrefix, 'Already published');
                            }
                        } else if (type === MessageType.Announce) {
                            const {announce} = msg;

                            that._processAnnounce(announce);
                        } else if (type === MessageType.Attendance) {
                            const {data} = msg;

                            this.callbacks.onReceivedAttendance(data);
                        } else if (type === MessageType.AttendanceLate) {
                            const {data} = msg;

                            this.callbacks.onReceivedAttendance(data);
                        } else if (type === MessageType.Quiz) {
                            const {data} = msg;

                            this.callbacks.onReceivedQuiz(data);
                        } else if (type === MessageType.StartPoll) {
                            const {pollId} = msg;

                            this.callbacks.onReceivedStartPoll(pollId);
                        } else if (type === MessageType.PollResponse) {
                            const {pollId} = msg;

                            if (that.callbacks.onReceivedPollResponse) {
                                that.callbacks.onReceivedPollResponse(pollId);
                            }
                        } else if (type === MessageType.EndPoll) {
                            const {pollId} = msg;

                            if (that.callbacks.onReceivedEndPoll) {
                                that.callbacks.onReceivedEndPoll(pollId);
                            }
                        } else if (type === MessageType.WhiteBoardData) {
                            const {visible} = msg;
                            const {videoViewWidth, videoViewHeight, videoViewLeft, videoViewTop} = this.roomStore;
                            const rect = {
                                width: videoViewWidth,
                                height: videoViewHeight,
                                left: videoViewLeft,
                                top: videoViewTop
                            };

                            if (visible) {
                                const {roomId} = this.roomStore;
                                if (roomId) {
                                    console.log(LogPrefix, 'Getting WhiteBoardData', roomId, rect)
                                    this.whiteBoardStore.getWhiteBoardHistory(roomId, rect, {
                                        success: () => {
                                            this.whiteBoardStore.setVisible(true, rect);
                                        }
                                    });
                                }
                            } else {
                                this.whiteBoardStore.setVisible(false);
                            }
                        } else if (type === MessageType.RequestPublish) {
                            const {primaryState, primaryFeed} = that.publishStore;
                            const {data} = msg;

                            if ((primaryState !== VideoState.Subscribed) || (primaryFeed !== from)) {
                                that.roomStore.setMemberPublishState(from, MemberPublishState.Request);

                                if (that.callbacks.onRequestPublish) {
                                    const member = _.find(chatMembers, (m) => m.id === from);
                                    that.callbacks.onRequestPublish(member, data);
                                }
                            }
                        } else if (type === MessageType.RequestTinyGroup) {
                            const {request, ownerGroupId, userIds} = msg;
                            if (request) {
                                if (userIds.find(id => that.roomStore.userId === id)) {
                                    that.publishStore.unpublish();
                                    that.setTinyGroup(ownerGroupId);
                                }
                            } else {
                                that.tinyGroupStore.onClose();
                                setTimeout(() => that.attachAudio(), 3000);
                                setTimeout(() => that.monitoringPublish(), 3000);
                            }
                        } else if (type === MessageType.ChangeParticipateTinyGroup) {
                            const {ownerGroupId} = msg;

                            that.tinyGroupStore.receivedChangeOwnerGroup(ownerGroupId);
                        } else if (type === MessageType.NotifyTinyGroup) {
                            const {notifyMessage} = msg;

                            that.tinyGroupStore.setNotifyMessage(notifyMessage);
                            that.tinyGroupStore.setNotifyDialogOpen(true);
                            const timeoutId = setTimeout(() => {
                                that.tinyGroupStore.setNotifyMessage('');
                                that.tinyGroupStore.setNotifyDialogOpen(false);
                            }, 10 * 1000);

                            that.tinyGroupStore.setNotifyMessageTimoutId(timeoutId);
                        }
                    }
                }
            },
            onControlMessage: (msg) => {
                console.log(LogPrefix, 'Got a control message', msg);
            },
            onAnnounceMessage: (announce) => {
                console.log(LogPrefix, 'Got a announce', announce);

                const {role} = that.roomStore;

                if(role !== 'publisher') {
                    that._processAnnounce(announce);
                }
            },
            onJoin: (joinUserId, displayName, when) => {
                console.log(LogPrefix, `User joined : joinUserId=${joinUserId}, displayName=${displayName}, when=${when}`);

                that.publishStore.chatMemberJoined(joinUserId);
                that.roomStore.chatMemberJoined(joinUserId);
                that.tinyGroupStore.chatMemberJoined(joinUserId);

                const {roomUserId, role, userId, chatMembers} = that.roomStore;

                const joinUser = chatMembers.find(m => m.id === joinUserId);
                console.log("joinUser",joinUser);
                if(joinUser) {
                    const message = `[${moment().format("YYYY-MM-DD a hh:mm")}] - ${joinUser.user.userName} 입장하셨습니다. -\n`;
                    that.roomStore.setMessages(message);
                }

                if(userId === joinUserId) {                 // 내가 조인 했을 경우,
                    if(role === 'publisher') {
                        that.roomStore.activateChannel();
                    }
                } else {                                    // 다른 이가 조인했을 경우,
                    if (role === 'publisher') {
                        that.roomStore.logChannelEnterHistory(joinUserId);

                        setTimeout(() => that.sendAnnounce(joinUserId, {
                            onSuccess: () => {
                                console.info(`Sending announce to ${joinUserId} success.`);
                            },
                            onError: () => {
                                console.warn(`Sending announce to ${joinUserId} error. retry...`);
                                that.sendAnnounce(joinUserId, {
                                    onSuccess: () => {
                                        console.info(`Sending 2nd announce to ${joinUserId} success.`);
                                    },
                                    onError: () => {
                                        console.warn(`Sending 2nd announce to ${joinUserId} error.`);
                                    }
                                });
                            }
                        }), 1000);

                        setTimeout(() => that.sendWhiteBoardMessage(joinUserId), 2000);
                    } else {
                        if (joinUserId === roomUserId) {     // 선생님이 뒤늦게 입장하면...
                            that._sendNotifyMedia();
                            setTimeout(() => that.monitoringPublish(), 4000);
                        }
                    }
                }
            },
            onLeave: (leaveUserId) => {
                console.log(LogPrefix, `User leaved : userId=${leaveUserId}`);

                that.publishStore.chatMemberLeaved(leaveUserId);
                that.roomStore.chatMemberLeaved(leaveUserId);
                that.tinyGroupStore.chatMemberLeaved(leaveUserId);

                const joinUser = that.roomStore.chatMembers.find(m => m.id === leaveUserId);
                if(joinUser) {
                    const message = `[${moment().format("YYYY-MM-DD a hh:mm")}] - ${joinUser.user.userName} 퇴장하셨습니다. -\n`;
                    that.roomStore.setMessages(message);
                }

                const {role, userId} = that.roomStore;
                if(userId === leaveUserId) {                // 내가 나갈 경우.
                    if(role === 'publisher') {
                        // that.roomStore.deactivateChannel();
                    }
                } else {                                    // 다른 이가 나갈 경우.
                    if (role === 'publisher') {
                        const {primaryState, primaryFeed} = that.publishStore;
                        if ((primaryState === VideoState.Subscribed || primaryState === VideoState.StreamEmpty) && (primaryFeed === leaveUserId)) {
                            console.log(LogPrefix, 'Publishing user leaved');

                            that.unsubscribe(VideoMode.Primary, {
                                onSuccess: () => {
                                    setTimeout(() => that.sendAnnounce(), 1000);
                                }
                            });
                        }
                        that.roomStore.logChannelExitHistory(leaveUserId);
                    } else {
                        const {roomUserId, roomEnded} = that.roomStore;
                        const {audioState, primaryState, secondaryState, monitoringState} = that.publishStore;

                        if (roomUserId === leaveUserId) {       // 선생님이 나가면....
                            if (audioState === VideoState.Published || audioState === VideoState.Publishing) {
                                that.unpublishAudio();
                            }

                            if (primaryState === VideoState.Published || primaryState === VideoState.Publishing) {
                                that.unpublish(VideoMode.Primary);
                            }

                            if (primaryState === VideoState.Published || primaryState === VideoState.Publishing) {
                                that.publishStore.unpublish();
                            }

                            if (monitoringState === VideoState.Published) {
                                that.unsubscribe(VideoMode.Primary);
                            }

                            if (secondaryState === VideoState.Subscribed || secondaryState === VideoState.Subscribing || secondaryState === VideoState.StreamEmpty) {
                                that.unsubscribe(VideoMode.Secondary);
                            }
                            if(that.history) {
                                that.roomStore.exitRoom();
                                that.history.push(that.roomStore.exitUrl);
                            }
                        }
                    }
                }
            },
            onKicked: (userName) => {
                console.log(LogPrefix, 'OnKicked', userName);
                if(that.history && that.roomStore.userId === userName) {
                    that.history.push(that.roomStore.exitUrl);
                    // window.location.reload();
                }
            },
            onConnecting: (info) => {
                console.log(LogPrefix, 'OnConnecting', info);
                that.chatState = ChatState.Connecting;
                that.roomStore.setChatState(ChatState.Connecting);
            },
            onConnectSuccess: (info) => {
                console.log(LogPrefix, 'OnConnectSuccess', info);
                that.chatState = ChatState.Connected;
                that.roomStore.setChatState(ChatState.Connected);

                if(that.videoManager) {
                    that.videoManager.createRoom();
                    that.startXVideo();
                }

                const {roomId, userId} = that.roomStore;
                that.audio.attachPlugin(roomId, userId);
            },
            onAlreadyJoined: (info) => {
                console.log(LogPrefix, 'OnAlreadyJoined', info);
                that.roomStore.setChatState(ChatState.AlreadyJoined);
            },
            onConnectError: (error) => {
                console.warn(LogPrefix, 'OnConnectError', error);
                that.chatState = ChatState.Error;
                that.roomStore.setChatState(ChatState.Error);
            },
            onConnectClosed: () => {
                console.log(LogPrefix, 'OnConnectClosed');

                if(that.lookAroundPublishVideo) {
                    that.lookAroundPublishVideo.leave();
                }

                if(that.secondaryVideo) {
                    that.secondaryVideo.leave();
                }

                if(that.primaryVideo) {
                    that.primaryVideo.leave();
                }

                if(that.audio) {
                    that.audio.leave();
                }

                if(that.videoManager) {
                    that.videoManager.destroyRoom();
                }

                if(that.monitoringStore.monitoringVideo) {
                    that.monitoringStore.monitoringVideo.leave();
                }

                if(that.janus) {
                    const janusHandle = that.janus;

                    setTimeout(() => {
                        console.log(LogPrefix, 'Janus destroy');
                        janusHandle.destroy()
                    }, 3000);
                }
            }
        });
    }

    _audioBridgeHandler = () => {
        console.log(LogPrefix, ' AudioBridgeHandler');

        const that = this;
        let isFirst = true;
        return ({
            onJoining: () => {
                console.log(LogPrefix, 'RoomAudio onJoining');
            },
            onJoined: () => {
                console.log(LogPrefix, 'RoomAudio onJoined');

                RoomPresenter2.getDevices((devices) => {
                    console.log(LogPrefix, 'Device detected', devices);

                    const audioDevices = _.filter(devices, (d) => d.deviceId !== 'default' && d.kind === 'audioinput');
                    const selectedDevice = audioDevices && audioDevices.length > 0 ? audioDevices[0] : undefined;

                    that.changeAudioDevice(selectedDevice);
                });
            },
            onJoinError: () => {
                console.log(LogPrefix, 'RoomAudio onJoinError');
            },
            onExit: () => {
                console.log(LogPrefix, 'RoomAudio onExit');

                that.publishStore.setAudioState(VideoState.None);
            },
            onParticipant: (participant) => {
                console.log(LogPrefix, 'RoomAudio onParticipant', participant);

                that.publishStore.setChatMemberAudioOn(participant.id, participant.setup, participant.muted);
                that.roomStore.setChatMemberAudioOn(participant.id, participant.setup, participant.muted);
                that.tinyGroupStore.setChatMemberAudioOn(participant.id, participant.setup, participant.muted);
            },
            onLeaveParticipant: (participantId) => {
                console.log(LogPrefix, 'RoomAudio onLeaveParticipant', participantId);

                that.publishStore.setChatMemberAudioOn(participantId, false, true);
                that.roomStore.setChatMemberAudioOn(participantId, false, true);
                that.tinyGroupStore.setChatMemberAudioOn(participantId, false, true);
            },
            onPublishing: () => {
                console.log(LogPrefix, 'RoomAudio onPublishing');

                that.publishStore.setAudioState(VideoState.Publishing);
            },
            onPublished: (userId, muted) => {
                console.log(LogPrefix, `RoomAudio onPublished : userId=${userId}, muted=${muted}`);

                that.publishStore.setAudioState(VideoState.Published);
                that.publishStore.setAudioMuted(muted);

                const {soundPlayed, memberMic} = that.publishStore;
                if(isFirst && !soundPlayed) {
                    isFirst = false;

                    if(!soundPlayed) {
                        that.roomStore.setAudioPlayDialogOpen(true);
                    }
                    if(memberMic) {
                        that.publishAudio();
                    }
                } else if(!soundPlayed) {
                    that.playAudio();
                }
            },
            //     if(isFirst && !that.roomStore.audioPlayed) {
            //         isFirst = false;
            //         that.roomStore.setAudioPlayDialogOpen(true);
            //     }
            // },
            onPublishError: () => {
                console.log(LogPrefix, 'RoomAudio onPublishError');

                that.publishStore.setAudioState(VideoState.PublishError);
            }
        });
    }

    _videoHandler = (videoMode) => {
        console.log(LogPrefix, 'VideoHandler : ' + videoMode);

        let changing = false;
        const that = this;
        return ({
            onPublishing: () => {
                console.log(LogPrefix, 'onPublishing', videoMode);
                that._setVideoState(videoMode, VideoState.Publishing)
            },
            onPublished: (feed) => {
                console.log(LogPrefix, 'onPublished', videoMode, feed);
                that._setVideoState(videoMode, VideoState.Published);
                that._setVideoFeed(videoMode, feed);

                const {role} = that.roomStore;
                if(role === 'publisher') {
                    that.sendAnnounce();
                } else {
                    that.sendPublishCompleteControlMessage();
                }
            },
            onLocalStreamAttached: (stream) => {
                console.log(LogPrefix, 'onLocalStreamAttached', videoMode, stream);

                const {soundPlayed} = that.publishStore;
                that._setMediaElementMuted(!soundPlayed);
            },
            onPublishError: (error) => {
                console.log(LogPrefix, 'onPublishError', videoMode, error);
                that._setVideoState(videoMode, VideoState.PublishError)
            },
            onUnpublishing: () => {
                console.log(LogPrefix, 'onUnpublishing', videoMode);

                that._setVideoState(videoMode, VideoState.Unpublishing);
            },
            onUnpublished: () => {
                console.log(LogPrefix, 'onUnpublished', videoMode);
                that._setVideoState(videoMode, VideoState.None);
                that._setVideoFeed(videoMode, '');
                that._setVideoStream(videoMode, undefined);
                that._setVideoSubstream(videoMode, -1);
                that._setVideoSubstreamLock(videoMode, -1);

                const {role} = that.roomStore;
                if(role === 'publisher') {
                    that.sendAnnounce();
                }
            },
            onSubscribing: (feed) => {
                console.log(LogPrefix, 'onSubscribing', videoMode, feed);
                that._setVideoState(videoMode, VideoState.Subscribing);
                that._setVideoFeed(videoMode, feed);
                that._setVideoSubstream(videoMode, -1);
                that._setVideoSubstreamLock(videoMode, -1);
            },
            onRemoteStreamAttached: (feed) => {
                console.log(LogPrefix, 'onRemoteStreamAttached', videoMode, feed);

                const {soundPlayed} = that.publishStore;
                that._setMediaElementMuted(!soundPlayed);
                that._setVideoState(videoMode, VideoState.StreamAttached);
                that._setVideoFeed(videoMode, feed);

                const {role} = that.roomStore;
                if(role === 'publisher') {
                    that.sendAnnounce();
                }
            },
            onRemoteStreamEmpty: (feed) => {
                console.warn(LogPrefix, 'onRemoteStreamEmpty', videoMode, feed);

                const videoState = videoMode === VideoMode.Primary ? that.publishStore.primaryState : that.publishStore.secondaryState;
                const videoStream = videoMode === VideoMode.Primary ? that.publishStore.primaryStream : that.publishStore.secondaryStream;
                if (videoState === VideoState.Subscribed || videoState === VideoState.StreamEmpty) {
                    changing = true;
                    that.unsubscribe(videoMode, {
                        onSuccess: () => {
                            console.warn(LogPrefix, `Unsubscribe ${videoMode} success. Retry subscribe...`);

                            const video = videoMode === VideoMode.Primary ? that.primaryVideo : that.secondaryVideo;
                            setTimeout(() => {
                                that._setVideoState(videoMode, VideoState.Subscribing);
                                that._setVideoFeed(videoMode, feed);
                                that._setVideoStream(videoMode, videoStream);
                                video.subscribe(feed);
                            }, 1000);
                        },
                        onError: () => {
                            console.warn(LogPrefix, `Unsubscribe ${videoMode} error`);

                            that._setVideoState(videoMode, VideoState.StreamEmpty);
                            that._setVideoFeed(videoMode, feed);
                            that._setVideoStream(videoMode, videoStream);
                            that._setVideoSubstream(videoMode, -1);
                            that._setVideoSubstreamLock(videoMode, -1);
                        }
                    });
                } else {
                    console.warn(LogPrefix, `StreamEmpty ${videoMode} event. but no operating... already operating... maybe...`);
                }
            },
            onStarted: () => {
                console.log(LogPrefix, 'onStarted', videoMode);
            },
            onSubscribed: (feed) => {
                console.log(LogPrefix, 'onSubscribed', videoMode, feed);

                const {soundPlayed} = that.publishStore;
                that._setMediaElementMuted(!soundPlayed);

                const videoState = videoMode === VideoMode.Primary ? that.publishStore.primaryState : that.publishStore.secondaryState;
                if(videoState !== VideoState.Subscribed) {
                    that._setVideoState(videoMode, VideoState.Subscribed);
                    that._setVideoFeed(videoMode, feed);

                    const {role} = that.roomStore;
                    if (role === 'publisher') {
                        that.sendAnnounce();
                    }
                }
            },
            onSubscribeError: (error) => {
                console.log(LogPrefix, 'onSubscribeError', videoMode, error);
                that._setVideoState(videoMode, VideoState.SubscribeError);
            },
            onUnsubscribing: () => {
                console.log(LogPrefix, 'onUnsubscribing', videoMode);

                that._setVideoState(videoMode, VideoState.Unsubscribing);
            },
            onUnsubscribed: () => {
                console.log(LogPrefix, 'onUnsubscribed', videoMode);
                const {role} = that.roomStore;
                const {primaryState} = that.publishStore;

                console.log(LogPrefix, 'onUnsubscribed : primaryState=', primaryState);

                that._setVideoState(videoMode, VideoState.None);
                that._setVideoFeed(videoMode, '');
                that._setVideoStream(videoMode, undefined);
                that._setVideoSubstream(videoMode, -1);
                that._setVideoSubstreamLock(videoMode, -1);

                if(changing) {
                    changing = false;
                } else if (role === 'publisher') {
                    that.sendAnnounce();
                }
            },
            onSubstreamChanged: (substream) => {
                console.log(LogPrefix, 'onSubstreamChanged', videoMode, substream);

                const {primaryHighQuality, primarySubstreamLock, primarySubstream} = that.publishStore;
                if(primaryHighQuality && (primarySubstream < 0)) {
                    setTimeout(() => that.setSubstream(videoMode, 2), 1000);
                }

                if((primaryHighQuality) && (videoMode === VideoMode.Primary)) {
                    if(substream > primarySubstream) {
                        that.publishStore.setPrimarySubstream(substream);
                        that.publishStore.setPrimarySubstreamLock(substream);
                    } else if(substream < primarySubstream) {
                        that.publishStore.setPrimarySubstream(substream);
                        setTimeout(() => that.setSubstream(VideoMode.Primary, primarySubstreamLock), 1000);
                    } else {
                        //Do nothing.
                        //that.roomStore.setPrimarySubstream(substream);
                    }
                }
            }
        });
    }

    _lookAroundHandler = (videoMode) => {
        console.log(LogPrefix, 'VideoHandler : ' + videoMode);

        const that = this;
        return ({
            onPublishing: () => {
                console.log(LogPrefix, 'onPublishing', videoMode);

                that.publishStore.setLookAroundPublishState(VideoState.Publishing);
            },
            onPublished: (feed) => {
                console.log(LogPrefix, 'onPublished', videoMode, feed);

                that.publishStore.setLookAroundPublishState(VideoState.Published);
            },
            onLocalStreamAttached: (stream) => {
                console.log(LogPrefix, 'onLocalStreamAttached', videoMode, stream);
            },
            onPublishError: (error) => {
                console.log(LogPrefix, 'onPublishError', videoMode, error);

                that.publishStore.setLookAroundPublishState(VideoState.PublishError);
            },
            onUnpublishing: () => {
                console.log(LogPrefix, 'onUnpublishing', videoMode);
            },
            onUnpublished: () => {
                console.log(LogPrefix, 'onUnpublished', videoMode);

                that.publishStore.setLookAroundPublishState(VideoState.None);
            },
            onSubscribing: (feed) => {
                console.log(LogPrefix, 'onSubscribing', videoMode, feed);
            },
            onRemoteStreamAttached: (feed) => {
                console.log(LogPrefix, 'onRemoteStreamAttached', videoMode, feed);
            },
            onRemoteStreamEmpty: (feed) => {
                console.warn(LogPrefix, 'onRemoteStreamEmpty', videoMode, feed);
            },
            onStarted: () => {
                console.log(LogPrefix, 'onStarted', videoMode);
            },
            onSubscribed: (feed) => {
                console.log(LogPrefix, 'onSubscribed', videoMode, feed);
            },
            onSubscribeError: (error) => {
                console.log(LogPrefix, 'onSubscribeError', videoMode, error);
            },
            onUnsubscribing: () => {
                console.log(LogPrefix, 'onUnsubscribing', videoMode);
            },
            onUnsubscribed: () => {
                console.log(LogPrefix, 'onUnsubscribed', videoMode);
            },
            onSubstreamChanged: (substream) => {
                console.log(LogPrefix, 'onSubstreamChanged', videoMode, substream);
            }
        });
    }

    attachAudio = () => {
        const {roomId, userId} = this.roomStore;
        const {audioState} = this.publishStore;
        console.log(LogPrefix, 'Attach to audio', audioState);
        if(audioState === VideoState.None) {
            console.log(LogPrefix, 'Attach to audio', audioState);

            this.audio.attachPlugin(roomId, userId);
        } else {
            console.warn(LogPrefix, 'Attach to audio ignored : Invalid state ', audioState);
        }

    }

    startXVideo = () => {
        const {roomId} = this.roomStore;

        console.log(LogPrefix, 'Starting XVideo');
        axios.post(`/api/v1/pagingxvideos/${roomId}`)
            .then(response => {
                console.log("XVideo start success");
            })
            .catch(error => {
                console.log("XVideo start error", error);
            });
    }

    endXVideo = () => {
        const {roomId} = this.roomStore;

        console.log(LogPrefix, 'Ending XVideo');
        axios.delete(`/api/v1/pagingxvideos/${roomId}`)
            .then(response => {
                console.log("XVideo end success");
            })
            .catch(error => {
                console.log("XVideo end error", error);
            });
    }

    release = () => {
        const { primaryState, secondaryState } = this.publishStore;

        if(primaryState === VideoState.Published) {
            this.unpublish(VideoMode.Primary, {
                onSuccess: () => {
                    this.primaryVideo.leave();
                }
            });
        }
        else if(primaryState === VideoState.Subscribed) {
            this.unsubscribe(VideoMode.Primary);
            // this.endXVideo();
        }

        if(secondaryState === VideoState.Published) {
            this.unpublish(VideoMode.Secondary, {
                onSuccess: () => {
                    this.secondaryVideo.leave();
                }
            });
        } else if(secondaryState === VideoState.Subscribed) {
            this.unsubscribe(VideoMode.Secondary);
        }

        if(this.audio) {
            this.stopAudio();
            this.unpublishAudio();

            this.audio.leave();
        }
    }

    startTinyGroup = () => {
        const { roomId, userId, displayName } = this.roomStore;

        this.release();

        setTimeout(() => {
            console.log(LogPrefix, 'SaveTinGroups');
            this.tinyGroupStore.saveTinyGroups(roomId, this.janus, userId, displayName, {
                sendMessage: () => {
                    console.log(LogPrefix, 'SendTinyGroupRequestMessage');
                    this.sendTinyGroupRequestMessage(true);
                }
            });
        }, 3000);
    }

    leave = () => {
        this.chat.leave();
    }

    kickAndJoin = () => {
        this.chat.kickAndJoin();
    }

    sendChatMessage = (msg) => {
        this.chat.sendMessage(MessageType.Message, msg, undefined, undefined, false);
    }

    sendChatWhisperMessage = (msg, to) => {
        this.chat.sendMessage(MessageType.Whisper, msg, to, {onSuccess: () => this.roomStore.addChatMessage({ type: MessageType.Whisper, msg: msg, from: this.roomStore.userId})});
    }

    sendChatLink = (link) => {
        this.chat.sendMessage(MessageType.Link, link);
    }

    sendAnnounce = (to, callbacks) => {
        const announce = this._getCurrentState();

        console.log(LogPrefix, 'SendAnnounce', announce, to);
        if(to) {
            this.chat.sendMessage(MessageType.Announce, {announce: announce}, to, callbacks);
        } else {
            this.chat.sendAnnounce(announce, callbacks);
        }
    }

    sendRequestAnnounce = () => {
        const {roomUserId} = this.roomStore;

        this.chat.sendMessage(MessageType.RequestAnnounce, {}, roomUserId);
    }

    _sendNotifySound = (soundOn) => {
        const {chatState, roomUserId, userId} = this.roomStore;

        if(this.chat && chatState === ChatState.Connected) {
            this.chat.sendMessage(MessageType.NotifySound, {
                id: userId,
                soundOn: soundOn,
            }, roomUserId);
        }
    }

    _sendNotifyMedia = () => {
        const {chatState, roomUserId, userId} = this.roomStore;

        const {videoStreams} = this.publishStore;

        if(this.chat && chatState === ChatState.Connected) {
            const deviceBrowserTypes = Params.getDeviceBrowserType();

            this.chat.sendMessage(MessageType.NotifyMedia, {
                id: userId,
                mediaOn: videoStreams && videoStreams.length > 0 ? true : false,
                deviceType: deviceBrowserTypes.deviceType,
                browserType: deviceBrowserTypes.browserType,
            }, roomUserId);
        }
    }

    sendRequestPublishControlMessage = (data) => {
        const {roomUserId} = this.roomStore;

        this.chat.sendMessage(MessageType.RequestPublish, {data: data}, roomUserId);
    }

    sendRequestPresentationPublishControlMessage = (userId, data) => {
        this.chat.sendMessage(MessageType.RequestPublish, {data: data}, userId);
    }

    sendConfirmPublishControlMessage = (userId) => {
        const that = this;
        const {primaryState} = this.publishStore;
        if(primaryState === VideoState.Published) {
            this.unpublish(VideoMode.Primary, {
                onSuccess: () => {
                    setTimeout(() => that.chat.sendMessage(MessageType.ConfirmPublish, {}, userId), 1000);
                }
            });
        } else {
            this.chat.sendMessage(MessageType.ConfirmPublish, {}, userId);
        }
    }

    sendPublishCompleteControlMessage = () => {
        const {roomUserId} = this.roomStore;

        this.chat.sendMessage(MessageType.PublishComplete, {}, roomUserId);
    }

    sendPublishStoppedControlMessage = () => {
        const {roomUserId} = this.roomStore;

        this.chat.sendMessage(MessageType.PublishStopped, {}, roomUserId);
    }

    sendAttendanceControlMessage = (userId, data) => {
        this.roomStore.removeRequestPublish(userId);
        this.chat.sendMessage(MessageType.Attendance, {data: data});
    }

    sendAttendanceLateControlMessage = (userId, toUserId) => {
        this.roomStore.removeRequestPublish(userId);
        this.chat.sendMessage(MessageType.AttendanceLate, {}, toUserId);
    }

    sendQuizControlMessage = (userId, data) => {
        this.roomStore.removeRequestPublish(userId);
        this.chat.sendMessage(MessageType.Quiz, {data: data});
    }

    sendStartPollControlMessage = (pollId) => {
        this.chat.sendMessage(MessageType.StartPoll, {pollId: pollId});
        this.roomStore.setSelectedChatTab(ChatTabIndex.Poll);
    }

    sendPollResponseMessage = (pollId) => {
        this.chat.sendMessage(MessageType.PollResponse, {pollId: pollId});
        this.roomStore.setSelectedChatTab(ChatTabIndex.Poll);
    }

    sendEndPollControlMessage = (pollId) => {
        this.chat.sendMessage(MessageType.EndPoll, {pollId: pollId});
    }

    sendWhiteBoardMessage = (to) => {
        if(this.chat) {
            const {visible} = this.whiteBoardStore;
            this.chat.sendMessage(MessageType.WhiteBoardData, {visible}, to);
        }
    }

    sendTinyGroupRequestMessage = (isStart) => {
        if(this.chat) {
            const participateOwnerGroupId = this.tinyGroupStore.tinyGroup.id;
            const targetUserIds = [];
            this.tinyGroupStore.tinyGroups.forEach(tg => tg.members.map(m => String(m.id)).forEach((id => targetUserIds.push(id))))
            this.chat.sendMessage(MessageType.RequestTinyGroup, { request: isStart, ownerGroupId: participateOwnerGroupId, userIds: targetUserIds});
        }
    }

    sendTinyGroupChangeOwnerParticipationGroupMessage = () => {
        if(this.chat) {
            const participateOwnerGroupId = this.tinyGroupStore.tinyGroup.id;
            this.chat.sendMessage(MessageType.ChangeParticipateTinyGroup, { ownerGroupId: participateOwnerGroupId });
        }
    }

    sendTinyGroupNotifyMessage = () => {
        if(this.chat) {
            this.chat.sendMessage(MessageType.NotifyTinyGroup, { notifyMessage: this.tinyGroupStore.notifyMessage })
        }
    }

    sendCallOwner = () => {
        console.log(LogPrefix, 'SendCallOwner');

        if(this.chat) {
            this.chat.sendMessage(MessageType.CallOwnerTinyGroup, { groupId: this.tinyGroupStore.tinyGroup.id }, this.roomStore.roomUserId);
        } else {
            console.warn("SendCallOwner error : chat is empty");
        }
    }



    openPublishDialog = (videoMode) => {
        // const that = this;
        window.Janus.listDevices((devices) => {
            console.log(LogPrefix, 'Device detected', devices);

            const videoDevices = _.filter(devices, (d) => d.kind === 'videoinput');

            this.roomStore.setPublishOptionDialogOpen(true, videoMode, videoDevices);
        });
    }

    closePublishDialog = () => {
        this.roomStore.setPublishOptionDialogOpen(false);
    }

    _logMediaStreamTrack = (mediaStream) => {
        mediaStream.getTracks().forEach((track) => {
            console.log(LogPrefix, 'Track', track);
            console.log(LogPrefix, "Track's constraints", track.getConstraints());
            console.log(LogPrefix, "Track's settings", track.getSettings());
        });
    }

    changeMemberMic = () => {
        const {memberMic} = this.publishStore;

        if(memberMic) {
            this.publishStore.setMemberMic(false);
            this.sendAnnounce();
        } else {
            this.publishStore.setMemberMic(true);
            this.sendAnnounce();
        }
    }

    changeAudioDevice = (device) => {
        this.publishStore.setAudioDevice(device);

        const audio = device !== undefined && device !== null ? {
            deviceId: device.deviceId,
            channelCount: 1,
            echoCancellation: true,
            noiseSuppression: true,
        } : undefined;

        this.audio.publish(audio);
    }

    monitoringPublish = () => {
        console.log(LogPrefix, 'Publishing to monitoring...');
        const {videoStreams} = this.publishStore;

        if(videoStreams && videoStreams.length > 0 ) {
            const stream = toJS(videoStreams[0]);
            const clonedStream = stream.stream.clone();
            stream.clonedStream = clonedStream;

            const {monitoringState} = this.publishStore;

            if (monitoringState === VideoState.Published) {
                this.publishStore.unpublish({
                    onSuccess: () => {
                        setTimeout(() => {
                            this.publishStore.setMonitoringStream(stream);
                            this.publishStore.connect(this.config.roomId, this.config.userId, this.config.displayName, stream);
                            console.log(LogPrefix, 'unpublish after success monitoring publish',this.publishStore.monitoringStream);
                        }, 1000);
                    }
                });
            } else {
                console.log("!!!", stream);
                this.publishStore.setMonitoringStream(stream);
                this.publishStore.connect(this.config.roomId, this.config.userId, this.config.displayName, stream);
                console.log(LogPrefix, 'success monitoring publish',this.publishStore.monitoringStream);
            }
        } else {
            console.log(LogPrefix, 'No video stream to publish');
        }
    }


    addLocalVideoStream = (deviceId) => {
        console.log(LogPrefix, 'AddLocalVideoStream', deviceId);
        if(navigator.mediaDevices && navigator.mediaDevices.getSupportedConstraints) {
            const supportedConsts = navigator.mediaDevices.getSupportedConstraints();
            console.log(LogPrefix, 'GetUserMedia supported constraints', supportedConsts);
        }

        const that = this;
        if(!deviceId) {
            navigator.mediaDevices.getUserMedia({audio: false, video: {width: CameraWidth, height: CameraHeight}})
                .then(mediaStream => {
                    console.log(LogPrefix, 'Get UserMedia success', mediaStream);
                    that._logMediaStreamTrack(mediaStream);

                    that.publishStore.addVideoStream({type: StreamType.LocalCamera, streamId: mediaStream.id, deviceId: undefined, stream: mediaStream});
                })
                .catch((error) => {
                    console.log(LogPrefix, 'Get UserMedia error', error);

                    navigator.mediaDevices.getUserMedia({audio: false, video: true})
                        .then(mediaStream => {
                            console.log(LogPrefix, "Get UserMedia success without constraint", mediaStream);

                            that._logMediaStreamTrack(mediaStream);

                            that.publishStore.addVideoStream({type: StreamType.LocalCamera, streamId: mediaStream.id, deviceId: undefined, stream: mediaStream});
                        })
                        .catch(error => {
                            console.warn("Get UserMedia error without constraint", error);
                        });
                });
        } else if(deviceId === 'lookaround') {
            const {roomId} = that.roomStore;

            //that._startXTream();
            axios.get(`/api/v1/xvideos/${roomId}/x-param`)
                .then(response => {
                    const param = response.data;

                    that.publishStore.addVideoStream({type: StreamType.Remote, streamId: param.xpublishId, deviceId: deviceId, stream: undefined});
                })
                .catch(error => {
                    console.warn("Can't get a xvideo parameter", error);
                });

        } else if(deviceId === 'screen') {
            console.log(LogPrefix, 'Getting DisplayMedia...', deviceId);
            if(navigator.mediaDevices.getDisplayMedia) {
                navigator.mediaDevices.getDisplayMedia({audio: true, video: {width: ScreenWidth, height: ScreenHeight}})
                    .then(mediaStream => {
                        console.log(LogPrefix, 'Get DisplayMedia success', mediaStream);
                        that._logMediaStreamTrack(mediaStream);

                        that.publishStore.addVideoStream({type: StreamType.LocalScreen, streamId: mediaStream.id, deviceId: deviceId, stream: mediaStream});
                    })
                    .catch(error => {
                        console.warn(LogPrefix, 'Get DisplayMedia error', error);
                    });
            } else {
                console.warn(LogPrefix, 'GetDisplayMedia not supported');
            }
        } else {
            console.log(LogPrefix, 'Getting UserMedia...', deviceId);
            navigator.mediaDevices.getUserMedia({audio: false, video: {deviceId: deviceId, width: CameraWidth, height: CameraHeight}})
                .then(mediaStream => {
                    console.log(LogPrefix, 'Get UserMedia success', mediaStream);
                    that._logMediaStreamTrack(mediaStream);

                    that.publishStore.addVideoStream({type: StreamType.LocalCamera, streamId: mediaStream.id, deviceId: deviceId, stream: mediaStream});
                })
                .catch((error) => {
                    console.log(LogPrefix, 'Get UserMedia error', error);

                    navigator.mediaDevices.getUserMedia({audio: false, video: {deviceId: deviceId}})
                        .then(mediaStream => {
                            console.log(LogPrefix, 'Get UserMedia success without constraints', mediaStream);
                            that._logMediaStreamTrack(mediaStream);

                            that.publishStore.addVideoStream({type: StreamType.LocalCamera, streamId: mediaStream.id, deviceId: deviceId, stream: mediaStream});
                        })
                        .catch((error) => {
                            console.warn(LogPrefix, 'Get UserMedia error without constraints', error);
                        });
                });
        }
    }

    unpublishLookAround = () => {
        console.log(LogPrefix, 'Unpublish look around');

        this.lookAroundPublishVideo.unpublish();
    }

    startMemberPublish = () => {
        console.log(LogPrefix, 'Starting MemberPublish');
        const {mode, primaryState, lookAroundPublishState, monitoringState, videoStreams} = this.publishStore;
        const that = this;
        if(this.audio) {
            this.audio.mute(false, {
                success: () => {
                    that.publishStore.setAudioMuted(false);
                }
            });
        }

        if(videoStreams && videoStreams.length > 0) {
            this.roomStore.setMemberPublishDialogOpen(false);

            const stream = videoStreams[0];

            if(mode === RoomMode.LookAround && lookAroundPublishState === VideoState.Published) {
                console.log(LogPrefix, 'Already look around published. so re publish');

                that.lookAroundPublishVideo.unpublish({
                    onSuccess: () => {
                        setTimeout(() => {
                            that.publishStream(VideoMode.Primary, stream.streamId);
                        }, 1000);
                    }
                });
            } else if (primaryState === VideoState.Published) {
                console.log(LogPrefix, 'Aleready published. so re publish');

                this.unpublishForChange(VideoMode.Primary, {
                    onSuccess: () => {
                        setTimeout(() => {
                            that.publishStream(VideoMode.Primary, stream.streamId);
                        }, 1000);
                    }
                });

            } else if (primaryState === VideoState.Subscribed || primaryState === VideoState.StreamAttached || primaryState === VideoState.StreamEmpty) {
                console.log(LogPrefix, 'Unsubscrbing');

                this.unsubscribe(VideoMode.Primary, {
                    onSuccess: () => {
                        setTimeout(() => {
                            that.publishStream(VideoMode.Primary, stream.streamId);
                        }, 1000);
                    }
                });
            } else if (monitoringState === VideoState.Published) {
                console.log(LogPrefix, 'monitoring unpublish ');
                    this.publishStore.unpublish({
                        onSuccess: () => {
                            setTimeout(() => {
                                that.publishStream(VideoMode.Primary, stream.streamId);
                            }, 1000);
                        }
                    });
            } else {
                this.publishStream(VideoMode.Primary, stream.streamId);
            }
        }
    }

    changeVideoStream = (streamId, deviceId) => {
        console.log(LogPrefix, 'ChangeVideoStream', streamId, deviceId);
        if(navigator.mediaDevices && navigator.mediaDevices.getSupportedConstraints) {
            const supportedConsts = navigator.mediaDevices.getSupportedConstraints();
            console.log(LogPrefix, 'GetUserMedia supported constraints', supportedConsts);
        }

        const {mode, primaryState, primaryStream, lookAroundPublishState, lookAroundStream, videoStreams, monitoringState, monitoringStream } = this.publishStore;
        const that = this;

        const changeStream = (mediaStream, deviceId) => {
            const oldStream = _.find(videoStreams, (s) => s.streamId === streamId);
            if(oldStream) {
                that._stopTrackInStream(oldStream.stream);
            }
            that.publishStore.changeVideoStream(streamId, {type:  deviceId === 'screen' ? StreamType.LocalScreen : StreamType.LocalCamera, streamId: mediaStream.id, deviceId: deviceId ? deviceId : undefined, stream: mediaStream});
            that._sendNotifyMedia();
        }
        const addStream = (mediaStream) => {
            that._logMediaStreamTrack(mediaStream);

            that.publishStore.addVideoStream({type: StreamType.LocalCamera, streamId: mediaStream.id, deviceId: deviceId ? deviceId : undefined, stream: mediaStream});
            that._sendNotifyMedia();
        }

        if(!deviceId) {
            console.log(LogPrefix, 'Getting UserMedia...');

            navigator.mediaDevices.getUserMedia({audio: false, video: {width: CameraWidth, height: CameraHeight}})
                .then(mediaStream => {
                    console.log(LogPrefix, 'Get UserMedia success', mediaStream);

                    if(streamId) {
                        changeStream(mediaStream);
                        if(primaryState === VideoState.Published) {
                            that.startMemberPublish();
                        } else if(mode === RoomMode.LookAround) {
                            that.publishLookAround();
                        } else if(monitoringState === VideoState.Published) {
                            that.monitoringPublish();
                        }
                    } else {
                        addStream(mediaStream);
                        if(primaryState === VideoState.Published) {
                            that.startMemberPublish();
                        } else if(mode === RoomMode.LookAround) {
                            that.publishLookAround();
                        }else if (monitoringState === VideoState.Published) {
                            that.monitoringPublish();
                        }
                    }
                })
                .catch((error) => {
                    console.log(LogPrefix, 'Get UserMedia error', error);

                    navigator.mediaDevices.getUserMedia({audio: false, video: true})
                        .then(mediaStream => {
                            console.log(LogPrefix, 'Get UserMedia success without constraints', mediaStream);

                            if(streamId) {
                                changeStream(mediaStream);
                                if(primaryState === VideoState.Published) {
                                    that.startMemberPublish();
                                } else if(mode === RoomMode.LookAround) {
                                    that.publishLookAround();
                                } else if(monitoringState === VideoState.Published) {
                                    that.monitoringPublish();
                                }
                            } else {
                                addStream(mediaStream);
                                if(primaryState === VideoState.Published) {
                                    that.startMemberPublish();
                                } else if(mode === RoomMode.LookAround) {
                                    that.publishLookAround();
                                } else if (monitoringState === VideoState.Published) {
                                    that.monitoringPublish();
                                }
                            }
                        })
                        .catch((error) => {
                            console.warn(LogPrefix, 'Get UserMedia error without constraints', error);
                        });
                });
        } else if(deviceId === 'screen') {
            console.log(LogPrefix, 'Getting DisplayMedia...', deviceId);
            if(navigator.mediaDevices.getDisplayMedia) {
                navigator.mediaDevices.getDisplayMedia({audio: true, video: {width: ScreenWidth, height: ScreenHeight}})
                    .then(mediaStream => {
                        console.log(LogPrefix, 'Get DisplayMedia success', mediaStream);

                        if(streamId) {
                            changeStream(mediaStream, deviceId);
                            if(primaryState === VideoState.Published) {
                                that.startMemberPublish();
                            } else if(mode === RoomMode.LookAround) {
                                that.publishLookAround();
                            } else if(monitoringState === VideoState.Published) {
                                that.monitoringPublish();
                            }
                        } else {
                            addStream(mediaStream, deviceId);
                            if(primaryState === VideoState.Published) {
                                that.startMemberPublish();
                            } else if(mode === RoomMode.LookAround) {
                                that.publishLookAround();
                            } else if (monitoringState === VideoState.Published) {
                                that.monitoringPublish();
                            }
                        }
                    })
                    .catch(error => {
                        console.log(LogPrefix, 'Get DisplayMedia error', error);
                    });
            } else {
                console.log(LogPrefix, 'GetDisplayMedia not supported');
            }
        } else {
            console.log(LogPrefix, 'Getting UserMedia...', deviceId);
            navigator.mediaDevices.getUserMedia({audio: false, video: {deviceId: deviceId, width: CameraWidth, height: CameraHeight}})
                .then(mediaStream => {
                    console.log(LogPrefix, 'Get UserMedia success', mediaStream);

                    if(streamId) {
                        changeStream(mediaStream, deviceId);
                        if(primaryState === VideoState.Published) {
                            that.startMemberPublish();
                        } else if(mode === RoomMode.LookAround) {
                            that.publishLookAround();
                        } else if(monitoringState === VideoState.Published) {
                            that.monitoringPublish();
                        }
                    } else {
                        addStream(mediaStream, deviceId);
                        if(primaryState === VideoState.Published) {
                            that.startMemberPublish();
                        } else if(mode === RoomMode.LookAround) {
                            that.publishLookAround();
                        } else if (monitoringState === VideoState.Published) {
                            that.monitoringPublish();
                        }
                    }
                })
                .catch((error) => {
                    console.log(LogPrefix, 'Get UserMedia error', error);
                    // GetUserMedia 호출 전 미리 이전 스트림 종료... for Android Chrome
                    const oldStream = _.find(videoStreams, (s) => s.streamId === streamId);
                    if(oldStream) {
                        that._stopTrackInStream(oldStream.stream);
                    }
                    if(primaryState === VideoState.Published && primaryStream && primaryStream.clonedStream) {
                        console.log(LogPrefix, 'Stopping primary video', primaryState, toJS(primaryStream));
                        that._stopTrackInStream(primaryStream.clonedStream);
                    } else {
                        console.log(LogPrefix, 'PrimaryState is not published', primaryState, toJS(primaryStream));
                    }
                    if(lookAroundPublishState === VideoState.Published && lookAroundStream && lookAroundStream.clonedStream) {
                        console.log(LogPrefix, 'Stopping look around video', lookAroundPublishState, toJS(lookAroundStream));
                        that._stopTrackInStream(lookAroundStream.clonedStream);
                    } else {
                        console.log(LogPrefix, 'LookAroundPublishState is not published', lookAroundPublishState, toJS(lookAroundStream));
                    }
                    if(monitoringState === VideoState.Published && monitoringStream && monitoringStream.clonedStream) {
                        console.log(LogPrefix, 'Stopping primary video', monitoringState, toJS(monitoringStream));
                        that._stopTrackInStream(monitoringStream.clonedStream);
                    } else {
                        console.log(LogPrefix, 'videoState is not published', monitoringState, toJS(monitoringStream));
                    }


                    // Constraints 없이 GetUserMedia 호출... for Android Chrome
                    navigator.mediaDevices.getUserMedia({audio: false, video: {deviceId: deviceId}})
                        .then(mediaStream => {
                            console.log(LogPrefix, 'Get UserMedia success without constraints', mediaStream);

                            if(streamId) {
                                changeStream(mediaStream, deviceId);
                                if(primaryState === VideoState.Published) {
                                    that.startMemberPublish();
                                } else if(mode === RoomMode.LookAround) {
                                    that.publishLookAround();
                                } else if (monitoringState === VideoState.Published) {
                                    that.monitoringPublish();
                                }
                            } else {
                                addStream(mediaStream, deviceId);
                                if(primaryState === VideoState.Published) {
                                    that.startMemberPublish();
                                } else if(mode === RoomMode.LookAround) {
                                    that.publishLookAround();
                                } else if (monitoringState === VideoState.Published) {
                                    that.monitoringPublish();
                                }
                            }
                        })
                        .catch((error) => {
                            console.warn(LogPrefix, 'Get UserMedia error without constraints', error);
                        });
                });
        }
    }

    removeVideoStream = (streamId) => {

        this.publishStore.removeVideoStream(streamId);

        console.log(LogPrefix, "Checking exists lookaround stream...");
        const {videoStreams} = this.publishStore;
        const removeStream = _.find(videoStreams, (stream) => stream.type === StreamType.Remote);
        if(removeStream) {
            console.log(LogPrefix, "Exists lookaround stream");
        } else {
            console.log(LogPrefix, "Not exists lookaround stream");
            //this._endXTream();
        }
    }

    _stopTrackInStream = (stream) => {
        if(stream) {
            stream.getTracks().forEach((track) => track.stop());
        }
    }

    _setStreamResolution = (stream, videoMode, type) => {
        let constraints = {};

        if(videoMode === VideoMode.Primary) {
            if(type === StreamType.LocalScreen) {
                constraints = {width: ScreenWidth, height: ScreenHeight};
            } else {
                constraints = {width: CameraWidth, height: CameraHeight};
            }
        } else if(videoMode === VideoMode.Secondary) {
            constraints = {width: SmallWidth, height: SmallHeight};
        } else if(videoMode === VideoMode.LookAround) {
            constraints = {width: LookAroundWidth, height: LookAroundHeight};
        }

        const videoTracks = stream.getVideoTracks();
        for(let i in videoTracks) {
            const track = videoTracks[i];
            track.applyConstraints(constraints)
                .then(() => {
                    console.log(LogPrefix, `Video Constraints apply success : ${stream.id}`, videoMode, constraints);
                })
                .catch(() => {
                    console.log(LogPrefix, `Video Constraints apply error : ${stream.id}`, videoMode, constraints);
                });
        }
    }

    _setRoomMode = (roomMode) => {
        this.publishStore.setMode(roomMode);
    }

    _setRoomModeByStream = () => {
        const {primaryStream, secondaryStream} = this.publishStore;

        if(primaryStream && primaryStream.type === StreamType.Remote) {
            this.publishStore.setMode(RoomMode.LookAround);
        } else if(secondaryStream && secondaryStream.type === StreamType.Remote) {
            this.publishStore.setMode(RoomMode.LookAround);
        } else {
            this.publishStore.setMode(RoomMode.Normal);
        }
    }

    publishAudio = () => {
        const {audioDevice} = this.publishStore;

        if(audioDevice) {
            const that = this;
            this.audio.mute(false, {
                success: () => {
                    that.publishStore.setAudioMuted(false);
                }
            });
        }
    }

    publishLookAround = () => {
        console.log(LogPrefix, 'Publish look around');
        const {userId,  channel} = this.roomStore;

        const {primaryState, lookAroundPublishState} = this.publishStore;

        const maxRetryCount = 30;
        let retryCount = 0;

        const publish = () => {
            const {videoStreams} = this.publishStore;

            if (videoStreams && videoStreams.length > 0) {
                const stream = toJS(videoStreams[0]);
                const that = this;

                console.log(LogPrefix, 'publishLookAround', toJS(stream));

                if (lookAroundPublishState === VideoState.Published) {
                    this.lookAroundPublishVideo.unpublish({
                        onSuccess: () => {
                            setTimeout(() => {
                                const clonedStream = stream.stream.clone();
                                stream.clonedStream = clonedStream;

                                that._setStreamResolution(clonedStream, VideoMode.LookAround, StreamType.Remote);
                                that._setVideoStream(VideoMode.LookAround, stream);
                                that.lookAroundPublishVideo.publish(userId, 512 * 1000, false, false, undefined, clonedStream);
                            }, 1000);
                        }
                    });
                } else {
                    setTimeout(() => {
                        const clonedStream = stream.stream.clone();
                        stream.clonedStream = clonedStream;

                        that._setStreamResolution(clonedStream, VideoMode.LookAround, StreamType.Remote);
                        that._setVideoStream(VideoMode.LookAround, stream);
                        that.lookAroundPublishVideo.publish(userId, 512 * 1000, false, false, undefined, clonedStream);
                    }, 1000);
                }
            } else {
                if(retryCount < maxRetryCount) {
                    retryCount++;
                    setTimeout(() => publish(), 1000);
                }
            }
        }

        if(primaryState !== VideoState.Published) {
            publish();
        }
    }

    publishStream = (videoMode, streamId) => {
        console.log(LogPrefix, 'Publish stream', videoMode);
        const {roomId, userId} = this.roomStore;
        const {videoStreams} = this.publishStore;
        const stream = streamId ? toJS(_.find(videoStreams, (stream) => stream.streamId === streamId)) : undefined;

        const that = this;
        const publish = () => {
            if(stream.type === StreamType.LocalCamera || stream.type === StreamType.LocalScreen) {
                const id = videoMode === VideoMode.Primary ? userId : roomId;
                const bitrate = videoMode === VideoMode.Primary ? (1024 * 1024) : (128 * 1024);
                const videoHandle = videoMode === VideoMode.Primary ? that.primaryVideo : that.secondaryVideo;

                const clonedStream = stream.stream.clone();
                stream.clonedStream = clonedStream;

                that._setStreamResolution(clonedStream, videoMode, stream.type);
                that._setVideoStream(videoMode, stream);

                that._setRoomModeByStream();
                videoHandle.publish(id, bitrate, stream.type === StreamType.LocalCamera, false, undefined, clonedStream);
                videoHandle.publish(id, bitrate, stream.type === StreamType.LocalCamera, false, undefined, clonedStream);
            } else if(stream.type === StreamType.Remote) {
                that._setVideoStream(videoMode, stream);
                that._setRoomMode(RoomMode.LookAround);
                that.subscribeVideo(videoMode, true, stream.streamId);
            }
        }

        if(videoMode === VideoMode.Primary) {
            const {primaryState} = this.publishStore;
            if(primaryState === VideoState.Published) {
                this.unpublish(videoMode, {
                    onSuccess: () => {
                        setTimeout(() => {
                            publish();
                        }, 1000);
                    }
                })
            } else if (primaryState === VideoState.Subscribed) {
                this.unsubscribe(videoMode, {
                    onSuccess: () => {
                        setTimeout(() => {
                            publish();
                        }, 1000);
                    }
                })
            } else {
                publish();
            }
        } else if(videoMode === VideoMode.Secondary) {
            const {secondaryState} = this.publishStore;
            if(secondaryState === VideoState.Published) {
                this.unpublish(videoMode, {
                    onSuccess: () => {
                        setTimeout(() => {
                            publish();
                        }, 1000);
                    }
                });
            } else if(secondaryState === VideoState.Subscribed) {
                this.unsubscribe(videoMode, {
                    onSuccess: () => {
                        setTimeout(() => {
                            publish();
                        }, 1000);
                    }
                })
            } else {
                publish();
            }
        }
    }

    stopMemberPublish = (memberId) => {
        this.roomStore.setMemberPublishState(memberId, MemberPublishState.None);

        const {primaryState} = this.publishStore;
        const that = this;

        if(primaryState === VideoState.Subscribed || primaryState === VideoState.StreamEmpty) {
            this.unsubscribe(VideoMode.Primary, {
                onSuccess: () => {
                    setTimeout(() => that.sendAnnounce(), 1000);
                }
            });
        }
    }

    unpublishAudio = () => {
        const that = this;

        this.audio.mute(true, {
            success: () => {
                that.publishStore.setAudioMuted(true);
            }
        });
    }

    unpublishForChange = (videoMode, callbacks) => {
        console.log(LogPrefix, 'UnpublishForChange', videoMode);

        const {role} = this.roomStore;
        const {mode} = this.publishStore;
        const video = videoMode === VideoMode.Primary ? this.primaryVideo : this.secondaryVideo;
        video.unpublish(callbacks);

        if(role === 'subscriber') {
            if(mode === RoomMode.LookAround) {
                this.publishLookAround();
            }
        }
    }

    unpublish = (videoMode, callbacks) => {
        console.log(LogPrefix, 'Unpublish', videoMode);

        const {role} = this.roomStore;
        const {mode, monitoringState} = this.publishStore;
        const video = videoMode === VideoMode.Primary ? this.primaryVideo : this.secondaryVideo;
        video.unpublish(callbacks);
        const that = this;
        if(role === 'subscriber') {
            if(this.audio) {
                this.audio.mute(true, {
                    success: () => {
                        that.publishStore.setAudioMuted(true);
                    }
                });
            }

            this.sendPublishStoppedControlMessage();

            if(mode === RoomMode.LookAround) {
                this.publishLookAround();
            }
            if(monitoringState === VideoState.Published) {
                setTimeout(() => this.monitoringPublish(), 2000);
            }
        }
    }

    closeMemberPublishDialog = () => {
        this.roomStore.setMemberPublishDialogOpen(false);
    }

    subscribeVideo = (videoMode, isStream, feed, callbacks) => {
        const {role} = this.roomStore;
        const video = videoMode === VideoMode.Primary ? this.primaryVideo : this.secondaryVideo;
        const videoState = videoMode === VideoMode.Primary ? this.publishStore.primaryState : this.publishStore.secondaryState;
        const doSubscribe = () => {
            const currentVideoState = videoMode === VideoMode.Primary ? this.publishStore.primaryState : this.publishStore.secondaryState;
            if(currentVideoState === VideoState.None || currentVideoState === VideoState.SubscribeError || currentVideoState === VideoState.PublishError) {
                video.subscribe(feed, callbacks);
            } else {
                console.warn("Not yet terminated", currentVideoState);
                setTimeout(doSubscribe, 1000);
            }
        }

        if(videoState === VideoState.Published || videoState === VideoState.Publishing) {
            video.unpublish(videoMode, {
                onSuccess: () => {
                    setTimeout(doSubscribe, 1000);
                }
            });
        } else if (videoState === VideoState.Subscribed || videoState === VideoState.StreamAttached || videoState === VideoState.Subscribing || videoState === VideoState.StreamEmpty) {
            if(isStream) {
                this.unsubscribe(videoMode, {
                    onSuccess: () => {
                        setTimeout(doSubscribe, 1000);
                    }
                });
            } else {
                this.changeFeed(videoMode, feed);
                this._setVideoFeed(videoMode, feed);
            }
        } else {
            doSubscribe();
        }
    }

    changeFeed = (videoMode, feed) => {
        const video = videoMode === VideoMode.Primary ? this.primaryVideo : this.secondaryVideo;
        video.changeFeed(feed);
    }

    unsubscribe = (videoMode, callbacks) => {
        const {role} = this.roomStore;

        const {primaryState, primaryStream, secondaryState} = this.publishStore;

        if(videoMode === VideoMode.Primary) {
            if (primaryState === VideoState.Subscribed || primaryState === VideoState.StreamAttached || primaryState === VideoState.Subscribing || primaryState === VideoState.StreamEmpty) {
                if(role === 'publisher' && primaryStream && primaryStream.deviceId === 'lookaround') {
                    this.publishStore.setMode(RoomMode.Normal);
                }

                this.primaryVideo.leave(callbacks);
            }
        } else if(videoMode === VideoMode.Secondary) {
            if (secondaryState === VideoState.Subscribed || secondaryState === VideoState.StreamAttached || secondaryState === VideoState.Subscribing || secondaryState === VideoState.StreamEmpty) {
                this.secondaryVideo.leave(callbacks);
            }
        }
    }

    setSubstream = (videoMode, substream) => {
        console.log(LogPrefix, `Set substream to ${substream}`);

        const {primaryState, secondaryState} = this.publishStore;
        if(videoMode === VideoMode.Primary) {
            if(primaryState === VideoState.Subscribed) {
                this.primaryVideo.setSimulcastSubstream(substream);
            }
        } else if(videoMode === VideoMode.Secondary) {
            if(secondaryState === VideoState.Subscribed) {
                this.secondaryVideo.setSimulcastSubstream(substream);
            }
        }
    }

    setSubstreamLock = (videoMode, substream) => {
        console.log(LogPrefix, `Set substream lock to ${substream}`);

        this._setVideoSubstreamLock(videoMode, substream);
    }

    play = (videoMode) => {
        const video = videoMode === VideoMode.Primary ? this.config.primaryVideoAreaRef : this.config.secondaryVideoAreaRef;
        const {primaryState} = this.publishStore;

        if((video) && (video.current)) {
            //video.current.muted = false;
            const playPromise = video.current.play();
            if((playPromise !== undefined) && (playPromise !== null)) {
                playPromise
                    .then(() => {
                        console.log('Played', videoMode);
                    })
                    .catch((error) => {
                        console.warn('Play Error', error);
                    });
            }


            if(videoMode === VideoMode.Primary) {
                if(primaryState === VideoState.StreamAttached) {
                    this._setVideoState(videoMode, VideoState.Subscribed);
                }

                this.publishStore.setPrimaryPlayConfirm(true);
                this.primaryVideo.setAutoPlay(true);
            } else {
                this._setVideoState(videoMode, VideoState.Subscribed);

                this.publishStore.setSecondaryPlayConfirm(true);
                this.secondaryVideo.setAutoPlay(true);
            }
        }
    }

    _setMediaElementMuted = (muted) => {
        const {primaryState, secondaryState} = this.publishStore;
        console.log(LogPrefix, 'SetSound MediaElement muted', muted, toJS(primaryState), toJS(secondaryState));

        if(this.config.primaryAudioRef && this.config.primaryAudioRef.current) {
            console.log(LogPrefix, 'SetSound audio element muted : ', muted);

            this.config.primaryAudioRef.current.muted = muted;
            this.config.primaryAudioRef.current.setAttribute('muted', muted ? 'true' : 'false');
        }

        if(this.config.primaryVideoAreaRef && this.config.primaryVideoAreaRef.current) {
            if((!muted) && primaryState !== VideoState.Publishing && primaryState !== VideoState.Published) {
                console.log(LogPrefix, 'SetSound primary video element muted : ', false);

                this.config.primaryVideoAreaRef.current.muted = false;
                this.config.primaryVideoAreaRef.current.setAttribute('muted', 'false');
            } else {
                console.log(LogPrefix, 'SetSound primary video element muted : ', true);

                this.config.primaryVideoAreaRef.current.muted = true;
                this.config.primaryVideoAreaRef.current.setAttribute('muted', 'true');
            }
        }

        if(this.config.secondaryVideoAreaRef && this.config.secondaryVideoAreaRef) {
            if((!muted) && secondaryState !== VideoState.Publishing && secondaryState !== VideoState.Published) {
                console.log(LogPrefix, 'SetSound secondary video element muted : ', false);

                this.config.secondaryVideoAreaRef.current.muted = false;
                this.config.secondaryVideoAreaRef.current.setAttribute('muted', 'false');
            } else {
                console.log(LogPrefix, 'SetSound secondary video element muted : ', true);

                this.config.secondaryVideoAreaRef.current.muted = true;
                this.config.secondaryVideoAreaRef.current.setAttribute('muted', 'true');
            }
        }
    }

    playAudio = () => {
        const {role} = this.roomStore;
        const that = this;

        this.audio.play({
            success: () => {
                that._setMediaElementMuted(false);
                that.publishStore.setSoundPlayed(true);

                if(role === 'subscriber') {
                    that._sendNotifySound(true);
                }
            }
        });
    }

    stopAudio = () => {
        const {role} = this.roomStore;
        const that = this;

        this.audio.stop({
            success: () => {
                that._setMediaElementMuted(true);
                that.publishStore.setSoundPlayed(false);

                if(role === 'subscriber') {
                    that._sendNotifySound(false);
                }
            }
        });
    }

    destroy = () => {
        const {chatState} = this.roomStore;

        if(chatState === ChatState.Connected) {
            this.leave();
        }

        const videoStreams = toJS(this.publishStore.videoStreams);
        console.log(LogPrefix, 'removing streams', videoStreams);
        this.publishStore.removeAllVideoStream();
        videoStreams.forEach((stream) => {
            console.log(LogPrefix, 'removing stream', stream.streamId);

            if(stream && stream.stream && stream.stream.getTracks) {
                stream.stream.getTracks().forEach((track) => {
                    console.log(LogPrefix, 'stop track', track);
                    track.stop()
                });
            }
        });
    }

    controlChattingPossibleSwitch = () => {
        this.roomStore.setChattingPossible(!this.roomStore.isChattingPossible);
        this.sendAnnounce();
    }

    openEndRoomDialog = () => {
        this.roomStore.setEndRoomDialogOpen(true);
    }

    closeEndRoomDialog = () => {
        this.roomStore.setEndRoomDialogOpen(false);
    }

    exitRoom = () => {
        this.roomStore.exitRoom();
    }

    endRoom = () => {
        this.roomStore.endRoom();
    }

    _getCurrentState = () => {
        const {notificationMsg, isChattingPossible, isVideoRotateX, isVideoRotateY, activationId} = this.roomStore;
        const {mode, mainVideo, subVideoHidden, memberMic, primaryState, primaryFeed, secondaryState, secondaryFeed} = this.publishStore;
        const { isTinyGroupDialogOpen, tinyGroup } = this.tinyGroupStore;

        return {
            mode: mode,

            activationId : activationId,

            mainVideo: mainVideo,
            subVideoHidden: subVideoHidden,

            memberMic: memberMic,

            primaryState: primaryState,
            primaryFeed: primaryFeed,

            secondaryState: secondaryState,
            secondaryFeed: secondaryFeed,

            notificationMsg: notificationMsg,

            isChattingPossible: isChattingPossible,

            isVideoRotateX: isVideoRotateX,
            isVideoRotateY: isVideoRotateY,

            isTinyGroupDialogOpen : isTinyGroupDialogOpen,
            ownerGroupId: tinyGroup ? tinyGroup.id : 0,
        };
    }

    _setVideoState = (videoMode, state) => {
        if(videoMode === VideoMode.Primary) {
            this.publishStore.setPrimaryState(state);
        } else if(videoMode === VideoMode.Secondary) {
            this.publishStore.setSecondaryState(state);
        }
    }

    _setVideoStream = (videoMode, stream) => {
        if(videoMode === VideoMode.Primary) {
            this.publishStore.setPrimaryStream(stream);
        } else if(videoMode === VideoMode.Secondary) {
            this.publishStore.setSecondaryStream(stream);
        }
    }

    _setVideoFeed = (videoMode, feed) => {
        if(videoMode === VideoMode.Primary) {
            this.publishStore.setPrimaryFeed(feed);
        } else if(videoMode === VideoMode.Secondary) {
            this.publishStore.setSecondaryFeed(feed);
        }
    }

    _setVideoSubstream = (videoMode, substream) => {
        if(videoMode === VideoMode.Primary) {
            this.publishStore.setPrimarySubstream(substream);
        } else if(videoMode === VideoMode.Secondary) {
            this.publishStore.setSecondarySubstream(substream);
        }
    }

    _setVideoSubstreamLock = (videoMode, substream) => {
        if(videoMode === VideoMode.Primary) {
            this.publishStore.setPrimarySubstreamLock(substream);
        } else if(videoMode === VideoMode.Secondary) {
            this.publishStore.setSecondarySubstreamLock(substream);
        }
    }

    // For VideoArea size
    setFullScreen = (fullscreen) => {
        this.roomStore.setFullscreen(fullscreen);
    }

    setLandscape = (landscape) => {
        this.layoutManager.setLandscape(landscape);
        this.roomStore.setLandscape(landscape);
    }

    initVideoArea = () => {
        this.layoutManager.initVideoArea();
    }

    resizeVideoArea = (needInit) => {
        console.log(LogPrefix, `Resizing video area : init=${needInit}`);

        this.layoutManager.resizeVideoArea(needInit);
    }

    changeMainVideo = () => {
        if(this.publishStore.mainVideo === VideoMode.Primary) {
            this.setMainVideo(VideoMode.Secondary);
        } else {
            this.setMainVideo(VideoMode.Primary);
        }
    }

    setMainVideo = (mainVideo) => {
        const {isPublisher} = this.roomStore;

        if((mainVideo === VideoMode.Primary) || (mainVideo === VideoMode.Secondary)) {
            this.layoutManager.setMainVideo(mainVideo, isPublisher);
            this.publishStore.setMainVideo(mainVideo);

            if(this.chat && isPublisher) {
                this.sendAnnounce();
            }
        } else {
            console.log(LogPrefix, `Invalid mainVideo value : ${mainVideo}`);
        }
    }

    setSubVideoHidden = (hidden) => {
        this.layoutManager.setSubVideoHidden(hidden);
        this.publishStore.setSubVideoHidden(hidden);

        const {role} = this.roomStore;

        if(this.chat && (role === 'publisher')) {
            this.sendAnnounce();
        }
    }

    setSubVideoPosition = (position) => {
        this.layoutManager.setSubVideoPosition(position);
        this.publishStore.setSubVideoPosition(position);
    }

    showSubVideoLocator = (show, position) => {
        this.layoutManager.showSubVideoLocator(show, position);
    }

    getSubVideoSize = () => {
        return this.layoutManager.getSubVideoSize();
    }

    setSubVideoSize = (size) => {
        this.publishStore.setSubScreenSize(size);
        this.layoutManager.setSubVideoSize(size);
    }

    setSubVideoHighQualityValue = (newValue) => {
        const {primaryState} = this.publishStore;

        this.publishStore.setPrimaryHighQuality(newValue);
        if(newValue && primaryState === VideoState.Subscribed) {
            this.primaryVideo.setSimulcastSubstream(2);
        }
    }

    primaryHighQuality = () => {
        const {primaryState} = this.publishStore;

        if(primaryState === VideoState.Subscribed) {
            this.primaryVideo.setSimulcastSubstream(2);
        }
    }

    setTinyGroup = (ownerGroupId) => {
        if(this.audio) {
            this.stopAudio();
            this.unpublishAudio();

            this.audio.leave();
        }

        setTimeout(() => {
            this.tinyGroupStore.setOwner(_.cloneDeep(this.roomStore.chatMembers.find(m => m.owner)));
            this.tinyGroupStore.setMembers(_.cloneDeep(this.roomStore.chatMembers.filter(m => !m.owner)));
            this.tinyGroupStore.getTinyGroup(this.janus, this.roomStore.roomId, this.roomStore.roomUserId, this.roomStore.userId, this.roomStore.displayName, ownerGroupId);
        }, 3000);

    }

    setWhiteBoardVisible = (visible) => {
        const { isPublisher, videoViewWidth, videoViewHeight, videoViewLeft, videoViewTop } = this.roomStore;

        if(isPublisher) {
            this.whiteBoardStore.setVisible(visible, {width: videoViewWidth, height: videoViewHeight, left: videoViewLeft, top: videoViewTop});
            this.sendWhiteBoardMessage();
        }
    }


    setWhiteBoardData = (data) => {
        const {roomId} = this.roomStore;

        if(roomId) {
            const that = this;

            this.whiteBoardStore.saveWhiteBoardHistory(roomId, data, {
                success: () => {
                    that.sendWhiteBoardMessage();
                },
            });
        }
    }

    clearWhiteBoard = () => {
        this.whiteBoardStore.clear();
    }

    // For Chat sizing
    setChatAreaWidth = (width) => {
        const drawed = this.layoutManager.setChatAreaWidth(width);
        this.roomStore.setChatAreaHidden(!drawed);
    }

    setChatAreaHidden = (hidden) => {
        this.layoutManager.setChatAreaHidden(hidden);
        this.roomStore.setChatAreaHidden(hidden);
    }
}
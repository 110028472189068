import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {Button, Dialog, DialogActions, FormControl, InputLabel, Typography} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {PollType} from "../../../stores/PollStore";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
    dialogTitle: {
        fontSize: 16,
        fontWeight: 600,
    },
    itemHeader: {
    },
    itemBody: {
        paddingTop: theme.spacing(2),
    },
    pollItemContainer: {
        paddingTop: theme.spacing(2),
    },
    pollItemHeader: {
        fontSize: 16,
        padding: theme.spacing(1),
    },
    pollItemBody: {
        margin: theme.spacing(1),
    }
});

@inject('pollStore')
@observer
class PollCreateDialog extends React.Component {

    handleChangePollName = (event) => {
        const newName = event.target.value;

        this.props.pollStore.setPollName(newName);
    }

    handleChangePollType = (event) => {
        const newType = event.target.value;

        this.props.pollStore.setPollType(newType);
    }

    handleChangePollItemName = (itemId, newName) => {
        this.props.pollStore.setPollItemName(itemId, newName);
    }

    handleCloseDialog = () => {
        this.props.pollStore.closePollCreateDialog();
    }

    handleCreateNewPoll = () => {
        this.props.pollStore.createNewPoll();
    }

    render() {
        const {classes} = this.props;
        const {isOpenPollCreateDialog, isCreating, pollName, pollType, isEditableItems, pollItems} = this.props.pollStore;

        return (
            <Dialog open={isOpenPollCreateDialog}
                    onClose={this.handleCloseDialog}
                    maxWidth="sm"
                    fullWidth
                    disableBackdropClick
                    disableEscapeKeyDown
            >
                <DialogTitle>
                    <Typography className={classes.dialogTitle}>설문조사</Typography>
                </DialogTitle>

                <DialogContent>
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="stretch">
                        <Box display="flex" flexDirection="row">
                            <FormControl fullWidth margin="normal">
                                <InputLabel shrink className={classes.itemHeader}>
                                    <Typography variant="subtitle1" >설문 유형</Typography>
                                </InputLabel>
                                <RadioGroup row value={pollType} onChange={this.handleChangePollType} className={classes.itemBody}>
                                    <FormControlLabel value={PollType.OX} control={<Radio />} label="예/아니오" />
                                    <FormControlLabel value={PollType.Type3} control={<Radio />} label="3 선택형" />
                                    <FormControlLabel value={PollType.Type4} control={<Radio />} label="4 선택형" />
                                    <FormControlLabel value={PollType.Type5} control={<Radio />} label="5 선택형" />
                                    <FormControlLabel value={PollType.Answer} control={<Radio />} label="단답형" />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <Typography variant="body2">{pollType === PollType.Answer ?
                            '학생이 최대 25자까지 답변할 수 있는 단답형 설문입니다.'
                            :
                            '학생이 하나의 항목을 선택할 수 있는 선택형 설문입니다.'
                        }</Typography>

                        <Box display="flex" flexDirection="row">
                            <TextField id="poll-name"
                                       margin="normal"
                                       label={<Typography variant="subtitle1" >설문 내용</Typography>}
                                       value={pollName}
                                       placeholder="질문 할 내용을 입력해 주세요."
                                       onChange={this.handleChangePollName}
                                       InputLabelProps={{shrink: true, className: classes.itemHeader}}
                                       InputProps={{className: classes.itemBody}}
                                       multiline
                                       fullWidth autoFocus
                            />
                        </Box>

                        <Box display="flex" flexDirection="column" className={classes.pollItemContainer}>
                            {pollItems.map((item) =>
                                <Box key={`poll-item-${item.itemId}`} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                    <Typography className={classes.pollItemHeader}>{item.itemId}.</Typography>
                                    {isEditableItems ?
                                        <TextField value={item.name}
                                                   placeholder="항목의 내용을 입력해 주세요."
                                                   onChange={(event) => this.handleChangePollItemName(item.itemId, event.target.value)}
                                                   className={classes.pollItemBody}
                                                   fullWidth
                                        />
                                        :
                                        <Typography className={classes.pollItemBody}>{item.name}</Typography>
                                    }
                                </Box>
                            )}
                        </Box>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button color="default" onClick={this.handleCloseDialog}>취소</Button>
                    <Button color="primary" disabled={isCreating} onClick={this.handleCreateNewPoll}>
                        {isCreating ?
                            <CircularProgress size={12} />
                            :
                            '확인'
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withSnackbar(withRouter(withStyles(styles) (PollCreateDialog)));
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    withStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {toJS} from "mobx";

import ChannelInputComponent from "./ChannelInputComponent";
import ChannelSummaryComponent from "./ChannelSummaryComponent";


const styles = (theme) => ({
    dialog: {
    },
    dialogTitle: {
    },
    dialogContent: {
    },
    commandButton: {
        borderRadius: 24,
    },
});


@inject('channelUpdateStore', 'channelStore', 'calendarChannelStore')
@observer
class ChannelUpdateDialog extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.channelUpdateStore.isLoadFailed) {
            this.props.enqueueSnackbar('강의 정보 조회에 실패하였습니다.', {
                variant: 'error'
            });

            this.props.channelUpdateStore.clearUpdateDialogState(false);
        }

        if(this.props.channelUpdateStore.isUpdated) {
            this.props.enqueueSnackbar('강의 정보를 업데이트 하였습니다.', {
                variant: 'info'
            });

            this.props.channelUpdateStore.clearUpdateDialogState(false);
            this.props.channelStore.loadChannelDateList(this.props.userId, this.props.loginType, true);
        }

        if(this.props.channelUpdateStore.isUpdateFailed) {
            this.props.enqueueSnackbar('강의 정보 변경에 실패하였습니다.', {
                variant: 'error'
            });

            this.props.channelUpdateStore.clearUpdateDialogState(true);
        }
    }

    handleCancel = () => {
        if(this.props.channelUpdateStore.isSummary) {
            this.props.channelUpdateStore.toEdit();
        } else {
            this.props.channelUpdateStore.clearUpdateDialogState(false);
        }
    }

    handleOk = () => {
        if(this.props.channelUpdateStore.isSummary) {
            this.props.channelUpdateStore.updateChannel();
            this.props.calendarChannelStore.loadingChannelList();
        } else {
            this.props.channelUpdateStore.toSummary();
        }
    }


    render() {
        const { classes  } = this.props;
        const isOpenUpdateDialog = toJS(this.props.channelUpdateStore.isOpenUpdateDialog);
        const isBeforeStartTime = toJS(this.props.channelUpdateStore.isBeforeStartTime);
        const isOverMember = toJS(this.props.channelUpdateStore.isOverMember);
        const isUpdatable = toJS(this.props.channelUpdateStore.isUpdatable);
        const isUpdating = toJS(this.props.channelUpdateStore.isUpdating);
        const isSummary = toJS(this.props.channelUpdateStore.isSummary);
        const channel = toJS(this.props.channelUpdateStore.channel);
        const memberCount = toJS(this.props.channelUpdateStore.memberCount);
        const filteredGroupList = toJS(this.props.channelUpdateStore.filteredGroupList);
        const selectedGroupId = toJS(this.props.channelUpdateStore.selectedGroupId);
        const availablePoint = toJS(this.props.channelUpdateStore.availablePoint);

        return (
            <Dialog disableBackdropClick fullWidth
                    maxWidth="md"
                    className={classes.dialog}
                    open={isOpenUpdateDialog}
                    onClose={this.handleClose}
                    aria-labelledby="channel-detail-dialog-title" >
                <DialogTitle id="channel-detail-dialog-title" className={classes.dialogTitle}>
                    수업 정보 변경
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    {isSummary ?
                        <ChannelSummaryComponent channel={channel}
                                                 memberCount={memberCount}
                                                 availablePoint={availablePoint}
                        />
                        :
                        <ChannelInputComponent userId={this.props.userId}
                                               channel={channel}
                                               memberCount={memberCount}
                                               groupList={filteredGroupList}
                                               selectedGroupId={selectedGroupId}
                                               isBeforeStartTime={isBeforeStartTime}
                                               isOverMember={isOverMember}

                                               changeChannelName={(name) => this.props.channelUpdateStore.changeChannelName(name)}
                                               changeChannelDesc={(desc) => this.props.channelUpdateStore.changeChannelDesc(desc)}
                                               changeChannelStartDate={(date) => this.props.channelUpdateStore.changeChannelStartDate(date)}
                                               changeChannelStartAmPm={(isAm) => this.props.channelUpdateStore.changeChannelStartAmPm(isAm)}
                                               changeChannelStartHour={(hour) => this.props.channelUpdateStore.changeChannelStartHour(hour)}
                                               changeChannelStartMinute={(minute) => this.props.channelUpdateStore.changeChannelStartMinute(minute)}
                                               changeChannelLiveTime={(liveTime) => this.props.channelUpdateStore.changeChannelLiveTime(liveTime)}
                                               changeSelectedGroup={(group) => this.props.channelUpdateStore.changeSelectedGroup(group)}
                                               removeGroup={(groupId) => this.props.channelUpdateStore.removeGroup(groupId)}
                                               removeMember={(email) => this.props.channelUpdateStore.removeMember(email)}
                                               // changeChannelJoinLimitCount={(limitCount) => this.props.channelUpdateStore.changeChannelJoinLimitCount(limitCount)}
                                               changeChannelSubScreenAvailableFlag={(flag) => this.props.channelUpdateStore.changeChannelSubScreenAvailableFlag(flag)}
                                               changeChannelVersion={(version) => this.props.channelUpdateStore.changeChannelVersion(version)}
                        />
                    }
                </DialogContent>

                <DialogActions>
                    <Button className={classes.commandButton} onClick={this.handleCancel}>
                        {isSummary ? '이전' : '취소'}
                    </Button>

                    <Button color="primary" className={classes.commandButton} disabled={(!isUpdatable) || (isUpdating)} onClick={this.handleOk}>
                        {isUpdating ? <CircularProgress style={{width: 16, height: 16}} /> : isSummary ? '확인' : '다음'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (ChannelUpdateDialog)));
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Box,
    Button,
    Container,
    Typography,
    withStyles,
    withWidth
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ConfirmDialog from "../room/ConfirmDialog";
import {action, toJS} from "mobx";
import {MonitoringLayoutManager} from "./api/MonitoringLayoutManager";
import { ReactComponent as FullscreenIcon } from '../../common/images/FullscreenIcon.svg';
import { ReactComponent as FullscreenExitIcon } from '../../common/images/FullscreenExitIcon.svg';
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import HighQualityIcon from '@material-ui/icons/HighQuality';
import {VideoMode} from "../room/api/RoomPresenter2";
import * as Params from "../../common/Params";
import {getURLParams} from "../../common/Params";

const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: 0,
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        width: '100%',
        height: '100%',
        padding: 0,
        backgroundColor: 'white',
    },

    title: {
        fontSize: 18,
        color: '#333333',
        padding: theme.spacing(2),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    titleButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        borderRadius: 18,
    },

    centerContainer: {
        // backgroundColor: 'white',
    },
    videoContainer: {
        // width: '100vw',
        // height: 'calc(100vh - 59px)',
        backgroundColor: 'black',
    },

    boxStyle: {
        backgroundColor: 'transparent',
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

const LogPrefix = '[MONITORING] ';

@inject('monitoringStore', 'roomStore', 'publishStore')
@observer
class Monitoring extends React.Component {
    constructor(props) {
        super(props);

        this.monitoringVideoContainerRef = React.createRef();
        this.monitoringVideoRef = React.createRef();
        this.headerAreaRef = React.createRef();
        this.footerAreaRef = React.createRef();


        window.onresize = this.resizeMonitoringArea;
        document.onfullscreenchange = this.onFullScreenChanged;
        document.addEventListener('webkitfullscreenchange', this.onFullScreenChanged);
    }

    componentDidMount() {
        if(!this.props.match.params) {
            console.log(LogPrefix, "Oops! I don't know channel id!!!");
            this.props.history.goBack();
            return;
        }

        const { channelId } = this.props.match.params;
        console.log(LogPrefix, 'Component did mount', channelId);
        this.props.monitoringStore.setUserId(this.props.userId);
        this.initializeMonitoringLayout();
        if(this.props.janusInitialized) this.props.monitoringStore.getChannelInfo(channelId);
        this.props.monitoringStore.setMonitoringVideoRef(this.monitoringVideoRef);

        document.addEventListener('keyup', eventKey => {
            if (eventKey.key === 'F8' || eventKey.code === 'F8') {
                this.changeFullscreen();
            }
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { channelId } = this.props.match.params;
        if(!prevProps.janusInitialized && this.props.janusInitialized) this.props.monitoringStore.getChannelInfo(channelId);
    }

    componentWillUnmount() {
        const { janus, pluginHandle, monitoringVideo, monitoringChat} = this.props.monitoringStore;
        if(pluginHandle) monitoringVideo.leave();
        if(monitoringChat) monitoringChat.leave();
        if(janus) janus.destroy();
    }

    handleReconnectDialogResponse = (reconnect) => {
        this.props.monitoringStore.setReconnectDialogOpen(false);
        if(reconnect) {
            window.location.reload();
        } else {
            this.props.history.push('/channel/list');
        }
    }

    initializeMonitoringLayout = () => {
        console.log(LogPrefix, 'Initializing monitoringLayout');

        this.monitoringLayoutManager = new MonitoringLayoutManager({
            mainZIndex: 10,
            monitoringVideoContainerRef : this.monitoringVideoContainerRef,
            monitoringVideoRef : this.monitoringVideoRef,
            additionalVideoRefs: [this.headerAreaRef,this.footerAreaRef],
            landscape: true,
            isFullScreen : false,
            isMobile: toJS(this.props.monitoringStore.isMobile),
        },this.onVideoResize);

        const landscape = window.innerWidth > window.innerHeight;
        this.monitoringLayoutManager.setLandscape(landscape);
        this.props.monitoringStore.setLandscape(landscape);
        this.monitoringLayoutManager.resizeMonitoringArea(true);
    }

    onVideoResize = (width, height) => {
        const {monitoringStore} = this.props;

        if(monitoringStore) {
            console.log(LogPrefix, `onVideoResize : width=${width}, height=${height}`);

            monitoringStore.setVideoViewHeight(height);
            monitoringStore.setVideoViewWidth(width);
        }
    }

    onFullScreenChanged = () => {
        const fullEl = document.fullscreenElement;
        console.log(LogPrefix, 'onFullScreen', fullEl);

        if(fullEl) {
            this.props.monitoringStore.setFullscreen(true);
            this.monitoringLayoutManager.setFullScreen(true);
        } else {
            if(document.webkitIsFullScreen) {
                this.props.monitoringStore.setFullscreen(true);
                this.monitoringLayoutManager.setFullScreen(true);
            } else {
                this.props.monitoringStore.setFullscreen(false);
                this.monitoringLayoutManager.setFullScreen(false);
            }
        }
        setTimeout(() => this.monitoringLayoutManager.resizeMonitoringArea(true), 1000);
        setTimeout(() => this.monitoringLayoutManager.resizeMonitoringArea(true), 3000);

    }


    // @action changeFullscreen = () => {
    //     if(!this.fullScreen) {
    //         if (this.monitoringVideoRef.current.requestFullscreen) {
    //             this.monitoringVideoRef.current.requestFullscreen();
    //         } else if (this.monitoringVideoRef.current.msRequestFullscreen) {
    //             this.monitoringVideoRef.current.msRequestFullscreen();
    //         } else if (this.monitoringVideoRef.current.mozRequestFullScreen) {
    //             this.monitoringVideoRef.current.mozRequestFullScreen();
    //         } else if (this.monitoringVideoRef.current.webkitRequestFullscreen) {
    //             this.monitoringVideoRef.current.webkitRequestFullscreen();
    //         }
    //     }
    // }


    @action changeFullscreen = () => {
        if(this.fullScreen) {
            if(document.exitFullscreen) {
                document.exitFullscreen();
            } else if(document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if(document.mozExitFullScreen) {
                document.mozExitFullScreen();
            } else if(document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        } else {
            if(document.body.requestFullscreen) {
                document.body.requestFullscreen();
            } else if(document.body.webkitRequestFullscreen) {
                document.body.webkitRequestFullscreen();
            } else if(document.body.mozRequestFullScreen) {
                document.body.mozRequestFullScreen();
            } else if(document.body.msRequestFullscreen) {
                document.body.msRequestFullscreen();
            }
        }
    }

    resizeMonitoringArea = () => {
        const landscape = window.innerWidth > window.innerHeight;

        if (this.props.monitoringStore.landscape !== landscape) {
            this.monitoringLayoutManager.setLandscape(landscape);
            this.props.monitoringStore.setLandscape(landscape);
        }

        this.monitoringLayoutManager.resizeMonitoringArea(true);
    }


    handleExit = () => {
        this.monitoringLayoutManager.resizeMonitoringArea(false);
        window.close();
        // this.props.history.push('/channel/list');
    }

    handleChangeMonitoring = (xPublishId) => {
        this.props.monitoringStore.setXPublishId(xPublishId);
        this.props.monitoringStore.subscribe();
    }


    monitoringRowLayer = (rowIndex) => {
        const {xParamInfo} = this.props.monitoringStore;

        const totalRowCount = xParamInfo.information.xRows;
        const feeds = totalRowCount === 1 ? xParamInfo.information.xFeeds : xParamInfo.information.xFeeds.filter((feed, i) => Math.floor(i/xParamInfo.information.xColumns) === rowIndex);
        return (
            <Box key={`monitoring-layer-${rowIndex}`} display="flex" flexDirection="row">
                {feeds.map(feed => this.monitoringColLayer(feed))}
            </Box>
        );
    }

    handleMonitoringHighQuality = () => {
        const {monitoringSubStream} = this.props.monitoringStore;
        if(monitoringSubStream < 1) {
            this.props.monitoringStore.setSubstream(VideoMode.Monitoring, 2);
        }
    }

    monitoringColLayer = (feed) => {
        const {classes, monitoringStore} = this.props;
        const ratio  = (monitoringStore.xParamInfo.videoViewWidth / monitoringStore.xParamInfo.information.xColumns) / monitoringStore.xParamInfo.display.width;
        const width = monitoringStore.xParamInfo.display.width * ratio;
        const height = monitoringStore.xParamInfo.display.height * ratio;
        const fontSize = (monitoringStore.xParamInfo.videoViewWidth / monitoringStore.xParamInfo.information.xColumns) / 8;
        const padding = fontSize / 2;

        console.log(LogPrefix, `MonitoringColumn : viewWidth=${monitoringStore.xParamInfo.videoViewWidth}, viewHeight=${monitoringStore.xParamInfo.videoViewHeight}, width=${width}, height=${height}, fontSize=${fontSize}, padding=${padding}`);
        return (
            <Box key={`monitoring-layer-${feed.userId}`} display="flex" flexDirection="column" justifyContent="flex-end" alignItems="stretch" flexGrow={0} style={{cursor: 'pointer', width: width, height: height}} className={classes.boxStyle}>
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="flex-end">
                    <Typography style={{color: '#ffffff', fontSize: fontSize, textShadow: '0 1px 2px rgba(0, 0, 0, 0.88)', paddingLeft: padding}}> {feed.userName} </Typography>
                </Box>
            </Box>
        );
    }

    render() {
        const { classes, monitoringStore} = this.props;
        return (
            <Container component="main" maxWidth={false} className={classes.mainContainer}>

                <Box ref={this.headerAreaRef} display={monitoringStore.fullScreen ? 'none' : 'flex'} flexDirection="row" alignItems="stretch" justifyContent="space-between" className={classes.centerContainer}  >
                    <Box display="flex" justifyContent="flex-center" alignItems="center">
                        <Typography className={classes.title}> {monitoringStore.channel.channelName} </Typography>
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                        <Button variant="contained" color="primary" className={classes.titleButton} startIcon={<ExitToAppIcon />} onClick={this.handleExit}> 나가기 </Button>
                    </Box>
                </Box>

                <Box display="flex" flexDirection='column' className={classes.mainContent} >
                    <Box display="flex" flexDirection="column" flexGrow={1} className={classes.centerContainer} >
                        <Box ref={this.monitoringVideoContainerRef} display="flex" flexDirection="column" justifyContent='center' alignItems='center' flexGrow={1} className={classes.videoContainer}>
                            <video ref={this.monitoringVideoRef} style={{background: 'transparent'}} id="full-screenVideo" playsInline />

                            {monitoringStore.xParamInfo.information &&
                                <Box display="flex" justifyContent={"center"} alignItems={"flex-start"} flexDirection="column" style={{position: 'absolute',zIndex: 12, width: monitoringStore.videoViewWidth, height: monitoringStore.videoViewHeight}}>
                                    {Array(monitoringStore.xParamInfo.information.xRows).fill('').map((emptyValue, index) => this.monitoringRowLayer(index))}
                                </Box>
                            }
                        </Box>
                    </Box>
                </Box>

                <Box ref={this.footerAreaRef} display={monitoringStore.fullScreen ? 'none' : 'flex'} flexDirection="row" alignItems="stretch" justifyContent="space-between" className={classes.centerContainer}  >
                    <Box display="flex" justifyContent="flex-start" alignItems="center">
                        {monitoringStore.information.length > 0 ? monitoringStore.information.map((info, index) =>
                            <Button variant="contained" color="primary" key={`monitoring-page-button-${index}`} className={classes.titleButton} onClick={() => this.handleChangeMonitoring(info.xPublishId)}> {index+1} </Button>
                        ) : []}
                    </Box>
                    <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                        <Tooltip title="화질 설정">
                            <IconButton ref={this.qualitySubScreenButtonRef} color={monitoringStore.monitoringSubStream > 1 ? 'primary' : 'default'} onClick={this.handleMonitoringHighQuality}>
                                <HighQualityIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={'전체화면'}>
                            <IconButton className={classes.iconButton} onClick={() => this.changeFullscreen()} >
                                {monitoringStore.fullScreen ?
                                    <FullscreenExitIcon />
                                    :
                                    <FullscreenIcon />
                                }
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>

                <ConfirmDialog maxWidth="xs"
                               dialogOpen={monitoringStore.isReconnectDialogOpen}
                               title={<Typography className={classes.dialogTitle}>서버접속 에러</Typography>}
                               content={
                                   <Box display="flex" flexDirection="column">
                                       <Typography>서버 접속에 문제가 발생하였습니다. 다시 시도할까요?</Typography>
                                   </Box>
                               }
                               onCancel={() => this.handleReconnectDialogResponse(false)}
                               onOk={() => this.handleReconnectDialogResponse(true)}
                />

            </Container>

        );
    }
}

export default withSnackbar(withRouter (withWidth()(withStyles(styles, { withTheme: true }) (Monitoring))));
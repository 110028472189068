import React from "react";
import MaterialTable from "material-table";
import {inject, observer} from "mobx-react";
import {getChannelStatusType, settingStatusStyle} from "../type/ChannelStatus";
import Button from "../../../components/CustomButtons/Button";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import moment from "moment";

const styles = theme => ({
    moveButton : {
        width : 50,
      "&:hover" : {
        background : 'blue',
      }
    },
});


@inject("dashboardStore", "calendarChannelStore")
@observer
class DashBoardChannelTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            moveHover : false,
        };
    }
    componentDidMount() {
        this.props.dashboardStore.getChannelList(this.props.userId);
    }

    handleMoveClick = (channelDate) => {
        this.props.history.push(`/channel/list?date=${moment(channelDate).format('YYYYMMDD')}`);
    }

    render() {
        const { isChannelListLoading, channels, selectedRow } = this.props.dashboardStore;
        const {classes} = this.props;
        const columns = [
            {title: 'id', field: 'id', headerStyle:{wordBreak:"keep-all",textAlign: "center"}, cellStyle:{wordBreak:"keep-all",textAlign: "center"}, hidden:true },
            {title: 'channelId', field: 'channelId', headerStyle:{wordBreak:"keep-all",textAlign: "center"}, cellStyle:{wordBreak:"keep-all",textAlign: "center"}, hidden:true},
            {title: '이름', field: 'channelName', headerStyle:{wordBreak:"keep-all",textAlign: "center"}, cellStyle:{wordBreak:"keep-all",textAlign: "center"}},
            {title: '시작일', field: 'liveStartDatetime', type: 'datetime', headerStyle:{wordBreak:"keep-all",textAlign: "center"}, cellStyle:{wordBreak:"keep-all",textAlign: "center",sorting : false}},
            {title: '종료일', field: 'liveEndDatetime', type: 'datetime', headerStyle:{wordBreak:"keep-all",textAlign: "center"}, cellStyle:{wordBreak:"keep-all",textAlign: "center",sorting : false}},
            {title: '버전', field: 'version', headerStyle:{wordBreak:"keep-all",textAlign: "center"}, cellStyle:{wordBreak:"keep-all",textAlign: "center"},sorting : false},
            {title: '상태', field: 'statusCode', lookup: getChannelStatusType(), cellStyle: cellData => settingStatusStyle(cellData), headerStyle:{wordBreak:"keep-all",textAlign: "center"}},
            {title: '이동', render: rowData => <Button key={rowData.id} className={classes.moveButton} onMouseOut={() => this.setState({moveHover: false})}
                                                     onMouseOver={() => this.setState({moveHover: true})} onClick={(e) => {this.handleMoveClick(rowData.channelDate)}}>이동</Button>,
                headerStyle:{wordBreak:"keep-all",textAlign: "center"}, cellStyle:{wordBreak:"keep-all",textAlign: "center"}, sorting : false}
        ];
        return (
            <MaterialTable
                style={{boxShadow: 'none'}}
                isLoading={isChannelListLoading}
                title=""
                data={channels}
                columns={columns}
                options={{
                    pageSize: 5,
                    // grouping: true,
                    // loadingType: 'linear',
                    // paginationType: 'stepped',
                    rowStyle: rowData => ({
                        backgroundColor: selectedRow.id === rowData.id ? '#EEE' : ''
                    })
                }}
                localization={{
                    pagination: {
                        labelRowsSelect: ' 개씩 보기',
                        labelDisplayedRows: '총 {count}개 중 {from} - {to}',
                    },
                }}
                onRowClick={(event, rowData) => {
                    if(!this.state.moveHover) {
                        this.props.dashboardStore.setSelectedRow(rowData);
                        this.props.dashboardStore.loadData(this.props.userId, rowData.channelId);
                    }
                }}
            />
        );
    }
}
export default withSnackbar(withRouter(withStyles(styles) (DashBoardChannelTable)));
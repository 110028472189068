import React from "react";
import {inject, observer} from "mobx-react";
import MaterialTable from "material-table";
import QuizDetailDialog from "./QuizDetailDialog";
import Button from "@material-ui/core/Button";

@inject("dashboardStore")
@observer
class DashBoardChannelConnectStatus extends React.Component {
    render() {
        const { isChannelMemberListLoading, memberStatus, isQuizDetailDialogOpen, selectedQuizResult, selectedUserId } = this.props.dashboardStore;
        const columns = [
            {title: 'userId', field: 'userId', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, hidden:true },
            {title: 'email', field: 'email', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, hidden:true },
            {title: '학생', field: 'userName', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, grouping: false},
            {title: '입장시각', field: 'startDatetime', type: 'datetime', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, grouping: false},
            {title: '퇴장시각', field: 'endDatetime', type: 'datetime', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, grouping: false},
            {title: '참여시간', field: 'playTime', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, grouping: false},
            {title: '접속횟수', field: 'connectCount', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}},
            {title: '출석체크횟수', field: 'attendCount', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}},
            {title: '발표횟수', field: 'presentationCount', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}},
            {title: '질문횟수', field: 'questionCount', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}},
            {title: '퀴즈참여결과', field: 'quizResult', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, grouping: false,
                render: rowData => (rowData.quizResult && rowData.quizResult.length > 0) ? <Button style={{ border: 'none', backgroundColor: 'rgba(0,0,0,0)', color: 'black',}} onClick={() => this.props.dashboardStore.setQuizDetailDialogOpen(true, rowData.quizResult, rowData.userId)}> 자세히보기 </Button> : ''
            },
        ];
        return (
            <>
                <MaterialTable
                    style={{boxShadow: 'none'}}
                    isLoading={isChannelMemberListLoading}
                    title=""
                    data={memberStatus}
                    columns={columns}
                    options={{
                        pageSize: 5,
                        // grouping: true,
                        // loadingType: 'linear',
                        // paginationType: 'stepped',
                        exportButton: true,
                        exportAllData: true,
                        pageSizeOptions: [5, 10, 20, 30, 50, 70, 100],
                    }}
                    localization={{
                        pagination: {
                            labelRowsSelect: ' 개씩 보기',
                            labelDisplayedRows: '총 {count}개 중 {from} - {to}',
                        },
                    }}
                />
                <QuizDetailDialog open={isQuizDetailDialogOpen}
                                  setOpen={(open, data) => this.props.dashboardStore.setQuizDetailDialogOpen(open, data)}
                                  data={selectedQuizResult}
                                  userId={selectedUserId}
                                  onImageDoubleClick={image => this.props.dashboardStore.onImageDoubleClick(image)}
                />
            </>
        );
    }
}

export default DashBoardChannelConnectStatus;
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogContent, Typography} from "@material-ui/core";

export default function BroadcastChannelEndDialog(props) {
    const { open, handleClose, handleChannelEnd} = props;

    const handleExit = () => {
        handleChannelEnd();
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'xs'} >
            <DialogContent>
                <Typography variant="subtitle1" component="h2">
                    방송 및 강의를 종료하면 교수님과 학생들 모두 강의에 다시 입장 할 수 없습니다.
                    그래도 종료하시겠습니까?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button style={{
                            color: 'rgba(0, 0, 0, 0.56)'
                        }}
                        onClick={() => {handleClose()}}
                >
                    취소
                </Button>
                <Button style={{
                            color: '#d64e4e'
                        }}
                        onClick={handleExit}
                >
                    강의 종료
                </Button>
            </DialogActions>
        </Dialog>
    );
}
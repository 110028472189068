import {action, computed, flow, observable} from "mobx";
import axios from "axios";
import _ from "lodash";


const EmtpyChannel = {
    channelId: '',
    userId: 0,
    channelName: '',
    channelDesc: '',
    liveStartDatetime: '',
    liveEndDatetime: '',
    liveTotalTime: 0,
    joinLimitCount: 0,
    reservedPoint: 0,
    durationTime: 0,
    typeCode: '',
    statusCode: '',
    subScreenAvailableFlag: '',
    statusReason: '',
    thumbnailUrl: '',
    inviteGroupList: [],
    channelMemberList: [],
    channelStateList: [],
    channelBroadcastEndpointList: [],
    channelFileList: []
};

const DetailDialogState = {
    Closed: 'Closed',
    Loading : 'Loading',
    Loaded: 'Loaded',
    LoadFailed: 'LoadFailed',
}

export default class ChannelDetailStore {
    @observable detailDialogState = DetailDialogState.Closed;
    @observable channel = {...EmtpyChannel};

    @action closeDetailDialog = () => {
        this.detailDialogState = DetailDialogState.Closed;
    }

    @computed get isOpenDetailDialog() {
        return this.detailDialogState !== DetailDialogState.Closed;
    }

    @computed get isLoadFailed() {
        return this.detailDialogState === DetailDialogState.LoadFailed;
    }

    openDetailDialog = flow(function* openDetailDialog(channelId) {
        this.channel = {...EmtpyChannel};
        this.detailDialogState = DetailDialogState.Loading;

        try {
            const response = yield axios.get(`/api/v1/channels/channel-info?channel-id=${channelId}`);
            const channel = response.data;

            channel.channelGroupList = _.orderBy(channel.channelGroupList, ['groupName'], ['asc']);
            channel.channelMemberList = _.orderBy(channel.channelMemberList, ['email'], ['asc']);
            this.channel = channel;
            this.detailDialogState = DetailDialogState.Loaded;
        } catch(error) {
            this.detailDialogState = DetailDialogState.LoadFailed;
        }
    })
}
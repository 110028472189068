import React from "react";
import {inject, observer} from "mobx-react";
import MaterialTable from "material-table";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core";
import {toJS} from "mobx";

const styles = theme => ({
    defaultBoxStyle:{
        width: '100%',
        '& > div':{
            boxShadow:'none',
            //border:'1px solid #eee',
        },
    },
});

@inject("adminStore", "signUpStore")
@observer
class UserManagementTable extends React.Component {
    componentDidMount() {
        this.props.adminStore.loadUserList();
    }

    render() {
        const { classes } = this.props;
        const { users } = this.props.adminStore;
        // const compareUserId = (a, b) => {
        //     const aNum = a.userId;
        //     const bNum = b.userId;
        //     return (aNum && bNum) ? Number(bNum) - Number(aNum) : bNum.localeCompare(aNum);
        // }

        const columns = [
            {
                title: '상태정보',
                field: 'statusCode',
                sorting: true,
                headerStyle: {textAlign: 'left', paddingLeft: '18px'},
                cellStyle: {textAlign: 'center'},
                render: rowData => {
                    switch (rowData.statusCode) {
                        case "NORMAL":
                            return <div style={{
                                width: "55px",
                                height: "20px",
                                borderRadius: "4px",
                                backgroundColor: "#3d92d4",
                                color: "#ffffff"
                            }}>등록승인</div>;
                        case "SLEEP":
                            return <div style={{
                                width: "55px",
                                height: "20px",
                                borderRadius: "4px",
                                backgroundColor: "#bfa139",
                                color: "#ffffff"
                            }}>등록신청</div>;
                        case "BLOCK":
                            return <div style={{
                                width: "55px",
                                height: "20px",
                                borderRadius: "4px",
                                backgroundColor: "#d64e4e",
                                color: "#ffffff"
                            }}>등록거부</div>;
                        case "UNKNOWN":
                            return <div>UNKNOWN</div>;
                        case "WITHDRAW":
                            return <div>WITHDRAW</div>;
                        default:
                            return;
                    }
                }
            },
            {
                title: '교수이름',
                field: 'userName',
                sorting: true,
                headerStyle: {textAlign: 'left', paddingLeft: '18px'},
                cellStyle: {textAlign: 'left'}
            },
            {
                title: '이메일',
                field: 'email',
                sorting: false,
                headerStyle: {textAlign: 'left', paddingLeft: '18px'},
                cellStyle: {textAlign: 'left'},
            }
        ];

        return (
            <div className={classes.defaultBoxStyle}>
                <MaterialTable
                    title={""}
                    options={{
                        search: true,
                        actionsColumnIndex: -1,
                        pageSize: 10,
                        pageSizeOptions: [5, 10, 15, 20, 25, 30, 40, 50],
                        headerStyle: {
                            backgroundColor: '#fafafa',
                            color: 'rgba(51, 51, 51, 0.56)',
                            borderTop: '1px solid #eee',
                            padding: 8,
                        },
                    }}
                    localization={{
                        pagination: {
                            labelRowsSelect: ' 개씩 보기',
                            labelDisplayedRows: '총 {count}개 중 {from} - {to}',
                        },
                    }}
                    columns={columns}
                    data={toJS(users)}
                    actions={[
                        rowData => ({
                            icon: 'check',
                            iconProps: {color: rowData.statusCode === "NORMAL" ? 'disabled' : 'secondary'},
                            tooltip: 'confirm User',
                            onClick: (event, rowData) => this.props.adminStore.changeUserStatus(rowData.userId, "NORMAL"),
                            disabled: rowData.statusCode === "NORMAL"
                        }),
                        rowData => ({
                            icon: 'clear',
                            tooltip: 'reject User',
                            onClick: (event, rowData) => this.props.adminStore.changeUserStatus(rowData.userId, "BLOCK"),
                        }),
                        rowData => ({
                            icon: 'delete',
                            tooltip: 'delete User',
                            onClick: (event, rowData) => {
                                if (window.confirm("정말 삭제하시겠습니까?")) {
                                    this.props.adminStore.deleteUser(rowData.userId)
                                }
                            },
                        }),
                        {
                            icon: 'add',
                            tooltip: 'Add User',
                            iconProps: {color: 'secondary'},
                            isFreeAction: true,
                            onClick: () => this.props.adminStore.changeSignUpDialogOpen(true)
                        }
                    ]}
                />
            </div>
        );
    }
}

export default withSnackbar(withRouter(withStyles(styles) (UserManagementTable)));
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Divider from "@material-ui/core/Divider";


const styles = (theme) => ({
    dialog: {

    },
    dialogTitle: {

    },
    dialogContent: {

    },

    itemHeader: {
        padding: theme.spacing(2),
        backgroundColor: '#fafafa'
    },
    itemBody: {
        padding: theme.spacing(2),
    },
    disabledTextField: {
        margin: 0,
        padding: 0,
        '& .Mui-disabled::before': {
            borderBottomStyle: 'none',
        },
    },
});

@inject('boardStore')
@observer
class BoardDetailDialog extends React.Component {
    handleClose = () => {
        this.props.boardStore.closeDetailDialog();
    }

    handleFileDownload = (fileId, fileName) => {
        this.props.boardStore.downloadFile(fileId, fileName);
    }

    render() {
        const {classes} = this.props;
        const {isOpenDetailDialog, board, firstFile, otherFileList} = this.props.boardStore;

        return (
            <Dialog disableBackdropClick fullWidth
                    maxWidth="sm"
                    className={classes.dialog}
                    open={isOpenDetailDialog}
                    onClose={this.handleClose}
                    aria-labelledby="channel-detail-dialog-title">
                <DialogTitle id="channel-detail-dialog-title" className={classes.dialogTitle}>
                    알림장 보기
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Divider />
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.itemHeader} style={{width: '30%'}}>
                                    <Typography>제목</Typography>
                                </TableCell>
                                <TableCell className={classes.itemBody} style={{width: '70%'}}>
                                    <Typography>{board.title}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.itemHeader}>
                                    <Typography>내용</Typography>
                                </TableCell>
                                <TableCell style={{padding: 0}}>
                                    <TextField id="board-contents"
                                               margin="normal"
                                               multiline rows={7} rowsMax={7}
                                               value={board.contents}
                                               className={classes.disabledTextField}
                                               inputProps={{style: {marginLeft: 16, color: '#333333'}}}
                                               fullWidth disabled />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.itemHeader} rowSpan={otherFileList.length > 0  ? otherFileList.length + 1 : 1}>
                                    <Typography>파일</Typography>
                                </TableCell>
                                <TableCell className={classes.itemBody}>
                                    {firstFile ?
                                        firstFile.downloading ?
                                            <React.Fragment>
                                                <Typography display="inline" variant="body2">{firstFile.fileNameOriginal}</Typography>
                                                <CircularProgress size="small" color="primary" style={{width: 15, height: 15, marginLeft: 6}} />
                                            </React.Fragment>
                                            :
                                            <Link href="#" color="inherit" underline="hover" onClick={() => this.handleFileDownload(firstFile.postFileId, firstFile.fileNameOriginal)}>
                                                <Typography variant="body2">{firstFile.fileNameOriginal}</Typography>
                                            </Link>
                                        :
                                        ''
                                    }
                                </TableCell>
                            </TableRow>
                            {otherFileList.map(file =>
                                <TableRow key={`file-row-${file.postFileId}`}>
                                    <TableCell className={classes.itemBody}>
                                        {file.downloading ?
                                            <React.Fragment>
                                                <Typography display="inline" variant="body2">{file.fileNameOriginal}</Typography>
                                                <CircularProgress size="small" color="primary" style={{width: 15, height: 15, marginLeft: 6}}/>
                                            </React.Fragment>
                                            :
                                            <Link href="#" color="inherit" underline="hover" onClick={() => this.handleFileDownload(file.postFileId, file.fileNameOriginal)}>
                                                <Typography variant="body2">{file.fileNameOriginal}</Typography>
                                            </Link>
                                        }
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.handleClose} style={{ borderRadius:'25px' }}>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (BoardDetailDialog)));
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Switch,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import IconButton from "@material-ui/core/IconButton";
import {QuizType} from "../../stores/QuizStore";
import ImageUploader from "react-images-upload";
import Badge from "@material-ui/core/Badge";

const styles = (theme) => ({
    dialog: {
    },
    dialogTitle: {
    },
    dialogContent: {
    },
    grow: {
        flexGrow: 1,
    },
    contentSubTitle: {
        paddingTop: 32,
        paddingLeft: theme.spacing(1),
        fontSize: 20,
    },
    itemHeader: {
    },
    itemBody: {
    },
    listContainer: {
        height: 320,
        overflow: 'auto',
        boxShadow:'none',
        border:'1px solid #eee',
        '& ul li > div > div > span ':{
            fontSize:'14px',
            fontWeight:'700',
            color:'#333'
        }
    },
    commandButton: {
        borderRadius: 24,
    },
    imageUpload: {
        margin: '0px',
        '& div': {
            boxShadow: 'none',
            margin: '0px',
            alignItems: 'flex-end'
        }
    }
});

@inject('quizStore')
@observer
class QuizUpdateDialog extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.quizStore.isUpdateFailed) {
            this.props.enqueueSnackbar('퀴즈 변경에 실패하였습니다.', {
                variant: 'error',
            });

            this.props.quizStore.clearUpdateState(true);
        }
    }

    handleChangeQuizTitle = (event) => {
        this.props.quizStore.setNewQuizTitle(event.target.value);
    }

    handleChangeQuizQuestion = (event) => {
        this.props.quizStore.setNewQuizQuestion(event.target.value);
    }

    handleChangeQuizOptionsContents = (optionId, event) => {
        this.props.quizStore.setNewQuizOptionsContents(optionId, event.target.value);
    }

    handleChangeQuizOptionsCorrectFlag = (optionId, event) => {
        this.props.quizStore.setNewQuizOptionsCorrectFlag(optionId, event.target.checked);
    }

    handleChangeQuizOptionContents = (event) => {
        this.props.quizStore.setNewQuizOptionContents(event.target.value);
    }

    handleChangeQuizOptionCorrectFlag = (event) => {
        const flag = event.target.checked;

        this.props.quizStore.setNewQuizOptionCorrectFlag(flag);
    }

    handleAddQuizOption = () => {
        this.props.quizStore.addNewQuizOption(this.props.quizStore.newQuiz.type === QuizType.SUBJECTIVE);
    }

    handleRemoveQuizOption = (quizOptionNumber) => {
        this.props.quizStore.removeNewQuizOption(quizOptionNumber);
    }

    handleCancel = () => {
        this.props.quizStore.clearUpdateState(false);
    }

    handleOk = () => {
        this.props.quizStore.updateQuiz(this.props.userId);
    }

    render() {
        const { classes } = this.props;
        const { isUpdateDialogOpen, newQuiz, quizQuestionImageDataUrls, newQuizOptionContents, newQuizOptionCorrectFlag, isUpdatable, isUpdating } = this.props.quizStore;
        const isSubjective = newQuiz.type === QuizType.SUBJECTIVE || newQuiz.type === QuizType.SHORT_ANSWER;
        const isExistCorrectInSingleAnswer = newQuiz.type === QuizType.SINGLE_CHOICE &&  newQuiz.quizOptions.some(option => option.correctFlag);
        return (
            <Dialog disableBackdropClick fullWidth
                    maxWidth="sm"
                    className={classes.dialog}
                    open={isUpdateDialogOpen}
                    onClose={this.handleCancel}
                    aria-labelledby="quiz-update-dialog-title" >
                <DialogTitle id="quiz-update-dialog-title" className={classes.dialogTitle}>
                    퀴즈 변경하기
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={1} className={classes.formContainer}>
                        <Grid item xs={12}>
                            <TextField id="update-quiz-title"
                                       margin="normal"
                                       label={<Typography variant="subtitle1" >퀴즈 명</Typography>}
                                       value={newQuiz.quizTitle}
                                       onChange={this.handleChangeQuizTitle}
                                       InputLabelProps={{shrink: true, className: classes.itemHeader}}
                                       InputProps={{className: classes.itemBody}}
                                       autoFocus fullWidth />
                        </Grid>
                        <Grid item xs={9}>
                            <TextField id="update-quiz-question"
                                       margin="normal"
                                       label={<Typography variant="subtitle1" >퀴즈 질문</Typography>}
                                       value={newQuiz.quizQuestion}
                                       onChange={this.handleChangeQuizQuestion}
                                       InputLabelProps={{shrink: true, className: classes.itemHeader}}
                                       InputProps={{className: classes.itemBody}}
                                       fullWidth />
                        </Grid>

                        <Grid item xs={3}>
                            <ImageUploader
                                className={classes.imageUpload}
                                withIcon={false}
                                withLabel={false}
                                singleImage={true}
                                buttonText="이미지선택"
                                onChange={(pictureFiles, pictureDataURLs) => {
                                    this.props.quizStore.setQuizQuestionImages(pictureFiles);
                                    this.props.quizStore.setQuizQuestionImageDataUrls(pictureDataURLs);
                                }}
                                imgExtension={[".jpg", ".jpeg", ".gif", ".tiff", ".tif", ".png", ".bmp"]}
                                maxFileSize={5242880}
                            />
                        </Grid>

                        {quizQuestionImageDataUrls && quizQuestionImageDataUrls.map((imageDataUrl, index) => (
                            <Grid item xs={12/ (quizQuestionImageDataUrls.length >= 5 ? 4 : quizQuestionImageDataUrls.length)} key={`quizQuestionImagePreview-${index}`}>
                                <Badge badgeContent={"X"} color="secondary" onClick={() => {
                                    this.props.quizStore.removeQuizQuestionImage(index);
                                    this.props.quizStore.removeQuizQuestionImageDataUrls(index);
                                }}>
                                    <img src={imageDataUrl} width={"100%"} alt="" />
                                </Badge>
                            </Grid>
                        ))}

                        {isSubjective ? '' :
                            <>
                                <Grid container item xs={12} style={{marginTop: 16}}>
                                    <Grid item xs={12}>
                                        <Typography variant="body1" style={{fontSize: 12}}>퀴즈 답변항목</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <List style={{height: 340, overflow: 'auto'}}>
                                            {newQuiz.quizOptions.map((option) =>
                                                <ListItem key={`update-quiz-option-item-${option.quizOptionNumber}`}>
                                                    <ListItemText primary={
                                                                        <Grid container style={{margin: 0, paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 16}}>
                                                                            <Grid item xs={1} style={{display: 'flex', alignItems: 'flex-end', paddingBottom: 12}}>
                                                                            <Typography variant="body1" style={{fontSize: 14}}> {`${option.quizOptionNumber}번 - `}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={11}>
                                                                            <TextField margin="normal"
                                                                                       value={option.quizOptionContents}
                                                                                       onChange={(event) => this.handleChangeQuizOptionsContents(option.quizOptionId, event)}
                                                                                       InputLabelProps={{shrink: true}}
                                                                                       fullWidth />
                                                                            </Grid>
                                                                        </Grid>
                                                                  }
                                                                  secondary={
                                                                        <FormControlLabel style={{marginLeft:0, marginRight: 0}}
                                                                                          control={<Switch checked={option.correctFlag} onChange={(event) => this.handleChangeQuizOptionsCorrectFlag(option.quizOptionId, event)} />}
                                                                                          label={option.correctFlag ? '정답' : '오답'}/>
                                                                  }
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton onClick={() => this.handleRemoveQuizOption(option.quizOptionNumber)}>
                                                            <DeleteOutlineIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            )}
                                        </List>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12}>
                                    <Grid item xs={isExistCorrectInSingleAnswer ? 10 : 7}>
                                        <TextField id="update-quiz-option-contents"
                                                   margin="normal"
                                                   label={<Typography variant="subtitle1" >새 답변항목</Typography>}
                                                   value={newQuizOptionContents}
                                                   onChange={this.handleChangeQuizOptionContents}
                                                   InputLabelProps={{shrink: true, className: classes.itemHeader}}
                                                   InputProps={{className: classes.itemBody}}
                                                   fullWidth />
                                    </Grid>
                                    {isExistCorrectInSingleAnswer ? '' :
                                        <Grid item xs={3} style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end', paddingBottom: 8}}>
                                            <FormControlLabel style={{marginLeft:0, marginRight: 0}}
                                                              control={<Switch checked={newQuizOptionCorrectFlag} onChange={this.handleChangeQuizOptionCorrectFlag} />}
                                                              label={newQuizOptionCorrectFlag ? '정답' : '오답'}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end', paddingBottom: 8}}>
                                        <Button variant="contained" style={{borderRadius:'25px'}} disabled={!Boolean(newQuizOptionContents)} onClick={this.handleAddQuizOption}>추가</Button>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <div className={classes.grow} />
                    <Button onClick={this.handleCancel}>
                        취소
                    </Button>
                    <Button color="primary" disabled={!isUpdatable || isUpdating} onClick={this.handleOk}>
                        {isUpdating ? <CircularProgress size="small" /> : '확인'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (QuizUpdateDialog)));
import React from "react";
import {withRouter} from "react-router-dom";
import {Button, Container, Grid, Typography, withStyles} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import {inject, observer} from "mobx-react";
import Footer from "../../components/Footer";


const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        width: '100%',
        textAlign: 'center',
    },
    logoVerti: {
        marginTop: 93,
        width: 119,
        height: 91,
    },
    subTitle: {
        marginTop: theme.spacing(5),
    },
    contentBox: {
        display: 'inline-block',
        width: 600,
        marginTop: theme.spacing(5),
    },
    contentFirstRow: {
        border: 'solid 1px #eeeeee',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    contentRow: {
        borderTop: 'none',
        borderLeft: 'solid 1px #eeeeee',
        borderRight: 'solid 1px #eeeeee',
        borderBottom: 'solid 1px #eeeeee',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    contentLeft: {
        textAlign: 'left',
        paddingLeft: theme.spacing(2),
    },
    contentRight: {
        textAlign: 'right',
        paddingRight: theme.spacing(2),
    },
    startButton: {
        display: 'inline-block',
        marginTop: 60,
        fontSize: 16,
        borderRadius: 24,
        width: 150,
        height: 48,
    },
    inline: {
        display: 'inline',
    },
});

@inject('ticketStore')
@observer
class TicketSummary extends React.Component {
    componentDidMount() {
        const userId = this.props.userId;
        const amount = this.props.match.params.amount;

        console.log(`Buy a ticket ${userId}, ${amount}`);
        this.props.ticketStore.buyTicket(userId, amount);
    }

    handleClickStart = () => {
        this.props.history.push("/channel/create");
    }

    render() {
        const { classes } = this.props;
        const amount = this.props.match.params.amount;

        return (
            <React.Fragment>
                <Container component="main" maxWidth="lg" className={classes.mainContainer}>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.mainContent}>
                        <img className={classes.logoVerti} src="/images/logo-verti.png" alt="ONTHELIVE" />

                        <Typography variant="h4" className={classes.subTitle}>이용권 구매 완료</Typography>

                        <div className={classes.contentBox} >
                            <Grid container>
                                <Grid container item xs={12} className={classes.contentFirstRow}>
                                    <Grid item xs={4} className={classes.contentLeft}>
                                        <Typography className={classes.inline} variant="h6">최종 결제 금액</Typography>
                                    </Grid>
                                    <Grid item xs={8} className={classes.contentRight}>
                                        <Typography className={classes.inline} variant="h6" color="primary">{amount}</Typography>
                                        <Typography className={classes.inline} variant="h6"> 원</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} className={classes.contentRow}>
                                    <Grid item xs={4} className={classes.contentLeft}>
                                        <Typography className={classes.inline} color="textSecondary">결제 수단</Typography>
                                    </Grid>
                                    <Grid container item xs={8} className={classes.contentRight}>
                                        <Grid item xs={12}>
                                            <Typography color="textSecondary">무료 체험</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <div style={{display: 'flex'}}>
                                                <div style={{flexGrow : 1, textAlign: 'right'}}>
                                                    <ErrorIcon className={classes.inline} fontSize="small" color="disabled"/>
                                                </div>
                                                <Typography className={classes.inline} color="textSecondary">무료체험 종료시 해당 금액은 사라집니다.</Typography>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button className={classes.startButton} color="primary" variant="contained" onClick={this.handleClickStart}>채널 만들기</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Container>

                <Footer />
            </React.Fragment>
        );
    }
};

export default withRouter(withStyles(styles)(TicketSummary));
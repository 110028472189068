import React from "react";
import {inject, observer} from "mobx-react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

@inject("dashboardStore")
@observer
class DashBoardChannelInfo extends React.Component {
    render() {
        const { channelInfo } = this.props.dashboardStore;
        return (
            <Grid container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  spacing={2}
            >
                <Grid item>
                    <Typography>강의명: {channelInfo.channelName}</Typography>
                </Grid>
                <Grid item>
                    <Typography>일시: {channelInfo.liveStartDatetime} ~ {channelInfo.liveEndDatetime} ({channelInfo.durationTime} 분)</Typography>
                </Grid>
                <Grid item>
                    <Typography>참가그룹: {channelInfo.groups}</Typography>
                </Grid>
            </Grid>
        );
    }
}

export default DashBoardChannelInfo;
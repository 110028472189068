import React from "react";
import Dialog from "@material-ui/core/Dialog/index";
import DialogTitle from "@material-ui/core/DialogTitle/index";
import DialogContent from "@material-ui/core/DialogContent/index";
import Close from "@material-ui/icons/Close";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "./CustomButtons/Button";
import modalStyle from "../common/jss/modalStyle";
import {observer} from "mobx-react";

const imagePopupStyle = theme => ({
    imagePopup: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: '900px'
        },
        backgroundColor: 'rgba( 255, 255, 255, 0 )',
            boxShadow: 'none',
            border: 'none',
            marginTop: '100px !important',
            textAlign: 'center'
    },
    imagePopupSize: {
        width: '100%'
    },
});

@observer
class ImagePopupModal extends React.Component {
    render() {
        const { classes, store } = this.props;
        return (
            <Dialog
                classes={{root: classes.modalRoot, paper: classes.imagePopup}}
                open={store.isShowImagePopupModal}
                // keepMounted scroll={"body"}
                onClose={() => store.onCloseImagePopupModal()}
                aria-labelledby="modal-slide-title"
                aria-describedby="modal-slide-description">
                <DialogTitle id="classic-modal-slide-title" disableTypography className={classes.modalHeader}>
                    <Button justIcon key="close" aria-label="Close" onClick={() => store.onCloseImagePopupModal()}>
                        <Close className={classes.modalClose} />
                    </Button>
                </DialogTitle>
                <DialogContent>
                    {store.popupTargetImage.image &&
                        <img className={classes.imagePopupSize}
                             src={`data:${store.popupTargetImage.type};base64,${store.popupTargetImage.image}`}
                             title={store.popupTargetImage.title}
                             alt={store.popupTargetImage.title}/>
                    }
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles((theme) => ({
    ...imagePopupStyle(theme),
    ...modalStyle(theme),
}),{ withTheme: true },)(ImagePopupModal);

import {action, computed, flow, observable, toJS} from "mobx";
import axios from "axios";
import * as validation from "../common/Validation";

const MinUserName = 2;
const MinPassword = 4;
const MinNickName = 2;

const State = {
    Ready: 'Ready',
    Pending: 'Pending',
    NotAvailableEmail: 'NotAvailableEmail',
    NotAvailableNickName: 'NotAvailableNickName',
    WrongCaptchaValue: 'WrongCaptchaValue',
    Success: 'Success',
    Fail: 'Fail',
}

const EmptyNewMember = {
    email: '',
    password: '',
    passwordConfirm: '',
    userName: '',
    nickName: '',
    typeCode: 'USER'
}

const EmptyAgreements = {
    all: false,
    service: false,
    privacy: false,
}

export default class SignUpStore {
    @observable state = State.Ready;
    @observable newMember = {...EmptyNewMember}
    @observable agreements = {...EmptyAgreements}
    @observable serverMode = '';
    @observable captcha = {};
    @observable captchaValue = '';

    @action initialize = (email) => {
        this.state = State.Ready;
        this.newMember = {...EmptyNewMember};
        this.agreements = {...EmptyAgreements};
        this.captcha = {};
        this.captchaValue = '';

        if((email !== undefined) && (email !== null) && (email.length > 0) && validation.validateEmail(email)) {
            this.newMember.email = email;
        }
    }

    @action clearState = () => {
        this.state = State.Ready;
    }

    @action changeNewMemberTypeCode = (typeCode) => {
        this.newMember.typeCode = typeCode;
    }

    @action changeNewMemberEmail = (email) => {
        this.newMember.email = email;
    }

    @action changeNewMemberPassword = (password) => {
        this.newMember.password = password;
    }

    @action changeNewMemberPasswordConfirm = (passwordConfirm) => {
        this.newMember.passwordConfirm = passwordConfirm;
    }

    @action changeNewMemberUserName = (userName) => {
        this.newMember.userName = userName;
    }

    @action changeNewMemberNickName = (nickName) => {
        this.newMember.nickName = nickName;
    }

    @action changeAgreementsAll = (allAgreement) => {
        if(allAgreement) {
            this.agreements.all = true;
            this.agreements.service = true;
            this.agreements.privacy = true;
        } else {
            this.agreements.all = false;
            this.agreements.service = false;
            this.agreements.privacy = false;
        }
    }

    @action changeAgreementsService = (serviceAgreements) => {
        if(serviceAgreements) {
            this.agreements.service = true;
        } else {
            this.agreements.all = false;
            this.agreements.service = false;
        }
    }

    @action changeAgreementsPrivacy = (privacyAgreements) => {
        if(privacyAgreements) {
            this.agreements.privacy = true;
        } else {
            this.agreements.all = false;
            this.agreements.privacy = false;
        }
    }

    @action changeCaptchaValue = captchaValue => this.captchaValue = captchaValue;

    @computed get isEmailInputed() {
        return (this.newMember.email !== undefined) && (this.newMember.email !== null) && (this.newMember.email.length > 0);
    }

    @computed get canSignUp() {
        const emailVerification = validation.validateEmail(this.newMember.email);
        const agreements = this.agreements.service && this.agreements.privacy;
        const passwordConfirm = this.newMember.password === this.newMember.passwordConfirm;
        const password = this.newMember.password.length >= MinPassword;
        const userName = this.newMember.userName.length >= MinUserName;
        const nickName = this.newMember.nickName.length >= MinNickName;
        const isExistResponseValue = this.captchaValue && this.captchaValue !== '' && this.captchaValue.length > 0;

        return emailVerification && agreements && passwordConfirm && password && userName && nickName && isExistResponseValue;
    }

    @computed get canAdminSignUp() {
        const emailVerification = validation.validateEmail(this.newMember.email);
        const passwordConfirm = this.newMember.password === this.newMember.passwordConfirm;
        const password = this.newMember.password.length >= MinPassword;
        const userName = this.newMember.userName.length >= MinUserName;
        const nickName = this.newMember.nickName.length >= MinNickName;

        return emailVerification && passwordConfirm && password && userName && nickName;
    }

    @computed get isValidEmail() {
        return validation.validateEmail(this.newMember.email);
    }

    @computed get isValidPassword() {
        return this.newMember.password.length >= MinPassword;
    }

    @computed get isPasswordConfirmed() {
        return this.newMember.password === this.newMember.passwordConfirm;
    }

    @computed get isValidUsername() {
        return this.newMember.userName.length >= MinUserName;
    }

    @computed get isValidNickName() {
        return this.newMember.nickName.length >= MinNickName;
    }

    @computed get isPending() {
        return this.state === State.Pending;
    }

    @computed get isSignUpSuccess() {
        return this.state === State.Success;
    }

    @computed get isSignUpFailed() {
        return this.state === State.Fail;
    }

    @computed get isNotAvailableEmail() {
        return this.state === State.NotAvailableEmail;
    }

    @computed get isNotAvailableNickName() {
        return this.state === State.NotAvailableNickName;
    }

    @computed get isWrongCaptchaValue() {
        return this.state === State.WrongCaptchaValue;
    }

    doSignUpNoCaptcha = flow(function *doSignUpNoCaptcha(successCallback) {
        this.state = State.Pending;

        try {
            const response = yield axios.get(`/api/v1/users/signupcheck?email=${this.newMember.email}`)
            const isNotAvailEmail = response.data.result;

            if(!isNotAvailEmail) {
                const param = toJS(this.newMember);
                delete param.passwordConfirm;

                const resp = yield axios.post('/api/v1/users/signup', param);
                if(resp.status === 200) {
                    this.state = State.Success;
                    this.newMember = {...EmptyNewMember};
                    if (successCallback !== undefined) successCallback();
                }
            } else {
                this.state = State.NotAvailableEmail;
            }
        } catch (e) {
            if(e.response.data.message === State.NotAvailableNickName) {
                this.state = State.NotAvailableNickName;
            } else {
                this.state = State.Fail;
            }
        }

    });

    doSignUp = flow(function* doSignUp(successCallback) {
        this.state = State.Pending;
        this.captcha.responseValue = this.captchaValue;

        try {
            const captchaResponse = yield axios.post('/api/v1/captcha', this.captcha);
            if(captchaResponse.data.result) {
                this.doSignUpNoCaptcha(successCallback);
            } else {
                this.state = State.WrongCaptchaValue;
            }
        } catch (e) {
            if(e.response.data.message === State.NotAvailableNickName) {
                this.state = State.NotAvailableNickName;
            } else {
                this.state = State.Fail;
            }
        }
    });

    getServerMode = flow(function* getServerMode() {
        try {
            const response = yield axios.get('/api/v1/informations/mode');
            const serverMode = response.data;

            this.serverMode = serverMode;
        } catch(error) {
            console.log("Can't get server mode");
        }
    })

    getCaptchaImage = flow(function* getCaptchaImage() {
        try {
            this.captcha = {};
            this.captchaValue = '';
            const response = yield axios.get('/api/v1/captcha');
            if(response.status === 200) {
                this.captcha = response.data;
                this.captcha.responseValue = this.captchaValue;
            } else {
                console.log("Can't get captcha key", response);
            }
        } catch(error) {
            console.log("Can't get captcha", error);
        }
    })
}
import {action, computed, flow, observable} from "mobx";
import axios from "axios";
import _ from "lodash";

const AttendanceDialogState = {
    Closed: 'Closed',
    Loading: 'Loading',
    Loaded: 'Loaded',
    LoadFailed: 'LoadFailed',
}

const AttendanceFilter = {
    All : 'all',
    Attendance: 'attendance',
    Absence: 'absence',
    NoMember: 'nomember',
}

export default class ChannelAttendanceStore {
    @observable attendanceDialogState = AttendanceDialogState.Closed;
    @observable paging = {
        list: [],
        totalCount: 0,
        page: 0,
        rowsPerPage: 10,
        filter: AttendanceFilter.All,
    }
    @observable attendanceList = [];

    @action closeAttendanceDialog = () => {
        this.attendanceDialogState = AttendanceDialogState.Closed;
    }

    @action changePagingFilter = (filter) => {
        this.paging.filter = filter;

        const filteredList = _.chain(this.attendanceList)
            .filter((attendance) => {
                switch (this.paging.filter) {
                    case AttendanceFilter.Attendance:
                        return attendance.joined && attendance.attendance;
                    case AttendanceFilter.Absence:
                        return attendance.joined && !attendance.attendance;
                    case AttendanceFilter.NoMember:
                        return !attendance.joined;
                    default:
                        return true;
                }
            })
            .sortBy(['userName'])
            .value();

        this.paging.totalCount = filteredList.length;
        this.paging.list = filteredList.splice(this.paging.page * this.paging.rowsPerPage, this.paging.rowsPerPage);
    }

    @action changePagingPage = (page) => {
        this.paging.page = page;

        const filteredList = _.chain(this.attendanceList)
            .filter((attendance) => {
                switch (this.paging.filter) {
                    case AttendanceFilter.Attendance:
                        return attendance.joined && attendance.attendance;
                    case AttendanceFilter.Absence:
                        return attendance.joined && !attendance.attendance;
                    case AttendanceFilter.NoMember:
                        return !attendance.joined;
                    default:
                        return true;
                }
            })
            .sortBy(['userName'])
            .value();

        this.paging.totalCount = filteredList.length;
        this.paging.list = filteredList.splice(this.paging.page * this.paging.rowsPerPage, this.paging.rowsPerPage);
    }

    @action changePagingRowsPerPage = (rowsPerPage) => {
        this.paging.rowsPerPage = rowsPerPage;

        const filteredList = _.chain(this.attendanceList)
            .filter((attendance) => {
                switch (this.paging.filter) {
                    case AttendanceFilter.Attendance:
                        return attendance.joined && attendance.attendance;
                    case AttendanceFilter.Absence:
                        return attendance.joined && !attendance.attendance;
                    case AttendanceFilter.NoMember:
                        return !attendance.joined;
                    default:
                        return true;
                }
            })
            .sortBy(['userName'])
            .value();

        this.paging.totalCount = filteredList.length;
        this.paging.list = filteredList.splice(this.paging.page * this.paging.rowsPerPage, this.paging.rowsPerPage);
    }

    @computed get isOpenAttendanceDialog() {
        return this.attendanceDialogState !== AttendanceDialogState.Closed;
    }

    @computed get isLoadFailed() {
        return this.attendanceDialogState === AttendanceDialogState.LoadFailed;
    }

    openAttendanceDialog = flow(function* openAttendanceDialog(channel) {
        this.attendanceList = [];
        this.attendanceDialogState = AttendanceDialogState.Loading;

        try {
            const version = channel.version ? channel.version === 1 ? "v1_0" : "v2_0" : "v2_0";
            const response = yield axios.get(`/api/v1/channels/users?channel-id=${channel.channelId}&paging=no&version=${version}`);
            const userList = response.data;
            const filteredList = _.chain(userList)
                .filter((attendance) => {
                    switch (this.paging.filter) {
                        case AttendanceFilter.Attendance:
                            return attendance.joined && attendance.attendance;
                        case AttendanceFilter.Absence:
                            return attendance.joined && !attendance.attendance;
                        case AttendanceFilter.NoMember:
                            return !attendance.joined;
                        default:
                            return true;
                    }
                })
                .sortBy(['userName'])
                .value();

            this.paging.totalCount = filteredList.length;
            this.paging.list = filteredList.splice(this.paging.page * this.paging.rowsPerPage, this.paging.rowsPerPage);
            this.attendanceList = userList;
            this.attendanceDialogState = AttendanceDialogState.Loaded;
        } catch(error) {
            this.attendanceList = [];
            this.paging.totalCount = 0;
            this.attendanceDialogState = AttendanceDialogState.LoadFailed;
        }
    })
}
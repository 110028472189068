import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogContent} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import * as store from "../../../stores/BroadcastStore";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {getDeviceBrowserType} from "../../../common/Params";
import Typography from "@material-ui/core/Typography";

export default function BroadcastSubPublishDialog(props) {
    const { open, handleClose, selectedSubPublishType, changePublishType, webRtcMainPublishType, playerOnOffHandle, webRtcCameraList, selectedWebRtcCamera, changeWebRtcCamera } = props;
    const deviceType = getDeviceBrowserType().deviceType;
    const browserType = getDeviceBrowserType().browserType;

    const handlePublishType = (event) => {
        changePublishType(event.target.value);
    }

    const handleConfirmClick = () => {
        if(selectedSubPublishType === store.publishType.WEB_CAM && selectedWebRtcCamera) {
            playerOnOffHandle();
            handleClose();
        }

        if(selectedSubPublishType === store.publishType.SCREEN_SHARING && webRtcMainPublishType !== store.publishType.SCREEN_SHARING) {
            playerOnOffHandle();
            handleClose();
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'xs'} disableBackdropClick={true}>
            <DialogTitle>
                부화면 방송하기
            </DialogTitle>
            <DialogContent>
                <FormControl component="fieldset">
                    <RadioGroup value={selectedSubPublishType} onChange={handlePublishType}>
                        <FormControlLabel value={store.publishType.WEB_CAM} disabled={!webRtcCameraList || (webRtcCameraList && webRtcCameraList.length === 0)} control={<Radio />} label="웹 캠" />
                        {selectedSubPublishType === store.publishType.WEB_CAM ?
                            <FormControl variant={"outlined"}>
                                <InputLabel id={"selectLabel"}>카메라</InputLabel>
                                <Select
                                    label={"카메라"}
                                    labelId={"selectLabel"}
                                    id={"selectLabel"}
                                    //defaultValue={""}
                                    value={selectedWebRtcCamera ? selectedWebRtcCamera : ""}
                                    onChange={(event) => changeWebRtcCamera(event.target.value)}
                                >
                                    {webRtcCameraList ? webRtcCameraList.map((item) => <MenuItem key={item.deviceId} value={item.deviceId}>{item.label}</MenuItem>) : ""}
                                </Select>
                            </FormControl>
                            : ""}
                        {deviceType === 'etc' && browserType === 'chrome' ?
                            <FormControlLabel value={store.publishType.SCREEN_SHARING} disabled={webRtcMainPublishType === store.publishType.SCREEN_SHARING} control={<Radio />} label="화면 공유" />
                            : ""}
                    </RadioGroup>
                </FormControl>
                {selectedSubPublishType === store.publishType.SCREEN_SHARING ?
                    <div style={{backgroundColor: "#fafafa", marginLeft: '32px', minHeight: '30px', display: "flex", alignItems: "center"}}>
                        <Typography variant="body2" style={{textAlign: 'center', padding: '8px'}}>
                            마이크 사용 여부를 확인해 주세요
                        </Typography>
                    </div>
                    : ""}
            </DialogContent>
            <DialogActions>
                <Button style={{
                            color: 'rgba(0, 0, 0, 0.56)'
                        }}
                        onClick={() => {handleClose()}}
                >
                    취소
                </Button>
                <Button style={{
                            color: '#d64e4e'
                        }}
                        onClick={handleConfirmClick}
                >
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    );
}
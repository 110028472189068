import {action, computed, flow, observable} from "mobx";
import axios from "axios";
import moment from "moment";
import {deviceType} from "../common/Params";
import _ from "lodash";
import {PresentationType} from "./RoomStore";
import {QuizType} from "./QuizStore";
import {channelStatusType} from "../views/dashboard/type/ChannelStatus";

const DATE_FORMAT = "MM월 DD일 (ddd) HH:mm";

export default class DashBoardStore {
    // image popup
    @observable isShowImagePopupModal = false;
    @action setShowImagePopupModal = isShowImagePopupModal => this.isShowImagePopupModal = isShowImagePopupModal;
    @observable popupTargetImage = {};
    @action
    onImageDoubleClick(image) {
        this.setShowImagePopupModal(true);
        this.popupTargetImage = image;
    }

    @action
    onCloseImagePopupModal() {
        this.setShowImagePopupModal(false);
        this.popupTargetImage = {};
    }
    //

    @observable isChannelListLoading = true;
    @observable isChannelInfoLoading = true;
    @observable isChannelPlayTimeLoading = true;
    @observable isChannelAttendStateLoading = true;
    @observable isChannelMemberJoinStatusLoading = true;
    @observable isChannelMemberExitStatusLoading = true;
    @observable isChannelPresentationStatusLoading = true;
    @observable isChannelQuestionStatusLoading = true;
    @observable isChannelQuizResultLoading = true;
    @observable isChannelMemberListLoading = true;

    @observable isQuizDetailDialogOpen = false;
    @observable selectedQuizResult = [];

    @observable userId = 0;
    @observable channelList = [];
    @observable selectedUserId = 0;
    @observable selectedRow = {id: 0};

    @observable channel = {};
    @observable channelGroupList = [];
    @observable channelStateList = [];
    @observable channelAttendStateList = [];
    @observable channelMemberJoinStatusList = [];
    @observable channelMemberExitStatusList = [];
    @observable channelPresentationStatusList = [];
    @observable channelQuestionStatusList = [];
    @observable channelQuizResultList = [];
    @observable channelMemberList = [];

    @action setUserId = userId => this.userId = userId;
    @action setSelectedRow = selectedRow => {
        this.selectedRow = selectedRow;
        this.channel = this.channelList.find(channel => channel.channelId === selectedRow.channelId);
    }

    @action setQuizDetailDialogOpen = (isQuizDetailDialogOpen, quizResult, userId) => {
        this.isQuizDetailDialogOpen = isQuizDetailDialogOpen;
        this.selectedUserId = userId;
        this.selectedQuizResult = quizResult;
    }

    @action loadData(userId, channelId) {
        this.isQuizDetailDialogOpen = false;
        this.getChannelGroupList(userId, channelId);
        this.getChannelStateList(userId, channelId);
        this.getChannelAttendStateList(userId, channelId);
        this.getChannelMemberStatusList(userId, channelId);
        // this.getChannelMemberExitStatusList(userId, channelId);
        this.getChannelPresentationStatusList(userId, channelId);
        this.getChannelQuestionStatusList(userId, channelId);
        this.getChannelQuizResultList(userId, channelId);
        this.getChannelMemberList(userId, channelId);
    }

    @computed
    get channels() {
        let index = 0;
        return this.channelList ? this.channelList.map(channel => ({
            "id": index++,
            "channelId": channel.channelId,
            "channelName": channel.channelName,
            "liveStartDatetime": moment(channel.liveStartDatetime).format(DATE_FORMAT),
            "liveEndDatetime": moment(channel.liveEndDatetime).format(DATE_FORMAT),
            "version": channel.version,
            "statusCode": channel.statusCode,
            "channelDate" : channel.liveStartDatetime,
        })) : [];
    };

    @computed
    get channelInfo() {
        return {
            "channelId": this.channel.channelId,
            "channelName": this.channel.channelName,
            "liveStartDatetime": moment(this.channel.liveStartDatetime).format(DATE_FORMAT),
            "liveEndDatetime": moment(this.channel.liveEndDatetime).format(DATE_FORMAT),
            "durationTime": moment(this.channel.liveEndDatetime).diff(this.channel.liveStartDatetime, 'minutes'),
            "groups": this.channelGroupList.map(group => `${group.groupName} ${group.groupUserCount}명`).reduce((a, b) => a === "" ? b : a + "," + b, "")
        }
    }

    @computed
    get channelPlayTime() {
        let index = 0;
        const openedList = this.channelStateList.filter(state => state.statusCode === channelStatusType.OPENED).map( state => ({
            "id": index++,
            "channelId": this.channel.channelId,
            "channelStateSeq": state.channelStateSeq,
            "statusCode": state.statusCode,
            "createdDatetime": state.createdDatetime
        }));

        const exitedList = this.channelStateList.filter(state => state.statusCode === channelStatusType.EXITED).map( state => ({
            "id": index++,
            "channelId": this.channel.channelId,
            "channelStateSeq": state.channelStateSeq,
            "statusCode": state.statusCode,
            "createdDatetime": state.createdDatetime
        }));
        return this.channelStateList ? openedList.map( openState => {
            const exited = exitedList.find(state => (state.channelStateSeq === (openState.channelStateSeq + 1)));
            return ({
                "id": index++,
                "channelId": this.channel.channelId,
                "channelStateSeq": openState.channelStateSeq,
                "startStatusCode": openState.statusCode,
                "endStatusCode": exited ? exited.statusCode : channelStatusType.EXITED,
                "startDatetime": moment(openState.createdDatetime).format("MM-DD HH:mm"),
                "endDatetime": exited ? moment(exited.createdDatetime).format("MM-DD HH:mm") : '',
                "playTime": exited ? moment(exited.createdDatetime).diff(openState.createdDatetime, 'minutes') : 0,
            })
        }) : [];
    }

    @computed
    get attendStatus() {
        return this.channelAttendStateList ? this.channelAttendStateList.map(attend => ({
            "title": `출석 요청 id(${attend.attendRequestId})`,
            "total": attend.memberTotalCount,
            "attend": attend.attendResponseList.filter(response => {
                const allType = attend.attendRequestTypeList.find(type => type.attendRequestType === "ALL");
                return response.attendRequestId === allType.attendRequestId && response.attendRequestTypeId === allType.attendRequestTypeId;
            }).length,
            "late": attend.attendRequestTypeList.filter(response => response.attendRequestType === "LATE" && attend.attendRequestId === response.attendRequestId).length,
        })) : [];
    }

    @computed
    get connectStatus() {
        return this.makeBarChartData(this.channelMemberJoinStatusList);
    }

    @computed
    get deviceStatus() {
        const etcList = this.channelMemberJoinStatusList.filter(connect => connect.deviceType === deviceType.etc);
        const androidList = this.channelMemberJoinStatusList.filter(connect => connect.deviceType === deviceType.android);
        const iosList = this.channelMemberJoinStatusList.filter(connect => connect.deviceType === deviceType.ios);
        const isExist = (etcList.length > 0 || androidList.length > 0 || iosList.length > 0);
        return isExist ? [
            ["분류", "학생수", { role: 'style' }, { role: 'annotation' }, { role: 'tooltip' }],
            ["데스크탑", etcList ? etcList.length : 0, this.random_color(), etcList ? `${etcList.length}명` : 0, etcList ? etcList.map(device => device.userName).join("\n") : ''],
            ["안드로이드", androidList ? androidList.length : 0, this.random_color(), androidList ? `${androidList.length}명` : 0, androidList ? androidList.map(device => device.userName).join("\n") : ''],
            ["iOS", iosList ? iosList.length : 0, this.random_color(), iosList ? `${iosList.length}명` : 0, iosList ? iosList.map(device => device.userName).join("\n") : '']
        ] : [];
    }

    @computed
    get presentationStatus() {
        return this.makeBarChartData(this.channelPresentationStatusList);
    }

    @computed
    get questionStatus() {
        return this.makeBarChartData(this.channelQuestionStatusList);
    }

    @computed
    get quizResult() {
        return this.channelQuizResultList ? this.channelQuizResultList : [];
    }

    @computed
    get memberStatus() {
        return this.channelMemberList ? this.channelMemberList.map(member => {
            const join = this.channelMemberJoinStatusList.find(join => member.userId === join.userId);
            // const exit = this.channelMemberExitStatusList.find(exit => member.userId === exit.userId);
            const startDatetime = join ? moment(join.minDatetime) : "";
            const endDatetime = (join && join.maxDatetime != null) ? moment(join.maxDatetime) : "";
            let diffTime = (endDatetime && startDatetime) ? moment(endDatetime).diff(moment(startDatetime), 'minutes') : "";
            const time = moment.duration(diffTime, 'minutes');
            const playTime = time.hours() > 0 ? time.hours()+"시간"+time.minutes()+"분" : time.minutes() > 0 ? time.minutes()+"분" : "";
            // if(playTime || playTime === 0) {
            //     if(playTime > 60) playTime = `${(playTime/60).toFixed(0)}시간${(playTime%60)}분`;
            //     else if(playTime < 60) playTime = `${playTime}분`
            //     else if(playTime === 0 ) playTime = '0분';
            //     else if(playTime === 60) playTime = '1시간'
            // }
            const connectCount = (join && join.typeCount) ? join.typeCount : 0;
            const attendCount = this.channelAttendStateList.flatMap(attend => attend.attendResponseList).filter(attend => member.userId === attend.userId);
            const presentation = this.channelPresentationStatusList.find(presentation => member.userId === presentation.userId);
            const question = this.channelQuestionStatusList.find(question => member.userId === question.userId);
            const presentationCount = (presentation && presentation.typeCount) ? presentation.typeCount : 0;
            const questionCount = (question && question.typeCount) ? question.typeCount : 0;
            const quizResult = this.channelQuizResultList.filter(quiz => quiz.channelQuizAnswerUserList.some(answerUser => member.userId === answerUser.userId));
            return ({
                "userId": member.userId,
                "email": member.email,
                "userName": member.userName,
                "startDatetime": startDatetime ? moment(startDatetime).format("HH시mm분") : "",
                "endDatetime": endDatetime ? moment(endDatetime).format("HH시mm분") : "",
                "playTime": playTime,
                "connectCount": connectCount,
                "attendCount": attendCount ? `${attendCount.length}/${this.channelAttendStateList.length}회` : 0,
                "presentationCount": `${presentationCount}회`,
                "questionCount": `${questionCount}회`,
                "quizResult": quizResult ? quizResult : [],

            });
        }) : [];
    }

    makeQuizChartData = quiz => {
        const mappedAnswerList = quiz.channelQuizAnswerList.map(answer => {
            const quizOption = quiz.channelQuizOptionList.find(option => answer.channelQuizOptionId === option.channelQuizOptionId);
            return {...answer, ...quizOption};
        });
        if(quiz.quizType === QuizType.SINGLE_CHOICE || quiz.quizType === QuizType.MULTIPLE_CHOICE) {
            const answerNumberList = quiz.channelQuizOptionList.map(option => option.quizOptionNumber).sort((a, b) => a - b);
            const groupByTypeQuizOptionNumber = _.groupBy(mappedAnswerList, option => option.quizOptionNumber);
            const keys = Object.keys(groupByTypeQuizOptionNumber);
            const array = [];
            array.push(["분류", "학생수", {role: 'style'}, {role: 'annotation'}, {role: 'tooltip'}]);
            return array.concat(
                answerNumberList.map(num =>
                    keys.some(key => Number(num) === Number(key))
                        ? ([
                            `${num}번`,
                            groupByTypeQuizOptionNumber[num].length,
                            this.random_color(),
                            `${groupByTypeQuizOptionNumber[num].length}명`,
                            quiz.channelQuizAnswerUserList.filter(answerUser => groupByTypeQuizOptionNumber[num].some(answer => answerUser.userId === answer.userId)).map(answerUser => answerUser.userName).join("\n")
                        ]) : ([`${num}번`, 0, this.random_color(), '0명', ""])
                )
            );
        } else if(quiz.quizType === QuizType.SHORT_ANSWER) {
            const groupByTypeQuizOptionContents = _.groupBy(mappedAnswerList, option => option.quizOptionContents);
            const keys = Object.keys(groupByTypeQuizOptionContents);
            const array = [];
            array.push(["분류", "학생수"]);
            return array.concat(keys.map(key => ([ key, groupByTypeQuizOptionContents[key].length ])));
        }
    }

    makeBarChart(chartDivName, barChartData) {
        window.google.charts.load('current', {'packages':['corechart']});
        window.google.charts.setOnLoadCallback(drawStuff);
        function drawStuff() {
            const data = window.google.visualization.arrayToDataTable(barChartData);
            const options = {
                width: '95%',
                height: '95%',
                legend: {position: 'none'},
                axisTitlesPosition: 'none',
                vAxis: { title: '', format: '#' },
                // hAxis: { title: '', format: '#' },
                annotations: { alwaysOutside: true }
            };
            const chart = new window.google.visualization.ColumnChart(document.getElementById(chartDivName));
            chart.draw(data, options);
        }
    }

    makeMaterialBarChart(chartDivName, barChartData) {
        window.google.charts.load('current', {'packages':['bar']});
        window.google.charts.setOnLoadCallback(drawChart);
        function drawChart() {
            const data = window.google.visualization.arrayToDataTable(barChartData);
            const options = {
                width: '95%',
                height: '95%',
                legend: {position: 'none'},
                axisTitlesPosition: 'none',
                vAxis: { title: '', format: '#' },
                hAxis: { title: '', format: '#' },
                annotations: { alwaysOutside: true },
                bars: 'vertical'
            };
            const chart = new window.google.charts.Bar(document.getElementById(chartDivName));
            chart.draw(data, window.google.charts.Bar.convertOptions(options));
        }
    }

    makePieChart = (chartDivName, pieChartData) => {
        window.google.charts.load('current', {'packages': ['corechart']});
        window.google.charts.setOnLoadCallback(drawChart);
        function drawChart() {
            const data = new window.google.visualization.arrayToDataTable(pieChartData);
            const options = {
                'width': '95%',
                'height': '95%'
            };
            const chart = new window.google.visualization.PieChart(document.getElementById(chartDivName));
            chart.draw(data, options);
        }
    }

    makeBarChartData(statusList) {
        const groupByTypeCount = _.groupBy(statusList, status => status.typeCount);
        const keys = Object.keys(groupByTypeCount);
        const array = [];
        array.push(["분류", "학생수", { role: 'style' }, { role: 'annotation' }, { role: 'tooltip' }]);
        return array.concat(keys.map(key => ([ `${key}번`, groupByTypeCount[key].length, this.random_color(), `${groupByTypeCount[key].length}명`, groupByTypeCount[key].map(status => status.userName).join("\n") ]) ));
    }

    // random color util //
    random_color_part () {
        // return this.str_pad( this.dechex( this.mt_rand( 0, 255 ) ), 2, '0', 1);
        return this.mt_rand(0, 255)
    }

    mt_rand (min, max) {
        const argc = arguments.length
        if (argc === 0) {
            min = 0
            max = 2147483647
        } else if (argc === 1) {
            throw new Error('Warning: mt_rand() expects exactly 2 parameters, 1 given')
        }
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    dechex (d) {
        var hex = Number(d).toString(16)
        hex = '000000'.substr(0, 6 - hex.length) + hex
        return hex
    }

    str_pad (str) {
        str += ''
        while (str.length < 3) {
            str = str + str
        }
        return str
    }

    random_color () {
        return 'rgb(' + this.random_color_part() + ',' + this.random_color_part() + ',' + this.random_color_part() + ')'
    }
    //////////////////

    getChannelList = flow(function* getChannelList(userId) {
        try {
            this.isChannelListLoading = true;
            const response = yield axios.get(`/api/v1/dashboard/users/${userId}/channels`);
            if(response.status === 200) {
                this.channelList = response.data;
                if(response.data.length > 0) {
                    const channel = response.data[0];
                    this.selectedRow = {
                        "id": 0,
                        "channelId": channel.channelId,
                        "channelName": channel.channelName,
                        "liveStartDatetime": channel.liveStartDatetime,
                        "liveEndDatetime": channel.liveEndDatetime,
                        "version": channel.version,
                        "statusCode": channel.statusCode,
                    };
                    this.channel = channel;
                    this.loadData(userId, channel.channelId);
                } else {
                    this.isChannelInfoLoading = false;
                    this.isChannelPlayTimeLoading = false;
                    this.isChannelAttendStateLoading = false;
                    this.isChannelMemberJoinStatusLoading = false;
                    this.isChannelMemberExitStatusLoading = false;
                    this.isChannelPresentationStatusLoading = false;
                    this.isChannelQuestionStatusLoading = false;
                    this.isChannelQuizResultLoading = false;
                    this.isChannelMemberListLoading = false;
                }
            }
            this.isChannelListLoading = false;
        } catch(error) {
            console.log("Can't get channel list.", error);
            this.isChannelListLoading = false;
            this.isChannelInfoLoading = false;
            this.isChannelPlayTimeLoading = false;
            this.isChannelAttendStateLoading = false;
            this.isChannelMemberJoinStatusLoading = false;
            this.isChannelMemberExitStatusLoading = false;
            this.isChannelPresentationStatusLoading = false;
            this.isChannelQuestionStatusLoading = false;
            this.isChannelQuizResultLoading = false;
            this.isChannelMemberListLoading = false;
        }
    });

    getChannelGroupList = flow(function* getChannelGroupList(userId, channelId) {
        try {
            this.isChannelInfoLoading = true;
            const response = yield axios.get(`/api/v1/dashboard/users/${userId}/channels/${channelId}/groups`);
            if(response.status === 200) {
                this.channelGroupList = response.data;
            }
            this.isChannelInfoLoading = false;
        } catch(error) {
            console.log("Can't get channel group list.", error);
            this.isChannelInfoLoading = false;
        }
    });

    getChannelStateList = flow(function* getChannelStateList(userId, channelId) {
        try {
            this.isChannelPlayTimeLoading = true;
            const response = yield axios.get(`/api/v1/dashboard/users/${userId}/channels/${channelId}/states`);
            if(response.status === 200) {
                this.channelStateList = response.data;
            }
            this.isChannelPlayTimeLoading = false;
        } catch(error) {
            console.log("Can't get channel state list.", error);
            this.isChannelPlayTimeLoading = false;
        }
    });

    getChannelAttendStateList = flow(function* getChannelAttendStateList(userId, channelId) {
        try {
            this.isChannelAttendStateLoading = true;
            const response = yield axios.get(`/api/v1/dashboard/users/${userId}/channels/${channelId}/attend`);
            if(response.status === 200) {
                this.channelAttendStateList = response.data;
            }
            this.isChannelAttendStateLoading = false;
        } catch(error) {
            console.log("Can't get channel attend state list.", error);
            this.isChannelAttendStateLoading = false;
        }
    });

    getChannelMemberStatusList = flow(function* getChannelMemberStatusList(userId, channelId) {
        try {
            this.isChannelMemberJoinStatusLoading = true;
            const response = yield axios.get(`/api/v1/dashboard/users/${userId}/channels/${channelId}/connect/JOIN`);
            if(response.status === 200) {
                this.channelMemberJoinStatusList = response.data;
            }
            this.isChannelMemberJoinStatusLoading = false;
        } catch(error) {
            console.log("Can't get channel member join status list.", error);
            this.isChannelMemberJoinStatusLoading = false;
        }
    });

    getChannelMemberExitStatusList = flow(function* getChannelMemberExitStatusList(userId, channelId) {
        try {
            this.isChannelMemberExitStatusLoading = true;
            const response = yield axios.get(`/api/v1/dashboard/users/${userId}/channels/${channelId}/connect/EXIT`);
            if(response.status === 200) {
                this.channelMemberExitStatusList = response.data;
            }
            this.isChannelMemberExitStatusLoading = false;
        } catch(error) {
            console.log("Can't get channel member join status list.", error);
            this.isChannelMemberExitStatusLoading = false;
        }
    });

    getChannelPresentationStatusList = flow(function* getChannelPresentationStatusList(userId, channelId) {
        try {
            this.isChannelPresentationStatusLoading = true;
            const response = yield axios.get(`/api/v1/dashboard/users/${userId}/channels/${channelId}/presentation/${PresentationType.PRESENTATION}`);
            if(response.status === 200) {
                this.channelPresentationStatusList = response.data;
            }
            this.isChannelPresentationStatusLoading = false;
        } catch(error) {
            console.log("Can't get channel question status list.", error);
            this.isChannelPresentationStatusLoading = false;
        }
    });

    getChannelQuestionStatusList = flow(function* getChannelQuestionStatusList(userId, channelId) {
        try {
            this.isChannelQuestionStatusLoading = true;
            const response = yield axios.get(`/api/v1/dashboard/users/${userId}/channels/${channelId}/presentation/${PresentationType.QUESTION}`);
            if(response.status === 200) {
                this.channelQuestionStatusList = response.data;
            }
            this.isChannelQuestionStatusLoading = false;
        } catch(error) {
            console.log("Can't get channel question status list.", error);
            this.isChannelQuestionStatusLoading = false;
        }
    });

    getChannelQuizResultList = flow(function* getChannelQuizResultList(userId, channelId) {
        try {
            this.isChannelQuizResultLoading = true;
            const response = yield axios.get(`/api/v1/dashboard/users/${userId}/channels/${channelId}/quiz`);
            if(response.status === 200) {
                this.channelQuizResultList = response.data;
            }
            this.isChannelQuizResultLoading = false;
        } catch(error) {
            console.log("Can't get channel question status list.", error);
            this.isChannelQuizResultLoading = false;
        }
    });

    getChannelMemberList = flow(function* getChannelMemberList(userId, channelId) {
        try {
            this.isChannelMemberListLoading = true;
            const response = yield axios.get(`/api/v1/dashboard/users/${userId}/channels/${channelId}/members`);
            if(response.status === 200) {
                this.channelMemberList = response.data;
            }
            this.isChannelMemberListLoading = false;
        } catch(error) {
            console.log("Can't get channel member list.", error);
            this.isChannelMemberListLoading = false;
        }
    });
}
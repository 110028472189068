import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";

const ExpansionPanels = withStyles({
    root: {
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        // backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        display: "inline-block",
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        backgroundColor: '#fafafa',
    },
}))(MuiExpansionPanelDetails);

export default function CustomizedExpansionPanels(props) {
    const [expanded, setExpanded] = React.useState('panel1');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <div>
            {props.data.map((faq, index) => (
                <ExpansionPanels square expanded={expanded === 'panel' + (index + 1)} onChange={handleChange('panel'+ (index + 1))} key={(index + 1)}>
                    <ExpansionPanelSummary aria-controls={"panel" + (index + 1) + "d-content"} id="panel1d-header">
                        <Grid container>
                            <Grid item xs={11}>
                                <Typography>
                                    {faq.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <div
                                    style={{
                                        textAlign: 'right'
                                    }}
                                >
                                    {expanded === 'panel' + (index + 1)
                                        ? <span className="material-icons"> expand_more </span>
                                        : <span className="material-icons"> expand_less </span>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography>
                            {faq.content}
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanels>
            ))}
        </div>
    );
}
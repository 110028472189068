import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead, TablePagination,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import moment from "moment";

const styles = (theme) => ({
    divider: {
      marginTop: '12px'
    },
    dialog: {
    },
    dialogTitle: {
        color: '#333333'
    },
    dialogContent: {
    },
    itemHeader: {
    },
    itemBody: {
        paddingTop: theme.spacing(1),
    },
    leftAlignTextBody: {
        paddingTop: 32,
        paddingRight: theme.spacing(2),
        textAlign: 'left'

    },
    filebox: {
        paddingTop: 35,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    fileSelection: {
        position: 'absolute',
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: 'hidden',
        clip: 'rect(0,0,0,0)',
        border: 0,
        borderRadius: 12,
    },
    uploadButton: {
        marginTop: 35,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        height: 36,
        borderRadius: 25,
    },
    grow: {
        flexGrow: 1,
    },
});

@inject('calendarChannelStore')
@observer
class ChannelResultDialog extends React.Component {
    componentDidMount() {

    }

    handleClose = () => {
        this.props.calendarChannelStore.closeChannelResultDialog();
    }

    handleChangePagingPage = (event, newPage) => {
        this.props.calendarChannelStore.changePagingPage(newPage);
    }

    handleChangePagingRowsPerPage = (event) => {
        const newValue = parseInt(event.target.value, 10);

        this.props.calendarChannelStore.changePagingRowsPerPage(newValue);
    }

    render() {
        const {classes} = this.props;
        const { isOpenChannelResultDialog, paging, resultTargetChannel } = this.props.calendarChannelStore;
        return (
            <Dialog disableBackdropClick fullWidth
                    maxWidth="md"
                    className={classes.dialog}
                    open={isOpenChannelResultDialog}
                    onClose={this.handleClose}
                    aria-labelledby="channel-file-dialog-title" >
                <DialogTitle id="channel-file-dialog-title" className={classes.dialogTitle}>
                    <div style={{fontSize: '20px'}}> 강의 결과 화면 </div>
                    <div className={classes.divider} />
                    <div style={{fontSize: '14px'}}> {resultTargetChannel ? `일시 : ${moment(resultTargetChannel.liveStartDatetime).format('M월 D일 (ddd) HH:MM')} ~ ${moment(resultTargetChannel.liveEndDatetime).format('HH:MM')}` : ''} </div>
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    {/*<Grid container>*/}
                    {/*    <Grid item xs={12}>*/}
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" style={{width: '14%', backgroundColor: '#fafafa', color: 'rgba(51, 51, 51, 0.56)'}}><Typography variant="subtitle2">학생</Typography></TableCell>
                                        <TableCell align="center" style={{width: '15%', backgroundColor: '#fafafa', color: 'rgba(51, 51, 51, 0.56)'}}><Typography variant="subtitle2">입장시각</Typography></TableCell>
                                        <TableCell align="center" style={{width: '15%', backgroundColor: '#fafafa', color: 'rgba(51, 51, 51, 0.56)'}}><Typography variant="subtitle2">퇴장시각</Typography></TableCell>
                                        <TableCell align="center" style={{width: '12%', backgroundColor: '#fafafa', color: 'rgba(51, 51, 51, 0.56)'}}><Typography variant="subtitle2">출석체크횟수</Typography></TableCell>
                                        <TableCell align="center" style={{width: '10%', backgroundColor: '#fafafa', color: 'rgba(51, 51, 51, 0.56)'}}><Typography variant="subtitle2">퀴즈횟수</Typography></TableCell>
                                        <TableCell align="center" style={{width: '12%', backgroundColor: '#fafafa', color: 'rgba(51, 51, 51, 0.56)'}}><Typography variant="subtitle2">퀴즈정답수</Typography></TableCell>
                                        <TableCell align="center" style={{width: '10%', backgroundColor: '#fafafa', color: 'rgba(51, 51, 51, 0.56)'}}><Typography variant="subtitle2">발표횟수</Typography></TableCell>
                                        <TableCell align="center" style={{width: '10%', backgroundColor: '#fafafa', color: 'rgba(51, 51, 51, 0.56)'}}><Typography variant="subtitle2">질문횟수</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paging.list.map(member =>
                                        <TableRow key={`channel-result-table-row${member.userId}`}>
                                            <TableCell align="center">
                                                <Typography variant="body2" style={{color: '#333333'}}>{member.userName}</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="body2" style={{color: 'rgba(51, 51, 51, 0.56)'}}>{member.firstJoinTime ? moment(member.firstJoinTime).format("a H시 MM분") : ''}</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="body2" style={{color: 'rgba(51, 51, 51, 0.56)'}}>{member.lastExitTime ? moment(member.lastExitTime).format("a H시 MM분") : ''}</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="body2" style={{fontWeight:"bold", float:"left", textAlign:"center", color: '#333333', marginLeft: '27px'}}>{member.attendCount}</Typography><Typography variant="body2" style={{float:"left", textAlign:"center", color: 'rgba(51, 51, 51, 0.56)'}}>/{member.attendTotalCount}</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="body2" style={{fontWeight:"bold", float:"left", textAlign:"center", color: '#333333', marginLeft: '17px'}}>{member.quizCount}</Typography><Typography variant="body2" style={{float:"left", textAlign:"center"}}>/{member.quizTotalCount}</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="body2" style={{color: 'rgba(51, 51, 51, 0.56)'}}>{member.quizCorrectCount}회</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="body2" style={{color: 'rgba(51, 51, 51, 0.56)'}}>{member.presentationCount}회</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="body2" style={{color: 'rgba(51, 51, 51, 0.56)'}}>{member.presentationCount}회</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                </DialogContent>

                <DialogActions>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 25, 30, 35, 40, 45, 50]}
                        component="div"
                        count={paging.totalCount}
                        rowsPerPage={paging.rowsPerPage}
                        page={paging.page}
                        labelRowsPerPage=""
                        labelDisplayedRows={({from, to, count}) => `총 ${count}개 중 ${from} - ${to}`}
                        SelectProps={{renderValue: (value) => value + ' 개씩 보기'}}
                        onChangePage={this.handleChangePagingPage}
                        onChangeRowsPerPage={this.handleChangePagingRowsPerPage}
                    />
                    <div className={classes.grow} />
                    <Button onClick={this.handleClose}
                            style={{
                                borderRadius:'25px'
                            }}>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (ChannelResultDialog)));
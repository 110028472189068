import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";

import {
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    Grid, Select,
    TextField,
    Typography
} from "@material-ui/core";

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ErrorIcon from "@material-ui/icons/Error";

import * as store from "../stores/AuthStore";

import Footer from "../components/Footer";
import {toJS} from "mobx";
import MenuItem from "@material-ui/core/MenuItem";
import SignInDialog from "../SignInDialog";


const style = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        marginTop: theme.spacing(6),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // height:'calc(100vh - 410px)',
        justifyContent:'center',
        padding: '60px 80px 0 80px',
        border:'1px solid #ddd',
        marginBottom:theme.spacing(2),
        backgroundColor:'#fff',
        [theme.breakpoints.down("sm")]: {
            padding:'30px 40px 0 40px',
        },
    },
    loginText: {
        margin: theme.spacing(2, 0, 0),
        padding: theme.spacing(1, 0),
        fontWeight:'700'
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        backgroundColor: '#3437CE',
        color: '#ffffff',
        // margin: theme.spacing(3, 0, 2),
        padding: '12px 0',
        borderRadius: '50px',
        fontSize: theme.spacing(2),
        boxShadow:'none',
        margin: theme.spacing(2, 0, 5),
        '&:hover':{
            boxShadow:'none',
            backgroundColor:'#191dc5'
        }
    },
    InputAdornment:{
        display:'flex',
        alignItems:'center',
        color:'#b0b0b0'
    },
    inputIcon :{
        color:'#8d8d8d',
        marginRight:theme.spacing(2),
        marginTop:'1em'
    },
    inputPassword:{
        '& input[type=password]':{
        fontFamily:'initial',
    }},
    alignCenter: {
        textAlign: 'center',
    },
    teacherTypeSelect: {
        paddingTop: 9,
        marginRight: theme.spacing(1),
    }
});

@inject('authStore')
@observer
class SignIn extends React.Component {
    componentDidMount() {
        this.props.authStore.checkLoginId();
    }

    handleChangeId = (e) => {
        this.props.authStore.changeLoginId(e.target.value);
    };

    handleChangePassword = (e) => {
        this.props.authStore.changeLoginPassword(e.target.value);
    };

    handleKeyUpPassword = (e) => {
        if(e.keyCode === 13) {
            this.props.authStore.doLogin();
        }
    };

    handleChangeTeacherType = (e) => {
        const newType = e.target.value;

        this.props.authStore.changeMemberTeacherIdType(newType);
    }

    handleChangeMemberTeacherId = (e) => {
        const {type} = this.props.authStore.member;

        if(type === 'email') {
            this.props.authStore.changeMemberEmail(e.target.value);
        } else {
            this.props.authStore.changeMemberTeacherNickName(e.target.value);
        }
    };

    handleChangeMemberName = (e) => {
        this.props.authStore.changeMemberName(e.target.value);
    };

    handleChangeMemberPassword = (e) => {
        this.props.authStore.changeMemberPassword(e.target.value);
    };

    handleCheckSaveId = (e) => {
        this.props.authStore.changeSaveId(e.target.checked);
    };


    handleSubmitForm = (e) => {
        e.preventDefault();
        this.props.authStore.doLogin();
    };

    handleClickSignUp = () => {
        this.props.history.push('/signup');
    }

    handleClickFindPassword = () => {
        this.props.history.push("/findpassword");
    }

    render() {
        const { classes } = this.props;
        const { loginState, login, member, saveId } = this.props.authStore;
        const isLoginTypeAdmin = toJS(this.props.authStore.isLoginTypeAdmin);
        const isLoginTypeUser = toJS(this.props.authStore.isLoginTypeUser);
        const isLoginTypeMember = toJS(this.props.authStore.isLoginTypeMember);

        return (
            <React.Fragment>
                <Container component="main" maxWidth="sm">
                    <div className={classes.appBarSpacer} />
                    <div className={classes.paper}>
                        <img src={"/images/login-logo.png"} alt={""}/>
                        <Typography className={classes.loginText} component="h1" variant="h3" >
                            {loginState === store.State.Failed ? '로그인 실패' : '로그인'}
                        </Typography>

                        <form className={classes.form}>
                            {isLoginTypeMember ?
                                <React.Fragment>
                                    <div className={classes.InputAdornment}>
                                        <MailOutlineIcon className={classes.inputIcon}/>
                                        <Select
                                            id="login-member-type-select"
                                            className={classes.teacherTypeSelect}
                                            value={member.type}
                                            onChange={this.handleChangeTeacherType}
                                        >
                                            <MenuItem value={'nickname'}>교수 별칭</MenuItem>
                                            <MenuItem value={'email'}>교수 이메일</MenuItem>
                                        </Select>
                                        <TextField label=""
                                                   margin="normal"
                                                   value={member.type === 'email' ? member.email : member.teacherNickName}
                                                   onChange={this.handleChangeMemberTeacherId}
                                                   autoFocus={!saveId}
                                                   required fullWidth />
                                    </div>
                                    <div className={classes.InputAdornment}>
                                        <MailOutlineIcon className={classes.inputIcon}/>
                                        <TextField id="name"
                                                   name="name"
                                                   label="학생 이름"
                                                   margin="normal"
                                                   value={member.name}
                                                   onChange={this.handleChangeMemberName}
                                                   autoFocus={!saveId}
                                                   required fullWidth />
                                    </div>
                                    <div className={classes.InputAdornment}>
                                        <VpnKeyIcon className={classes.inputIcon}/>
                                        <TextField id="password"
                                                   type="password"
                                                   name="password"
                                                   label="비밀번호"
                                                   margin="normal"
                                                   autoComplete={"off"}
                                                   value={member.password}
                                                   className={classes.inputPassword}
                                                   onChange={this.handleChangeMemberPassword}
                                                   onKeyUp={this.handleKeyUpPassword}
                                                   autoFocus={saveId}
                                                   required fullWidth />
                                    </div>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className={classes.InputAdornment}>
                                        <MailOutlineIcon className={classes.inputIcon}/>
                                        <TextField id="email"
                                                   name="email"
                                                   label="이메일 주소"
                                                   margin="normal"
                                                   value={login.id}
                                                   onChange={this.handleChangeId}
                                                   autoFocus={!saveId}
                                                   required fullWidth />
                                    </div>
                                    <div className={classes.InputAdornment}>
                                        <VpnKeyIcon className={classes.inputIcon}/>
                                        <TextField id="password"
                                                   type="password"
                                                   name="password"
                                                   label="비밀번호"
                                                   margin="normal"
                                                   autoComplete={"off"}
                                                   value={login.password}
                                                   className={classes.inputPassword}
                                                   onChange={this.handleChangePassword}
                                                   onKeyUp={this.handleKeyUpPassword}
                                                   autoFocus={saveId}
                                                   required fullWidth />
                                    </div>
                                </React.Fragment>
                            }

                            <div className={classes.alignCenter}>
                                <FormControlLabel control={<Checkbox 
                                                                color="primary" 
                                                                checked={saveId} onChange={this.handleCheckSaveId} />}
                                                  label={(isLoginTypeAdmin || isLoginTypeUser) ? '이메일 기억하기' : '이름 기억하기'}
                                />
                            </div>

                            <Button type="submit"
                                    className={classes.submit}
                                    variant="contained"
                                    disabled={loginState === store.State.Pending}
                                    onClick={this.handleSubmitForm}
                                    fullWidth >
                                {loginState === store.State.Pending ? <CircularProgress size={22}/> : '로그인'}
                            </Button>
                        </form>

                        {isLoginTypeUser &&
                            <div style={{
                                width: '100%',
                                paddingLeft: 34,
                                paddingBottom: 32,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {/* <Button onClick={this.handleClickSignUp}>회원가입</Button>*/}
                                {/* <Typography style={{paddingLeft: 3, paddingRight: 3}}>|</Typography>*/}
                                {/* <Button onClick={this.handleClickFindPassword}>비밀번호 찾기</Button>*/}
                            </div>
                        }
                    </div>
                </Container>

                <Container component="div" maxWidth="sm" style={{paddingBottom: 32}}>
                    <Grid container style={{backgroundColor: '#fafafa', paddingTop: 36, paddingBottom: 36, paddingLeft: 16, paddingRight: 16}}>
                        <Grid container item xs={12}>
                            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', paddingBottom: 16}}>
                                <ErrorIcon style={{width: 16, height: 16, paddingTop: 2, paddingRight: 2}} /><Typography variant="subtitle2">로그인 시스템 변경 안내</Typography>
                            </Grid>
                            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center'}}>
                                <Typography variant="body2" style={{textAlign: 'left'}}>
                                    로그인 시스템이 변경되었습니다.<br/>
                                    학생 계정은 회원가입 과정을 통해 만들어 지지 않습니다.<br/>
                                    교수 계정의 '그룹' 메뉴를 통해 생성된 학생만 로그인 가능하며,<br/> 이전에 회원가입 과정을 통해 만들어진 계정은 모두 교수 계정으로 취급됩니다.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>

                <Footer />

                <SignInDialog />
            </React.Fragment>
        );
    }
};

export default withSnackbar(withRouter(withStyles(style) (SignIn)));

import React from 'react';
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";

const styles = {
    onePixelVideo: {
        width: '100%',
        height: '100%',
    },
};

@inject("broadcastStore")
@observer
class BroadcastOnePixelVideoTag extends React.Component {
    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        const {classes} = this.props;

        return (
            <video className={classes.onePixelVideo} id={this.props.broadcastStore.tempVideoId} autoPlay={true} playsInline={true}>
            </video>
        );
    }
}

export default withStyles(styles)(BroadcastOnePixelVideoTag);
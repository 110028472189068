import {action, computed, flow, observable, toJS} from "mobx";
import {VideoState} from "../views/room/api/RoomPresenter";
import axios from "axios";
import {MemberPublishState, RoomMode, VideoMode, VideoPosition} from "../views/room/api/RoomPresenter2";
import _ from "lodash";
import {PresentationType} from "./RoomStore";

const LogPrefix = '[PublishStore] ';

const PublishWidth = 192;
const PublishHeight = 108;
const PublishBitrate = 128 * 1000;

export default class PublishStore {
    @observable server = '';
    @observable pluginName = '';
    @observable roomId = '';
    @observable publisherId = '';
    @observable publisherName = '';
    @observable janus = undefined;
    @observable pluginHandle = undefined;

    @observable devices = [];

    @observable isVideoOff = false;
    @observable isAudioOff = false;
    @observable selectedVideoDeviceId = '';
    @observable selectedAudioDeviceId = '';

    @observable owner = {};
    @observable members = [];

    @observable mode = RoomMode.Normal;
    @observable mainVideo = VideoMode.Primary;
    @observable subVideoHidden = false;
    @observable subVideoPosition = '';
    @observable memberMic = false;
    @observable audioDevice = '';
    @observable videoStreams = [];

    @observable audioState = VideoState.None;
    @observable soundPlayed = false;
    @observable audioMuted = true;
    @observable primaryState = VideoState.None;
    @observable secondaryState = VideoState.None;
    @observable lookAroundPublishState = VideoState.None;
    @observable monitoringState = VideoState.None
    @observable isPublishButtonClicked = false;

    @observable audioPlayConfirm = false;
    @observable primaryPlayConfirm = false;
    @observable secondaryPlayConfirm = false;

    @observable audioFeed = '';
    @observable primaryFeed = '';
    @observable secondaryFeed = '';
    @observable audioStream = undefined;
    @observable primaryStream = undefined;
    @observable secondaryStream = undefined;
    @observable lookAroundStream = undefined;
    @observable monitoringStream = undefined;
    @observable primaryHighQuality = true;
    @observable secondaryHighQuality = false;
    @observable primarySubstream = -1;
    @observable secondarySubstream = -1;
    @observable primarySubstreamLock = -1;
    @observable secondarySubstreamLock = -1;
    @observable subScreenSize = 25;
    @observable primaryRecord = false;
    @observable secondaryRecord = false;
    @observable primaryRecorder = undefined;
    @observable secondaryRecorder = undefined;


    @action initialize = () => {
        this.server = '';
        this.pluginName = '';
        this.roomId = '';
        this.publisherId = '';
        this.publisherName = '';
        this.pluginHandle = undefined;



        this.mode = RoomMode.Normal;
        this.mainVideo = VideoMode.Primary;
        this.subVideoHidden = false;
        this.subVideoPosition = VideoPosition.BottomRight;
        this.memberMic = false;
        this.audioDevice = '';
        this.videoStreams = [];

        this.audioState = VideoState.None;
        this.soundPlayed = false;
        this.audioMuted = true;
        this.primaryState = VideoState.None;
        this.secondaryState = VideoState.None;
        this.lookAroundPublishState = VideoState.None;
        this.monitoringState = VideoState.None;
        this.isPublishButtonClicked = false;

        this.audioPlayConfirm = false;
        this.primaryPlayConfirm = false;
        this.secondaryPlayConfirm = false;

        this.audioFeed = '';
        this.primaryFeed = '';
        this.secondaryFeed = '';
        this.audioStream = undefined;
        this.primaryStream = undefined;
        this.secondaryStream = undefined;
        this.lookAroundStream = undefined;
        this.monitoringStream = undefined;
        this.primaryHighQuality = true;
        this.secondaryHighQuality = false;
        this.primarySubstream = -1;
        this.secondarySubstream = -1;
        this.primarySubstreamLock = -1;
        this.secondarySubstreamLock = -1;
        this.subScreenSize = 25;
        this.primaryRecord = false;
        this.secondaryRecord = false;
        this.primaryRecorder = undefined;
        this.secondaryRecorder = undefined;
    }

    @action setDevices = devices => {
        this.devices = devices;

        this.selectedVideoDeviceId = this.videoDevices[0].deviceId;
        this.selectedAudioDeviceId = this.audioDevices[0].deviceId;
    }


    @action setServer = (server) => {
        this.server = server;
    }

    @action setPluginName = (pluginName) => {
        this.pluginName = pluginName;
    }
    
    @action setRoomId = (roomId) => {
        this.roomId = roomId;
    }
    
    @action setPublisherId = (publisherId) => {
        this.publisherId = publisherId;
    }

    @action setPluginHandle = (pluginHandle) => {
        this.pluginHandle = pluginHandle;
    }

    @action setMode = (mode) => {
        this.mode = mode;
    }

    @action setMainVideo = (mainVideo) => {
        this.mainVideo = mainVideo;
    }

    @action setSubVideoHidden = (subVideoHidden) => {
        this.subVideoHidden = subVideoHidden;
    }

    @action setSubVideoPosition = (position) => {
        this.subVideoPosition = position;
    }

    @action setMemberMic = (on) => {
        this.memberMic = on;
    }

    @action setAudioDevice = (device) => {
        this.audioDevice = device;
    }

    @action addVideoStream = (stream) => {
        this.videoStreams.push(stream);
    }

    @action removeVideoStream = (streamId) => {
        let index = -1;
        for(let i in this.videoStreams) {
            const stream = this.videoStreams[i];
            if(stream.streamId === streamId) {
                index = i;
                break;
            }
        }

        this.videoStreams.splice(index, 1);
    }

    @action removeAllVideoStream = () => {
        this.videoStreams = [];
    }

    @action changeVideoStream = (streamId, stream) => {
        console.log(LogPrefix, 'ChangeVideoStream', streamId, stream);

        let index = -1;
        for(let i in this.videoStreams) {
            const stream = this.videoStreams[i];
            if(stream.stream.id === streamId) {
                index = i;
                break;
            }
        }

        this.videoStreams.splice(index, 1);
        this.videoStreams.push(stream);
    }

    @action setAudioState = (state) => {
        this.audioState = state;
    }

    @action setSoundPlayed = (played) => {
        this.soundPlayed = played;
    }

    @action setAudioMuted = (muted) => {
        this.audioMuted = muted;
    }

    @action setPrimaryState = (state) => {
        this.primaryState = state;
    }

    @action setSecondaryState = (state) => {
        this.secondaryState = state;
    }

    @action setLookAroundPublishState = (state) => {
        this.lookAroundPublishState = state;
    }

    @action setMonitoringState = (monitoringState) => {
        this.monitoringState = monitoringState;
    }

    @action setPublishButtonClicked = (isPublishButtonClicked) => {
        this.isPublishButtonClicked = isPublishButtonClicked;
    }

    @action setAudioPlayConfirm = (confirm) => {
        this.audioPlayConfirm = confirm;
    }

    @action setPrimaryPlayConfirm = (confirm) => {
        this.primaryPlayConfirm = confirm;
    }

    @action setSecondaryPlayConfirm = (confirm) => {
        this.secondaryPlayConfirm = confirm;
    }


    @action setAudioStream = (stream) => {
        this.audioStream = stream;
    }

    @action setPrimaryStream = (stream) => {
        this.primaryStream = stream;
    }

    @action setSecondaryStream = (stream) => {
        this.secondaryStream = stream;
    }

    @action setLookAroundStream = (stream) => {
        this.lookAroundStream = stream;
    }

    @action setMonitoringStream = (monitoringStream) => {
        this.monitoringStream = monitoringStream;
    }

    @action setAudioFeed = (feed) => {
        this.audioFeed = feed;
    }

    @action setPrimaryFeed = (feed) => {
        this.primaryFeed = feed;
    }

    @action setSecondaryFeed = (feed) => {
        this.secondaryFeed = feed;
    }

    @action setPrimaryStream = (stream) => {
        this.primaryStream = stream;
    }

    @action setSecondaryStream = (stream) => {
        this.secondaryStream = stream;
    }

    @action setPrimaryHighQuality = (highQuality) => {
        this.primaryHighQuality = highQuality;
    }

    @action setSecondaryHighQuality = (highQuality) => {
        this.primaryHighQuality = highQuality;
    }

    @action setPrimarySubstream = (substream) => {
        this.primarySubstream = substream;
    }

    @action setSecondarySubstream = (substream) => {
        this.secondarySubstream = substream;
    }

    @action setPrimarySubstreamLock = (substream) => {
        this.primarySubstreamLock = substream;
    }

    @action setSecondarySubstreamLock = (substream) => {
        this.secondarySubstreamLock = substream;
    }

    @action setSubScreenSize = (size) => {
        this.subScreenSize = size;
    }

    @action setPrimaryRecord = (record) => {
        this.primaryRecord = record;
    }

    @action setSecondaryRecord = (record) => {
        this.secondaryRecord = record;
    }

    @action setPrimaryRecorder = (recorder) => {
        this.primaryRecorder = recorder;
    }

    @action setSecondaryRecorder = (recorder) => {
        this.secondaryRecorder = recorder;
    }

    @action setOwner = owner => this.owner = owner;
    @action setMembers = members => {
        if(members) {
            members.forEach(m => {
                m.isDragging = false;
                m.isSelected = false;
                m.isGhosting = false;
                m.destinationGroupId = 'none';
            });
        }
        this.members = members ? members : [];
    }

    @action chatMemberJoined = (userId) => {
        const member = _.find(this.members, (m) => m.id === userId);
        if(member) {
            member.joined = true;
        }
    }

    @action chatMemberLeaved = (userId) => {
        const member = _.find(this.members, (m) => m.id === userId);
        if(member) {
            member.joined = false;
            member.mediaOn = false;
            member.audioSetup = false;
            member.audioOn = false;
            member.soundOn = false;
            member.publishType = PresentationType.NONE;
            member.publishState = MemberPublishState.None;
            member.deviceType = '';
            member.browserType = '';
        }
    }

    @action setChatMemberSoundOn = (userId, soundOn) => {
        console.log('!!!',this.members);
        const member = _.find(this.members, (m) => m.id === userId);
        if(member) {
            member.soundOn = soundOn;
        }
    }

    @action setChatMemberAudioOn = (userId, setup, muted) => {
        const member = _.find(this.members, (m) => m.id === userId);
        if(member) {
            member.audioSetup = setup;
            member.audioOn = !muted;
        }
    }

    @action setChatMemberMediaOn = (userId, mediaOn, deviceType, browserType) => {
        const member = _.find(this.members, (m) => m.id === userId);
        if(member) {
            member.mediaOn = mediaOn;
            member.deviceType = deviceType;
            member.browserType = browserType;
            if(mediaOn) {
                member.publishType = PresentationType.PRESENTATION;
            }
        }
    }

    @action setChatMemberPublishType = (userId, type) => {
        for(let i in this.members) {
            const m = this.members[i];

            if(String(m.id) === String(userId)) {
                m.publishType = type;
                break;
            }
        }
    }

    @action setMemberPublishState = (userId, state) => {
        for(let i in this.members) {
            const m = this.members[i];

            if(state === MemberPublishState.Published) {
                if(m.id === userId) {
                    m.publishState = state;
                } else {
                    if (m.publishType === PresentationType.PRESENTATION && m.mediaOn) m.publishType = PresentationType.None;
                }
            } else if(state === MemberPublishState.None) {
                if(m.id === userId) {
                    m.publishState = state;
                    m.publishType = PresentationType.PRESENTATION;
                }
                if(m.publishType === PresentationType.None && m.mediaOn) m.publishType = PresentationType.PRESENTATION;
            } else {
                if(m.id === userId) {
                    m.publishState = state;
                    break;
                }
            }
        }
    }

    @action removeRequestPublish = (userId) => {
        for(let i in this.members) {
            const m = this.members[i];

            if(m.id === userId) {
                m.publishState = MemberPublishState.None;
                break;
            }
        }
    }

    @action changeVideoOff = () => {
        this.isVideoOff = !this.isVideoOff;

        // this.monitoringPublish();
    }

    @action changeAudioOff = () => {
        this.isAudioOff = !this.isAudioOff;

        // this.monitoringPublish();
    }



    @action selectVideoDeviceId = deviceId => {

        if(deviceId === "videoOff") {
            this.isVideoOff = true;
        } else {
            this.selectedVideoDeviceId = deviceId;
            this.isVideoOff = false;
        }
        // this.monitoringPublish();
    }

    @action selectAudioDeviceId = deviceId => {
        if(deviceId === "audioOff") {
            this.isAudioOff = true;
        } else {
            this.selectedAudioDeviceId = deviceId;
            this.isAudioOff = false;
        }
        // this.monitoringPublish();
    }

    @action monitoringPublish = () => {

        const videoPublishInfo = this.isVideoOff ? false : this.videoPublishInfo;
        const audioPublishInfo = this.isAudioOff ? false : this.audioPublishInfo;

        if(this.videoStreams && this.videoStreams.length > 0 ) {
            const stream = toJS(this.videoStreams[0]);
            const clonedStream = stream.stream.clone();
            stream.clonedStream = clonedStream;

            this.unpublish({
                onSuccess: () => {
                    setTimeout(() => {
                        this.setMonitoringStream(stream);
                        this._connect(this.roomId, this.publisherId, this.publisherName, videoPublishInfo, audioPublishInfo, clonedStream);
                        console.log(LogPrefix, 'unpublish after success monitoring publish', this.monitoringStream);
                    }, 1000);
                }
            });
        }else {
            console.log(LogPrefix, 'No video stream to publish');
        }
    }

    @computed
    get videoDevices() {
        const videoDevices = this.devices.filter(device => device.deviceId !== 'default').filter(device => device.kind === 'videoinput');
        videoDevices.push({ deviceId: "videoOff", label: "비디오 끄기" });
        return videoDevices;
    }

    @computed
    get videoPublishInfo() {
        return { deviceId: this.selectedVideoDeviceId ? this.selectedVideoDeviceId : "default",width: PublishWidth, height: PublishHeight };
    }

    @computed
    get audioDevices() {
        const audioDevices =  this.devices.filter(device => device.deviceId !== 'default').filter(device => device.kind === 'audioinput');
        audioDevices.push({ deviceId: "audioOff", label: "오디오 끄기" });
        return audioDevices;
    }

    @computed
    get audioPublishInfo() {
        return { deviceId: this.selectedAudioDeviceId ? this.selectedAudioDeviceId : "default"};
    }

    connect = flow(function *connect(channelId, publisherId, publisherName, stream, callbacks) {
        try {
            const response = yield axios.get(`/api/v1/xvideos/${channelId}/x-param`);

            console.log(LogPrefix, 'monitoring connect success',response.data, publisherId, publisherName);
            const param = response.data;

            this.server = param.server;
            this.pluginName = param.pluginName;
            this.roomId = param.channelId;
            this.publisherId = publisherId;
            this.publisherName = publisherName;
            this.monitoringState = VideoState.None;
            this.pluginHandle = undefined;


            this._connect(publisherId, publisherName, stream ? stream.clonedStream : stream, callbacks);
        } catch(error) {
            console.warn(LogPrefix, "Can't get a x parameter");
        }
    });

    _connect = (publisherId, publisherName, stream, callbacks) => {
        const {server} = this;

        const that = this;
        const janus = new window.Janus({
            server: server,
            success: function() {
                console.log(LogPrefix, 'Janus connected successfully');

                that.publish(janus, publisherId, publisherName, stream, callbacks);
            },
            error: function(error) {
                console.log(LogPrefix, 'Janus connect failed', error);
            },
            destroyed: function() {
                console.log(LogPrefix, 'Janus destroyed');
            }
        });
    }

    publish = (janus, publisherId, publisherName, stream, callbacks) => {
        console.log(LogPrefix,`[RoomVideo ${janus}]`, `Publish : publisherId=${publisherId}, publisherName=${publisherName}`, stream);
        this.setMonitoringState(VideoState.Publishing);

        const {pluginName} = this;
        const that = this;
        console.log(LogPrefix, 'Attaching videoroom plugin to publisher...');
        janus.attach({
            plugin: pluginName,
            opaqueId: window.Janus.randomString(12),
            success: (pluginHandle) => {
                console.log(LogPrefix, 'Videoroom plugin attached successfully', pluginHandle,callbacks);

                that.setPluginHandle(pluginHandle);
                that.createRoom(callbacks);
            },
            error: (error) => {
                console.log(LogPrefix, 'Videoroom plugin attach failed', error);

                that.setMonitoringState(VideoState.PublishError);
                if(callbacks.onError) {
                    callbacks.onError();
                }
            },
            consentDialog: (on) => {
                console.log(LogPrefix, 'Videoroom plugin consentDialog', on);
            },
            mediaState: (medium, on) => {
                console.log(LogPrefix, 'Videoroom plugin mediaState', medium, on);
            },
            webrtcState: (on) => {
                console.log(LogPrefix, 'Vidoeroom plugin webrtcState', on);
            },
            onmessage: (msg, jsep) => {
                console.log(LogPrefix, 'Videoroom plugin onmessage', msg, jsep);

                const event = msg.videoroom;
                if(event !== undefined && event !== null) {
                    if(event === 'joined') {
                        that.createOffer(stream, callbacks);
                    } else if(event === 'event') {
                        if(msg['configured'] === 'ok') {
                            console.log(LogPrefix, 'Published');

                            //that.sendForward();
                        } else if(msg['error_code']) {
                            const errorCode = msg['error_code'];
                            if (errorCode === 436) {
                                console.log(LogPrefix, "User already exists. so kick...");

                                that.kickAndJoinRoomToPublisher(callbacks);
                            }

                            return;
                        }

                    }
                }

                if(jsep !== undefined && jsep !== null) {
                    const pluginHandle = that.pluginHandle;

                    pluginHandle.handleRemoteJsep({
                        jsep: jsep,
                        success: () => {
                            console.log(LogPrefix, 'HandleRemoteJsep sended successfully');

                            that.setMonitoringState(VideoState.Published);
                            if(callbacks && callbacks.onSuccess) {
                                callbacks.onSuccess();
                            }
                        },
                        error: (error) => {
                            console.warn(LogPrefix, 'HandleRemoteJsep send error', error);

                            that.setMonitoringState(VideoState.PublishError);
                            if(callbacks && callbacks.onError) {
                                callbacks.onError();
                            }
                        }
                    });
                }
            },
            onlocalstream: (stream) => {
                console.log(LogPrefix, 'Videoroom plugin onlocalstream', stream);

                console.log(LogPrefix, 'Audio tracks', stream.getAudioTracks());
                console.log(LogPrefix, 'Video tracks', stream.getVideoTracks());
            },
            onremotestream: (stream) => {
                console.log(LogPrefix, 'Videoroom plugin onremotestream', stream);
            },
            slowLink: (data) => {
                console.log(LogPrefix, 'Videoroom plugin slowLink', data);
            },
            oncleanup: function() {
                console.log(LogPrefix, 'Videoroom plugin oncleanup');

                console.log(LogPrefix, 'Stopping all tracks in stream', stream);
                if(stream) {
                    stream.getTracks().forEach((track) => {
                        console.log(LogPrefix, 'Stopping a track', track);
                        track.stop()
                    });
                }
            },
        });
    };
    
    createRoom = (callbacks) => {
        const {roomId, pluginHandle} = this;

        const that = this;
        const existsCommand = {
            message: {
                request: 'exists',
                room: roomId,
            },

            success: (data) => {
                console.log(LogPrefix, 'Exists command sent successfully', data);

                if(data.exists) {
                    console.log(LogPrefix, `Room is already exists : ${roomId}`);

                    this.joinRoomToPublisher(callbacks);
                } else {
                    const createCommand = {
                        message: {
                            request: 'create',
                            room: roomId,
                            publishers: 100,
                            permanent: false,
                            description: `OnTheLive ${roomId}`,
                            notify_joining: false,
                        },
                        success: (data) => {
                            console.log(LogPrefix, 'Create command sent successfully',callbacks, data);

                            this.joinRoomToPublisher(callbacks);
                        },
                        error: (error) => {
                            console.log(LogPrefix, 'Create command send failed', error);

                            that.setMonitoringState(VideoState.PublishError);
                            if(callbacks && callbacks.onError) {
                                callbacks.onError();
                            }
                        }
                    }

                    console.log(LogPrefix, 'Sending create command...', createCommand.message);
                    pluginHandle.send(createCommand);
                }
            },
            error: (error) => {
                console.log(LogPrefix, 'Exists command send failed', error);

                that.setMonitoringState(VideoState.PublishError);
                if(callbacks && callbacks.onError) {
                    callbacks.onError();
                }
            }
        };

        console.log(LogPrefix, 'Sending exists command...', existsCommand.message);
        pluginHandle.send(existsCommand);
    };

    joinRoomToPublisher = (callbacks) => {
        const {roomId, publisherId, publisherName, pluginHandle} = this;

        const that = this;
        const joinCommand = {
            message: {
                request: 'join',
                room: roomId,
                ptype: 'publisher',
                id: publisherId,
                display: publisherName,
            },
            success: () => {
                console.log(LogPrefix, 'Join command sended successfully');
            },
            error: (error) => {
                console.log(LogPrefix, 'Join command send failed', error);

                that.setMonitoringState(VideoState.PublishError);
                if(callbacks && callbacks.onError) {
                    callbacks.onError();
                }
            }
        };

        console.log(LogPrefix, 'Sending join command...', joinCommand.message);
        pluginHandle.send(joinCommand);
    };

    kickAndJoinRoomToPublisher = (callbacks) => {
        const {roomId, publisherId, pluginHandle} = this;

        const that = this;
        const kickCommand = {
            message: {
                request: 'kick',
                room: roomId,
                id: publisherId,
            },
            success: (data) => {
                console.log(LogPrefix, 'Kick command sended successfully', data);

                if(data.videoroom === 'success') {
                    that.joinRoomToPublisher(callbacks);
                } else {
                    console.log(LogPrefix, 'Kick command sended successfully but failed');

                    that.setMonitoringState(VideoState.PublishError);
                    if(callbacks && callbacks.onError) {
                        callbacks.onError();
                    }
                }
            },
            error: (error) => {
                console.log(LogPrefix, 'Kick command send failed', error);

                that.setMonitoringState(VideoState.PublishError);
                if(callbacks && callbacks.onError) {
                    callbacks.onError();
                }
            }
        };

        console.log(LogPrefix, 'Sending kick command...', kickCommand.message);
        pluginHandle.send(kickCommand);
    };

    setStreamResolution = (stream) => {
        console.log('!!!!,',stream);
        const constraints = {width: PublishWidth, height: PublishHeight};
        const videoTracks = stream.getVideoTracks();

        for(let i in videoTracks) {
            const track = videoTracks[i];
            track.applyConstraints(constraints)
                .then(() => {
                    console.log(LogPrefix, `Video Constraints apply success : ${stream.id}`, constraints);
                })
                .catch(() => {
                    console.log(LogPrefix, `Video Constraints apply error : ${stream.id}`, constraints);
                });
        }
    }

    // createOffer = (stream, callbacks) => {
    //     const {pluginHandle} = this;
    //
    //     console.log(LogPrefix, "createOffer", audio , video);
    //     const that = this;
    //     const media = {
    //         audioRecv: false,
    //         videoRecv: false,
    //     };
    //
    //     if(audio) {
    //         media.audioSend = true;
    //         media.audio = audio;
    //     } else {
    //         media.audioSend = false;
    //     }
    //     if(video) {
    //         media.videoSend = true;
    //         if(video.deviceId === 'screen') {
    //             media.video = 'screen';
    //             media.screenshareWidth = video.width;
    //             media.screenshareHeight = video.height;
    //             media.captureDesktopAudio = true;
    //         } else {
    //             media.video = video;
    //         }
    //     } else {
    //         media.videoSend = false;
    //     }
    //
    //     this.setStreamResolution(stream);
    //
    //     const createOfferCommand = {
    //         // media: {
    //         //     audioRecv: false,
    //         //     videoRecv: false,
    //         //     audio: false,
    //         //     video: true,
    //         // },
    //         // stream: stream,
    //         simulcast: false,
    //         simulcast2: false,
    //         success: (jsep) => {
    //             console.log(LogPrefix, 'Create offer sended successfully', jsep);
    //
    //             const publishCommand = {
    //                 message: {
    //                     request: 'publish',
    //                     audio: true,
    //                     video: true,
    //                     bitrate: PublishBitrate,
    //                     videocodec: 'vp8',
    //                 },
    //                 jsep: jsep,
    //                 success: (data) => {
    //                     console.log(LogPrefix, 'Publish command sended successfully', data);
    //                 },
    //                 error: (error) => {
    //                     console.log(LogPrefix, 'Publish command send failed', error);
    //
    //                     that.setMonitoringState(VideoState.PublishError);
    //                     if(callbacks && callbacks.onError) {
    //                         callbacks.onError();
    //                     }
    //                 }
    //             };
    //
    //             console.log(LogPrefix, 'Sending publish command...', publishCommand.message);
    //             pluginHandle.send(publishCommand);
    //         },
    //         error: (error) => {
    //             console.log(LogPrefix, 'Create offer send failed', error);
    //
    //             that.setMonitoringState(VideoState.PublishError);
    //             if(callbacks && callbacks.onError) {
    //                 callbacks.onError();
    //             }
    //         }
    //     };
    //     if(stream) {
    //         createOfferCommand.media = {
    //             audioRecv: false,
    //             videoRecv: false,
    //             audio: true,
    //             video: true,
    //         };
    //         createOfferCommand.stream = stream;
    //     } else {
    //         createOfferCommand.media = media;
    //     }
    //
    //     console.log(LogPrefix, 'Sending create offer...', createOfferCommand.media, createOfferCommand.stream);
    //     pluginHandle.createOffer(createOfferCommand);
    // };

    createOffer = (stream, callbacks) => {
        const {pluginHandle} = this;

        const that = this;
        this.setStreamResolution(stream);

        const createOfferCommand = {
            media: {
                audioRecv: false,
                videoRecv: false,
                audio: false,
                video: true,
            },
            stream: stream,
            simulcast: false,
            simulcast2: false,
            success: (jsep) => {
                console.log(LogPrefix, 'Create offer sended successfully', jsep);

                const publishCommand = {
                    message: {
                        request: 'publish',
                        audio: false,
                        video: true,
                        bitrate: PublishBitrate,
                        videocodec: 'vp8',
                    },
                    jsep: jsep,
                    success: (data) => {
                        console.log(LogPrefix, 'Publish command sended successfully', data);
                    },
                    error: (error) => {
                        console.log(LogPrefix, 'Publish command send failed', error);

                        that.setMonitoringState(VideoState.PublishError);
                        if(callbacks && callbacks.onError) {
                            callbacks.onError();
                        }
                    }
                };

                console.log(LogPrefix, 'Sending publish command...', publishCommand.message);
                pluginHandle.send(publishCommand);
            },
            error: (error) => {
                console.log(LogPrefix, 'Create offer send failed', error);

                that.setMonitoringState(VideoState.PublishError);
                if(callbacks && callbacks.onError) {
                    callbacks.onError();
                }
            }
        };

        console.log(LogPrefix, 'Sending create offer...', createOfferCommand.message);
        pluginHandle.createOffer(createOfferCommand);
    };


    // sendForward = () => {
    //     const {roomId, pluginHandle, publisherId} = this;
    //
    //     const forwardCommand = {
    //         message: {
    //             request : "rtp_forward",
    //             room : roomId,
    //             publisher_id : publisherId,
    //
    //             host_family : "ipv4",
    //             host : 'localhost',
    //             video_port : 1234,
    //             simulcast : false,
    //         },
    //         success: (result) => {
    //             console.log(LogPrefix, 'Send forward command success', result);
    //
    //             if(result && result.videoroom === 'rtp_forward') {
    //                 console.log(LogPrefix, 'Send forward command success');
    //             } else {
    //                 console.warn(LogPrefix, 'Send forward command success. but forward failed.', result);
    //             }
    //         },
    //         error: (error) => {
    //             console.warn(LogPrefix,'Send forward command error', error);
    //         }
    //     };
    //
    //     console.log(LogPrefix, 'Sending forward command :', forwardCommand.message);
    //     pluginHandle.send(forwardCommand);
    // };

    unpublish = (callbacks) => {
        const {pluginHandle} = this;
        const that = this;

        if(pluginHandle) {
            const unpublishCommand = {
                message: {
                    request: 'unpublish',
                },
                success: (data) => {
                    console.log(LogPrefix, 'Unpublish command sended successfully', data);

                    if(that.janus) {
                        that.janus.destroy();
                    }
                    // that.initialize();

                    if(callbacks && callbacks.onSuccess) {
                        callbacks.onSuccess();
                    }
                },
                error: (error) => {
                    console.log(LogPrefix, 'Unpublish command send failed', error);

                    if(that.janus) {
                        that.janus.destroy();
                    }
                    // that.initialize();
                }
            };

            console.log(LogPrefix, 'Sending unpublish command...', unpublishCommand.message);
            pluginHandle.send(unpublishCommand);
        }
    };
}
import {computed, flow, observable} from "mobx";
import axios from "axios";
import moment from "moment";
import _ from "lodash";

const ListState = {
    Loading: 'Loading',
    Loaded: 'Loaded',
    LoadFailed: 'LoadFailed',
};

const DateFormat = "YYYY-MM-DD";

export default class ChannelStore {
    ListType = {
        Invited: 'invited',
        Created: 'created',
        Joined: 'joined'
    };

    @observable listState = ListState.Loaded;
    @observable selectedType = this.ListType.Created;
    @observable selectedDate = '';
    @observable dateList = [];

    @computed get isLoading() {
        return this.listState === ListState.Loading;
    }

    loadChannelDateList = flow(function* loadChannelDateList(userId, loginType, isExpand, date) {
        this.listState = ListState.Loading;
        this.selectedType = loginType === 'USER' ? this.ListType.Created : this.ListType.Invited;

        try {
            const response = yield axios.get(`/api/v1/channels/broadcast-date?user-id=${userId}&type=${this.selectedType}`);
            const dateList = response.data;

            this.dateList = _.chain(dateList)
                .map((date) => ({
                    isLoading: false,
                    date: date,
                    channelList: [],
                }))
                .orderBy(['date'], ['desc'])
                .value();

            this.selectedDate = '';
            if(date) {
                this.changeExpanded(userId, date, isExpand);
            } else {
                this.changeExpanded(userId, new Date(), isExpand);
            }
            this.listState = ListState.Loaded;
        } catch(e) {
            this.listState = ListState.LoadFailed;
        }
    });

    changeExpanded = flow(function* changeExpanded(userId, date, isExpand) {
        const loadingDate = _.find(this.dateList, (d) => moment(d.date).isSame(moment(date), 'day'));

        if(loadingDate) {
            if(isExpand) {
                loadingDate.isLoading = true;

                try {
                    const channelListResponse = yield axios.get(`/api/v1/channels?user-id=${userId}&type=${this.selectedType}&search-date=${moment(loadingDate.date).format(DateFormat)}`);

                    loadingDate.isLoading = false;
                    loadingDate.channelList = _.sortBy(channelListResponse.data, ['liveStartDatetime']);
                    this.selectedDate = moment(date).format(DateFormat);
                } catch (error) {
                    loadingDate.isLoading = false;
                    loadingDate.channelList = [];
                    this.selectedDate = '';
                }
            } else {
                this.selectedDate = '';
            }
        }
    });

    // refreshExpanded = flow(function* refreshExpanded(userId) {
    //     const loadingDate = _.find(this.dateList, (d) => (this.selectedDate === d.date) && (moment(d.date).isSame(moment(this.selectedDate), 'day')));
    //
    //     if(loadingDate) {
    //         loadingDate.isLoading = true;
    //
    //         try {
    //             const channelListResponse = yield axios.get(`/api/v1/channels?user-id=${userId}&type=${this.selectedType}&search-date=${moment(loadingDate.date).format(DateFormat)}`);
    //
    //             loadingDate.isLoading = false;
    //             loadingDate.channelList = channelListResponse.data;
    //         } catch (error) {
    //             loadingDate.isLoading = false;
    //             loadingDate.channelList = [];
    //         }
    //     }
    // })
}
import React from "react";
// import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";

// const useStyles = makeStyles(theme => ({
//
// }));

export default function ConfirmDialog(props) {
    // const classes = useStyles();
    const {title, content, maxWidth, dialogOpen, onCancel, onOk} = props;

    return (
        <Dialog disableBackdropClick
                disableEscapeKeyDown
                maxWidth={maxWidth ? maxWidth : "xs"}
                open={dialogOpen}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                {onCancel ?
                    <Button key={'cancel'} onClick={onCancel} color="default">
                        취소
                    </Button>
                    :
                    null
                }
                <Button key={'ok'} autoFocus onClick={onOk} color="primary">
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    );
};
import {action, computed, flow, observable, toJS} from "mobx";
import axios from "axios";
import moment from "moment";

import * as Chat from "../views/broadcast/BroadcastChat";
import {QuizType} from "./QuizStore";

export default class BroadcastQuizStore {
    @observable dialogOpen = false;
    @observable selectedQuiz = null;
    @observable selectedQuizInfo = null;
    @observable quizList = null;
    @observable studentQuizView = false;
    @observable quizQuestionObj = null;
    @observable publishedQuestion = {};
    @observable receivedQuestion = {};
    @observable isSendingQuiz = false;
    @observable answerList = [];
    @observable isSendingAnswer = false;
    @observable sendAnswerResult = null;

    @observable quizResultDialogOpen = false;
    @observable quizResultQuestion = null;
    @observable selectedQuizResultId = null;
    @observable selectedQuizAnswer = null;
    @observable quizAnswer = '';
    @observable selectedChannelQuizOptionId = '';

    @action initData = () => {
        this.dialogOpen = false;
        this.quizList = null;
        this.selectedQuiz = null;
        this.studentQuizView = false;
        this.selectedQuizInfo = null;
        this.quizQuestionObj = null;
        this.publishedQuestion = {};
        this.receivedQuestion = {};
        this.isSendingQuiz = false;
        this.answerList = [];
        this.isSendingAnswer = false;
        this.sendAnswerResult = null;
        this.quizAnswer = '';
        this.selectedChannelQuizOptionId = '';
    };

    @action initQuizResultData = () => {
        this.quizResultDialogOpen = false;
        this.quizResultQuestion = null;
        this.selectedQuizResultId = null;
        this.selectedQuizAnswer = null;
        this.quizAnswer = '';
        this.selectedChannelQuizOptionId = '';
    };

    @action changeSelectedQuizResultId = (value) => {
        this.selectedQuizResultId = value;
    };

    @action changeAnswerList = (id, type) => {
        if(type === QuizType.SINGLE_CHOICE) {
            this.selectedChannelQuizOptionId = id;
            this.answerList = [];
        } else {
            const findIdx = this.answerList.indexOf(id);
            if (findIdx !== -1) {
                this.answerList.splice(findIdx, 1);
                return null;
            }
        }
        this.answerList.push(id);
        this.answerList = Array.from(new Set(this.answerList));
    }

    @action changeIsSendingQuiz = (value) => {
        this.isSendingQuiz = value
    }

    @action changeQuizQuestionObj = (obj) => {
        this.quizQuestionObj = obj;
    }

    @action changeQuizResultDialogOpen = (value) => {
        this.quizResultDialogOpen = value;
    }

    @action changeDialogOpen = (value) => {
        this.dialogOpen = value;
    }

    @action changeSelectedQuiz = (quiz) => {
        this.selectedQuiz = quiz ? quiz.quizId : "";
    }

    @action changeStudentQuizView = (value) => {
        this.studentQuizView = value;
    }

    @action changeQuizAnswer = value => this.quizAnswer = value;

    @computed
    get receivedQuiz() {
        const getQuizObjOptions = () => {
            return this.receivedQuestion.channelQuizOptions.map((item) => {
                return {
                    channelQuizOptionId: item.channelQuizOptionId,
                    quizQuestionId: item.quizQuestionId,
                    quizOptionNumber: item.quizOptionNumber,
                    quizOptionContents: item.quizOptionContents,
                }
            });
        };

        return (this.receivedQuestion && this.receivedQuestion.channelQuizOptions) ? {
            quizQuestionId: this.receivedQuestion.quizQuestionId,
            quiz: {
                quizId: this.receivedQuestion.channelQuizId,
                userId: this.receivedQuestion.userId,
                quizTitle: this.receivedQuestion.quizTitle,
                quizQuestion: this.receivedQuestion.quizQuestion,
                type: this.receivedQuestion.type,
                quizImages: this.receivedQuestion.channelQuizImages,
                options: getQuizObjOptions(),
            }
        } : {};
    }

    getQuizList = flow(function* getQuizList(userId) {
        this.quizList = null;
        try{
            const response = yield axios.get(`/api/v1/quiz`, {params: {
                "user-Id": userId,
                paging: 'no'
            }});

            if(response.status === 200 && response.data) {
                response.data.sort((item, nextItem) => {
                    if(moment(item.createdDatetime).isSameOrBefore(moment(nextItem.createdDatetime))) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            }
            this.quizList = response.data;
            this.selectedQuiz = response.data ? response.data[0].quizId : null;
        } catch (e) {
            console.log('error', e);
        }
    });

    getQuizInfo = flow(function* getQuizInfo() {
        this.selectedQuizInfo = null;
        try {
            if(!this.selectedQuiz) {
                return null;
            }
            yield axios.get(`/api/v1/quiz/quiz-info`, {params: {"quiz-id": this.selectedQuiz}});
        } catch (e) {
            console.log('error', e);
        }
    });

    getQuizResultQuestion = flow(function* getQuizResultQuestion(channelId) {
        this.quizResultQuestion = null;
        try {
            const response = yield axios.get(`/api/v1/quiz/questions`, {params: {
                    "channel-id": channelId,
                    paging: "no",
                }});

            if(response.status === 200 && response.data) {
                response.data.sort((item, nextItem) => {
                    if(moment(item.createdDatetime).isSameOrBefore(moment(nextItem.createdDatetime))) {
                        return 1;
                    } else {
                        return -1;
                    }
                });

                this.quizResultQuestion = response.data;
                this.selectedQuizResultId = response.data[0].quizQuestionId;
                this.getQuizResultQuestionAnswer(response.data[0].quizQuestionId);
            }
        } catch (e) {
            console.log('error', e);
        }
    });

    getQuizResultQuestionInfo = flow(function* getQuizResultQuestionInfo(quizQuestionId) {
        try {
            yield axios.get(`/api/v1/quiz/questions-info`, {params: {
                    "quiz-question-id": quizQuestionId,
                }});
        } catch (e) {
            console.log('error', e);
        }
    });

    getQuizResultQuestionAnswer = flow(function* getQuizResultQuestionAnswer(quizQuestionId) {
        this.selectedQuizAnswer = null;
        try {
            const response = yield axios.get(`/api/v1/quiz/answer`, {params: {
                    "quiz-question-id": quizQuestionId,
                    paging: "no",
                }});
            if(response.status === 200) {
                this.selectedQuizAnswer = response.data;
            }
        } catch (e) {
            console.log('error', e);
        }
    });

    postQuiz = flow(function* postQuiz(channelId) {
        this.publishedQuestion = {};
        try {
            const response = yield axios.post(`/api/v1/quiz/questions`, {channelId: channelId, quizId: this.selectedQuiz});
            if(response.status === 200) {
                this.publishedQuestion = response.data;
                Chat.handleSendQuiz();
            }
        } catch (e) {
            console.log('error', e);
        }
    });

    postRoomQuiz = flow(function* postRoomQuiz(channelId, sendQuiz) {
        this.publishedQuestion = {};
        try {
            const response = yield axios.post(`/api/v1/quiz/questions`, {channelId: channelId, quizId: this.selectedQuiz});
            if(response.status === 200) {
                this.publishedQuestion = response.data;
                sendQuiz();
            }
        } catch (e) {
            console.log('error', e);
        }
    });

    receivedRoomQuiz = flow(function* receivedRoomQuiz(quizQuestionId) {
        this.receivedQuestion = {};
        try {
            const response = yield axios.get(`/api/v1/quiz/questions-info?quiz-question-id=${quizQuestionId}`);
            if(response.status === 200) {
                this.receivedQuestion = response.data;
                this.studentQuizView = true;
            }
        } catch (e) {
            console.log('error', e);
        }
    });

    doAnswer = flow(function* doAnswer() {
        this.isSendingAnswer = true;
        try {
            const response = yield axios.post(`/api/v1/quiz/answer`, {channelQuizOptionIdList: this.answerList, quizQuestionId: this.quizQuestionObj.quizQuestionId});
            if(response.status === 200) {
                this.sendAnswerResult = response.data;
                this.isSendingAnswer = false;
                this.initData();
            }
        } catch (e) {
            console.log('error', e);
        }
    });

    doAnswerSubjective = flow(function* doAnswerSubjective(subjectiveAnswer) {
        this.isSendingAnswer = true;
        try {
            const response = yield axios.post(`/api/v1/quiz/answer/subjective`, subjectiveAnswer);
            if(response.status === 200) {
                this.sendAnswerResult = response.data;
                this.isSendingAnswer = false;
                this.initData();
            }
        } catch (e) {
            console.log('error', e);
        }
    });

    addQuiz = flow(function* addQuiz(newQuiz, type, channelId) {
        try {
            newQuiz.type = type;
            const param = toJS(newQuiz);
            const response = yield axios.post('/api/v1/quiz', param);
            if(response.status === 200) {
                this.selectedQuiz = response.data;
                this.postQuiz(channelId);
            }
        } catch(error) {}
    });

    addRoomQuiz = flow(function* addRoomQuiz(newQuiz, type, channelId, sendQuiz) {
        try {
            newQuiz.type = type;
            const param = toJS(newQuiz);
            const response = yield axios.post('/api/v1/quiz', param);
            if(response.status === 200) {
                this.selectedQuiz = response.data;
                this.postRoomQuiz(channelId, sendQuiz);
            }
        } catch(error) {}
    });
}
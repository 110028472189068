import React from "react";
import {Container, Grid, Typography} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import FormControl from "@material-ui/core/FormControl";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from '@material-ui/icons/Search';
import Fab from "@material-ui/core/Fab";
import ClearIcon from '@material-ui/icons/Clear';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddIcon from '@material-ui/icons/Add';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
        position: 'relative'
    },
    topRight: {
        marginTop: theme.spacing(10),
        position: 'absolute',
        top: '8px',
        right: '56px',
        fontSize: '18px',
        textAlign: '-webkit-right',
        [theme.breakpoints.down('xs')]: {
            right:'2em'
        }
        // marginRight: '77px',
        // display: 'flex',
        // flexDirection: 'column',
        // alignItems: 'center',
        // width:'200px',
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        marginTop: theme.spacing(2),
    },
    createButtonContainer: {
        margin: theme.spacing(1),
        textAlign: 'right',
        justifyContent: 'right',
    },
    defaultBoxStyle:{
        '& > div':{
            boxShadow:'none',
            //border:'1px solid #eee',
        },
    },
    searchBackGround: {
        backgroundColor: "#f9f9f9",
        width: '100%',
        height: '82px',
        alignItems: 'center',
    },
    search: {
        position: 'relative',
        top: '22%',
        borderRadius: '24px',
        // backgroundColor: fade(theme.palette.common.white, 0.15),
        // '&:hover': {
        //     backgroundColor: fade(theme.palette.common.white, 0.25),
        // },
        backgroundColor: "#ffffff",
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '48px',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        color: '#3437CE',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        width: '100%',
        height: '100%',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    addIcon: {
        backgroundColor: "#000000",
        width: '48px',
        height: '48px',
        display: 'flex',
    },
    addText: {
        fontSize: '12px',
        opacity: '0.56',
        textAlign: 'center',
        color: 'rgba(51, 51, 51, 0.56)',
        marginTop: '12px',
        marginRight: '-1.87em',
    },
    clearIcon: {
        backgroundColor: "#878787",
        width: '48px',
        height: '48px',
        display: 'flex',
    },
    studentGuidIcon: {
        backgroundColor: "#3437CE",
        color: '#ffffff',
        display: 'flex',
        marginTop: '15px',
        '& > span > a > span': {
            marginBottom: theme.spacing(1),
        },
    },
    teacherGuidIcon: {
        backgroundColor: "#3c3c3c",
        color: '#ffffff',
        display: 'flex',
        marginTop: '15px',
        '& > span > a > span': {
            marginBottom: theme.spacing(1),
        }
    },
}));

export default function FaqTemplates(props) {
    const classes = useStyles();
    const [isGuideOpen, setGuideOpen] = React.useState(false);
    const { searchWord, setSearchWord, tab, expansionPanel } = props;
    return (
        <>
            <Container component="main" maxWidth="lg" className={classes.mainContainer}>
                <div className={classes.appBarSpacer} />
                <div className={classes.mainContent} >
                    <Typography variant="h4" component="h2">{'FAQ'}</Typography>
                    <FormControl margin="dense">
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                            <Link color="inherit" underline="none">Home</Link>
                            <Link color="inherit" underline="none">{'faq'}</Link>
                        </Breadcrumbs>
                    </FormControl>

                    <div className={classes.createButtonContainer}>
                            <div style={{height: 14}}/>
                    </div>

                    <Grid container>
                        <Grid item xs={12}>
                            라이브온에어에 관련 FAQ를 확인해보세요.
                        </Grid>

                        <div className={classes.createButtonContainer}>
                            <div style={{height: 14}}/>
                        </div>

                        <Grid item xs={12}>
                            <div className={classes.searchBackGround}>
                                <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon />
                                    </div>
                                    <InputBase
                                        placeholder="질문을 검색하세요."
                                        classes={{
                                            root: classes.inputRoot,
                                            input: classes.inputInput,
                                        }}
                                        value={searchWord}
                                        onChange={event => setSearchWord(event.target.value)}
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                </div>
                            </div>
                        </Grid>

                        <div className={classes.createButtonContainer}>
                            <div style={{height: 14}}/>
                        </div>

                        <Grid item xs={12}>
                            {tab}
                        </Grid>

                        <Grid item xs={12}>
                            {expansionPanel}
                        </Grid>
                    </Grid>
                </div>
            </Container>
            <div className={classes.topRight}>
                {/* {isGuideOpen ?
                    <>
                        <Fab className={classes.clearIcon} color="primary" aria-label="add"
                             onClick={() => setGuideOpen(false)}
                        >
                            <ClearIcon/>
                        </Fab>
                        <Fab className={classes.studentGuidIcon} variant="extended">
                            <GetAppIcon className={classes.extendedIcon}/>
                             <Link href={'https://files.onthe.live/manual/가이드북(학생용).pdf'} color={"inherit"} download>학생 가이드북 다운로드</Link> 
                        </Fab> */}
                        {/*<Fab className={classes.teacherGuidIcon} variant="extended">*/}
                        {/*    <GetAppIcon className={classes.extendedIcon}/>*/}
                        {/*    <Link href={'https://files.onthe.live/manual/가이드북.pdf'} color={"inherit"} download>가이드북 다운로드</Link>*/}
                        {/*</Fab>*/}
                    {/* </>
                    :
                    <>
                        <Fab className={classes.addIcon} color="primary" aria-label="add"
                             onClick={() => setGuideOpen(true)}
                        >
                            <AddIcon/>
                        </Fab>
                        <div className={classes.addText}>가이드북 다운로드</div>
                    </>
                } */}

            </div>
        </>
    );
}

// export default withSnackbar(withRouter(withStyles(styles) (FaqTemplates)));
import {action, flow, observable} from "mobx";
import axios from "axios";
import * as LookAroundJanus from "../views/lookaround/LookAroundJanus";
import {publishType} from "../views/lookaround/LookAroundJanus";

export default class LookAroundStore {
    @observable userId = null;
    @observable userInfo = null;
    @observable channel = null;
    @observable channelWsMainURL = null;
    @observable pluginHandle = null;
    @observable screenSharingPluginHandle = null;
    @observable selectedCamera = null;
    @observable lookAroundRemotePluginHandleList = [];
    @observable isLookAroundSoundOn = false;

    @action initData = () => {
        this.userId = null;
        this.userInfo = null;
        this.channel = null;
        this.channelWsMainURL = null;
        this.pluginHandle = null;
        this.screenSharingPluginHandle = null;
        this.selectedCamera = null;
        this.lookAroundRemotePluginHandleList = [];
    };

    @action setCameraId = selectedCamera => this.selectedCamera = selectedCamera;

    @action changePluginHandle = (obj) => {
        this.pluginHandle = obj;
    }

    @action changeScreenSharingPluginHandle = obj => this.screenSharingPluginHandle = obj;

    @action addLookAroundRemotePluginHandle = (feed, pluginHandle) => {
        let duplicateIndex = this.lookAroundRemotePluginHandleList.findIndex((item) => item.feed === feed);
        if(duplicateIndex !== -1) {
            this.lookAroundRemotePluginHandleList.splice(duplicateIndex);
        }

        this.lookAroundRemotePluginHandleList.push({
            feed: feed,
            pluginHandle: pluginHandle,
        });
    }

    @action initLookAroundJanusPublisher = (isTeacher, pType) => {
        this.initLookAroundJanus(LookAroundJanus.janusJoinPType.publisher, pType, "look-around-" + this.userInfo.userId, "look-around-" + this.userInfo.userId, isTeacher);
    };

    @action initLookAroundScreenShareJanusPublisher = () => {
        this.initLookAroundJanus(LookAroundJanus.janusJoinPType.publisher, publishType.SCREEN_SHARING, "look-around-screen-share-" + this.channel.userId, "look-around-screen-share-" + this.channel.userId, true);
    };

    @action initLookAroundJanus = (janusJoinType, pType, feed, videoElId, isTeacher) => {
        //console.log('initJanus');
        LookAroundJanus.initJanus({
            serverAddr: this.channelWsMainURL,
            channelId: this.channel.channelId,
            channelOwnerUserId: this.channel.userId,
            userId: this.userInfo.userId,
            userName: this.userInfo.userName,
            recordFlag: this.userInfo.recordFlag,
            type: janusJoinType,
            publishType: pType,
            changePluginHandle: pType === publishType.SCREEN_SHARING ? this.changeScreenSharingPluginHandle : this.changePluginHandle,
            videoElId: videoElId,
            feed: feed,
            isTeacher: isTeacher,
            lookAroundRemotePluginHandleList: this.lookAroundRemotePluginHandleList,
            addLookAroundRemotePluginHandle: this.addLookAroundRemotePluginHandle,
            maxPublishers: this.channel ? this.channel.channelMemberList.length + 2 : 100,
            cameraDeviceId: this.selectedCamera,
        });
    }

    @action janusRoomDestroy = () => {
        setTimeout(() => {
            if(this.pluginHandle) {
                LookAroundJanus.roomDestroy(this.pluginHandle);
            }
            this.initData();
        }, 1000);
    }

    @action lookAroundTeacherMutedControl = (isON) => {
        const localFeed = document.getElementById(`look-around-${this.userId}`);
        localFeed.muted = isON;
        this.lookAroundRemotePluginHandleList.forEach(data => {
            const lookAroundFeed = document.getElementById(data.feed);
            if(lookAroundFeed) lookAroundFeed.muted = isON;
        });
        this.isLookAroundSoundOn = !isON;
    }

    @action lookAroundStudentMutedControl = (isON) => {
        const shareScreen = document.getElementById(`look-around-screen-share-${this.channel.userId}`);
        if(shareScreen) shareScreen.muted = isON;
        this.isLookAroundSoundOn = !isON;
    }

    getUser = flow(function* (userId) {
        try{
            const response = yield axios.get(`/api/v1/users/user-info`, {params: {"user-id": userId}});
            this.userInfo = response.data;
        } catch (e) {
            console.log('get user error');
            console.log(e);
        }
    });

    getChannel = flow(function* (channelId, userId, loginToken, isTeacher) {
        this.loading = true;
        this.userId = userId;

        try {
            const response = yield axios.get(`/api/v1/channels/channel-info`, {params: {"channel-id": channelId}});

            if(response.status !== 200) {
                new Error("get channel fail");
            }

            if(response.data) {
                response.data.channelBroadcastEndpointList.forEach((item) => {
                    if(item.typeCode === "MAIN") {
                        this.channelWsMainURL = item.playbackEndpoint + "?sign=" + loginToken;
                    }
                });
            }

            this.channel = response.data;
            this.initLookAroundJanusPublisher(isTeacher, publishType.WEB_CAM);
            this.loading = false;
        } catch (e) {
            console.log('error');
            console.log(e);
            this.loading = false;
        }
    });
}
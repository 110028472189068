import React from 'react';
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";

import {withSnackbar} from "notistack";
import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogContent, DialogTitle, FormGroup, Grid} from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import {QuizType} from "../../../stores/QuizStore";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Autocomplete from "@material-ui/lab/Autocomplete";

const styles = (theme) => ({
});

@inject("broadcastQuizStore", "roomStore", "quizStore", "authStore")
@observer
class BroadcastQuizDialog extends React.Component{
    componentDidMount() {
    }

    handleClose = () => {
        const {changeDialogOpen, initData, changeStudentQuizView} = this.props.broadcastQuizStore;
        initData();
        changeDialogOpen(false);
        changeStudentQuizView(false);
    }

    handleSendQuiz = () => {
        const {channel} = this.props.roomStore;
        const {changeIsSendingQuiz} = this.props.broadcastQuizStore;

        changeIsSendingQuiz(true);
        if(channel) {
            this.props.broadcastQuizStore.postRoomQuiz(channel.channelId, this.props.sendQuiz);
        }
    }

    handleAnswer = () => {
        const { answerList } = this.props.broadcastQuizStore;
        if(answerList && answerList.length > 0) {
            this.props.broadcastQuizStore.doAnswer();
            this.handleClose();
        }
    }

    handleAnswerSubjective = () => {
        const { quizAnswer, receivedQuiz } = this.props.broadcastQuizStore;
        const subjectiveAnswer = {
            "quizQuestionId" : receivedQuiz.quizQuestionId,
            "userId" : receivedQuiz.quiz.userId,
            "channelQuizOptionId" : 1,
            "quizOptionContents" : quizAnswer
        }
        if(quizAnswer.length > 0) {
            this.props.broadcastQuizStore.doAnswerSubjective(subjectiveAnswer);
            this.handleClose();
        }
    }

    getDialogOpen = () => {
        const {dialogOpen, studentQuizView} = this.props.broadcastQuizStore;
        const {channel, userId} = this.props.roomStore;
        if(channel && String(channel.userId) === String(userId) && dialogOpen) {
            return true;
        }

        return !!(channel && String(channel.userId) !== String(userId) && studentQuizView);
    }

    handleChangeQuizAnswer = (event, strLimit) => {
        const answer = event.target.value.length <= strLimit ? event.target.value : event.target.value.substring(0, strLimit - 1);
        this.props.broadcastQuizStore.changeQuizAnswer(answer);
    }

    render() {
        // const { classes } = this.props;
        const { selectedQuiz, studentQuizView, quizList, receivedQuiz, isSendingQuiz, answerList, isSendingAnswer, quizAnswer, selectedChannelQuizOptionId } = this.props.broadcastQuizStore;
        const { changeSelectedQuiz, changeAnswerList } = this.props.broadcastQuizStore;
        const { channel, userId } = this.props.roomStore;
        const renderDialogContent = () => {
            if(channel && String(channel.userId) === String(userId)) {
                return (
                    <React.Fragment>
                        <DialogTitle>
                            퀴즈
                        </DialogTitle>
                        <DialogContent>
                            {/*<TextField*/}
                            {/*    select*/}
                            {/*    fullWidth*/}
                            {/*    label={"퀴즈 선택"}*/}
                            {/*    variant={"outlined"}*/}
                            {/*    value={selectedQuiz !== null ? selectedQuiz : ""}*/}
                            {/*    onChange={(event) => changeSelectedQuiz(event.target.value)}*/}
                            {/*>*/}
                            {/*    {*/}
                            {/*        quizList ? quizList.map((item) => {*/}
                            {/*            return (*/}
                            {/*                <MenuItem key={"quiz-list"+item.quizId} value={item.quizId}>{item.quizTitle}</MenuItem>*/}
                            {/*            )*/}
                            {/*        }) : <MenuItem value={""} />*/}
                            {/*    }*/}
                            {/*</TextField>*/}
                            <Autocomplete
                                onChange={(event, value) => {
                                    changeSelectedQuiz(value);
                                }}
                                options={quizList ? quizList.map((item) => {
                                    return ({
                                            quizId : item.quizId,
                                            title : item.quizTitle
                                    })}) : []
                                }
                                getOptionSelected={(option,value) => option.quizId === value.quizId}
                                getOptionLabel={(option) => `${option.title}`}
                                size="small"
                                renderInput={(params) => <TextField {...params} label="" variant="outlined"  placeholder="퀴즈를 검색 해주세요." />}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                style={{
                                    color: 'rgba(0, 0, 0, 0.56)'
                                }}
                                onClick={this.handleClose}
                            >
                                취소
                            </Button>
                            <Button
                                style={{
                                    color: '#d64e4e'
                                }}
                                disabled={!selectedQuiz || isSendingQuiz}
                                // disabled={true}
                                onClick={() => this.handleSendQuiz()}
                            >
                                퀴즈 내기
                            </Button>
                            {/*<Button color="primary" onClick={this.handleClickAddQuiz}>퀴즈추가</Button>*/}
                        </DialogActions>
                    </React.Fragment>
                );
            }

            if(receivedQuiz && receivedQuiz.quiz && receivedQuiz.quiz.options && studentQuizView) {
                return (
                    <React.Fragment>
                        <DialogTitle>
                            {receivedQuiz.quiz.quizTitle}
                        </DialogTitle>
                        <DialogContent
                            style={{
                                wordBreak: 'break-all',
                            }}
                        >
                            <Typography variant={"subtitle1"} gutterBottom>
                                {receivedQuiz.quiz.quizQuestion}
                            </Typography>

                            <Grid container spacing={2}>
                                {receivedQuiz.quiz.quizImages && receivedQuiz.quiz.quizImages.map((quizImage, index) => (
                                    <Grid item xs={12/(receivedQuiz.quiz.quizImages.length >= 5 ? 4 : receivedQuiz.quiz.quizImages.length)} key={`channelQuizInfoImagePreview-${index}`}>
                                        <img src={`data:${quizImage.type};base64,${quizImage.image}`} width={"100%"} alt="" />
                                    </Grid>
                                ))}
                            </Grid>

                            <FormControl component="fieldset" style={{width: '100%'}}>
                                {/*<FormLabel component="legend">Gender</FormLabel>*/}
                                {(receivedQuiz.quiz.type === QuizType.SUBJECTIVE || receivedQuiz.quiz.type === QuizType.SHORT_ANSWER) ?
                                    <TextField id="answer"
                                               margin="normal"
                                               label={<Typography variant="subtitle1" >주관식 {receivedQuiz.quiz.type === QuizType.SHORT_ANSWER ? "(10자 이내)" : "(250자 이내)"}</Typography>}
                                               value={quizAnswer}
                                               onChange={event => this.handleChangeQuizAnswer(event, receivedQuiz.quiz.type === QuizType.SHORT_ANSWER ? 10 : 250)}
                                               InputLabelProps={{shrink: true}}
                                               InputProps={{}}
                                               fullWidth />
                                    :
                                    <FormGroup>
                                        {receivedQuiz.quiz.type === QuizType.MULTIPLE_CHOICE ?
                                            receivedQuiz.quiz.options.map((item, index) => (
                                                <FormControlLabel key={"quiz-check-"+index}
                                                                  control={
                                                                      <Checkbox
                                                                          checked={answerList.findIndex((answer) => answer.toString() === item.channelQuizOptionId.toString()) !== -1}
                                                                          onChange={(event) => changeAnswerList(event.target.value, QuizType.MULTIPLE_CHOICE)}
                                                                          value={item.channelQuizOptionId}
                                                                      />
                                                                  }
                                                                  label={item.quizOptionContents}
                                                />
                                            ))
                                            :
                                            <RadioGroup aria-label="quiz-radio" name="quiz-radio" value={selectedChannelQuizOptionId} onChange={(event) => changeAnswerList(event.target.value, QuizType.SINGLE_CHOICE)}>
                                                {receivedQuiz.quiz.options.map((item, index) => <FormControlLabel
                                                    key={"quiz-radio-"+index} value={item.channelQuizOptionId}
                                                    checked={selectedChannelQuizOptionId === item.channelQuizOptionId.toString()}
                                                    control={<Radio />}
                                                    label={item.quizOptionContents} />)}
                                            </RadioGroup>
                                        }
                                    </FormGroup>
                                }
                            </FormControl>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                style={{
                                    color: 'rgba(0, 0, 0, 0.56)'
                                }}
                                onClick={this.handleClose}
                            >
                                취소
                            </Button>
                            <Button
                                style={{
                                    color: '#d64e4e'
                                }}
                                disabled={isSendingAnswer}
                                onClick={(receivedQuiz.quiz.type === QuizType.SUBJECTIVE || receivedQuiz.quiz.type === QuizType.SHORT_ANSWER) ? this.handleAnswerSubjective : this.handleAnswer}
                            >
                                전송
                            </Button>
                        </DialogActions>
                    </React.Fragment>
                );
            }

            return (
                <DialogTitle>퀴즈</DialogTitle>
            );
        }

        return (
            <Dialog open={this.getDialogOpen()} onClose={this.handleClose} fullWidth={true} maxWidth={'xs'} >
                {renderDialogContent()}
            </Dialog>
        );
    }
}

export default withSnackbar(withRouter(withStyles(styles) (BroadcastQuizDialog)));

import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Chip, FormControl, FormHelperText, Grid, InputLabel, Select, TextField, Typography} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";


const useStyles = makeStyles(theme => ({
    formContainer:{
        '& > div':{
            marginBottom:theme.spacing(2),
            marginTop:theme.spacing(1),
        }
    },
    itemHeader: {
    },
    itemBody: {
        paddingTop: theme.spacing(1),
    },
    itemBody2: {
        paddingTop: theme.spacing(1) + 1,
    },
    rightAlignTextBody: {
        paddingTop: 32,
        paddingRight: theme.spacing(2),
        textAlign: 'right'

    },
    noLabelItemContainer: {
        paddingTop: 25,
    },
    buttonGrid: {
        textAlign: 'center',
        paddingTop: 32,
    },
    button: {
        borderRadius: 18,
    },
    chip: {
        backgroundColor: '#fafafa',
        margin: 2,
    },
    inline: {
        display: 'inline',
    },
    disableInputLine:{
        '& > div':{
            marginTop:theme.spacing(1),
        },
        '& div::before':{
            border:'0 none'
        },
        '& div input':{
            fontSize:'16px',
            fontWeight:'700',
            textAlign:'right',
            color: theme.palette.primary.main,
        },
        '& > label':{
            color: '#333333!important',
            fontWeight:'700',
            fontSize:'1rem',
        }
    },
    noGroupText: {
        color: '#ffffff',
        width: '100%',
        textAlign: 'center',
    },
}));

const AMList = [
    {value: '00', text: '0시 (오전 0시)'},
    {value: '01', text: '1시 (오전 1시)'},
    {value: '02', text: '2시 (오전 2시)'},
    {value: '03', text: '3시 (오전 3시)'},
    {value: '04', text: '4시 (오전 4시)'},
    {value: '05', text: '5시 (오전 5시)'},
    {value: '06', text: '6시 (오전 6시)'},
    {value: '07', text: '7시 (오전 7시)'},
    {value: '08', text: '8시 (오전 8시)'},
    {value: '09', text: '9시 (오전 9시)'},
    {value: '10', text: '10시 (오전 10시)'},
    {value: '11', text: '11시 (오전 11시)'},
];
const PMList = [
    {value: '12', text: '12시 (오후 0시)'},
    {value: '13', text: '13시 (오후 1시)'},
    {value: '14', text: '14시 (오후 2시)'},
    {value: '15', text: '15시 (오후 3시)'},
    {value: '16', text: '16시 (오후 4시)'},
    {value: '17', text: '17시 (오후 5시)'},
    {value: '18', text: '18시 (오후 6시)'},
    {value: '19', text: '19시 (오후 7시)'},
    {value: '20', text: '20시 (오후 8시)'},
    {value: '21', text: '21시 (오후 9시)'},
    {value: '22', text: '22시 (오후 10시)'},
    {value: '23', text: '23시 (오후 11시)'},
];


export default function ChannelInputComponent(props) {
    const classes = useStyles();
    const [alertDialogOpen, setAlertDialogOpen] = React.useState(false);
    const {channel, memberCount, groupList, selectedGroupId, isBeforeStartTime, isOverMember} = props;
    const {changeChannelName, changeChannelDesc,
        changeChannelStartDate, changeChannelStartAmPm, changeChannelStartHour, changeChannelStartMinute, changeChannelLiveTime,
        changeSelectedGroup, removeGroup, removeMember,
        // changeChannelSubScreenAvailableFlag,
        changeChannelVersion} = props;

    const handleChangeChannelName = (event) => {
        const channelName = event.target.value;

        changeChannelName(channelName);
    }

    const handleChangeChannelDesc = (event) => {
        const channelDesc = event.target.value;

        changeChannelDesc(channelDesc);
    }

    const handleChangeChannelStartDate = (date) => {
        changeChannelStartDate(date);
    }

    const handleChangeChannelStartAmPm = (event) => {
        const ampm = event.target.value;

        changeChannelStartAmPm(ampm === '오전');
    }

    const handleChangeChannelStartHour = (event) => {
        const hour = Number(event.target.value);

        if(!isNaN(hour)) {
            changeChannelStartHour(hour);
        }
    }

    const handleChangeChannelStartMinute = (event) => {
        const minute = Number(event.target.value);

        if(!isNaN(minute)) {
            changeChannelStartMinute(minute);
        }
    }

    const handleChangeChannelLiveTime = (event) => {
        const liveTime = Number(event.target.value);

        if(!isNaN(liveTime)) {
            changeChannelLiveTime(liveTime);
        }
    }

    const handleChangeSeletecdGroup = (event) => {
        changeSelectedGroup(event.target.value);
    }


    // const handleChangeChannelSubScreenAvailableFlag = (event) => {
    //     const flag = event.target.value;
    //
    //     if(flag === 'true') {
    //         changeChannelSubScreenAvailableFlag(true);
    //     } else if(flag === 'false') {
    //         changeChannelSubScreenAvailableFlag(false);
    //     }
    // }

    const handleChangeChannelVersion = (event) => {
        const version = Number(event.target.value);

        //if(version > 1) {
        //    setAlertDialogOpen(true);
        //} else {
            changeChannelVersion(version);
        //}
    }

    const handleClickRemoveGroup = (groupId) => {
        removeGroup(groupId);
    }

    const handleClickRemoveMember = (email) => {
        removeMember(email);
    }

    const handleAlertDialogOk = () => {
        changeChannelVersion(2);
        setAlertDialogOpen(false);
    }

    const handleAlertDialogClose = () => {
        changeChannelVersion(1);
        setAlertDialogOpen(false);
    }

    return (
        <Grid container spacing={1} className={classes.formContainer}>
            <Grid item xs={8}>
                <TextField id="channel-name"
                           margin="normal"
                           label={<Typography variant="subtitle1" >강의 명</Typography>}
                           value={channel.channelName}
                           onChange={handleChangeChannelName}
                           helperText={channel.channelName.length < 1 ? '* 강의 명을 입력해 주세요' : ' '}
                           InputLabelProps={{shrink: true, className: classes.itemHeader}}
                           InputProps={{className: classes.itemBody}}
                           autoFocus fullWidth
                           error={channel.channelName.length < 1}
                />
            </Grid>
            <Grid item xs={4}>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="channel-sub-label" className={classes.itemHeader}>
                        <Typography variant="subtitle1" >강의 화면</Typography>
                        {/*<Typography variant="subtitle1" >부화면 사용여부</Typography>*/}
                    </InputLabel>
                    <Select
                        labelId="channel-sub-label"
                        id="channel-sub-select"
                        className={classes.itemBody}
                        value={channel.version}
                        onChange={handleChangeChannelVersion}
                        native
                    >
                        <option value={'1'}>v1.0</option>
                        <option value={'2'}>v2.0</option>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <TextField id="channel-desc"
                           margin="normal"
                           label={<Typography variant="subtitle1" >설명</Typography>}
                           value={channel.channelDesc}
                           onChange={handleChangeChannelDesc}
                           helperText={channel.channelDesc.length < 1 ? '* 강의 설명을 입력해 주세요' : ' '}
                           InputLabelProps={{shrink: true, className: classes.itemHeader}}
                           InputProps={{className: classes.itemBody}}
                           fullWidth
                           error={channel.channelDesc.length < 1}
                />
            </Grid>
            <Grid item container spacing={1} xs={12} sm={9} >
                <Grid item xs={5}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="ko">
                        <KeyboardDatePicker id="channel-start-datetime-picker"
                                            format="LL"
                                            margin="normal"
                                            variant="dialog"
                                            okLabel="확인"
                                            cancelLabel="취소"
                                            label={<Typography variant="subtitle1" >시작 시각</Typography>}
                                            value={channel.liveStartDatetime}
                                            onChange={handleChangeChannelStartDate}
                                            helperText={isBeforeStartTime ? '* 현재 이후의 시각으로 설정 해 주세요.' : ' '}
                                            KeyboardButtonProps={{'aria-label': 'change start date'}}
                                            InputLabelProps={{shrink: true, className: classes.itemHeader}}
                                            InputProps={{className: classes.itemBody }}
                                            error={isBeforeStartTime}
                                            fullWidth
                                            // disablePast
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth margin="normal" className={classes.noLabelItemContainer}>
                        <Select value={moment(channel.liveStartDatetime).format('A')}
                                onChange={handleChangeChannelStartAmPm}
                                native
                        >
                            <option value='오전'>오전</option>
                            <option value='오후'>오후</option>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl fullWidth margin="normal" className={classes.noLabelItemContainer}>
                        <Select value={moment(channel.liveStartDatetime).format('HH')}
                                onChange={handleChangeChannelStartHour}
                                native
                        >
                            {moment(channel.liveStartDatetime).format('A') === '오전' ?
                                AMList.map((item, index) =>
                                    <option key={`time-${item.value}`} value={item.value}>{item.text}</option>
                                )
                                :
                                PMList.map((item, index) =>
                                    <option key={`time-${item.value}`} value={item.value}>{item.text}</option>
                                )
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={2}>
                    <FormControl fullWidth margin="normal" className={classes.noLabelItemContainer}>
                        <Select value={moment(channel.liveStartDatetime).format('mm')}
                                onChange={handleChangeChannelStartMinute}
                                native
                        >
                            <option value={'00'}>00 분</option>
                            <option value={'10'}>10 분</option>
                            <option value={'20'}>20 분</option>
                            <option value={'30'}>30 분</option>
                            <option value={'40'}>40 분</option>
                            <option value={'50'}>50 분</option>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={3}>
                <FormControl fullWidth margin="normal" style={{paddingTop: 4}}>
                    <InputLabel id="channel-end-time-label" shrink className={classes.itemHeader}>
                        <Typography variant="subtitle1" >강의 시간</Typography>
                    </InputLabel>
                    <Select
                        labelId="channel-end-time-label"
                        id="channel-end-time-select"
                        className={classes.itemBody2}
                        value={channel.liveTotalTime}
                        onChange={handleChangeChannelLiveTime}
                        native
                    >
                        <option value={'1800'}>30 분</option>
                        <option value={'3600'}>60 분</option>
                        <option value={'5400'}>90 분</option>
                        <option value={'7200'}>120 분</option>
                        <option value={'9000'}>150 분</option>
                        <option value={'10800'}>180 분</option>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item container spacing={1} xs={4}>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="channel-member-group-label" shrink className={classes.itemHeader}>
                        <Typography color={memberCount < 1 ? "error" : "initial"} variant="subtitle1" >{`참여 대상 (${memberCount} 명)`}</Typography>
                    </InputLabel>
                    <Select labelId="channel-member-group-label"
                            id="channel-member-group-select"
                            className={classes.itemBody2}
                            value={selectedGroupId}
                            onChange={handleChangeSeletecdGroup}
                            native
                    >
                        <option key={'group-item-none'} value={''}></option>
                        {groupList.map(group =>
                            <option key={`group-item-${group.groupId}`} value={group.groupId}>{`${group.groupName} (${group.groupUserCount} 명)`}</option>
                        )}
                    </Select>
                    <FormHelperText error={isOverMember}>{isOverMember ? '참여 대상 수가 너무 많습니다.' : ' '}</FormHelperText>
                </FormControl>
            </Grid>
            <Grid item xs={8}>
                <div style={{marginTop: 36, marginLeft: 6}}>
                    {channel.inviteGroupList.map((group, index) =>
                        <Chip key={`channel-invite-group-chip-${index}-${group.groupId}`} className={classes.chip} label={`${group.groupName}(${group.groupUserCount}명)`} onDelete={() => handleClickRemoveGroup(group.groupId)} />
                    )}
                    {channel.channelMemberList.map((member, index) =>
                        <Chip key={`channel-member-chip-${index}-${member.email}`} className={classes.chip} label={member.member ? member.userName : member.email} onDelete={() => handleClickRemoveMember(member.email)} />
                    )}
                </div>
            </Grid>

            <Dialog disableBackdropClick
                    disableEscapeKeyDown
                    maxWidth="xs"
                    open={alertDialogOpen}
            >
                <DialogTitle>알림</DialogTitle>
                <DialogContent>
                    <Typography>새로운 강의 UI는 앞으로의 개선 방향을 체험해 보는 기능으로 베타 운영 중입니다. 아직 지원하지 않는 기능이 있으며, 오류가 있을 수 있습니다.</Typography>
                    <Typography>강의을 하실 목적이면 기존 UI를 이용해 주세요.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleAlertDialogOk} color="primary">
                        새로운 UI 사용하기
                    </Button>
                    <Button onClick={handleAlertDialogClose} color="default">
                        기존 UI 사용하기
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};
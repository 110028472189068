import React from "react";
import {Box, withStyles} from "@material-ui/core";

const styles = theme => ({

})

const settings = {
    center: 2,
    bars: 3,
    spacing: 2,
    width: 6,
    height: 6
};

class SoundMeterVisualizer extends React.Component {
    constructor(props) {
        super(props);

        this.divRefs = [];
        for(let i = 0; i < settings.bars; i++) {
            this.divRefs.push(React.createRef());
        }

        this.volums = [];
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.volume !== this.props.volume) {
            this.volums.unshift(this.props.volume);
            if(this.volums.length > settings.bars) {
                this.volums.pop();
                for (let i = 0; i < settings.bars; i++) {
                    const vol = this.volums[i]/50;
                    let scale = 1;
                    if(String(i) === String(settings.center - 1)) scale = 1.5;
                    const scaleY = vol * scale + 1;
                    this.divRefs[i].current.style.transform = `scaleY(${scaleY})`;
                }
            }
        }
    }

    dot = (i) =>
        <div
            ref={this.divRefs[i]}
            key={`vu-${i}`}
            style={{
                background: this.props.theme.palette.primary.main,
                borderRadius: settings.width + "px",
                width: settings.width + "px",
                height: settings.height + "px",
                marginRight: settings.spacing + "px",
            }}
        />
    ;

    render() {
        let i = 0;
        return (
            <Box display="flex" flexDirection="row" justifyContent="space-around" alignItems="center">
                {this.dot(i++)}
                {this.dot(i++)}
                {this.dot(i++)}
            </Box>
        );
    }
}

export default (withStyles(styles, { withTheme: true }) (SoundMeterVisualizer));

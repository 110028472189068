import {action, computed, flow, observable, toJS} from "mobx";
import axios from "axios";
import _ from "lodash";

const DialogState = {
    Closed: 'Closed',
    Loading: 'Loading',
    Loaded: 'Loaded',
    LoadFailed: 'LoadFailed',
    Adding: 'Adding',
    Added: 'Added',
    AddFailed: 'AddFailed',
}

const compareUserNumber = (a, b) => {
    const aNum = a.userNumber.match(/\d+/);
    const bNum = b.userNumber.match(/\d+/);

    if (aNum && bNum) {
        return Number(aNum) - Number(bNum);
    } else {
        return a.userNumber.localeCompare(b.userNumber);
    }
}

export default class MemberAddStore {
    @observable groupId = '';
    @observable dialogState = DialogState.Closed;
    @observable groupMemberList = [];
    @observable checkedGroupMembers = [];
    @observable allGroupMemberChecked = false;
    @observable memberFilter = '';
    @observable memberList = [];
    @observable filterComment = '';
    @observable filterUserName = '';
    @observable checkedMembers = [];
    @observable allMemberChecked = false;

    @action clearDialogState = (open) => {
        if(open) {
            this.dialogState = DialogState.Loaded;
        } else {
            this.dialogState = DialogState.Closed;
        }
    }

    @action setFilterComment = (comment) => {
        this.filterComment = comment;
    }

    @action setFilterUserName = (userName) => {
        this.filterUserName = userName;
    }

    @action changeMemberFilter = (filter) => {
        this.memberFilter = filter;
    }

    @action addMemberToGroup = (email) => {
        const newMember = _.find(toJS(this.memberList), (member) => member.email === email);

        if(newMember) {
            this.groupMemberList.push(newMember);
            this.groupMemberList = this.groupMemberList.sort(compareUserNumber);
        }
    }

    @action toggleAddMember = (email) => {
        this.allMemberChecked = false;

        const currentIndex = this.checkedMembers.indexOf(email);

        if (currentIndex === -1) {
            this.checkedMembers.push(email);
        } else {
            this.checkedMembers.splice(currentIndex, 1);
        }
    }

    @action addCheckMemberToGroup = () => {
        for(let i=0; i<this.checkedMembers.length; i++) {
            const email = this.checkedMembers[i];

            this.addMemberToGroup(email);
        }

        this.allMemberChecked = false;
        this.checkedMembers = [];
    }

    @action toggleAllMemberChecked = (checked) => {
        this.allMemberChecked = checked;
        this.checkedMembers = [];

        if(checked) {
            const allMembers = this.filteredMemberList;
            for (let i in allMembers) {
                const m = allMembers[i];

                this.checkedMembers.push(m.email);
            }
        }
    }

    @action removeMemberFromGroup = (email) => {
        const groupMembers = toJS(this.groupMemberList);

        let removeIndex = -1;
        for(let i=0; i<groupMembers.length; i++) {
            const groupMember = groupMembers[i];

            if(groupMember.email === email) {
                removeIndex = i;
                break;
            }
        }

        if(removeIndex >= 0) {
            groupMembers.splice(removeIndex, 1);
            this.groupMemberList = groupMembers;
        }
    }

    @action toggleRemoveMember = (email) => {
        this.allGroupMemberChecked = false;

        const currentIndex = this.checkedGroupMembers.indexOf(email);

        if (currentIndex === -1) {
            this.checkedGroupMembers.push(email);
        } else {
            this.checkedGroupMembers.splice(currentIndex, 1);
        }

        console.log('checkedGroupMembers', toJS(this.checkedGroupMembers));
    }

    @action removeCheckMemberFromGroup = () => {
        for(let i=0; i<this.checkedGroupMembers.length; i++) {
            const email = this.checkedGroupMembers[i];

            this.removeMemberFromGroup(email);
        }

        this.allGroupMemberChecked = false;
        this.checkedGroupMembers = [];
    }

    @action toggleAllRemoveMemberCheck = (checked) => {
        this.allGroupMemberChecked = checked;
        this.checkedGroupMembers = [];

        if(checked) {
            const allGroupMembers = this.groupMemberList;
            for(let i in allGroupMembers) {
                const m = allGroupMembers[i];

                this.checkedGroupMembers.push(m.email);
            }
        }
    }

    @computed get isDialogOpen() {
        return this.dialogState !== DialogState.Closed;
    }

    @computed get isAdding() {
        return this.dialogState === DialogState.Adding;
    }

    @computed get isAdded() {
        return this.dialogState === DialogState.Added;
    }

    @computed get isAddFailed() {
        return this.dialogState === DialogState.AddFailed;
    }

    @computed get filteredMemberList() {
        const groupMemberList = toJS(this.groupMemberList);
        const filteredMemberList =  _.filter(toJS(this.memberList), (member) => {
            const memberInGroup = _.find(groupMemberList, (groupMember) => groupMember.email === member.email);
            if(memberInGroup) {
                return false;
            } else {
                if (this.memberFilter) {
                    return member.userName.startsWith(this.memberFilter);
                } else {
                    return true;
                }
            }
        });
        return this.filterComment && this.filterComment !== undefined ? filteredMemberList.filter((member) => member.comment === this.filterComment) : filteredMemberList;
    }

    openDialog = flow(function* openDialog(groupId, userId) {
        this.dialogState = DialogState.Loading;
        this.groupId = groupId;

        try {
            const groupResponse = yield axios.get(`/api/v1/groups/users?group-id=${groupId}`);
            const groupMembers = groupResponse.data;

            const memberResponse = yield axios.get(`/api/v1/users/members?user-id=${userId}&paging=no`);
            const members = memberResponse.data;

            this.memberList = members.sort(compareUserNumber);
            this.groupMemberList = groupMembers.sort(compareUserNumber);
            this.dialogState = DialogState.Loaded;
        } catch(error) {
            this.dialogState = DialogState.LoadFailed;
        }
    })

    update = flow(function* update() {
        this.dialogState = DialogState.Adding;

        try {
            const param = {
                groupId: this.groupId,
                emailList: this.groupMemberList.map((member) => member.email),
            };
            yield axios.post('/api/v1/groups/users', param);

            this.dialogState = DialogState.Added;
        } catch(error) {
            this.dialogState = DialogState.AddFailed;
        }
    })
}
import {action, computed, flow, observable} from "mobx";
import axios from "axios";

const State = {
    Ready: 'Ready',
    Requesting: 'Requesting',
    RequestSuccess: 'RequestSuccess',
    RequestFailed: 'RequestFailed',
    WrongCaptchaValue: 'WrongCaptchaValue',
}

export default class PasswordStore {
    @observable state = State.Ready;
    @observable email = '';
    @observable captcha = {};
    @observable captchaValue = '';

    @action initialize = () => {
        this.state = State.Ready;
        this.email = '';
        this.captcha = {};
        this.captchaValue = '';
    }

    @action changeEmail = email => this.email = email;
    @action changeCaptchaValue = captchaValue => this.captchaValue = captchaValue;

    @action clearState = () => {
        this.state = State.Ready;
    }

    @computed get isReady() {
        return this.state === State.Ready || this.state === State.Requesting;
    }

    @computed get isRequestSuccess() {
        return this.state === State.RequestSuccess;
    }

    @computed get isRequestFailed() {
        return this.state === State.RequestFailed;
    }

    @computed get isWrongCaptchaValue() {
        return this.state === State.WrongCaptchaValue;
    }

    findPassword = flow(function* findPassword() {
        this.state = State.Requesting;
        this.captcha.responseValue = this.captchaValue;
        try {
            const captchaResponse = yield axios.post('/api/v1/captcha', this.captcha);
            console.log(captchaResponse.data.result);
            if(captchaResponse.data.result) {
                yield axios.get(`/api/v1/users/password?email=${this.email}`);
                this.state = State.RequestSuccess;
            } else {
                this.state = State.WrongCaptchaValue;
            }
        } catch (error) {
            this.state = State.RequestFailed;
        }
    })

    getCaptchaImage = flow(function* getCaptchaImage() {
        try {
            this.captcha = {};
            this.captchaValue = '';
            const response = yield axios.get('/api/v1/captcha');
            if(response.status === 200) {
                this.captcha = response.data;
                this.captcha.responseValue = this.captchaValue;
            } else {
                console.log("Can't get captcha key", response);
            }
        } catch(error) {
            console.log("Can't get captcha", error);
        }
    })
}
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogContent, Typography} from "@material-ui/core";

export default function BroadcastChannelExitDialog(props) {
    const { open, handleClose, history, handleBroadcastBlock, channel, userId} = props;

    const handleExit = () => {
        if(channel && userId && channel.userId === userId) {
            handleBroadcastBlock();
        }

        history.push("/channel/list");
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'xs'} >
            <DialogContent>
                <Typography variant="subtitle1" component="h2">
                    {(channel && userId && channel.userId === userId) ? "방송을 종료하고 ": " "}강의 목록으로 돌아갑니다.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button style={{
                            color: 'rgba(0, 0, 0, 0.56)'
                        }}
                        onClick={() => {handleClose()}}
                >
                    취소
                </Button>
                <Button style={{
                            color: '#d64e4e'
                        }}
                        onClick={handleExit}
                >
                    나가기
                </Button>
            </DialogActions>
        </Dialog>
    );
}
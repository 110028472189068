import {default as AdminStore} from "./AdminStore";
import {default as AuthStore} from "./AuthStore";
import {default as SignUpStore} from "./SingUpStore";
import {default as MainMenuStore} from "./MainMenuStore";
import {default as ChannelFormStore} from "./ChannelFormStore";
import {default as ChannelCreateStore} from "./ChannelCreateStore";
import {default as ChannelStore} from "./ChannelStore";
import {default as CalendarChannelStore} from "./CalendarChannelStore";
import {default as ChannelDetailStore} from "./ChannelDetailStore";
import {default as ChannelUpdateStore} from "./ChannelUpdateStore";
import {default as ChannelAttendanceStore} from "./ChannelAttendanceStore";
import {default as ChannelFileStore} from "./ChannelFileStore";
import {default as PasswordStore} from "./PasswordStore";
import {default as MailCertificationStore} from "./MailCertificationStore";
import {default as PassCertificationStore} from "./PassCertificationStore";
import {default as BroadcastStore} from "./BroadcastStore";
import {default as GroupStore} from "./GroupStore";
import {default as QuizStore} from "./QuizStore";
import {default as MemberAddStore} from "./MemberAddStore";
import {default as MemberInfoStore} from "./MemberInfoStore";
import {default as ShareAddStore} from "./ShareAddStore";
import {default as TicketStore} from "./TicketStore";
import {default as PaymentStore} from "./PaymentStore";
import {default as UserStore} from "./UserStore";
import {default as BoardStore} from "./BoardStore";
import {default as HelpStore} from "./HelpStore";
import {default as BroadcastQuizStore} from "./BroadcastQuizStore";
import {default as LookAroundStore} from "./LookAroundStore";
import RoomStore from "./RoomStore";
import PollStore from "./PollStore";

import DashBoardStore from "./DashBoardStore";
import MemberAttendanceStateStore from "./MemberAttendanceStateStore";
import RecordStore from "./RecordStore";
// import MonitoringPublishStore from "./MonitoringPublishStore";
import MonitoringStore from "./MonitoringStore";
import WhiteBoardStore from "./WhiteBoardStore";
import TinyGroupStore from "./TinyGroupStore";
import PublishStore from "./PublishStore";

export const stores = {
    adminStore: new AdminStore(),
    authStore:  new AuthStore(),
    signUpStore: new SignUpStore(),
    mainMenuStore: new MainMenuStore(),
    channelFormStore: new ChannelFormStore(),
    channelCreateStore: new ChannelCreateStore(),
    channelStore: new ChannelStore(),
    calendarChannelStore: new CalendarChannelStore(),
    channelDetailStore: new ChannelDetailStore(),
    channelUpdateStore: new ChannelUpdateStore(),
    channelAttendanceStore: new ChannelAttendanceStore(),
    channelFileStore: new ChannelFileStore(),
    passwordStore: new PasswordStore(),
    mailCertificationStore: new MailCertificationStore(),
    passCertificationStore: new PassCertificationStore(),
    broadcastStore: new BroadcastStore(),
    groupStore: new GroupStore(),
    quizStore: new QuizStore(),
    memberAddStore: new MemberAddStore(),
    memberInfoStore: new MemberInfoStore(),
    shareAddStore: new ShareAddStore(),
    ticketStore: new TicketStore(),
    paymentStore: new PaymentStore(),
    pollStore: new PollStore(),
    userStore: new UserStore(),
    boardStore: new BoardStore(),
    helpStore: new HelpStore(),
    broadcastQuizStore: new BroadcastQuizStore(),
    lookAroundStore: new LookAroundStore(),
    recordStore: new RecordStore(),
    roomStore: new RoomStore(),
    dashboardStore: new DashBoardStore(),
    memberAttendanceStateStore: new MemberAttendanceStateStore(),
    // monitoringPublishStore: new MonitoringPublishStore(),
    monitoringStore: new MonitoringStore(),
    whiteBoardStore: new WhiteBoardStore(),
    tinyGroupStore : new TinyGroupStore(),
    publishStore : new PublishStore(),
};
import {action, computed, flow, observable, toJS} from "mobx";
import _ from "lodash";
import axios from "axios";
import {RoomVideo} from "../views/room/api/RoomVideo";
import {MemberPublishState} from "../views/room/api/RoomPresenter";
import {PresentationType} from "./RoomStore";
import SoundMeter from "../common/SoundMeter";
// import SoundMeter2 from "../common/SoundMeter2";

export const TINY_GROUP_VIDEO_BOX_PADDING_X = 0;
export const TINY_GROUP_VIDEO_BOX_PADDING_Y = 20;

export const TINY_GROUP_VIDEO_RESOLUTION_WIDTH = 192;
export const TINY_GROUP_VIDEO_RESOLUTION_HEIGHT = 108;

export const TINY_GROUP_OWNER_VIDEO_WIDTH = 320;
export const TINY_GROUP_OWNER_VIDEO_HEIGHT = 180;
export const TINY_GROUP_MEMBER_VIDEO_WIDTH = 226;
export const TINY_GROUP_MEMBER_VIDEO_HEIGHT = 126;

export const TINY_GROUP_MAX_USER_COUNT = 6;
export const TINY_GROUP_MAX_TITLE_LENGTH = 20;

export const TINY_GROUP_CHAT_DROP_ZONE_ID = 'dropMembers';

const emptyTinyGroup = {
    id: 0,
    name: '모둠',
    expanded: true,
    isEdit: false,
    isOwnerExist: false,
    isDragOver: false,
    isCalled: false,
    members: []
}

const emptySelectedMembersInfo = {
    droppableId: '',
    membersIndex: []
}

export const getVideoMapKey = index => `tiny-group-video-map-key-${index}`;

const LogPrefix = '[TinyGroupStore] ';

export default class TinyGroupStore {

    @observable tinyGroupMaxUserCount = TINY_GROUP_MAX_USER_COUNT;

    @observable notifyMessageTimoutId = undefined;
    @observable notifyMessage = '';
    @observable isNotifyDialogOpen = false;

    @observable janus = undefined;

    @observable devices = [];

    @observable isVideoOff = false;
    @observable isAudioOff = false;
    @observable selectedVideoDeviceId = '';
    @observable selectedAudioDeviceId = '';

    @observable userId = '';
    @observable videoRefs = [];
    @observable tinyGroups = [];

    @observable owner = {};
    @observable members = [];

    @observable tinyGroup = _.cloneDeep(emptyTinyGroup);
    @observable tinyGroupVideoMap = {};

    @observable selectedMembersInfo = _.cloneDeep(emptySelectedMembersInfo);

    @observable isMembersDragOver = false;
    @observable destinationIndex = 0;

    @observable isTinyGroupDialogOpen = false;
    @observable isTinyGroupClosedButtonDisabled = true;

    @action initTinyGroupMaxUserCount = () => this.tinyGroupMaxUserCount = TINY_GROUP_MAX_USER_COUNT;
    @action subTinyGroupMaxUserCount = () => this.tinyGroupMaxUserCount = this.tinyGroupMaxUserCount > 1 ? this.tinyGroupMaxUserCount - 1 : 1;
    @action addTinyGroupMaxUserCount = () => this.tinyGroupMaxUserCount = this.tinyGroupMaxUserCount < TINY_GROUP_MAX_USER_COUNT ? this.tinyGroupMaxUserCount + 1 : TINY_GROUP_MAX_USER_COUNT;

    @action setNotifyMessageTimoutId = notifyMessageTimoutId => this.notifyMessageTimoutId = notifyMessageTimoutId;
    @action setNotifyMessage = notifyMessage => this.notifyMessage = notifyMessage;
    @action setNotifyDialogOpen = isNotifyDialogOpen => this.isNotifyDialogOpen = isNotifyDialogOpen;

    @action setDevices = devices => {
        this.devices = devices;

        this.selectedVideoDeviceId = this.videoDevices[0].deviceId;
        this.selectedAudioDeviceId = this.audioDevices[0].deviceId;
    }

    @action changeVideoOff = () => {
        this.isVideoOff = !this.isVideoOff;

        this.publish(this.userId);
    }
    @action changeAudioOff = () => {
        this.isAudioOff = !this.isAudioOff;

        this.publish(this.userId);
    }

    @action selectVideoDeviceId = deviceId => {
        if(deviceId === "videoOff") {
            this.isVideoOff = true;
        } else {
            this.selectedVideoDeviceId = deviceId;
            this.isVideoOff = false;
        }

        this.publish(this.userId);
    }

    @action selectAudioDeviceId = deviceId => {
        if(deviceId === "audioOff") {
            this.isAudioOff = true;
        } else {
            this.selectedAudioDeviceId = deviceId;
            this.isAudioOff = false;
        }

        this.publish(this.userId);
    }

    @action setUserId = userId => this.userId = userId;
    @action setMemberVideoStream = (userId, stream) => {
        const memberInfo = this.getRoomVideo(userId);
        if(memberInfo) {
            memberInfo.stream = stream;
            memberInfo.attached = true;
        }
    }

    @action setMemberVideoAttached = (userId, attached) => {
        const memberInfo = this.getRoomVideo(userId);
        if(memberInfo) {
            memberInfo.attached = attached;
        }
    }

    @action setMemberVideoPlayed = (userId, played) => {
        const memberInfo = this.getRoomVideo(userId);
        if(memberInfo) {
            memberInfo.played = played;
        }
    }

    @action setMemberGroupPlayClicked = (userId, groupPlayClicked) => {
        const memberInfo = this.getRoomVideo(userId);
        if(memberInfo) {
            memberInfo.groupPlayClicked = groupPlayClicked;
        }
    }

    @action init = () => {
        this.janus = undefined;
        this.members = [];
        this.tinyGroups = [];
        this.tinyGroup = _.cloneDeep(emptyTinyGroup);
        this.tinyGroupVideoMap = {};
        this.tinyGroupVideos = [];
    }

    @action setOwner = owner => this.owner = owner;
    @action setMembers = members => {
        if(members) {
            members.forEach(m => {
                m.isDragging = false;
                m.isSelected = false;
                m.isGhosting = false;
                m.destinationGroupId = 'none';
            });
        }
        this.members = members ? members : [];
    }

    @action chatMemberJoined = (userId) => {
        const member = this.members.find(m => String(m.id) === userId);
        if(member) {
            member.joined = true;
            // this.setChatMemberAvatar(member);
        } else {
            this.tinyGroups.forEach(tg => {

                const member = tg.members.find(m => String(m.id) === userId);
                if(member) {
                    member.joined = true;
                    // this.setChatMemberAvatar(member);
                }
            });

            const member = this.tinyGroup.members.find(m => String(m.id) === userId);
            if(member) {
                member.joined = true;
                // this.setChatMemberAvatar(member);
            }
        }
    }

    @action chatMemberLeaved = (userId) => {
        const member = this.members.find(m => String(m.id) === userId);
        if(member) {
            member.joined = false;
            member.mediaOn = false;
            member.audioSetup = false;
            member.audioOn = false;
            member.soundOn = false;
            member.publishType = PresentationType.NONE;
            member.publishState = MemberPublishState.None;
            member.deviceType = '';
            member.browserType = '';

            member.isDragging = false;
            member.isSelected = false;
            member.isGhosting = false;
        } else {
            this.tinyGroups.forEach(tg => {
                const member = tg.members.find(m => String(m.id) === userId);
                if(member) {
                    member.joined = false;
                    member.mediaOn = false;
                    member.audioSetup = false;
                    member.audioOn = false;
                    member.soundOn = false;
                    member.publishType = PresentationType.NONE;
                    member.publishState = MemberPublishState.None;
                    member.deviceType = '';
                    member.browserType = '';

                    member.isDragging = false;
                    member.isSelected = false;
                    member.isGhosting = false;
                }
            });

            const member = this.tinyGroup.members.find(m => String(m.id) === userId);
            if(member) {
                member.joined = false;
                member.mediaOn = false;
                member.audioSetup = false;
                member.audioOn = false;
                member.soundOn = false;
                member.publishType = PresentationType.NONE;
                member.publishState = MemberPublishState.None;
                member.deviceType = '';
                member.browserType = '';

                member.isDragging = false;
                member.isSelected = false;
                member.isGhosting = false;
            }
        }
    }

    @action setChatMemberSoundOn = (userId, soundOn) => {
        const member = _.find(this.members, (m) => String(m.id) === userId);
        if(member) {
            member.soundOn = soundOn;
        } else {
            this.tinyGroups.forEach(tg => {
                const member = tg.members.find(m => String(m.id) === userId);
                if(member) {
                    member.soundOn = soundOn;
                }
            });

            const member = this.tinyGroup.members.find(m => String(m.id) === userId);
            if(member) {
                member.soundOn = soundOn;
            }
        }
    }

    @action setChatMemberAudioOn = (userId, setup, muted) => {
        const member = _.find(this.members, (m) => String(m.id) === userId);
        if(member) {
            member.audioSetup = setup;
            member.audioOn = !muted;
        } else {
            this.tinyGroups.forEach(tg => {
                const member = tg.members.find(m => String(m.id) === userId);
                if(member) {
                    member.audioSetup = setup;
                    member.audioOn = !muted;
                }
            });

            const member = this.tinyGroup.members.find(m => String(m.id) === userId);
            if(member) {
                member.audioSetup = setup;
                member.audioOn = !muted;
            }
        }
    }

    @action setChatMemberMediaOn = (userId, mediaOn, deviceType, browserType) => {
        const member = _.find(this.members, (m) => m.id === userId);
        if(member) {
            member.mediaOn = mediaOn;
            member.deviceType = deviceType;
            member.browserType = browserType
            if(mediaOn) {
                member.publishType = PresentationType.PRESENTATION;
            }
        } else {
            this.tinyGroups.forEach(tg => {
                const member = tg.members.find(m => String(m.id) === userId);
                if(member) {
                    member.mediaOn = mediaOn;
                    member.deviceType = deviceType;
                    member.browserType = browserType
                    if(mediaOn) {
                        member.publishType = PresentationType.PRESENTATION;
                    }
                }
            });

            const member = this.tinyGroup.members.find(m => String(m.id) === userId);
            if(member) {
                member.mediaOn = mediaOn;
                member.deviceType = deviceType;
                member.browserType = browserType
                if(mediaOn) {
                    member.publishType = PresentationType.PRESENTATION;
                }
            }
        }
    }

    @action setTinyGroup = tinyGroup => this.tinyGroup = tinyGroup;
    @action setTinyGroupDialogOpen = tinyGroupDialogOpen => this.isTinyGroupDialogOpen = tinyGroupDialogOpen;
    @action setTinyGroupClosedButtonDisabled = tinyGroupClosedButtonDisabled => this.isTinyGroupClosedButtonDisabled = tinyGroupClosedButtonDisabled;

    @action autoAssignment = () => {
        this.tinyGroups = Array(this.tinyGroupCount).fill(emptyTinyGroup).map((tg, i) => ({
                id: (i + 1),
                name: tg.name + " " + (i + 1),
                editName: tg.name + " " + (i + 1),
                expanded: tg.expanded,
                isEdit: tg.isEdit,
                isDragOver: tg.isDragOver,
                isCalled: tg.isCalled,
                members: [],
        }));

        // let tinyGroupIndex = 0;
        // while(this.members.length !== 0) {
        //     const randomNum = Math.floor(Math.random() * this.members.length);
        //     this.tinyGroups[tinyGroupIndex++].members.push(this.members.splice(randomNum, 1)[0]);
        //     if(tinyGroupIndex >= this.tinyGroups.length) tinyGroupIndex = 0;
        // }

        for(let i = 0; i < this.tinyGroups.length; i++) {
            for(let j = 0; j < this.tinyGroupMaxUserCount; j++) {
                const randomNum = Math.floor(Math.random() * this.members.length);
                this.tinyGroups[i].members.push(this.members.splice(randomNum, 1)[0]);
                if(this.members.length <= 0) break;
            }
        }

        this.clearSelected();
        this.clearDragging();
        this.clearGhosting();
        this.selectedMembersInfo = _.cloneDeep(emptySelectedMembersInfo);
    }

    @action addTinyGroup = () => {
        const tinyGroup = _.cloneDeep(emptyTinyGroup);
        tinyGroup.name = tinyGroup.name + " " + (this.tinyGroups.length + 1);
        tinyGroup.editName = tinyGroup.name + " " + (this.tinyGroups.length + 1);
        this.tinyGroups.push(tinyGroup);
        this.tinyGroups.forEach((tg, i) => tg.id = (i + 1));
    }

    @action changeExpanded = index => this.tinyGroups[index].expanded = !this.tinyGroups[index].expanded;
    @action changeTinyGroupEditName = (index, editName) => this.tinyGroups[index].editName = editName.length > TINY_GROUP_MAX_TITLE_LENGTH ? editName.substr(0, TINY_GROUP_MAX_TITLE_LENGTH) : editName;

    @action editTinyGroup = index => {
        this.tinyGroups[index].editName = this.tinyGroups[index].name;
        this.tinyGroups[index].isEdit = true;
    }

    @action saveTinyGroup = index => {
        this.tinyGroups[index].name = this.tinyGroups[index].editName;
        this.tinyGroups[index].isEdit = false;
    }

    @action saveCancelTinyGroup = index => {
        this.tinyGroups[index].isEdit = false;
    }

    @action deleteTinyGroups = (initMember) => {
        // this.tinyGroups.forEach((tinyGroup, i) => {
        //     if(tinyGroup.members) {
        //         this.members = this.members.concat(_.cloneDeep(tinyGroup.members));
        //     }
        // });
        if(initMember) {
            this.init();
            initMember();
        }
    }

    @action deleteTinyGroup = index => {
        if(this.tinyGroups[index]) {
            if (this.tinyGroups[index].members) {
                this.members = this.members.concat(_.cloneDeep(this.tinyGroups[index].members));
            }
            this.tinyGroups.splice(index, 1);
        }
    }

    @action getSyncMembers = sourceDroppableId =>  {
        if(this.isDropMembers(sourceDroppableId)) {
            return this.members;
        } else {
            const tinyGroupIndex = this.getTinyGroupIndex(sourceDroppableId);
            return this.tinyGroups[tinyGroupIndex].members;
        }
    }

    @action assignment = (sourceDroppableId, sourceMemberId, destinationDroppableId, destinationIndex) => {
        const sourceMembers = this.getSyncMembers(sourceDroppableId);
        const destinationMembers = this.getSyncMembers(destinationDroppableId);
        const dragResult = {
            source: {
                droppableId : sourceDroppableId,
                index : sourceMembers.findIndex(m => String(m.id) === String(sourceMemberId))
            },
            destination: {
                droppableId : destinationDroppableId,
                index : (destinationIndex >= destinationMembers.length || destinationIndex < 0) ? destinationMembers.length : destinationIndex
            }
        }

        this.dragEnd(dragResult);
    }

    @action isDropMembers = droppableId => droppableId === "dropMembers";
    @action getTinyGroupIndex = droppableId => droppableId.split("-")[1];
    @action replaceTinyGroup = (tinyGroupIndex, tinyGroup) => this.tinyGroups.splice(tinyGroupIndex, 1, tinyGroup);
    @action getMember = (droppableId, memberIndex) => {
        if (this.isDropMembers(droppableId)) {
            return _.find(this.members,(m, i) => i === memberIndex);
        } else {
            const tinyGroupIndex = this.getTinyGroupIndex(droppableId);
            return _.find(this.tinyGroups[tinyGroupIndex].members, (m, i) => i === memberIndex);
        }
    }

    @action clearSelected = () => {
        if(this.members.length > 0) this.members.forEach(m => m.isSelected = false);
        this.tinyGroups.forEach(tg => {
            if(tg.members.length > 0) tg.members.forEach(m => m.isSelected = false);
        });
    }

    @action clearDragging = () => {
        if(this.members.length > 0) this.members.forEach(m => m.isDragging = false);
        this.tinyGroups.forEach(tg => {
            if(tg.members.length > 0) tg.members.forEach(m => m.isDragging = false);
        });
    }

    @action clearGhosting = () => {
        if(this.members.length > 0) this.members.forEach(m => m.isGhosting = false);
        this.tinyGroups.forEach(tg => {
            if (tg.members.length > 0) tg.members.forEach(m => m.isGhosting = false);
        });
    }

    @action toggleSelection = (memberIndex, droppableId) => {
        const selectedMembersInfo = _.cloneDeep(emptySelectedMembersInfo);
        selectedMembersInfo.droppableId = droppableId;
        selectedMembersInfo.membersIndex.push(memberIndex);

        const member = this.getMember(droppableId, memberIndex);
        if(member) {
            this.clearSelected();
            this.clearDragging();
            this.clearGhosting();

            member.isSelected = true;
            this.selectedMembersInfo = selectedMembersInfo;
        }
    };

    @action toggleSelectionInGroup = (memberIndex, droppableId) => {
        const member = this.getMember(droppableId, memberIndex);
        if(member) {
            if (this.selectedMembersInfo.droppableId !== droppableId) {
                this.clearSelected();
                this.clearDragging();
                this.clearGhosting();
                this.selectedMembersInfo = _.cloneDeep(emptySelectedMembersInfo);
            }

            member.isSelected = true;
            this.selectedMembersInfo.droppableId = droppableId;
            this.selectedMembersInfo.membersIndex.push(memberIndex);
        }
    };

    @action multiSelectTo = (memberIndex, droppableId) => {
        if(droppableId === this.selectedMembersInfo.droppableId && this.selectedMembersInfo.membersIndex.length > 0) {
            const setSelected = members => {
                const lastIndex = this.selectedMembersInfo.membersIndex[this.selectedMembersInfo.membersIndex.length - 1];
                for(let i = 0; i < members.length; i++) {
                    const member = members[i];
                    if(!member.isSelected) {
                        if (lastIndex > memberIndex) {
                            if (lastIndex >= i && i >= memberIndex) {
                                this.toggleSelectionInGroup(i, droppableId);
                            }
                        } else if (lastIndex < memberIndex) {
                            if (lastIndex <= i && i <= memberIndex) {
                                this.toggleSelectionInGroup(i, droppableId);
                            }
                        }
                    }
                }
            }

            if(this.isDropMembers(droppableId)) {
                setSelected(this.members);
            } else {
                const tinyGroupIndex = this.getTinyGroupIndex(this.selectedMembersInfo.droppableId);
                const tinyGroup = _.cloneDeep(this.tinyGroups[tinyGroupIndex]);
                setSelected(tinyGroup.members);
            }
        }
    }

    @action setTinyGroupMoveId = (member, sourceDroppableId, sourceMemberName, destinationGroupId) => {
        member.destinationGroupId = destinationGroupId;
        if(destinationGroupId !== 'none') {
            const destinationGroupIndex = this.tinyGroups.findIndex(tg => String(tg.id) === String(destinationGroupId));
            const destinationDroppableId = destinationGroupId === TINY_GROUP_CHAT_DROP_ZONE_ID ? TINY_GROUP_CHAT_DROP_ZONE_ID : `dropTinyGroupMembers-${destinationGroupIndex}`;
            const destinationMembers = this.getSyncMembers(destinationDroppableId);

            this.assignment(sourceDroppableId, sourceMemberName, destinationDroppableId, destinationMembers.length);
        }
    }
    @action setMembersDragOver = isMembersDragOver => this.isMembersDragOver = isMembersDragOver;
    @action setTinyGroupDragOver = (index, isDragOver) => this.tinyGroups[index].isDragOver = isDragOver;
    @action setDestinationIndex = destinationIndex => this.destinationIndex = destinationIndex;

    @action dropMember = (members, data) => members.splice(data.index, 0, data.member);

    @action dragStart = (dragStart) => {
        const { source } = dragStart;
        console.log(LogPrefix, "drag start : ", toJS(source));

        const setDragging = (srcIndex, members) => members[srcIndex].isDragging = true;
        const setGhosting = (srcIndex, members) => this.selectedMembersInfo.membersIndex.filter(index => index !== srcIndex).forEach(index => members[index].isGhosting = true);

        const isSourceMulti = source.droppableId === this.selectedMembersInfo.droppableId && this.selectedMembersInfo.membersIndex.length > 1;
        if(isSourceMulti) {
            if (this.isDropMembers(this.selectedMembersInfo.droppableId)) {
                const members = _.cloneDeep(this.members);
                setDragging(source.index, members);
                setGhosting(source.index, members);
                this.members = members;
            } else {
                const tinyGroupIndex = this.getTinyGroupIndex(this.selectedMembersInfo.droppableId);
                const tinyGroup = _.cloneDeep(this.tinyGroups[tinyGroupIndex]);
                setDragging(source.index, tinyGroup.members);
                setGhosting(source.index, tinyGroup.members);
                this.replaceTinyGroup(tinyGroupIndex, tinyGroup);
            }
        } else {
            this.clearSelected();
            this.clearDragging();
            this.clearGhosting();
            this.selectedMembersInfo = _.cloneDeep(emptySelectedMembersInfo);
        }
    }

    @action dragEnd = (dragResult) => {
        const { source, destination } = dragResult;
        console.log(LogPrefix, "drag end :", toJS(source), toJS(destination));

        if (!destination || source.reason === "CANCEL") {return;}

        const isSourceMulti = source.droppableId === this.selectedMembersInfo.droppableId;
        if(!this.isDropMembers(destination.droppableId) && source.droppableId !== destination.droppableId) {
            const getSourceLength = isSourceMulti ? this.selectedMembersInfo.membersIndex.length : 1;
            if(this.isDropMembers(destination.droppableId)) {
                if (this.members.length + getSourceLength > this.tinyGroupMaxUserCount) { return; }
            } else {
                const tinyGroupIndex = this.getTinyGroupIndex(destination.droppableId);
                const tinyGroup = _.cloneDeep(this.tinyGroups[tinyGroupIndex]);
                if (tinyGroup.members.length + getSourceLength > this.tinyGroupMaxUserCount) { return; }
            }
        }

        if(isSourceMulti) {
            this.selectedMembersInfo.membersIndex = this.selectedMembersInfo.membersIndex.slice().sort();
            for(let i = 0; i < this.selectedMembersInfo.membersIndex.length; i++) {
                const srcIndex = this.selectedMembersInfo.membersIndex[i] - i;
                const destIndex = destination.index + i;
                if(srcIndex < destIndex) {
                  this.drop(srcIndex, destIndex, this.selectedMembersInfo.droppableId, destination.droppableId);
                } else  if(srcIndex > destIndex) {
                  this.drop(srcIndex, destIndex, this.selectedMembersInfo.droppableId, destination.droppableId);
                } else {
                  this.drop(srcIndex, destIndex, this.selectedMembersInfo.droppableId, destination.droppableId);
                }
            }
        } else {
            this.drop(source.index, destination.index, source.droppableId, destination.droppableId);
        }

        this.clearSelected();
        this.clearDragging();
        this.clearGhosting();
        this.selectedMembersInfo = _.cloneDeep(emptySelectedMembersInfo);
    };

    @action drop = (srcIndex, destIndex, sourceDroppableId, destinationDroppableId) => {
        const addArray = (array, sourceIndex, destIndex) => {
            const srcValue = _.cloneDeep(array[sourceIndex]);
            array.splice(sourceIndex, 1);
            array.splice(destIndex, 0, srcValue);
        };

        const addTinyGroupMember = (tinyGroup, tinyGroupIndex, destinationIndex, tinyGroupMember) => {
            tinyGroup.members.splice(destinationIndex, 0, tinyGroupMember);
            this.replaceTinyGroup(tinyGroupIndex, tinyGroup);
        };

        if(sourceDroppableId === destinationDroppableId) {
            if (this.isDropMembers(sourceDroppableId)) {
                addArray(this.members, srcIndex, destIndex);
            } else {
                const tinyGroupIndex = this.getTinyGroupIndex(sourceDroppableId);
                const tinyGroup = _.cloneDeep(this.tinyGroups[tinyGroupIndex]);
                addArray(tinyGroup.members, srcIndex, destIndex);
                this.replaceTinyGroup(tinyGroupIndex, tinyGroup);
            }
        } else {
            if (this.isDropMembers(sourceDroppableId)) {
                const tinyGroupIndex = this.getTinyGroupIndex(destinationDroppableId);
                const tinyGroup = _.cloneDeep(this.tinyGroups[tinyGroupIndex]);
                const member = _.cloneDeep(this.members[srcIndex]);
                addTinyGroupMember(tinyGroup, tinyGroupIndex, destIndex, member);
                this.members.splice(srcIndex, 1);
            } else {
                const tinyGroupIndex = this.getTinyGroupIndex(sourceDroppableId);
                const tinyGroup = _.cloneDeep(this.tinyGroups[tinyGroupIndex]);
                const member = _.cloneDeep(tinyGroup.members[srcIndex]);
                if (this.isDropMembers(destinationDroppableId)) {
                    this.members.splice(destIndex, 0, member);
                    tinyGroup.members.splice(srcIndex, 1);
                    this.replaceTinyGroup(tinyGroupIndex, tinyGroup);
                } else {
                    const targetGroupIndex = this.getTinyGroupIndex(destinationDroppableId);
                    const targetGroup = _.cloneDeep(this.tinyGroups[targetGroupIndex]);
                    addTinyGroupMember(targetGroup, targetGroupIndex, destIndex, member);
                    tinyGroup.members.splice(srcIndex, 1);
                    this.replaceTinyGroup(tinyGroupIndex, tinyGroup);
                }
            }
        }
    }

    @action getRoomVideo = feed =>  Object.values(this.tinyGroupVideoMap).find(memberInfo => String(memberInfo.userId) === String(feed));

    @action soundMeterInit = memberInfo => {
        if(memberInfo.soundMeter) memberInfo.soundMeter.stop();
        if(memberInfo.soundMeterInterval) clearInterval(memberInfo.soundMeterInterval);
        memberInfo.audioContext = undefined;
        memberInfo.isSoundMetering = false;
        memberInfo.soundMeter = undefined;
        memberInfo.soundMeterValue = 0;
        memberInfo.soundMeterInterval = undefined;
    }

    @action publish = (stream) => {
        this.unPublish();

        const memberInfo = this.getRoomVideo(this.userId);
        if(memberInfo && memberInfo.video) {
            const bitrate = 128 * 1000;
            const videoPublishInfo = this.isVideoOff ? false : this.videoPublishInfo;
            const audioPublishInfo = this.isAudioOff ? false : this.audioPublishInfo;
            console.log(LogPrefix, this.userId, '-', bitrate, 'bps, video publish info', videoPublishInfo);
            console.log(LogPrefix, this.userId, '-', bitrate, 'bps, audio publish info', audioPublishInfo);

            setTimeout(() => memberInfo.video.publish(this.userId, bitrate, false, audioPublishInfo, videoPublishInfo, stream ? stream.stream : stream), 1000);
        }
    }

   @action unPublish = () => {
       const memberInfo = this.getRoomVideo(this.userId);
       if(memberInfo) {
           if(memberInfo.video) memberInfo.video.unpublish();
           memberInfo.attached = false;
           memberInfo.played = false;
           memberInfo.stream = undefined;

           this.soundMeterInit(memberInfo);
       }

       console.log(LogPrefix, this.userId, 'unPublish', toJS(memberInfo));
   }

    @action leaveAndSubscribe = (memberInfo, feed) => {
        if(memberInfo.video.pluginHandle) {
            this.leave(memberInfo, {
                onSuccess: () => setTimeout(() => this.subscribe(memberInfo, feed), 1000)
            });
        } else {
            this.subscribe(memberInfo, feed);
        }
    }

    @action subscribe = (memberInfo, feed) => memberInfo.video.subscribe(feed);

    @action leave = (memberInfo, callbacks) => {
        if(memberInfo.video) memberInfo.video.leave(callbacks);
        memberInfo.attached = false;
        memberInfo.played = false;
        memberInfo.stream = undefined;

        this.soundMeterInit(memberInfo);
    }

    @action onClose = () => {
       this.unPublish();
        Object.values(this.tinyGroupVideoMap).filter(memberInfo => memberInfo.attached).filter(memberInfo => memberInfo.userId !== this.userId).forEach(memberInfo => this.leave(memberInfo));

        this.tinyGroup = _.cloneDeep(emptyTinyGroup);
        this.tinyGroupVideoMap = {};
        this.tinyGroupVideos = [];

        this.setTinyGroupDialogOpen(false);
    }

    @action _videoHandler = (videoMode) => {
        console.log(LogPrefix, 'VideoHandler : ' + videoMode);

        const that = this;
        return ({
            onPublishing: () => {
                console.log(LogPrefix, 'onPublishing', videoMode);
            },
            onPublished: (feed) => {
                console.log(LogPrefix, 'onPublished', videoMode, feed);
            },
            onLocalStreamAttached: (stream) => {
                console.log(LogPrefix, 'onLocalStreamAttached', videoMode, stream);

                that.setMemberVideoStream(that.userId, stream);
            },
            onPublishError: (error) => {
                console.log(LogPrefix, 'onPublishError', videoMode, error);
            },
            onUnpublishing: () => {
                console.log(LogPrefix, 'onUnpublishing', videoMode);
            },
            onUnpublished: () => {
                console.log(LogPrefix, 'onUnpublished', videoMode);
            },
            onReceivedUnpublished: (targetFeed) => {
                console.log(LogPrefix, 'onReceivedUnpublished', targetFeed);
                const memberInfo = that.getRoomVideo(targetFeed);
                if(memberInfo) this.leave(memberInfo);
            },
            onSubscribe: (feed) => {
                console.log(LogPrefix, 'onSubscribe', videoMode, feed);

                const memberInfo = that.getRoomVideo(feed);
                if(memberInfo && !memberInfo.attached) {
                    if(that.tinyGroup.isOwnerExist) {
                        that.leaveAndSubscribe(memberInfo, feed);
                    } else {
                        if(!memberInfo.isOwner) {
                            that.leaveAndSubscribe(memberInfo, feed);
                        }
                    }
                }
            },
            onSubscribing: (feed) => {
                console.log(LogPrefix, 'onSubscribing', videoMode, feed);
            },
            onRemoteStreamAttached: (feed, stream) => {
                console.log(LogPrefix, 'onRemoteStreamAttached', videoMode, feed, stream);

                that.setMemberVideoStream(feed, stream);
            },
            onRemoteStreamEmpty: (feed) => {
                console.warn(LogPrefix, 'onRemoteStreamEmpty', videoMode, feed);

                const memberInfo = that.getRoomVideo(feed);
                if(memberInfo) {
                    if(that.tinyGroup.isOwnerExist) {
                        that.leaveAndSubscribe(memberInfo, feed);
                    } else {
                        if(!memberInfo.isOwner) that.leaveAndSubscribe(memberInfo, feed);
                    }
                }
            },
            onStarted: () => {
                console.log(LogPrefix, 'onStarted', videoMode);
            },
            onSubscribed: (feed, stream) => {
                console.log(LogPrefix, 'onSubscribed', videoMode, feed, stream);
            },
            onSubscribeError: (error) => {
                console.log(LogPrefix, 'onSubscribeError', videoMode, error);
            },
            onUnsubscribing: () => {
                console.log(LogPrefix, 'onUnsubscribing', videoMode);
            },
            onUnsubscribed: () => {
                console.log(LogPrefix, 'onUnsubscribed', videoMode);
            },
            onSubstreamChanged: (substream) => {
                console.log(LogPrefix, 'onSubstreamChanged', videoMode, substream);
            }
        });
    }

    @action addOwner = tinyGroup => {
        console.log(LogPrefix, 'AddOwner', toJS(tinyGroup), toJS(this.owner));

        const ownerInGroupMembers = tinyGroup.members.find(m => m.id === this.owner.id);
        if(ownerInGroupMembers) {
            console.log(LogPrefix, `AddOwner ignored : Already exists owner ${ownerInGroupMembers.id}`);
        } else {
            tinyGroup.members.splice(0, 0, _.cloneDeep(this.owner));
            console.log(LogPrefix, `AddOwner : Added owner(${this.owner.id}) to group`, toJS(tinyGroup));
        }
    }

    @action getTinyGroupVideo = (roomUserId, janus, roomId, userId, displayName, index) => {
        return ({
            attached: false,
            played: false,
            stream: undefined,
            groupPlayClicked: false,
            roomUserId: roomUserId,
            userId: userId,
            isOwner: roomUserId === userId,
            audioContext: undefined,
            audioStream: undefined,
            isSoundMetering: false,
            soundMeter: undefined,
            soundMeterValue: 0,
            video: new RoomVideo(userId, janus, roomId, undefined, userId, displayName, undefined, this._videoHandler(userId)),
            refIndex: index,
        });
    }

    @action setSoundMeterValue = (memberInfo, soundVal) => memberInfo.soundMeterValue = soundVal;
    @action startSoundMeter = userId => {
        const memberInfo = this.getRoomVideo(userId);
        console.log(LogPrefix,memberInfo,"memberInfo",memberInfo.audioContext,memberInfo.stream);
        if(memberInfo) {
            if (memberInfo.stream && memberInfo.attached && memberInfo.played) {
                if (!memberInfo.audioContext) memberInfo.audioContext = new (window.AudioContext || window.webkitAudioContext)();
                if(memberInfo.soundMeterInterval) clearInterval(memberInfo.soundMeterInterval);
                if (memberInfo.soundMeter) {
                    memberInfo.soundMeter.stop();
                } else {
                    // memberInfo.soundMeter = new SoundMeter2(toJS(memberInfo.audioContext));
                    memberInfo.soundMeter = new SoundMeter(toJS(memberInfo.audioContext));
                }
                memberInfo.soundMeter.connectToSource(memberInfo.stream, (e) => {
                    if (e) {
                        console.warn(LogPrefix, "SoundMeter connection error", toJS(memberInfo), e);

                        memberInfo.isSoundMetering = false;
                    } else {
                        console.log(LogPrefix, "SoundMeter connection success", toJS(memberInfo));

                        memberInfo.isSoundMetering = true;
                        if (!memberInfo.soundMeterInterval) {
                            memberInfo.soundMeterInterval = setInterval(() => {
                                let soundVal = memberInfo.isSoundMetering ? memberInfo.soundMeter.instant.toFixed(2) * 100 : 0;
                                // let soundVal = memberInfo.isSoundMetering ? memberInfo.soundMeter.instant : 0;
                                //console.log(LogPrefix, "SoundMeterValue", soundVal);
                                this.setSoundMeterValue(memberInfo, soundVal);
                            }, 20);
                        }
                    }
                });
            }
        }
    }

    @action calledOwner = tinyGroupId => {
        const tinyGroup = this.tinyGroups.find(tg => String(tg.id) === String(tinyGroupId));
        if(tinyGroup) {
            tinyGroup.isCalled = true;
        }
    }

    @action requestChangeOwnerGroup = (targetGroupId) => {
        const oriTinyGroup = this.tinyGroups.find(tg => String(tg.id) === String(targetGroupId));
        oriTinyGroup.isOwnerExist = true;
        oriTinyGroup.isCalled = false;

        const tinyGroup = _.cloneDeep(oriTinyGroup);
        this.addOwner(tinyGroup);

        tinyGroup.members.forEach((m, i) => {
            const memberInfo = this.tinyGroupVideoMap[getVideoMapKey(i)];
            const userId = String(m.id);
            if(memberInfo && !memberInfo.isOwner) {
                memberInfo.userId = userId;
                memberInfo.refIndex = i;
                this.leaveAndSubscribe(memberInfo, userId);
            }
        });

        this.setTinyGroup(tinyGroup);
    }

    @action receivedChangeOwnerGroup = (targetGroupId) => {
        const isOwnerExist = String(this.tinyGroup.id) === String(targetGroupId);
        this.tinyGroup.isOwnerExist = isOwnerExist;
        const ownerMemberInfo = Object.values(this.tinyGroupVideoMap).find(memberInfo => memberInfo.isOwner);

        if(ownerMemberInfo) {
            if (isOwnerExist) {
                this.leaveAndSubscribe(ownerMemberInfo, ownerMemberInfo.roomUserId);
            } else {
               this.leave(ownerMemberInfo);
            }
        }
    }

    @computed
    get videoDevices() {
        const videoDevices = this.devices.filter(device => device.deviceId !== 'default').filter(device => device.kind === 'videoinput');
        videoDevices.push({ deviceId: "videoOff", label: "비디오 끄기" });
        return videoDevices;
    }

    @computed
    get videoPublishInfo() {
        return { deviceId: this.selectedVideoDeviceId ? this.selectedVideoDeviceId : "default", width: TINY_GROUP_VIDEO_RESOLUTION_WIDTH, height: TINY_GROUP_VIDEO_RESOLUTION_HEIGHT };
    }

    @computed
    get audioDevices() {
        const audioDevices =  this.devices.filter(device => device.deviceId !== 'default').filter(device => device.kind === 'audioinput');
        audioDevices.push({ deviceId: "audioOff", label: "오디오 끄기" });
        return audioDevices;
    }

    @computed
    get audioPublishInfo() {
        return { deviceId: this.selectedAudioDeviceId ? this.selectedAudioDeviceId : "default"};
    }

    @computed
    get tinyGroupCount() {
        return this.members ? this.members.length > this.tinyGroupMaxUserCount ? Math.ceil(this.members.length/this.tinyGroupMaxUserCount) : 1 : 0;
    }

    @computed
    get currentTinyGroupIndex() {
        return this.tinyGroups.findIndex(tg => tg.id === this.tinyGroup.id);
    }

    saveTinyGroups = flow(function * (roomId, janus, userId, displayName, callbacks) {
        console.log(LogPrefix, 'saveTinyGroups', roomId, janus, userId, displayName, callbacks);

        try {
            const channelId = roomId;
            this.tinyGroups.forEach((tg, i) => tg.id = (i + 1));
            const param = {
                tinyGroups: toJS(this.tinyGroups)
            }
            this.janus = janus;
            const response = yield axios.post(`/api/v1/rooms/${channelId}/tiny/groups`, param);
            if(response.status === 200) {
                this.userId = userId;
                this.tinyGroups[0].isOwnerExist = true;
                const tinyGroup = _.cloneDeep(this.tinyGroups[0]);

                this.tinyGroupVideoMap[getVideoMapKey(0)] = this.getTinyGroupVideo(userId, janus, roomId, userId, displayName, 0);
                for(let i = 0; i < TINY_GROUP_MAX_USER_COUNT; i++) {
                    const member = tinyGroup.members[i];
                    if(member) {
                        const memberId = String(member.user.userId);
                        this.tinyGroupVideoMap[getVideoMapKey(i + 1)] = this.getTinyGroupVideo(userId, janus, roomId, memberId, displayName, i + 1);
                    } else {
                        this.tinyGroupVideoMap[getVideoMapKey(i + 1)] = this.getTinyGroupVideo(userId, janus, roomId, `tiny-group-member-${userId}-${i}`, displayName, i + 1);
                    }
                }

                this.addOwner(tinyGroup);
                this.setTinyGroup(tinyGroup);
                if(callbacks.sendMessage) callbacks.sendMessage();
                setTimeout(() => this.publish(), 1500);
                this.setTinyGroupDialogOpen(true);
                setTimeout(() => this.setTinyGroupClosedButtonDisabled(false), 6000);
            }
        } catch (e) {
            console.log("Can't save tiny groups", e);
        }
    });

    // getTinyGroup = flow(function * (janus, roomId, roomUserId, userId, displayName, ownerGroupId) {
    //     try {
    //         this.janus = janus;
    //         this.userId = userId;
    //         const channelId = roomId;
    //         const response = yield axios.get(`/api/v1/rooms/${channelId}/tiny/groups/${userId}`);
    //         if (response.status === 200 && response.data.members) {
    //             console.log(LogPrefix, 'Get a tiny group success', response.data);
    //
    //             const tinyGroup = {
    //                 id: response.data.id,
    //                 name: response.data.name,
    //                 isOwnerExist: String(response.data.id) === String(ownerGroupId),
    //                 members: response.data.members.map(m => this.members.find(chatMember => String(chatMember.user.userId) === String(m.id)))
    //             };
    //             this.tinyGroupVideoMap[getVideoMapKey(0)] = this.getTinyGroupVideo(roomUserId, janus, roomId, roomUserId, displayName, 0);
    //             for (let i = 0; i < tinyGroup.members.length; i++) {
    //
    //                 const member = tinyGroup.members[i];
    //                 const memberId = String(member.id);
    //                 this.tinyGroupVideoMap[getVideoMapKey(i + 1)] = this.getTinyGroupVideo(roomUserId, janus, roomId, memberId, displayName, i + 1);
    //
    //                 const memberInfo = this.getRoomVideo(memberId);
    //                 setTimeout(() => this.leaveAndSubscribe(memberInfo, memberId), 3000);
    //             }
    //
    //             this.addOwner(tinyGroup);
    //             this.setTinyGroup(tinyGroup);
    //             // setTimeout(() => this.publish(), 1000);
    //             const ownerInfo = this.getRoomVideo(roomUserId);
    //             setTimeout(() => this.leaveAndSubscribe(ownerInfo, roomUserId), 3000);
    //             this.setTinyGroupDialogOpen(true);
    //         }
    //     } catch (e) {
    //         console.log("Can't get tiny group", e);
    //     }
    // });


    getTinyGroup = flow(function * (janus, roomId, roomUserId, userId, displayName, ownerGroupId) {
        try {
            this.janus = janus;
            this.userId = userId;
            const channelId = roomId;
            const response = yield axios.get(`/api/v1/rooms/${channelId}/tiny/groups/${userId}`);
            if (response.status === 200 && response.data.members) {
                console.log(LogPrefix, 'Get a tiny group success', response.data);

                const tinyGroup = {
                    id: response.data.id,
                    name: response.data.name,
                    isOwnerExist: String(response.data.id) === String(ownerGroupId),
                    members: response.data.members.map(m => this.members.find(chatMember => String(chatMember.id) === String(m.id)))
                };

                this.tinyGroupVideoMap[getVideoMapKey(0)] = this.getTinyGroupVideo(roomUserId, janus, roomId, roomUserId, displayName, 0);
                this.tinyGroupVideoMap[getVideoMapKey(1)] = this.getTinyGroupVideo(roomUserId, janus, roomId, userId, displayName, 1);
                const otherMembers = _.filter(tinyGroup.members, (m) => String(m.user.userId) !== String(userId));
                for (let i = 0; i < otherMembers.length; i++) {
                    const member = otherMembers[i];
                    const memberId = String(member.user.userId);
                    this.tinyGroupVideoMap[getVideoMapKey(i + 2)] = this.getTinyGroupVideo(roomUserId, janus, roomId, memberId, displayName, i + 2);
                }

                this.addOwner(tinyGroup);
                this.setTinyGroup(tinyGroup);
                setTimeout(() => this.publish(), 1000);
                this.setTinyGroupDialogOpen(true);
            }
        } catch (e) {
            console.log("Can't get tiny group", e);
        }
    });



    // setChatMemberAvatar = flow(function* setChatMemberAvatar(member) {
    //     try {
    //         if(member) {
    //             console.log(LogPrefix, `Getting avatar for ${member.user.id}`);
    //
    //             const result = yield axios.get(`/api/v1/avatars/${member.user.id}`);
    //             const avatar = result.data;
    //
    //             if(avatar) {
    //                 member.avatar = avatar;
    //             }
    //         }
    //     } catch(error) {
    //         console.log(LogPrefix, `Get avatar for ${member.user.id} error`);
    //     }
    // });
}
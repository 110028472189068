/*global _CONST_TYPES*/
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SmsIcon from "@material-ui/icons/Sms";
import PeopleIcon from "@material-ui/icons/People";
import Drawer from "@material-ui/core/Drawer";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {ListItemAvatar, ListItemText, Typography} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Button from "@material-ui/core/Button";
import Badge from "@material-ui/core/Badge";
import * as store from "../../../stores/BroadcastStore";
import * as Type from "../../../stores/BroadcastStore";
import * as Chat from "../BroadcastChat";
import {msgType} from "../BroadcastChat";
import moment from "moment";
import BroadcastVideoTrackEmpty from "../component/BroadcastVideoTrackEmpty";
// import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';

const useStyles = (mainWidth) => {
    return makeStyles((theme) => ({
        chatInput: {
            marginLeft: theme.spacing(1),
            flex: 1,
        },
        chatGrid: {
            borderTop: 'none',
        },
        chatMessage: {
            height: '100%',
            maxHeight: '100%',
            overflow: 'auto',
            wordBreak: 'break-all',
            // height: '78%',
            // height: '100%',
            // backgroundColor: '#f7f7f7',
            backgroundColor: '#ffffff',
            [theme.breakpoints.down("sm")]: {
                order:3,
            }
        },
        drawer: {
            overflowX: 'hidden',
            width: theme.chatWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            overflowX: 'hidden',
            width: theme.chatWidth,
            [theme.breakpoints.down("md")]: {
                //width:'100%'
            }
        },
        toolbar: {
            ...theme.mixins.toolbar,
            //minHeight: '64px',
        },
        drawerPaperAnchorRight: {
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
            height: `calc(100vh - 64px)`,
            marginTop: '64px',
            //minHeight: '630px',
        },
        chatInputDiv: {
            padding: '2px 4px',
            display: 'flex',
            borderTop:'1px solid #eee',
            order:4,
            height:'48px',
            minHeight: '48px',
            lineHeight:'48px',
        },
        chatUserList:{
            alignItems:'flex-start',
        },
        chatUserMineList:{
            alignItems:'flex-start',
            flexDirection: 'row-reverse',
            textAlign: 'right',
        },
        chatTime:{
            fontSize: '5px',
            float: 'left',
            flexDirection: 'column',
            // marginTop: 'auto',
            alignItems: 'flex-end',
            // display: 'flex',
            color: '#9b9b9b',
            marginRight: '6px',
            marginTop: '6px',
            width: '100%',
            textAlign: 'left',
        },
        chatTimeMine:{
            fontSize: '5px',
            float: 'right',
            flexDirection: 'column',
            // marginTop: 'auto',
            alignItems: 'flex-end',
            // display: 'flex',
            color: '#9b9b9b',
            marginRight: '6px',
            marginTop: '6px',
            width: '100%',
            textAlign: 'right',
        },
        chatUser:{
            '& p:first-child':{
                fontWeight:'600',
                fontSize:'12px',
                color:'rgba(0, 0, 0, 0.54)',
                marginBottom:'4px',
                textAlign: 'left',
            },
            '& p:nth-child(2)':{
                float : 'left'
                // backgroundColor: '#fff',
                // padding: '12px 8px',
                // borderRadius: '0px 10px 10px 10px',
                // boxShadow:'0 1px 4px #eaeaea',
                // display:'inline-block',
                // fontWeight:'500',
                // fontSize:'13px',
                // color:'#333',
            },
            // '& p:nth-child(2) p':{
            //     fontWeight:'500',
            //     fontSize:'13px',
            //     color:'#333',
            //     marginBottom: 0,
            // },
        },
        chatUserMine:{
            paddingRight: '16px',
            '& p:first-child':{
                fontWeight:'600',
                fontSize:'12px',
                color:'rgba(0, 0, 0, 0.54)',
                marginBottom:'4px',
                textAlign: 'right'
            },
            '& p:nth-child(2)':{
                float : 'right'
                // backgroundColor: '#fff',
                // padding: '12px 8px',
                // borderRadius: '0px 10px 10px 10px',
                // boxShadow:'0 1px 4px #eaeaea',
                // display:'inline-block',
                // fontWeight:'500',
                // fontSize:'13px',
                // color:'#333',
            },
            // '& p:nth-child(2) p':{
            //     fontWeight:'500',
            //     fontSize:'13px',
            //     color:'#333',
            //     marginBottom: 0,
            // },
        },
        chatMsgSystem: {
            position:'relative',
            '&::before':{
                // content:'" "',
                position:'absolute',
                top:'calc(1.5em - 2px)',
                left:0,
                borderTop:'1px solid #e2e2e2',
                width:'100%'
            },
            '& li div span':{
                // color:'#c1010a',
                color:'#333333',
                // color:'#c1010a',
                // backgroundColor:'#fff',
                backgroundColor:'#f0f0f0',
                // border:'1px solid #e2e2e2',
                fontWeight:'800',
                display:'inline-block',
                borderRadius: '50px',
                padding: '4px 20px',
                fontSize: '12px'
            },
        },
        chatMsgPresentation: {
            position:'relative',
            '&::before':{
                // content:'" "',
                position:'absolute',
                top:'calc(1.5em - 2px)',
                left:0,
                borderTop:'1px solid #e2e2e2',
                width:'100%'
            },
            '& li div span':{
                // color:'#c1010a',
                color:'#333333',
                // backgroundColor:'#fff',
                backgroundColor:'#fdea82',
                // border:'1px solid #e2e2e2',
                fontWeight:'800',
                display:'inline-block',
                borderRadius: '50px',
                padding: '4px 20px',
                fontSize: '12px'
            },
        },
        chatMsgQuestion: {
            position:'relative',
            '&::before':{
                // content:'" "',
                position:'absolute',
                top:'calc(1.5em - 2px)',
                left:0,
                borderTop:'1px solid #e2e2e2',
                width:'100%'
            },
            '& li div span':{
                // color:'#c1010a',
                color:'#ffffff',
                // backgroundColor:'#fff',
                backgroundColor:'#c41c1b',
                // border:'1px solid #e2e2e2',
                fontWeight:'800',
                display:'inline-block',
                borderRadius: '50px',
                padding: '4px 20px',
                fontSize: '12px'
            },
        },
        micIcon:{
            fontSize:'1rem',
            color:'#7b7b7b',
            // border:'1px solid #bfbfbf',
            borderRadius:'50px',
            padding:'8px',
            height:'20px',
            width:'20px',
            position:'absolute',
            bottom:theme.spacing(1),
            right:theme.spacing(1),
            boxShadow: '0px 3px 10px 0 rgba(165, 156, 156, 0.56)',
            backgroundColor:'rgba(255,255,255,1)',
            cursor:'pointer',
            zIndex:900,
            '&:hover, &:focus':{
                backgroundColor:'rgba(255,255,255,0.87)',
                boxShadow:'none',
            }
        },
        chatOwnerMsg: {
            backgroundColor: '#3c3c3c',
            padding: '3px 8px',
            borderRadius: '0px 10px 10px 10px',
            boxShadow:'0 1px 4px #eaeaea',
            display:'inline-block',
            fontWeight:'500',
            fontSize:'13px',
            color: '#ffffff',
        },
        chatMemberMsg: {
            backgroundColor: '#fff',
            padding: '3px 8px',
            borderRadius: '0px 10px 10px 10px',
            boxShadow:'0 1px 4px #eaeaea',
            display:'inline-block',
            fontWeight:'500',
            fontSize:'13px',
            color:'#333',
        },
        chatOwnerMineMsg: {
            backgroundColor: '#3c3c3c',
            padding: '3px 8px',
            borderRadius: '10px 0px 10px 10px',
            boxShadow:'0 1px 4px #eaeaea',
            display:'inline-block',
            fontWeight:'500',
            fontSize:'13px',
            color: '#ffffff',
        },
        chatMemberMineMsg: {
            backgroundColor: '#fff',
            padding: '3px 8px',
            borderRadius: '10px 0px 10px 10px',
            boxShadow:'0 1px 4px #eaeaea',
            display:'inline-block',
            fontWeight:'500',
            fontSize:'13px',
            color:'#333',
        },
        videoOff: {
            zIndex: 1050,
            position: 'absolute',
            width: '320px',
            height: '240px',
            [theme.breakpoints.down("xs")]: {
                height: '210px',
            },
            // backgroundImage: 'url("/images/bg-play-stop.jpg")',
            // backgroundSize: 'cover',
        },
        videoOffImg: {
            zIndex: 1051,
            display: 'flex',
        },
        videoBtns: {
            position:'absolute',
            top:'120px',
            left:'50%',
            marginLeft:'-21px',
            marginTop:'-21px',
            fontSize: '14px',
            color:'#fff',
            zIndex: 1050,
            '& span':{
                margin:0,
            },
            '& svg':{
                width:'60px',
                height:'60px',
                boxShadow: '0 1px 11px #b5b5b5',
                borderRadius: '50%',
            }
        },
        videoActionIcon: {
            color: '#fff!important',
            '& svg':{
                color:'#fff',
            }
        },
        firstPlayText: {
            display: 'block',
            zIndex: 1051,
            height: '23px',
            objectFit: 'contain',
            fontSize: '14px',
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: '0.5',
            letterSpacing: 'normal',
            textAlign: 'center',
            color: '#fff2ac',
            marginTop: '8px',
        },
        BtnPresentation: {
            borderRadius: '18px',
            backgroundColor: '#fdea82'
        },
        BtnPresentationDisable: {
            borderRadius: '18px',
            backgroundColor: '#dcdbdc',
            //color: '#ffffff'
        },
        BtnQuestionAllow: {
            borderRadius: '18px',
            backgroundColor: '#b50d0f',
            color: '#ffffff'
        },
        BtnQuestionAllowDisable: {
            borderRadius: '18px',
            backgroundColor: '#dcdbdc'
        }
    }));
};

export default function BroadcastDrawer(props) {
    const classes = useStyles(props.mainWidth)();
    const { userInfo, chatMsgList, chatMsg, chatTabSelected, chatUserList, channel, chatIssueCount, chatQuestionObjList, inPresentation, inQuestion, isFirstPlayCheck, isVideoTrackOff, mainWidth, } = props;
    const { handleChatTab,
            handleChangeChatMsg,
            handleChatScroll,
            handleKeyUpChatMsg,
            handleChatMsgSend,
            handleVideoMouseOver,
            handleAllowQuestion,
            handleDoStopQuestion,
            getScreenView,
            playerOnOffHandle,
            getScreenText,
            getScreenSound,
            handleSoundToggle,
            changeIsFirstPlayCheck,
            getIsWebRtc,
            changeResetDialogOpen,
            changePresentationErrorDialogOpen,
    } = props;

    const getIssueCount = () => {
        return chatIssueCount;
    }

    const renderDrawChatUserList = () => {
        const renderButton = (item) => {
            if(!channel || (channel && !channel.subScreenAvailableFlag)) {
                return null;
            }

            // const renderPresentationBtn = () => {
            //     let disabled = true;
            //     if(!(inPresentation || inQuestion) && item.presentationAvailableFlag === Chat.presentationAvailableFlag.Y && !subScreenView) {
            //         disabled = false;
            //     }
            //
            //     if(chatPresentationObj && inPresentation && chatPresentationObj.userId === item.userId) {
            //         return (
            //             <Button className={classes.BtnPresentationDisable}
            //                     onClick={handleDoEndPresentation}
            //                     variant={"contained"}
            //             >
            //                 발표중지
            //             </Button>
            //         );
            //     }
            //
            //     return (
            //         <Button className={classes.BtnPresentation}
            //                 variant={"contained"}
            //                 disabled={disabled}
            //                 onClick={() => handleDoAnnounce(item.userId)}
            //         >
            //             발표요청
            //         </Button>
            //     );
            // };

            const renderQuestionBtn = () => {
                let disabled = true;
                let isQuestionRequest = false;
                let stopBtnActive = false;
                let stopQObj = null;
                if(chatQuestionObjList) {
                    chatQuestionObjList.forEach(qObj => {
                        if(item.userId === qObj.userId) {
                            isQuestionRequest = (qObj.statusCode === _CONST_TYPES._PRESENTATION_STATUS.REQUEST);
                            if(qObj.statusCode === _CONST_TYPES._PRESENTATION_STATUS.START) {
                                stopBtnActive = true;
                                stopQObj = qObj;
                            }
                        }
                    });
                }

                if(stopBtnActive) {
                    return (
                        <Button className={classes.BtnQuestionAllow}
                                onClick={() => handleDoStopQuestion(stopQObj)}>
                            질문중지
                        </Button>
                    );
                }

                if(isQuestionRequest && item.questionAvailableFlag === Chat.questionAvailableFlag.Y) {
                    disabled = false;
                }

                return (
                    <Button className={disabled ? classes.BtnQuestionAllowDisable : classes.BtnQuestionAllow}
                            disabled={disabled}
                            onClick={() => {
                                if(inPresentation || inQuestion) {
                                    changePresentationErrorDialogOpen(true);
                                    return null;
                                }

                                handleAllowQuestion(item.userId);
                            }}
                            variant={"contained"}
                    >
                        질문수락
                    </Button>
                );
            };

            if(channel && channel.userId === item.userId) {
                return (
                    <ListItemSecondaryAction>
                        <Button onClick={() => changeResetDialogOpen(true)}>리셋</Button>
                        {/*<CircularProgress size={22}/>*/}
                    </ListItemSecondaryAction>
                );
            }

            if(channel && channel.userId !== item.userId) {
                return (
                    <ListItemSecondaryAction>
                        <React.Fragment>
                            {/*{renderPresentationBtn()}*/}
                            {renderQuestionBtn(item)}
                        </React.Fragment>
                    </ListItemSecondaryAction>
                );
            }
        }

        const renderUserList = () => {
            if(chatUserList) {
                let ownerRender = false;
                return chatUserList.map((item, index) => {
                    if(item.typeCode === store.chatUserType.OWNER) {
                        if(ownerRender) {
                            return null;
                        }
                        ownerRender = true;
                    }
                    return (
                        <div key={"chat-user-list"+index}>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar
                                        // style={{backgroundColor: item.chatForegroundColor}}
                                            src={item.chatIconUrl ? item.chatIconUrl : ""} />
                                    {/*    {item.userName.substring(0, 1)}*/}
                                </ListItemAvatar>
                                <ListItemText
                                    primary={item.userName}
                                />
                                {renderButton(item)}
                            </ListItem>
                        </div>
                    );
                })
            }
        }

        return (
            <React.Fragment>
                <Divider/>
                <List dense={true}>
                    {renderUserList()}
                </List>
            </React.Fragment>
        );
    };

    const renderDrawChat = () => {
        return (
            <React.Fragment>
                <div className={classes.chatMessage} id={"chatMsgArea"}>
                    <List dense={true}>
                        { chatMsgList ? chatMsgList.map((item, index) => {
                            setTimeout(() => handleChatScroll(), 1000);
                            //console.log();
                            return (
                                <div key={"chat-message"+index}>
                                    {item.msgType === msgType.CHAT_MSG ?
                                        <ListItem className={item.sentUser.userId === userInfo.userId ? classes.chatUserMineList : classes.chatUserList}>
                                            <ListItemAvatar>
                                                <Avatar
                                                        // style={{backgroundColor: item.sentUser.chatForegroundColor}}
                                                        src={item.sentUser.chatIconUrl ? item.sentUser.chatIconUrl : ""} />
                                                {/*{item.sentUser.userName.substring(0, 1)}*/}
                                            </ListItemAvatar>
                                            <ListItemText
                                                className={item.sentUser.userId === userInfo.userId ? classes.chatUserMine : classes.chatUser}
                                                disableTypography={true}
                                                primary={<Typography color={"textSecondary"}>{item.sentUser.userName}</Typography>}
                                                secondary={
                                                    <React.Fragment>
                                                            {/*{item.msg}*/}
                                                        <Typography
                                                            className={item.sentUser.typeCode === store.chatUserType.OWNER
                                                                ? item.sentUser.userId === userInfo.userId ? classes.chatOwnerMineMsg : classes.chatOwnerMsg
                                                                : item.sentUser.userId === userInfo.userId ? classes.chatMemberMineMsg : classes.chatMemberMsg}
                                                            //color="textPrimary"
                                                        >
                                                            {item.msg}
                                                        </Typography>
                                                        <div className={item.sentUser.userId === userInfo.userId ? classes.chatTimeMine : classes.chatTime}>
                                                            {moment().format("a h:mm")}
                                                        </div>
                                                    </React.Fragment>
                                                }

                                            />
                                        </ListItem>
                                        :
                                        <div className={item.msgType === msgType.SYSTEM_MSG ? classes.chatMsgSystem : item.msgType === msgType.PRESENTATION_MSG ? classes.chatMsgPresentation : item.msgType === msgType.QUESTION_MSG ? classes.chatMsgQuestion : classes.chatMsgSystem}>
                                            <ListItem>
                                                <ListItemText align={"center"}>{item.msg}</ListItemText>
                                            </ListItem>
                                        </div>
                                    }
                                </div>
                            );
                        }) : "" }
                    </List>
                </div>

                <div className={classes.chatInputDiv}>
                    <Divider/>
                    <InputBase
                        className={classes.chatInput}
                        value={chatMsg}
                        onChange={handleChangeChatMsg}
                        onKeyUp={handleKeyUpChatMsg}
                        inputProps={{ 'aria-label': 'naked' }}
                        placeholder='메세지를 입력하세요'
                    />
                    <IconButton onClick={handleChatMsgSend}>
                        <SendIcon/>
                    </IconButton>
                </div>
            </React.Fragment>
        );
    };

    const renderVideoOffDiv = () => {
        if(getScreenView(Type.broadcastVideoEl.SUB) && isFirstPlayCheck) {
            return (
                <div className={classes.videoOff}
                     style={{cursor: "pointer"}}
                     id={Type.videoOffLayer.SUB}
                     onClick={() => {changeIsFirstPlayCheck(false)}}
                >
                    <img src={"/images/p-bg.png"} alt={""} width={320} height={240} style={{position: "absolute"}} />
                </div>
            );
        }

        return (
            <React.Fragment>
            {!getScreenView(Type.broadcastVideoEl.SUB) ?
                <div className={classes.videoOff}
                     id={Type.videoOffLayer.SUB}
                     onClick={() => {playerOnOffHandle(Type.broadcastVideoEl.SUB)}}
                >
                    <img src={"/images/p-bg.png"} alt={""} width={320} height={240} style={{position: "absolute"}} />
                </div>
                : ""}
            </React.Fragment>
        );
    };

    const renderVideoOffBtn = () => {
        if(getScreenView(Type.broadcastVideoEl.SUB) && isFirstPlayCheck) {
            return (
                <React.Fragment>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            width: '100%',
                            height: '240px',
                            position: "absolute",
                            top: 0,
                            left: 0,
                            margin: 0,
                            cursor: 'pointer',
                            zIndex: 1060,
                        }}
                        onClick={() => {changeIsFirstPlayCheck(false)}}
                    >
                        <Button
                            // style={{
                            //     display: 'flex',
                            // }}
                            onClick={() => {playerOnOffHandle(Type.broadcastVideoEl.SUB)}}
                        >
                            <img src={"/images/play.png"} width={42} height={42} alt=""/>
                        </Button>
                        <div className={classes.firstPlayText}>
                            방송이 재생 중이니 플레이 버튼을 눌러주세요.
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {!getScreenView(Type.broadcastVideoEl.SUB) ?
                    <Button
                        className={classes.videoBtns}
                        onClick={() => {playerOnOffHandle(Type.broadcastVideoEl.SUB)}}
                    >
                        <img src={"/images/play.png"} width={42} height={42} alt=""/>
                    </Button>
                    :
                    ""
                }
            </React.Fragment>
        );
    };

    return (
        <Drawer
            id={"broadcastDrawer"}
            className={classes.drawer}
            variant="permanent"
            classes = {{
                paper: classes.drawerPaper,
                paperAnchorRight: classes.drawerPaperAnchorRight,
            }}
            // style={drawerStyle.drawerPaperAnchorRight}
            anchor="right"
        >
            {/* <div className={classes.toolbar}/> */}
            {channel && channel.subScreenAvailableFlag ?
                <React.Fragment>
                    <div>
                        <div style={{
                            padding: '8px',
                            display: 'flex',
                            alignItems: "center",
                            justifyContent: "center",
                            color:'#fff2ac',
                            zIndex: 1051,
                            position: "absolute",
                            right: 0,
                        }}>
                            {getScreenText(Type.broadcastVideoEl.SUB)}
                        </div>
                        <div style={{
                            padding: '8px',
                            color: 'white',
                            zIndex: 1051,
                            position: "absolute",
                            cursor: 'pointer',
                            left: 0,
                            top: 'calc(240px - 53px)',
                        }}
                             onClick={() => handleSoundToggle(Type.broadcastVideoEl.SUB)}
                        >
                            {getScreenSound(Type.broadcastVideoEl.SUB)}
                        </div>
                        {renderVideoOffDiv()}
                        <div>
                            {renderVideoOffBtn()}
                            {getIsWebRtc(Type.broadcastVideoEl.SUB) && mainWidth > 600 ?
                                <video id={Type.broadcastVideoEl.SUB}
                                       style={{backgroundColor: '#6e6e6e', cursor: "pointer"}}
                                       width={320}
                                       height={240}
                                       playsInline={true}
                                       autoPlay={true}
                                       onClick={() => {playerOnOffHandle(Type.broadcastVideoEl.SUB)}}
                                       onMouseOver={() => {handleVideoMouseOver(true)}}
                                       onMouseOut={() => {handleVideoMouseOver(false)}}
                                >
                                </video>
                                : ""}
                            {!getIsWebRtc(Type.broadcastVideoEl.SUB) ?
                                <canvas id={Type.broadcastVideoEl.SUB}
                                        style={{backgroundColor: '#6e6e6e', cursor: "pointer"}}
                                        width={320}
                                        height={240}
                                        onClick={() => {playerOnOffHandle(Type.broadcastVideoEl.SUB)}}
                                        onMouseOver={() => {handleVideoMouseOver(true)}}
                                        onMouseOut={() => {handleVideoMouseOver(false)}}
                                >
                                </canvas>
                                : ""}
                        </div>
                    </div>

                    {getScreenView(Type.broadcastVideoEl.SUB) && isVideoTrackOff && !isFirstPlayCheck ?
                        <div style={{position: "absolute", width: 320, height: 240}}>
                            <BroadcastVideoTrackEmpty/>
                        </div>
                        :
                        ""
                    }

                </React.Fragment>
                : ""}
            <div className={classes.toolbar} style={{
                display: 'flex',
                alignItems: "flex-end",
                borderBottom:'1px solid rgba(0, 0, 0, 0.12)'
            }}>
                <Tabs
                    value={chatTabSelected}
                    variant={"fullWidth"}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="disabled tabs example"
                >
                    <Tab name={store.chatTabSelectType.CHAT} icon={<SmsIcon/>} onClick={handleChatTab} value={store.chatTabSelectType.CHAT} />
                    <Tab name={store.chatTabSelectType.CHAT_USER_LIST} icon={
                        <Badge badgeContent={getIssueCount()} color={"primary"}>
                            <PeopleIcon/>
                        </Badge>
                    } onClick={handleChatTab} value={store.chatTabSelectType.CHAT_USER_LIST} />
                </Tabs>
            </div>
            {(chatTabSelected === store.chatTabSelectType.CHAT) ? renderDrawChat() : renderDrawChatUserList()}
        </Drawer>
    );
}


import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
    Button,
    DialogActions, DialogTitle,
} from "@material-ui/core";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";

const style = theme => ({
});

class CommonDialog extends React.Component {
    render() {
        const { open, onClose, maxWidth, title, disableScrollLock, contentComponent, closeButtonColor, closeText, okButtonColor, onOk, okText, okButtonDisabled} = this.props;
        return (
            <Dialog disableBackdropClick open={open} onClose={onClose} fullWidth={true} maxWidth={maxWidth} scroll={'paper'} disableScrollLock={disableScrollLock ? true : false}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    {contentComponent}
                </DialogContent>
                <DialogActions>
                    {onClose &&
                        <Button color={closeButtonColor ? closeButtonColor : 'default'}
                                onClick={onClose}
                        >
                            {closeText ? closeText : '취소'}
                        </Button>
                    }

                    {onOk &&
                        <Button color={okButtonColor ? okButtonColor : 'primary'}
                                onClick={onOk}
                                disabled={okButtonDisabled ? okButtonDisabled : false}
                        >
                            {okText ? okText : '확인'}
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        );
    }
}

export default withSnackbar(withRouter(withStyles(style) (CommonDialog)));
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import moment from "moment";


const styles = (theme) => ({
    dialog: {
    },
    dialogTitle: {

    },
    dialogContent: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    itemHeader: {
        padding: theme.spacing(2),
        backgroundColor: '#fafafa'
    },
    itemBody: {
        padding: theme.spacing(2),
    },
    innerCell: {
        padding: 0,
        border: 'none',
    },
    chip: {
        backgroundColor: '#fafafa',
        margin: 2,
    },
    inlineHeader: {
        display: 'inline',
        paddingRight: theme.spacing(1),
    },
    inline: {
        display: 'inline',
    },
});

const DateFormat = "LLL";

@inject('channelDetailStore')
@observer
class ChannelDetailDialog extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.channelDetailStore.isLoadFailed) {
            this.props.enqueueSnackbar('채널 정보 조회에 실패하였습니다.', {
                variant: 'error'
            });

            this.props.channelDetailStore.closeDetailDialog();
        }
    }

    handleClose = () => {
        this.props.channelDetailStore.closeDetailDialog();
    }

    render() {
        const { classes  } = this.props;
        const { isOpenDetailDialog, channel } = this.props.channelDetailStore;

        return (
            <Dialog disableBackdropClick fullWidth
                    maxWidth="md"
                    className={classes.dialog}
                    open={isOpenDetailDialog}
                    onClose={this.handleClose}
                    aria-labelledby="channel-detail-dialog-title" >
                <DialogTitle id="channel-detail-dialog-title" className={classes.dialogTitle}>
                    강의 정보
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Divider />
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.itemHeader}>
                                    <Typography>강의 명</Typography>
                                </TableCell>
                                <TableCell className={classes.itemBody}>
                                    <Typography>{channel.channelName}</Typography>
                                </TableCell>
                                <TableCell className={classes.itemHeader}>
                                    {/*<Typography>부화면 사용 여부</Typography>*/}
                                    <Typography>강의 화면</Typography>
                                </TableCell>
                                <TableCell className={classes.itemBody}>
                                    {/*<Typography>{channel.subScreenAvailableFlag ? '사용' : '미사용'}</Typography>*/}
                                    <Typography>{channel.version > 1 ? 'v2.0' : 'v1.0'}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.itemHeader}>
                                    <Typography>방송주소</Typography>
                                </TableCell>
                                <TableCell colSpan={3} className={classes.itemBody}>
                                {/*{channel.channelBroadcastEndpointList.map((endpoint, index) =>*/}
                                {/*    <div key={`channel-endpoint-div-${index}`}>*/}
                                {/*        <Typography className={classes.inlineHeader}>{endpoint.typeCode === 'MAIN' ? '주화면' : '부화면'}</Typography>*/}
                                {/*        <Typography className={classes.inline}>{endpoint.broadcastEndpoint}</Typography>*/}
                                {/*    </div>*/}
                                {/*)}*/}
                                    {channel.channelBroadcastEndpointList.filter(endpoint => endpoint.typeCode === 'MAIN').map((endpoint, index) =>
                                        <div key={`channel-endpoint-div-${index}`}>
                                            <Typography className={classes.inline}>{endpoint.broadcastEndpoint}</Typography>
                                        </div>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.itemHeader}>
                                    <Typography>설명</Typography>
                                </TableCell>
                                <TableCell colSpan={3} className={classes.itemBody}>
                                    <Typography>{channel.channelDesc}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{width: '15%'}} className={classes.itemHeader}>
                                    <Typography>시작 시각</Typography>
                                </TableCell>
                                <TableCell style={{width: '35%'}} className={classes.itemBody}>
                                    <Typography>{moment(channel.liveStartDatetime).format(DateFormat)}</Typography>
                                </TableCell>
                                <TableCell style={{width: '15%'}} className={classes.itemHeader}>
                                    <Typography>종료 시각</Typography>
                                </TableCell>
                                <TableCell style={{width: '35%'}} className={classes.itemBody}>
                                    <Typography>{moment(channel.liveEndDatetime).format(DateFormat)}</Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.itemHeader}>
                                    <Typography>{`참여 그룹 (${channel.inviteGroupList.map(group => group.groupUserCount).reduce((a, b) => a + b, 0)}명)`}</Typography>
                                </TableCell>
                                <TableCell colSpan={3} className={classes.itemBody}>
                                    {channel.inviteGroupList.map(group => <Chip key={`channel-group-chip-${group.groupId}`} className={classes.chip} label={`${group.groupName ? group.groupName : group.groupId} (${group.groupUserCount}명)`}/>)}
                                </TableCell>
                            </TableRow>
                            {/*<TableRow>*/}
                            {/*    <TableCell className={classes.itemHeader}>*/}
                            {/*        <Typography>{`참여 대상(${channel.channelMemberList.length}명)`}</Typography>*/}
                            {/*    </TableCell>*/}
                            {/*    <TableCell colSpan={3} className={classes.itemBody}>*/}
                            {/*        {channel.channelMemberList.map(member => <Chip key={`channel-member-chip-${member.email}`} className={classes.chip} label={member.member ? member.userName : member.email} />)}*/}
                            {/*    </TableCell>*/}
                            {/*</TableRow>*/}
                        </TableBody>
                    </Table>
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.handleClose}
                            style={{
                                borderRadius:'25px'
                            }}>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (ChannelDetailDialog)));
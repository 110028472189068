import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Avatar,
    Breadcrumbs,
    Container,
    Drawer,
    FormControl,
    FormControlLabel,
    Grid,
    Link,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from "@material-ui/icons/Check";
import CreateIcon from "@material-ui/icons/Create";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import RemoveIcon from "@material-ui/icons/Remove";

import {inject, observer} from "mobx-react";
import {toJS} from "mobx";

import Footer from "../../components/Footer";
import GroupCardComponent from "./GroupCardComponent";
import GroupUpdateDialog from "./GroupUpdateDialog";
import GroupAddDialog from "./GroupAddDialog";
import ShareAddDialog from "./ShareAddDialog";
import Button from "@material-ui/core/Button";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import MemberAddConfirmDialog from "./MemberAddConfirmDialog";
import MemberInfoDialog from "./MemberInfoDialog";
import MemberAddDialog from "./MemberAddDialog";

const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        marginTop: theme.spacing(2),
    },
    content: {
        marginTop: theme.spacing(3),
    },
    groupContainer: {
        padding: theme.spacing(2),
    },
    contentTitleContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: 2,
    },
    contentTitleLeftContainer: {
        textAlign: 'left',
        paddingLeft: theme.spacing(1),
    },
    contentTitleRightContainer: {
        textAlign: 'right',
        paddingRight: theme.spacing(2),
        '& label > span':{
            padding:'4px'
        },
        '& span':{
            fontSize:'13px'
        }
    },
    subTitleButton: {
        fontSize: 14,
        borderRadius: 20,
        '& svg':{
            width:'18px',
            height:'18px',
            marginRight:'4px'
        }
    },
    defaultBoxStyle:{
        '& > div':{
            boxShadow:'none',
            //border:'1px solid #eee',
        },
    },

    noHistoryContainer: {
        display: 'flex',
        width: '100%',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: theme.spacing(3),
    },
    noHistoryAvatar: {
        width: 160,
        height: 160,
        textAlign: 'center',
        border: 'solid 1px #d4d4d4',
        backgroundColor: '#FFFFFF',
    },
    noHistoryHeader: {
        fontSize: 16,
        color: '#333333',
        paddingTop: theme.spacing(5),
    },
    noHistoryBody: {
        fontSize: 14,
        color: '#b7b7b7',
        paddingTop: theme.spacing(1),
    },
    noHistoryButton: {
        borderRadius: 24,
        fontSize: 16,
        width: 200,
        height: 48,
        marginTop: theme.spacing(4),
    },

    card: {
        boxShadow:'none',
        border:'1px solid #eee',
        backgroundColor: '#e7e7e7',
        padding: theme.spacing(3),
        textAlign: 'center',
        cursor: 'pointer',
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(1),
    },


    helpContainer: {
        width: 350,
        padding: theme.spacing(4),
        boxSizing:'border-box'
    },
    helpLogoContainer: {
        display: 'flex',
        flexDirection:'column',
        textAlign:'center'

    },
    helpLogoImage: {
        padding: theme.spacing(2,0,1)
    },
    helpLogoText: {
        fontSize: 22,
        fontWeight: 800,
        margin:theme.spacing(2,0,1),
    },
    helpPageTitle:{
        textAlign:'center',
        fontSize:'1.1em',
    },
    helpTitleContainer: {
        paddingTop: theme.spacing(2),
        paddingLeft:'20px',
        lineHeight:'1.45',
        '& > li':{
            fontSize:'1em',
            marginBottom:'1.5em',
        },
        '& b':{
            borderBottom:'1px solid #333'
        }
    },
    helpBodyContainer: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        '& > li':{
            fontSize:'0.9em'
        }
    },
    helpIcon:{
        position: 'fixed',
        bottom: '35px',
        right: '35px',
        boxShadow: 'none',
        background:'none',
        width:'85px',
        fontSize: 15,
        '&:hover, &:focus, &:active':{
            background:'none',
            boxShadow:'none',
        },
        '& svg':{
            width:36,
            height:36,
            marginRight:4,
            boxShadow:'0 2px 3px #c1c1c1',
            borderRadius:'50px'
        },
        '& span':{
            color:'rgba(51,51,51,0.56)'
        }
    },
    helpCloseIcon: {
        position: 'absolute',
        right: '1rem',
        top: '1rem',
        color: 'rgba(51,51,51,0.37)',
        width:'40px',
        height:'40px',
        cursor: 'pointer',
    },
});


@inject('authStore', 'groupStore', 'memberAddStore', 'memberInfoStore', 'shareAddStore', 'helpStore')
@observer
class GroupList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmOpen: false,
        }
    }

    componentDidMount() {
        this.props.groupStore.loadGroupList(this.props.userId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.groupStore.isAdded) {
            this.props.groupStore.clearAddState(false);
            this.setState({confirmOpen: true});
        }

        if(this.props.groupStore.isAddFailed) {
            this.props.enqueueSnackbar('그룹 정보 추가에 실패하였습니다.', {
                variant: 'error'
            });

            this.props.groupStore.clearAddState(true);
        }

        if(this.props.groupStore.isUpdated) {
            this.props.enqueueSnackbar('그룹 정보를 업데이트 하였습니다.', {
                variant: 'info'
            });

            this.props.groupStore.clearUpdateState(false);
        }

        if(this.props.groupStore.isUpdateFailed) {
            this.props.enqueueSnackbar('그룹 정보 업데이트에 실패하였습니다.', {
                variant: 'error'
            });

            this.props.groupStore.clearUpdateState(true);
        }
    }

    handleChangePagingFilter = (event) => {
        const filter = event.target.value;

        this.props.groupStore.changePagingFilter(filter);
    }

    handleClickAddGroup = () => {
        this.props.groupStore.openAddGroupDialog(this.props.userId);
    }

    handleClickUpdateGroup = (groupId) => {
        this.props.groupStore.openUpdateGroupDialog(this.props.userId, groupId);
    }

    handleClickRemoveGroup = (groupId, userCount) => {
        this.props.groupStore.removeGroup(groupId, userCount, this.props.userId);
    }

    handleClickAddMember = (groupId) => {
        this.props.memberAddStore.openDialog(groupId, this.props.userId);
    }

    handleClickShowMember = (groupId) => {
        this.props.memberInfoStore.openDialog(groupId, this.props.userId);
    }

    handleClickAddShare = (groupId) => {
        this.props.shareAddStore.openDialog(groupId, this.props.userId);
    }

    handleCloseConfirmDialog = () => {
        this.setState({confirmOpen: false});
    }

    handleConfirmOk = () => {
        this.setState({confirmOpen: false});
        this.props.memberAddStore.openDialog(this.props.groupStore.lastNewGroupId, this.props.userId);
    }

    render() {
        const { classes } = this.props;
        const { paging, filteredGroupList, groupList } = this.props.groupStore;
        return (
            <React.Fragment>
                <Container component="main" className={classes.mainContainer}>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.mainContent} />
                    <Typography variant="h4" component="h2">
                        그룹 목록
                    </Typography>
                    <FormControl margin={"dense"}>
                        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                            <Link color={"inherit"} underline={"none"}>그룹</Link>
                            <Link color={"inherit"} href={"#"} onClick={(event) => {event.preventDefault()}}>그룹 목록</Link>
                        </Breadcrumbs>
                    </FormControl>

                    <Grid container className={classes.content} spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                            <Grid container className={classes.contentTitleContainer}>
                                <Grid item xs={8} className={classes.contentTitleLeftContainer}>
                                    <FormControl component="fieldset">
                                        <RadioGroup row value={paging.filter} onChange={this.handleChangePagingFilter}>
                                            <FormControlLabel value="all" control={<Radio />} label="모두" />
                                            <FormControlLabel value="my" control={<Radio />} label="내그룹" />
                                            <FormControlLabel value="share" control={<Radio />} label="공유받은그룹" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4} className={classes.contentTitleRightContainer}>
                                    <Button color="primary" variant="outlined" className={classes.subTitleButton} onClick={this.handleClickAddGroup}><AddRoundedIcon/>그룹추가</Button>
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                {groupList.length > 0 ?
                                    filteredGroupList.map(group =>
                                    <Grid item xs={12} key={`group-card-${group.groupId}`}>
                                        <GroupCardComponent  group={group} onClickUpdate={this.handleClickUpdateGroup} onClickAddMember={this.handleClickAddMember} onClickShowMember={this.handleClickShowMember} onClickAddShare={this.handleClickAddShare} onClickRemove={this.handleClickRemoveGroup} />
                                    </Grid>
                                    )
                                    :
                                    <div className={classes.noHistoryContainer}>
                                        <Avatar className={classes.noHistoryAvatar}><img src="/images/nohistory.png" alt="no history"/></Avatar>
                                        <Typography className={classes.noHistoryHeader}>아직 그룹이 없습니다.</Typography>
                                        <Typography className={classes.noHistoryBody}>강의 만들기시에 참여 인원 지정은 그룹을 통해서만 가능합니다.<br/>강의을 만들기 전에 미리 그룹을 생성해 주세요.</Typography>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>

                <GroupAddDialog open={this.props.groupStore.isOpenAddDialog}
                                group={toJS(this.props.groupStore.group)}
                                isAdded={this.props.groupStore.isAdded}
                                isAddFailed={this.props.groupStore.isAddFailed}

                                closeDialog={() => this.props.groupStore.clearAddState(false)}
                                onChangeGroupName={(name) => this.props.groupStore.changeGroupName(name)}
                                onChangeGroupDesc={(desc) => this.props.groupStore.changeGroupDesc(desc)}
                                addNewGroup={() => this.props.groupStore.addNewGroup(this.props.userId)}
                />
                <GroupUpdateDialog open={this.props.groupStore.isOpenUpdateDialog}
                                   group={toJS(this.props.groupStore.group)}
                                   isUpdated={this.props.groupStore.isUpdated}
                                   isUpdateFailed={this.props.groupStore.isUpdateFailed}
                                   
                                   closeDialog={() => this.props.groupStore.clearUpdateState(false)}
                                   onChangeGroupName={(name) => this.props.groupStore.changeGroupName(name)}
                                   onChangeGroupDesc={(desc) => this.props.groupStore.changeGroupDesc(desc)}
                                   updateGroup={() => this.props.groupStore.updateGroup(this.props.userId)}
                />

                <MemberAddDialog userId={this.props.userId} />
                <MemberAddConfirmDialog open={this.state.confirmOpen} closeDialog={this.handleCloseConfirmDialog} onOk={this.handleConfirmOk}/>
                <MemberInfoDialog userId={this.props.userId} />
                <ShareAddDialog />

                <Footer/>

                <div>
                    {/*<Fab size="small" className={classes.helpIcon} onClick={() => this.props.helpStore.openHelp()} >*/}
                    {/*    <HelpIcon color="primary" viewBox="2 2 20 20" /> <span>도움말</span>*/}
                    {/*</Fab>*/}

                    <Drawer anchor="right" open={this.props.helpStore.open} onClose={() => this.props.helpStore.closeHelp()} >
                        <CloseRoundedIcon className={classes.helpCloseIcon} onClick={() => this.props.helpStore.closeHelp()}/>

                        <div className={classes.helpContainer}>
                            <div className={classes.helpLogoContainer}>
                                <div className={classes.helpLogoImage}><img src="/images/logo-vert.png" alt="ONTHELIVE" /></div>
                                <Typography className={classes.helpLogoText}>도움말</Typography>
                            </div>

                            <div>
                                <Typography className={classes.helpPageTitle} variant="h5" >그룹목록</Typography>
                                <ol className={classes.helpTitleContainer}>
                                    <li>교수님은 강의을 개설하기 전에 나의 학생을 추가하면 학생은 별도의 회원 가입 과정 없이 편하게 이용이 가능하며, 그룹을 등록하여 학생들을 관리하면 강의 만들기가 편합니다.</li>

                                    <li>'출석부'의 <AddIcon />을 클릭하여 학생의 계정을 생성합니다.
                                        <ul className={classes.helpBodyContainer}>
                                            <li>학생의 출석 번호, 이름을 입력 하고 <CheckIcon />을 클릭하면, 학생 계정이 생성됩니다.</li>
                                            <li>비밀번호는 입력하지 않을 경우, 자동으로 6자리가 생성됩니다.</li>
                                            <li><CreateIcon />을 클릭하면, 정보를 수정할 수 있습니다.</li>
                                            <li><DeleteOutlineIcon />을 클릭하면, 삭제도 가능 합니다.</li>
                                        </ul>
                                    </li>

                                    <li>엑셀 파일을 이용하여 학생의 계정을 등록할 수 있습니다.
                                        <ul className={classes.helpBodyContainer}>
                                            <li>먼저, '출석부 형식 다운로드'를 클릭하여 출석부 작성에 필요한 엑셀 파일을 다운로드 받습니다.</li>
                                            <li>다른 엑셀 파일을 업로드 할 경우 학생 정보가 제대로 추가되지 않을 수 있습니다.</li>
                                            <li>다운로드 받은 파일에 학생의 정보를 입력합니다.</li>
                                            <li>'파일선택'을 클릭한 후 '출석부 올리기' 버튼을 클릭합니다.</li>
                                            <li>학생 정보가 제대로 표시 되는지 확인합니다.</li>
                                            <li>업로드에 실패하는 경우 중복된 학생 이름이 없는지 확인합니다.</li>
                                            <li>출석부에 같은 이름의 학생 이름은 등록할 수 없습니다.</li>
                                        </ul>
                                    </li>

                                    <li>'그룹목록'의 <AddIcon />을 클릭하여 그룹명, 그룹설명을 입력하고 추가를 클릭하여 그룹을 생성합니다.</li>

                                    <li>그룹이 생성되면 해당 그룹의 '<MoreVertIcon />더보기'의 '학생추가하기'를 통해 학생들을 추가해 줍니다.
                                        <ul className={classes.helpBodyContainer}>
                                            <li>학생의 이름 옆의 <AddIcon />을 클릭하고, 해당 그룹에 학생이 추가되었는지 확인합니다.</li>
                                            <li><RemoveIcon />을 클릭하여 해당 그룹에서 학생을 제외할 수 있습니다.</li>
                                            <li>'확인' 버튼을 누르면 해당 사항이 반영됩니다.</li>
                                            <li>학생 이름의 목록이 너무 길 경우, 학생 이름 검색에 학생의 이름을 입력하면 입력한 이름으로 시작하는 학생만 보여지게 됩니다.</li>
                                        </ul>
                                    </li>

                                    <li>해당 그룹을 다른 교수님에게 공유하기 위해서는 '<MoreVertIcon />더보기'의 '그룹공유하기'를 이용하면 됩니다.
                                        <ul className={classes.helpBodyContainer}>
                                            <li><AddIcon />와 <RemoveIcon />을 통해, 공유 목록에서 추가 및 삭제 합니다.</li>
                                            <li>'확인' 버튼을 누르면 해당 사항이 반영됩니다.</li>
                                            <li>계정의 목록이 너무 길 경우, 계정 검색에 이메일을 입력하면 입력한 이메일로 시작하는 계정만 보여지게 됩니다.</li>
                                        </ul>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </Drawer>
                </div>
            </React.Fragment>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (GroupList)));
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {Button, CircularProgress, Grid, Typography, withStyles} from "@material-ui/core";
import MaterialTable, {MTableToolbar} from "material-table";
import axios from "axios";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import GetAppIcon from "@material-ui/icons/GetApp";
import fileDownload from "js-file-download";


const styles = theme => ({
    addIconContainer: {
        color: '#3437CE',
        border: '1px solid rgba(52, 55, 206, 0.5)',
        fontSize: 14,
        fontWeight: 500,
        borderRadius: 20,
        padding: '7px 15px',
        minWidth: 64,
        lineHeight: 1.35,
        display: 'inline-flex',
        '& svg':{
            width:'18px',
            height:'18px',
            marginRight:'4px'
        }
    },
    toolbarContainer: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderTop: '1px solid #eee',
    },
    fileText: {
        flexGrow: 1,
        paddingRight: theme.spacing(2),
        textAlign: 'right',
    },
    filebox: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    fileSelection: {
        position: 'absolute',
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: 'hidden',
        clip: 'rect(0,0,0,0)',
        border: 0,
        borderRadius: 12,
    },
    fileButton: {
        marginLeft: theme.spacing(1),
        borderRadius: 25,
    },
    uploadButton: {
        borderRadius: 25,
    },
});

const compareUserNumber = (a, b) => {
    const aNum = a.userNumber.match(/\d+/);
    const bNum = b.userNumber.match(/\d+/);

    if (aNum && bNum) {
        return Number(aNum) - Number(bNum);
    } else {
        return a.userNumber.localeCompare(b.userNumber);
    }
}

class MemberTableComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            uploadFile: '',
            isDownloading: false,
            isUploading: false,
            userList : [],
            page : 0,
            pageSize: 10,
            totalCount : 0,
        };
        this.tableRef = React.createRef();
    }

    handleChangeRowPerPage = (event) => {
        this.setState({
            page: 0,
            pageSize: event,
        });

        if(this.tableRef.current) {
            this.tableRef.current.onQueryChange();
        }
    }

    handleClickDownload = () => {
        this.setState({isDownloading: true});

        axios.get('/api/v1/files/member', {responseType: 'blob'})
            .then(response => {
                const file = response.data;
                fileDownload(file, '출석부.xlsx');
                this.setState({isDownloading: false});
            })
            .catch(error => {
                this.props.enqueueSnackbar('엑셀 다운로드에 실패하였습니다.', {
                    variant: 'error',
                });
                this.setState({isDownloading: false});
            });
    }

    handleClickSelectFile = (event) => {
        const file = event.target.files[0];

        this.setState({uploadFile: file});
    }

    handleClickDelete = () => {
        console.log(this.state.userList);
        if(this.state.userList !== null && this.state.userList.length > 0 ) {
            if (window.confirm(this.state.userList.map(user => user.userName).reduce((a, b) => a + ", " + b) + " 학생을 삭제하시겠습니까?")) {
                this.state.userList.forEach(user => {
                    axios.delete(`/api/v1/users/members?user-id=${user.userId}`)
                        .then(response => {
                        })
                        .catch(error => {
                            this.props.enqueueSnackbar(user.userName + ' 삭제 실패하였습니다.', {
                                variant: 'error',
                            });
                        })
                });
                if(this.tableRef.current) {
                    setTimeout(() =>this.tableRef.current.onQueryChange(),1000);
                }
            }
        }
    }

    handleClickUpload = () => {
        this.setState({isUploading: true});

        const param = new FormData();
        param.append('file', this.state.uploadFile);

        axios.post('/api/v1/files/member', param)
            .then(response => {
                // console.log("upload", response);
                if(response.status === 200) {
                    const dbOnlyUserList = response.data;
                    console.log("upload", dbOnlyUserList);
                    if(dbOnlyUserList && dbOnlyUserList.length > 0) {
                        if (window.confirm(dbOnlyUserList.map(user => user.userName).reduce((a, b) => a + ", " + b) + " 학생을 삭제하시겠습니까?")) {
                            dbOnlyUserList.forEach(user => {
                                axios.delete(`/api/v1/users/members?user-id=${user.userId}`)
                                    .then(response => {
                                    })
                                    .catch(error => {
                                        this.props.enqueueSnackbar(user.userName + ' 삭제 실패하였습니다.', {
                                            variant: 'error',
                                        });
                                    })
                            });
                        }
                    }
                }

                if(this.tableRef.current) {
                    this.tableRef.current.onQueryChange();
                }
                this.setState({uploadFile: '', isUploading: false});
            })
            .catch(error => {
                // console.log("upload_error", error.response);
                if(error.response && error.response.status === 400) {
                    const duplicationUserList = error.response.data.message.split("User name duplication in excel file :")[1];
                    this.props.enqueueSnackbar('엑셀 업로드에 실패하였습니다.\n학생 이름 중복 : ' + duplicationUserList, {
                        variant: 'error',
                    });
                } else {
                    this.props.enqueueSnackbar('엑셀 업로드에 실패하였습니다.', {
                        variant: 'error',
                    });
                }

                this.setState({isUploading: false});
            })
    }

    render() {
        const {classes, userId, loadGroupList} = this.props;
        const {uploadFile, isDownloading, isUploading} = this.state;
        const isUploadable = uploadFile !== undefined && uploadFile != null && uploadFile !== '';

        return (
            <MaterialTable title="출석부"
                           tableRef={this.tableRef}
                           columns={[
                           //     { title: <Button onClick={() => {this.handleClickDelete()}}>삭제</Button>,
                           //     sorting: false,
                           //     headerStyle: {textAlign: 'left',width: 50},
                           //     cellStyle: {textAlign: 'left', width: 50},
                           //
                           // },
                               {
                                   title: '번호',
                                   field: 'userNumber',
                                   sorting: true,
                                   headerStyle: {textAlign: 'left', width: '5%'},
                                   cellStyle: {textAlign: 'left', width: '5%'},
                               },
                               {
                                   title: '이름',
                                   field: 'userName',
                                   sorting: true,
                                   headerStyle: {textAlign: 'left', width: '5%'},
                                   cellStyle: {textAlign: 'left', width: '5%'}
                               },
                               {
                                   title: '비밀번호',
                                   field: 'password',
                                   sorting: false,
                                   headerStyle: {textAlign: 'left', width: '5%'},
                                   cellStyle: {textAlign: 'left', width: '5%'}
                               },
                               {
                                   title: '그룹',
                                   field: 'groupNames',
                                   sorting: true,
                                   headerStyle: {textAlign: 'left', width: '30%'},
                                   cellStyle: {textAlign: 'left', width: '30%'},
                                   editable: 'never',
                               },
                               {
                                   title: '비고',
                                   field: 'comment',
                                   sorting: true,
                                   headerStyle: {textAlign: 'left', width: '30%'},
                                   cellStyle: {textAlign: 'left', width: '30%'}
                               }]}
                           icons={{
                               Add: (props => <div className={classes.addIconContainer}><AddRoundedIcon/>학생추가</div>),
                           }}
                           options={{
                               addRowPosition: 'first',
                               search: false,
                               actionsColumnIndex: -1,
                               pageSize: this.state.pageSize,
                               pageSizeOptions: [5, 10, 20, 30, 50, 70, 100],
                               headerStyle: {
                                   backgroundColor: '#fafafa',
                                   color: 'rgba(51, 51, 51, 0.56)',
                                   borderTop: '1px solid #eee',
                               },
                               selection : true,
                               // selectionProps: (rowData) => {
                               //     this.setState({userList : rowData})
                               // }
                           }}
                           onChangeRowsPerPage={this.handleChangeRowPerPage}
                           onSelectionChange={(rows) => this.setState({userList : rows})}
                           data={query =>
                               new Promise((resolve, reject) => {
                                   if(query.orderBy) {
                                       axios.get(`/api/v1/users/members?user-id=${userId}&paging=no`)
                                           .then(response => {
                                               const members = response.data;
                                               const totalCount = members.length;
                                               members.forEach(member => member.groupNames = member.userGroupList.map(data => data.groupName).reduce((a, b) => a ? a + ", " + b : b ? b : "", ""));
                                               if (query.orderBy.title === "그룹") {
                                                   members.sort((a, b) => {
                                                       if(query.orderDirection === "asc") {
                                                           return a.groupNames < b.groupNames ? -1 : a.groupNames > b.groupNames ? 1 : 0;
                                                       }else {
                                                           return a.groupNames > b.groupNames ? -1 : a.groupNames > b.groupNames ? 1 : 0;
                                                       }
                                                   });
                                               }else if(query.orderBy.title === "비고") {
                                                   members.sort((a, b) => {
                                                       if(query.orderDirection === "asc") {
                                                           return a.comment < b.comment ? -1 : a.comment > b.comment ? 1 : 0;
                                                       }else {
                                                           return a.comment > b.comment ? -1 : a.comment > b.comment ? 1 : 0;
                                                       }
                                                   });
                                               }else if(query.orderBy.title === "이름") {
                                                   members.sort((a, b) => {
                                                       if(query.orderDirection === "asc") {
                                                           return a.userName < b.userName ? -1 : a.userName > b.userName ? 1 : 0;
                                                       }else {
                                                           return a.userName > b.userName ? -1 : a.userName > b.userName ? 1 : 0;
                                                       }
                                                   });
                                               }else {
                                                   members.sort(compareUserNumber);
                                               }
                                               resolve({
                                                   data: members.splice(query.page * this.state.pageSize, this.state.pageSize),
                                                   page: query.page,
                                                   totalCount: totalCount,
                                               });
                                           })
                                           .catch(error => {
                                               reject(error);
                                           });
                                   }else {
                                       axios.get(`/api/v1/users/members?user-id=${userId}&paging=no`)
                                           .then(response => {
                                               const members = response.data.sort(compareUserNumber);
                                               const totalCount = members.length;
                                               members.forEach(member => member.groupNames = member.userGroupList.map(data => data.groupName).reduce((a, b) => a ? a + ", " + b : b ? b : "", ""));
                                               resolve({
                                                   data: members.splice(query.page * this.state.pageSize, this.state.pageSize),
                                                   page: query.page,
                                                   totalCount: totalCount,
                                               });
                                           })
                                           .catch(error => {
                                               reject(error);
                                           });
                                   }
                               })

                           }
                           editable={{
                               onRowAdd: (newMember =>
                                       new Promise((resolve, reject) => {
                                           if ((newMember.userNumber) && (newMember.userName) && (newMember.userNumber.length <= 50) && (newMember.userName.length <= 20)) {
                                               const param = [{
                                                   userNumber: newMember.userNumber ? newMember.userNumber : '',
                                                   userName: newMember.userName ? newMember.userName : '',
                                                   password: newMember.password ? newMember.password : '',
                                                   comment: newMember.comment ? newMember.comment : '',
                                               }];
                                               axios.post('/api/v1/users/members', param)
                                                   .then(response => {
                                                       resolve();
                                                   })
                                                   .catch(error => {
                                                       reject(error);
                                                   });
                                           } else {
                                               reject();
                                           }
                                       })
                               ),
                               onRowUpdate: ((newData, oldData) =>
                                       new Promise((resolve, reject) => {
                                           if ((newData.userNumber) && (newData.userName) && (newData.userNumber.length <= 50) && (newData.userName.length <= 20)) {
                                               const param = {
                                                   email: newData.email ? newData.email : '',
                                                   userNumber: newData.userNumber ? newData.userNumber : '',
                                                   userName: newData.userName ? newData.userName : '',
                                                   password: newData.password ? newData.password : '',
                                                   comment: newData.comment ? newData.comment : '',
                                               };
                                               axios.put('/api/v1/users/members', param)
                                                   .then(response => {
                                                       resolve();
                                                   })
                                                   .catch(error => {
                                                       reject(error);
                                                   });
                                           } else {
                                               reject();
                                           }
                                       })
                               ),
                               // onRowDelete: (oldData =>
                               //         new Promise((resolve, reject) => {
                               //             axios.delete(`/api/v1/users/members?user-id=${oldData.userId}`)
                               //                 .then(() => {
                               //                     loadGroupList(userId);
                               //                     resolve();
                               //                 })
                               //                 .catch(error => {
                               //                     reject(error);
                               //                 });
                               //         })
                               // ),
                           }}
                           localization={{
                               header: {
                                   actions: '',
                               },
                               body: {
                                   emptyDataSourceMessage: '아직 추가한 학생이 없습니다.',
                                   addTooltip: '추가',
                                   editTooltip: '편집',
                                   deleteTooltip: '삭제',
                                   editRow: {
                                       deleteText: '정말로 삭제 할까요?',
                                       cancelTooltip: '취소',
                                       saveTooltip: '저장',
                                   },
                               },
                               pagination: {
                                   labelRowsSelect: ' 개씩 보기',
                                   labelDisplayedRows: '총 {count}개 중 {from} - {to}',
                               },
                           }}
                           actions={[
                               {
                                   tooltip: '삭제',
                                   icon: 'delete',
                                   onClick: (evt, data) => {
                                       this.handleClickDelete()
                                   }
                               }
                           ]}
                           components={{
                               Toolbar: props => (
                                   <div>
                                       <MTableToolbar {...props} />
                                       <Grid container spacing={1} className={classes.toolbarContainer}>
                                           <Grid item xs={12} sm={4} style={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
                                               <Button variant="outlined" onClick={this.handleClickDownload}>{isDownloading ? <CircularProgress style={{width: 16, height: 16}} /> : <GetAppIcon />} 출석부 다운로드</Button>
                                           </Grid>
                                           <Grid item xs={12} sm={8} style={{display: 'flex', justifyContent: 'right', alignItems: 'center'}}>
                                               <Typography className={classes.fileText}>{uploadFile ? uploadFile.name : ''}</Typography>

                                               <div className={classes.filebox}>
                                                   <Button component="label" variant="outlined" htmlFor="file-upload" className={classes.fileButton}>파일 선택</Button>
                                                   <input id="file-upload" type="file" className={classes.fileSelection} onChange={this.handleClickSelectFile} />
                                               </div>

                                               <Button color="primary" variant="outlined" className={classes.uploadButton} disabled={!isUploadable || isUploading} onClick={() => this.handleClickUpload()}>
                                                   {isUploading ? <CircularProgress style={{width: 16, height: 16}} /> : '출석부 올리기' }
                                               </Button>
                                           </Grid>
                                       </Grid>
                                   </div>
                               ),
                           }}
            />
        );
    }
}

export default withSnackbar(withRouter(withStyles(styles) (MemberTableComponent)));
import React from "react";
import {inject, observer} from "mobx-react";
import Grid from "@material-ui/core/Grid";
import AttendStatusExpansionPanels from "./AttendStatusExpansionPanels";

@inject("dashboardStore")
@observer
class DashBoardChannelAttendStatus extends React.Component {
    render() {
        const { attendStatus} = this.props.dashboardStore;
        return (
            <Grid container
                  // direction="column"
                  // justify="center"
                  alignItems="flex-end"
            >
                <Grid item xs={5}>
                   <AttendStatusExpansionPanels
                       data={attendStatus}
                       pieChart={(chartDivName, pieChartData) => this.props.dashboardStore.makePieChart(chartDivName, pieChartData)}
                   />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={6}>
                    <div id="attend_chart_div" />
                </Grid>
            </Grid>
        );
    }
}

export default DashBoardChannelAttendStatus;
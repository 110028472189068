/*global OnTheLiveChat, _CONST_TYPES*/
import randomColor from "randomcolor";
import * as Type from "../../stores/BroadcastStore";
import * as store from "../../stores/AuthStore";
import * as BroadcastJanus from "./BroadcastJanus"

let props = null;
export const setChatProps = (chatProps) => {
    props = chatProps;
};

export const presentationAvailableFlag = {
    Y: "Y",
    N: "N"
};
export const questionAvailableFlag = {
    Y: "Y",
    N: "N",
};
export const msgType = {
    CHAT_MSG: 'CHAT_MSG',
    SYSTEM_MSG: 'SYSTEM_MSG',
    QUESTION_MSG: 'QUESTION_MSG',
    PRESENTATION_MSG: 'PRESENTATION_MSG'
};

export const lookAroundControl = {
    COMMAND: {
        UNDERSTAND: 'UNDERSTAND',
    },
    TARGET: {
        LOOK_AROUND: 'LOOK_AROUND'
    },
    VALUE: {
        YES: 'YES',
        NO: 'NO'
    }
}

export const chatInit = (channel) => {
    if(!props) {
        console.log("must be set props");
        return null;
    }

    const {userInfo, chatServerInfo} = props.broadcastStore;
    const {changeChatObj} = props.broadcastStore;
    const chatFgColor = randomColor();
    const chatBgColor = "#FAFAFA";
    let chat = props.broadcastStore.chatObj;

    if(!userInfo) {
        console.log("can't chat init not login user");
        return null;
    }

    const identity = {
        user: {
            userId: userInfo.userId,
            userName: userInfo.userName,
            email: userInfo.email,
            chatName: userInfo.userName,
            typeCode: (userInfo.userId === channel.userId) ? Type.chatUserType.OWNER : Type.chatUserType.MEMBER,
            chatIconUrl: userInfo.profileUrl ? userInfo.profileUrl : "",
            chatForegroundColor: chatFgColor,
            chatBackgroundColor: chatBgColor,
            presentationAvailableFlag: presentationAvailableFlag.Y,
            questionAvailableFlag: questionAvailableFlag.Y,
        },
        channel: {
            channelId: channel.channelId,
            channelName: channel.channelName
        },
        token: sessionStorage.getItem(store.SessionStorageTokenKey)
    };

    const chatEventListeners = {
        onConnected: function() {
        },
        onError: handleChatOnError,
        onGiftReceived: function(channel, sentUser, item) {
        },
        onMessageReceived: handleChatOnMsgRec,
        onNoticeReceived: handleChatOnNoticeReceived,
        onDirectMessageReceived: handleChatOnDirectMessageReceived,
        onAttendRequestReceived: handleChatOnAttendRequestReceived,
        onAttendResponseReceived: handleChatOnAttendResponseReceived,
        onBroadcastSwitchReceived: handleChatOnBroadcastSwitchReceived,
        onClosed: handleChatOnClosed,
        onJoined: handleChatOnJoined,
        onExited: handleChatOnExited,
        onBanned: handleChatOnBanned,
        onUnBanned: handleChatOnUnBanned,
        onReady: () => handleChatOnReady(channel.channelName),
        onDuplicated: handleChatOnDuplicated,
        onPresentationReceived: handleChatOnPresentationReceived,
        onQuestionReceived: handleChatOnQuestionReceived,
        onControlMessageReceived: handleControlMessageReceived,
        onQuizRequestReceived: handleQuizRequestReceived,
        onQuizResponseReceived: handleQuizResponseReceived,
    };

    if(!chat) {
        chat = new OnTheLiveChat();
    }

    chat.initChat({
        domain: {
            api: chatServerInfo.chatUrl,
            ws: chatServerInfo.chatUrl
        },
        enableLogo: true,
        enableDebug: false,
    });
    chat.initIdentity(identity);
    chat.initListener(chatEventListeners);
    chat.start();

    changeChatObj(chat);
};

const handleQuizRequestReceived = (channel, sentUser, quizQuestion) => {
    const {changeQuizQuestionObj, changeStudentQuizView} = props.broadcastQuizStore;
    changeQuizQuestionObj(quizQuestion);
    changeStudentQuizView(true);
};

const handleQuizResponseReceived = (channel, sentUser, quizAnswer) => {
};

const handleChatOnNoticeReceived = (channel, sentUser, message) => {
};

const handleChatOnDirectMessageReceived = (channel, sentUser, toUser, message) => {
};

const handleChatOnAttendRequestReceived = (channel, sentUser, message) => {
    //const {attendMsg} = props.broadcastStore;
    const {changeAttendMsg, addChatMsgList} = props.broadcastStore;
    changeAttendMsg(message);
    addChatMsgList(null, "출석 체크 인증 번호: " + message.authCode + " 출석 하기 버튼을 누른 후 인증번호를 입력해 주세요.", msgType.SYSTEM_MSG);
};

const handleChatOnAttendResponseReceived = (channel, sentUser, message) => {
    const {addChatMsgList, changeAttendDialogStatus, changeAttendMsg} = props.broadcastStore;
    addChatMsgList(null, sentUser.userName + " 출석 성공.", msgType.SYSTEM_MSG);
    changeAttendDialogStatus(false);
    changeAttendMsg(null);

};

const handleChatOnBroadcastSwitchReceived = (channel, sentUser, broadcastTypeCode) => {
    // const {changeMainVideoEl} = props.broadcastStore;
    // changeMainVideoEl(broadcastTypeCode);
};

const handleControlMessageReceived = (channel, sentUser, toUser, command) => {
    const {userInfo} = props.broadcastStore;
    channel = props.broadcastStore.channel;
    const {changeMainVideoEl, changeMainScreenView, changeSubScreenView, changeMainVideoElSound, changeSubVideoElSound, initJanus, changeWebRtcMainPublishType, changeStudentLookAroundOpen, unPublishLookAroundJanus, addLookAroundUnderstandList} = props.broadcastStore;
    const commandType = command.command;
    const commandTarget = command.target;
    const commandValue = command.value;

    if(commandType === _CONST_TYPES._CONTROL_COMMAND.SWITCH && commandTarget === _CONST_TYPES._CONTROL_TARGET.SCREEN) {
        changeMainVideoEl(commandValue);
    }

    if(commandType === _CONST_TYPES._CONTROL_COMMAND.TURNON && commandTarget === _CONST_TYPES._CONTROL_TARGET.SCREEN) {
        if(commandValue === Type.broadcastType.MAIN) {
            changeMainScreenView(true);
        } else {
            changeSubScreenView(true);
        }
    }

    if(commandType === _CONST_TYPES._CONTROL_COMMAND.TURNOFF && commandTarget === _CONST_TYPES._CONTROL_TARGET.SCREEN) {
        if(commandValue === Type.broadcastType.MAIN) {
            changeMainScreenView(false);
        } else {
            changeSubScreenView(false);
        }
    }

    if(commandType === _CONST_TYPES._CONTROL_COMMAND.TURNON && commandTarget === _CONST_TYPES._CONTROL_TARGET.SOUND) {
        if(commandValue === Type.broadcastType.MAIN) {
            changeMainVideoElSound(true);
        } else {
            changeSubVideoElSound(true);
        }
    }

    if(commandType === _CONST_TYPES._CONTROL_COMMAND.TURNOFF && commandTarget === _CONST_TYPES._CONTROL_TARGET.SOUND) {
        if(commandValue === Type.broadcastType.MAIN) {
            changeMainVideoElSound(false);
        } else {
            changeSubVideoElSound(false);
        }
    }

    if(commandType === _CONST_TYPES._CONTROL_COMMAND.JOINED && commandTarget === Type.publishMethodType.WEB_RTC) {
        if(commandValue) {
            let value = JSON.parse(commandValue);
            if(value.roomType && value.roomType === Type.janusRoomType.LOOK_AROUND && !value.closed && sentUser.userId === channel.userId && userInfo.userId !== channel.userId) {
                changeStudentLookAroundOpen(true);
                //initLookAroundJanusPublisher();
            }

            if(value.roomType && value.roomType === Type.janusRoomType.LOOK_AROUND && value.closed && sentUser.userId === channel.userId && userInfo.userId !== channel.userId) {
                changeStudentLookAroundOpen(false);
                unPublishLookAroundJanus();
            }

            if(value.roomType === Type.janusRoomType.NORMAL && value.publisherUserId.toString() !== userInfo.userId.toString()) {
                initJanus(value.joinType, value.handlerType, value.feed);
            }
        }
    }

    if(commandType === _CONST_TYPES._CONTROL_COMMAND.SWITCH_BROADCAST && commandTarget === _CONST_TYPES._CONTROL_TARGET.MAIN) {
        if(commandValue) {
            changeWebRtcMainPublishType(commandValue);
        }
    }

    if(commandType === lookAroundControl.COMMAND.UNDERSTAND && commandTarget === lookAroundControl.TARGET.LOOK_AROUND) {
        const lookAroundCommandValue = JSON.parse(commandValue);
        if(lookAroundCommandValue.value === lookAroundControl.VALUE.YES) {
            addLookAroundUnderstandList(lookAroundCommandValue.userId, true);
        }

        if(lookAroundCommandValue.value === lookAroundControl.VALUE.NO) {
            addLookAroundUnderstandList(lookAroundCommandValue.userId, false);
        }
    }
    //

    //changeWebRtcMainPublishType

    // if(commandType === _CONST_TYPES._CONTROL_COMMAND.TURNOFF && commandTarget === _CONST_TYPES._CONTROL_TARGET.BROADCAST && userInfo && userInfo.userId === sentUser.userId) {
    //     props.history.push("/channel/list");
    // }
};

const handleChatOnReady = (channelName) => {
    const {chatObj} = props.broadcastStore;
    const {addChatMsgList, changeChatUserList} = props.broadcastStore;
    addChatMsgList(null ,"["+channelName+"]", msgType.SYSTEM_MSG);
    chatObj.getChannelUsers((data) => {
        changeChatUserList(data);
    });
    // chatObj.close();
};

const handleChatOnUnBanned = (channel, sentUser) => {
    const {addChatMsgList} = props.broadcastStore;
    addChatMsgList(null, sentUser.userName + " Unbanned", msgType.SYSTEM_MSG);
};

const handleChatOnBanned = (channel, sentUser) => {
    const {addChatMsgList} = props.broadcastStore;
    addChatMsgList(null, sentUser.userName + " banned", msgType.SYSTEM_MSG);
};

const handleChatOnExited = (channel, sentUser) => {
    const {chatObj} = props.broadcastStore;
    const {addChatMsgList, changeChatUserList} = props.broadcastStore;
    addChatMsgList(null, sentUser.userName + " 님이 퇴장하셨습니다.", msgType.SYSTEM_MSG);

    chatObj.getChannelUsers((data) => {
        changeChatUserList(data);
    });
};

const handleChatOnJoined = (channel, sentUser, message) => {
    const {chatObj, userInfo, pluginHandle, janusSubFeed} = props.broadcastStore;
    const {addChatMsgList, changeChatUserList, changeChatReady, changeChatPresentationObj, addChatQuestionObj, changeMainScreenView, changeSubScreenView, changeMainVideoEl, changeMainVideoElSound, changeSubVideoElSound, initJanus, changeWebRtcMainPublishType}= props.broadcastStore;
    // const isWebRtc = publishMethod === publishMethodType.WEB_RTC;
    // const isWebRtc = mainPublishType !== Type.publishType.RTMP;
    changeChatReady(true);
    addChatMsgList(null, sentUser.userName + " 님이 입장하였습니다.", msgType.SYSTEM_MSG);

    chatObj.getChannelUsers((data) => {
        changeChatUserList(data);
    });

    if(userInfo && sentUser.userId === userInfo.userId) {
        if(channel.userId !== userInfo.userId) {
            changeWebRtcMainPublishType(message.mainBroadcastTypeCode);
            if(!pluginHandle && message.mainBroadcastTypeCode !== Type.publishType.RTMP) {
                initJanus(BroadcastJanus.janusJoinPType.subscriber, BroadcastJanus.janusPluginHandlerType.main, channel.channelId);
            }
        }

        if(message && message.screenMode) {
            changeMainVideoEl(message.screenMode);
        }

        if(message && message.mainSoundStatusCode && message.mainSoundStatusCode === Type.joinMsgSoundType.ON) {
            changeMainVideoElSound(true);
        } else {
            changeMainVideoElSound(false);
        }

        if(message && message.subSoundStatusCode && message.subSoundStatusCode === Type.joinMsgSoundType.ON) {
            changeSubVideoElSound(true);
        } else {
            changeSubVideoElSound(false);
        }

        if(sentUser.userId === channel.userId) {
            chatObj.sendTurnOffScreen(Type.broadcastType.MAIN);
            chatObj.sendTurnOffScreen(Type.broadcastType.SUB);
            handleDoResetPresentation();
            return null;
        }

        if(message && message.mainScreenStatusCode && message.mainScreenStatusCode === Type.joinMsgScreenType.ON) {
            changeMainScreenView(true);
        } else {
            changeMainScreenView(false);
        }

        if(message && message.subScreenStatusCode && message.subScreenStatusCode === Type.joinMsgScreenType.ON) {
            changeSubScreenView(true);
        } else {
            changeSubScreenView(false);
        }
    }

    if(userInfo && sentUser.userId !== userInfo.userId && channel.userId === userInfo.userId) {
        if(janusSubFeed) {
            handleWebRtcPublisherRoomJoined(JSON.stringify({
                roomType: Type.janusRoomType.NORMAL,
                joinType: BroadcastJanus.janusJoinPType.subscriber,
                handlerType: BroadcastJanus.janusPluginHandlerType.sub,
                feed: janusSubFeed,
                publisherUserId: janusSubFeed,
            }));
        }
    }

    if(message && message.latestChannelPresentation && message.latestChannelPresentation.typeCode === _CONST_TYPES._OBJECT.PRESENTATION) {
        changeChatPresentationObj(message.latestChannelPresentation);
    }

    if(message && message.latestChannelPresentation && message.latestChannelPresentation.typeCode === _CONST_TYPES._OBJECT.QUESTION) {
        addChatQuestionObj(message.latestChannelPresentation);
    }
};

const handleChatOnClosed = () => {
    // const {addChatMsgList, changeChatObj} = props.broadcastStore;
    //addChatMsgList(null, "onClosed");
    // changeChatObj(null);
};

const handleChatOnMsgRec = (channel, sentUser, message) => {
    const {addChatMsgList, changeChatMsg} = props.broadcastStore;
    const { loginUser } = props.authStore;

    addChatMsgList(sentUser, message, msgType.CHAT_MSG);
    if(sentUser.userId === loginUser.userId) {
        changeChatMsg("");
    }
    // displayMessage(sentUser, message);
};

const handleChatOnDuplicated = (channel, sentUser) => {
    console.log('handleChatOnDuplicated');
    props.broadcastStore.changeDuplicateUserDialogOpen(true);
    // const {addChatMsgList, changeChatReady} = props.broadcastStore;
    // addChatMsgList(null, "다른 곳에서 접속하여 연결이 끊어집니다.");
    // changeChatReady(false);
};

const handleChatOnError = (error) => {
    const {chatObj} = props.broadcastStore;
    const {addChatMsgList, changeAttendDialogMsgError, changeChatReady, changeNetworkErrorDialogOpen, changeAlreadyUserDialogOpen} = props.broadcastStore;
    console.log(error);

    if (error == null) return;
    const errorCode = error.code;
    // const errorMessage = error.message;

    if (errorCode == null) return;

    if(errorCode === "FAILED_CONNECT_SERVER") {
        changeNetworkErrorDialogOpen(true);
        return null;
    }

    if(errorCode === "MISMATCH_ATTEND_AUTH_CODE") {
        changeAttendDialogMsgError(true);
        return null;
    }

    if(errorCode === "NOT_EXIST_CHANNEL") {
        changeChatReady(false);
        chatObj.close();
    }

    if(errorCode === "ALREADY_JOINED_USER") {
        addChatMsgList(null, "다른 곳에서 이미 접속했습니다.", msgType.SYSTEM_MSG);
        changeAlreadyUserDialogOpen(true);
        changeChatReady(true);
        chatObj.close();
    }

    if(errorCode === "NOT_READY_FOR_CHAT") {
        changeNetworkErrorDialogOpen(true);
        return null;
    }

    //addChatMsgList(null, "ERROR:"+errorCode);

    if (errorCode === 'INVALID_ACCESS_TOKEN') {
        // invalid token
    } else if (errorCode === 'EXPIRED_ACCESS_TOKEN') {
        // expired access token
    } else if (errorCode === 'BANNED_USER') {
        // banned user
    } else if(errorCode === 'INVALID_CHANNEL_PRESENTATION_STATUS') {
        if(chatObj) {
            chatObj.resetPresentation("handleDoResetPresentation", () => {
            });
        }
    }
};

export const sendPresentationStart = () => {
    const { chatObj, chatPresentationObj, userInfo, webRtcSubPublishType, initJanus } = props.broadcastStore;
    const isWebRTC = webRtcSubPublishType !== Type.publishType.RTMP;
    if(isWebRTC &&  userInfo.userId === chatPresentationObj.userId) {
        initJanus(BroadcastJanus.janusJoinPType.publisher, BroadcastJanus.janusPluginHandlerType.sub, userInfo.userId);
        chatObj.sendPresentationState(chatPresentationObj, _CONST_TYPES._PRESENTATION_STATUS.START, '', responseBody => {});
    }
}

export const sendQuestionRequest = () => {
    const { chatObj, userId } = props.broadcastStore;
    chatObj.sendPresentationRequest(_CONST_TYPES._OBJECT.QUESTION, userId, '', responseBody => {});
}

export const sendQuestionStart = () => {
    const { chatQuestionObjList, userId, chatObj, userInfo, webRtcSubPublishType, initJanus, changeQuestionReady } = props.broadcastStore;
    const question = chatQuestionObjList.find(obj => obj.userId === userId);
    const isWebRTC = webRtcSubPublishType !== Type.publishType.RTMP;
    changeQuestionReady(false);
    if(isWebRTC && userInfo.userId === question.userId) {
        initJanus(BroadcastJanus.janusJoinPType.publisher, BroadcastJanus.janusPluginHandlerType.sub, question.userId);
        chatObj.sendPresentationState(question, _CONST_TYPES._PRESENTATION_STATUS.START, '', responseBody => {});
    }
}

const handleChatOnPresentationReceived = (channel, sentUser, toUser, presentation) => {
    const { changeChatPresentationObj, addChatMsgList, changeSubMemberPublishDialogOpen, changeDisablePresentation } = props.broadcastStore;
    const { chatObj, userInfo, webRtcSubPublishType } = props.broadcastStore;
    const memberPresentationButton = document.getElementById("memberPresentationButton");
    const memberPresentationButtonText = document.getElementById("memberPresentationButtonText");
    const isWebRTC = webRtcSubPublishType !== Type.publishType.RTMP;
    changeChatPresentationObj(presentation);
    switch(presentation.statusCode) {
        case _CONST_TYPES._PRESENTATION_STATUS.REQUEST:
            const handler = addPresentationMsg[presentation.statusCode];
            handler ? handler(addChatMsgList, sentUser, toUser) : console.log("not presentation status code: " + presentation.statusCode);
            // if(isWebRTC && userInfo.userId !== channel.userId && subPluginHandle) BroadcastJanus.doUnPublish(subPluginHandle);
            break;
        case _CONST_TYPES._PRESENTATION_STATUS.ALLOW:
            break;
        case _CONST_TYPES._PRESENTATION_STATUS.DENY:
            break;
        case _CONST_TYPES._PRESENTATION_STATUS.READY:
            if (isWebRTC && userInfo.userId === presentation.userId) {
                memberPresentationButton.style.opacity = '1.0';
                memberPresentationButtonText.style.color = '#000000';
                changeDisablePresentation(false);
                memberPresentationButton.onclick = () => changeSubMemberPublishDialogOpen(true);
                changeSubMemberPublishDialogOpen(true);
            }
            break;
        case _CONST_TYPES._PRESENTATION_STATUS.START:
            if(isWebRTC && userInfo.userId === presentation.userId) {
                memberPresentationButton.onclick = () => {
                    // if(subPluginHandle) BroadcastJanus.doUnPublish(subPluginHandle);
                    chatObj.sendPresentationState(presentation, _CONST_TYPES._PRESENTATION_STATUS.END, '', responseBody => {});
                }
                memberPresentationButtonText.innerText = "발표 종료";
                memberPresentationButton.style.backgroundColor = '#ef5350';
            }
            break;
        case _CONST_TYPES._PRESENTATION_STATUS.RESET:
        case _CONST_TYPES._PRESENTATION_STATUS.END:
            if(isWebRTC && userInfo.userId !== channel.userId) {
                // if(subPluginHandle) BroadcastJanus.doUnPublish(subPluginHandle);
                memberPresentationButton.onclick = () => sendPresentationStart();
                memberPresentationButtonText.innerText = "발표 하기";
                changeDisablePresentation(true);
                memberPresentationButton.style.opacity = '0.37';
                memberPresentationButtonText.style.color = '#333';
                memberPresentationButton.style.backgroundColor = '#fff';
            }
            break;
        default:
            break;
    }
};

const handleChatOnQuestionReceived = (channel, sentUser, toUser, question) => {
    const {addChatQuestionObj, addChatMsgList, changeSubMemberPublishDialogOpen, changeQuestionReady} = props.broadcastStore;
    const { chatObj, userInfo, webRtcSubPublishType } = props.broadcastStore;
    const memberQuestionButton = document.getElementById("memberQuestionButton");
    const memberQuestionButtonText = document.getElementById("memberQuestionButtonText");
    const isWebRTC = webRtcSubPublishType !== Type.publishType.RTMP;
    addChatQuestionObj(question);
    switch(question.statusCode) {
        case _CONST_TYPES._PRESENTATION_STATUS.REQUEST:
            const handler = addQuestionMsg[question.statusCode];
            handler ? handler(addChatMsgList, sentUser, toUser) : console.log('not question status code: ' + question.statusCode);
            break;
        case _CONST_TYPES._PRESENTATION_STATUS.ALLOW:
            // if(isWebRTC && subPluginHandle) BroadcastJanus.doUnPublish(subPluginHandle);
            break;
        case _CONST_TYPES._PRESENTATION_STATUS.DENY:
            break;
        case _CONST_TYPES._PRESENTATION_STATUS.READY:
            if(isWebRTC && userInfo.userId === question.userId) {
                changeQuestionReady(true);
                changeSubMemberPublishDialogOpen(true);
            }
            break;
        case _CONST_TYPES._PRESENTATION_STATUS.START:
            if(isWebRTC && userInfo.userId === question.userId) {
                memberQuestionButton.onclick = () => {
                    // if(subPluginHandle) BroadcastJanus.doUnPublish(subPluginHandle);
                    chatObj.sendPresentationState(question, _CONST_TYPES._PRESENTATION_STATUS.END, '', responseBody => {});
                };
                memberQuestionButtonText.innerText = "질문 종료";
                memberQuestionButton.style.backgroundColor = '#ef5350';
            }
            break;
        case _CONST_TYPES._PRESENTATION_STATUS.RESET:
        case _CONST_TYPES._PRESENTATION_STATUS.END:
            if(isWebRTC && userInfo.userId !== channel.userId) {
                // if(subPluginHandle) BroadcastJanus.doUnPublish(subPluginHandle);
                memberQuestionButton.onclick = () => sendQuestionRequest();
                memberQuestionButtonText.innerText = "질문 하기";
                memberQuestionButton.style.backgroundColor = '#fff';
            }
            break;
        default:
            break;
    }
};

const addQuestionMsg = {
    [_CONST_TYPES._PRESENTATION_STATUS.RESET]: (addChatMsgList) => {
        addChatMsgList(null, "질문 상태가 초기화 되었습니다.", msgType.QUESTION_MSG);
    },
    [_CONST_TYPES._PRESENTATION_STATUS.REQUEST]: (addChatMsgList, sentUser, toUser) => {
        addChatMsgList(null, `[${sentUser.userName}] 질문을 요청했습니다.`, msgType.QUESTION_MSG);
    },
    [_CONST_TYPES._PRESENTATION_STATUS.ALLOW]: (addChatMsgList, sentUser, toUser) => {
        addChatMsgList(null, `[${toUser.userName}] 질문을 수락했습니다.`, msgType.QUESTION_MSG);
    },
    [_CONST_TYPES._PRESENTATION_STATUS.READY]: () => {
    },
    [_CONST_TYPES._PRESENTATION_STATUS.START]: (addChatMsgList, sentUser, toUser) => {
        addChatMsgList(null, `[${sentUser.userName}] 이 질문을 시작하였습니다.`, msgType.QUESTION_MSG);
    },
    [_CONST_TYPES._PRESENTATION_STATUS.END]: (addChatMsgList, sentUser, toUser) => {
        addChatMsgList(null, `[${sentUser.userName}] 이 질문을 종료하였습니다.`, msgType.QUESTION_MSG);
    }
};

const addPresentationMsg = {
    [_CONST_TYPES._PRESENTATION_STATUS.RESET]: (addChatMsgList) => {
        addChatMsgList(null, "발표 상태가 초기화 되었습니다.", msgType.PRESENTATION_MSG);
    },
    [_CONST_TYPES._PRESENTATION_STATUS.REQUEST]: (addChatMsgList, sentUser, toUser) => {
        addChatMsgList(null, `[${toUser.userName}] 에게 발표 요청을 보냈습니다.`, msgType.PRESENTATION_MSG);
    },
    [_CONST_TYPES._PRESENTATION_STATUS.READY]: () => {
    },
    [_CONST_TYPES._PRESENTATION_STATUS.START]: (addChatMsgList, sentUser, toUser) => {
        addChatMsgList(null, `[${sentUser.userName}] 이 발표를 시작하였습니다.`, msgType.PRESENTATION_MSG);
    },
    [_CONST_TYPES._PRESENTATION_STATUS.END]: (addChatMsgList, sentUser, toUser) => {
        addChatMsgList(null, `[${sentUser.userName}] 이 발표를 종료하였습니다.`, msgType.PRESENTATION_MSG);
    }
};

export const handleKeyUpChatMsg = (e) => {
    const {chatObj, chatMsg} = props.broadcastStore;

    if(e.keyCode === 13 && chatObj && chatMsg && typeof chatMsg === "string" && chatMsg.trim()) {
        chatObj.sendMessage(chatMsg);
    }
};

export const handleChatMsgSend = () => {
    const {chatObj, chatMsg} = props.broadcastStore;
    if(chatObj && chatMsg && typeof chatMsg === "string" && chatMsg.trim()) {
        chatObj.sendMessage(chatMsg);
    }
};

export const handleChatAttendance = () => {
    const {chatObj} = props.broadcastStore;
    const {addChatMsgList} = props.broadcastStore;
    chatObj.sendAttendRequest((data) => {
        addChatMsgList(null, "출석 요청을 보냈습니다.", msgType.SYSTEM_MSG);
    });
};

export const handleChatAttendanceLate = () => {
    const {chatObj} = props.broadcastStore;
    const {addChatMsgList} = props.broadcastStore;
    chatObj.sendAttendRequestLate((data) => {
        addChatMsgList(null, "미출석자에게 출석 요청을 보냈습니다.", msgType.SYSTEM_MSG);
    });
};

export const handleChatBroadcastSwitch = () => {
    const {chatObj, mainVideoEl} = props.broadcastStore;

    const sendType = (mainVideoEl === Type.broadcastVideoEl.MAIN) ? Type.broadcastType.SUB : Type.broadcastType.MAIN;
    chatObj.sendBroadcastSwitch(sendType);
};

export const handleDoAttend = () => {
    const { chatObj, attendMsg, attendDialogMsg } = props.broadcastStore;
    if(chatObj && attendMsg) {
        chatObj.sendAttendResponse(attendMsg.attendAuthId, attendDialogMsg, () => {
        });
    }
};

export const handleDoAnnounce = (userId) => {
    const {chatObj, changeWebRtcSubPublishType, changeSelectedSubPublishType} = props.broadcastStore;

    // if(subPluginHandle) {
    //     BroadcastJanus.doUnPublish(subPluginHandle);
    // }

    changeWebRtcSubPublishType(null);
    changeSelectedSubPublishType(null);

    chatObj.requestPresentation(userId, "test", () => {
    });
    return null;
};

export const handleAllowQuestion = (userId) => {
    const {chatObj, chatQuestionObjList, changeWebRtcSubPublishType, changeSelectedSubPublishType} = props.broadcastStore;

    changeWebRtcSubPublishType(null);
    changeSelectedSubPublishType(null);

    let chatQuestionObj = null;
    for(let i = 0; i < chatQuestionObjList.length; i++) {
        if(chatQuestionObjList[i].userId === userId) {
            chatQuestionObj = chatQuestionObjList[i];
            break;
        }
    }

    if(!chatQuestionObj) {
        return null;
    }

    chatObj.allowQuestion(chatQuestionObj, "", () => {
    });
    return null;
}

export const handleDoResetPresentation = () => {
    const {chatObj} = props.broadcastStore;
    chatObj.resetPresentation("handleDoResetPresentation", () => {
    });
}

export const handleDoEndPresentation = () => {
    const {chatObj, chatPresentationObj} = props.broadcastStore;
    chatObj.endPresentation(chatPresentationObj, "", () => {
    });
};

export const handleDoQuestion = () => {
    const {chatObj} = props.broadcastStore;
    chatObj.requestQuestion("test", () => {});
};

export const handleDoStopQuestion = (qObj) => {
    const {chatObj} = props.broadcastStore;
    chatObj.endQuestion(qObj, "stop question", () => {
    });
}

export const handleMainScreenView = () => {
    const {chatObj, mainScreenView} = props.broadcastStore;
    if(mainScreenView) {
        chatObj.sendTurnOffScreen(Type.broadcastType.MAIN);
    } else {
        chatObj.sendTurnOnScreen(Type.broadcastType.MAIN);
    }
};

export const handleSubScreenView = () => {
    const {chatObj, subScreenView} = props.broadcastStore;
    if(subScreenView) {
        chatObj.sendTurnOffScreen(Type.broadcastType.SUB);
    } else {
        chatObj.sendTurnOnScreen(Type.broadcastType.SUB);
    }
};

export const handleMainSound = (value = null) => {
    //return null;
    const {chatObj, mainVideoElSound, channel, userInfo} = props.broadcastStore;

    if(!(channel && userInfo && channel.userId === userInfo.userId)) {
        return null;
    }

    if(value !== null) {
        if(value) {
            chatObj.sendControl(_CONST_TYPES._CONTROL_COMMAND.TURNON, _CONST_TYPES._CONTROL_TARGET.SOUND, Type.broadcastType.MAIN);
        } else {
            chatObj.sendControl(_CONST_TYPES._CONTROL_COMMAND.TURNOFF, _CONST_TYPES._CONTROL_TARGET.SOUND, Type.broadcastType.MAIN);
        }

        return null;
    }

    if(mainVideoElSound) {
        chatObj.sendControl(_CONST_TYPES._CONTROL_COMMAND.TURNOFF, _CONST_TYPES._CONTROL_TARGET.SOUND, Type.broadcastType.MAIN);
    } else {
        chatObj.sendControl(_CONST_TYPES._CONTROL_COMMAND.TURNON, _CONST_TYPES._CONTROL_TARGET.SOUND, Type.broadcastType.MAIN);
    }
};

export const handleSubSound = (value = null) => {
    //return null;
    const {chatObj, subVideoElSound, channel, userInfo} = props.broadcastStore;

    if(!(channel && userInfo && channel.userId === userInfo.userId)) {
        return null;
    }

    if(value !== null) {
        if(value) {
            chatObj.sendControl(_CONST_TYPES._CONTROL_COMMAND.TURNON, _CONST_TYPES._CONTROL_TARGET.SOUND, Type.broadcastType.SUB)
        } else {
            chatObj.sendControl(_CONST_TYPES._CONTROL_COMMAND.TURNOFF, _CONST_TYPES._CONTROL_TARGET.SOUND, Type.broadcastType.SUB);
        }

        return null;
    }

    if(subVideoElSound) {
        chatObj.sendControl(_CONST_TYPES._CONTROL_COMMAND.TURNOFF, _CONST_TYPES._CONTROL_TARGET.SOUND, Type.broadcastType.SUB);
    } else {
        chatObj.sendControl(_CONST_TYPES._CONTROL_COMMAND.TURNON, _CONST_TYPES._CONTROL_TARGET.SOUND, Type.broadcastType.SUB);
    }
};

export const handleBroadcastBlock = () => {
    const {chatObj} = props.broadcastStore;
    if(chatObj) {
        chatObj.sendControl(_CONST_TYPES._CONTROL_COMMAND.TURNOFF, _CONST_TYPES._CONTROL_TARGET.BROADCAST, "ksj");
    }
};

export const handleWebRtcPublisherRoomJoined = (obj) => {
    const {chatObj} = props.broadcastStore;
    if(chatObj) {
        chatObj.sendControl(_CONST_TYPES._CONTROL_COMMAND.JOINED, Type.publishMethodType.WEB_RTC, obj);
    }
}

export const handleWebRtcSwitch = (isWebRtc) => {
    const {chatObj} = props.broadcastStore;
    const publishType = isWebRtc ? Type.publishMethodType.WEB_RTC : Type.publishMethodType.RTMP;
    //const publishType = isWebRtc ? "WEBRTC" : Type.publishType.RTMP;
    if(chatObj) {
        chatObj.sendControl(_CONST_TYPES._CONTROL_COMMAND.SWITCH_BROADCAST, _CONST_TYPES._CONTROL_TARGET.MAIN, publishType);
    }
}

export const handleSendQuiz = () => {
    const {chatObj, channel} = props.broadcastStore;
    const {publishedQuestion} = props.broadcastQuizStore;

    const getQuizObjOptions = () => {
        return publishedQuestion.channelQuizOptions.map((item) => {
            return {
                channelQuizOptionId: item.channelQuizOptionId,
                quizQuestionId: item.quizQuestionId,
                quizOptionNumber: item.quizOptionNumber,
                quizOptionContents: item.quizOptionContents,
            }
        });
    };

    const quizObj = {
        quizQuestionId: publishedQuestion.quizQuestionId,
        channelId: channel.channelId,
        quiz: {
            quizId: publishedQuestion.channelQuizId,
            userId: publishedQuestion.userId,
            quizTitle: publishedQuestion.quizTitle,
            quizQuestion: publishedQuestion.quizQuestion,
            type: publishedQuestion.type,
            quizImages: [...publishedQuestion.channelQuizImages],
            options: getQuizObjOptions(),
        }
    }

    if(chatObj) {
        chatObj.requestQuiz(quizObj, () => {
            const {changeIsSendingQuiz, initData} = props.broadcastQuizStore;
            changeIsSendingQuiz(false);
            initData();
        });
    }
};

export const sendControlLookAroundUnderstand = (value, userId) => {
    const {chatObj} = props.broadcastStore;
    if(chatObj) {
        const commandValue = {
            value: value ? lookAroundControl.VALUE.YES : lookAroundControl.VALUE.NO,
            userId: userId
        }
        chatObj.sendControl(lookAroundControl.COMMAND.UNDERSTAND, lookAroundControl.TARGET.LOOK_AROUND, JSON.stringify(commandValue));
    }
}
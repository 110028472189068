import React from "react";
import {NavLink, withRouter} from "react-router-dom";
import {getDeviceBrowserType} from "../common/Params";

import {
    AppBar,
    Avatar,
    Badge,
    Button,
    Card,
    CardContent,
    CardHeader,
    createMuiTheme,
    Fab,
    Grid,
    Hidden,
    IconButton,
    Menu,
    MenuItem,
    Popover,
    Toolbar,
    Typography,
    withStyles
} from "@material-ui/core";
import Btn from "./CustomButtons/Button";
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleSharpIcon from "@material-ui/icons/AccountCircleSharp";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {inject, observer} from "mobx-react";
import Tooltip from "@material-ui/core/Tooltip";
import HelpIcon from "@material-ui/icons/Help";



const theme = createMuiTheme();

const logoWidth = 120;


const useStyles = {
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        borderBottom:'1px solid #eee',
        // width: `calc(100%-${drawerWidth}px`,
        // marginRight: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${theme.drawerWidth}px)`,
            // width: `calc(100% - ${theme.drawerWidth + drawerWidth}px)`,
            // marginRight: theme.drawerWidth,
        },
        [theme.breakpoints.down('xs')]: {
        display:'flex',
        borderBottom:'1px solid #ddd'
        },
    },
    appBarWrap:{
        [theme.breakpoints.down('xs')]: {
            // display:'grid',
            // gridAutoColumns: '33.33%',
            '& > button, & > div':{
                display:'flex',
                // justifyContent:'center',
            }
        },
        [theme.breakpoints.down("xs")]: {
            minHeight: '60px'
        }
    },
    toolBar: {
        [theme.breakpoints.down("xs")]: {
            minHeight: '60px'
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.down('xs')]: {
            // gridColumn:1,
            order:3,
            justifyContent:'flex-end',
            marginRight:'12px',
            marginLeft:0,
            padding:0
        },
    },
    menuTitle:{
        // flexWarp:'nowrap !important',
        // width:'220px'
    },
    title: {
        marginLeft: (theme.sideMenuWidth - logoWidth) / 2,
        //paddingLeft: theme.spacing(3),
        flexGrow: 1,
        '& > div':{
            flexWrap: 'nowrap',
        },
        [theme.breakpoints.down('xs')]: {
            // gridColumn:2,
            order:2,
            paddingLeft: 0,
            // display:'none',
            '& > div':{
                justifyContent:'center',
            },
            '& img':{
                width:'85%'
            }
        },
    },
    iconMenu:{
        [theme.breakpoints.down('xs')]: {
            // gridColumn:3,
            order:1,
            justifyContent:'flex-start',
            width:'auto',
        },
    },
    unreadText: {
        color: '#ffffff',
        width: '100%',
        textAlign: 'center',
    },

    titleBtn: {
        fontSize: '16px',
        fontFamily: 'NanumSquareR',
        '&:hover, &:focus, &:active':{
            background:'none',
        },
        '& span:nth-child(2)':{
            display:'none',
        }
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',

    },
    loginBtn: {
        borderRadius: 50,
        fontWeight: 400,
        fontSize: 13,
        minWidth: 86,
        height: 32,
        boxShadow:'none',
        '&:hover':{
            boxShadow:'none',
            backgroundColor:'#d5d5e7'
        }
    },
    logOut:{
        fontWeight: 400,
        fontSize: 14,
        borderRadius: 50,
    },
    signUpBtn: {
        backgroundColor: '#3437CE',
        color: '#ffffff',
        borderRadius: 50,
        fontWeight: 400,
        fontSize: 13,
        minWidth: 86,
        height: 34,
        boxShadow:'none',
        "&:hover":{
            backgroundColor: '#3437CE',
        }
    },
    boardBadgeIcon: {
        color: '#e7e7e7',
        fontSize: 30,
    },
    myPage:{
        boxShadow: 'none',
        borderRadius:'50px',
        fontWeight:'400',
        fontSize: '13px',
    },
    media:{
        fontSize:300,
    },
    detailInform : {
        background: "#fafafa",
        minWidth: 250,
    },
    modifyButton: {
        borderRadius: '15px',
        boxShadow: 'none',
        width : 60,
        height: 30,
        lineHeight:1,
        fontSize : 14,
    },
    mobileMenuItem: {
        minWidth:'80px',
        justifyContent:'center',
        fontWeight:'600',
    },
    helpIcon:{
        zIndex: theme.zIndex.drawer + 2,
        paddingLeft: '16px',
        boxShadow: 'none',
        background:'none',
        width:'36px',
        fontSize: 15,
        '&:hover, &:focus, &:active':{
            background:'none',
            boxShadow:'none',
        },
        '& svg':{
            width:26,
            height:26,
            marginRight:4,
            // boxShadow:'0 2px 3px #c1c1c1',
            boxShadow:'none',
            borderRadius:'50px'
        },
        '& span':{
            color:'rgba(51,51,51,0.56)'
        }
    },
};

@inject("mainMenuStore", "authStore", "userStore", "boardStore", "signUpStore")
@observer
class TopBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            memberLoginHover: false,
            userLoginHover: false,
        };
    }

    componentDidMount() {
        if(this.props.userId && this.props.loginType) {
            this.props.boardStore.loadUnreadBoardCount(this.props.userId, this.props.loginType);
        }
        this.props.signUpStore.getServerMode();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.userId && this.props.loginType) {
            this.props.boardStore.loadUnreadBoardCount(this.props.userId, this.props.loginType);
        }
    }

    handleMenu = (event) => {
        const name = event.currentTarget.name;
        const anchorEl = event.currentTarget;
        this.props.mainMenuStore.changeMenuData(name, "anchorEl", anchorEl);
    };

    handleMenuClose = (target) => {
        this.props.mainMenuStore.changeMenuData(target, "anchorEl", null);
    };

    handleChannelCreateBtnClick = (link) => {
        this.handleMenuClose("channelMenu");
        this.handlePushLink(link);
    };

    handleClickMyPage = (event) => {
        let anchorEl = null;
        if(!Boolean(this.props.mainMenuStore.menuData.myPage.anchorEl)){
            anchorEl = event.currentTarget;
        }
        const name = event.currentTarget.name;
        this.props.mainMenuStore.changeMenuData(name, "anchorEl", anchorEl);
        this.props.userStore.getUserData();
    };

    handleCloseMypagePopover = (e) =>{
        this.props.mainMenuStore.changeMenuData("myPage", "anchorEl", null);
    };

    handleClickInfoModify = () =>{
        this.props.history.push('/mypage');
        this.handleCloseMypagePopover();
    };

    handleClickMemberLogin = () => {
        this.props.authStore.changeLoginType('MEMBER');
        this.props.history.push('/channel/list');
    };

    handleClickUserLogin = () => {
        this.props.authStore.changeLoginType('USER');
        this.props.history.push('/channel/list');
    };

    handleToggleMenu = (event) => {
        this.props.setMobileOpen(!this.props.mobileOpen);
        this.props.mainMenuStore.changeMenuBarAnchorEl(event.currentTarget);
    };

    handleCloseMenu = () => {
        this.props.mainMenuStore.changeMenuBarAnchorEl(null);
    };

    handlePushLink = link => {
        if(this.props.location.pathname.startsWith("/broadcast/")) {
            if(this.props.location.pathname !== link) {
                if(window.confirm("다른 메뉴로 이동하시겠습니까?")) {
                    this.props.history.push(link);
                }
            }
        } else {
            this.props.history.push(link);
        }
    }

    handleClickAnchorMenu = (link) => {
        this.props.mainMenuStore.changeMenuBarAnchorEl(null);
        if(this.props.location.pathname.startsWith("/broadcast/")) {
            if(this.props.location.pathname !== link) {
                if(window.confirm("다른 메뉴로 이동하시겠습니까?")) {
                    this.props.history.push(link);
                }
            }
        } else {
            this.props.history.push(link);
        }
    }

    handleClickBoard = () => {
        if(this.props.location.pathname.startsWith("/broadcast/")) {
            if (window.confirm("알림장으로 이동하시겠습니까?")) {
                this.props.history.push("/board/list");
            }
        } else {
            this.props.history.push("/board/list");
        }
    };

    render() {
        const { classes } = this.props;
        const { isLoggedIn, doLogout } = this.props;
        const { menuBarAnchorEl } = this.props.mainMenuStore;
        const { myPage } = this.props.mainMenuStore.menuData;
        const { userData } = this.props.userStore;
        const { isLoginTypeUser } = this.props.authStore;
        const { serverMode } = this.props.signUpStore;

        const myPagePopoverOpen = Boolean(myPage.anchorEl);
        const profileUrl = userData.profileUrl || "/images/login-logo.png";
        return (
            <div>
                <AppBar position="fixed" color={"inherit"} elevation={0} className={classes.appBar}>
                    <Toolbar className={classes.appBarWrap}>
                        <IconButton color="inherit" aria-label="open drawer" edge="start" className={classes.menuButton} onClick={this.handleToggleMenu}>
                            <MenuRoundedIcon />
                        </IconButton>
                        <div className={classes.title}>
                            <Grid container alignItems={"center"} spacing={1} className={classes.menuTitle}>
                                <Hidden smUp>
                                    <div>
                                        <Menu id="simple-menu" anchorEl={menuBarAnchorEl} open={Boolean(menuBarAnchorEl)}
                                              onClose={this.handleCloseMenu} keepMounted>
                                            {isLoggedIn ?
                                                isLoginTypeUser ?
                                                    [
                                                        <MenuItem key="top-menu-small-dashboard" className={classes.mobileMenuItem} onClick={() => this.handleClickAnchorMenu('/dashboard')}>대시보드</MenuItem>,
                                                        <MenuItem key="top-menu-small-channel" className={classes.mobileMenuItem} onClick={() => this.handleClickAnchorMenu('/channel/list')}>강의</MenuItem>,
                                                        // <MenuItem key="top-menu-small-attendancebook" className={classes.mobileMenuItem} onClick={() => this.handleClickAnchorMenu('/attendance/state')}>출석현황</MenuItem>,
                                                        <MenuItem key="top-menu-small-attendance" className={classes.mobileMenuItem} onClick={() => this.handleClickAnchorMenu('/attendance/list')}>출석부</MenuItem>,
                                                        <MenuItem key="top-menu-small-group" className={classes.mobileMenuItem} onClick={() => this.handleClickAnchorMenu('/group/list')}>그룹</MenuItem>,
                                                        <MenuItem key="top-menu-small-quiz" className={classes.mobileMenuItem} onClick={() => this.handleClickAnchorMenu('/quiz/list')}>퀴즈</MenuItem>
                                                    ]
                                                    :
                                                    [
                                                        <MenuItem key="top-menu-small-channel" className={classes.mobileMenuItem} onClick={() => this.handleClickAnchorMenu('/channel/list')}>강의</MenuItem>
                                                    ]
                                                :
                                                null
                                            }
                                            <MenuItem className={classes.mobileMenuItem} onClick={() => this.handleClickAnchorMenu('/faq')}>FAQ</MenuItem>
                                        </Menu>
                                    </div>
                                </Hidden>

                                <NavLink className={classes.link} to={this.props.location.pathname.startsWith("/broadcast/") ? this.props.location.pathname : "/"}>
                                    <img src="/images/logo.png" alt="" />
                                </NavLink>

                                <Hidden xsDown>
                                    <div style={{paddingLeft: 16, paddingTop: 6}}>
                                        {isLoggedIn ?
                                            isLoginTypeUser ?
                                                [
                                                    <Button key="top-menu-large-dashboard" className={classes.titleBtn} onClick={() => this.handlePushLink('/dashboard')}>대시보드</Button>,
                                                    <Button key="top-menu-large-channel" className={classes.titleBtn} onClick={() => this.handlePushLink('/channel/list')}>강의</Button>,
                                                    // <Button key="top-menu-large-attendancebook" className={classes.titleBtn} onClick={() => this.handlePushLink('/attendance/state')}>출석현황</Button>,
                                                    <Button key="top-menu-large-attendance" className={classes.titleBtn} onClick={() => this.handlePushLink("/attendance/list")}>출석부</Button>,
                                                    <Button key="top-menu-large-group" className={classes.titleBtn} onClick={() => this.handlePushLink("/group/list")}>그룹</Button>,
                                                    <Button key="top-menu-large-quiz" className={classes.titleBtn} onClick={() => this.handlePushLink("/quiz/list")}>퀴즈</Button>
                                                ]
                                                :
                                                [
                                                    <Button key="top-menu-large-channel" className={classes.titleBtn} onClick={() => this.handlePushLink('/channel/list')}>강의</Button>
                                                ]
                                            :
                                            null

                                        }
                                        <Button className={classes.titleBtn} onClick={() => this.handlePushLink("/faq")}>
                                            FAQ
                                        </Button>
                                    </div>
                                </Hidden>
                            </Grid>
                        </div>

                        { isLoggedIn ? (
                            <Grid className={classes.iconMenu}>
                                <Hidden smUp>
                                    <IconButton style={{paddingTop: 12, paddingBottom: 12, paddingLeft: 6, paddingRight: 6}} onClick={() => doLogout(this.props.location.pathname)}>
                                        <ExitToAppIcon />
                                    </IconButton>

                                    <IconButton style={{paddingTop: 12, paddingBottom: 12, paddingLeft: 6, paddingRight: 6}} onClick={this.handleClickBoard}>
                                        <Badge color="primary" badgeContent={this.props.boardStore.unreadCount} >
                                            <NotificationsIcon  />
                                        </Badge>
                                    </IconButton>

                                    <IconButton style={{paddingTop: 12, paddingBottom: 12, paddingLeft: 6, paddingRight: 6}} onClick={this.handleClickMyPage} name="myPage" variant="contained">
                                        <AccountCircleSharpIcon />
                                    </IconButton>
                                </Hidden>
                                <Hidden xsDown>
                                    <Button className={classes.logOut} style={{marginLeft: 3, marginRight: 3}} onClick={() => doLogout(this.props.location.pathname)}>
                                        로그아웃
                                    </Button>

                                    <IconButton onClick={this.handleClickBoard} style={{marginLeft: 3, marginRight: 3}}>
                                        <Tooltip open={this.props.boardStore.unreadCount > 0}
                                                 arrow
                                                 placement="bottom"
                                                 disableFocusListener
                                                 disableHoverListener
                                                 disableTouchListener
                                                 title={
                                                     ""
                                                     // <React.Fragment>
                                                     //     <Typography variant="subtitle1" className={classes.unreadText}>읽지 않은 알림이 존재합니다.</Typography>
                                                     //     <Typography variant="body1" className={classes.unreadText}>위의 아이콘을 클릭하여 확인해 보세요.</Typography>
                                                     // </React.Fragment>
                                                 }>
                                            <Badge color="primary" badgeContent={this.props.boardStore.unreadCount} >
                                                <NotificationsIcon />
                                            </Badge>
                                        </Tooltip>
                                    </IconButton>

                                    <Button className={classes.myPage} style={{marginLeft: 3, marginRight: 3}} onClick={this.handleClickMyPage} name="myPage" variant="contained">
                                        마이페이지
                                    </Button>
                                </Hidden>
                                <Popover open={myPagePopoverOpen}
                                         onClose={this.handleCloseMypagePopover}
                                         anchorEl={myPage.anchorEl}
                                         disablePortal={false}
                                         transition ="true"
                                         anchorOrigin={{vertical: 'bottom', horizontal: 'right',}}
                                         transformOrigin={{vertical: 'top', horizontal: 'right',}}>
                                    <Card >
                                        <CardHeader avatar={<Avatar aria-label="recipe" src={profileUrl} className={classes.avatar}></Avatar>} title={userData.email} />
                                        <CardContent className={classes.detailInform}>
                                            <Grid container spacing={2} justify="center" direction="row" alignItems="center" >
                                                <Grid item xs={2}>
                                                    <Typography variant="body1" color="textSecondary" component="p"> 이름 </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography variant="body1" color="textSecondary" component="p"> {userData.name} </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button variant="outlined" className={classes.modifyButton} onClick={this.handleClickInfoModify}>수정</Button>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Popover>
                                {getDeviceBrowserType().deviceType === "etc" &&
                                    <Fab size="small" className={classes.helpIcon} onClick={() => this.props.helpStore.openHelp()} >
                                        <HelpIcon color="primary" viewBox="2 2 20 20" />
                                    </Fab>
                                }
                            </Grid>
                            ) : (
                            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={1} className={classes.iconMenu}>
                                <Hidden xsDown>
                                    <Grid item>
                                        <Button className={classes.loginBtn}
                                                variant="contained"
                                                onMouseOver={() => this.setState({memberLoginHover: true})}
                                                onMouseOut={() => this.setState({memberLoginHover: false})}
                                                onClick={this.handleClickMemberLogin}>
                                            {this.state.memberLoginHover ? '로그인' : '학생'}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button className={classes.loginBtn}
                                                variant="contained"
                                                onMouseOver={() => this.setState({userLoginHover: true})}
                                                onMouseOut={() => this.setState({userLoginHover: false})}
                                                onClick={this.handleClickUserLogin}>
                                            {this.state.userLoginHover ? '로그인' : '교수'}
                                        </Button>
                                    </Grid>
                                    {serverMode !== "SERVER" &&
                                        <Grid item>
                                            <Button className={classes.signUpBtn}
                                                    variant={"contained"}
                                                    color={"secondary"}
                                                    onClick={() => this.props.history.push("/signup")}>
                                                회원가입
                                            </Button>
                                            
                                        </Grid>
                                    }
                                </Hidden>
                            </Grid>
                        )}
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

export default withRouter(withStyles(useStyles) (TopBar));

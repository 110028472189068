import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogContent, TextField, Typography} from "@material-ui/core";

export default function BroadcastAttendDialog(props) {
    const { open, msg, msgError, msgHelperText, changeMsg, changeStatus, doAttend, attendMsg, changeMsgError} = props;

    const doAttendHandle = () => {
        if(msg === attendMsg.authCode) {
            doAttend();
            changeMsgError(false);
            changeStatus(false);
        } else {
            changeMsgError(true);
        }
    }

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'xs'} >
            <DialogTitle>
                출석하기
            </DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1" component="h2">
                    인증코드를 입력해 주세요.
                </Typography>
                <Typography variant="subtitle1" component="h2">
                    인증코드 : {attendMsg && attendMsg.authCode ? attendMsg.authCode : ""}
                </Typography>
                <TextField id="addGroupName"
                           name="addGroupName"
                           // label="그룹명"
                           margin="normal"
                           error={msgError}
                           helperText={msgError ? msgHelperText : ""}
                           value={msg}
                           onChange={(e)=> {changeMsg(e.target.value)}}
                           required fullWidth />
            </DialogContent>
            <DialogActions>
                <Button style={{
                    color: 'rgba(0, 0, 0, 0.56)'
                }}
                        onClick={() => {changeStatus(false)}}
                >
                    취소
                </Button>
                <Button style={{
                    color: '#d64e4e'
                }}
                        onClick={doAttendHandle}
                >
                    출석
                </Button>
            </DialogActions>
        </Dialog>
    );
}
import {action, computed, flow, observable, toJS} from "mobx";
import axios from "axios";
import _ from "lodash";

const ListState = {
    None: 'None',
    Loading: 'Loading',
    Loaded: 'Loaded',
    LoadFailed: 'LoadFailed',
};

const InfoState = {
    None: 'None',
    Loading: 'Loading',
    Loaded: 'Loaded',
    LoadFailed: 'LoadFailed',
};

const AddState = {
    Closed: 'Closed',
    Opened: 'Opened',
    Adding: 'Adding',
    Added: 'Added',
    AddFailed: 'AddFailed',
};

const DeleteState = {
    None: 'None',
    Confirm: 'Confirm',
    Deleting: 'Deleting',
    Deleted: 'Deleted',
    DeleteFailed: 'DeleteFailed',
};

const UpdateState = {
    Closed: 'Closed',
    Opened: 'Opened',
    Updating: 'Updating',
    Updated: 'Updated',
    UpdateFailed: 'UpdateFailed',
};

export const QuizType = {
    SINGLE_CHOICE: 'SINGLE_CHOICE',
    MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
    SHORT_ANSWER: 'SHORT_ANSWER',
    SUBJECTIVE: 'SUBJECTIVE'
};

const EmptyQuizInfo = {
    quizId: 0,
    userId: 0,
    quizTitle: '',
    quizQuestion: '',
    quizOptions: [],
    createdDatetime: '',
    modifiedDatetime: '',
};

const EmptyNewQuiz = {
    quizTitle: '',
    quizQuestion: '',
    type: QuizType.SINGLE_CHOICE,
    quizOptions: [],
};

export default class QuizStore {
    @observable listState = ListState.None;
    @observable quizList = [];
    @observable infoState = InfoState.None;
    @observable quizInfo = {...EmptyQuizInfo};

    @observable updateState = UpdateState.Closed;
    @observable addState = AddState.Closed;
    @observable newQuiz = {...EmptyNewQuiz};
    @observable quizQuestionImages = [];
    @observable quizQuestionImageDataUrls = [];
    @observable newQuizOptionContents = '';
    @observable newQuizOptionCorrectFlag = false;
    @observable isSubjective = false;
    @observable quizType = QuizType.SINGLE_CHOICE;

    @observable deleteState = DeleteState.None;
    @observable deleteQuizId = 0;


    @action clearListState = () => {
        this.listState = [];
        this.listState = ListState.None;
    }

    @action clearInfoState = () => {
        this.quizInfo = {...EmptyQuizInfo};
        this.infoState = InfoState.None;
    }

    @action openUpdateDialog = () => {
        this.newQuiz = toJS(this.quizInfo);
        this.quizQuestionImges = [...this.quizInfo.quizImages];
        this.quizQuestionImageDataUrls = this.quizInfo.quizImages.map(quizImage => `data:${quizImage.type};base64,${quizImage.image}`);
        this.updateState = UpdateState.Opened;
    }

    @action clearUpdateState = (open) => {
        if(open) {
            this.updateState = UpdateState.Opened;
        } else {
            this.updateState = UpdateState.Closed;
        }
    }

    @action openAddDialog = () => {
        this.newQuiz = {...EmptyNewQuiz};
        this.addState = AddState.Opened;
    }

    @action clearAddState = (open) => {
        if(open) {
            this.addState = AddState.Opened;
        } else {
            this.addState = AddState.Closed;
        }
    }

    @action initNewQuiz = () => {
        this.newQuiz = {...EmptyNewQuiz}
        this.quizQuestionImages = [];
        this.quizQuestionImageDataUrls = [];
    }

    @action initNewQuizWithData = () => {
        this.initNewQuiz();
        this.isSubjective = false;
        this.quizType = QuizType.SINGLE_CHOICE;
    }

    @action setNewQuizTitle = title => this.newQuiz.quizTitle = title;
    @action setNewQuizQuestion = question => this.newQuiz.quizQuestion = question;
    @action setQuizQuestionImages = images => this.quizQuestionImages = [...images];
    @action setQuizQuestionImageDataUrls = imageDataUrls => this.quizQuestionImageDataUrls = [...imageDataUrls];
    @action removeQuizQuestionImage = index => this.quizQuestionImages.splice(index, 1);
    @action removeQuizQuestionImageDataUrls = index => this.quizQuestionImageDataUrls.splice(index, 1);

    @action setNewQuizOptionsContents = (optionId, contents) => {
        for(let i=0; i<this.newQuiz.quizOptions.length; i++) {
            const opt = this.newQuiz.quizOptions[i];
            if(opt.quizOptionId === optionId) {
                opt.quizOptionContents = contents;
                break;
            }
        }
    }

    @action setNewQuizOptionsCorrectFlag = (optionId, flag) => {
        for(let i=0; i<this.newQuiz.quizOptions.length; i++) {
            const opt = this.newQuiz.quizOptions[i];
            if(opt.quizOptionId === optionId) {
                opt.correctFlag = flag;
                break;
            }
        }
    }

    @action setNewQuizOptionContents = contents => this.newQuizOptionContents = contents;
    @action setNewQuizOptionCorrectFlag = flag => this.newQuizOptionCorrectFlag = flag;
    @action setSubjective = isSubjective => this.isSubjective = isSubjective;
    @action setQuizType = quizType => this.quizType = quizType;

    @action addNewQuizOption = () => {
        const lastQuiz = _.chain(this.newQuiz.quizOptions)
                        .orderBy(['quizOptionNumber'], ['desc'])
                        .head()
                        .value();

        const newOption = {
            quizOptionNumber: lastQuiz ? lastQuiz.quizOptionNumber + 1 : 1,
            quizOptionContents: toJS(this.newQuizOptionContents),
            correctFlag: toJS(this.newQuizOptionCorrectFlag),
        };

        this.newQuiz.quizOptions.push(newOption);
        this.newQuizOptionContents = '';
        this.newQuizOptionCorrectFlag = false;
    }

    @action removeNewQuizOption = (optionNumber) => {
        const newOptions = toJS(this.newQuiz.quizOptions);
        const removeIndex = _.findIndex(newOptions, (option) => option.quizOptionNumber === optionNumber);
        if(removeIndex >= 0) {
            newOptions.splice(removeIndex, 1);
        }

        for(let i=0; i<newOptions.length; i++) {
            const opt = newOptions[i];
            opt.quizOptionNumber = i+1;
        }

        this.newQuiz.quizOptions = newOptions;
    }

    @action openDeleteDialog = (quizId) => {
        this.deleteQuizId = quizId;
        this.deleteState = DeleteState.Confirm;
    }

    @action clearDeleteState = () => {
        this.deleteQuizId = 0;
        this.deleteState = DeleteState.None;
    }

    @computed get isLoadFailed() {
        return this.listState === ListState.LoadFailed;
    }

    @computed get isInfoLoadFailed() {
        return this.infoState === InfoState.LoadFailed;
    }

    @computed get isUpdateDialogOpen() {
        return this.updateState !== UpdateState.Closed;
    }

    @computed get isUpdating() {
        return this.updateState === UpdateState.Updating;
    }

    @computed get isUpdateFailed() {
        return this.updateState === UpdateState.UpdateFailed;
    }

    @computed get isAddDialogOpen() {
        return this.addState !== AddState.Closed;
    }

    @computed get isAddable() {
        return (this.quizType === QuizType.SINGLE_CHOICE || this.quizType === QuizType.MULTIPLE_CHOICE)
                ? (this.newQuiz.quizTitle.length > 0 && this.newQuiz.quizQuestion.length > 0 && this.newQuiz.quizOptions.length > 0)
                : (this.newQuiz.quizTitle.length > 0 && this.newQuiz.quizQuestion.length > 0);
    }

    @computed get isUpdatable() {
        return (this.newQuiz.type === QuizType.SINGLE_CHOICE || this.newQuiz.type === QuizType.MULTIPLE_CHOICE)
            ? (this.newQuiz.quizTitle.length > 0 && this.newQuiz.quizQuestion.length > 0 && this.newQuiz.quizOptions.length > 0)
            : (this.newQuiz.quizTitle.length > 0 && this.newQuiz.quizQuestion.length > 0);
    }

    @computed get isAdding() {
        return this.addState === AddState.Adding;
    }

    @computed get isAddFailed() {
        return this.addState === AddState.AddFailed;
    }

    @computed get isOpenDeleteDialog() {
        return this.deleteState === DeleteState.Confirm;
    }

    @computed get isDeleteFailed() {
        return this.deleteState === DeleteState.DeleteFailed;
    }

    loadQuizList = flow(function* loadQuizList(userId) {
        this.listState = ListState.Loading;

        try {
            const response = yield axios.get(`/api/v1/quiz?user-id=${userId}&paging=no`);
            const quizs = _.orderBy(response.data, ['createdDatetime'], ['desc']);

            this.quizList = quizs;
            this.listState = ListState.Loaded;
        } catch(error) {
            this.quizList = [];
            this.listState = ListState.LoadFailed;
        }
    });

    loadQuizInfo = flow(function* loadQuizInfo(quizId) {
        this.infoState = InfoState.Loading;
        this.quizInfo = {...EmptyQuizInfo};

        try {
            const response = yield axios.get(`/api/v1/quiz/quiz-info?quiz-id=${quizId}`);
            if(response.status === 200) {
                const quiz = response.data;
                this.quizInfo = quiz;
                this.infoState = InfoState.Loaded;
            }
        } catch(error) {
            this.infoState = InfoState.LoadFailed;
        }
    });

    updateQuiz = flow(function* updateQuiz(userId) {
        this.updateState = AddState.Adding;

        try {
            delete this.newQuiz.quizImages;
            const files = toJS(this.quizQuestionImages);
            const param = new FormData();
            param.append("quizTransfer", JSON.stringify(this.newQuiz));
            files.forEach(file => param.append("quizQuestionImages", file));
            const response = yield axios.put('/api/v1/quiz', param);
            if(response.status === 200) {
                this.initNewQuizWithData();
                this.quizInfo = {...EmptyQuizInfo};
                this.updateState = UpdateState.Closed;

                this.loadQuizList(userId);
            }
        } catch(error) {
            this.updateState = UpdateState.UpdateFailed;
        }
    });

    addNewQuiz = flow(function* addNewQuiz(userId) {
        this.addState = AddState.Adding;

        try {
            this.newQuiz.type = this.quizType;
            const files = toJS(this.quizQuestionImages);
            const param = new FormData();
            param.append("quizRegist", JSON.stringify(this.newQuiz));
            files.forEach(file => param.append("quizQuestionImages", file));
            yield axios.post(`/api/v1/quiz`, param);

            this.initNewQuizWithData();
            this.addState = AddState.Closed;

            this.loadQuizList(userId);
        } catch(error) {
            this.addState = AddState.AddFailed;
        }
    });

    deleteQuiz = flow(function* deleteQuiz(userId) {
        this.deleteState = DeleteState.Deleting;

        try {
            const quizId = toJS(this.deleteQuizId);
            yield axios.delete(`/api/v1/quiz?quiz-id=${quizId}`);

            this.deleteState = DeleteState.Deleted;
            this.loadQuizList(userId);
        } catch(error) {
            this.deleteState = DeleteState.DeleteFailed;
        }
    })
}
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Paper,
    Typography,
    withStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";


const styles = (theme) => ({
    dialog: {
    },
    dialogTitle: {
    },
    dialogContent: {
    },
    grow: {
        flexGrow: 1,
    },
    contentSubTitle: {
        paddingTop: 5,
        paddingLeft: theme.spacing(1),
        fontSize: 15,
    },
    itemHeader: {
    },
    itemBody: {
        paddingTop: theme.spacing(1),
    },
    listContainer: {
        height: 360,
        overflow: 'auto',
        boxShadow:'none',
        border:'1px solid #eee',
        '& ul li > div > div > span ':{
            fontSize:'14px',
            fontWeight:'700',
            color:'#333'
        }
    },
    commandButton: {
        borderRadius: 24,
    },
});

@inject('memberAddStore', 'groupStore')
@observer
class MemberAddDialog extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.memberAddStore.isAdded) {
            this.props.enqueueSnackbar('학생이 추가되었습니다', {
                variant: 'info'
            });

            this.props.memberAddStore.clearDialogState(false);
            this.props.groupStore.loadGroupList(this.props.userId);
        }

        if(this.props.memberAddStore.isAddFailed) {
            this.props.enqueueSnackbar('학생 추가에 실패하였습니다', {
                variant: 'error'
            });
        }
    }

    handleOK = () => {
        this.props.memberAddStore.update();
    }

    handleCancel = () => {
        this.props.memberAddStore.clearDialogState(false);
        this.props.memberAddStore.setFilterComment("");
        this.props.memberAddStore.setFilterUserName("");
    }

    handleChangeMemberFilter = (value) => {
        const comment = value ? value.comment : "";
        this.props.memberAddStore.setFilterComment(comment);
    }

    handleChangeGroupMemberFilter = (value) => {
        const userName = value ? value.userName : "";
        this.props.memberAddStore.setFilterUserName(userName);
    }

    // handleClickAddMember = (email) => {
    //     this.props.memberAddStore.addMemberToGroup(email);
    // }

    handleToggleAddMember = (email) => {
        this.props.memberAddStore.toggleAddMember(email);
    }

    handleClickAddCheckMember = () => {
        this.props.memberAddStore.addCheckMemberToGroup();
    }

    handleToggleAddAllMember = (event) => {
        this.props.memberAddStore.toggleAllMemberChecked(event.target.checked);
    }

    // handleClickRemoveMember = (email) => {
    //     this.props.memberAddStore.removeMemberFromGroup(email);
    // }

    handleToggleRemoveMember = (email) => {
        this.props.memberAddStore.toggleRemoveMember(email);
    }

    handleClickRemoveCheckMember = () => {
        this.props.memberAddStore.removeCheckMemberFromGroup();
    }

    handleToggleRemoveAllMember = (event) => {
        this.props.memberAddStore.toggleAllRemoveMemberCheck(event.target.checked);
    }

    render() {
        const { classes } = this.props;
        const { isDialogOpen, isAdding, groupMemberList, filteredMemberList, checkedMembers, allMemberChecked, checkedGroupMembers, allGroupMemberChecked, filterUserName} = this.props.memberAddStore;
        const filterList = filteredMemberList.filter((item, index) => {
            return (
                filteredMemberList.findIndex((item2, j) => {
                    return item.comment === item2.comment;
                }) === index
            );
        }).filter(i => i.comment !== "");


        return (
            <Dialog disableBackdropClick fullWidth
                    maxWidth="sm"
                    className={classes.dialog}
                    open={isDialogOpen}
                    onClose={this.handleCancel}
                    aria-labelledby="member-add-dialog-title" >
                <DialogTitle id="member-add-dialog-title" className={classes.dialogTitle}>
                    학생 변경하기
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Grid container spacing={1}>
                        <Grid container spacing={1} item xs={12}>
                            <Grid item xs={5}>
                                {/*<TextField id="upload-comment"*/}
                                {/*           margin="normal"*/}
                                {/*           label={<Typography variant="subtitle1" >비고 검색</Typography>}*/}
                                {/*           value={filterComment}*/}
                                {/*           onChange={this.handleChangeMemberFilter}*/}
                                {/*           InputLabelProps={{shrink: true, className: classes.itemHeader}}*/}
                                {/*           InputProps={{className: classes.itemBody}}*/}
                                {/*           fullWidth*/}
                                {/*/>*/}
                                <Autocomplete
                                    onChange={(event, value) => {
                                        this.handleChangeMemberFilter(value);
                                    }}
                                    options={filterList ? filterList.map((item) => {
                                        return ({
                                            comment : item.comment,
                                        })}) : []
                                    }
                                    getOptionSelected={(option,value) => option.comment === value.comment}
                                    getOptionLabel={(option) => `${option.comment}`}
                                    size="small"
                                    renderInput={(params) => <TextField {...params} label="" variant="outlined"  placeholder="비고를 검색 해주세요." />}
                                />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={5}>
                                <Autocomplete
                                    onChange={(event, value) => {
                                        this.handleChangeGroupMemberFilter(value);
                                    }}
                                    options={groupMemberList ? groupMemberList.map((item) => {
                                        return ({
                                            userName : item.userName,
                                        })}) : []
                                    }
                                    getOptionSelected={(option,value) => option.userName === value.userName}
                                    getOptionLabel={(option) => `${option.userName}`}
                                    size="small"
                                    renderInput={(params) => <TextField {...params} label="" variant="outlined"  placeholder="학생를 검색 해주세요." />}
                                />
                                <Typography className={classes.contentSubTitle}>{`그룹에 포함된 학생 (${groupMemberList.length} 명)`}</Typography>
                            </Grid>

                        </Grid>

                        <Grid item xs={5}>
                            <Paper className={classes.listContainer}>
                                <List dense subheader={
                                    <ListSubheader style={{textAlign: 'right', backgroundColor: 'white'}}>
                                        <FormControlLabel control={<Checkbox checked={allMemberChecked} onChange={this.handleToggleAddAllMember} />}
                                                          label="전체선택"
                                                          labelPlacement="start"
                                        />
                                    </ListSubheader>
                                }>
                                    {filteredMemberList.map(member =>
                                        <ListItem key={`user-member-${member.email}`} >
                                            <ListItemText id={`user-member-list-item-${member.email}`} primary={member.userName} secondary={member.userNumber}></ListItemText>
                                            <ListItemSecondaryAction>
                                                <Checkbox edge="end" checked={checkedMembers.indexOf(member.email) !== -1} onChange={() => this.handleToggleAddMember(member.email)} inputProps={{'aria-labelledby': `user-member-list-item-${member.email}`}} />
                                                {/*<IconButton size="small" color="primary" onClick={() => this.handleClickAddMember(member.email)}>*/}
                                                {/*    <AddIcon />*/}
                                                {/*</IconButton>*/}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )}
                                </List>
                            </Paper>
                        </Grid>

                        <Grid item xs={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                            <IconButton style={{marginTop: 6, marginBottom: 6}} onClick={this.handleClickAddCheckMember}>
                                <ArrowForwardIcon />
                            </IconButton>
                            <IconButton style={{marginTop: 6, marginBottom: 6}} onClick={this.handleClickRemoveCheckMember}>
                                <ArrowBackIcon />
                            </IconButton>
                        </Grid>

                        <Grid item xs={5}>
                            <Paper className={classes.listContainer}>

                                {filterUserName !== "" && filterUserName !== undefined && filterUserName !== null ?
                                    <List>
                                        {groupMemberList.filter(user => user.userName === filterUserName).map(member =>
                                            <ListItem key={`group-member-${member.email}`}>
                                                <ListItemText key={`group-member-list-item-${member.email}`}
                                                              primary={member.userName}
                                                              secondary={member.userNumber}></ListItemText>
                                                <ListItemSecondaryAction>
                                                    <Checkbox edge="end"
                                                              checked={checkedGroupMembers.indexOf(member.email) !== -1}
                                                              onChange={() => this.handleToggleRemoveMember(member.email)}
                                                              inputProps={{'aria-labelledby': `group-member-list-item-${member.email}`}}/>
                                                    {/*<IconButton size="small" color="primary" onClick={() => this.handleClickRemoveMember(member.email)} >*/}
                                                    {/*    <RemoveIcon />*/}
                                                    {/*</IconButton>*/}
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        )}
                                    </List>
                                    :
                                    <List dense subheader={
                                        <ListSubheader style={{textAlign: 'right', backgroundColor: 'white'}}>
                                            <FormControlLabel control={<Checkbox checked={allGroupMemberChecked}
                                                                                 onChange={this.handleToggleRemoveAllMember}/>}
                                                              label="전체선택"
                                                              labelPlacement="start"
                                            />

                                        </ListSubheader>
                                    }>
                                        {groupMemberList.map(member =>
                                            <ListItem key={`group-member-${member.email}`}>
                                                <ListItemText key={`group-member-list-item-${member.email}`}
                                                              primary={member.userName}
                                                              secondary={member.userNumber}></ListItemText>
                                                <ListItemSecondaryAction>
                                                    <Checkbox edge="end"
                                                              checked={checkedGroupMembers.indexOf(member.email) !== -1}
                                                              onChange={() => this.handleToggleRemoveMember(member.email)}
                                                              inputProps={{'aria-labelledby': `group-member-list-item-${member.email}`}}/>
                                                    {/*<IconButton size="small" color="primary" onClick={() => this.handleClickRemoveMember(member.email)} >*/}
                                                    {/*    <RemoveIcon />*/}
                                                    {/*</IconButton>*/}
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        )}
                                    </List>
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <div className={classes.grow} />
                    <Button onClick={this.handleCancel}>
                        취소
                    </Button>
                    <Button onClick={this.handleOK}>
                        {isAdding ? <CircularProgress size="small" /> : '확인'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (MemberAddDialog)));
import _ from "lodash";

const VIDEO_MIN_HEIGHT = 36;
const LogPrefix = '[MonitoringLayoutManager] ';

export class MonitoringLayoutManager {
    constructor(config, onResize) {
        this.config = Object.assign({}, {
            mainZIndex: 10,
            monitoringVideoContainerRef :undefined,
            monitoringVideoRef : undefined,
            additionalVideoRefs : [],
            isMobile : false,
            isFullScreen : false,
            landscape: true,
        }, config);

        this.onResize = onResize;
    }

    initVideoArea = () => {
        console.log(LogPrefix, `Initializing video area`);

        if(this.config.monitoringVideoRef && this.config.monitoringVideoRef.current) {
            this.config.monitoringVideoRef.current.style.left = '0px';
            this.config.monitoringVideoRef.current.style.top = '0px';
            this.config.monitoringVideoRef.current.style.width = '1px';
            this.config.monitoringVideoRef.current.style.height = '1px';
            this.config.monitoringVideoRef.current.setAttribute('left', `0px`);
            this.config.monitoringVideoRef.current.setAttribute('top', `0px`);
            this.config.monitoringVideoRef.current.setAttribute('width', `1px`);
            this.config.monitoringVideoRef.current.setAttribute('height', `1px`);
        }
    }

    // For VideoArea size
    setLandscape = (landscape) => {
        if(this.config.landscape !== landscape) {
            this.config.landscape = landscape;
        }
    }

    setFullScreen = (fullScreen) => {
        this.config.isFullScreen =  fullScreen;
    }

    resizeMonitoringArea = (needInit) => {
        console.log(LogPrefix, `Resizing video area : init=${needInit}`, this.config);

        const {monitoringVideoContainerRef, additionalVideoRefs, monitoringVideoRef, isMobile, isFullScreen} = this.config;
        const tryMaxSize = isMobile || isFullScreen;

        if(monitoringVideoContainerRef && monitoringVideoContainerRef.current) {
            // 크기를 초기화 시켜야 VideoContainer의 사이즈가 화면 크기에 맞게 줄어듬...
            if(needInit) {
                this.initVideoArea();
            }
            const additionalHeight = _.chain(additionalVideoRefs).map((ref) => ref.current ? ref.current.clientHeight : 0).reduce((sum, height) => sum + height, 0).value();
            const containerRect = monitoringVideoContainerRef.current.getBoundingClientRect();
            console.log(LogPrefix, 'containerRect', containerRect.top);
            console.log(LogPrefix, 'additionalHeight', additionalHeight);


            const calculatedWidth = tryMaxSize ? window.innerWidth : (this.config.landscape ? containerRect.width : window.innerWidth);
            const calculatedHeight = (9 / 16) * calculatedWidth;
            const maxHeight = tryMaxSize ? window.innerHeight : (this.config.landscape ? window.innerHeight - containerRect.top - additionalHeight : calculatedHeight);

            // const calculatedWidth = containerRect.width;
            // const calculatedHeight = (9 / 16) * calculatedWidth;
            // const maxHeight = window.innerHeight + containerRect.top + additionalHeight;

            let mainWidth = calculatedWidth;
            let mainHeight = calculatedHeight;
            // let mainMargin = 0;

            if(mainHeight > maxHeight) {
                mainWidth = (16 / 9) * maxHeight;
                mainHeight = maxHeight;
                // mainMargin = (calculatedWidth - mainWidth) / 2;
            } else if(mainHeight < VIDEO_MIN_HEIGHT) {
                mainWidth = (16 / 9) * VIDEO_MIN_HEIGHT;
                mainHeight = VIDEO_MIN_HEIGHT;
                // mainMargin = (calculatedWidth - mainWidth) / 2;
            }

            if(monitoringVideoRef && monitoringVideoRef.current) {
                monitoringVideoRef.current.style.width = `${mainWidth}px`;
                monitoringVideoRef.current.style.height = `${mainHeight}px`;
                monitoringVideoRef.current.setAttribute('width', `${mainWidth}px`);
                monitoringVideoRef.current.setAttribute('height', `${mainHeight}px`);
            }

            console.log(LogPrefix, `Width & Height = ${mainWidth}, ${mainHeight}`);
            if (this.onResize) {
                try {
                    const mainRect = monitoringVideoRef.current.getBoundingClientRect();
                    this.onResize(mainWidth, mainHeight, mainRect.left, mainRect.top);
                } catch (error) {
                    console.warn(LogPrefix, 'Error in onResize callback', error);
                }
            }
        }
    }

}
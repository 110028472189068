import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Avatar,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, IconButton, Input, InputAdornment, MenuItem, Tooltip,
    Typography,
    withStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {
    TINY_GROUP_MAX_USER_COUNT, TINY_GROUP_MEMBER_VIDEO_HEIGHT,
    TINY_GROUP_MEMBER_VIDEO_WIDTH, TINY_GROUP_OWNER_VIDEO_HEIGHT, TINY_GROUP_OWNER_VIDEO_WIDTH,
    TINY_GROUP_VIDEO_BOX_PADDING_X,
    TINY_GROUP_VIDEO_BOX_PADDING_Y, TINY_GROUP_VIDEO_RESOLUTION_HEIGHT, TINY_GROUP_VIDEO_RESOLUTION_WIDTH,
} from "../../../stores/TinyGroupStore";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {toJS} from "mobx";
import Paper from "@material-ui/core/Paper";
import Draggable from 'react-draggable';
import CommonDialog from "../../../components/CommonDialog";
import {RoomPresenter} from "../api/RoomPresenter";

// import VideocamIcon from '@material-ui/icons/Videocam';
import MicIcon from '@material-ui/icons/Mic';
import {ReactComponent as VideocamRoundedIcon} from '../../../common/images/VideocamRoundedIcon.svg';
import {ReactComponent as VideocamRoundedIconOff} from '../../../common/images/VideocamRoundedIconOff.svg';
import {ReactComponent as SettingsVoiceIcon} from '../../../common/images/SettingsVoiceIcon.svg';
import {ReactComponent as SettingsVoiceIconOff} from '../../../common/images/SettingsVoiceIconOff.svg';
import {ReactComponent as GroupPlayIcon} from "../../../common/images/GroupPlayIcon.svg";
import {ReactComponent as ErrorOutlineIcon} from '../../../common/images/ErrorOutlineIcon.svg';
import {PUBLISH_BUTTON_TIMEOUT} from "../../../stores/RoomStore";
import {ReactComponent as PersonIcon} from "../../../common/images/PersonIcon.svg";
import SoundMeterVisualizer from "../../../components/SoundMeterVisualizer";

import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';

import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import clsx from "clsx";


const styles = (theme) => ({
    dialog: {},
    dialogTitle: {
        fontSize: 16,
        fontWeight: 600,
    },
    dialogContent: {},
    formContainer: {},

    boxStyle: {
        backgroundColor: 'transparent',
    },

    videoBoxStyle: {
        backgroundColor: 'black',
        cursor: 'pointer',
        // width: TINY_GROUP_VIDEO_WIDTH + 2,
        // height: TINY_GROUP_VIDEO_HEIGHT + 24,
    },
    videoStyle: {
        backgroundColor: 'transparent',
        // width: TINY_GROUP_VIDEO_WIDTH,
        // height: TINY_GROUP_VIDEO_HEIGHT,
        // padding: 4
    },
    fontStyle: {
        color: '#ffffff',
        fontSize: 14,
        textShadow: '0 1px 2px rgba(0, 0, 0, 0.88)',
        fontFamily: 'Noto Sans KR',
        marginTop:-1
    },
    bottomBox: {
        width: '100%',
        padding:'0 16px 16px',
        "& .MuiPaginationItem-root": {
            color: '#a4a4a4',
            fontSize: '13px',
            fontWeight: 'normal',
            margin: 0
        },
        "& .MuiPaginationItem-root:hover": {
            background: 'transparent',
        },
        "& .MuiPaginationItem-page.Mui-selected": {
            background: 'transparent',
            fontSize: '18px',
            fontWeight: 'bold'
        }
    },
    sendBtn:{
        border:'1px solid #c4c4c4',
        background:'#fff',
        color:'#f2441d',
        fontSize:14,
        fontWeight:'500',
        fontFamily: 'Noto Sans KR',
        boxShadow:'none',
        padding:'10px',
        borderRadius:6,
        "&:hover":{
            background:'#fff',
            boxShadow:'none',
        }
    },
    endBtn:{
        borderRadius: 6,
        background: '#e5e5e5',
        padding:'8px 35px',
        fontFamily: 'Noto Sans KR',
        fontSize: 14,
        color: '#808080',
        fontWeight:'500',
        boxShadow: 'none',
        "&:hover":{
            background: '#87878a',
            boxShadow: 'none',
            color:'#fff'
        }
    },
    dialogStyle:{
        "& .MuiDialogContent-root":{
            overflowY:'none'
        }
    },
    buttonStyle: {
        borderRadius: 6,
        // background: '#e2e2e2',
        fontSize: 14,
        // color: '#808080',
        // boxShadow: 'none',
        fontFamily: 'Noto Sans KR',
        // "&:hover": {
        //     background: theme.palette.primary.main,
        //     color: '#fff',
        // }
    },
    soundBar: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        },
        width: '50%',
        height: '50%',
        // transform: 'rotate(0.75turn) scaleX(5.5)',
        // WebkitTransform: 'rotate(0.75turn) scaleX(5.5)',
        // msTransform : 'rotate(0.75turn) scaleX(5.5)',
        // MozTransform : 'rotate(0.75turn) scaleX(5.5)',
        // OTransform : 'rotate(0.75turn) scaleX(5.5)',
    },

    soundVisual: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        },
        // width: '60%',
        // height: '50%',
        // transform: 'rotate(0.75turn) scaleX(5.5)',
        // WebkitTransform: 'rotate(0.75turn) scaleX(5.5)',
        // msTransform : 'rotate(0.75turn) scaleX(5.5)',
        // MozTransform : 'rotate(0.75turn) scaleX(5.5)',
        // OTransform : 'rotate(0.75turn) scaleX(5.5)',
    },

    box: {
        // width: '100%',
        "& .MuiLinearProgress-colorPrimary": {
            background: 'grey'
        },
        "& .MuiLinearProgress-root": {
            height: '13px'
        },
        "& .MuiLinearProgress-barColorPrimary": {
            background: 'rgb(44,196,44)',
        }
    },
    selectBox: {
        height: '37px',
        borderColor: '#cbcaca',
        "& .MuiSelect-select:focus": {
            backgroundColor: 'transparent',
        },
        "& .MuiSelect-selectMenu": {
            height: '1em'
        }
    },
    selectLabel:{
        fontFamily:'Noto Sans KR',
        fontSize:13,
        fontWeight:'500',
        color:'#333',
    },
    notJoinedAvatar: {
        marginRight: theme.spacing(1),
    },
    joinedAvatar: {
        // backgroundColor: theme.palette.primary.main,
        background: '#9cc779',
        marginRight: theme.spacing(1),
    },
    joinedTeacherAvatar: {
        // backgroundColor: theme.palette.primary.main,
        background: theme.palette.primary.main,
        // background: '#d93d1a',
        marginRight: theme.spacing(1),
    },

    myBell: {
        transformOrigin: 'top',
        animation: '$bell 2s infinite linear',
        color:'#f2441d'
    },
    "@keyframes bell": {
        "0%, 50%": {
            transform: 'rotate(0deg)'
        },
        "5%, 15%, 25%, 35%, 45%": {
            transform: 'rotate(13deg)'
        },
        "10%, 20%, 30%, 40%": {
            transform: 'rotate(-13deg)'
        }
    },
    circle: {
        width: '2em',
        height: '1.5em',
        position: 'absolute',
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '70%',
        borderColor: `transparent ${theme.palette.primary.main}`,
        animation: '$ring 2s infinite linear both'
    },
    second: {
        animationDelay: '.3s'
    },
    third: {
        animationDelay: '.7s'
    },
    "@keyframes ring": {
        "0%, 100%": {
            opacity: '0'
        },
        "1%": {
            opacity: '1'
        },
        "50%": {
            width: '4em',
            height: '3em',
            opacity: '0'
        }
    },
    iconBtn:{
        padding:0,
        "&:hover":{
            background:'transparent',
            cursor:'pointer'
        }
    }
});
const OWNER_TEXT_LENGTH = 12;
// const MEMBER_TEXT_LENGTH = 24;
const MEMBER_TEXT_LENGTH = 12;

const LogPrefix = '[TinyGroupDialog] ';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

@inject("roomStore", 'publishStore', "tinyGroupStore")
@observer
class TinyGroupDialog extends React.Component {
    constructor(props) {
        super(props);

        this.videoRefs = [];
        this.playRefs = [];
        this.avatarRefs = [];
        for(let i = 0; i < (TINY_GROUP_MAX_USER_COUNT + 1); i++) {
            this.videoRefs.push(React.createRef());
            this.playRefs.push(React.createRef());
            this.avatarRefs.push(React.createRef());
        }

        this.destinationGroupId = 0;
    }

    _manageStream(index, userId) {
        const memberInfo = this.props.tinyGroupStore.getRoomVideo(userId);
        if(memberInfo) {
            if(memberInfo.stream) {
                const videoRef = this.videoRefs[memberInfo.refIndex];
                if(!memberInfo.attached) {
                    if (videoRef && videoRef.current) {
                        const videoEl = videoRef.current;
                        videoEl.srcObject = memberInfo.stream;
                        this.props.tinyGroupStore.setMemberVideoAttached(userId, true);
                        console.log(LogPrefix, 'Video ref attach success', memberInfo.refIndex, userId, videoRef);
                    } else {
                        console.log(LogPrefix, 'Video ref current not exists', memberInfo.refIndex, userId, videoRef);
                    }
                }

                if(memberInfo.groupPlayClicked) {
                    this.handleVideoPlay(memberInfo.refIndex, userId);
                }
            } else if(!memberInfo.stream) {
                const videoRef = this.videoRefs[memberInfo.refIndex];
                if(videoRef && videoRef.current) {
                    const videoEl = videoRef.current;

                    videoEl.srcObject = undefined;
                }
            }
        }
    }

    componentDidMount() {
        RoomPresenter.getDevices(devices => this.props.tinyGroupStore.setDevices(devices));
        this.destinationGroupId = this.props.tinyGroupStore.tinyGroup.id;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {tinyGroup} = this.props.tinyGroupStore;
        // console.log(LogPrefix, `ManageStream`,tinyGroup.members)
        if(tinyGroup.id !== this.destinationGroupId) this.destinationGroupId = tinyGroup.id;
        tinyGroup.members.forEach((m, i) => {
            this._manageStream(i, m.user.userId)
        });
    }

    handleVideoPlay = (index, userId) => {
        const memberInfo = this.props.tinyGroupStore.getRoomVideo(userId);
        if(memberInfo) {
            if (memberInfo.stream && memberInfo.attached && !memberInfo.played) {
                const stream = memberInfo.stream;
                const videoRef = this.videoRefs[memberInfo.refIndex];
                if(videoRef && videoRef.current) {
                    const videoEl = videoRef.current;
                    videoEl.srcObject = stream;
                    videoEl.onloadedmetadata = () => {
                        videoEl.play();
                    }

                    if(!memberInfo.groupPlayClicked) this.props.tinyGroupStore.setMemberGroupPlayClicked(userId, true);
                    this.props.tinyGroupStore.setMemberVideoPlayed(userId, true);
                    console.log(LogPrefix, 'Video ref played success', memberInfo.refIndex, memberInfo.userId, videoRef);
                    this.props.tinyGroupStore.startSoundMeter(userId);
                } else {
                    console.log(LogPrefix, 'Video ref current not exists', memberInfo.refIndex, memberInfo.userId, videoRef);
                }

                console.log(LogPrefix, 'VideoRefs', this.videoRefs);
                console.log(LogPrefix, 'TinyGroupVideoMap', toJS(this.props.tinyGroupStore.tinyGroupVideoMap));
            }
        }
    }


    getShortenedText = (length, text) => text.length > length ? text.substr(0, length - 2) + '...' : text;

    getColumn = index => {
        const { classes, tinyGroupStore, roomStore } = this.props;

        const memberInfo = Object.values(tinyGroupStore.tinyGroupVideoMap).find(memberInfo => memberInfo.refIndex === index);
        const member = memberInfo ? tinyGroupStore.tinyGroup.members.find(m => String(m.id) === String(memberInfo.userId)) : {};
        const isGroupPlayed = memberInfo ? (memberInfo.groupPlayClicked || !memberInfo.attached) : false;
        const isAvatarPlayed = memberInfo ? (!memberInfo.attached && !memberInfo.played) : false;
        const isPlayed = memberInfo ? (memberInfo.attached && memberInfo.played) : false;
        const isMine = String(memberInfo.userId) === String(tinyGroupStore.userId);

        const groupPlayStyle = {
            display: isGroupPlayed ? 'none' : 'flex'
        }

        const avatarPlayStyle = {
            display: isAvatarPlayed ? 'flex' : 'none'
        }

        const videoStyle = {
            display: isPlayed ? 'flex' : 'none',
            width: '100%',
            height: '100%',
        };

        //console.log(LogPrefix, `GetColumn : index=${index}`, toJS(memberInfo), toJS(member));

        const videoBoxStyle = {
            width: (roomStore.isMobile && !roomStore.isMobileLandscape) ? TINY_GROUP_VIDEO_RESOLUTION_WIDTH + TINY_GROUP_VIDEO_BOX_PADDING_X + 2 : member.owner ?  TINY_GROUP_OWNER_VIDEO_WIDTH + TINY_GROUP_VIDEO_BOX_PADDING_X : TINY_GROUP_MEMBER_VIDEO_WIDTH + TINY_GROUP_VIDEO_BOX_PADDING_X,
            height: (roomStore.isMobile && !roomStore.isMobileLandscape) ? TINY_GROUP_VIDEO_RESOLUTION_HEIGHT + TINY_GROUP_VIDEO_BOX_PADDING_Y + 4 : member.owner ? TINY_GROUP_OWNER_VIDEO_HEIGHT + TINY_GROUP_VIDEO_BOX_PADDING_Y : TINY_GROUP_MEMBER_VIDEO_HEIGHT + TINY_GROUP_VIDEO_BOX_PADDING_Y + 4,
        }

        const videoResolutionStyle = {
            width: (roomStore.isMobile && !roomStore.isMobileLandscape) ? TINY_GROUP_VIDEO_RESOLUTION_WIDTH : member.owner ?  TINY_GROUP_OWNER_VIDEO_WIDTH : TINY_GROUP_MEMBER_VIDEO_WIDTH,
            height: (roomStore.isMobile && !roomStore.isMobileLandscape) ? TINY_GROUP_VIDEO_RESOLUTION_HEIGHT : member.owner ? TINY_GROUP_OWNER_VIDEO_HEIGHT : TINY_GROUP_MEMBER_VIDEO_HEIGHT,
        }

        return (
            <Box key={`tiny-group-layer-${index}`} border={1} borderColor={"white"} display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexGrow={0} className={classes.videoBoxStyle} style={videoBoxStyle}>

                <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" style={videoStyle}>
                    {isMine ?
                        <video playsInline muted ref={this.videoRefs[index]} id={`tiny-group-video-${index}`} className={classes.videoStyle} style={videoResolutionStyle}/>
                        :
                        <video playsInline ref={this.videoRefs[index]} id={`tiny-group-video-${index}`} className={classes.videoStyle}style={videoResolutionStyle}/>
                    }
                </Box>

                <Box ref={this.playRefs[index]} flexDirection="column" justifyContent="center" alignItems="stretch" style={groupPlayStyle}>
                    <IconButton
                        onClick={e => this.handleVideoPlay(index, member.id)}
                    >
                        <GroupPlayIcon />
                    </IconButton>
                    {/*{memberInfo.stream && <Typography style={{fontSize: '1rem', color: '#fff2ac'}}>▶︎ 를 선택하세요.</Typography>}*/}
                </Box>

                <Box ref={this.avatarRefs[index]} flexDirection="column" justifyContent="center" alignItems="stretch" style={avatarPlayStyle} pb={1}>
                    {/*<Avatar className={member.joined ? memberInfo.isOwner ? classes.joinedTeacherAvatar : classes.joinedAvatar : classes.notJoinedAvatar}>*/}
                    {/*    {member.joined && member.avatar ?*/}
                    {/*        <img src={member.avatar} style={{width: 40, height: 40}} alt={""}/>*/}
                    {/*        :*/}
                    {/*        <PersonIcon/>*/}
                    {/*    }*/}
                    {/*</Avatar>*/}

                    <Avatar className={member.joined ? classes.joinedAvatar : classes.notJoinedAvatar}>
                        <PersonIcon/>
                    </Avatar>
                </Box>

                <Box display={isPlayed ? "-webkit-box" : "flex"} flexDirection="row" justifyContent="center" alignItems="center" >
                    <Typography className={classes.fontStyle}> {member ? member.user.userName : ""} </Typography>

                    {/*<Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" style={{...videoStyle, width: "50%"}}>*/}
                    {/*    <LinearProgress className={classes.soundBar} variant="determinate" value={memberInfo.soundMeterValue} />*/}
                    {/*</Box>*/}

                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" style={{...videoStyle, width: '60%', overflow: 'hidden'}}>
                        <SoundMeterVisualizer className={classes.soundVisual} volume={memberInfo.soundMeterValue} />
                    </Box>
                </Box>
            </Box>
        )
    }

    getGroupMove = () => {
        const { classes, roomStore, publishStore, tinyGroupStore } = this.props;
        const isNarrowStateMobile = (tinyGroupStore.tinyGroup.members.length === 2 || tinyGroupStore.tinyGroup.members.length === 3 || tinyGroupStore.tinyGroup.members.length === 5) || (roomStore.isMobile && roomStore.isMobileLandscape);

        return (
            <Box className={classes.box} display="flex" flexDirection="row" justifyContent="flex-start" alignItems="stretch"  flexGrow={1} style={(roomStore.isMobile && !roomStore.isMobileLandscape) ? {width:'80%',marginBottom:16} : (roomStore.isPublisher && !isNarrowStateMobile) ? {width:'32%'} : {width:'49%'}} >
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="outlined-tiny-group-change-native" className={classes.selectLabel}>모둠 이동</InputLabel>
                    <Select
                        // native
                        fullWidth
                        value={this.destinationGroupId}
                        label="TGroupC"
                        inputProps={{
                            name: 'TGroupC"',
                            id: 'outlined-tiny-group-change-native',
                        }}
                        className={classes.selectBox}
                        disabled={publishStore.isPublishButtonClicked}
                        onChange={(e) => {
                            const destinationGroupId = e.target.value;
                            publishStore.setPublishButtonClicked(true);
                            if (this.destinationGroupId !== destinationGroupId) {
                                this.videoRefs.forEach((ref, i) => {
                                    if (i !== 0 && ref.current) {
                                        ref.current.srcObject = undefined;
                                    }
                                });
                                this.destinationGroupId = destinationGroupId;
                                tinyGroupStore.requestChangeOwnerGroup(destinationGroupId);

                                if (this.props.onSendChangeOwnerParticipationGroup) {
                                    this.props.onSendChangeOwnerParticipationGroup();
                                }
                                setTimeout(() => publishStore.setPublishButtonClicked(false), PUBLISH_BUTTON_TIMEOUT);
                            }
                        }}
                    >
                        {tinyGroupStore.tinyGroups.map(tg =>
                            <MenuItem key={`tiny-group-change-${tg.id}`} value={tg.id}>
                                <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                    {(tg.id === this.destinationGroupId) ?
                                        <NotificationsRoundedIcon color={'primary'} fontSize={'small'}/>
                                        :
                                        <NotificationsRoundedIcon color={(tg.isCalled) ? 'primary' : 'disabled'} fontSize={'small'}/>
                                    }
                                    <Box pl={1}>
                                        {tg.name ? tg.name : '알수없는 그룹'}
                                    </Box>
                                </Box>
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Box>
        )
    };


    render() {
        const { classes, roomStore, publishStore, tinyGroupStore } = this.props;
        const wrap = roomStore.isMobile ? "wrap" : "nowrap";
        let index = 0;
        const isNarrowState = (tinyGroupStore.tinyGroup.members.length === 2 || tinyGroupStore.tinyGroup.members.length === 3 || tinyGroupStore.tinyGroup.members.length === 5) || (roomStore.isMobile && roomStore.isMobileLandscape);
        return (
            <>
                <Dialog
                    open={tinyGroupStore.isTinyGroupDialogOpen}
                    onClose={() => tinyGroupStore.setTinyGroupDialogOpen(false)}
                    disableBackdropClick
                    disableEscapeKeyDown
                    // fullWidth
                    maxWidth={"md"}
                    scroll={'body'}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-title"
                    className={classes.dialogStyle}
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        <Box display="flex" flexDirection={(roomStore.isMobile &&!roomStore.isMobileLandscape) ? "column" : "row" } justifyContent="space-between" >
                            <Box style={{width:'34%',textAlign:'left'}}>
                                <Typography className={classes.dialogTitle}>{tinyGroupStore.tinyGroup.name}</Typography>
                            </Box>

                            {(roomStore.isPublisher && tinyGroupStore.tinyGroups.find(tg => tg.isCalled))&&
                                <Box display='flex' flexDirection="row" justifyContent="center" alignItems="center" style={(roomStore.isMobile &&!roomStore.isMobileLandscape) ? {width:'100%'} : {width:'33%'}}>>
                                    <NotificationsRoundedIcon className={classes.myBell} fontSize={'large'} color={'primary'} />

                                    <div className={clsx(classes.circle, classes.first)} />
                                    <div className={clsx(classes.circle, classes.second)} />
                                    <div className={clsx(classes.circle, classes.third)} />
                                </Box>
                            }

                            {roomStore.isPublisher ?
                                <Box style={(roomStore.isMobile &&!roomStore.isMobileLandscape) ? {width:'100%',marginTop:16} : {width:'34%'}} display='flex' justifyContent={(roomStore.isMobile &&!roomStore.isMobileLandscape) ? "center" : "flex-end"}>
                                    <Button variant="contained" color="primary"
                                            onClick={() => tinyGroupStore.setNotifyDialogOpen(true)}
                                            className={classes.sendBtn}
                                    >
                                        <NotificationsActiveRoundedIcon style={{color:'#f2441d',marginRight:5}}/>알림 보내기
                                    </Button>
                                </Box>
                                :
                                <Button variant="contained" color="primary"
                                        disabled={(tinyGroupStore.tinyGroup.isOwnerExist || publishStore.isPublishButtonClicked)}
                                        onClick={() => {
                                            console.log(LogPrefix, 'CallOwner clicked');
                                            roomStore.setPublishButtonClicked(true);

                                            if(this.props.onSendCallOwner) {
                                                this.props.onSendCallOwner();
                                            }
                                            setTimeout(() => publishStore.setPublishButtonClicked(false), PUBLISH_BUTTON_TIMEOUT);
                                        }}
                                        onTouchEnd={() => {
                                            console.log(LogPrefix, 'CallOwner TouchEnd');
                                            publishStore.setPublishButtonClicked(true);

                                            if(this.props.onSendCallOwner) {
                                                this.props.onSendCallOwner();
                                            }
                                            setTimeout(() => publishStore.setPublishButtonClicked(false), PUBLISH_BUTTON_TIMEOUT);
                                        }}
                                        className={classes.sendBtn}
                                        style={(roomStore.isMobile &&!roomStore.isMobileLandscape) ? {width:127, margin:'16px auto 0'} : {width:127}}
                                >
                                    <NotificationsActiveRoundedIcon style={(tinyGroupStore.tinyGroup.isOwnerExist || publishStore.isPublishButtonClicked)?{color:'#b1b1b1',marginRight:5}:{color:'#f2441d',marginRight:5}}/>도움요청
                                </Button>
                            }
                        </Box>
                    </DialogTitle>
                    <DialogContent style={roomStore.isMobile && !roomStore.isMobileLandscape ? { padding:'8px 0'} : {padding:'8px 24px'}}>
                        <Box display="flex" flexWrap={wrap} flexDirection="column" justifyContent="center" alignItems="stretch" style={{width: '100%', height: '100%'}}>
                            <Box key={`tiny-group-layer-0`} display="flex" flexWrap={wrap} flexDirection="row" justifyContent="center" alignItems="center" >
                                {tinyGroupStore.tinyGroup.members.length > index && this.getColumn(index++)}
                            </Box>
                            {roomStore.isMobileLandscape ?
                                <React.Fragment>
                                    <Box key={`tiny-group-layer-1`} display="flex" flexWrap={wrap} flexDirection="row" justifyContent="center" alignItems="center" >
                                        {tinyGroupStore.tinyGroup.members.length > index && this.getColumn(index++)}
                                        {tinyGroupStore.tinyGroup.members.length > index && this.getColumn(index++)}
                                    </Box>

                                    <Box key={`tiny-group-layer-2`} display="flex" flexWrap={wrap} flexDirection="row" justifyContent="center" alignItems="center" >
                                        {tinyGroupStore.tinyGroup.members.length > index && this.getColumn(index++)}
                                        {tinyGroupStore.tinyGroup.members.length > index && this.getColumn(index++)}
                                    </Box>

                                    <Box key={`tiny-group-layer-3`} display="flex" flexWrap={wrap} flexDirection="row" justifyContent="center" alignItems="center" >
                                        {tinyGroupStore.tinyGroup.members.length > index && this.getColumn(index++)}
                                        {tinyGroupStore.tinyGroup.members.length > index && this.getColumn(index++)}
                                    </Box>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <Box key={`tiny-group-layer-1`} display="flex" flexWrap={wrap} flexDirection="row" justifyContent="center" alignItems="center" >
                                        {tinyGroupStore.tinyGroup.members.length > index && this.getColumn(index++)}
                                        {tinyGroupStore.tinyGroup.members.length > index && this.getColumn(index++)}
                                        {(tinyGroupStore.tinyGroup.members.length > index && !isNarrowState) && this.getColumn(index++)}
                                    </Box>

                                    <Box key={`tiny-group-layer-2`} display="flex" flexWrap={wrap} flexDirection="row" justifyContent="center" alignItems="center" >
                                        {tinyGroupStore.tinyGroup.members.length > index && this.getColumn(index++)}
                                        {tinyGroupStore.tinyGroup.members.length > index && this.getColumn(index++)}
                                        {tinyGroupStore.tinyGroup.members.length > index && this.getColumn(index++)}
                                    </Box>
                                </React.Fragment>
                            }

                            <Box display="flex" flexDirection={(roomStore.isMobile && !roomStore.isMobileLandscape) ? "column" : "row"} justifyContent="space-between" alignItems="center" pt={2} pb={roomStore.isPublisher ? 0 : 2}>
                                <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" flexGrow={1} style={(roomStore.isMobile && !roomStore.isMobileLandscape) ? {width:'80%',marginBottom:16} : (roomStore.isPublisher && !isNarrowState) ? {width:'32%',marginRight:10} : {width:'49%',marginRight:10}} >
                                    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                        <Tooltip title={tinyGroupStore.isVideoOff ? "비디오 켜기" : "비디오 끄기"}>
                                            <div style={{width:34}}>
                                                <IconButton disabled={publishStore.isPublishButtonClicked} className={classes.iconBtn} onClick={() => {
                                                    publishStore.setPublishButtonClicked(true);
                                                    publishStore.changeVideoOff();
                                                    tinyGroupStore.changeVideoOff();
                                                    setTimeout(() => publishStore.setPublishButtonClicked(false), PUBLISH_BUTTON_TIMEOUT);
                                                }}>
                                                    {(tinyGroupStore.isVideoOff || publishStore.isPublishButtonClicked) ? <VideocamRoundedIconOff color="disabled" /> : <VideocamRoundedIcon />}
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </Box>

                                    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" flexGrow={1} >
                                        <FormControl variant="outlined" style={{'flexGrow': '1'}} fullWidth>
                                            <InputLabel htmlFor="outlined-video-device-native" className={classes.selectLabel}>비디오 장치</InputLabel>
                                            <Select
                                                native
                                                fullWidth
                                                value={tinyGroupStore.isVideoOff ? "videoOff" : tinyGroupStore.selectedVideoDeviceId}
                                                label="VideoDev"
                                                inputProps={{
                                                    name: 'VideoDev',
                                                    id: 'outlined-video-device-native',
                                                }}
                                                disabled={publishStore.isPublishButtonClicked}
                                                onChange={e => {
                                                    publishStore.setPublishButtonClicked(true);
                                                    publishStore.selectVideoDeviceId(e.target.value);
                                                    tinyGroupStore.selectVideoDeviceId(e.target.value);
                                                    setTimeout(() => publishStore.setPublishButtonClicked(false), PUBLISH_BUTTON_TIMEOUT);
                                                }}
                                                className={classes.selectBox}
                                            >
                                                {tinyGroupStore.videoDevices.map(device =>
                                                    <option key={`video-device-${device.deviceId}`} value={device.deviceId}>
                                                        {device.label ? this.getShortenedText(roomStore.isPublisher ? OWNER_TEXT_LENGTH : MEMBER_TEXT_LENGTH, device.label) : '알수없는 장치'}
                                                    </option>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>

                                <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" flexGrow={1} style={(roomStore.isMobile &&!roomStore.isMobileLandscape) ? {width:'80%',marginBottom:16} : (roomStore.isPublisher && !isNarrowState) ? {width:'32%',marginRight:10} : {width:'49%'}}>
                                    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                        <Tooltip title={tinyGroupStore.isAudioOff ? "오디오 켜기" : "오디오 끄기"}>
                                            <div style={{width:28}}>
                                                <IconButton disabled={publishStore.isPublishButtonClicked} className={classes.iconBtn} onClick={() => {
                                                    publishStore.setPublishButtonClicked(true);
                                                    publishStore.changeAudioOff();
                                                    tinyGroupStore.changeAudioOff();
                                                    setTimeout(() => publishStore.setPublishButtonClicked(false), PUBLISH_BUTTON_TIMEOUT);
                                                }}>
                                                    {(tinyGroupStore.isAudioOff || publishStore.isPublishButtonClicked) ?  <SettingsVoiceIconOff /> : <SettingsVoiceIcon />}
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    </Box>

                                    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" flexGrow={1} >
                                        <FormControl variant="outlined" style={{'flexGrow': '1'}} fullWidth>
                                            <InputLabel htmlFor="outlined-audio-device-native" className={classes.selectLabel}>오디오 장치</InputLabel>
                                            <Select
                                                native
                                                fullWidth
                                                value={tinyGroupStore.isAudioOff ? "audioOff" : tinyGroupStore.selectedAudioDeviceId}
                                                label="AudioDev"
                                                inputProps={{
                                                    name: 'AudioDev',
                                                    id: 'outlined-audio-device-native',
                                                }}
                                                disabled={publishStore.isPublishButtonClicked}
                                                onChange={e => {
                                                    publishStore.setPublishButtonClicked(true);
                                                    publishStore.selectAudioDeviceId(e.target.value);
                                                    tinyGroupStore.selectAudioDeviceId(e.target.value);
                                                    setTimeout(() => publishStore.setPublishButtonClicked(false), PUBLISH_BUTTON_TIMEOUT);
                                                }}
                                                className={classes.selectBox}
                                            >
                                                {tinyGroupStore.audioDevices.map(device =>
                                                    <option key={`audio-device-${device.deviceId}`} value={device.deviceId}>
                                                        {device.label ? this.getShortenedText(roomStore.isPublisher ? OWNER_TEXT_LENGTH : MEMBER_TEXT_LENGTH, device.label) : '알수없는 장치'}
                                                    </option>
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                </Box>

                                {((roomStore.isPublisher && !isNarrowState) || (roomStore.isPublisher && roomStore.isMobile && !roomStore.isMobileLandscape)) && this.getGroupMove()}
                            </Box>
                        </Box>

                    </DialogContent>

                    {roomStore.isPublisher &&
                    <DialogActions style={{display:'flex', flexDirection:"column"}}>
                        <Box display="flex" justifyContent="center" flexDirection="row" className={classes.bottomBox}>
                            {isNarrowState ? (roomStore.isPublisher && roomStore.isMobile && !roomStore.isMobileLandscape) ? null : this.getGroupMove() : null }
                            <Button variant="contained" color={'primary'}
                                    disabled={tinyGroupStore.isTinyGroupClosedButtonDisabled}
                                    onClick={() => {
                                        if (this.props.onSendEndMessage) {
                                            this.props.onSendEndMessage();
                                        }

                                        tinyGroupStore.setTinyGroupClosedButtonDisabled(true);
                                        tinyGroupStore.onClose();

                                        if(this.props.onClose) {
                                            this.props.onClose();
                                        }
                                    }}
                                    className={classes.endBtn}
                                    style={(roomStore.isPublisher && !isNarrowState) ? {marginLeft:0} : {marginLeft:8}}
                            >
                                모둠 종료
                            </Button>

                        </Box>
                    </DialogActions>
                    }
                </Dialog>

                <CommonDialog maxWidth="xs"
                              open={tinyGroupStore.isNotifyDialogOpen}
                              title={<Typography className={classes.dialogTitle}>모둠 알람</Typography>}
                              contentComponent={
                                  <FormControl fullWidth>
                                      {roomStore.isPublisher && <InputLabel htmlFor="tiny-group-notify-message">알람 내용을 입력해 주세요.</InputLabel>}
                                      <Input
                                          id="tiny-group-notify-message"
                                          startAdornment={
                                              <InputAdornment position="start">
                                                  <ErrorOutlineIcon />
                                              </InputAdornment>
                                          }
                                          value={tinyGroupStore.notifyMessage}
                                          onChange={e => tinyGroupStore.setNotifyMessage(e.target.value)}
                                          style={{paddingBottom:5,fontFamily:'Noto Sans KR',fontSize:'14px',color:'#808080'}}
                                          disabled={!roomStore.isPublisher}
                                      />
                                  </FormControl>
                              }

                              onClose={roomStore.isPublisher ? () => {
                                  tinyGroupStore.setNotifyMessage('');
                                  tinyGroupStore.setNotifyDialogOpen(false);
                              } : undefined}

                              okText={roomStore.isPublisher ? "메세지 전송" : "확인"}
                              onOk={() => {
                                  if(roomStore.isPublisher && this.props.onSendNotifyMessage) {
                                      this.props.onSendNotifyMessage();
                                  }

                                  tinyGroupStore.setNotifyMessage('');
                                  tinyGroupStore.setNotifyDialogOpen(false);

                                  if(!roomStore.isPublisher && tinyGroupStore.notifyMessageTimoutId) {
                                      window.clearTimeout(tinyGroupStore.notifyMessageTimoutId);
                                  }
                              }}
                />
            </>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (TinyGroupDialog)));
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import moment from "moment";


const styles = (theme) => ({
    dialog: {
    },
    dialogTitle: {
    },
    dialogContent: {
    },
    contentHeaderGrid: {
        textAlign: 'right',
    },
    grow: {
        flexGrow: 1,
    },
});

const DateFormat = "MM.DD HH:mm";

@inject('channelAttendanceStore')
@observer
class ChannelAttendanceDialog extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.channelAttendanceStore.isLoadFailed) {
            this.props.enqueueSnackbar('참여 정보 조회에 실패하였습니다.', {
                variant: 'error'
            });

            this.props.channelAttendanceStore.closeAttendanceDialog();
        }
    }

    handleClose = () => {
        this.props.channelAttendanceStore.closeAttendanceDialog();
    }

    handleChangePagingFilter = (event) => {
        const filter = event.target.value;

        this.props.channelAttendanceStore.changePagingFilter(filter);
    }

    handleChangePagingPage = (event, newPage) => {
        this.props.channelAttendanceStore.changePagingPage(newPage);
    }

    handleChangePagingRowsPerPage = (event) => {
        const newValue = parseInt(event.target.value, 10);

        this.props.channelAttendanceStore.changePagingRowsPerPage(newValue);
    }

    render() {
        const {classes} = this.props;
        const {isOpenAttendanceDialog, paging} = this.props.channelAttendanceStore;

        return (
            <Dialog disableBackdropClick fullWidth
                    maxWidth="md"
                    className={classes.dialog}
                    open={isOpenAttendanceDialog}
                    onClose={this.handleClose}
                    aria-labelledby="channel-attendance-dialog-title" >
                <DialogTitle id="channel-attendance-dialog-title" className={classes.dialogTitle}>
                    강의 참여 기록
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Grid container>
                        <Grid item xs={12} className={classes.contentHeaderGrid}>
                            <FormControl component="fieldset">
                                <RadioGroup row value={paging.filter} onChange={this.handleChangePagingFilter}>
                                    <FormControlLabel value="all" control={<Radio />} label="모두" />
                                    <FormControlLabel value="attendance" control={<Radio />} label="참석" />
                                    <FormControlLabel value="absence" control={<Radio />} label="미참석" />
                                    {/*<FormControlLabel value="nomember" control={<Radio />} label="비회원" />*/}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {/*<TableCell align="center" style={{width: '25%'}}><Typography variant="subtitle2">이메일</Typography></TableCell>*/}
                                        <TableCell align="center" style={{width: '30%'}}><Typography variant="subtitle2">이름</Typography></TableCell>
                                        <TableCell align="center" style={{width: '50%'}}><Typography variant="subtitle2">참여시간</Typography></TableCell>
                                        <TableCell align="center" style={{width: '20%'}}><Typography variant="subtitle2">출석체크수</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paging.list.map(attendance =>
                                        <TableRow key={`attendance-table-row${attendance.email}`}>
                                            {/*<TableCell align="left" component="th" scope="row">*/}
                                            {/*    <Typography variant="body2">{attendance.userId < 5000000000 ? attendance.email : ''}</Typography>*/}
                                            {/*</TableCell>*/}
                                            <TableCell align="center">
                                                <Typography variant="body2">{attendance.userName}</Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="body2" color={attendance.attendance ? 'textPrimary' : 'primary'}>
                                                    {attendance.attendance ? moment(attendance.startDateTime).format(DateFormat) + ' ~ ' + moment(attendance.finishDateTime).format(DateFormat) : (attendance.joined ? '미참여' : '비회원')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="body2">{attendance.authSuccessCount}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 25, 30, 35, 40, 45, 50]}
                        component="div"
                        count={paging.totalCount}
                        rowsPerPage={paging.rowsPerPage}
                        page={paging.page}
                        labelRowsPerPage=""
                        labelDisplayedRows={({from, to, count}) => `총 ${count}개 중 ${from} - ${to}`}
                        SelectProps={{renderValue: (value) => value + ' 개씩 보기'}}
                        onChangePage={this.handleChangePagingPage}
                        onChangeRowsPerPage={this.handleChangePagingRowsPerPage}
                    />
                    <div className={classes.grow} />
                    <Button onClick={this.handleClose}
                            style={{
                                borderRadius:'25px'
                            }}>
                        확인
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (ChannelAttendanceDialog)));
import {action, computed, flow, observable, toJS} from "mobx";
import axios from "axios";
import _ from "lodash";

const DialogState = {
    Closed: 'Closed',
    Loading: 'Loading',
    Loaded: 'Loaded',
    LoadFailed: 'LoadFailed',
    Adding: 'Adding',
    Added: 'Added',
    AddFailed: 'AddFailed',
    Deleting: 'Deleting',
    Deleted: 'Deleted',
    DeleteFailed: 'DeleteFailed',
}

export default class ShareAddStore {
    @observable user = {email: ''};
    @observable groupId = '';
    @observable dialogState = DialogState.Closed;
    @observable shareList = [];
    @observable userFilter = '';
    @observable userHelperText = '';
    @observable userList = [];


    @action clearDialogState = (open) => {
        if(open) {
            this.dialogState = DialogState.Loaded;
        } else {
            this.dialogState = DialogState.Closed;
        }
    }

    @action changeUserFilter = (filter) => {
        this.userFilter = filter;
    }


    @action removeUserFromShareList = (email) => {
        const shares = toJS(this.shareList);

        let removeIndex = -1;
        for(let i=0; i<shares.length; i++) {
            const share = shares[i];

            if(share.email === email) {
                removeIndex = i;
                break;
            }
        }

        if(removeIndex >= 0) {
            shares.splice(removeIndex, 1);
            this.shareList = shares;
        }
    }

    @computed get isDialogOpen() {
        return this.dialogState !== DialogState.Closed;
    }

    @computed get isAdding() {
        return this.dialogState === DialogState.Adding;
    }

    @computed get isAddFailed() {
        return this.dialogState === DialogState.AddFailed;
    }

    @computed get isDeleting() {
        return this.dialogState === DialogState.Deleting;
    }

    @computed get isDeleteFailed() {
        return this.dialogState === DialogState.DeleteFailed;
    }

    @computed get filteredUserList() {
        const userEmail = toJS(this.user.email);
        const shareList = toJS(this.shareList);
        const filteredUserList = _.filter(toJS(this.userList), (user) => {
            const userInShare = _.find(shareList, (share) => share.email === user.email);

            if(user.email === userEmail) {
                return false;
            }

            if(userInShare) {
                return false;
            } else {
                if(this.userFilter) {
                    return user.email.startsWith(this.userFilter);
                } else {
                    return true;
                }
            }
        });

        return filteredUserList;
    }

    openDialog = flow(function* openDialog(groupId, userId) {
        this.dialogState = DialogState.Loading;
        this.groupId = groupId;

        try {
            const response = yield axios.get(`/api/v1/users/user-info?user-id=${userId}`);
            const user = response.data;
            this.user = user;

            const userResponse = yield axios.get(`/api/v1/users/emails`);
            const users = userResponse.data;
            this.userList = users;

            const shareResponse = yield axios.get(`/api/v1/groups/shares?group-id=${groupId}`);
            const shares = shareResponse.data;
            this.shareList = shares;

            this.dialogState = DialogState.Loaded;
        } catch(error) {
            this.dialogState = DialogState.LoadFailed;
        }
    })

    addUserToShare = flow(function* addUserToShare() {
        this.dialogState = DialogState.Adding;
        this.userHelperText = '';

        try {
            const userEamil = this.userFilter;
            const usersResponse = yield axios.get(`/api/v1/users/signupcheck?email=${userEamil}`);

            if((usersResponse.data) && (usersResponse.data.result)) {
                const param = {
                    groupId: this.groupId,
                    groupShare: this.shareList.map((share) => ({email: share.email, comment: ''})),
                };
                param.groupShare.push({email: userEamil, comment: ''});

                yield axios.post('/api/v1/groups/shares', param);

                const groupId = this.groupId;
                const shareResponse = yield axios.get(`/api/v1/groups/shares?group-id=${groupId}`);
                const shares = shareResponse.data;

                this.userFilter = '';
                this.userHelperText = '';
                this.shareList = shares;
                this.dialogState = DialogState.Added;
            } else {
                this.userHelperText = '존재하지 않는 계정입니다.';

                this.dialogState = DialogState.Added;
            }
        } catch(error) {
            this.dialogState = DialogState.AddFailed;
        }

    });

    removeUserFromShare = flow(function* (email) {
        this.dialogState = DialogState.Deleting;

        try {
            const shares = toJS(this.shareList);

            let removeIndex = -1;
            for(let i=0; i<shares.length; i++) {
                const share = shares[i];

                if(share.email === email) {
                    removeIndex = i;
                    break;
                }
            }

            if(removeIndex >= 0) {
                shares.splice(removeIndex, 1);
            }

            const param = {
                groupId: this.groupId,
                groupShare: shares,
            };

            yield axios.post('/api/v1/groups/shares', param);

            const groupId = this.groupId;
            const shareResponse = yield axios.get(`/api/v1/groups/shares?group-id=${groupId}`);
            const shareList = shareResponse.data;

            this.shareList = shareList;
            this.dialogState = DialogState.Deleted;
        } catch (error) {
            this.dialogState = DialogState.DeleteFailed;
        }
    })

    // update = flow(function* update() {
    //     this.dialogState = DialogState.Adding;
    //
    //     try {
    //         const param = {
    //             groupId: this.groupId,
    //             groupShare: this.shareList.map((share) => ({email: share.email, comment: ''})),
    //         };
    //         yield axios.post('/api/v1/groups/shares', param);
    //
    //         this.dialogState = DialogState.Added;
    //     } catch(error) {
    //         this.dialogState = DialogState.AddFailed;
    //     }
    // })
}
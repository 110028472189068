import React from "react";
import FaqTemplates from "./FaqTemplates";
import CustomizedTab from "./CustomizedTab";
import ExpansionPanels from "./ExpansionPanels";
import {singInFaqData, broadcastFaqData, groupFaqData} from "./FaqData";

export default function Faq() {
    const [searchWord, setSearchWord] = React.useState("");
    const [value, setValue] = React.useState(0);
    return (
        <FaqTemplates
            searchWord={searchWord}
            setSearchWord={setSearchWord}
            tab={<CustomizedTab value={value} setValue={setValue} />}
            expansionPanel={<ExpansionPanels data={value === 0 ? singInFaqData.filter(data => searchWord.length <= 0 ? true : data.title.includes(searchWord) || data.content.includes(searchWord))
                                                                : value === 1 ? broadcastFaqData.filter(data => searchWord.length <= 0 ? true : data.title.includes(searchWord) || data.content.includes(searchWord))
                                                                              : groupFaqData.filter(data => searchWord.length <= 0 ? true : data.title.includes(searchWord) || data.content.includes(searchWord))
                                                    } />}
        />
    );
}
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {Button, CircularProgress, Container, Grid, TextField, Typography, withStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Footer from "../../components/Footer";
import * as params from "../../common/Params";

const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
    mainLogo: {
        width: 119,
        height: 91,
        paddingTop: theme.spacing(7),
    },
    mainTitle: {
        fontSize: 38,
        color: '#333333',
        paddingTop: theme.spacing(6),
    },
    mainHeader: {
        fontSize: 16,
        color: '#333333',
        paddingTop: theme.spacing(5),
    },
    mainBody: {
        fontSize: 14,
        color: '#b7b7b7',
        paddingTop: theme.spacing(1),
    },
    mainButton: {
        borderRadius: 24,
        fontSize: 16,
        width: 200,
        height: 48,
        marginTop: theme.spacing(4),
    },
    passwordText: {
        width: 320,
    },
});

@inject('passCertificationStore')
@observer
class PasswordCertification extends React.Component {
    componentDidMount() {
        const param = params.getURLParams(this.props.location.search);

        if(param.email && param.authKey) {
            this.props.passCertificationStore.certificate(param.email, param.authKey);
        } else {
            this.props.passCertificationStore.setCertificationFailed();
        }
    }

    handleChangePassword = (event) => {
        this.props.passCertificationStore.changePassword(event.target.value);
    }

    handleChangePasswordConfirm = (event) => {
        this.props.passCertificationStore.changePasswordConfirm(event.target.value);
    }

    handleClickChangePassword = () => {
        this.props.passCertificationStore.updatePassword();
    }

    handleClickSignIn = () => {
        this.props.history.push('/');
    }

    handleClickFindPassword = () => {
        this.props.history.push('/findpassword');
    }


    render() {
        const {classes} = this.props;
        const {isCertificated, isChanged, isFailed, isValidPassword, isPasswordConfirmed, canChangePassword, isChanging, password, passwordConfirm} = this.props.passCertificationStore;


        return (
            <React.Fragment>
                <Container component="main" maxWidth="lg" className={classes.mainContainer}>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.mainContent} >
                        <img className={classes.mainLogo} src="/images/logo-verti.png" alt="ONTHELIVE" />

                        {isCertificated ?
                            <div>
                                <Typography className={classes.mainTitle}>비밀번호 변경</Typography>
                                <Typography className={classes.mainHeader}>변경할 비밀번호를 입력하세요.</Typography>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField id="find-pass"
                                                   margin="normal"
                                                   type="password"
                                                   className={classes.passwordText}
                                                   label={<Typography variant="subtitle1" >비밀번호</Typography>}
                                                   value={password}
                                                   onChange={this.handleChangePassword}
                                                   InputLabelProps={{shrink: true}}
                                                   helperText={isValidPassword ? '' : '최소 4 글자 이상을 입력해 주세요.'}
                                                   autoFocus />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField id="find-pass"
                                                   margin="normal"
                                                   type="password"
                                                   className={classes.passwordText}
                                                   label={<Typography variant="subtitle1" >비밀번호 확인</Typography>}
                                                   value={passwordConfirm}
                                                   onChange={this.handleChangePasswordConfirm}
                                                   InputLabelProps={{shrink: true}}
                                                   helperText={isPasswordConfirmed ? '' : '패스워드가 일치하지 않습니다.'}
                                        />
                                    </Grid>
                                </Grid>

                                <Button color="primary" variant="contained"
                                        className={classes.mainButton}
                                        disabled={(!canChangePassword) || isChanging}
                                        onClick={() => this.handleClickChangePassword()}>
                                    {isChanging ? <CircularProgress size={16} /> : '변경 하기'}
                                </Button>
                            </div>
                            :
                            ''
                        }

                        {isChanged ?
                            <div>
                                <Typography className={classes.mainTitle}>비밀번호 변경 성공</Typography>
                                <Typography className={classes.mainHeader}>비밀번호를 변경 하였습니다.</Typography>
                                <Typography className={classes.mainBody}>변경하신 비밀번호를 사용하여 로그인 하여 주세요.</Typography>
                                <Button color="primary" variant="contained"
                                        className={classes.mainButton}
                                        onClick={() => this.handleClickSignIn()}>
                                    로그인 하기
                                </Button>
                            </div>
                            :
                            ''
                        }

                        {isFailed ?
                            <div>
                                <Typography className={classes.mainTitle}>비밀번호 변경 실패</Typography>
                                <Typography className={classes.mainHeader}>비밀번호 변경이 실패하였습니다.</Typography>
                                <Typography className={classes.mainBody}>비밀번호 변경을 다시 시도하여 주세요. 계속해서 문제가 발생하는 경우에는 관리자에게 문의하여 주세요.</Typography>
                                <Button color="primary" variant="contained"
                                        className={classes.mainButton}
                                        onClick={() => this.handleClickFindPassword()}>
                                    회원가입 하기
                                </Button>
                            </div>
                            :
                            ''
                        }
                    </div>
                </Container>

                <Footer />
            </React.Fragment>
        );
    }
}

export default withSnackbar(withRouter(withStyles(styles) (PasswordCertification)));
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogContent, Typography} from "@material-ui/core";

export default function BroadcastLookAroundErrorDialog(props) {
    const { open, handleClose} = props;

    const handleConfirm = () => {
        handleClose();
        //history.push("/channel/list");
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} disableBackdropClick={true} disableEscapeKeyDown={true} maxWidth={'xs'} >
            <DialogContent>
                <Typography variant="subtitle1" component="h2">
                    발표, 질문 중에는 둘러보기가 불가능 합니다.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button style={{
                            color: '#d64e4e'
                        }}
                        onClick={handleConfirm}
                >
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    );
}
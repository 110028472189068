import React, {Fragment, useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import {QuizType} from "../../../stores/QuizStore";

const QuizResultExpansionPanels = withStyles({
    root: {
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        // backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        display: "inline-block",
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        // backgroundColor: '#fafafa',
        backgroundColor: '#ffffff',
    },
}))(MuiExpansionPanelDetails);

export default function CustomizedExpansionPanels(props) {
    const [expanded, setExpanded] = React.useState('panel1');
    const handleChange = (panel, quiz, index) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        handleQuizData(quiz, index);
    };

    const handleQuizData = (quiz, index) => {
        if(quiz.quizType === QuizType.SINGLE_CHOICE || quiz.quizType === QuizType.MULTIPLE_CHOICE) {
            const barChartData = props.makeQuizChartData(quiz);
            if(barChartData.length > 1) props.barChart("quiz_chart_div_" + index, barChartData);
        } else if(quiz.quizType === QuizType.SHORT_ANSWER) {
            const pieChartData = props.makeQuizChartData(quiz);
            if(pieChartData.length > 1) props.pieChart("quiz_chart_div_" + index, pieChartData);
        }
    }

    useEffect(() => {
        if(props.data.length > 0) {
            const quiz = props.data[0];
            handleQuizData(quiz, 0);
        }
    });

    return (
        <>
            {props.data.map((quiz, index) => (
                <QuizResultExpansionPanels square expanded={expanded === 'panel' + (index + 1)} onChange={handleChange('panel'+ (index + 1), quiz, index)} key={(index + 1)}>
                    <ExpansionPanelSummary aria-controls={"panel" + (index + 1) + "d-content"} id="panel1d-header">
                        <Grid container direction="row" justify={'flex-start'} alignItems={'flex-start'}>
                            <Grid item xs={11}>
                                <Typography>
                                    {quiz.quizTitle}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <div
                                    style={{
                                        textAlign: 'right'
                                    }}
                                >
                                    {expanded === 'panel' + (index + 1)
                                        ? <span className="material-icons"> expand_more </span>
                                        : <span className="material-icons"> expand_less </span>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container direction="row" justify={'flex-start'} alignItems={'flex-start'}>
                            <Grid item xs={12}>
                                <Typography color={"inherit"}>
                                    문항)
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {quiz.quizQuestion}
                                </Typography>
                                {quiz.channelQuizImageList.map((quizImage, index) => (
                                    <Grid item xs={1} key={`quizResultImagePreview-${index}`}>
                                        <img src={`data:${quizImage.type};base64,${quizImage.image}`} width={"100%"} alt="" onDoubleClick={() => props.onImageDoubleClick(quizImage)} />
                                    </Grid>
                                ))}
                            </Grid>
                            <Grid item xs={quiz.quizType !== QuizType.SUBJECTIVE ? 7 : 12}>
                                {quiz.quizType === QuizType.SINGLE_CHOICE || quiz.quizType === QuizType.MULTIPLE_CHOICE
                                    ? quiz.channelQuizOptionList.map((option, i) => {
                                        return (
                                            <Grid container key={"quiz-option-" + quiz.quizQuestionId + "-" + (i + 1)} direction="row" justify={'flex-start'} alignItems={'flex-start'}>
                                                <Grid item xs={1} style={{color: "#ff0000"}}>
                                                    <Typography color={"inherit"}>
                                                        {option.correctFlag ? '정답' : ''}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <Typography color={"inherit"}>
                                                        {option.quizOptionNumber}번 - {option.quizOptionContents}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        )
                                    })
                                    :
                                    (
                                        <Grid container direction="row" justify={'flex-start'} alignItems={'flex-start'}>
                                            <Grid item xs={1}>
                                                <Typography color={"inherit"}>
                                                    학생
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography color={"inherit"}>
                                                    응답
                                                </Typography>
                                            </Grid>
                                            {
                                                quiz.channelQuizAnswerList.map((answer, i) => {
                                                    const user = quiz.channelQuizAnswerUserList.find(answerUser => answer.userId === answerUser.userId);
                                                    return (
                                                        <Fragment key={"quiz-option-" + quiz.quizQuestionId + "-" + i}>
                                                            <Grid item xs={1}>
                                                                <Typography color={"inherit"}>
                                                                    {user.userName}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={11}>
                                                                <Typography color={"inherit"}>
                                                                    {answer.quizOptionContents}
                                                                </Typography>
                                                            </Grid>
                                                        </Fragment>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    )
                                }
                            </Grid>
                            {quiz.quizType !== QuizType.SUBJECTIVE ?
                                <Grid item xs={5}>
                                    <div id={"quiz_chart_div_" + index}/>
                                </Grid>
                                : ''
                            }
                        </Grid>
                    </ExpansionPanelDetails>
                </QuizResultExpansionPanels>
            ))}
        </>
    );
}
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {Button, Container, Grid, TextField, Typography, withStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Footer from "../../components/Footer";
import {Replay} from "@material-ui/icons";

const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
    mainLogo: {
        width: 119,
        height: 91,
        paddingTop: theme.spacing(7),
    },
    mainTitle: {
        fontSize: 38,
        color: '#333333',
        paddingTop: theme.spacing(6),
    },
    mainHeader: {
        fontSize: 16,
        color: '#333333',
        paddingTop: theme.spacing(5),
    },
    emailText: {
        width: 320,
    },
    mainBody: {
        fontSize: 14,
        color: '#b7b7b7',
        paddingTop: theme.spacing(1),
    },
    mainButton: {
        borderRadius: 24,
        fontSize: 16,
        width: 200,
        height: 48,
        marginTop: theme.spacing(4),
    },
    captcha: {
        paddingTop: theme.spacing(2),
    },
    gutterMargin:{
        margin:theme.spacing(3, 0, 2),
        '& input[type=password]':{
            fontFamily:'initial',
        }
    },
});


@inject('passwordStore')
@observer
class FindPassword extends React.Component {
    componentDidMount() {
        this.props.passwordStore.initialize();
        this.props.passwordStore.getCaptchaImage();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.passwordStore.isWrongCaptchaValue) {
            this.props.enqueueSnackbar('자동입력 방지문자가 잘못 입력되었습니다.', {
                variant: 'error'
            });

            this.props.passwordStore.clearState();
            this.props.passwordStore.getCaptchaImage();
        }
    }

    handleChangeEmail = (event) => {
        this.props.passwordStore.changeEmail(event.target.value);
    }

    handleClickFind = () => {
        this.props.passwordStore.findPassword();
    }

    handleClickToHome = () => {
        this.props.history.push("/");
    }

    handleChangeCaptchaValue = (event) => {
        this.props.passwordStore.changeCaptchaValue(event.target.value);
    }

    render() {
        const {classes} = this.props;
        const {isReady, isRequestSuccess, isRequestFailed, email, captcha, captchaValue} = this.props.passwordStore;
        const isCaptchaValueExist = captchaValue && captchaValue !== '' && captchaValue.length > 0;
        return (
            <React.Fragment>
                <Container component="main" maxWidth="xs" className={classes.mainContainer}>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.mainContent} >
                        <img className={classes.mainLogo} src="/images/logo-verti.png" alt="ONTHELIVE" />

                        {isReady ?
                            <div>
                                <Typography className={classes.mainTitle}>비밀번호 찾기</Typography>
                                <Typography className={classes.mainHeader}>가입시 사용한 이메일을 입력해 주세요.</Typography>
                                <TextField id="find-pass"
                                           margin="normal"
                                           className={classes.emailText}
                                           label={<Typography variant="subtitle1" >이메일</Typography>}
                                           value={email}
                                           onChange={this.handleChangeEmail}
                                           InputLabelProps={{shrink: true}}
                                           fullWidth autoFocus />
                                <Grid container className={classes.captcha}>
                                    <Grid item xs={12}>
                                        <Typography variant="body2" >* 아래 이미지를 보이는 대로 입력해 주세요.</Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <img src={(captcha && captcha.key) ? `/api/v1/captcha/${captcha.key}` : ''} style={{width: '70%'}} alt="" />
                                    </Grid>
                                    <Grid item xs={4} style={{ display: 'flex' }}>
                                        <Button style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '75%'
                                        }} onClick={() => this.props.passwordStore.getCaptchaImage()}>
                                            <Replay /> 새로고침
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField id="responseValue"
                                                   name="responseValue"
                                                   margin="dense"
                                                   value={captchaValue}
                                                   onChange={this.handleChangeCaptchaValue}
                                                   className={classes.gutterMargin}
                                                   InputLabelProps={{shrink: true}}
                                                   helperText={'자동입력 방지문자'}
                                                   required
                                                   style={{
                                                       width: '80%'
                                                   }}
                                        />
                                    </Grid>
                                </Grid>
                                <Button color="primary" variant="contained"
                                        className={classes.mainButton}
                                        disabled={(email.length < 1 || !isCaptchaValueExist)}
                                        onClick={() => this.handleClickFind()}>
                                    비밀번호 찾기
                                </Button>
                            </div>
                            :
                            ''
                        }
                        {isRequestSuccess ?
                            <div>
                                <Typography className={classes.mainTitle}>비밀번호 찾기</Typography>
                                <Typography className={classes.mainHeader}>{`${email}로 전송된 이메일을 확인하여 패스워드를 변경해 주세요.`}</Typography>
                                <Typography className={classes.mainBody}>이메일 인증 후에 패스워드를 변경하실 수 있습니다.</Typography>
                                <Button color="primary" variant="contained"
                                        className={classes.mainButton}
                                        onClick={() => this.handleClickToHome()}>
                                    메인으로 가기
                                </Button>
                            </div>
                            :
                            ''
                        }
                        {isRequestFailed ?
                            <div>
                                <Typography className={classes.mainTitle}>비밀번호 찾기</Typography>
                                <Typography className={classes.mainHeader}>비밀번호 찾기 요청이 실패하였습니다.</Typography>
                                <Typography className={classes.mainBody}>계속해서 문제가 발생하는 경우에는 관리자에게 문의하여 주세요.</Typography>
                                <Button color="primary" variant="contained"
                                        className={classes.mainButton}
                                        onClick={() => this.handleClickToHome()}>
                                    메인으로 가기
                                </Button>
                            </div>
                            :
                            ''
                        }
                    </div>
                </Container>

                <Footer />
            </React.Fragment>
        );
    }
}

export default withSnackbar(withRouter(withStyles(styles) (FindPassword)));
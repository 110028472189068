import {observable, flow, action, computed, toJS} from "mobx";
import axios from "axios";
import {MonitoringVideo} from "../views/monitoring/api/MonitoringVideo";
import _ from "lodash";
import * as Params from "../common/Params";
import {VideoMode} from "../views/room/api/RoomPresenter2";
import {RoomChat} from "../views/room/api/RoomChat";



const LogPrefix = '[MonitoringStore] ';
// const PollingTimeout = 1000;

export default class MonitoringStore {
    @observable userId = 0;
    @observable channel = {};

    @observable janus = {};
    @observable pluginHandle = {};
    @observable xParam = {};
    @observable server = '';
    @observable isReconnectDialogOpen = false;

    @observable isConnected = false;
    @observable isChatConnected = false;

    @observable monitoringVideo = undefined;
    @observable monitoringVideoRef = undefined;

    @observable chatJanus = {};
    @observable monitoringChat = undefined;
    @observable chatMemberList = [];
    @observable xPublishId = '';
    @observable information = {};
    @observable videoViewHeight = 0;
    @observable videoViewWidth = 0;
    @observable landscape = true;
    @observable fullScreen = false;
    @observable monitoringSubStream = -1;
    @observable monitoringSubStreamLock = -1;

    @observable selectedFeed = 0;

    @action setJanus = janus => this.janus = janus;
    @action setChatJanus = chatJanus => this.chatJanus = chatJanus;
    @action setUserId = userId => this.userId = userId;
    @action setReconnectDialogOpen = open => this.isReconnectDialogOpen = open;
    @action setPluginHandle = pluginHandle => this.pluginHandle = pluginHandle;
    @action setMonitoringVideoRef = monitoringVideoRef => this.monitoringVideoRef = monitoringVideoRef;
    @action setFullscreen = fullScreen => this.fullScreen = fullScreen;

    @action setMonitoringSubstreamLock = (substream) => {
        this.monitoringSubStreamLock = substream;
    }
    @action setMonitoringSubstream = (substream) => {
        this.monitoringSubStream = substream;
    }

    setSubstream = (videoMode, substream) => {
        console.log(LogPrefix, `Set substream to ${substream}`);

        if(videoMode === VideoMode.Monitoring) {
            this.monitoringVideo.setSimulcastSubstream(substream);
        }
    }

    @action setVideoViewHeight = (height) => {
        console.log(LogPrefix, 'setVieoViewHeight', height);
        this.videoViewHeight = height;
    }

    @action setVideoViewWidth = (width) => {
        this.videoViewWidth = width;
    }

    @action setAdditionalHeight = (additionalHeight) => {
        this.additionalHeight = additionalHeight;
    }

    @action setLandscape = (landscape) => {
        this.landscape = landscape;
    }

    @action chatConnect = () => {
        // const server = "wss://gtci-dev.onthe.live:8987/janus";
        // const server = "wss://bs010.onthe.live:8987/janus";
        // const server = `wss://${window.location.hostname}:8987/janus`;
        const server = _.find(this.channel.channelBroadcastEndpointList, (endpoint) => endpoint.typeCode === 'MAIN');
        const iceServers = undefined;
        console.log(LogPrefix, 'MonitoringChatJanus Connecting...', server, iceServers);
        const that = this;
        const chatJanus = new window.Janus({
            server: server.broadcastEndpoint,
            iceServers: iceServers,
            success: () => {
                console.log(LogPrefix, 'MonitoringChatJanus connected successfully');
                that.setChatJanus(chatJanus);
                that.setChatConnected(true);
                that.attachAndJoin();
            },
            error: error => {
                console.log(LogPrefix, 'MonitoringChatJanus connect failed', error);
                that.setChatJanus({});
                that.setReconnectDialogOpen(true);
            },
            destroyed: () => console.log(LogPrefix, 'MonitoringChatJanus destroyed')
        });
        this.chatJanus = chatJanus;
    }

    @action connect = () => {
        // const server = "wss://gtci-dev.onthe.live:8989/janus";
        // const server = "wss://bs010.onthe.live:8989/janus";
        // const server = `wss://${window.location.hostname}:8989/janus`;
        const server = this.xParam.server;
        const iceServers = undefined;
        console.log(LogPrefix, 'MonitoringJanus Connecting...', server, iceServers);
        const that = this;
        const janus = new window.Janus({
            server: server,
            iceServers: iceServers,
            success: () => {
                console.log(LogPrefix, 'MonitoringJanus connected successfully');
                that.setJanus(janus);
                that.setConnected(true);
                that.subscribe();
            },
            error: error => {
                console.log(LogPrefix, 'MonitoringJanus connect failed', error);
                that.setJanus({});
                that.setReconnectDialogOpen(true);
            },
            destroyed: () => console.log(LogPrefix, 'MonitoringJanus destroyed')
        });
        // this.janus = janus;
    }

    @action attachAndJoin = () => {
        this.isChatConnected = true;
        this.monitoringChat = new RoomChat(this.chatJanus, this.channel.channelId, `publisher`, String(this.userId), `ADMIN(${this.userId})`, {
            onJoin: (joinUserId, displayName, when) => this.monitoringMemberJoined(joinUserId, displayName),
            onLeave: (joinUserId, displayName, when) => this.monitoringMemberLeaved(joinUserId)
        });
        this.monitoringChat.attachAndJoin();
    }

    @action subscribe = () => {
        console.log(LogPrefix, 'Videoroom plugin to subscriber...');
        const opaqueId = window.Janus.randomString(12);
        const  that = this;
        this.janus.attach({
            plugin: that.xParam.pluginName,
            opaqueId: opaqueId,
            success: pluginHandle => {
                console.log(LogPrefix, 'Videoroom plugin attached successfully', pluginHandle);
                that.startSubscribe(pluginHandle);
            },
            error: error => {
                console.log(LogPrefix, 'Videoroom plugin attach failed', error);
            },
            consentDialog: (on) => {
                console.log(LogPrefix, 'Videoroom plugin consentDialog', on);
            },
            mediaState: (medium, on) => {
                console.log(LogPrefix, 'Videoroom plugin mediaState', medium, on);
            },
            webrtcState: on => {
                console.log(LogPrefix, 'Videoroom plugin webrtcState', on);
            },
            onmessage: (msg, jsep) => {
                console.log(LogPrefix, 'Videoroom plugin onmessage', msg, jsep);
                const event = msg.videoroom;
                if(event !== undefined && event !== null) {
                    if(event === 'attached') {
                        console.log(LogPrefix, 'Videoroom plugin attached successfully');
                    } else if(event === 'event') {
                        const started = msg.started;
                        if(started === 'ok') {
                            console.log(LogPrefix, 'onStarted');
                            return;
                        }

                        const substream = msg.substream;
                        if((substream !== undefined) && (substream !== null)) {
                            console.log(LogPrefix, 'onSubstreamChanged', substream);
                            if(this.monitoringSubStream < 0) {
                                setTimeout(() => that.setSubstream(VideoMode.Monitoring, 2), 1000);
                            }

                            if(this.monitoringVideo.videoMode === VideoMode.Monitoring) {
                                if(substream > this.monitoringSubStream) {
                                    this.setMonitoringSubstream(substream);
                                    this.setMonitoringSubstreamLock(substream);
                                } else if(substream < this.monitoringSubStream) {
                                    this.setMonitoringSubstream(substream);
                                    setTimeout(() => that.setSubstream(VideoMode.Monitoring, this.monitoringSubStreamLock), 1000);
                                } else {
                                    //Do nothing.
                                    //that.roomStore.setPrimarySubstream(substream);
                                }
                            }
                            return;
                        }

                        if(msg.error_code === 425 || msg.error_code === 428) { //error [ 425 : Already in as a subscriber on this handle, 428 : No Such Feed ]
                            setTimeout(that.leaveAndStartSubscribe(), 1000);
                        }

                    } else if(event === 'error') {
                        that.leaveAndStartSubscribe();
                    }
                }

                if(jsep !== undefined && jsep !== null) {
                    const {pluginHandle} = that;
                    console.log(LogPrefix, 'Creating answer for Handling SDP as well....');
                    pluginHandle.createAnswer({
                        jsep: jsep,
                        media: {
                            audioSend: false,
                            videoSend: false,
                        },
                        success: jsep => {
                            console.log(LogPrefix, 'Create answer success - Got SDP!', jsep);
                            const startRequest = {
                                message: {
                                    request: 'start',
                                    roomId: that.xParam.channelId
                                },
                                jsep: jsep,
                                success: result => console.log(LogPrefix, 'Start command send successfully', result),
                                error: error => console.log(LogPrefix, 'Start command send failed', error)
                            };
                            console.log(LogPrefix, 'Sending start command...');
                            pluginHandle.send(startRequest);
                        },
                        error: error => console.log(LogPrefix, 'Create answer error', error),
                    });
                }
            },
            onremotestream: stream => {
                console.log(LogPrefix, 'streaming plugin onremotestream', stream);
                console.log(LogPrefix, that.monitoringVideoRef);
                if(that.monitoringVideoRef && that.monitoringVideoRef.current) {
                    that.monitoringVideoRef.current.autoplay = true;
                    that.monitoringVideoRef.current.muted = false;
                    window.Janus.attachMediaStream(that.monitoringVideoRef.current, stream);

                    const videoTracks = stream.getVideoTracks();
                    const audioTracks = stream.getAudioTracks();
                    console.log(LogPrefix, 'Video tracks', videoTracks);
                    console.log(LogPrefix, 'Audio tracks', audioTracks);
                    if(videoTracks.length <= 0 && audioTracks.length <= 0) that.leaveAndStartSubscribe();
                }
            },
            slowLink: (data) => {
                console.log(LogPrefix, 'Videoroom plugin slowLink', data);
            },
            oncleanup: () => {
                console.log(LogPrefix, 'Videoroom plugin oncleanup');
            },
        });
    }

    @action setConnected = isConnected => this.isConnected = isConnected;
    @action setChatConnected = isChatConnected => this.isChatConnected = isChatConnected;

    @action startSubscribe = pluginHandle => {
        this.setPluginHandle(pluginHandle);

        this.monitoringVideo = new MonitoringVideo(VideoMode.Monitoring,this.janus, pluginHandle, this.xParam.channelId, "admin", this.monitoringVideoRef);
        this.monitoringVideo._checkRoom(this.xPublishId);
    }

    @action leaveAndStartSubscribe = () => this.monitoringVideo.leave({onSuccess: this.subscribe()});




    @action monitoringMemberJoined = (userId) => {
        const member = _.find(this.information.map(info => info.xFeeds.filter((m) => m.userId === userId)));

        member.map(user => {
            user.joined = true;
        })
        console.log("member chatMemberJoined ",member);
    }

    @action monitoringMemberLeaved = (userId) => {
        const member = _.find(this.information.map(info => info.xFeeds.filter((m) => m.userId === userId)));

        member.map(user => {
            user.joined = false;
        })
        console.log("member chatMemberLeaved",member);
    }

    @action setSelectedFeed = selectedFeed => this.selectedFeed = selectedFeed;

    @action setXPublishId = xPublishId => this.xPublishId = xPublishId;

    @computed
    get isMobile() {
        const deviceBrowserType = Params.getDeviceBrowserType(false);
        return (deviceBrowserType.deviceType === 'android' || deviceBrowserType.deviceType === 'ios');
    }


    @computed
    get xParamInfo() {
        const subscribeWidth = this.xParam.subscribeWidth ? this.xParam.subscribeWidth : 0;
        const subscribeHeight = this.xParam.subscribeHeight ? this.xParam.subscribeHeight : 0;
        const videoViewHeight = this.videoViewHeight ? this.videoViewHeight : 0;
        const videoViewWidth =   this.videoViewWidth ? this.videoViewWidth : 0;
        // const xColumns = this.xParam.informations ? this.xParam.informations.map(paging => (paging.xcolumns))  : 0;
        const information = _.find(this.information, (info) => info.xPublishId === this.xPublishId);

        // const xFeeds = this.xParam.informations ? this.xParam.informations.map(paging => (paging.xfeeds)) : [];
        // const xRows = xColumns >= xFeeds.length ? 1 : isNaN((xFeeds.length/xColumns)) ? 0 : Number(Math.floor(xFeeds.length/xColumns)) + Number((xFeeds.length%xColumns > 0 ? 1 : 0));
        // const xColumns = 5;
        // const xFeeds = ["001", "002", "003", "004", "005", "006", "007", "008", "009", "010", "011", "012", "013", "014", "015", "016", "017", "018", "019", "020", "021", "022", "023", "024", "025", "026", "027", "028", "029", "030", "031", "032", "033", "034", "035", "036", "037", "038", "039", "040"];
        return {
            "display" : this.xParam.display,
            "videoViewHeight": videoViewHeight,
            "videoViewWidth": videoViewWidth,
            "subscribeWidth": subscribeWidth,
            "subscribeHeight": subscribeHeight,
            "information" : information,
            // "xColumns": xColumns,
            // "xRows": xRows,
            // "xFeeds": xFeeds,
        };
    }

    getChannelInfo = flow(function* getChannelInfo(channelId) {
        try {
            const response = yield axios.get(`/api/v1/channels/channel-info?channel-id=${channelId}`);
            if(response.status === 200) {
                this.channel = response.data;
                console.log(LogPrefix, 'getChannelInfo data', response.data)
                this.getXParameter(channelId);
            }
        } catch (error) {
            console.log(LogPrefix, "Can't get channel info.", error);
        }
    });


    getXParameter = flow(function* getXParameter(channelId) {
        try {
            const response = yield axios.get(`/api/v1/pagingxvideos/${channelId}/x-param`);
            if(response.status === 200) {
                console.log(LogPrefix, 'getXParameter data', response.data)
                this.xParam = response.data;
                // this.chatMemberList = this.xParam.informations.map(paging => paging.xfeeds);
                this.setXPublishId(this.xParam.informations[0].xpublishId);

                const information = this.xParam.informations ? this.xParam.informations.map(info => ({
                    xColumns : info.xcolumns,
                    xFeeds : info.xfeeds.map(feed => ({userId: feed.userId, userName: feed.userName, joined: false})),
                    xRows : info.xcolumns >= info.xfeeds.length ? 1 : isNaN((info.xfeeds.length/info.xcolumns)) ? 0 : Number(Math.floor(info.xfeeds.length/info.xcolumns)) + Number((info.xfeeds.length%info.xcolumns > 0 ? 1 : 0)),
                    xPublishId : info.xpublishId
                })) : [];

                this.information = information;
                console.log(LogPrefix, 'information',information);
                this.connect();
                setTimeout(() => this.connect(), 4000);
                // this.chatConnect();
            }
        } catch (error) {``````````
            console.log(LogPrefix, "Can't get xParameter.", error);
        }
    });
}
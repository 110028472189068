import React from "react";
import MaterialTable from "material-table";
import {inject, observer} from "mobx-react";

@inject("dashboardStore")
@observer
class DashBoardChannelPlayTimeTable extends React.Component {
    componentDidMount() {
        this.props.dashboardStore.getChannelList(this.props.userId);
    }

    render() {
        const { isChannelPlayTimeLoading, channelPlayTime } = this.props.dashboardStore;
        const columns = [
            {title: 'id', field: 'id', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, hidden:true},
            {title: 'channelId', field: 'channelId', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, hidden:true },
            {title: 'channelStateSeq', field: 'channelStateSeq', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, hidden:true },
            {title: '시작 시각', field: 'startDatetime', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}},
            {title: '종료 시각', field: 'endDatetime', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}},
            {title: '진행 시간(분)', field: 'playTime', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}},
        ];
        return (
            <MaterialTable
                style={{boxShadow: 'none'}}
                isLoading={isChannelPlayTimeLoading}
                title=""
                data={channelPlayTime}
                columns={columns}
                options={{
                    pageSize: 5,
                    // loadingType: 'linear',
                    // paginationType: 'stepped',
                    search: true,
                    showTitle: false,
                }}
                localization={{
                    pagination: {
                        labelRowsSelect: ' 개씩 보기',
                        labelDisplayedRows: '총 {count}개 중 {from} - {to}',
                    },
                }}
            />
        );
    }
}

export default DashBoardChannelPlayTimeTable;
import React from "react";
import {inject, observer} from "mobx-react";
import Grid from "@material-ui/core/Grid";

@inject("dashboardStore")
@observer
class DashBoardChannelQuestionStatus extends React.Component {
    render() {
        const { questionStatus} = this.props.dashboardStore;
        if(questionStatus.length > 1) this.props.dashboardStore.makeBarChart("question_status_chart_div", questionStatus);
        else this.props.dashboardStore.makeMaterialBarChart("question_status_chart_div", questionStatus);
        return (
            <Grid container
                  // direction="column"
                  justify="center"
                  alignItems="center"
            >
                <Grid item xs={12}>
                    <div id="question_status_chart_div" />
                </Grid>
            </Grid>
        );
    }
}

export default DashBoardChannelQuestionStatus;
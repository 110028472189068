import React from "react";
import {inject, observer} from "mobx-react";
import {Container, Grid, Typography, withStyles} from "@material-ui/core";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import FormControl from "@material-ui/core/FormControl";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import SignUpDialog from "./SignUpDialog";

const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        marginTop: theme.spacing(2),
    },

    createButtonContainer: {
        margin: theme.spacing(1),
        textAlign: 'right',
        justifyContent: 'right',
    },
    defaultBoxStyle:{
        '& > div':{
            boxShadow:'none',
            //border:'1px solid #eee',
        },
    },
});

@inject("adminStore", "signUpStore")
@observer
class AdminTemplates extends React.Component {
    render() {
        const { classes, userManagementTable } = this.props;
        return (
            <>
                <Container component="main" maxWidth="lg" className={classes.mainContainer}>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.mainContent} >
                        <Typography variant="h4" component="h2">{'교수 계정 관리'}</Typography>
                        <FormControl margin="dense">
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                                <Link color="inherit" underline="none">계정</Link>
                                <Link color="inherit" underline="none">{'교수 계정 관리'}</Link>
                            </Breadcrumbs>
                        </FormControl>

                        <div className={classes.createButtonContainer}>
                                <div style={{height: 14}}/>
                        </div>

                        <Grid container>
                            <Grid container item xs={12}>
                                {userManagementTable}
                            </Grid>
                        </Grid>
                    </div>
                </Container>

                <SignUpDialog
                    open={this.props.adminStore.isSignUpDialogOpen}
                    handleClose={() => {
                        this.props.adminStore.changeSignUpDialogOpen(false);
                    }}
                />
            </>
        );
    }
}

export default withSnackbar(withRouter(withStyles(styles) (AdminTemplates)));
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {Box, Typography, withStyles} from "@material-ui/core";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import {inject, observer} from "mobx-react";

import {RoomMode, VideoMode, VideoState} from "./api/RoomPresenter2";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
    boxStyle: {
        backgroundColor: 'black',
    }
});

@inject('roomStore', 'publishStore')
@observer
class RoomLayerContentComponent extends React.Component {
    render() {
        const {classes, mode, role} = this.props;
        const {onPlayPrimary, onPlaySecondary} = this.props;
        const {mainVideo, subVideoHidden, primaryState, secondaryState} = this.props.publishStore;

        if(mode === VideoMode.Primary) {
            const imageSize = mainVideo === VideoMode.Primary ? 85 : 21;
            const fontSize = this.props.roomStore.videoViewWidth / 60;
            const textPadding = mainVideo === VideoMode.Primary ? 8 : 4;

            if ((mainVideo !== VideoMode.Primary) && (subVideoHidden)) {
                return <React.Fragment></React.Fragment>;
            }

            if (primaryState === VideoState.StreamAttached) {
                return (
                    <React.Fragment>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                             flexGrow={1} style={{cursor: 'pointer'}} className={classes.boxStyle} onClick={onPlayPrimary}>
                            <PlayArrowIcon fontSize='large' style={{color: '#fff2ac', width: imageSize, height: imageSize}}/>
                            <Typography style={{fontSize: fontSize, color: '#fff2ac', paddingTop: textPadding}}>방송이 시작되었습니다.</Typography>
                            <Typography style={{fontSize: fontSize, color: '#fff2ac'}}>화면을 눌러 방송을 시청하세요.</Typography>
                        </Box>
                    </React.Fragment>
                );
            } else if(primaryState === VideoState.Subscribing) {
                return this.props.roomStore.mode === RoomMode.LookAround ?
                    <React.Fragment>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexGrow={1} style={{cursor: 'pointer'}} className={classes.boxStyle} >
                            <CircularProgress style={{color: '#fff2ac'}} />
                            <Typography style={{fontSize: fontSize, color: '#fff2ac', paddingTop: textPadding}}>둘러보기 화면 준비 중 입니다.</Typography>
                        </Box>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" lexGrow={1} style={{cursor: 'pointer'}} className={classes.boxStyle}>
                            <CircularProgress style={{color: '#fff2ac'}}/>
                            <Typography style={{fontSize: fontSize, color: '#fff2ac', paddingTop: textPadding}}>화면 준비 중 입니다.</Typography>
                        </Box>
                    </React.Fragment>
            } else if(primaryState === VideoState.StreamEmpty) {
                return (
                    <React.Fragment>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexGrow={1} className={classes.boxStyle}>
                            <Typography style={{fontSize: fontSize, color: '#fff2ac', paddingTop: textPadding}}>방송 신호가 없습니다.</Typography>
                            <Typography style={{fontSize: fontSize, color: '#fff2ac'}}>잠시만 기다려주세요.</Typography>
                        </Box>
                    </React.Fragment>
                );
            } else if(primaryState === VideoState.None) {
                if(role === 'publisher') {
                    return (
                        <React.Fragment>
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexGrow={1} className={classes.boxStyle}>
                                <Typography style={{fontSize: fontSize, color: '#fff2ac', paddingTop: textPadding}}>아래의 방송 영상 목록을 눌러</Typography>
                                <Typography style={{fontSize: fontSize, color: '#fff2ac'}}>방송을 시작해 주세요.</Typography>
                            </Box>
                        </React.Fragment>
                    );
                } else {
                    return (
                        <React.Fragment>
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexGrow={1} className={classes.boxStyle}>
                                <Typography style={{fontSize: fontSize, color: '#fff2ac', paddingTop: textPadding}}>방송 중이 아닙니다.</Typography>
                                <Typography style={{fontSize: fontSize, color: '#fff2ac'}}>잠시만 기다려 주세요.</Typography>
                            </Box>
                        </React.Fragment>
                    );
                }
            } else {
                return (
                    <React.Fragment>

                    </React.Fragment>
                );
            }
        } else {
            const imageSize = mainVideo === VideoMode.Secondary ? 85 : 21;
            const  fontSize = this.props.roomStore.videoViewWidth / 90;
            const textPadding = mainVideo === VideoMode.Secondary ? 8 : 4;

            if((mainVideo !== VideoMode.Secondary) && (subVideoHidden)) {
                return <React.Fragment></React.Fragment>;
            }

            if(secondaryState === VideoState.StreamAttached) {
                return (
                    <React.Fragment>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center"
                             flexGrow={1} style={{cursor: 'pointer'}} className={classes.boxStyle} onClick={onPlaySecondary}>
                            <PlayArrowIcon fontSize='large'
                                           style={{color: '#fff2ac', width: imageSize, height: imageSize}}/>
                            <Typography style={{fontSize: fontSize, color: '#fff2ac', paddingTop: textPadding}}>방송이 시작되었습니다.</Typography>
                            <Typography style={{fontSize: fontSize, color: '#fff2ac'}}>화면을 눌러 방송을 시청하세요.</Typography>
                        </Box>
                    </React.Fragment>
                );
            } else if(secondaryState === VideoState.StreamEmpty) {
                return (
                    <React.Fragment>
                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexGrow={1} className={classes.boxStyle}>
                            <Typography style={{fontSize: fontSize, color: '#fff2ac', paddingTop: textPadding}}>방송 신호가 없습니다.</Typography>
                            <Typography style={{fontSize: fontSize, color: '#fff2ac'}}>잠시만 기다려주세요.</Typography>
                        </Box>
                    </React.Fragment>
                );
            } else if(secondaryState === VideoState.None) {
                if(role === 'publisher') {
                    return (
                        <React.Fragment>
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexGrow={1} className={classes.boxStyle}>
                                <Typography style={{fontSize: fontSize, color: '#fff2ac', paddingTop: textPadding}}>아래의 방송 영상 목록을 눌러</Typography>
                                <Typography style={{fontSize: fontSize, color: '#fff2ac'}}>방송을 시작해 주세요.</Typography>
                            </Box>
                        </React.Fragment>
                    );
                } else {
                    return (
                        <React.Fragment>
                            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flexGrow={1}  className={classes.boxStyle}>
                                <Typography style={{fontSize: fontSize, color: '#fff2ac', paddingTop: textPadding}}>방송 중이 아닙니다.</Typography>
                                <Typography style={{fontSize: fontSize, color: '#fff2ac'}}>잠시만 기다려 주세요.</Typography>
                            </Box>
                        </React.Fragment>
                    );
                }
            } else {
                return (
                    <React.Fragment>
                        <Box style={{backgroundColor: 'transparent'}} />
                    </React.Fragment>
                );
            }
        }
    }
}

export default withSnackbar(withRouter(withStyles(styles) (RoomLayerContentComponent)));
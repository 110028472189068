import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

const styles = (theme) => ({
    dialog: {
    },
    dialogTitle: {
    },
    dialogContent: {
    },
    formContainer:{
    },
    itemHeader: {
    },
    itemBody: {
    },
    emptyItemHeader: {
        fontSize: 12,
        fontWeight: 600,
    },
    fileText: {
        paddingTop: 32,
        paddingRight: theme.spacing(2),
        textAlign: 'left'

    },
    filebox: {
        paddingTop: 35,
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    fileSelection: {
        position: 'absolute',
        width: 1,
        height: 1,
        padding: 0,
        margin: -1,
        overflow: 'hidden',
        clip: 'rect(0,0,0,0)',
        border: 0,
        borderRadius: 12,
    },
    uploadButton: {
        marginTop: 35,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        height: 36,
        borderRadius: 25,
    },

    commandButton: {
        borderRadius: 24,
    },
});

@inject('boardStore')
@observer
class BoardUpdateDialog extends React.Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.boardStore.isUpdateLoadingFailed) {
            this.props.enqueueSnackbar('알림 정보 조회에 실패하였습니다.', {
                variant: 'error',
            });

            this.props.boardStore.clearUpdateDialogState(false);
        }

        if(this.props.boardStore.isUploadFailed) {
            this.props.enqueueSnackbar('파일 업로드에 실패하였습니다.', {
                variant: 'error',
            });

            this.props.boardStore.clearUpdateDialogState(true);
        }

        if(this.props.boardStore.isUpdated) {
            this.props.enqueueSnackbar('알림 정보를 수정하였습니다.', {
                variant: 'info',
            })

            this.props.boardStore.clearUpdateDialogState(false);
        }

        if(this.props.boardStore.isUpdateFailed) {
            this.props.enqueueSnackbar('알림 정보 수정에 실패하였습니다.', {
                variant: 'error',
            });

            this.props.boardStore.clearUpdateDialogState(true);
        }
    }

    handleCancel = () => {
        this.props.boardStore.clearUpdateDialogState(false);
    }

    handleOk = () => {
        this.props.boardStore.updateBoard(this.props.userId, this.props.loginType);
    }

    handleChangeBoardTitle = (event) => {
        this.props.boardStore.changeBoardTitle(event.target.value);
    }

    handleChangeBoardContents = (event) => {
        this.props.boardStore.changeBoardContents(event.target.value);
    }

    handleChangeUploadFile = (event) => {
        const file = event.target.files[0];

        this.props.boardStore.changeUploadFile(file);
    }

    handleUpload = () => {
        this.props.boardStore.uploadBoardFile(this.props.userId);
    }

    render() {
        const {classes} = this.props;
        const {isOpenUpdateDialog, isUpdatable, isUpdating, isUploadable, isUploading, board, fileList, uploadFile} = this.props.boardStore;

        return (
            <Dialog disableBackdropClick fullWidth
                    maxWidth="sm"
                    className={classes.dialog}
                    open={isOpenUpdateDialog}
                    onClose={this.handleClose}
                    aria-labelledby="channel-detail-dialog-title" >
                <DialogTitle id="channel-detail-dialog-title" className={classes.dialogTitle}>
                    알림장 변경
                </DialogTitle>

                <DialogContent className={classes.dialogContent}>
                    <Grid container className={classes.formContainer}>
                        <Grid item xs={12} style={{padding: 0, margin: 0}}>
                            <TextField id="board-name"
                                       margin="normal"
                                       label={<Typography variant="subtitle1" >제목</Typography>}
                                       value={board.title}
                                       onChange={this.handleChangeBoardTitle}
                                       helperText={board.title.length < 1 ? '제목을 입력해 주세요' : ' '}
                                       InputLabelProps={{shrink: true, className: classes.itemHeader}}
                                       InputProps={{className: classes.itemBody}}
                                       autoFocus fullWidth />
                        </Grid>

                        <Grid item xs={12} style={{padding: 0, margin: 0}}>
                            <TextField id="board-contents"
                                       margin="normal"
                                       multiline rows={1} rowsMax={6}
                                       label={<Typography variant="subtitle1" >내용</Typography>}
                                       value={board.contents}
                                       onChange={this.handleChangeBoardContents}
                                       helperText={board.contents.length < 1 ? '내용을 입력해 주세요' : ' '}
                                       InputLabelProps={{shrink: true, className: classes.itemHeader}}
                                       InputProps={{className: classes.itemBody}}
                                       fullWidth />
                        </Grid>

                        <Grid item xs={12} style={{padding: 0, margin: 0}}>
                            <Typography className={classes.emptyItemHeader}>파일</Typography>
                        </Grid>
                        <Grid item xs={12} style={{padding: 0, margin: 0}}>
                            <Table>
                                <TableBody>
                                {fileList.length > 0 ?
                                    fileList.map(file =>
                                        <TableRow key={`file-row-${file.postFileId}`}>
                                            <TableCell><Typography variant="body2">{file.fileNameOriginal}</Typography></TableCell>
                                        </TableRow>
                                    )
                                    :
                                    <TableRow>
                                        <TableCell><Typography variant="body2">없음</Typography></TableCell>
                                    </TableRow>
                                }
                                </TableBody>
                            </Table>
                        </Grid>

                        <Grid item xs={12} style={{display: 'flex'}}>
                            <FormControl margin="normal" style={{flexGrow: 1}}>
                                <InputLabel id="channel-sub-label" className={classes.itemHeader} shrink>
                                    <Typography variant="subtitle1" >파일 추가</Typography>
                                </InputLabel>
                                <div className={classes.fileText}>
                                    <Typography>{uploadFile ? uploadFile.name : ''}</Typography>
                                </div>
                            </FormControl>

                            <div className={classes.filebox}>
                                <Button component="label" variant="contained" htmlFor="file-upload" disabled={isUpdating} style={{borderRadius:'25px'}}>파일 선택</Button>
                                <input id="file-upload" type="file" className={classes.fileSelection} onChange={this.handleChangeUploadFile} />
                            </div>

                            <Button color="primary" variant="contained" className={classes.uploadButton} disabled={!isUploadable || isUploading} onClick={this.handleUpload}>
                                {isUploading ? <CircularProgress style={{width: 16, height: 16}} /> : '자료 올리기' }
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button className={classes.commandButton} onClick={this.handleCancel}>
                        취소
                    </Button>

                    <Button color="primary" className={classes.commandButton} disabled={(!isUpdatable) || (isUploading) || (isUpdating)} onClick={this.handleOk}>
                        {isUpdating ? <CircularProgress style={{width: 16, height: 16}} />  : '확인'}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (BoardUpdateDialog)));
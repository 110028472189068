import React from "react";
import {inject, observer} from "mobx-react";

import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";

import * as authStore from "../../stores/AuthStore";
import Student from "./student/Broadcast";
import Teacher from "./teacher/Broadcast";

export const styles = (theme) => ({

});

@inject("broadcastStore", "authStore")
@observer
class Broadcast extends React.Component {
    componentDidMount() {
        const {channelId} = this.props.match.params;
        const {loginUser} = this.props.authStore;
        const token = sessionStorage.getItem(authStore.SessionStorageTokenKey);
        const userId = loginUser? loginUser.userId : null;

        this.props.broadcastStore.initData();

        if(!channelId) {
            this.props.history.push("/channel/list");
            return null;
        }

        this.props.broadcastStore.getUser(userId);
        this.props.broadcastStore.getChannel(channelId, userId, token);
    }

    render() {
        const { channel, userInfo } = this.props.broadcastStore;

        if(channel && userInfo && channel.userId === userInfo.userId) {
            return (
                <Teacher janusInitialized={this.props.janusInitialized}/>
            );
        }

        if(channel && userInfo && channel.userId !== userInfo.userId) {
            return (
                <Student janusInitialized={this.props.janusInitialized}/>
            );
        }

        return (
            <div/>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (Broadcast)));

import React from 'react';
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";
import {Button, CircularProgress, Container, ListItemText, TextField, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Footer from "../../components/Footer";

const style = theme => ({
    appBarSpacer: theme.mixins.toolbar,
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    signUpText: {
        marginTop: theme.spacing(2),
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    checkAgreeMargin: {
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },

    payMethodTitle: {
        margin: theme.spacing(5, 0, 0),
    },
    payMethod: {
        margin: theme.spacing(0, 10, 0),
    },
});

@inject('paymentStore','authStore')
@observer
class Payment extends React.Component {	
	
	constructor(props) {
	    super(props);
	    this.state = {
  		      method: 'card'
  	 	};

    	//  this.onRadioChange = this.handleChangePayMethod.bind(this);
   	  	//  this.onSubmit = this.handleSubmitForm.bind(this);

	  }


    componentDidMount() {
    
    	const {loginUser} = this.props.authStore;
    	const amount = this.props.match.params.itemId; // itemId로 otl_item TB를 가져와야 함.
    	const {changeIamPortData, changePaymentData } = this.props.paymentStore;
    	
    	 // itemId, itemName , price 
    	
    	// pg iamport 
    	changeIamPortData("merchant_uid",`mid_${new Date().getTime()}`);  // itemId
        changeIamPortData("name", `온더 ${amount}원 이용권`);         // itemName
        changeIamPortData("amount",`${amount}`);                        // price
        
        changeIamPortData("buyer_name", loginUser.userName);            // login user name
        changeIamPortData("buyer_email", loginUser.email);              // login user id(email) 
        
        // 결제 payment
        changePaymentData("itemId",`${amount}`);// 
    	changePaymentData("userId",loginUser.userId);//
    	
    	// API 결제수단 가져오기 
    	this.props.paymentStore.getPaymentMethod(); 
    	
    } 

    componentDidUpdate(prevProps, prevState, snapshot) {

      const { iamPortData, paymentResult, changePaymentResult } = this.props.paymentStore;
        
      if(paymentResult === true) {
            changePaymentResult(null); 
            // alert(`${paymentData.paymentMethodName} 로 결제완료(${iamPortData.amount}원) 하였습니다.`);
            this.props.history.push(`/ticket/summary/${iamPortData.amount}`);
      } else if(paymentResult === false) {
            changePaymentResult(null);
      }
    }

    handleChangePayMethod = (e) => { 
  
      	this.setState({
    	      method: e.target.value
      		});
    	const { paymentMethodList } = this.props.paymentStore;
       	let methodTable = paymentMethodList.reduce((acc, it) => ({...acc, [it.iamportPayMethod]: it }), {})
     
       	this.props.paymentStore.changePaymentData("paymentMethodCode",  methodTable[e.target.value].paymentMethodCode);          
        this.props.paymentStore.changeIamPortData("pay_method", methodTable[e.target.value].iamportPayMethod);
        this.props.paymentStore.changePaymentData("paymentMethodName",  methodTable[e.target.value].paymentMethodName);
        
    };

    // 복수개 회사 입금통장시  
    handleChangePayment = (e) => {       
        const name = e.target.name;
        let value = e.target.value;
        this.props.paymentStore.changePaymentData(name, value);
    };
    
    handleSubmitForm = (e) => {
    	e.preventDefault();
     
       	// alert("[알림] 테스트 기간중  결제승인 시 익일 자동 취소 됩니다.");
        const { iamPortData, paymentMethodList } = this.props.paymentStore;
        
        if(iamPortData.pay_method === ''){
        	let methodTable = paymentMethodList.reduce((acc, it) => ({...acc, [it.iamportPayMethod]: it }), {})
          
           	this.props.paymentStore.changePaymentData("paymentMethodCode",  methodTable[this.state.method].paymentMethodCode);          
            this.props.paymentStore.changeIamPortData("pay_method", methodTable[this.state.method].iamportPayMethod);
            this.props.paymentStore.changePaymentData("paymentMethodName",  methodTable[this.state.method].paymentMethodName);
        	
        }
    
        if( iamPortData.pay_method === 'account' ) {  // 무통장 입금 
        	this.props.paymentStore.setPaymentInfo();
        } else  {
    
          const { IMP } = window;
          IMP.init(iamPortData.userCode);
      
          const {
            pg,
            pay_method,
            merchant_uid,
            name,
            amount,
            buyer_name,
            buyer_email,
            digital,
          } = e.target;
        
           const data = {
            pg,                           
            pay_method,                           
            merchant_uid,  
            name,                  
            amount,                                 
            buyer_name, 
            buyer_email,               
            digital,            
          };

          data.pg = iamPortData.pg;
          data.pay_method = iamPortData.pay_method;       
          data.merchant_uid = iamPortData.merchant_uid;         
          data.name = iamPortData.name;
          data.amount = iamPortData.amount;
          data.buyer_name = iamPortData.buyer_name;
          data.buyer_email = iamPortData.buyer_email;
   
          IMP.request_pay(data, this.callback);
        }

    };

    callback = (response) => {
      const {
        success,
        // merchant_uid,
        // imp_uid,
        error_msg,     
      } = response;
      //const query = queryString.stringify(response);     

      // alert(`success =>  ${success}, merchant_uid =>  ${merchant_uid}, imp_uid =>  ${imp_uid}`)
      if (success) { // 아임포트 PG 결제 성공시 
        this.props.paymentStore.setPaymentInfo();
      } else {
        alert(`결제 실패 =>  ${error_msg}`);
        // test by lee              
        // this.props.paymentStore.setPaymentInfo();
      }
    };
    
   
    render() {

        const { classes } = this.props;
        const commaRegExp = /\B(?=(\d{3})+(?!\d))/g; 
        const { iamPortData, paymentTransferData, paymentMethodList, paymentResult } = this.props.paymentStore;
       
        const elements = paymentMethodList.map( (item, index) => {
           	return  <FormControlLabel  key={item.paymentMethodCode} value={item.iamportPayMethod} onChange={this.handleChangePayMethod} control={<Radio color="primary" />} label={item.paymentMethodName} />
          });
       
      
        return (
         <React.Fragment>
         
                <Container component="main" maxWidth="xs">
              
                    <div className={classes.appBarSpacer} />
                    <div className={classes.paper}>
                        <img src={"/images/login-logo.png"} alt={""}/>
                        <Typography className={classes.signUpText} component="h1" variant="h5" >
                            결제 내역 
                        </Typography>

                        <div className={classes.form}>
                            <Grid item xs={12}>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="결제내역 확인 후 결제해주세요." />
                                        <ListItemText align={"right"} />
                                    </ListItem>
                                </List>
                            </Grid>
                            <div style={{
                                height: '2px',
                                backgroundColor: '#333333'
                            }} />

                            <TextField  disabled variant="filled" id="name"
                                       name="name"
                                       label="상품명"
                                       margin="normal"
                                       value={iamPortData.name}
                                       fullWidth />
                             
                             <TextField disabled variant="filled" id="amount"
                                       name="amount"
                                       label="결제금액"
                                       margin="normal"
                                       value={iamPortData.amount.toString().replace(commaRegExp, ',')}
                                       fullWidth />

                                       <TextField disabled variant="filled" id="buyer_name"
                                       name="buyer_name"
                                       label="구매자"
                                       margin="normal"
                                       value={iamPortData.buyer_name}
                                       fullWidth />


                                       <TextField disabled variant="filled" id="buyer_email"
                                       name="buyer_email"
                                       label="구매자이메일"
                                       margin="normal"
                                       value={iamPortData.buyer_email}
                                       fullWidth />


                                       <FormLabel className={classes.payMethodTitle} component="legend">결제수단</FormLabel>
                                       <RadioGroup className={classes.payMethod} row aria-label="position" name="pay_method" defaultValue={this.state.method} >                           
                                       <ol>{elements}</ol>                                       
                                       </RadioGroup>

                          {iamPortData.pay_method ==='account' ?
                            <div>
                                  <TextField disabled variant="filled"id="bankName"
                                             name="bankName"
                                             label="은행명"
                                             margin="normal"
                                             value={paymentTransferData.bankName}
                                             onChange={this.handleChangePayment}
                                             required fullWidth />

                                  <TextField disabled variant="filled" id="bankAccountNo"
                                             name="bankAccountNo"
                                             label="계좌번호"
                                             margin="normal"
                                             value={paymentTransferData.bankAccountNo}
                                             onChange={this.handleChangePayment}
                                             required fullWidth />


                                  <TextField disabled variant="filled" id="bankAccountName"
                                             name="bankAccountName"
                                             label="예금주"
                                             margin="normal"
                                             value={paymentTransferData.bankAccountName}
                                             onChange={this.handleChangePayment}
                                             required fullWidth />
                            </div>
                                       : ''
                          }
                           
                          <Grid item xs={12} align={"center"}>
	                          {paymentResult === null ?
	                            <Button type="submit"
	                                    style={{
	                                        borderRadius: '24px',
	                                        backgroundColor: '#d64e4e',
	                                        color: '#ffffff'
	                                    }}
	                                    className={classes.submit}
	                                    color="primary"
	                                    variant="contained"
	                                    onClick={this.handleSubmitForm}
	                                    fullWidth >                               
	                                결제하기
	                            </Button>                            
	                              : <CircularProgress size={22}/>}
                             </Grid>
                          </div>
                      </div>                     
                </Container> 
                <Footer />
            </React.Fragment>
        );
    }
};

export default withSnackbar(withRouter(withStyles(style) (Payment)));

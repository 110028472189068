import {action, observable} from "mobx";

export default class HelpStore {
    @observable open = false;

    @action openHelp = () => {
        this.open = true;
    }

    @action closeHelp = () => {
        this.open = false;
    }
}
import React from "react";
import {inject, observer} from "mobx-react";
import moment from "moment";

import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import {Dialog, DialogContent} from "@material-ui/core";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import ChannelAttendanceDialog from "../../channel/ChannelAttendanceDialog";
import BroadcastAttendDialog from "../BroadcastAttendDialog";
import MobileBroadcastChat from "./MobileBroadcastChat";
import BroadcastDrawer from "./BroadcastDrawer";
import BroadcastHelp from "../BroadcastHelp";
import BroadcastChannelExitDialog from "../dialog/BroadcastChannelExitDialog";
import BroadcastChannelEndDialog from "../dialog/BroadcastChannelEndDialog";
import * as Type from "../../../stores/BroadcastStore";
import {janusRoomType} from "../../../stores/BroadcastStore";
import * as Chat from "../BroadcastChat";
import * as Player from "../BroadcastNodePlayer";
import ChannelFileDialog from "../../channel/ChannelFileDialog";
import BroadcastQuizDialog from "../dialog/BroadcastQuizDialog";
import BroadcastQuizResultDialog from "../dialog/BroadcastQuizResultDialog";
import BroadcastSubPublishDialog from "../dialog/BroadcastSubPublishDialog";
import BroadcastMainPublishDialog from "../dialog/BroadcastMainPublishDialog";
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import {browserType} from "../../../common/Params";
import BroadcastResetDialog from "../dialog/BroadcastResetDialog";
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import VolumeOffIcon from '@material-ui/icons/VolumeOff'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import FullscreenIcon from '@material-ui/icons/Fullscreen'
import BroadcastTeacherLookAroundDialog from "../dialog/BroadcastTeacherLookAroundDialog";
import BroadcastScreenOffDialog from "../dialog/BroadcastScreenOffDialog";
import {toJS} from "mobx";
import BroadcastAttendReqeuestDialog from "../dialog/BroadcastAttendRequestDialog";
import BroadcastNetworkErrorDialog from "../dialog/BroadcastNetworkErrorDialog";
import BroadcastAlreadyUserDialog from "../dialog/BroadcastAlreadyUserDialog";
import BroadcastDuplicateUserDialog from "../dialog/BroadcastDuplicateUserDialog";
import BroadcastLookAroundPublishDialog from "../dialog/BroadcastLookAroundPublishDialog";
import BroadcastVideoTrackEmpty from "../component/BroadcastVideoTrackEmpty";
import BroadcastLookAroundErrorDialog from "../dialog/BroadcastLookAroundErrorDialog";
import BroadcastOnePixelVideoTag from "../component/BroadcastOnePixelVideoTag";
import BroadcastPresentationErrorDialog from "../dialog/BroadcastPresentationErrorDialog";
import {janusJoinPType} from "../BroadcastLookAroundJanus";

export const styles = (theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer+1,
    },
    appBarSpacer: {
        ...theme.mixins.toolbar,
        minHeight: '64px',
        [theme.breakpoints.down("xs")]: {
            minHeight: '60px',
        },
    },
    mainContent: {
        // width: `calc( ${theme.videoMaxWidth}px + ${theme.chatWidth}px + 160px )`,
        // width: `calc(100%-${theme.chatWidth})`,
        // marginRight: theme.chatWidth,
        // flexDirection: "column",
        // alignItems: 'center',
        overflowX: 'hidden',
        display: 'flex',
        justifyContent:'center',
        width: '100%',
        minWidth: '100%',
        //minWidth:'640px',
        [theme.breakpoints.down("xs")]: {
            position: 'fixed',
            top: 0,
            flexDirection: "column",
            flex: '1 0 auto',
            outline: '0',
            width: '100%',
            height: '100%',
            overflow: 'auto',
            minWidth:'100%',
            // height: '100vh',
            // height: `calc((var(--vh, 1vh) * 100) - 64px)`,
            // height: 'calc(100% - 64px)'

        },
    },
    mainContainer: {
        //flexGrow: 1,
        display: 'flex',
        width: '100%',
        minWidth: '100%',
        //maxWidth: theme.videoMaxWidth,

        // border:'1px solid rgba(0, 0, 0, 0.12)',
        order:2,
        borderLeft:'1px solid #eee',
        [theme.breakpoints.up("sm")]: {
            minWidth:'320px'
        },
        [theme.breakpoints.down("sm")]: {
            order:1,
        },
        [theme.breakpoints.down("xs")]: {
            display: 'block',
            border:0,
            // mainWidth:'100%',
            maxWidth:'600px',
            minWidth:'340px',
            //minHeight:'286px',
            //position: 'relative',
        },
        // height:'100vh'
    },
    main: {
        width: '100%',
        minWidth: '100%',
    },
    video: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        [theme.breakpoints.down("xs")]: {
            position: 'relative',
            height: '210px',
        },
        backgroundColor: '#6e6e6e',
    },
    videoOff: {
        zIndex: 1050,
        position: 'absolute',
        width: '100%',
        height: '100%',
        [theme.breakpoints.down("xs")]: {
            height: '210px',
        },
        // backgroundImage: 'url("/images/bg-play-stop.jpg")',
        // backgroundSize: 'cover',
    },
    videoOffImg: {
        zIndex: 1051,
        display: 'flex',
    },
    videoGrid: {
        position: "relative",
        paddingBottom: '56.25%',
        // borderBottom:'1px solid #ddd',
        [theme.breakpoints.down("xs")]: {
            paddingBottom: '0',
            backgroundColor: '#000',
            height: '210px',
            //borderBottom: 'none',
        },
        '& video::-webkit-media-controls': {
            display: 'none !important'
        }
    },
    videoBtns: {
        position:'absolute',
        top:'50%',
        left:'50%',
        marginLeft:'-42px',
        marginTop:'-42px',
        // fontSize: '14px',
        color:'#fff',
        zIndex: 1050,
        '& span':{
            margin:0,
        },
        '& svg':{
            width:'60px',
            height:'60px',
            boxShadow: '0 1px 11px #b5b5b5',
            borderRadius: '50%',
        }
    },
    videoCommentPaper: {
        padding: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            padding: 0,
            paddingLeft: theme.spacing(2),
        }
    },
    videoCommentTit:{
        fontSize:'13px',
        color:'rgba(51, 51, 51, 0.56);',
        fontWeight:'600'
    },
    videoCommentDesc:{
        fontSize:'14px',
        color:'#333333',
        margin:0,
        paddingLeft:0,
        '& li':{
            listStyle:'none',
            margin:'8px 0'
        },
    },
    videoTitle:{
        fontWeight:'700',
        fontSize:18,
        display:'flex',
        alignItems:'center',
        [theme.breakpoints.down("xs")]: {
            fontWeight:'700',
            fontSize:14,
        }
    },
    videoAssignment:{
        display:'inline-block',
        padding:6,
        fontSize:'13px',
        color:'#333',
        backgroundColor:'#e6e6e6',
        borderRadius:'6px',
        marginLeft:'auto'
    },
    videoDesc:{
        fontWeight:'500',
        paddingTop:'16px'
    },
    videoDate:{
        fontSize: '13px',
        color:'rgba(0,0,0,0.56)'
    },

    closeClass:{
        display:'flex',
        alignItems:'center',
        fontSize:'14px',
        fontWeight:'800',
        cursor:'pointer',
        width:'100px',
        padding:'12px 0',
        borderRadius:0,
        boxShadow:'none',
        // position:'absolute',
        position:'unset',
        bottom:'100px',
        '& svg':{
            fontSize:'18px',
            marginTop:'2px',
            paddingRight:'4px'
        },
        '&:hover, &:focus':{
            borderLeftWidth:'0!important',
            borderBottomWidth:'0!important',
        },
        [theme.breakpoints.down("xs")]: {
            position:'unset',
        }
    },
    prevClass:{
        borderRadius:0,
        // position:'absolute',
        position:'unset',
        bottom:0,
        width:'100px',
        '&:hover, &:focus':{
            borderLeftWidth:'0!important',
            borderBottomWidth:'0!important',
        },
        [theme.breakpoints.down("xs")]: {
            position:'unset',
        }
    },
    videoHeadMaker: {
        marginLeft: 'auto',
        fontSize:'12px',
        color:'#acacac',
    },
    videoHeadMakerName: {
        fontWeight:'500',
        fontSize:'14px',
        color:'#333333',
        marginLeft:'8px'
    },
    bradcastDrawerWrap:{
        order:3,
    },
    videoActionAreaWrap:{
        //height: `calc(100vh - 64px)`,
        order:1,
        [theme.breakpoints.down("xs")]: {
            order:2,
            borderTop:'1px solid #eee',
            height: 'auto'
        }
    },
    videoActionArea: {
        overflowY: 'auto',
        position:'relative',
        display: 'flex',
        alignItems:'center',
        width:'100px',
        flexDirection:'column',
        height: `calc(100vh - 64px)`,
        minHeight: '630px',
        // boxShadow: '-15px -5px 21px #f1f1f1',
        border:'1px solid #eee',
        borderWidth:'0 0 0 1px',
        // overflowY: 'auto',
        '& svg':{
            width:'24px',
            height:'24px',
            [theme.breakpoints.down("xs")]: {
                width:'18px',
                height:'18px',
            },
        },
        '& button, &>div':{
            width:'100px',
            height:'100px',
            boxSizing:'border-box',
            borderRadius:0,
            [theme.breakpoints.down("xs")]: {
                width:'80px',
                height:'64px',
                padding:'8px',
                fontSize:'13px'
            },
        },
        '& > button span, &>div':{
            display:'flex',
            flexDirection: 'column',
            justifyContent:'center',
        },
        '& > button > span > svg, & >div > svg, & > button > span > span':{
            marginRight:0,
            // marginBottom:'12px',
        },
        // height:'60px',
        [theme.breakpoints.up("md")]: {
            // height:'60px',
            flexDirection:'column',
            '& button:hover, & button:focus':{
                borderBottom:'1px solid #eee',
                borderLeft:'4px solid #CF1A1F',
            }
        },
        [theme.breakpoints.down("md")]: {
            '& button, &>div':{
                padding:16,
            }
        },
        [theme.breakpoints.down("sm")]: {
            height: `calc(100vh - 64px)`,
        },
        [theme.breakpoints.down("xs")]: {
            height:'auto',
            flexDirection:'row',
            display:'flex',
            flexWrap:'nowrap',
            overflowX:'auto',
            overflowY: 'hidden',
            minHeight: 'auto',
            width:'100%',
            border:'0 none',
            '& button, &>div':{
                borderRightWidth:'0',
                flex:'0 0 auto',
            },

        },
    },
    videoActionIcon: {
        color: '#fff!important',
        '& svg':{
            color:'#fff',
        }
    },
    videoUserCount: {
        padding: theme.spacing(2),
        fontSize: '14px',
        fontWeight: '700',
        display: "flex",
        alignItems: "center",
        mainWidth:'64px',
        lineHeight:'1.75'
    },
    icons: {
        width:theme.spacing(2),
        height:theme.spacing(2),
        marginRight: '6px',
        color: '#3c3c3c',
    },
    playIcon: {
        color: '#fff!important',
        marginRight:'0'
    },
    test:{
        borderRight:'1px solid #eee',
    },
    micIcon:{
        fontSize:'1rem',
        color:'#7b7b7b',
        borderRadius:'50px',
        padding:'8px',
        height:'20px',
        width:'20px',
        position:'absolute',
        bottom:theme.spacing(1),
        right:theme.spacing(1),
        boxShadow: '0px 3px 10px 0 rgba(165, 156, 156, 0.56)',
        backgroundColor:'rgba(255,255,255,1)',
        cursor:'pointer',
        zIndex:900,
        '&:hover, &:focus':{
            backgroundColor:'rgba(255,255,255,0.87)',
            boxShadow:'none',
        }
    },
    dialogContent:{
        '& > div:nth-child(3) > div':{
            background:'none',
            boxShadow:'none',
            '& svg':{
                color:'#fff',
            }
        }
    },
    dialogButton:{
        color:'#fff',
        marginTop:'30px',
        border:'1px solid #fff',
        background:'none',
        borderRadius:'50px',
        padding:'4px 16px',
        '& span svg':{
            width:18,
            height:18,
            marginRight:4
        }
    },
    firstPlayText: {
        display: 'block',
        zIndex: 1051,
        height: '23px',
        objectFit: 'contain',
        fontSize: '24px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: '0.5',
        letterSpacing: 'normal',
        textAlign: 'center',
        color: '#fff2ac',
        [theme.breakpoints.down("xs")]: {
            fontSize: '14px',
        }
    },
    firstPlayBtn: {
        width: '84px',
        height: '84px',
        [theme.breakpoints.down("xs")]: {
            width: '50px',
            height: '50px',
        }
    },
    pTopScreenTxt: {
        height: '100%',
        paddingRight: '8px',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color:'#fff2ac',
        zIndex: 1051,
        position: "absolute",
        margin: 'auto',
        top: 0,
        right: 0
    },
    pFootActionArea: {
        height: '100%',
        width: '100%',
        display: "flex",
        alignItems: "center",
        color:'white',
        zIndex: 1051,
        position: "absolute",
        margin: 'auto',
        top: 0,
        left: 0,
    },
    playerIcon: {
        filter: 'drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.78))',
        width: '32px',
        height: '32px'
    }
});

@inject("broadcastStore", "authStore", "channelAttendanceStore", "channelFileStore", "broadcastQuizStore")
@observer
class Broadcast extends React.Component {
    componentDidMount() {
        const {chatObj} = this.props.broadcastStore;
        this.props.broadcastStore.getBarCodesInfo();
        this.getMainDivWidth();
        // Player.setProps(this.props);
        // Player.registerNodePlayer();

        if(chatObj) {
            chatObj.close();
            this.props.broadcastStore.changeChatObj(null);
        }

        window.onresize = this.getMainDivWidth;

        window.onbeforeunload = (e) => {
            e.preventDefault();
            return 'Stop this event';
        };

        window.history.pushState(null, null, window.location.href);
        window.onpopstate = function () {
            //alert('hello');
            window.history.forward();
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { channel, chatObj, userInfo, chatServerInfo} = this.props.broadcastStore;
        // const { changeCountDown, changeCountDownTimer} = this.props.broadcastStore;

        // if(videoElChangeStatus) {
        //     this.replayTimer();
        // }

        if(!chatObj && userInfo && channel && chatServerInfo) {
            Chat.setChatProps(this.props);
            Chat.chatInit(channel);
        }

        // if(channel && !countDown) {
        //     const startDateTime = channel ? moment(channel.liveStartDatetime) : "";
        //     if(startDateTime) {
        //         const timer = CountDown.CountDownTimer(new Date(startDateTime), changeCountDown);
        //         changeCountDown(1);
        //         changeCountDownTimer(timer);
        //     }
        // }
    }

    componentWillUnmount() {
        const {chatObj} = this.props.broadcastStore;
        //Player.handlePlayStop();
        if(chatObj) {
            chatObj.close();
            this.props.broadcastStore.changeChatObj(null);
        }

        this.props.broadcastStore.janusRoomDestroy();
        window.onbeforeunload = null;
    }

    replayTimer = () => {
        // const { mainVideoEl, mainVideoElSound, subVideoEl, subVideoElSound, videoElChangeStatus, secondNodePlayer, mainPublishType } = this.props.broadcastStore;
        const { mainPublishType } = this.props.broadcastStore;
        const { changeVideoElStatus } = this.props.broadcastStore;
        // const mainEl = document.getElementById(mainVideoEl);
        // const subEl = document.getElementById(subVideoEl);

        if(mainPublishType !== Type.publishMethodType.RTMP) {
            // BroadcastJanus.publishOwnFeed(this.props.broadcastStore.pluginHandle, false);
            // BroadcastJanus.publishOwnFeed(this.props.broadcastStore.pluginHandle, true);
            changeVideoElStatus(false);
            return null;
        }

        // if(videoElChangeStatus && nodePlayer && secondNodePlayer) {
        //     let checkFlag = false;
        //     if(mainEl && mainEl.tagName.toLowerCase() === "canvas") {
        //         nodePlayer.setView(mainVideoEl);
        //         nodePlayer.setVolume(mainVideoElSound ? 1.0 : 0.0);
        //         checkFlag = true;
        //     }
        //
        //     if(subEl && subEl.tagName.toLowerCase() === "canvas") {
        //         secondNodePlayer.setView(subVideoEl);
        //         secondNodePlayer.setVolume(subVideoElSound ? 1.0 : 0.0);
        //         checkFlag = true;
        //     }
        //
        //     if(!checkFlag) {
        //         changeVideoElStatus(true);
        //         return null;
        //     }
        //
        //     Player.handlePlayStop();
        //     Player.handlePlayStart();
        //
        //     changeVideoElStatus(false);
        // }

        setTimeout(() => {
            const {videoElChangeStatus} = this.props.broadcastStore;
            if(!videoElChangeStatus) {
                return null;
            }

            this.replayTimer();
        }, 1000);
    }

    handleKeyUpChatMsg = Chat.handleKeyUpChatMsg;
    handleChatMsgSend = Chat.handleChatMsgSend;
    handleChatAttendance = Chat.handleChatAttendance;
    handleChatAttendanceLate = Chat.handleChatAttendanceLate;
    handleChatBroadcastSwitch = Chat.handleChatBroadcastSwitch;
    handleDoAttend = Chat.handleDoAttend;

    handleChangeChatMsg = (e) => {
        this.props.broadcastStore.changeChatMsg(e.target.value);
    };

    handleChatTab = (e) => {
        const value = e.currentTarget.name;
        this.props.broadcastStore.changeTabSelect(value);
    };

    handleChatScroll = () => {
        let chatMsgDiv = document.getElementById("chatMsgArea");
        if(chatMsgDiv) {
            chatMsgDiv.scrollTop = chatMsgDiv.scrollHeight ? chatMsgDiv.scrollHeight : 0;
        }
    };

    handleFullScreen = Player.handleFullScreen;

    handleAttendView = () => {
        const { changeAttendDialogStatus, initAttendDialog } = this.props.broadcastStore;
        initAttendDialog();
        changeAttendDialogStatus(true);
        // const {channel} = this.props.broadcastStore;
        // this.props.channelAttendanceStore.openAttendanceDialog(channel.channelId);
    };

    handleChannelAttendance = (channel) => {
        this.props.channelAttendanceStore.openAttendanceDialog(channel);
    }

    handleFileDownload = () => {
        const {channel} = this.props.broadcastStore;
        if(!channel) {
            return null;
        }

        this.props.channelFileStore.openFileDialog(channel.channelId, channel.userId);
    };

    handleChannelEnd = () => {
        const {changeChannelEndDialogOpen} = this.props.broadcastStore;
        changeChannelEndDialogOpen(true);
    };

    doChannelEnd = () => {
        const {loginUser} = this.props.authStore;
        const {channel} = this.props.broadcastStore;

        if(loginUser && loginUser.userId) {
            this.props.broadcastStore.exitChannel(channel, loginUser.userId);
            Chat.handleBroadcastBlock();
            this.props.history.push("/channel/list");
        }
    };

    handleChannelExit = () => {
        this.props.broadcastStore.changeChannelExitDialogOpen(true);
        //this.props.history.push("/channel/list");
    };

    handleVideoMouseOver = (value) => {
        const {playBtnMouseOver} = this.props.broadcastStore;
        const {changePlayBtnView} = this.props.broadcastStore;

        if(!value && !playBtnMouseOver) {
            setTimeout(() => {
                if(!playBtnMouseOver) {
                    changePlayBtnView(false);
                }
            }, 500);
            return null;
        }

        changePlayBtnView(value);
    };

    handlePlayBtnMouseOver = (value) => {
        const {changePlayBtnMouseOver} = this.props.broadcastStore;
        changePlayBtnMouseOver(value);
    };

    getMainDivWidth = () => {
        const { subVideoElViewChange } = this.props.broadcastStore;
        const { changeSubVideoElViewChange } = this.props.broadcastStore;
        let mainWidth = 0;
        let El = document.getElementById("mainDiv");
        if(El && El.scrollWidth) {
            mainWidth = Number(El.scrollWidth);
            if(mainWidth <= 600 && subVideoElViewChange !== Type.subViewChangeType.HIDDEN) {
                changeSubVideoElViewChange(Type.subViewChangeType.HIDDEN);
            }

            if(mainWidth > 600 && subVideoElViewChange !== Type.subViewChangeType.SHOW) {
                changeSubVideoElViewChange(Type.subViewChangeType.SHOW);
            }

            this.props.broadcastStore.changeMainWidth(mainWidth);
        }
    };

    mobileCheck = () => {
        const UserAgent = navigator.userAgent;
        return UserAgent.match(/iPhone|iPod|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || UserAgent.match(/LG|SAMSUNG|Samsung/) != null;
    }

    // mobileUrlbarCheck = () => {
    //     // We execute the same script as before
    //     let vh = window.innerHeight * 0.01;
    //     document.documentElement.style.setProperty('--vh', `${vh}px`);
    // }

    // getPlayer = (videoEl) => {
    //     const {nodePlayer, secondNodePlayer, mainVideoEl, subVideoEl} = this.props.broadcastStore;
    //
    //     if(mainVideoEl === videoEl) {
    //         return nodePlayer;
    //     }
    //
    //     if(subVideoEl === videoEl) {
    //         return secondNodePlayer;
    //     }
    //
    //     return null;
    // }

    getIsWebRtc = (videoEl) => {
        const {mainVideoEl, subVideoEl, mainPublishType, subPublishType} = this.props.broadcastStore;

        if(mainVideoEl === videoEl) {
            return mainPublishType !== Type.publishType.RTMP;
        }

        if(subVideoEl === videoEl) {
            return subPublishType !== Type.publishType.RTMP;
        }

        return null;
    }

    getScreenView = (videoEl) => {
        const {mainScreenView, subScreenView, mainVideoEl, subVideoEl} = this.props.broadcastStore;

        if(mainVideoEl === videoEl) {
            return mainScreenView;
        }

        if(subVideoEl === videoEl) {
            return subScreenView;
        }

        return null;
    }

    getScreenText = (videoEl) => {
        const { mainVideoEl, subVideoEl, myScreenView, mySubScreenView } = this.props.broadcastStore;
        const { changeMyScreenView, changeMySubScreenView } = this.props.broadcastStore;

        if(mainVideoEl === videoEl) {
            return (
                <React.Fragment>
                    {!myScreenView ?
                        <div
                            style={{
                                fontSize: '24px',
                                paddingRight: '8px',
                            }}
                        >
                            교수 화면만 정지중
                        </div>
                        : ""}
                    <img src={"/images/vcr-main.png"} alt={""} width={35} style={{cursor: 'pointer'}} onClick={() => changeMyScreenView(!myScreenView)}/>
                </React.Fragment>
            );
        }

        if(subVideoEl === videoEl) {
            return (
                <React.Fragment>
                    {!mySubScreenView ?
                        <div
                            style={{
                                fontSize: '24px',
                                paddingRight: '8px',
                            }}
                        >
                            교수 화면만 정지중
                        </div>
                        : ""}
                    <img src={"/images/vcr-sub.png"} alt={""} width={35} style={{cursor: 'pointer'}} onClick={() => changeMySubScreenView(!mySubScreenView)}/>
                </React.Fragment>
            );
        }

        return null;
    }

    getScreenSound = (videoEl) => {
        const { classes } = this.props;
        const {mainVideoEl, subVideoEl, mainVideoElSound, subVideoElSound} = this.props.broadcastStore;
        if(mainVideoEl === videoEl) {
            return mainVideoElSound ?
                // <img
                //     src={"/images/sound-on-icon.png"}
                //     width={30}
                //     alt={""}
                // />
                <VolumeUpIcon className={classes.playerIcon} />
                :
                // <img
                //     src={"/images/sound-off-icon.png"}
                //     width={30}
                //     alt={""}
                // />
                <VolumeOffIcon className={classes.playerIcon} />
        }

        if(subVideoEl === videoEl) {
            return subVideoElSound ?
                // <img
                //     src={"/images/sound-on-icon.png"}
                //     width={30}
                //     alt={""}
                // />
                <VolumeUpIcon className={classes.playerIcon} />
                :
                // <img
                //     src={"/images/sound-off-icon.png"}
                //     width={30}
                //     alt={""}
                // />
                <VolumeOffIcon className={classes.playerIcon} />
        }

        return null;
    }

    getScreenMic = (videoEl) => {
        const {mainVideoEl, subVideoEl, mainVideoElSound, subVideoElSound} = this.props.broadcastStore;
        if(mainVideoEl === videoEl) {
            return mainVideoElSound ?
                <MicIcon
                    style={{
                        margin: '0px',
                        color: 'white'
                    }}
                    width={30}
                />
                :
                <MicOffIcon
                    style={{
                        margin: '0px',
                        color: 'white'
                    }}
                    width={30}
                />
        }

        if(subVideoEl === videoEl) {
            return subVideoElSound ?
                <MicIcon
                    style={{
                        margin: '0px',
                        color: 'white'
                    }}
                    width={30}
                />
                :
                <MicOffIcon
                    style={{
                        margin: '0px',
                        color: 'white'
                    }}
                    width={30}
                />
        }

        return null;
    }

    handleSoundToggle = (videoEl) => {
        const { mainVideoEl, subVideoEl } = this.props.broadcastStore;

        if(mainVideoEl === videoEl) {
            Chat.handleMainSound();
        }

        if(subVideoEl === videoEl) {
            Chat.handleSubSound();
        }
    }


    playerOnOffHandle = (videoEl) => {
        const {mainVideoEl, subVideoEl, isFirstPlayCheck, subScreenView, mainScreenView} = this.props.broadcastStore;
        const {changeIsFirstPlayCheck, changeSubPublishDialogOpen, changeMainPublishDialogOpen, changeScreenOffDialogOpen, changeScreenOffDialogTarget} = this.props.broadcastStore;
        // const isWebRtc = publishMethod === Type.publishMethodType.WEB_RTC;
        // const isWebRtc = mainPublishType !== Type.publishType.RTMP;
        //const isSubWebRtc = subPublishType !== Type.publishType.RTMP;

        if(this.getScreenView(videoEl) && isFirstPlayCheck) {
            changeIsFirstPlayCheck(false);
            return null;
        }

        changeIsFirstPlayCheck(false);

        if(mainVideoEl === videoEl) {
            if(!mainScreenView) {
                changeMainPublishDialogOpen(true);
            } else {
                changeScreenOffDialogOpen(true);
                changeScreenOffDialogTarget(Type.broadcastType.MAIN);
                //Chat.handleMainScreenView();
            }
        }

        if(subVideoEl === videoEl) {
            if(!subScreenView) {
                changeSubPublishDialogOpen(true);
            }else{
                changeScreenOffDialogOpen(true);
                changeScreenOffDialogTarget(Type.broadcastType.SUB);
                //Chat.handleSubScreenView();
            }
        }

        return null;
    }

    handleQuizDialogOpen = () => {
        const {userId} = this.props.broadcastStore;
        this.props.broadcastQuizStore.getQuizList(userId);
        this.props.broadcastQuizStore.changeDialogOpen(true);
    }

    handleQuizResultDialogOpen = () => {
        const { channel } = this.props.broadcastStore;
        const { changeQuizResultDialogOpen } = this.props.broadcastQuizStore;
        this.props.broadcastQuizStore.getQuizResultQuestion(channel.channelId);
        changeQuizResultDialogOpen(true);
    }

    render() {
        const { classes } = this.props;
        const {
            channel,
            chatUserList,
            mainWidth,
            chatMsgList,
            userId,
            attendDialogMsg,
            attendDialogStatus,
            attendDialogMsgError,
            attendDialogMsgHelperText,
            chatReady,
            channelExitDialogOpen,
            channelEndDialogOpen,
            isFirstPlayCheck,
            subPublishDialogOpen,
            selectedSubPublishType,
            mainPublishType,
            availableBroadcastSwitch,
            deviceBrowserType,
            isLookAroundSoundOn
        } = this.props.broadcastStore;
        const { changeAttendDialogStatus, changeAttendDialogMsg, changeChannelExitDialogOpen, changeChannelEndDialogOpen, changeIsFirstPlayCheck, handleWebRtcFullScreen, changeSubPublishDialogOpen, changeSelectedSubPublishType, changeAvailableBroadcastSwitch } = this.props.broadcastStore;
        const startDateTime = channel ? moment(channel.liveStartDatetime).format("YYYY-MM-DD HH:mm") : "";
        const endDateTime = channel ? moment(channel.liveEndDatetime).format("YYYY-MM-DD HH:mm") : "";
        // const isWebRtc = publishMethod === Type.publishMethodType.WEB_RTC;
        const isWebRtc = mainPublishType !== Type.publishType.RTMP;
        chatMsgList.slice();
        this.props.broadcastStore.lookAroundUnderstandList.slice();

        const renderVideoOff = () => {
            if(this.getScreenView(Type.broadcastVideoEl.MAIN) && isFirstPlayCheck) {
                return (
                    <React.Fragment>
                        <div className={classes.videoOff}
                             id={Type.videoOffLayer.MAIN}
                             onClick={() => {changeIsFirstPlayCheck(false)}}
                        >
                            <img src={"/images/p-bg.png"}
                                 alt={""}
                                 width={"100%"}
                                 height={"100%"}
                                 style={{position: "absolute"}} />
                            <Grid container
                                  spacing={2}
                                  direction={"column"}
                                  alignContent={"center"}
                                  justify={"center"}
                                  style={{
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      margin: 0,
                                      zIndex: 1060,
                                      width: '100%',
                                      height: '100%',
                                      cursor: 'pointer',
                                  }}>
                                <Grid item style={{zIndex: 1051}}>
                                    <div style={{
                                        display: 'block',
                                        textAlign: 'center',
                                        zIndex: 1061
                                    }}
                                         onClick={() => {changeIsFirstPlayCheck(false)}}
                                    >
                                        <Button
                                            // className={classes.videoBtns}
                                            // onClick={() => {changeIsFirstPlayCheck(false)}}
                                        >
                                            <img src={"/images/play.png"} className={classes.firstPlayBtn} alt=""/>
                                        </Button>
                                    </div>
                                </Grid>

                                <Grid item style={{zIndex: 1051}}>
                                    <div className={classes.firstPlayText}>
                                        방송이 재생 중이니 플레이 버튼을 눌러주세요.
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </React.Fragment>
                );
            }

            if(!this.getScreenView(Type.broadcastVideoEl.MAIN)) {
                return (
                    <React.Fragment>
                        <div className={classes.videoOff}
                             id={Type.videoOffLayer.MAIN}
                             onClick={() => {this.playerOnOffHandle(Type.broadcastVideoEl.MAIN)}}
                        >
                            <img src={"/images/p-bg.png"} alt={""} width={"100%"} height={"100%"} style={{position: "absolute"}} />
                        </div>
                        <Button
                            className={classes.videoBtns}
                            onClick={() => {this.playerOnOffHandle(Type.broadcastVideoEl.MAIN)}}
                            onMouseOver={() => {this.handlePlayBtnMouseOver(true)}}
                            onMouseOut={() => {this.handlePlayBtnMouseOver(false)}}
                        >
                            <img src={"/images/play.png"} width={84} height={84} alt=""/>
                        </Button>
                    </React.Fragment>
                );
            }
        };

        return (
            <div id={"mainDiv"}>
                <div className={classes.appBarSpacer}/>
                <BroadcastHelp/>
                <Dialog open={!chatReady || !this.props.janusInitialized} className={classes.dialogContent}>
                    <DialogContent align={"center"}>
                        <CircularProgress size={50}/><br/>
                        <Button variant="outlined" onClick={this.handleChannelExit} className={classes.dialogButton}><ExitToAppIcon/> <span>나가기</span></Button>
                    </DialogContent>
                </Dialog>
                <div className={classes.mainContent}>
                    <div className={classes.appBarSpacer}/>
                    <div className={classes.bradcastDrawerWrap}>
                        <BroadcastDrawer {...this.props.broadcastStore}
                                         mainWidth={mainWidth}
                                         subVideoEl={Type.broadcastVideoEl.SUB}
                                         handleChatTab={this.handleChatTab}
                                         handleChangeChatMsg={this.handleChangeChatMsg}
                                         handleChatScroll={this.handleChatScroll}
                                         handleKeyUpChatMsg = {this.handleKeyUpChatMsg}
                                         handleChatMsgSend = {this.handleChatMsgSend}
                                         handleVideoMouseOver = {this.handleVideoMouseOver}
                                         // handlePlayStart = {Player.handlePlayStart}
                                         // handlePlayStop = {Player.handlePlayStop}
                                         handleDoAnnounce = {Chat.handleDoAnnounce}
                                         handleAllowQuestion = {Chat.handleAllowQuestion}
                                         handleDoResetPresentation = {Chat.handleDoResetPresentation}
                                         handleDoEndPresentation = {Chat.handleDoEndPresentation}
                                         handleDoQuestion = {Chat.handleDoQuestion}
                                         handleDoStopQuestion = {Chat.handleDoStopQuestion}
                                         getScreenView = {this.getScreenView}
                                         playerOnOffHandle = {this.playerOnOffHandle}
                                         getScreenText = {this.getScreenText}
                                         getScreenSound = {this.getScreenSound}
                                         handleSoundToggle = {this.handleSoundToggle}
                                         changeIsFirstPlayCheck = {changeIsFirstPlayCheck}
                                         getIsWebRtc = {this.getIsWebRtc}
                                         changeResetDialogOpen = {this.props.broadcastStore.changeResetDialogOpen}
                                         changePresentationErrorDialogOpen = {this.props.broadcastStore.changePresentationErrorDialogOpen}
                    />
                    </div>
                    {/* 이동 */}
                    <div className={classes.videoActionAreaWrap}>
                        <div className={classes.videoActionArea}>
                            <Button onClick={() => this.handleChannelAttendance(channel)} >
                                {/*<img src={"/images/quiz-result.png"} alt={""}/>*/}
                                <AssignmentIndIcon className={classes.icons} />
                                출석부 보기
                            </Button>

                            <Button onClick={() => this.props.broadcastStore.changeAttendRequestDialogOpen(true)} >
                                <img src={"/images/icon-assignment-check.png"} alt={""}/>
                                {/*<AssignmentTurnedInIcon className={classes.icons} />*/}
                                출석 요청
                            </Button>

                            <Button onClick={this.handleQuizDialogOpen}>
                                <img src={"/images/quiz-question.png"} alt={""}/>
                                {/*<EventNoteIcon className={classes.icons}/>*/}
                                퀴즈
                            </Button>

                            <Button onClick={this.handleQuizResultDialogOpen}>
                                <img src={"/images/quiz-result.png"} alt={""}/>
                                {/*<EventNoteIcon className={classes.icons}/>*/}
                                퀴즈 결과
                            </Button>

                            <Button onClick={this.handleFileDownload}>
                                <img src={"/images/download.png"} alt={""}/>
                                {/*<CloudDownloadIcon className={classes.icons}/>*/}
                                강의 자료
                            </Button>

                            {!this.mobileCheck() ?
                                <Button onClick={() => {
                                    if(!(this.props.broadcastStore.inPresentation || this.props.broadcastStore.inQuestion)) {
                                        this.props.broadcastStore.changeLookAroundPublishDialogOpen(true);
                                    } else {
                                        this.props.broadcastStore.changeLookAroundErrorDialogOpen(true);
                                    }
                                }}>
                                    <img src={"/images/look-around.png"} alt={""}/>
                                    둘러 보기
                                </Button>
                                : ""}
                            {/*<Button onClick={() => changeSubPublishDialogOpen(true)}>*/}
                            {/*    <PlayCircleFilledIcon className={classes.icons}/>*/}
                            {/*    부화면 방송하기*/}
                            {/*</Button>*/}

                            <Button className={classes.prevClass} onClick={this.handleChannelExit} variant="contained" color="primary"><ExitToAppIcon/><span>나가기</span></Button>
                            <Button className={classes.closeClass} onClick={this.handleChannelEnd} variant="contained"><PowerSettingsNewRoundedIcon/><span>강의종료</span></Button>
                        </div>
                        {/* <Divider/> */}
                    </div>
                    {/* 이동 */}
                    <div className={classes.mainContainer}>
                        <div className={classes.main} style={{width: '100%', minWidth: '100%'}}>
                            {/*video area*/}
                            <Grid item xs={12}>
                                <Grid container spacing={0}>
                                    {/*<Hidden xsDown>*/}
                                    {/*    <Grid item xs={12}*/}
                                    {/*          style={{*/}
                                    {/*              position: "relative",*/}
                                    {/*              minHeight: '45px',*/}
                                    {/*          }}>*/}
                                    {/*        <img src={"/images/p-top.png"} alt={""} width={"100%"} height={"100%"} />*/}
                                    {/*        <div className={classes.pTopScreenTxt}>*/}
                                    {/*            {this.getScreenText(Type.broadcastVideoEl.MAIN)}*/}
                                    {/*        </div>*/}
                                    {/*    </Grid>*/}
                                    {/*</Hidden>*/}
                                    <Grid className={classes.videoGrid} item xs={12}>
                                        {/*<Hidden smUp>*/}
                                            <div style={{
                                                padding: '8px',
                                                zIndex: 1051,
                                                position: "absolute",
                                                right: 0,
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                color:'#fff2ac',
                                            }}>
                                                {this.getScreenText(Type.broadcastVideoEl.MAIN)}
                                            </div>
                                        {/*</Hidden>*/}

                                        {/*<Hidden smUp>*/}
                                            <div style={{
                                                display: 'flex',
                                                padding: '8px',
                                                color: 'white',
                                                zIndex: 1051,
                                                position: "absolute",
                                                right: 0,
                                                bottom: 0,
                                            }}

                                            >
                                                {channel.subScreenAvailableFlag ?
                                                    <div
                                                        style={{paddingRight: '18px', cursor: 'pointer'}}
                                                        onClick={() => {
                                                            if(!availableBroadcastSwitch) {
                                                                return null;
                                                            }

                                                            changeAvailableBroadcastSwitch(false);
                                                            this.handleChatBroadcastSwitch();
                                                            setTimeout(() => {
                                                                changeAvailableBroadcastSwitch(true);
                                                            }, 2000)
                                                        }}
                                                    >
                                                        {/*<img src={"/images/imgtransition-icon.png"} alt={""} width={30}/>*/}
                                                        <SwapHorizIcon className={classes.playerIcon} width={30} />
                                                    </div>
                                                    : ""}

                                                {deviceBrowserType.browserType !== browserType.safari ?
                                                    <div
                                                        style={{cursor: "pointer"}}
                                                        onClick={isWebRtc ? handleWebRtcFullScreen : this.handleFullScreen}
                                                    >
                                                        {/*<img src={"/images/full-icon.png"} alt={""} width={30}/>*/}
                                                        <FullscreenIcon className={classes.playerIcon} width={30} />
                                                    </div>
                                                    :
                                                    ""
                                                }
                                            </div>

                                            <div style={{
                                                padding: '8px',
                                                color: 'white',
                                                zIndex: 1051,
                                                position: "absolute",
                                                cursor: 'pointer',
                                                left: 0,
                                                bottom: 0,
                                            }}
                                                 onClick={() => this.handleSoundToggle(Type.broadcastVideoEl.MAIN)}
                                            >
                                                {this.getScreenSound(Type.broadcastVideoEl.MAIN)}
                                            </div>
                                        {/*</Hidden>*/}

                                        {renderVideoOff()}
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: 1,
                                                height: 1,
                                                border: '1px solid red',
                                                zIndex: 1050,
                                                backgroundColor: '#000000',
                                            }}
                                        >
                                            <BroadcastOnePixelVideoTag/>
                                        </div>
                                        <div
                                            style={{
                                                position: "absolute",
                                                top: 0,
                                                right: 0,
                                                width: 1,
                                                height: 1,
                                                zIndex: 1050,
                                                backgroundColor: '#000000',
                                            }}
                                        >
                                            <video id={"tempVideoSwitch"} autoPlay={true} playsInline={true}>
                                            </video>
                                        </div>
                                        {/*{this.getIsWebRtc(Type.broadcastVideoEl.MAIN) ?*/}
                                            <video className={classes.video}
                                                   id={Type.broadcastVideoEl.MAIN}
                                                   style={{
                                                       // zIndex: '1052',
                                                       outline: "none",
                                                   }}
                                                   onClick={(event) => {this.playerOnOffHandle(Type.broadcastVideoEl.MAIN)}}
                                            >
                                            </video>
                                            {/*: ""}*/}
                                        {/*{!this.getIsWebRtc(Type.broadcastVideoEl.MAIN) ?*/}
                                        {/*    <canvas className={classes.video}*/}
                                        {/*            id={Type.broadcastVideoEl.MAIN}*/}
                                        {/*            width={1920}*/}
                                        {/*            height={1080}*/}
                                        {/*            onClick={() => {this.playerOnOffHandle(Type.broadcastVideoEl.MAIN)}}*/}
                                        {/*            onMouseOver={() => {this.handleVideoMouseOver(true)}}*/}
                                        {/*            onMouseOut={() => {this.handleVideoMouseOver(false)}}*/}
                                        {/*    >*/}
                                        {/*    </canvas>*/}
                                        {/*    : ""}*/}
                                        {this.getScreenView(Type.broadcastVideoEl.MAIN) && this.props.broadcastStore.isVideoTrackOff && !isFirstPlayCheck ? <BroadcastVideoTrackEmpty/> : ""}
                                        {/* <Divider/> */}
                                    </Grid>
                                    {/*{deviceBrowserType.browserType !== browserType.safari ?*/}
                                    {/*<Hidden xsDown>*/}
                                    {/*    <Grid item xs={12}*/}
                                    {/*          style={{*/}
                                    {/*              position: "relative",*/}
                                    {/*              minHeight: '45px'*/}
                                    {/*          }}*/}
                                    {/*    >*/}
                                    {/*        <img src={"/images/p-foot.png"} alt={""} width={"100%"} height={"100%"}/>*/}
                                    {/*        <div className={classes.pFootActionArea}>*/}
                                    {/*            <div style={{ paddingLeft: '50px', cursor: "pointer"}}*/}
                                    {/*                 onClick={() => this.handleSoundToggle(Type.broadcastVideoEl.MAIN)}*/}
                                    {/*            >*/}
                                    {/*                {this.getScreenSound(Type.broadcastVideoEl.MAIN)}*/}
                                    {/*            </div>*/}

                                    {/*            <div*/}
                                    {/*                style={{*/}
                                    {/*                    display: 'flex',*/}
                                    {/*                    width: '100%'*/}
                                    {/*                }}*/}
                                    {/*            />*/}

                                    {/*            {channel.channelBroadcastEndpointList.length === 2 ?*/}
                                    {/*                <div style={{ paddingRight: '20px', cursor: "pointer"}}*/}
                                    {/*                     onClick={this.handleChatBroadcastSwitch}*/}
                                    {/*                >*/}
                                    {/*                    <img src={"/images/imgtransition-icon.png"} alt={""} width={30}/>*/}
                                    {/*                </div>*/}
                                    {/*                : ""}*/}

                                    {/*            <div style={{ paddingRight: '50px', cursor: "pointer" }}*/}
                                    {/*                 onClick={isWebRtc ? handleWebRtcFullScreen  : this.handleFullScreen}*/}
                                    {/*            >*/}
                                    {/*                <img src={"/images/full-icon.png"} alt={""} width={30}/>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*    </Grid>*/}
                                    {/*</Hidden>*/}
                                    {/*    :*/}
                                    {/*    ""*/}
                                    {/*}*/}

                                    <Grid item xs={12}>
                                        <Paper elevation={0} className={classes.videoCommentPaper}>
                                            <ul className={classes.videoCommentDesc}>
                                                <li className={classes.videoTitle}>{channel ? channel.channelName : ""}
                                                    {/*<span>({!countDown || typeof countDown !== "object" ? countDown : countDown.days+"일 "+countDown.hours+"시 "+countDown.minutes+"분 "+countDown.seconds+"초 남았습니다"})</span>*/}
                                                    <span className={classes.videoAssignment}>참여자 : {chatUserList? chatUserList.length : 0}</span>
                                                </li>
                                                <Hidden xsDown>
                                                    <li className={classes.videoDate}>{startDateTime + " ~ " + endDateTime}</li>
                                                    <li className={classes.videoDesc}>{channel ? channel.channelDesc : ""}</li>
                                                </Hidden>
                                            </ul>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <Hidden smUp>
                        <MobileBroadcastChat {...this.props.broadcastStore}
                                             handleChatTab={this.handleChatTab}
                                             handleChangeChatMsg={this.handleChangeChatMsg}
                                             handleChatScroll={this.handleChatScroll}
                                             handleKeyUpChatMsg = {this.handleKeyUpChatMsg}
                                             handleChatMsgSend = {this.handleChatMsgSend}
                                             handleDoQuestion = {Chat.handleDoQuestion}
                                             handleDoStopQuestion = {Chat.handleDoStopQuestion}
                                             handleDoAnnounce = {Chat.handleDoAnnounce}
                                             handleDoEndPresentation = {Chat.handleDoEndPresentation}
                                             handleAllowQuestion = {Chat.handleAllowQuestion}
                                             handleDoResetPresentation = {Chat.handleDoResetPresentation}
                        />
                    </Hidden>
                    {/*<ChannelAttendanceDialog userId={this.props.broadcastStore.userId} />*/}
                    <BroadcastAttendReqeuestDialog open={this.props.broadcastStore.isAttendRequestDialogOpen}
                                                   handleClose={() => this.props.broadcastStore.changeAttendRequestDialogOpen(false)}
                                                   handleChatAttendance={this.handleChatAttendance}
                                                   handleChatAttendanceLate={this.handleChatAttendanceLate}
                    />
                    <BroadcastAttendDialog open={attendDialogStatus}
                                           msg={attendDialogMsg}
                                           msgError={attendDialogMsgError}
                                           msgHelperText={attendDialogMsgHelperText}
                                           changeStatus={changeAttendDialogStatus}
                                           changeMsg={changeAttendDialogMsg}
                                           doAttend={this.handleDoAttend}
                    />
                    <ChannelFileDialog userId={userId} />
                    <BroadcastChannelExitDialog open={channelExitDialogOpen}
                                                history={this.props.history}
                                                channel={channel}
                                                userId={userId}
                                                handleClose={() => changeChannelExitDialogOpen(false)}
                                                handleBroadcastBlock = {Chat.handleBroadcastBlock}
                    />
                    <BroadcastChannelEndDialog open={channelEndDialogOpen}
                                               history={this.props.history}
                                               handleClose={() => changeChannelEndDialogOpen(false)}
                                               handleChannelEnd={this.doChannelEnd}
                    />
                    <BroadcastQuizDialog/>
                    <BroadcastQuizResultDialog/>
                    <BroadcastMainPublishDialog open={this.props.broadcastStore.mainPublishDialogOpen}
                                                selectedMainPublishType = {this.props.broadcastStore.selectedMainPublishType}
                                                webRtcSubPublishType = {this.props.broadcastStore.subPublishType}
                                                webRtcCameraList = {this.props.broadcastStore.webRtcCameraList}
                                                selectedWebRtcCamera = {this.props.broadcastStore.selectedWebRtcCamera}

                                                changeWebRtcCamera = {this.props.broadcastStore.changeWebRtcCamera}
                                                handleClose={() => this.props.broadcastStore.changeMainPublishDialogOpen(false)}
                                                changePublishType = {this.props.broadcastStore.changeSelectedMainPublishType}
                                                playerOnOffHandle = {Chat.handleMainScreenView}
                                                handleWebRtcSwitch = {Chat.handleWebRtcSwitch}
                                                mobileCheck = {this.mobileCheck}
                    />
                    <BroadcastSubPublishDialog open={subPublishDialogOpen}
                                               selectedSubPublishType = {selectedSubPublishType}
                                               webRtcMainPublishType = {mainPublishType}
                                               webRtcCameraList = {this.props.broadcastStore.webRtcCameraList}
                                               selectedWebRtcCamera = {this.props.broadcastStore.selectedSubWebRtcCamera}

                                               changeWebRtcCamera = {this.props.broadcastStore.changeSubWebRtcCamera}
                                               handleClose={() => changeSubPublishDialogOpen(false)}
                                               changePublishType = {changeSelectedSubPublishType}
                                               playerOnOffHandle = {Chat.handleSubScreenView}
                                               mobileCheck = {this.mobileCheck}
                    />
                    <BroadcastResetDialog open = {this.props.broadcastStore.resetDialogOpen}
                                          handleClose = {() => this.props.broadcastStore.changeResetDialogOpen(false)}
                                          doRest = {Chat.handleDoResetPresentation}
                    />
                    <BroadcastTeacherLookAroundDialog open={this.props.broadcastStore.isTeacherLookAroundOpen}
                                                      userInfo={this.props.broadcastStore.userInfo}
                                                      chatUserList={toJS(chatUserList)}
                                                      channel={this.props.broadcastStore.channel}
                                                      lookAroundUnderstandList={this.props.broadcastStore.lookAroundUnderstandList}
                                                      mutedControl={this.props.broadcastStore.lookAroundMutedControl}
                                                      isLookAroundSoundOn={isLookAroundSoundOn}
                                                      handleClose = {() => {
                                                          this.props.broadcastStore.changeTeacherLookAroundOpen(false);
                                                          this.props.broadcastStore.lookAroundJanusUnPublish();
                                                          Chat.handleWebRtcPublisherRoomJoined(JSON.stringify({
                                                              roomType: janusRoomType.LOOK_AROUND,
                                                              joinType: null,
                                                              closed: true,
                                                              handlerType: null,
                                                              feed: null,
                                                              publisherUserId: null,
                                                          }));
                                                      }}
                                                      chatSendControlLookAroundUnderstand = {() => {}}
                                                      chatDoAnnounce = {this.props.broadcastStore.chatDoAnnounce}
                    />
                    <BroadcastScreenOffDialog open = {this.props.broadcastStore.screenOffDialogOpen}
                                              doOff = {this.playerOnOffHandle}
                                              offHandle = {this.props.broadcastStore.screenOffDialogTarget === Type.broadcastType.MAIN ? Chat.handleMainScreenView : Chat.handleSubScreenView}
                                              handleClose = {() => {
                                                  this.props.broadcastStore.changeScreenOffDialogOpen(false);
                                              }}
                    />
                    <BroadcastNetworkErrorDialog open = {this.props.broadcastStore.networkErrorDialogOpen}
                                                 history = {this.props.history}
                                                 handleClose = {() => {
                                                     this.props.broadcastStore.changeNetworkErrorDialogOpen(false);
                                                 }}
                    />
                    <BroadcastAlreadyUserDialog open = {this.props.broadcastStore.alreadyUserDialogOpen}
                                                history = {this.props.history}
                                                handleClose = {() => {
                                                    this.props.broadcastStore.changeAlreadyUserDialogOpen(false);
                                                }}
                                                handleConfirm = {() => {
                                                    this.props.broadcastStore.chatStartWithPushOut();
                                                    this.props.broadcastStore.changeAlreadyUserDialogOpen(false);
                                                }}

                    />
                    <BroadcastDuplicateUserDialog open = {this.props.broadcastStore.duplicateUserDialogOpen}
                                                  handleClose = {() => this.props.broadcastStore.changeDuplicateUserDialogOpen(false)}
                                                  handleConfirm = {() => {
                                                      this.props.broadcastStore.changeDuplicateUserDialogOpen(false);
                                                      this.props.history.push("/channel/list");
                                                  }}
                    />
                    <BroadcastLookAroundPublishDialog open = {this.props.broadcastStore.lookAroundPublishDialogOpen}
                                                      webRtcCameraList = {this.props.broadcastStore.webRtcCameraList}
                                                      selectedLookAroundCamera = {this.props.broadcastStore.selectedLookAroundCamera}
                                                      changeWebRtcCamera = {this.props.broadcastStore.changeSelectedLookAroundCamera}
                                                      handleClose = {() => this.props.broadcastStore.changeLookAroundPublishDialogOpen(false)}
                                                      handleConfirm = {() => {
                                                          this.props.broadcastStore.changeLookAroundPublishDialogOpen(false);
                                                          this.props.broadcastStore.changeTeacherLookAroundOpen(true);
                                                          this.props.broadcastStore.initLookAroundJanusPublisher();

                                                          // window.open(`/lookaround/teacher/${channel.channelId}/${this.props.broadcastStore.selectedLookAroundCamera}`);

                                                          Chat.handleWebRtcPublisherRoomJoined(JSON.stringify({
                                                              roomType: janusRoomType.LOOK_AROUND,
                                                              joinType: janusJoinPType.subscriber,
                                                              closed: false,
                                                              handlerType: null,
                                                              feed: userId,
                                                              publisherUserId: userId,
                                                          }));
                                                      }}
                    />
                    <BroadcastLookAroundErrorDialog open = {this.props.broadcastStore.lookAroundErrorDialogOpen}
                                                    handleClose = {() => this.props.broadcastStore.changeLookAroundErrorDialogOpen(false)}
                    />
                    <BroadcastPresentationErrorDialog open = {this.props.broadcastStore.presentationErrorDialogOpen}
                                                      handleClose = {() => this.props.broadcastStore.changePresentationErrorDialogOpen(false)}
                    />
                </div>
                <ChannelAttendanceDialog loginType={this.props.loginType} userId={this.props.userId} />
                {/*<Hidden smDown>*/}
                {/*    <Footer />*/}
                {/*</Hidden>*/}
            </div>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (Broadcast)));

import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Avatar,
    Box,
    Button,
    IconButton,
    InputBase,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    MenuItem,
    MenuList,
    Popover,
    Tab,
    Slider,
    Tabs,
    Typography,
    withStyles
} from "@material-ui/core";
import grey from "@material-ui/core/colors/grey";
import Accordion from '@material-ui/core/Accordion';
import {ResponsiveBar} from "@nivo/bar";
import {green} from "@material-ui/core/colors";
import AddIcon from "@material-ui/icons/Add";
import MessageIcon from "@material-ui/icons/Message";
import LinkIcon from "@material-ui/icons/Link";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PersonIcon from "@material-ui/icons/Person";
import HowToRegIcon from '@material-ui/icons/HowToReg';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import PeopleIcon from '@material-ui/icons/People';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import SmsIcon from '@material-ui/icons/Sms';
import PollIcon from '@material-ui/icons/Poll';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import moment from "moment";
import {inject, observer} from "mobx-react";

import {ChatTabIndex, MemberPublishState, MessageType, RoomMode, VideoState} from "./api/RoomPresenter2";
import Badge from "@material-ui/core/Badge";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import Link from "@material-ui/core/Link";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import BrushIcon from '@material-ui/icons/Brush';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import TextFieldsIcon from '@material-ui/icons/TextFields';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {ModeType} from "./api/WhiteBoard";
import _ from 'lodash';
import {PresentationType} from "../../stores/RoomStore";
import {DeleteOutlined} from "@material-ui/icons";

import { ReactComponent as BrushIconOn1 } from '../../common/images/BrushIconOn1.svg';
import { ReactComponent as BrushIconWhite1 } from '../../common/images/BrushIconWhite1.svg';
import { ReactComponent as TextWhiteIcon } from '../../common/images/TextWhiteIcon.svg';
import { ReactComponent as EraserGray } from '../../common/images/EraserGray.svg';
import { ReactComponent as GroupIcon } from '../../common/images/GroupIcon.svg';
import { ReactComponent as EraserWhite } from '../../common/images/EraserWhite.svg';
import { ReactComponent as SubtractIcon } from '../../common/images/subtractIcon.svg';
import { ReactComponent as PlusIcon } from '../../common/images/plusIcon.svg';


import HtmlDragDrop from "./tinyGroup/HtmlDragDrop";
import {PUBLISH_BUTTON_TIMEOUT} from "../../stores/RoomStore";
import {TINY_GROUP_CHAT_DROP_ZONE_ID} from "../../stores/TinyGroupStore";
import Divider from "@material-ui/core/Divider";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Tooltip from "@material-ui/core/Tooltip";
import fileDownload from "js-file-download";


const styles = theme => ({
    mainControlCommandArea: {
        pointer: 'cursor',
    },
    mainControlChatArea: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexBasis: '100%',
    },

    tabContainer: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(2),
    },
    tab: {
        minWidth: 95,
        maxWidth: 95,
    },
    smallTab: {
        minWidth: 70,
        maxWidth: 70,
    },
    tabButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    moreButton:{
        borderRadius:'5px',
        minWidth: 100,
        height: 38,
        '& span':{
            fontSize: 14,
            fontWeight: 700,
        },
        // '&:hover, &:focus':{
        //     background:'none'
        // }
    },

    noticeContainer: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        border: '1px solid',
        borderRadius: '4px',
        borderColor: grey[500],
        backgroundColor: 'rgba(191, 49, 40, 0.12)'
    },
    noticeContainerWithLeftMargin: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        border: '1px solid',
        borderRadius: '4px',
        borderColor: grey[500],
        backgroundColor: 'rgba(191, 49, 40, 0.12)'
    },

    chatContainer: {
        [theme.breakpoints.down('xs')]: {
            height: '200px',
        },
        flexGrow: 1,
        flexShrink: 1,
        paddingTop: 8,
        paddingBottom: 8,
        height: '1px',
        overflowY: 'scroll',
    },
    mobilechatContainer:{
        flexGrow: 1,
        flexShrink: 1,
        paddingTop: 8,
        paddingBottom: 8,
        height: '200px',
        overflowY: 'scroll',
    },
    chatArea: {
        margin: 0,
        paddingTop: theme.spacing(1),
        paddingLef: 0,
        paddingRight: 0,
        paddingBottom: 0,
    },

    chatMsgContainer:{
        alignItems:'flex-start',
    },
    chatMyMsgContainer:{
        alignItems:'flex-start',
        flexDirection: 'row-reverse',
        textAlign: 'right',
    },
    chatMsgTextContainer: {
        padding: 0,
        margin: 0,
    },

    chatFrom: {
        fontWeight:'600',
        fontSize:'12px',
        color:'rgba(0, 0, 0, 0.54)',
        marginBottom:'4px',
        textAlign: 'left',
    },
    chatOwnerMsg: {
        backgroundColor: '#3c3c3c',
        padding: '3px 8px',
        borderRadius: '0px 10px 10px 10px',
        boxShadow:'0 1px 4px #eaeaea',
        display:'inline-block',
        fontWeight:'500',
        fontSize:'13px',
        color: '#ffffff',
    },
    chatOwnerWhisperMsg: {
        opacity: 0.5,
        backgroundColor: '#3c3c3c',
        padding: '3px 8px',
        borderRadius: '0px 10px 10px 10px',
        boxShadow:'0 1px 4px #eaeaea',
        display:'inline-block',
        fontWeight:'500',
        fontSize:'13px',
        color: '#ffffff',
    },
    chatMemberMsg: {
        backgroundColor: '#fff',
        padding: '3px 8px',
        borderRadius: '0px 10px 10px 10px',
        boxShadow:'0 1px 4px #eaeaea',
        display:'inline-block',
        fontWeight:'500',
        fontSize:'13px',
        color:'#333',
    },
    chatMemberWhisperMsg: {
        opacity: 0.5,
        backgroundColor: '#fff',
        padding: '3px 8px',
        borderRadius: '0px 10px 10px 10px',
        boxShadow:'0 1px 4px #eaeaea',
        display:'inline-block',
        fontWeight:'500',
        fontSize:'13px',
        color:'#333',
    },
    chatOwnerMineMsg: {
        backgroundColor: '#3c3c3c',
        padding: '3px 8px',
        borderRadius: '10px 0px 10px 10px',
        boxShadow:'0 1px 4px #eaeaea',
        display:'inline-block',
        fontWeight:'500',
        fontSize:'13px',
        color: '#ffffff',
    },
    chatOwnerMineWhisperMsg: {
        opacity: 0.5,
        backgroundColor: '#3c3c3c',
        padding: '3px 8px',
        borderRadius: '10px 0px 10px 10px',
        boxShadow:'0 1px 4px #eaeaea',
        display:'inline-block',
        fontWeight:'500',
        fontSize:'13px',
        color: '#ffffff',
    },
    chatMemberMineMsg: {
        backgroundColor: '#fff',
        padding: '3px 8px',
        borderRadius: '10px 0px 10px 10px',
        boxShadow:'0 1px 4px #eaeaea',
        display:'inline-block',
        fontWeight:'500',
        fontSize:'13px',
        color:'#333',
    },
    chatMemberMineWhisperMsg: {
        opacity: 0.5,
        backgroundColor: '#fff',
        padding: '3px 8px',
        borderRadius: '10px 0px 10px 10px',
        boxShadow:'0 1px 4px #eaeaea',
        display:'inline-block',
        fontWeight:'500',
        fontSize:'13px',
        color:'#333',
    },
    chatTime:{
        fontSize: '9px',
        float: 'left',
        flexDirection: 'column',
        // marginTop: 'auto',
        alignItems: 'flex-end',
        // display: 'flex',
        color: '#9b9b9b',
        marginRight: '6px',
        marginTop: '6px',
        width: '100%',
        textAlign: 'left',
    },
    chatTimeMine:{
        fontSize: '9px',
        float: 'right',
        flexDirection: 'column',
        // marginTop: 'auto',
        alignItems: 'flex-end',
        // display: 'flex',
        color: '#9b9b9b',
        marginRight: '6px',
        marginTop: '6px',
        width: '100%',
        textAlign: 'right',
    },

    notJoinedAvatar: {

    },
    joinedAvatar: {
        backgroundColor: theme.palette.primary.main,
    },
    mediaOn: {
        width: 20,
        height: 20,
        color: green[300],
    },

    mediaOff: {
        width: 20,
        height: 20,
        color: grey[300],
    },

    commandContainer: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },

    pollContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
    pollTitleContainer: {
        padding: theme.spacing(1),
    },
    pollTitle: {
        fontSize: 16,
    },
    formControl: {
    },
    whiteBoard:{
        [theme.breakpoints.down('md')]: {
            paddingLeft:20,
            paddingRight:20
        },

    },
    whiteBoardButtonGroup: {
        width: '92px',
        height: '30px',
        fontSize: '14px',
        marginBottom: '16px',
        wordBreak: "keep-all",
        boxShadow: '0 3px 4px 0 rgba(0, 0, 0, 0.16)'
    },
    whiteBoardButtonGroupIcon: {
        width: '18px',
        height: '18px',
        marginRight: '4px',
    },
    whiteBoardButtonEraser: {
        width: '120px',
        height: '40ox',
        // marginRight: '16px',
        wordBreak: "keep-all",
    },

    inputSelect:{
        "& .MuiAccordionSummary-root.Mui-focused":{
            background:'transparent'
        },
    },
    tinyGroupBox:{
        "& .MuiButton-root.Mui-disabled":{
            background:'#e2e2e2',
            color:'#808080',
        }
    },
    tinyGroupBox1:{
        [theme.breakpoints.down('xs')]: {
            padding:'5px 5px',
        },
        border:'1px solid #707070',
        padding:'5px 10px',
        borderRadius:18,
        "& .MuiButton-root.Mui-disabled":{
            background:'#e2e2e2',
            color:'#808080',
        }
    },
    tinyBox:{
        marginRight: theme.spacing(1),
        marginBottom:5,
        textAlign:'center'
    },
    tinyText:{
        [theme.breakpoints.down('xs')]: {
            fontSize: 11,
        },
        fontFamily: 'Noto Sans KR',
        fontSize: '14px',
        fontWeight: 'bold',
    },
    tinyGroupMenuButton: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: 8,
            width:'45%',
            height:'50px'
        },
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        borderRadius: 6,
        background:'#6c6c70',
        fontSize:14,
        fontFamily:'Noto Sans KR',
        // border:'1px solid #7d7d7d',
        color:'#fff',
        // boxShadow: '0 3px 4px 0 rgba(0, 0, 0, 0.16)',
        "&:hover":{
            background: theme.palette.primary.main,
            color:'#fff',
        }
    },
    tinyGroupMenuButton1: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: 8,
            width:'23%',
            height:'50px',
            fontSize:11,
            padding:'5px 10px',
            marginRight: 5,
        },
        width:90,
        marginRight: theme.spacing(1),
        borderRadius: 6,
        background:'#6c6c70',
        fontSize:14,
        fontFamily:'Noto Sans KR',
        // border:'1px solid #7d7d7d',
        color:'#fff',
        padding:'6px 10px',
        // boxShadow: '0 3px 4px 0 rgba(0, 0, 0, 0.16)',
        "&:hover":{
            background: theme.palette.primary.main,
            color:'#fff',
        }
    },

    tinyGroupTitleButton: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: 8,
            width:'40%',
            height:'50px'
        },
        marginLeft: 10,
        padding:'10px',
        // marginRight: theme.spacing(1),
        borderRadius: 6,
        background:'#e2e2e2',
        fontSize:12,
        color:'#808080',
        boxShadow: 'none',
        fontFamily:'Noto Sans KR',
        "&:hover":{
            background: theme.palette.primary.main,
            color:'#fff',
        }
    },
});

const LogPrefix = '[RoomChatComponent] ';
const InputMode = {
    Message: 'Message',
    Link: 'Link',
    Notification: 'Notification',
};

const WhiteBoardSlider = withStyles(theme => ({
    root: {
        // color: '#3594f3',
        color: theme.primary,
    },
    thumb: {
        height: 8,
        width: 8,
        marginTop: -3,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
}))(Slider);

const BootstrapInput = withStyles((theme) => ({
    input: {
        position: 'relative',
        border: '1px solid #a4a4a4',
        fontSize: 12,
        padding: '0px 26px 0px 12px',
        width: '80px',
        height: '20px'
    },
}))(InputBase);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        },
    },
};

@inject('roomStore', 'pollStore', 'whiteBoardStore', 'tinyGroupStore', 'publishStore')
@observer
class RoomChatComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            commandMenuAnchor: null,
            inputMessage: '',

            inputMode: InputMode.Message,
            isOpenChatAddMenu: false,
        };

        this.chatContainerRef = React.createRef();
        this.chatAddButtonRef = React.createRef();
    }

    componentDidMount() {
        console.log(LogPrefix, 'Component did mount');

        this.props.pollStore.initializeStore();
        this.props.whiteBoardStore.initialize();
        if(this.props.roomStore.storeInitialized && !this.props.whiteBoardStore.whiteBoard) {
            console.log(LogPrefix, 'init WhiteBoard',this.props.roomStore.isPublisher, this.props.roomStore.roomId,  this.props.whiteBoardRef, this.props.onWhiteBoardDataChanged);
            this.props.whiteBoardStore.initWhiteBoard(this.props.roomStore.isPublisher, this.props.roomStore.roomId, this.props.whiteBoardRef, this.props.onWhiteBoardDataChanged);
        }
    }

    componentWillUnmount() {
        this.changeSelectedTab(ChatTabIndex.Chat);

        this.props.whiteBoardStore.destroy();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(LogPrefix, 'Component did update');

        if (this.props.roomStore.selectedChatTab === ChatTabIndex.Chat) {
            this.chatContainerRef.current.scrollTop = this.chatContainerRef.current.scrollHeight;
        }

        if(this.props.roomStore.storeInitialized && !this.props.whiteBoardStore.whiteBoard) {
            console.log(LogPrefix, 'init WhiteBoard',this.props.roomStore.isPublisher, this.props.roomStore.roomId,  this.props.whiteBoardRef, this.props.onWhiteBoardDataChanged);
            this.props.whiteBoardStore.initWhiteBoard(this.props.roomStore.isPublisher, this.props.roomStore.roomId, this.props.whiteBoardRef, this.props.onWhiteBoardDataChanged);
        }

        // if(this.props.roomStore.role === 'publisher') {
        //     if (this.props.roomStore.selectedChatTab === ChatTabIndex.WhiteBoard) {
        //         if (!this.props.roomStore.whiteBoard) {
        //             this.props.roomStore.initWhiteBoard(this.props.whiteBoardRef, this.props.onSendWhiteBoardData);
        //             this.props.roomStore.getWhiteBoardHistory(this.props.getBoundingVideoRect());
        //         } else {
        //             this.props.roomStore.setWhiteBoardData(this.props.getBoundingVideoRect());
        //             if(this.props.onSendWhiteBoardData) this.props.onSendWhiteBoardData(this.props.roomStore.whiteBoard.getSyncSignal(true));
        //         }
        //     } else {
        //         if (this.props.roomStore.whiteBoard) {
        //             this.props.roomStore.whiteBoard.removeInput();
        //             if (this.props.onSendWhiteBoardData) this.props.onSendWhiteBoardData(this.props.roomStore.whiteBoard.getSyncSignal(false));
        //         }
        //     }
        // }
    }

    changeSelectedTab = (tabIndex) => {
        this.props.roomStore.setSelectedChatTab(tabIndex);

        if(tabIndex === ChatTabIndex.Chat) this.props.roomStore.setNewChatBadgeInvisible(true);

        if(tabIndex === ChatTabIndex.TinyGroup && (!this.props.tinyGroupStore.members || (this.props.tinyGroupStore.members.length <= 0 && this.props.tinyGroupStore.tinyGroups.length <= 0))) {
            this.props.tinyGroupStore.init();
            this.props.tinyGroupStore.initTinyGroupMaxUserCount();
            this.props.tinyGroupStore.setOwner(_.cloneDeep(this.props.roomStore.chatMembers.find(m => m.owner)));
            this.props.tinyGroupStore.setMembers(_.cloneDeep(this.props.roomStore.chatMembers.filter(m => !m.owner)));
        }

        this.props.onWhiteBoardVisibleChanged(tabIndex === ChatTabIndex.WhiteBoard);
    }

    handleChangeInputMessage = (event) => {
        this.setState({inputMessage: event.target.value});
        //this.props.roomStore.setInputMessage(event.target.value);
    }

    handleKeyUpInputMessage = (event) => {
        if(event.keyCode === 13) {
            this.handleClickSendMessage();
        }
    }

    handleClickSendMessage = () => {
        const msg = this.state.inputMessage;

        if(msg) {
            if(this.state.inputMode === InputMode.Notification) {
                this.props.onSendChatNotification(msg);
            } else if(this.state.inputMode === InputMode.Link) {
                this.props.onSendChatLink(msg);
            } else {
                const { whisperTarget } = this.props.roomStore;
                if(whisperTarget.id === "all") this.props.onSendChatMessage(msg);
                else this.props.onSendChatWhisperMessage(msg, whisperTarget.id);
            }
            this.setState({
                inputMessage: '',
                inputMode: InputMode.Message,
            });
        }
    }

    handleClickCommandOpen = (event) => {
        this.setState({commandMenuAnchor: event.currentTarget});
    }

    handleClickCommandClose = () => {
        this.setState({commandMenuAnchor: null});
    }

    handleChangeTab = (event, value) => {
        console.log(LogPrefix, 'Changed tab value : ' + value);

        this.changeSelectedTab(value);
    }

    handleClickRequestPublish = () => {
        this.setState({commandMenuAnchor: null});

        if(this.props.onRequestPublish) {
            this.props.onRequestPublish();
        }
    }

    handleClickRequestPresentationPublish = (userId) => {
        this.setState({commandMenuAnchor: null});

        if(this.props.onRequestPresentationPublish) {
            this.props.onRequestPresentationPublish(userId);
        }
    }

    handleClickConfirmPublish = (userId) => {
        if(this.props.onConfirmPublish) {
            this.props.onConfirmPublish(userId);
        }
    }

    handleClickStopMemberPublish = (memberId) => {
        if(this.props.onStopMemberPublish) {
            this.props.onStopMemberPublish(memberId);
        }
    }

    handleOpenAttendance = () => {
        this.setState({commandMenuAnchor: null});

        if(this.props.onOpenAttendance) {
            const {channel} = this.props.roomStore;
            this.props.onOpenAttendance(channel);
        }

    }

    handleOpenAttendanceRequestDialog = () => {
        this.setState({commandMenuAnchor: null});

        if(this.props.onOpenAttendRequestDialog) {
            this.props.onOpenAttendRequestDialog(true);
        }
    }

    handleOpenQuizDialog = () => {
        this.setState({commandMenuAnchor: null});

        if(this.props.onOpenQuizDialog) {
            this.props.onOpenQuizDialog();
        }
    }

    handleOpenQuizResult = () => {
        this.setState({commandMenuAnchor: null});

        if(this.props.onOpenQuizResult) {
            this.props.onOpenQuizResult();
        }
    }

    handleOpenPollDialog = () => {
        this.setState({commandMenuAnchor: null});

        if(this.props.onOpenPollDialog) {
            this.props.onOpenPollDialog();
        }
    }

    handleToggleChatAddMenu = () => {
        this.setState({isOpenChatAddMenu: !this.state.isOpenChatAddMenu});
    }

    handleCloseChatAddMenu = () => {
        this.setState({isOpenChatAddMenu: false});
    }

    handleChangeToMessageMode = () => {
        this.setState({
            isOpenChatAddMenu: false,
            inputMode: InputMode.Message,
            inputMessage: '',
        });

    }

    handleChangeToLinkMode = () => {
        this.setState({
            isOpenChatAddMenu: false,
            inputMode: InputMode.Link,
            inputMessage: '',
        });
    }

    handleChangeToNotificationMode = () => {
        this.setState({
            isOpenChatAddMenu: false,
            inputMode: InputMode.Notification,
            inputMessage: '',
        });
    }

    handleOpenFileDialog = () => {
        if(this.props.onOpenFileDialog) {
            this.props.onOpenFileDialog();
        }
    }

    handleStartTinyGroup = () => {
        this.props.publishStore.setPublishButtonClicked(true);
        setTimeout(() => this.props.publishStore.setPublishButtonClicked(false), PUBLISH_BUTTON_TIMEOUT + 2000);

        this.props.onStartTinyGroup();
    }

    render() {
        const {classes, tinyGroupStore} = this.props;
        const {roomUserId, isMobile, role, isPublisher, landscape, selectedChatTab, notificationMsg, chatMessages, chatMembers, userId,  memberPublishRequestCount, whisperTargetMembers, whisperTarget, isChattingPossible,isMobileLandscape, isPublishButtonClicked, isNewChatBadgeInvisible} = this.props.roomStore;
        const {mode, primaryState} = this.props.publishStore;
        const { lineWidth, fontSize, canvasAlpha } = this.props.whiteBoardStore;
        const {pollResult, pollResultItems} = this.props.pollStore;

        const getChatMessageClassName = (msg) => {
            if(msg.from === userId) {
                return classes.chatMemberMineMsg;
            } else if(msg.from === roomUserId) {
                return classes.chatOwnerMsg;
            } else {
                return classes.chatMemberMsg;
            }
        }

        const getChatWhisperMessageClassName = (msg) => {
            if(msg.from === userId) {
                return classes.chatMemberMineWhisperMsg;
            } else if(msg.from === roomUserId) {
                return classes.chatOwnerWhisperMsg;
            } else {
                return classes.chatMemberWhisperMsg;
            }
        }

        const getDeviceBrowserTypeString = (member) => {
            let device = '';
            let browser = '';

            if(member.deviceType) {
                if(member.deviceType === 'android') {
                    device = 'Android';
                } else if(member.deviceType === 'ios') {
                    device = 'iOS';
                } else if(member.deviceType === 'etc') {
                    device = 'PC';
                } else {
                    device = '알수 없음';
                }
            }

            if(member.browserType) {
                if(member.browserType === 'kakaotalk') {
                    browser = '카카오톡';
                } else if(member.browserType === 'samsungbrowser') {
                    browser = '삼성브라우저';
                } else if (member.browserType === 'chrome') {
                    browser = '크롬';
                } else if(member.browserType === 'safari') {
                    browser = '사파리';
                } else {
                    browser = '알수 없음';
                }
            }

            return device || browser ? `(${device}, ${browser})` : '';
        }

        const chartXValues = [];
        if(chatMembers) {
            for(let i in chatMembers) {
                chartXValues.push(Number(i));
            }
        }

        const BarComponent = props => {
            console.log('props', props);
            return (
                <g transform={`translate(${props.x},${props.y})`}>
                    <rect
                        x={-3}
                        y={7}
                        width={props.width}
                        height={props.height}
                        fill="rgba(0, 0, 0, .07)"
                    />
                    <rect width={props.width} height={props.height} fill={props.color} />
                    <rect
                        x={props.width - 5}
                        width={5}
                        height={props.height}
                        fill={props.borderColor}
                        fillOpacity={0.2}
                    />
                    <text
                        x={5}
                        y={props.height / 2 - 8}
                        textAnchor="start"
                        dominantBaseline="central"
                        fill="black"
                        style={{
                            fontWeight: 900,
                            fontSize: 15,
                        }}
                    >
                        {props.data.indexValue}
                    </text>
                    <text
                        x={5}
                        y={props.height / 2 + 10}
                        textAnchor="start"
                        dominantBaseline="central"
                        fill={props.borderColor}
                        style={{
                            fontWeight: 400,
                            fontSize: 13,
                        }}
                    >
                        {props.data.value}
                    </text>
                </g>
            )
        }


        return (
            <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="stretch" flexGrow={1} flexShrink={10}>
                <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" className={classes.tabContainer}>
                    <Tabs textColor="primary"
                          variant="fullWidth"
                          value={selectedChatTab}
                          onChange={this.handleChangeTab}
                          style={{flexGrow: 1}}
                    >
                        {role === 'publisher' ?
                            [
                                <Tab key={'chat-message'} icon={
                                    <Tooltip title={"채팅"}>
                                        <Badge className={classes.badge} invisible={isNewChatBadgeInvisible} badgeContent={"N"} color="primary">
                                            <SmsIcon/>
                                        </Badge>
                                    </Tooltip>
                                } style={{minWidth: 70}} />,
                                <Tab key={'chat-member'} icon={
                                    <Tooltip title={"참여자"}>
                                        <Badge badgeContent={memberPublishRequestCount} color="primary"><PeopleIcon/></Badge>
                                    </Tooltip>
                                } style={{minWidth: 70}} />,
                                <Tab key={'chat-poll'} icon={
                                    <Tooltip title={"설문조사"}>
                                        <PollIcon />
                                    </Tooltip>
                                } style={{minWidth: 70}} />,
                                <Tab key={'chat-tiny-group'} icon={
                                    <Tooltip title={"회의"}>
                                        <GroupIcon />
                                    </Tooltip>
                                } style={{minWidth: 70}} />,
                                <Tab key={'chat-white-board'} icon={
                                    <Tooltip title={"필기"}>
                                        <BrushIcon />
                                    </Tooltip>
                                } style={{minWidth: 70}} />,
                            ]
                            :
                            [
                                <Tab key={'chat-message'} icon={
                                    <Tooltip title={"채팅"}>
                                        <Badge className={classes.badge} invisible={isNewChatBadgeInvisible} badgeContent={"N"} color="primary">
                                            <SmsIcon/>
                                        </Badge>
                                    </Tooltip>
                                } style={isMobile ? {minWidth: 70} : {minWidth: 85}} />,
                                <Tab key={'chat-member'} icon={
                                    <Tooltip title={"참여자"}>
                                        <PeopleIcon/>
                                    </Tooltip>
                                } style={isMobile ? {minWidth: 70} : {minWidth: 85}} />,
                                <Tab key={'chat-poll'} icon={
                                    <Tooltip title={"설문조사"}>
                                        <PollIcon />
                                    </Tooltip>
                                } style={isMobile ? {minWidth: 70} : {minWidth: 85}} />,
                            ]
                        }
                    </Tabs>
                    {role === 'publisher' ?
                    <Button onClick={this.handleClickCommandOpen} variant="text" startIcon={<MoreVertIcon />} className={classes.moreButton} >
                        더보기
                    </Button>
                        : '' }
                    <Popover anchorEl={this.state.commandMenuAnchor}
                          anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                          transformOrigin={{vertical: 'top', horizontal: 'right'}}
                          open={Boolean(this.state.commandMenuAnchor)}
                          onClose={this.handleClickCommandClose}
                          keepMounted
                    >
                        <MenuList>
                            {/*{role === 'publisher' ? */}
                            {/*[*/}
                                    <MenuItem key="chat-menu-item-attend-result" onClick={this.handleOpenAttendance}>
                                        <ListItemIcon>
                                            <AssignmentIndIcon />
                                        </ListItemIcon>
                                        <ListItemText>출석부 보기</ListItemText>
                                    </MenuItem>
                                    <MenuItem key="chat-menu-item-attend" onClick={this.handleOpenAttendanceRequestDialog}>
                                        <ListItemIcon>
                                            <HowToRegIcon />
                                        </ListItemIcon>
                                        <ListItemText>출석체크</ListItemText>
                                    </MenuItem>
                                    <MenuItem key="chat-menu-item-quiz" onClick={this.handleOpenQuizDialog}>
                                        <ListItemIcon>
                                            <LiveHelpIcon />
                                        </ListItemIcon>
                                        <ListItemText>퀴즈</ListItemText>
                                    </MenuItem>
                                    <MenuItem key="chat-menu-item-quiz-result" onClick={this.handleOpenQuizResult}>
                                        <ListItemIcon>
                                            <ListAltIcon />
                                        </ListItemIcon>
                                        <ListItemText>퀴즈 결과</ListItemText>
                                    </MenuItem>

                                    <MenuItem key="chat-menu-item-poll" onClick={this.handleOpenPollDialog}>
                                        <ListItemIcon>
                                            <PollIcon />
                                        </ListItemIcon>
                                        <ListItemText>설문 조사</ListItemText>
                                    </MenuItem>

                                    <MenuItem key="chat-menu-item-file-download" onClick={this.handleOpenFileDialog}>
                                        <ListItemIcon>
                                            <ImportContactsIcon />
                                        </ListItemIcon>
                                        <ListItemText>수업 자료</ListItemText>
                                    </MenuItem>

                                    <MenuItem key="chat-menu-item-chat-download" onClick={() => {
                                        const date = moment();
                                        const blob = new Blob(this.props.roomStore.messages, {type: 'text/plain'});
                                        const file = new File([blob], `chat-${this.props.roomStore.roomName}-${date.format("yyyy-MM-DD a h:mm")}.txt`, {type: 'text/plain'});
                                        fileDownload(file, file.name);
                                    }}>
                                        <ListItemIcon>
                                            <SaveAltIcon/>
                                        </ListItemIcon>
                                        <ListItemText>채팅 다운로드</ListItemText>
                                    </MenuItem>


                            {/*    ] */}
                            {/*    : */}
                            {/*    [ */}
                            {/*        <MenuItem key="chat-menu-item-question" disabled={primaryState === VideoState.Published} onClick={this.handleClickRequestPublish}> */}
                            {/*            <ListItemIcon> */}
                            {/*                <LiveHelpIcon /> */}
                            {/*            </ListItemIcon> */}
                            {/*            <ListItemText>질문하기</ListItemText> */}
                            {/*        </MenuItem>, */}

                            {/*        <MenuItem key="chat-menu-item-file-download" onClick={this.handleOpenFileDialog}> */}
                            {/*            <ListItemIcon> */}
                            {/*                <ImportContactsIcon /> */}
                            {/*            </ListItemIcon> */}
                            {/*            <ListItemText>수업 자료</ListItemText> */}
                            {/*        </MenuItem> */}
                            {/*    ] */}
                            {/*} */}
                        </MenuList>
                    </Popover>
                </Box>

                {notificationMsg && selectedChatTab === ChatTabIndex.Chat &&
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start" className={landscape ? classes.noticeContainer : classes.noticeContainerWithLeftMargin}>
                        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" style={{paddingBottom: '18px'}}>
                            <ErrorOutlineIcon color={'primary'} style={{marginRight: '8px'}}/>
                            <Typography style={{color: '#c31e1f'}}> 공지사항 </Typography>
                        </Box>
                        <Box display="flex" flexDirection="column" justifyContent={"flex-start"} alignItems={"center"} style={{paddingLeft: '5px'}}>
                            <Typography style={{color: '#333333'}}> {notificationMsg} </Typography>
                        </Box>
                    </Box>
                }

                {(selectedChatTab === ChatTabIndex.Chat && role === 'publisher') &&
                    <Box display="flex" flexDirection="column" justifyContent="flex-end" alignItems="flex-end">
                        <FormControlLabel
                            value={isChattingPossible ? "활성화" : "비활성화"}
                            control={<Switch color="primary" checked={isChattingPossible} onChange={this.props.onHandleChatting} />}
                            label={isChattingPossible ? "활성화" : "비활성화"}
                            labelPlacement="end"
                        />
                    </Box>
                }

                <Box ref={this.chatContainerRef} className={isMobileLandscape ? classes.mobilechatContainer : classes.chatContainer}>
                    <List dense className={classes.chatArea} hidden={selectedChatTab !== ChatTabIndex.Chat}>
                        {chatMessages.map((msg, index) =>
                            <ListItem key={`chat-msg-item-${index}`}
                                      className={msg.from === userId ? classes.chatMyMsgContainer : classes.chatMsgContainer}>
                                {msg.from !== userId ?
                                    <ListItemAvatar>
                                        <Avatar>
                                            <PersonIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    :
                                    null
                                }

                                <ListItemText className={classes.chatMsgTextContainer}
                                              primary={
                                                  <React.Fragment>
                                                      {msg.from !== userId ? <Typography color={"textSecondary"}
                                                                                         className={classes.chatFrom}>{msg.fromName}</Typography> : null}
                                                      {msg.type === MessageType.Link ?
                                                          <Typography className={getChatMessageClassName(msg)}>
                                                              웹 링크 : <Link href={msg.msg && (msg.msg.startsWith('https://') || msg.msg.startsWith('https://')) ? msg.msg : 'http://' + msg.msg} color="inherit" target="_blank" rel="noopener">{msg.msg}</Link>
                                                          </Typography>
                                                          :
                                                          msg.type === MessageType.Whisper ?
                                                              <Typography className={getChatWhisperMessageClassName(msg)}>
                                                                  귓속말 : {msg.msg}
                                                              </Typography>
                                                          :
                                                          <Typography className={getChatMessageClassName(msg)}>
                                                              {msg.msg}
                                                          </Typography>
                                                      }

                                                      <div
                                                          className={msg.from === userId ? classes.chatTimeMine : classes.chatTime}>
                                                          {moment(msg.date).format("a h:mm")}
                                                      </div>
                                                  </React.Fragment>
                                              }
                                />
                            </ListItem>
                        )}
                    </List>
                    <List dense hidden={selectedChatTab !== ChatTabIndex.Member}>
                        {chatMembers.map((member, index) =>
                            <ListItem key={`chat-member-item-${member.user.userId}`}>
                                <ListItemAvatar>
                                    {role === 'publisher' && member.id !== userId ?
                                        <Avatar className={member.joined ? classes.joinedAvatar : classes.notJoinedAvatar}>
                                            <PersonIcon/>
                                        </Avatar>
                                        :
                                        <Avatar className={member.joined ? classes.joinedAvatar : classes.notJoinedAvatar}>
                                            <PersonIcon/>
                                        </Avatar>
                                    }

                                </ListItemAvatar>

                                <ListItemText primary={
                                    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="stretch">
                                        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" flexGrow={1}>
                                            <Typography>{member.user.userName}</Typography>
                                            <Typography variant="body2" style={{fontSize: 10, paddingLeft: 8}}> {getDeviceBrowserTypeString(member)}</Typography>
                                        </Box>

                                        <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                                            {(role === 'publisher' && member.owner)  &&
                                                <Button key={`av-member-item-${member.owner}`} variant="outlined" color="primary"
                                                        startIcon={<DeleteOutlined />} style={{wordBreak : 'keep-all'}}
                                                        onClick={() => this.props.roomStore.questionInit()}
                                                >
                                                    질문 초기화
                                                </Button>
                                            }
                                            {(role === 'publisher' && !member.owner && member.mediaOn && member.publishType === PresentationType.PRESENTATION && member.publishState === MemberPublishState.None) &&
                                                <Button  key={`present-request-${member.user.id}`} variant="outlined" color="primary"
                                                         style={{wordBreak : 'keep-all'}}
                                                         startIcon={<PlayArrowIcon />}
                                                         disabled={primaryState !== VideoState.None}
                                                         onClick={() => this.handleClickRequestPresentationPublish(member.id)}
                                                >
                                                    발표하기
                                                </Button>
                                            }
                                            {(role === 'publisher' && !member.owner && member.mediaOn && member.publishType === PresentationType.PRESENTATION && member.publishState === MemberPublishState.Published) &&
                                                <Button key={`present-stop-${member.user.id}`} variant="contained" color="primary"
                                                        startIcon={<StopIcon/>}  style={{wordBreak : 'keep-all'}}
                                                        // disabled={isPublishButtonClicked}
                                                        onClick={() => this.handleClickStopMemberPublish(member.id)}
                                                >
                                                    중지
                                                </Button>
                                            }
                                            {(member.publishType === PresentationType.QUESTION && member.publishState === MemberPublishState.Request) &&
                                                <Button variant="outlined" color="primary"
                                                        startIcon={<PlayArrowIcon />}
                                                        disabled={mode === RoomMode.LookAround || primaryState === VideoState.Subscribing || primaryState === VideoState.StreamAttached || primaryState === VideoState.StreamEmpty || primaryState === VideoState.Subscribed }
                                                        onClick={() => this.handleClickConfirmPublish(member.id)}
                                                >
                                                    질문수락
                                                </Button>
                                            }
                                            {(member.publishType === PresentationType.QUESTION && (member.publishState === MemberPublishState.Confirmed || member.publishState === MemberPublishState.Published)) &&
                                                <Button variant="contained" color="primary"
                                                        startIcon={<StopIcon/>}
                                                        onClick={() => this.handleClickStopMemberPublish(member.id)}
                                                >
                                                    중지
                                                </Button>
                                            }
                                        </Box>
                                    </Box>
                                }
                                              secondary={
                                                  (!member.owner && role === 'publisher') &&
                                                  <Box display="flex" flexDirection="row" justifyContent="flex-start">
                                                      {member.mediaOn ?
                                                          <VideocamIcon className={classes.mediaOn} />
                                                          :
                                                          <VideocamOffIcon className={classes.mediaOff} />
                                                      }
                                                      {member.audioSetup && member.audioOn ?
                                                          <MicIcon className={classes.mediaOn} />
                                                          :
                                                          <MicOffIcon className={classes.mediaOff} />
                                                      }
                                                      {member.soundOn ?
                                                          <VolumeUpIcon className={classes.mediaOn} />
                                                          :
                                                          <VolumeOffIcon className={classes.mediaOff} />
                                                      }
                                                  </Box>
                                              }
                                />
                            </ListItem>
                        )}
                    </List>

                    {selectedChatTab === ChatTabIndex.Poll ?
                        <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="stretch" className={classes.pollContainer}>
                            <Box display="flex" flexDirection="column" className={classes.pollTitleContainer}>
                                {pollResult.name.split('\n').map((nameToken, index) =>
                                    <Typography key={`poll-name-token-${pollResult.pollId}-${index}`} className={classes.pollTitle}>{nameToken}</Typography>
                                )}
                            </Box>

                            {pollResultItems && pollResultItems.length > 0 ?
                                <div style={{height: (pollResultItems.length * 80) + 40}}>
                                    <ResponsiveBar layout="horizontal"
                                                   margin={{top: 10, right: 10, bottom: 30, left: 60}}
                                                   data={pollResultItems}
                                                   indexBy="name"
                                                   keys={['count']}
                                                   colors={{ scheme: 'nivo' }}
                                                   colorBy="index"
                                                   borderColor={{from: 'color', modifiers: [['darker', 2.6]]}}
                                                   labelTextColor={{from: 'color', modifiers: [['darker', 1.4]]}}
                                                   tickValues={chartXValues}
                                                   enableGridX={true}
                                                   gridXValues={chartXValues}
                                                   enableGridY={false}
                                                   axisTop={null}
                                                   axisBottom={{
                                                       tickValues: chartXValues,
                                                   }}
                                                   axisLeft={{
                                                       tickRotation: -30,
                                                   }}
                                                   axisRight={null}
                                                   padding={0.3}
                                                   isInteractive={false}
                                                   barComponent={BarComponent}
                                                   motionStiffness={170}
                                                   motionDamping={26}
                                    />
                                </div>
                                :
                                null
                            }
                        </Box>
                        :
                        null
                    }

                    {(selectedChatTab === ChatTabIndex.TinyGroup && isPublisher) &&
                    <>
                        {/*<Box style={{width:'100%', height:50}} onDragOver={() => this.chatContainerRef.current.scrollTo({ top: (this.chatContainerRef.current.scrollTop - 10), behavior: 'smooth' })}>*/}
                        {/*    <Box className={classes.dragBox}>*/}
                        {/*        <ArrowDropUpIcon />*/}
                        {/*    </Box>*/}
                        {/*</Box>*/}


                        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" pt={2} pb={2} className={classes.tinyGroupBox}>
                            <Button variant="contained" color="primary" className={classes.tinyGroupMenuButton}
                                    disabled={(tinyGroupStore.tinyGroups.length <= 0) || (isPublishButtonClicked)}
                                    onClick={this.handleStartTinyGroup}
                            >
                                모둠 시작하기
                            </Button>
                        </Box>

                        <Divider variant="middle" pb={2} />

                        <Box display="flex" flexDirection="row" justifyContent="space-around" alignItems="center" pt={2} pb={2} className={classes.tinyGroupBox}>
                            <Box>
                                <Box className={classes.tinyBox}>
                                    <Typography className={classes.tinyText} style={{color: tinyGroupStore.tinyGroups.length > 0 ? '#808080' : '#333333'}}>자동배정인원</Typography>
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className={classes.tinyGroupBox1} style={{backgroundColor: tinyGroupStore.tinyGroups.length > 0 ? '#e2e2e2' : 'inherit'}}>
                                    <IconButton style={{padding: '0px',marginRight:8}} disabled={tinyGroupStore.tinyGroups.length > 0} onClick={() => tinyGroupStore.subTinyGroupMaxUserCount()}>
                                        <SubtractIcon fontSize={"small"} />
                                    </IconButton>
                                    {tinyGroupStore.tinyGroupMaxUserCount}명
                                    <IconButton style={{padding: '0px',marginLeft:8}} disabled={tinyGroupStore.tinyGroups.length > 0} onClick={() => tinyGroupStore.addTinyGroupMaxUserCount()}>
                                        <PlusIcon fontSize={"small"}/>
                                    </IconButton>
                                </Box>
                            </Box>
                            <Button variant="contained" color="primary" className={classes.tinyGroupMenuButton1}
                                    disabled={tinyGroupStore.tinyGroups.length > 0}
                                    onClick={tinyGroupStore.autoAssignment}
                            >
                                자동 배정<br />({tinyGroupStore.tinyGroupMaxUserCount}명)
                            </Button>

                            <Button variant="contained" color="primary" className={classes.tinyGroupMenuButton1}
                                    disabled={tinyGroupStore.members.length <= 0 || tinyGroupStore.tinyGroups.length >= this.props.roomStore.chatMembers.length}
                                    onClick={() => tinyGroupStore.addTinyGroup()}
                            >
                                모둠<br />생성
                            </Button>

                            <Button variant="contained" color="primary" className={classes.tinyGroupMenuButton1}
                                    disabled={tinyGroupStore.tinyGroups.length <= 0}
                                    onClick={() => tinyGroupStore.deleteTinyGroups(() => this.props.tinyGroupStore.setMembers(_.cloneDeep(this.props.roomStore.chatMembers.filter(m => !m.owner))))}
                            >
                                모둠<br />초기화
                            </Button>
                        </Box>

                        {/* <Divider variant="middle" pb={2} /> */}

                        {tinyGroupStore.tinyGroups.map((tg, i) => (
                            <Accordion key={`additional-${i}`} expanded={tg.expanded} elevation={0} className={classes.inputSelect}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-label="Expand"
                                    aria-controls={`additional-actions-content-${i}`}
                                    id={`additional-actions-summary-${i}`}
                                    IconButtonProps={{
                                        onClick: () => tinyGroupStore.changeExpanded(i),
                                    }}
                                    className={classes.borderStyle}
                                >
                                    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" style={{width: '100%'}}>
                                        <FormControl fullWidth>
                                            <Input
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <GroupIcon />
                                                    </InputAdornment>
                                                }
                                                disabled={!tg.isEdit}
                                                value={tg.isEdit ? tg.editName : tg.name}
                                                onChange={e => tinyGroupStore.changeTinyGroupEditName(i, e.target.value)}
                                                style={{paddingBottom:5,fontFamily:'Noto Sans KR',fontSize:'14px',color:'#808080'}}
                                            />
                                        </FormControl>

                                        <Typography variant={"caption"}>({tg.members.length}/{tinyGroupStore.tinyGroupMaxUserCount})</Typography>

                                        {tg.isEdit ?
                                            <>
                                                <Button variant="contained" color="primary" className={classes.tinyGroupTitleButton} onClick={() => tinyGroupStore.saveTinyGroup(i)}>
                                                    저장
                                                </Button>
                                                <Button variant="contained" color="primary" className={classes.tinyGroupTitleButton} onClick={() => tinyGroupStore.saveCancelTinyGroup(i)}>
                                                    취소
                                                </Button>
                                            </>
                                            :
                                            <>
                                                <Button variant="contained" color="primary" className={classes.tinyGroupTitleButton} onClick={() => tinyGroupStore.editTinyGroup(i)}>
                                                    변경
                                                </Button>
                                                <Button variant="contained" color="primary" className={classes.tinyGroupTitleButton} onClick={() => tinyGroupStore.deleteTinyGroup(i)}>
                                                    삭제
                                                </Button>

                                            </>
                                        }
                                    </Box>
                                </AccordionSummary>

                                <AccordionDetails>

                                    {tinyGroupStore.tinyGroups &&
                                    <HtmlDragDrop
                                        droppableId={`dropTinyGroupMembers-${i}`}
                                        members={tg.members}
                                        isDragOver={tg.isDragOver}
                                    />
                                    }

                                </AccordionDetails>

                            </Accordion>
                        ))}

                        {/* <Divider variant="middle" pb={2} /> */}

                        {tinyGroupStore.tinyGroups &&
                        <HtmlDragDrop
                            droppableId={TINY_GROUP_CHAT_DROP_ZONE_ID}
                            members={tinyGroupStore.members}
                            isDragOver={tinyGroupStore.isMembersDragOver}
                            minHeight={'100%'}
                        />
                        }

                        {/*<Box style={{width:'100%',height:50}} onDragOver={() => this.chatContainerRef.current.scrollTo({ top: (this.chatContainerRef.current.scrollTop + 10), behavior: 'smooth' })}>*/}
                        {/*    <Box className={classes.dragBox} style={{bottom:5}}>*/}
                        {/*        <ArrowDropDownIcon />*/}
                        {/*    </Box>*/}
                        {/*</Box>*/}

                    </>
                    }


                    {selectedChatTab === ChatTabIndex.WhiteBoard ?
                        <Box className={classes.whiteBoard} id="whiteBoardMenus" display="flex" flexDirection="column" justifyContent={"flex-start"} alignItems={"center"} flexGrow={0} style={{cursor: 'pointer', width: '100%', height: '100%'}} >

                            <Box display="flex" flexDirection="column" justifyContent={"center"} alignItems={"flex-start"} style={{width: '100%'}} >
                                <Box display="flex" flexDirection="row" justifyContent={"center"} alignItems={"flex-start"} style={{fontSize: '14px', marginBottom: '16px'}}>
                                    캔버스 투명도
                                </Box>
                                <Box display="flex" flexDirection="row" justifyContent={"flex-start"} alignItems={"center"} style={{width: '93%'}} >
                                    <div style={{fontSize: '12px', wordBreak: "keep-all"}}>
                                        불투명도
                                    </div>
                                    <Box border={1} borderColor={"gray.500"} display="flex" flex-grow={1} style={{fontSize: '12px', wordBreak: "keep-all", marginLeft: '16px', marginRight: '16px', paddingLeft: '5px',  paddingRight: '5px'}}>
                                        {canvasAlpha}%
                                    </Box>
                                    <WhiteBoardSlider valueLabelDisplay="auto" value={canvasAlpha} onChange={(e, v) => this.props.whiteBoardStore.setCanvasAlpha(v)} step={10} marks min={0} max={100} />
                                </Box>
                            </Box>

                            <div style={{width: '100%', height: '32px'}} />

                            <Box display="flex" flexDirection="column" justifyContent={"center"} alignItems={"flex-start"} style={{width: '100%'}} >
                                {this.props.whiteBoardStore.whiteBoardMode === ModeType.DRAWING ?
                                    <Button className={classes.whiteBoardButtonGroup} size="medium" style={{ backgroundColor: this.props.theme.palette.primary.main, color: '#ffffff'}} onClick={() => this.props.whiteBoardStore.setWhiteBoardMode(ModeType.DRAWING)}>
                                        <BrushIconWhite1 className={classes.whiteBoardButtonGroupIcon}/>브러쉬
                                    </Button>
                                    :
                                    <Button className={classes.whiteBoardButtonGroup} style={{color:'#6d6d70'}} variant="outlined" size="medium"  onClick={() => this.props.whiteBoardStore.setWhiteBoardMode(ModeType.DRAWING)}>
                                        <BrushIconOn1 className={classes.whiteBoardButtonGroupIcon} /> 브러쉬
                                    </Button>
                                }

                                {/*<Box display="flex" flexDirection="row" justifyContent={"flex-start"} alignItems={"center"} style={{width: '93%', marginBottom: '16px'}} >*/}
                                {/*    <Box display="flex" flexDirection="row" justifyContent={"center"} alignItems={"flex-end"} onClick={e => this.props.roomStore.setWhiteBoardDrawingType(DrawingType.PEN)} >*/}
                                {/*        {(this.props.roomStore.whiteBoardMode === ModeType.DRAWING && this.props.roomStore.whiteBoardDrawingType === DrawingType.PEN) ?*/}
                                {/*            <img src={"/images/pen-blue.png"} alt="펜"/>*/}
                                {/*            :*/}
                                {/*            <img src={"/images/pen-gray.png"} alt="펜"/>}*/}
                                {/*        /!*<div style={{marginLeft: '8px', fontSize: '12px', color:'#c5c5c5'}}>*!/*/}
                                {/*        /!*    펜*!/*/}
                                {/*        /!*</div>*!/*/}
                                {/*    </Box>*/}
                                {/*</Box>*/}

                                <Box display="flex" flexDirection="row" justifyContent={"flex-start"} alignItems={"center"} style={{width: '93%'}} >
                                    <div style={{fontSize: '12px', wordBreak: "keep-all",color:'#000',fontWeight:'600'}}>
                                        두께
                                    </div>
                                    <Box border={1} borderColor={"gray.500"} display="flex" flex-grow={1} style={{fontSize: '12px', wordBreak: "keep-all", marginLeft: '16px', marginRight: '16px', paddingLeft: '5px',  paddingRight: '5px',color:'#000'}}>
                                        {lineWidth}px
                                    </Box>
                                    <WhiteBoardSlider valueLabelDisplay="auto" value={lineWidth} onChange={(e, v) => this.props.whiteBoardStore.setLineWidth(v)} min={1} max={25} />
                                </Box>
                            </Box>

                            <div style={{width: '100%', height: '32px'}} />

                            <Box display="flex" flexDirection="column" justifyContent={"center"} alignItems={"flex-start"} style={{width: '100%'}} >
                                {this.props.whiteBoardStore.whiteBoardMode === ModeType.TYPING ?
                                    <Button className={classes.whiteBoardButtonGroup} size="medium" style={{backgroundColor: this.props.theme.palette.primary.main, color: '#ffffff'}} onClick={e => this.props.whiteBoardStore.setWhiteBoardMode(ModeType.TYPING)}>
                                        <TextWhiteIcon className={classes.whiteBoardButtonGroupIcon}/> 텍스트
                                    </Button>
                                    :
                                    <Button className={classes.whiteBoardButtonGroup} style={{color:'#6d6d70'}} variant="outlined" size="medium" onClick={e => this.props.whiteBoardStore.setWhiteBoardMode(ModeType.TYPING)}>
                                        <TextFieldsIcon className={classes.whiteBoardButtonGroupIcon} /> 텍스트
                                    </Button>
                                }

                                <Box display="flex" flexDirection="row" justifyContent={"flex-start"} alignItems={"center"} style={{width: '93%'}} >
                                    <FormControl style={{marginRight: '16px'}}>
                                        <Select
                                            id="whiteboard-font-pt-select"
                                            value={fontSize}
                                            onChange={e => this.props.whiteBoardStore.setFontSize(e.target.value)}
                                            input={<BootstrapInput />}
                                            MenuProps={MenuProps}
                                        >
                                            {_.range(8, 132, 2).map( n => (<MenuItem key={`pt-${n}`} value={n}> {n}pt </MenuItem>) )}
                                        </Select>
                                    </FormControl>

                                    <div style={{marginRight: '16px', fontWeight: "bold", color: `${this.props.whiteBoardStore.isBold ? this.props.theme.palette.primary.main : '#000000'}`}} onClick={e => this.props.whiteBoardStore.setBold(!this.props.whiteBoardStore.isBold)}>가</div>
                                    <u style={{marginRight: '16px',fontWeight: "bold",  color: `${this.props.whiteBoardStore.isUnderline ? this.props.theme.palette.primary.main : '#000000'}`}} onClick={e => this.props.whiteBoardStore.setUnderline(!this.props.whiteBoardStore.isUnderline)}>가</u>
                                    <strike style={{marginRight: '16px',fontWeight: "bold", color: `${this.props.whiteBoardStore.isStroke ? this.props.theme.palette.primary.main : '#000000'}`}} onClick={e => this.props.whiteBoardStore.setStroke(!this.props.whiteBoardStore.isStroke)}>가</strike>
                                </Box>
                            </Box>

                            <div style={{width: '100%', height: '32px'}} />

                            <Box display="flex" flexDirection="column" justifyContent={"center"} alignItems={"flex-start"} style={{width: '100%',}} className={classes.colorBox}>
                                <Box borderTop={1} borderLeft={1} borderColor={"#e2e2e2"} display="flex" flexDirection="row" justifyContent={"center"} alignItems={"center"} style={{width: '93%'}}>
                                    {['#ffffff', '#c3c3c3', '#585858', '#000000', '#be0027', '#fe0000', '#f48825', '#fab035']
                                        .map(rgb => (<div key={`${rgb}`} style={{backgroundColor: `${rgb}`, width: '100%', height: '48px'}} onClick={e => this.props.whiteBoardStore.whiteBoard.changeColor(`${rgb}`)}/>))}
                                </Box>
                                <Box borderLeft={1} borderColor={"#e2e2e2"} display="flex" flexDirection="row" justifyContent={"center"} alignItems={"center"} style={{width: '93%'}}>
                                    {['#ffc847', '#ffdd00', '#e4ea32', '#2d9f47', '#00bbe4', '#037ef5', '#361677', '#7e3f98']
                                        .map(rgb => (<div key={`${rgb}`} style={{backgroundColor: `${rgb}`, width: '100%', height: '48px'}} onClick={e => this.props.whiteBoardStore.whiteBoard.changeColor(`${rgb}`)}/>))}
                                </Box>
                            </Box>

                            <div style={{width: '100%', height: '32px'}} />

                            <Box display="flex" flexDirection="row" justifyContent={"flex-end"} alignItems={"center"} style={{width: '88%'}} >
                                {this.props.whiteBoardStore.whiteBoardMode === ModeType.ERASER ?
                                    <Button className={classes.whiteBoardButtonEraser} size="medium" style={{marginRight: '16px', backgroundColor: this.props.theme.palette.primary.main, color: '#ffffff'}} onClick={e => this.props.whiteBoardStore.setWhiteBoardMode(ModeType.ERASER)}>
                                        {/* <img src={this.props.roomStore.serverContextPath + "/images/eraser-white.png"} alt="지우기" style={{marginRight: '4px'}} /> 픽셀지우기 */}
                                        <EraserWhite style={{marginRight:5}}/> 픽셀지우기
                                    </Button>
                                    :
                                    <Button className={classes.whiteBoardButtonEraser}  variant="outlined" size="medium" style={{marginRight: '16px', color: '#7d7d7d'}} onClick={e => this.props.whiteBoardStore.setWhiteBoardMode(ModeType.ERASER)}>
                                        {/* <img src={this.props.roomStore.serverContextPath + "/images/eraser-gray.png"} alt="지우기" style={{marginRight: '4px'}} /> 픽셀지우기 */}
                                        <EraserGray style={{marginRight:5}}/> 픽셀지우기
                                    </Button>
                                }

                                <Button className={classes.whiteBoardButtonEraser}  variant="outlined" size="medium" style={{color: '#7d7d7d'}} onClick={e => this.props.onWhiteBoardCleared()}>
                                    {/* <img src={this.props.roomStore.serverContextPath + "/images/eraser-gray.png"} alt="모두 지우기" style={{marginRight: '4px'}} /> 모두지우기 */}
                                    <EraserGray style={{marginRight:5}}/> 모두지우기

                                </Button>
                            </Box>
                        </Box>
                        :
                        null
                    }
                </Box>

                <Box display="flex" flexDirection="row" className={classes.commandContainer}>
                    {selectedChatTab === ChatTabIndex.Chat ?
                        <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" flexGrow={1}>
                            {role === 'publisher' ?
                                <React.Fragment>
                                    <Button ref={this.chatAddButtonRef} style={{minWidth: 24}}
                                            onClick={this.handleToggleChatAddMenu}><AddIcon/></Button>
                                    <Popover open={this.state.isOpenChatAddMenu}
                                             anchorEl={this.chatAddButtonRef.current}
                                             anchorOrigin={{
                                                 vertical: 'top',
                                                 horizontal: 'left',
                                             }}
                                             transformOrigin={{
                                                 vertical: 'bottom',
                                                 horizontal: 'left',
                                             }}
                                             onClose={this.handleCloseChatAddMenu}
                                    >
                                        <MenuList>
                                            <MenuItem key="chat-add-item-message" onClick={this.handleChangeToMessageMode}>
                                                <ListItemIcon>
                                                    <MessageIcon/>
                                                </ListItemIcon>
                                                <ListItemText>메세지 보내기</ListItemText>
                                            </MenuItem>
                                            <MenuItem key="chat-add-item-link" onClick={this.handleChangeToLinkMode}>
                                                <ListItemIcon>
                                                    <LinkIcon/>
                                                </ListItemIcon>
                                                <ListItemText>웹 링크 보내기</ListItemText>
                                            </MenuItem>
                                            <MenuItem key="chat-add-item-notification" onClick={this.handleChangeToNotificationMode}>
                                                <ListItemIcon>
                                                    <ErrorOutlineIcon/>
                                                </ListItemIcon>
                                                <ListItemText>공지사항 보내기</ListItemText>
                                            </MenuItem>
                                        </MenuList>
                                    </Popover>
                                </React.Fragment>
                                :
                                null
                            }

                            {this.state.inputMode === InputMode.Message &&
                                <FormControl id="whisper-target-select-form-control" variant="outlined" className={classes.formControl}>
                                    <InputLabel id="whisper-target-select-label">대상</InputLabel>
                                    <Select
                                        labelId="whisper-target-select-label"
                                        id="whisper-target-select"
                                        value={whisperTarget.id}
                                        style={{height: 42}}
                                        onChange={e => this.props.roomStore.setWhisperTarget(e.target.value)}
                                        label="대상"
                                        defaultValue={"all"}
                                    >
                                        <MenuItem key={`whisper-all`} value="all"> 전체 </MenuItem>
                                        {/*{role === 'publisher' ?*/}
                                        {/*    whisperTargetMembers.map((m, index) => (<MenuItem key={`whisper-${index}-${m.userId}`} value={m.id}>{m.user.userName}</MenuItem>))*/}
                                        {/*    :*/}
                                        {/*    whisperTargetMembers.filter(m => m.owner).map((m, index) => (<MenuItem key={`whisper-${index}-${m.userId}`} value={m.id}>{m.user.userName}</MenuItem>))*/}
                                        {/*}*/}
                                        {whisperTargetMembers.map((m, index) => (<MenuItem key={`whisper-${index}-${m.userId}`} value={m.id}>{m.user.userName}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            }

                            <InputBase inputProps={{'aria-label': 'naked'}}
                                       placeholder={this.state.inputMode === InputMode.Link ? '웹 링크를 입력하세요' : this.state.inputMode === InputMode.Notification ? '공지사항을 입력하세요.' : '메세지를 입력하세요'}
                                       value={(isChattingPossible || role === 'publisher') ? this.state.inputMessage  : '채팅이 금지되었습니다.'}
                                       onChange={(isChattingPossible || role === 'publisher') ? this.handleChangeInputMessage : () => {}}
                                       onKeyUp={(isChattingPossible || role === 'publisher') ? this.handleKeyUpInputMessage : () => {}}
                                       style={{flexGrow: 1, marginLeft: 8, opacity: (isChattingPossible || role === 'publisher') ? 1 : 0.4}}
                            />
                            <IconButton style={{flexGrow: 0}} disabled={!(isChattingPossible || role === 'publisher')} onClick={this.handleClickSendMessage}>
                                <KeyboardReturnIcon/>
                            </IconButton>
                        </Box>
                        :
                        null
                    }
                </Box>
            </Box>
        )
    }
}

export default withSnackbar(withRouter(withStyles(styles, { withTheme: true }) (RoomChatComponent)));
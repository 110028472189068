import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    IconButton,
    Menu,
    MenuItem,
    Typography
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import PauseIcon from "@material-ui/icons/Pause";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import StopIcon from "@material-ui/icons/Stop";

const useStyles = makeStyles(theme => ({
    card: {
        position: 'relative',
        width: 'calc(100% - 280px)',
        top: 0,
        left: 280,
        padding: 0,
        border: 'none',
        boxShadow: 'none',
        '@media screen and (max-width: 600px)': {
            width: 'calc(100% - 110px)',
            left: 110,
        }
    },
    cardAlternative: {
        position: 'relative',
        backgroundColor: '#f5f5f5',
        width: 'calc(100% - 280px)',
        top: 0,
        left: 280,
        padding: 0,
        border: 'none',
        boxShadow: 'none',
        '@media screen and (max-width: 600px)': {
            width: 'calc(100% - 110px)',
            left: 110,
        }
    },
    cardHeader: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        textAlign: 'left',
    },
    moreButton:{
        borderRadius:'50px',
        '& span':{
            fontSize:14
        },
        '&:hover, &:focus':{
            background:'none'
        }
    },


    cardContent: {
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(0),
        textAlign: 'left',
    },
    cardContentBody: {
        color: '#797979',
    },

    cardActions: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    activeColor: {
        color: '#3437CE',
    },
    inactiveColor: {
        color: '#737373',
    },
    grow: {
        flexGrow: 1,
    },
    monitoringButton: {
        borderRadius: 50,
        marginRight : 10,
    },
    actionButton: {
        borderRadius: 50,
    }
}));

export default function CalendarChannelCardComponent(props) {
    const classes = useStyles();
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const {isAlternative, userId, channel, onShowInfo, onEditInfo, onAttendance, onShowFile, onDelete, onShowResult, onEnter, onMonitoring, isLoginTypeAdmin} = props;

    const handleOpenMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    }

    const handleShowInfo = (channelId) => {
        setAnchorEl(null);
        if(onShowInfo) {
            onShowInfo(channelId);
        }
    }

    const handleEditInfo = (channelId) => {
        setAnchorEl(null);
        if(onEditInfo) {
            onEditInfo(channelId);
        }
    }

    // const handleQuiz = (channelId) => {
    //     setAnchorEl(null);
    //     if(onQuiz) {
    //         onQuiz(channelId);
    //     }
    // }

    const handleAttendance = (channel) => {
        setAnchorEl(null);
        if(onAttendance) {
            onAttendance(channel);
        }
    }

    const handleShowFile = (channelId, channelUserId) => {
        setAnchorEl(null);
        if(onShowFile) {
            onShowFile(channelId, channelUserId);
        }
    }

    const handleDelete = (channelId) => {
        setAnchorEl(null);
        if(onDelete) {
            onDelete(channelId);
        }
    }

    const handleShowResult = channel => {
        setAnchorEl(null);
        if(onShowResult) {
            onShowResult(channel);
        }
    }

    const getActionHeader = (channel) => {
        switch(channel.statusCode) {
            case 'WAIT':
                return (
                    <div style={{display: 'flex', justifyContent: 'left'}}>
                        <PauseIcon className={classes.inactiveColor} />
                        <Typography style={{paddingTop: 3, paddingLeft: 6}}>시작예정</Typography>
                    </div>
                );
            case 'OPENED':
                return (
                    <div style={{display: 'flex', justifyContent: 'left'}}>
                        <PauseIcon className={classes.activeColor} />
                        <Typography style={{paddingTop: 3, paddingLeft: 6}}>강의준비중</Typography>
                    </div>
                );
            case 'ONAIR':
                return (
                    <div style={{display: 'flex', justifyContent: 'left'}}>
                        <PlayCircleFilledIcon className={classes.activeColor} />
                        <Typography style={{paddingTop: 3, paddingLeft: 6}}>강의중</Typography>
                    </div>
                );
            case 'OFFAIR':
                return (
                    <div style={{display: 'flex', justifyContent: 'left'}}>
                        <PauseIcon className={classes.activeColor} />
                        <Typography style={{paddingTop: 3, paddingLeft: 6}}>강의완료중</Typography>
                    </div>
                );
            case 'CLOSED':
            default:
                return (
                    <div style={{display: 'flex', justifyContent: 'left'}}>
                        <StopIcon className={classes.inactiveColor} />
                        <Typography style={{paddingTop: 3, paddingLeft: 6}}>강의종료</Typography>
                    </div>
                );
        }
    }

    return (
        <Card className={isAlternative ? classes.cardAlternative : classes.card}>
            <CardHeader className={classes.cardHeader}
                        title={
                            <Typography variant={"subtitle1"}>
                                {channel.channelName}
                            </Typography>
                        }
                        action={
                            channel.userId === userId ?
                                <React.Fragment>
                                    <IconButton aria-label="settings" onClick={handleOpenMenu} className={classes.moreButton}>
                                        <MoreVertIcon /> 더보기
                                    </IconButton>
                                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                        <MenuItem onClick={() => handleShowInfo(channel.channelId)}>강의정보보기</MenuItem>
                                        <MenuItem onClick={() => handleEditInfo(channel.channelId)} disabled={channel.statusCode !== 'WAIT'}>강의정보수정</MenuItem>
                                        <MenuItem onClick={() => handleShowFile(channel.channelId, channel.userId)}>강의자료올리기</MenuItem>
                                        <MenuItem onClick={() => handleAttendance(channel)}>출석부보기</MenuItem>
                                        <MenuItem onClick={() => handleDelete(channel.channelId)} disabled={channel.statusCode === 'OPENED' || channel.statusCode === 'ONAIR' || channel.statusCode === 'OFFAIR' }>강의삭제</MenuItem>
                                        <MenuItem onClick={() => handleShowResult(channel)}>강의결과보기</MenuItem>
                                    </Menu>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <IconButton aria-label="settings" onClick={handleOpenMenu} className={classes.moreButton}>
                                        <MoreVertIcon /> 더보기
                                    </IconButton>
                                    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                        <MenuItem onClick={() => onShowFile(channel.channelId, channel.userId)} >강의자료보기</MenuItem>
                                        {isLoginTypeAdmin &&
                                            <MenuItem onClick={() => handleDelete(channel.channelId)} disabled={channel.statusCode === 'OPENED' || channel.statusCode === 'ONAIR' || channel.statusCode === 'OFFAIR' }>강의삭제</MenuItem>
                                        }

                                    </Menu>
                                </React.Fragment>
                        }
            />

            <CardContent className={classes.cardContent}>
                <Typography className={classes.cardContentBody} variant="body2" >
                    {channel.channelDesc ? channel.channelDesc : ''}
                </Typography>
            </CardContent>

            <CardActions className={classes.cardActions}>
                {getActionHeader(channel)}
                <div style={{flexGrow: 1}} />
                <div style={{display: 'flex', justifyContent: 'right'}}>
                    {channel.userId === userId ?(
                        <Button variant="outlined" color="primary" className={classes.monitoringButton}
                                disabled={channel.userId === userId ? (channel.statusCode === 'CLOSED') : (channel.statusCode === 'WAIT' || channel.statusCode === 'CLOSED')}
                                onClick={() => onMonitoring(channel.channelId)}
                        >
                            모니터링
                        </Button>
                    ):null}

                    <Button variant="contained" color="primary" className={classes.actionButton}
                            disabled={channel.userId === userId ? (channel.statusCode === 'CLOSED') : (channel.statusCode === 'WAIT' || channel.statusCode === 'CLOSED')}
                            onClick={() => onEnter(channel.channelId, channel.version)}
                    >
                        {channel.userId === userId ? '강의하기' : '강의듣기'}
                    </Button>
                </div>
            </CardActions>
        </Card>
    )
}
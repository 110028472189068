import {observable, action, flow} from "mobx";
import axios from "axios";
import {DrawingType, ModeType, WhiteBoard} from "../views/room/api/WhiteBoard";

const LogPrefix = '[WhiteBoardStore] ';

export default class WhiteBoardStore {

    //@observable role = 'publisher'
    @observable whiteBoard = undefined;
    @observable lineWidth = 5;
    @observable isBold = false;
    @observable isUnderline = false;
    @observable isStroke = false;
    @observable fontSize = 26;
    @observable canvasAlpha = 0;
    @observable whiteBoardMode = ModeType.DRAWING;
    @observable whiteBoardDrawingType = DrawingType.PEN;

    @observable visible = false;
    @observable isDataOver = false;

    @action initialize = () => {
        this.whiteBoard = undefined;

        this.lineWidth = 5;
        this.isBold = false;
        this.isUnderline = false;
        this.isStroke = false;
        this.fontSize = 26;
        this.canvasAlpha = 0;
        this.whiteBoardMode = ModeType.DRAWING;
        this.whiteBoardDrawingType = DrawingType.PEN;

        this.visible = false;
        this.isDataOver = false;
    }

    @action initWhiteBoard = (isPublisher, roomId, whiteBoardRef, onWhiteBoardDataChanged) => {
        console.log(LogPrefix, 'Init WhiteBoard');

        this.whiteBoard = new WhiteBoard(whiteBoardRef, isPublisher, {onDataChanged: onWhiteBoardDataChanged, onDataMax: this.setDataOver});
    }

    @action setLineWidth = lineWidth => {
        this.setWhiteBoardMode(ModeType.DRAWING);
        this.lineWidth = lineWidth;
        this.whiteBoard.changeLineWidth(lineWidth);
    }

    @action setBold = isBold => {
        this.setWhiteBoardMode(ModeType.TYPING);
        this.isBold = isBold;
        this.whiteBoard.setBold(isBold);
    }

    @action setUnderline = isUnderline => {
        this.setWhiteBoardMode(ModeType.TYPING);
        this.isUnderline = isUnderline;
        this.whiteBoard.setUnderline(isUnderline);
    }

    @action setStroke = isStroke => {
        this.setWhiteBoardMode(ModeType.TYPING);
        this.isStroke = isStroke;
        this.whiteBoard.setStroke(isStroke);
    }

    @action setFontSize = fontSize => {
        this.setWhiteBoardMode(ModeType.TYPING);
        this.fontSize = fontSize;
        this.whiteBoard.changeFontSize(fontSize);
    }

    @action setCanvasAlpha = canvasAlpha => {
        this.setWhiteBoardMode(ModeType.CANVAS_ALPHA);
        this.canvasAlpha = canvasAlpha;
        this.whiteBoard.changeCanvasAlpha(canvasAlpha);
    }

    @action setWhiteBoardMode = mode => {
        this.whiteBoardMode = mode;
        this.whiteBoard.changeModeType(mode);
        console.log(LogPrefix, "whiteBoard", mode);
    }

    @action setWhiteBoardDrawingType = drawingType => {
        this.setWhiteBoardMode(ModeType.DRAWING);
        this.whiteBoardDrawingType = drawingType;
        this.whiteBoard.changeDrawingType(drawingType);
    }

    @action destroy = () => {
        if(this.whiteBoard) this.whiteBoard.destroy();
    }

    @action clear = () => {
        if(this.whiteBoard) {
            this.whiteBoard.initDrawingHistory();
            this.whiteBoard.redraw();
        }
    }

    @action redraw = (rect) => {
        console.log(LogPrefix, 'redraw', rect);

        if(this.whiteBoard) {
            this.whiteBoard.redraw(rect);
        }
    }

    @action setVisible = (visible, rect) => {
        this.visible = visible;
        this.whiteBoard.setVisible(visible, rect);
        if(visible) {
            this.whiteBoard.redraw(rect);
        }
    }

    @action setDataOver = dataOver => this.isDataOver = dataOver;

    getWhiteBoardHistory = flow(function* getWhiteBoardHistory(channelId, rect, callback) {
        try {
            const response = yield axios.get(`/api/v1/whiteboard/${channelId}`);
            console.log(LogPrefix, "get white board history. ", response);
            if(response.data.history) {
                const whiteBoardData = JSON.parse(response.data.history);

                this.whiteBoard.setData(whiteBoardData);

                if(callback && callback.success) {
                    callback.success();
                }
            }
        } catch (error) {
            console.log(LogPrefix, "Can't get whiteboard history", error);
        }
    });

    saveWhiteBoardHistory = flow(function* saveWhiteBoardHistory(channelId, whiteBoardData, callback) {
        try {
            const param = {
                channelId: channelId,
                history: JSON.stringify(whiteBoardData),
            }
            console.log(LogPrefix, `Saving white board history : channelId=${channelId}, content=`, whiteBoardData);
            yield axios.post(`/api/v1/whiteboard/${channelId}`, param);

            if(callback && callback.success) {
                callback.success();
            }
        } catch (error) {
            console.log(LogPrefix, "Can't save whiteboard history", error);
        }
    });

    clearWhiteBoardHistory = flow(function* clearWhiteBoardHistory(channelId) {
        try {
            const response = yield axios.delete(`/api/v1/whiteboard/${channelId}`);
            console.log(LogPrefix, "Clear white board history. ", response);
            if(response.status !== 200) {
                console.log(LogPrefix, "Can't clear whiteboard history", response);
            }
        } catch (error) {
            console.log(LogPrefix, "Can't clear whiteboard history", error);
        }
    });
}
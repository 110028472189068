
export const MaxPublishers = 100;
export const FirFreq = 2;

const LogPrefix = '[RoomManager] ';

export class RoomManager {
    constructor(janus, roomId, callbacks) {
        this.janus = janus;
        this.roomId = roomId;

        this.pluginHandle = undefined;
    }

    createRoom = () => {
        console.log(LogPrefix, `Create Room...`, this.roomId);

        const opaqueId = window.Janus.randomString(12);
        const that = this;
        console.log(LogPrefix, `Attaching videoroom plugin... : opaqueId=${opaqueId}`);
        this.janus.attach({
            plugin: 'janus.plugin.videoroom',
            opaqueId: opaqueId,
            success: (pluginHandle) => {
                console.log(LogPrefix, 'Videoroom plugin attached successfully', pluginHandle);

                that.pluginHandle = pluginHandle;
                that._createRoom();
            },
            error: (error) => {
                console.log(LogPrefix, 'Videoroom plugin attach failed', error);
            },
            consentDialog: (on) => {
                console.log(LogPrefix, 'Videoroom plugin consentDialog', on);
            },
            mediaState: (medium, on) => {
                console.log(LogPrefix, 'Videoroom plugin mediaState', medium, on);
            },
            webrtcState: (on) => {
                console.log(LogPrefix, 'Vidoeroom plugin webrtcState', on);
            },
            onmessage: (msg, jsep) => {
                console.log(LogPrefix, 'Videoroom plugin onmessage', msg, jsep);
            },
            onlocalstream: (stream) => {
                console.log(LogPrefix, 'Videoroom plugin onlocalstream', stream);
            },
            onremotestream: (stream) => {
                console.log(LogPrefix, 'Videoroom plugin onremotestream', stream);
            },
            slowLink: (data) => {
                console.log(LogPrefix, 'Videoroom plugin slowLink', data);
            },
            oncleanup: () => {
                console.log(LogPrefix, 'Videoroom plugin oncleanup');
            },
        });
    }

    destroyRoom = () => {
        const {pluginHandle, roomId} = this;

        if(pluginHandle) {
            const destroyRequest = {
                message: {
                    request: 'destroy',
                    room: roomId,
                    permanent: false,
                },
                success: (data) => {
                    console.log(LogPrefix, 'Destroy command sended successfully', data);
                },
                error: (error) => {
                    console.log(LogPrefix, 'Destory command send failed', error);
                }
            };

            console.log(LogPrefix, 'Sending destroy command...', destroyRequest.message);
            pluginHandle.send(destroyRequest);

        }
    }

    _createRoom = () => {
        const {roomId, pluginHandle} = this;

        const existsRequest = {
            message: {
                request: 'exists',
                room: roomId,
            },

            success: (data) => {
                console.log(LogPrefix, 'Exists command sended successfully', data);

                if(data.exists) {
                    console.log(LogPrefix, `Room is already exists : ${roomId}`);
                } else {
                    const createRequest = {
                        message: {
                            request: 'create',
                            room: roomId,
                            videocodec: 'vp9,vp8,h264',
                            publishers: MaxPublishers,
                            permanent: false,
                            description: `OnTheLive ${roomId}`,
                            notify_joining: false,
                            videoorient_ext: false,
                            fir_freq: FirFreq,
                        },
                        success: (data) => {
                            console.log(LogPrefix, 'Create command sended successfully', data);
                        },
                        error: (error) => {
                            console.log(LogPrefix, 'Create command send failed', error);
                        }
                    }

                    console.log(LogPrefix, 'Sending create command...', createRequest.message);
                    pluginHandle.send(createRequest);
                }
            },
            error: (error) => {
                console.log(LogPrefix, 'Exists command send failed', error);
            }
        };

        console.log(LogPrefix, 'Sending exists command...', existsRequest.message);
        pluginHandle.send(existsRequest);
    };
}

const cardIconWidth = "52px";
const cardIconHeight = "52px";
const cardImageMargin = "0 !important";

const roseColor = ["rgb(52, 55, 206)", "rgb(176, 176, 236)"];
const successColor = ["rgb(34, 195, 174)", "rgb(29, 190, 169)"];
const infoColor = ["rgb(32, 191, 231)", "rgb(27, 186, 226)"];
const primaryColor = ["rgb(177, 154, 218)", "rgb(172, 149, 213)"];

const roseIconColor = [roseColor[0], roseColor[1]];
const successIconColor = [successColor[0], successColor[1]];
const infoIconColor = [infoColor[0], infoColor[1]];
const primaryIconColor = [primaryColor[0], primaryColor[1]];

export {
    cardIconWidth,
    cardIconHeight,
    roseIconColor,
    successIconColor,
    infoIconColor,
    primaryIconColor,
    cardImageMargin
};
import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogContent, Typography} from "@material-ui/core";

export default function BroadcastAlreadyUserDialog(props) {
    const { open, handleClose, history, handleConfirm} = props;

    const handleCancel = () => {
        handleClose();
        history.push("/channel/list");
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true} disableBackdropClick={true} disableEscapeKeyDown={true} maxWidth={'xs'} >
            <DialogContent>
                <Typography variant="subtitle1" component="h2">
                    이미 접속중입니다.<br/>
                    현재 브라우저로 로그인합니다.
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>
                    취소
                </Button>
                <Button style={{
                            color: '#d64e4e'
                        }}
                        onClick={handleConfirm}
                >
                    확인
                </Button>
            </DialogActions>
        </Dialog>
    );
}
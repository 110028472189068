import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogContent, TextField, Typography} from "@material-ui/core";

export default function GroupUpdateDialog(props) {
    const {open, group} = props;
    const {closeDialog, onChangeGroupName, onChangeGroupDesc, updateGroup} = props;

    const handleChangeGroupName = (event) => {
        onChangeGroupName(event.target.value);
    }

    const handleChangeGroupDesc = (event) => {
        onChangeGroupDesc(event.target.value);
    }

    return (
        <Dialog open={open}
                onClose={closeDialog}
                maxWidth="xs"
                fullWidth >
            <DialogTitle>
                그룹추가
            </DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1" component="h2">
                    그룹명
                </Typography>
                <TextField id="addGroupName"
                           name="addGroupName"
                           // label="그룹명"
                           margin="normal"
                           value={group.groupName}
                           onChange={handleChangeGroupName}
                           required fullWidth />
                <Typography variant="subtitle1" component="h2">
                    그룹 설명
                </Typography>
                <TextField id="addGroupDesc"
                           name="addGroupDesc"
                           // label="그룹 설명"
                           margin="normal"
                           value={group.groupDesc}
                           onChange={handleChangeGroupDesc}
                           required fullWidth />
            </DialogContent>
            <DialogActions>
                <Button style={{
                    color: 'rgba(0, 0, 0, 0.56)',
                    borderRadius:'25px'
                }}
                        onClick={closeDialog}
                >
                    취소
                </Button>
                <Button style={{
                    color: '#3437CE',
                    borderRadius:'25px'
                }}
                        onClick={updateGroup}
                >
                    수정
                </Button>
            </DialogActions>
        </Dialog>
    );
}
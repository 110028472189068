import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {Breadcrumbs, Button, Container, FormControl, Grid, Link, TextField, Typography, withStyles} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";


const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        marginTop: theme.spacing(2),
    },
});

class LookAroundTest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            roomId: 'DemoDefault',
            userId: '',
        };
    }

    handleChangeRoomId = (event) => {
        this.setState({roomId: event.target.value});
    }

    handleChangeUserId = (event) => {
        this.setState({userId: event.target.value});
    }

    handleClickTeacher = () => {
        let supports = navigator.mediaDevices.getSupportedConstraints();
        console.log('supports', supports);

        navigator.mediaDevices.getDisplayMedia({
            video: true,
            audio: true,
            audioConstraints: {
                mandatory: {
                    echoCancellation: true,
                },
                optional: [{
                    googAutoGainControl: true,
                }]
            }
        })
            .then(() => {
                console.log("getDisplayMedia success");
            })
            .catch(() => {
                console.log("getDisplayMedia failed");
            });

    }

    handleClickStudent = () => {

    }

    render() {
        const {classes} = this.props;
        const { roomId, userId } = this.state;

        return (
            <React.Fragment>
                <Container component="main" maxWidth="lg" className={classes.mainContainer}>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.mainContent} >
                        <Typography variant="h4" component="h2">둘러보기 테스트</Typography>
                        <FormControl margin="dense">
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                                <Link color="inherit" underline="none">둘러보기</Link>
                                <Link color="inherit" underline="none">둘러보기 테스트</Link>
                            </Breadcrumbs>
                        </FormControl>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField label="RoomID" value={roomId} InputLabelProps={{shrink: true}} onChange={this.handleChangeRoomId}></TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <TextField label="UserID" value={userId} InputLabelProps={{shrink: true}} onChange={this.handleChangeUserId}></TextField>
                            </Grid>

                            <Grid item xs={12}>
                                <Button variant="contained" onClick={this.handleClickTeacher}>교수 테스트</Button>
                                <Button variant="contained" onClick={this.handleClickStudent}>학생 테스트</Button>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (LookAroundTest)));
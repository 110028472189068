import React from "react";
import {Card, CardContent, CardHeader, IconButton, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";


const useStyles = makeStyles(theme => ({
    card: {
        boxShadow:'none',
        border:'1px solid #eee',
        padding: theme.spacing(2,3),
    },
    cardHeader: {
        padding: theme.spacing(1, 0, 1, 0),
        border: 'none',
        // paddingTop: theme.spacing(1),
        // paddingLeft: theme.spacing(2),
        // paddingRight: theme.spacing(2),
        // paddingBottom: 0,
        textAlign: 'left',
        '& > div h6':{
            fontWeight:700
        },
        '& > div span':{
            border: 'none',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            paddingBottom: theme.spacing(0),
        },
    },
    cardContent: {
        padding: theme.spacing(0),
        // paddingTop: theme.spacing(0),
        // paddingLeft: theme.spacing(2),
        // paddingRight: theme.spacing(2),
        // paddingBottom: 0,
        textAlign: 'left',
        '&:last-child':{
            paddingBottom : theme.spacing(1),
        }
    },
    cardContentBody: {
        color: '#797979',
    },
    shareBadge: {
        marginRight: 6,
        padding: 2,
        color: '#FFFFFF',
        borderRadius: 4,
        backgroundColor: '#494949',
    },


    moreButton:{
        borderRadius:'50px',
        padding:theme.spacing(1, 0, 0, 0 ),
        '& span':{
            fontSize:12
        },
        '&:hover, &:focus':{
            background:'none'
        }
    }
}));


export default function GroupCardComponent(props) {
    const classes = useStyles();
    const [ anchorEl, setAnchorEl ] = React.useState(null);
    const { group } = props;
    const { onClickUpdate, onClickAddMember, onClickShowMember, onClickAddShare, onClickRemove } = props;

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    }

    const handleClickUpdate = (groupId) => {
        setAnchorEl(null);
        onClickUpdate(groupId);
    }

    const handleClickAddMember = (groupId) => {
        setAnchorEl(null);
        onClickAddMember(groupId);
    }

    const handleClickShowMember = (groupId) => {
        setAnchorEl(null);
        onClickShowMember(groupId);
    }

    const handleClickAddShare = (groupId) => {
        setAnchorEl(null);
        onClickAddShare(groupId);
    }

    const handleClickRemove = (groupId, userCount) => {
        setAnchorEl(null);
        onClickRemove(groupId, userCount);
    }

    return (
        <Card className={classes.card}>
            <CardHeader className={classes.cardHeader}
                        title={group.owner ?
                            <React.Fragment>
                                <Typography display="inline" variant="subtitle1">
                                    {group.groupName}
                                </Typography>
                                <Typography display="inline" variant="body1">
                                    {` (${group.groupUserCount} 명)`}
                                </Typography>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <Typography display="inline" variant="body1" className={classes.shareBadge}>공유</Typography>
                                <Typography display="inline" variant="subtitle1">
                                    {group.groupName}
                                </Typography>
                                <Typography display="inline" variant="body1">
                                    {` (${group.groupUserCount} 명)`}
                                </Typography>
                            </React.Fragment>
                        }
                        action={
                            <React.Fragment>
                                <IconButton aria-label="settings" onClick={handleClickMenu} className={classes.moreButton}>
                                    <MoreVertIcon /> 더보기
                                </IconButton>
                                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
                                    <MenuItem onClick={() => handleClickUpdate(group.groupId)} disabled={!group.owner}>정보수정하기</MenuItem>
                                    {group.owner ?
                                        <MenuItem onClick={() => handleClickAddMember(group.groupId)}>학생변경하기</MenuItem>
                                        :
                                        <MenuItem onClick={() => handleClickShowMember(group.groupId)}>학생정보보기</MenuItem>
                                    }

                                    <MenuItem onClick={() => handleClickAddShare(group.groupId)} disabled={!group.owner}>그룹공유하기</MenuItem>
                                    <MenuItem onClick={() => handleClickRemove(group.groupId, group.groupUserCount)} disabled={!group.owner}>그룹삭제하기</MenuItem>
                                </Menu>
                            </React.Fragment>
                    }
            />
            <CardContent className={classes.cardContent}>
                <Typography className={classes.cardContentBody} variant="body1">
                    학생 : {group.memberNames}
                </Typography>
                {/*<Typography className={classes.cardContentBody} variant="body2">*/}
                {/*    {group.groupDesc ? group.groupDesc : ''}*/}
                {/*</Typography>*/}
            </CardContent>
        </Card>
    );
}
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {Button, Dialog, DialogActions, Typography} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import {PollType} from "../../../stores/PollStore";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = (theme) => ({
    dialogTitle: {
        fontSize: 16,
        fontWeight: 600,
    },
    itemHeader: {
    },
    itemBody: {
        paddingTop: theme.spacing(2),
    },
    pollItemHeaderContainer: {
        padding: theme.spacing(1),
    },
    pollItemHeader: {
        fontSize: 16,
    },
    pollItemBody: {
        margin: theme.spacing(1),
    }
});

@inject('pollStore')
@observer
class PollSelectDialog extends React.Component {

    handleChangePollAnswer = (event) => {
        const newAnswer = event.target.value;

        if(newAnswer.length <= 25) {
            this.props.pollStore.setPollAnswer(newAnswer);
        }
    }

    handleChangePollItem = (event) => {
        const newItemId = Number(event.target.value);

        this.props.pollStore.setSelectedPollItemId(newItemId);
    }

    handleSelectPollItem = () => {
        this.props.pollStore.selectPollItem();
    }

    handleCloseDialog = () => {
        this.props.pollStore.closePollSelectDialog();
    }


    render() {
        const {classes} = this.props;
        const {isOpenPollSelectDialog, isGetting, isGetError, isSelecting, poll, pollAnswer, selectedPollItemId} = this.props.pollStore;

        const renderDialogBody = () => {
            if(isGetting) {
                return (
                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                        <CircularProgress />
                    </Box>
                );
            } else if(isGetError) {
                return (
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Typography>설문조사 정보 읽기에 실패하였습니다.</Typography>
                        <Button>다시 시도</Button>
                    </Box>
                );
            } else {
                return (
                    <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="stretch">
                        <Box display="flex" flexDirection="column" className={classes.pollItemHeaderContainer}>
                            {poll.name.split('\n').map((nameToken, index) =>
                                <Typography key={`poll-name-token-${poll.pollId}-${index}`} className={classes.pollItemHeader}>{nameToken}</Typography>
                            )}
                        </Box>
                        <Box display="flex" flexDirection="column">
                            {poll.type === PollType.Answer ?
                                <TextField value={pollAnswer}
                                           placeholder="답변을 입력해 주세요. (최대 25자까지 입력가능합니다.)"
                                           onChange={this.handleChangePollAnswer}
                                           className={classes.pollItemBody}
                                           fullWidth
                                />
                                :
                                <RadioGroup value={selectedPollItemId} onChange={this.handleChangePollItem}>
                                    {poll.items.map((item) =>
                                        <FormControlLabel key={`poll-item-${poll.pollId}-${item.itemId}`} value={item.itemId} control={<Radio />} label={`${item.itemId}. ${item.name}`} />
                                    )}
                                </RadioGroup>
                            }
                        </Box>
                    </Box>
                );
            }
        }

        return (
            <Dialog open={isOpenPollSelectDialog}
                    onClose={this.handleCloseDialog}
                    maxWidth="sm"
                    fullWidth
                    disableBackdropClick
                    disableEscapeKeyDown
            >
                <DialogTitle>
                    <Typography className={classes.dialogTitle}>설문조사</Typography>
                </DialogTitle>

                <DialogContent>
                    {renderDialogBody()}
                </DialogContent>

                <DialogActions>
                    <Button color="default" onClick={this.handleCloseDialog}>취소</Button>
                    <Button color="primary" disabled={isGetting || isGetError || isSelecting} onClick={this.handleSelectPollItem}>확인</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withSnackbar(withRouter(withStyles(styles) (PollSelectDialog)));
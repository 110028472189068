import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {Button, Container, Typography, withStyles} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Footer from "../../components/Footer";
import * as params from "../../common/Params";

const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        textAlign: 'center',
        marginTop: theme.spacing(2),
    },
    mainLogo: {
        width: 119,
        height: 91,
        paddingTop: theme.spacing(7),
    },
    mainTitle: {
        fontSize: 38,
        color: '#333333',
        paddingTop: theme.spacing(6),
    },
    mainHeader: {
        fontSize: 16,
        color: '#333333',
        paddingTop: theme.spacing(5),
    },
    mainBody: {
        fontSize: 14,
        color: '#b7b7b7',
        paddingTop: theme.spacing(1),
    },
    mainButton: {
        borderRadius: 24,
        fontSize: 16,
        width: 200,
        height: 48,
        marginTop: theme.spacing(4),
    },
});


@inject('mailCertificationStore', 'authStore')
@observer
class MailCertification extends React.Component {
    componentDidMount() {
        const param = params.getURLParams(this.props.location.search);

        if(param.email && param.authKey) {
            this.props.mailCertificationStore.certificate(param.email, param.authKey);
        } else {
            this.props.mailCertificationStore.setCertificationFailed();
        }
    }

    handleClickSignIn = () => {
        this.props.authStore.changeLoginType('USER');
        this.props.history.push('/channel/list');
    }

    handleClickRetry = () => {
        const param = params.getURLParams(this.props.location.search);

        if(param.email && param.authKey) {
            this.props.mailCertificationStore.certificate(param.email, param.authKey);
        } else {
            this.props.mailCertificationStore.setCertificationFailed();
        }
    }

    render() {
        const {classes} = this.props;
        const {isCertificated, isCertificationFailed} = this.props.mailCertificationStore;


        return (
            <React.Fragment>
                <Container component="main" maxWidth="lg" className={classes.mainContainer}>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.mainContent} >
                        <img className={classes.mainLogo} src="/images/logo-verti.png" alt="ONTHELIVE" />

                        {isCertificated ?
                            <div>
                                <Typography className={classes.mainTitle}>이메일 인증 완료</Typography>
                                <Typography className={classes.mainHeader}>이메일 인증이 완료되었습니다.</Typography>
                                <Typography className={classes.mainBody}>로그인하여 ONTHELIVE의 서비스를 이용해 보세요.</Typography>
                                <Button color="primary" variant="contained"
                                        className={classes.mainButton}
                                        onClick={() => this.handleClickSignIn()}>
                                    로그인 하기
                                </Button>
                            </div>
                            :
                            ''
                        }
                        {isCertificationFailed ?
                            <div>
                                <Typography className={classes.mainTitle}>이메일 인증 실패</Typography>
                                <Typography className={classes.mainHeader}>이메일 인증이 실패하였습니다.</Typography>
                                <Typography className={classes.mainBody}>아래 버튼을 클릭하여 다시 시도해 주세요. 계속해서 문제가 발생하는 경우에는 관리자에게 문의하여 주세요.</Typography>
                                <Button color="primary" variant="contained"
                                        className={classes.mainButton}
                                        onClick={() => this.handleClickRetry()}>
                                    다시 시도하기
                                </Button>
                            </div>
                            :
                            ''
                        }
                    </div>
                </Container>

                <Footer />
            </React.Fragment>
        );
    }
}

export default withSnackbar(withRouter(withStyles(styles) (MailCertification)));
import {action, observable} from "mobx";

export default class MainMenuStore {
    @observable menuData = {
        channelMenu: {
            status: false,
            anchorEl: null
        },
        myPage : {
            anchorEl : null,
            icon : null,
            name : "",
            email : "",
        },

    };

    @observable menuBarAnchorEl = null;

    @action changeMenuData = (target, key, value) => {
        this.menuData[target][key] = value;
    };

    @action changeMenuBarAnchorEl = (value) => {
        this.menuBarAnchorEl = value;
    };
}
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Box,
    Button,
    withStyles
} from "@material-ui/core";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import {VideoState} from "./api/RoomPresenter2";
import {inject, observer} from "mobx-react";

const styles = theme => ({
    mainContainer: {
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(1),
    },
    title: {
        fontSize: 18,
        color: '#333333',
        padding: theme.spacing(2),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    titleButton: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        borderRadius: 18,
    },
    subTitle: {
        fontSize: 16,
    },
    subContent: {
        backgroundColor: '#f7f7f7',
    },
    centerAlignedItemGrid: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
});

@inject('roomStore', 'publishStore')
@observer
class RoomControlComponent extends React.Component {

    handleClickRequestPublish = () => {
        this.setState({commandMenuAnchor: null});

        if(this.props.onRequestPublish) {
            this.props.onRequestPublish();
        }
    }

    handleOpenFileDialog = () => {
        if(this.props.onOpenFileDialog) {
            this.props.onOpenFileDialog();
        }
    }


    render() {
        const {classes} = this.props;
        const {role} = this.props.roomStore;
        const {primaryState} = this.props.publishStore;
        return (
            <Box display="flex" flexDirection="column" alignItems="stretch" className={classes.mainContainer}>
                <Box display="flex" flexDirection="column" >
                    {/*<Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">*/}
                    {/*    <Typography className={classes.title}>{roomName}</Typography>*/}
                    {/*</Box>*/}

                    {role === 'publisher' ?

                        <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                            <Button variant="outlined" className={classes.titleButton}
                                    startIcon={<PowerSettingsNewIcon/>}
                                    onClick={this.props.onEnd}
                            >
                                수업 종료
                            </Button>
                            <Button variant="contained" color="primary" className={classes.titleButton}
                                    startIcon={<ExitToAppIcon/>}
                                    onClick={this.props.onExit}
                            >
                                나가기
                            </Button>
                        </Box>

                        :

                        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                            <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center">
                                <Button key="chat-menu-item-question"
                                        variant="outlined" className={classes.titleButton}
                                        startIcon={<LiveHelpIcon/>}
                                        disabled={primaryState === VideoState.Published}
                                        onClick={this.handleClickRequestPublish}
                                > 질문하기 </Button>
                                <Button key="chat-menu-item-file-download"
                                        variant="outlined" className={classes.titleButton}
                                        startIcon={<ImportContactsIcon/>}
                                        onClick={this.handleOpenFileDialog}
                                > 수업자료 </Button>
                            </Box>

                            <Button variant="contained" color="primary" className={classes.titleButton}
                                    startIcon={<ExitToAppIcon/>}
                                    onClick={this.props.onExit}
                            >
                                나가기
                            </Button>
                        </Box>
                    }
                </Box>
            </Box>
        );
    }
}

export default withSnackbar(withRouter(withStyles(styles, { withTheme: true }) (RoomControlComponent)));
import React from "react";
import {inject, observer} from "mobx-react";
import Grid from "@material-ui/core/Grid";

@inject("dashboardStore")
@observer
class DashBoardChannelConnectStatus extends React.Component {
    render() {
        const { connectStatus} = this.props.dashboardStore;
        if(connectStatus.length > 1) this.props.dashboardStore.makeBarChart("connect_status_chart_div", connectStatus);
        else this.props.dashboardStore.makeMaterialBarChart("connect_status_chart_div", connectStatus);
        return (
            <Grid container
                  // direction="column"
                  justify="center"
                  alignItems="center"
            >
                <Grid item xs={12}>
                    <div id="connect_status_chart_div" />
                </Grid>
            </Grid>
        );
    }
}

export default DashBoardChannelConnectStatus;
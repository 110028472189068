import {action, computed, flow, observable} from "mobx";
import moment from "moment";
import axios from "axios";
import * as validation from "../common/Validation";

const LiveTimeFormat = "YYYY-MM-DDTHH:mm:ss";

const formCheckRegExp = {
    inviteEmail: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
};

export const Type = {
    LIMIT_MEMBER_EL: "limitMemberCount",
    SELECT_INVITE_GROUP_EL: "selectInviteGroup",
};

export const inviteType = {
    TYPE: "inviteType",
    PERSONAL: "PERSONAL",
    GROUP: "GROUP"
};

const getInitStartTime = () => {
    let startTime = moment();
    let startMinute = ((Math.floor(Number(startTime.format("mm"))/10) * 10));
    startTime.set('minute', startMinute);
    startTime.set("second", 0);

    // if(startMinute === 60) {
    //     //startTime = startTime.add(1, "hours");
    //     startTime.set("minute", 0);
    //     startTime.set("second", 0)
    // } else {
    //     startTime.set('minute', startMinute);
    //     startTime.set("second", 0)
    // }

    return startTime;
};

const initState = {
    userId: null,
    formData: {
        liveTotalTime: 3000,
        name: '',
        comment: '',
        startDateTime: getInitStartTime().format(LiveTimeFormat),
        endDateTime: getInitStartTime().add(3000, 'seconds').format(LiveTimeFormat),
        inviteType: inviteType.PERSONAL,
        inviteEmail: '',
        inviteList: [],
        inviteGroupList: [],
        invitePersonList: [],
        selectInviteGroup: {
            groupId: '',
            groupName: '',
            groupUserCount: 0,
        },
        limitMemberCount: 30,
        subMonitorView: false,
        userPoint: 0,
        reservedPoint: 1500,
    },
    formError: {
        name: true,
        nameText: '이름을 입력하세요',
        comment: true,
        commentText: '설명을 입력하세요',
        startDateTime: false,
        startDateTimeText: '',
        endDateTime: false,
        endDateTimeText: '',
        inviteEmail: true,
        inviteEmailText: '올바른 형식의 이메일을 입력하세요',
        limitMemberCount: true,
        limitMemberCountText: '조건에 맞는 값을 입력하세요',
    },
    submitStatus: null,
    confirmView: false,
};

export default class ChannelFormStore {
    @observable userId = initState.userId;
    @observable formData = initState.formData;
    @observable submitStatus = initState.submitStatus;
    @observable formError = initState.formError;
    @observable groupList = [];
    @observable confirmView = initState.confirmView;

    @action init = () => {
        this.formData = initState.formData;
        this.submitStatus = initState.submitStatus;
        this.userId = initState.userId;
        this.formError = initState.formError;
        this.confirmView = initState.confirmView;
        this.groupList = [];
    };

    @action initSubmitStatus = () => {
        this.submitStatus = initState.submitStatus;
    };

    @action changeConfirmView = (value) => {
        this.confirmView = value;
    };

    @action changeUserId = (value) => {
        this.userId = value;
    };

    @action changeFormData = (target, value) => {
        this.formError[target] = !value || (typeof value === "string" && !value.trim());
        if(formCheckRegExp[target] && !formCheckRegExp[target].test(value)) {
            this.formError[target] = true;
        }

        if(target === inviteType.TYPE && value === inviteType.GROUP) {
            this.getGroupList();
        }

        this.formData[target] = value;
        this.setPoint();
    };

    @action changeStartDateTime = (value) => {
        if(!value.isAfter(moment())) {
            this.formError.startDateTime = true;
            this.formError.startDateTimeText = "현재 날짜 이후를 선택해 주세요";
        }
        this.formData.startDateTime = value;
        this.setEndTime();
    };

    @action changeEndDateTime = (value) => {
        if(!value.isAfter(this.formData.startDateTime)) {
            this.formError.endDateTime = false;
            this.formError.endDateTimeText = "시작 날짜 이후를 선택해 주세요";
        }

        this.formData.endDateTime = value;
    };

    @action addInviteEmail = () => {
        if(this.formData.inviteType === inviteType.GROUP) {
            if(this.formData.selectInviteGroup.groupId && this.formData.selectInviteGroup.groupName) {
                const groupTargetIndex = this.formData.inviteList.indexOf(this.formData.selectInviteGroup.groupName);
                if(groupTargetIndex === -1) {
                    this.formData.inviteList.push(this.formData.selectInviteGroup.groupName);
                    this.formData.inviteGroupList.push(this.formData.selectInviteGroup);
                }
                return null;
            }
            return null;
        }

        if(this.formError.inviteEmail) {
            return null;
        }

        const inviteEmail = this.formData.inviteEmail;
        const targetIndex = this.formData.inviteList.indexOf(inviteEmail);
        if(inviteEmail && typeof inviteEmail === "string" && inviteEmail.trim() && targetIndex === -1) {
            this.formData.inviteList.push(inviteEmail);
            this.formData.invitePersonList.push(inviteEmail);
            this.formData.inviteEmail = '';
        }
    };

    @action delInviteEmail = (value) => {
        const inviteGroupList =  this.formData.inviteGroupList;
        if(inviteGroupList.length > 1) {
            const delList = inviteGroupList.map((item, index) => {
                if(item.groupName === value) {
                    return index;
                } else {
                    return null;
                }
            });

            if(delList) {
                delList.forEach((item) => {
                    if(item !== null) {
                        inviteGroupList.splice(item, 1);
                    }
                })
            }
        }

        const targetIndex = this.formData.inviteList.indexOf(value);
        const personListTarget = this.formData.invitePersonList.indexOf(value);
        if(targetIndex !== -1) {
            this.formData.inviteList.splice(targetIndex, 1);
        }

        if(personListTarget !== -1) {
            this.formData.invitePersonList.splice(personListTarget, 1);
        }
    };

    @action changeChannelStartAmPm = (isAM) => {
        const startTime = moment(this.formData.startDateTime);
        if(isAM) {
            startTime.set('hour', 0);
            startTime.set('minute', 0);
            startTime.set('second', 0);
            startTime.set('millisecond', 0);
        } else {
            startTime.set('hour', 12);
            startTime.set('minute', 0);
            startTime.set('second', 0);
            startTime.set('millisecond', 0);
        }

        this.formData.startDateTime = startTime.format(LiveTimeFormat);
        this.setEndTime();
    };

    @action changeChannelStartHour = (hour) => {
        const time = moment(this.formData.startDateTime);

        time.set('hour', hour);

        this.formData.startDateTime = time.format(LiveTimeFormat);
        this.setEndTime();
    };

    @action changeChannelStartMinute = (minute) => {
        const time = moment(this.formData.startDateTime);

        time.set('minute', minute);

        this.formData.startDateTime = time.format(LiveTimeFormat);
        this.setEndTime();
    };

    @action changeChannelLiveTime = (liveTime) => {
        this.formData.liveTotalTime = liveTime;
        this.setEndTime();
        this.setPoint();
    };

    @computed get isValidEmail() {
        return validation.validateEmail(this.formData.inviteEmail);
    }

    getGroupList = flow(function* getGroupList() {
        try {
            const params = {
                "user-id" : this.userId,
                paging: "no"
            };

            const response = yield axios.get("/api/v1/groups", {params: params});
            const groupList = response.data;
            const validGroupList = groupList.filter((item) => {
                if(item.groupUserCount) {
                    return true;
                } else {
                    return null;
                }
            });

            if(validGroupList && validGroupList.length > 0) {
                const firstData = {
                    groupId: validGroupList[0].groupId,
                    groupUserCount: validGroupList[0].groupUserCount,
                    groupName: validGroupList[0].groupName,
                };
                this.formData.selectInviteGroup = firstData;
            }

            this.groupList = response.data;
            //response.data;
        } catch (e) {
            this.groupList = [];
            console.log('get group list error');
            console.log(e);
        }
    });

    getUserPoint = flow(function* getUserPoint() {
        try {
            const params = {
                "user-id" : this.userId,
            };

            const response = yield axios.get("/api/v1/users/points", {params: params});
            console.log('response');
            console.log(response);
            this.formData.userPoint = response.data.availablePoint;
            //response.data;
        } catch (e) {
            //this.formData.userPoint = 0;
            console.log('get group list error');
            console.log(e);
        }
    });

    addChannel = flow(function* addChannel() {
        this.submitStatus = "pending";
        try {
            let inviteGroupList = this.formData.inviteGroupList;
            let inviteGroupIdList = [];
            if(inviteGroupList.length > 0) {
                inviteGroupIdList = inviteGroupList.map((item, index) => {
                    return item.groupId;
                });
            }
            //YYYY-MM-DDTHH:mm:ss.SSS
            const data = {
                channelName: this.formData.name,
                channelDesc: this.formData.comment,
                joinLimitCount: this.formData.limitMemberCount,
                // liveEndDatetime: moment(this.formData.endDateTime, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                // liveStartDatetime: moment(this.formData.startDateTime, "YYYY-MM-DDTHH:mm").format("YYYY-MM-DDTHH:mm:ss.SSS"),
                liveTotalTime: this.formData.liveTotalTime,
                liveEndDatetime: this.getEndTime(),
                liveStartDatetime: this.formData.startDateTime,
                reservedPoint: this.formData.reservedPoint,
                subScreenAvailableFlag: this.formData.subMonitorView,
                userId: this.userId,
                typeCode: "PRIVATE",
                statusCode: "WAIT",
                inviteEmailList: this.formData.invitePersonList,
                inviteGroupList: inviteGroupIdList,
            };

            console.log('data');
            console.log(data);

            const response = yield axios.post("/api/v1/channels", data);

            if(response.status !== 200) {
                new Error("add channel fail");
            }

            this.submitStatus = true;
        } catch (e) {
            this.submitStatus = false;
            console.log('error');
            console.log(e);
            alert("error");
        }
    });

    @action setEndTime = () => {
        const endTime = moment(this.formData.startDateTime);
        endTime.add(this.formData.liveTotalTime, 'seconds');

        this.formData.endDateTime = endTime.format(LiveTimeFormat);
    };

    getEndTime = () => {
        const endTime = moment(this.formData.startDateTime);
        endTime.add(this.formData.liveTotalTime, 'seconds');

        return endTime.format(LiveTimeFormat);
    };

    setPoint = () => {
        const points = this.formData.liveTotalTime * this.formData.limitMemberCount / 60;       // 분당 단가

        this.formData.reservedPoint = points;
    };
}
import {action, computed, flow, observable} from "mobx";
import axios from "axios";

const DialogState = {
    Closed: 'Closed',
    Loading: 'Loading',
    Loaded: 'Loaded',
    LoadFailed: 'LoadFailed',
    Adding: 'Adding',
    Added: 'Added',
    AddFailed: 'AddFailed',
}

const compareUserNumber = (a, b) => {
    const aNum = a.userNumber.match(/\d+/);
    const bNum = b.userNumber.match(/\d+/);

    if (aNum && bNum) {
        return Number(aNum) - Number(bNum);
    } else {
        return a.userNumber.localeCompare(b.userNumber);
    }
}

export default class MemberAddStore {
    @observable dialogState = DialogState.Closed;
    @observable groupMemberList = [];

    @action clearDialogState = (open) => {
        if(open) {
            this.dialogState = DialogState.Loaded;
        } else {
            this.dialogState = DialogState.Closed;
        }
    }

    @computed get isDialogOpen() {
        return this.dialogState !== DialogState.Closed;
    }

    openDialog = flow(function* openDialog(groupId, userId) {
        this.dialogState = DialogState.Loading;

        try {
            const groupResponse = yield axios.get(`/api/v1/groups/users?group-id=${groupId}`);
            const groupMembers = groupResponse.data;

            this.groupMemberList = groupMembers.sort(compareUserNumber);
            this.dialogState = DialogState.Loaded;
        } catch(error) {
            this.dialogState = DialogState.LoadFailed;
        }
    })
}
import {action, computed, flow, observable, toJS} from "mobx";
import axios from "axios";
import moment from "moment";
import _ from "lodash";

const LiveTimeFormat = "YYYY-MM-DDTHH:mm:ss";
const MinTotalTime = 600;
const DefaultTotalTime = 3000;
const DefaultLimitCount = 100;
const calculatePoint = (totalTime, limitCount) => totalTime * limitCount / 60;

const getCurrentTimeMoment = () => {
    const current = moment();
    const minute = current.minute();
    const addMinute = 10 - (minute % 10);

    current.add(1, 'hours');
    current.add(addMinute > 0 ? addMinute : 10, 'minutes');
    current.set('second', 0);
    current.set('millisecond', 0);

    // console.log('StartDate : ' + current.format(LiveTimeFormat));

    return current;
}

const EmtpyChannel = {
    channelId: '',
    userId: 0,
    channelName: '',
    channelDesc: '',
    liveStartDatetime: getCurrentTimeMoment().format(LiveTimeFormat),
    liveEndDatetime: getCurrentTimeMoment().add(DefaultTotalTime, 'seconds').format(LiveTimeFormat),
    liveTotalTime: DefaultTotalTime,
    joinLimitCount: DefaultLimitCount,
    reservedPoint: calculatePoint(DefaultTotalTime, DefaultLimitCount),
    durationTime: 0,
    typeCode: "PRIVATE",
    statusCode: "WAIT",
    subScreenAvailableFlag: true,
    version: 2,
    statusReason: '',
    thumbnailUrl: '',
    channelMemberList: [],
    channelStateList: [],
    channelBroadcastEndpointList: [],
    channelFileList: [],
    inviteGroupList: [],
};

const CreateState = {
    Loaded: 'Loaded',
    Summary: 'Summary',
    Creating: 'Creating',
    Created: 'Created',
    CreateFailed: 'CreateFailed',
};

export default class ChannelCreateStore {
    InviteType = {
        Personal: 'Personal',
        Group: 'Group',
    }
    @observable createState = CreateState.Loaded;
    @observable inviteType = this.InviteType.Group;
    @observable createGroupDialogOpen = false;
    @observable groupList = [];
    @observable selectedGroupId = '';
    @observable newEmail = '';
    @observable channel = {...EmtpyChannel};
    @observable availablePoint = 0;

    @action clearCreateState = () => {
        this.createState = CreateState.Loaded;
        this.inviteType = this.InviteType.Personal;
        this.createGroupDialogOpen = false;
        this.groupList = [];
        this.selectedGroupId = '';
        this.newEmail = '';
        this.availablePoint = 0;
        this.channel = {...EmtpyChannel};
        this.channel.liveStartDatetime = getCurrentTimeMoment().format(LiveTimeFormat);
        this.channel.liveEndDatetime = getCurrentTimeMoment().add(DefaultTotalTime, 'seconds').format(LiveTimeFormat);
        this.channel.liveTotalTime = DefaultTotalTime;
        this.channel.joinLimitCount = DefaultLimitCount;
        this.channel.reservedPoint = calculatePoint(MinTotalTime, DefaultLimitCount);

        console.log('cleared create state : ' + this.channel.liveStartDatetime);
    }

    @action initLiveStartDateTime = (startDate) => {
        console.log('init starttime', startDate);

        const date = moment(startDate);
        const startTime = moment();
        const minute = startTime.minute();
        const addMinute = 10 - (minute % 10);

        startTime.year(date.year());
        startTime.month(date.month());
        startTime.date(date.date());

        startTime.add(1, 'hours');
        startTime.add(addMinute > 0 ? addMinute : 10, 'minutes');
        startTime.set('second', 0);
        startTime.set('millisecond', 0);

        this.changeChannelStartDate(startTime.format(LiveTimeFormat));
    }

    @action updateJoinLimitCount = () => {
        let count = _.reduce(toJS(this.channel.inviteGroupList), (sum, n) => sum + n.groupUserCount, 0);
        count += this.channel.channelMemberList.length;

        this.channel.joinLimitCount = count;
    }

    @action toSummary = () => {
        this.updateJoinLimitCount();
        this.channel.liveEndDatetime = moment(this.channel.liveStartDatetime).add(this.channel.liveTotalTime, 'seconds').format(LiveTimeFormat);
        console.log(`Live time : ${this.channel.liveStartDatetime} ~ ${this.channel.liveEndDatetime}`);

        this.createState = CreateState.Summary;
    }

    @action toEdit = () => {
        this.createState = CreateState.Loaded;
    }

    @action changeCreateGroupDialogOpen = (open) => {
        this.createGroupDialogOpen = open;
    }

    @action changeSelectedGroup = (groupId) => {
        const selectedGroup = _.find(toJS(this.groupList), (group) => group.groupId === groupId);
        if(selectedGroup) {
            this.channel.inviteGroupList.push(selectedGroup);
        }

        this.updateJoinLimitCount();
    }

    @action changeChannelName = (name) => {
        this.channel.channelName = name;
    }

    @action changeChannelDesc = (desc) => {
        this.channel.channelDesc = desc;
    }

    @action changeChannelStartDate = (startDate) => {
        console.log(startDate);
        const startTime = moment(startDate);

        this.channel.liveStartDatetime = startTime.format(LiveTimeFormat);
        this.channel.liveEndDatetime = moment(this.channel.liveStartDatetime).add(this.channel.liveTotalTime, 'seconds').format(LiveTimeFormat);
        console.log(`Live time : ${this.channel.liveStartDatetime} ~ ${this.channel.liveEndDatetime}`);
    }

    @action changeChannelStartAmPm = (isAM) => {
        const startTime = moment(this.channel.liveStartDatetime);

        if(isAM) {
            startTime.set('hour', 0);
            startTime.set('minute', 0);
            startTime.set('second', 0);
            startTime.set('millisecond', 0);
        } else {
            startTime.set('hour', 12);
            startTime.set('minute', 0);
            startTime.set('second', 0);
            startTime.set('millisecond', 0);
        }

        this.channel.liveStartDatetime = startTime.format(LiveTimeFormat);
        this.channel.liveEndDatetime = moment(this.channel.liveStartDatetime).add(this.channel.liveTotalTime, 'seconds').format(LiveTimeFormat);
        console.log(`Live time : ${this.channel.liveStartDatetime} ~ ${this.channel.liveEndDatetime}`);
    }

    @action changeChannelStartHour = (hour) => {
        const time = moment(this.channel.liveStartDatetime);
        time.set('hour', hour);

        this.channel.liveStartDatetime = time.format(LiveTimeFormat);
        this.channel.liveEndDatetime = moment(this.channel.liveStartDatetime).add(this.channel.liveTotalTime, 'seconds').format(LiveTimeFormat);
        console.log(`Live time : ${this.channel.liveStartDatetime} ~ ${this.channel.liveEndDatetime}`);
    }

    @action changeChannelStartMinute = (minute) => {
        const time = moment(this.channel.liveStartDatetime);
        time.set('minute', minute);

        this.channel.liveStartDatetime = time.format(LiveTimeFormat);
        this.channel.liveEndDatetime = moment(this.channel.liveStartDatetime).add(this.channel.liveTotalTime, 'seconds').format(LiveTimeFormat);
        console.log(`Live time : ${this.channel.liveStartDatetime} ~ ${this.channel.liveEndDatetime}`);
    }

    @action changeChannelLiveTime = (liveTime) => {
        this.channel.liveTotalTime = liveTime;
        this.channel.liveEndDatetime = moment(this.channel.liveStartDatetime).add(this.channel.liveTotalTime, 'seconds').format(LiveTimeFormat);
        console.log(`Live time : ${this.channel.liveStartDatetime} ~ ${this.channel.liveEndDatetime}`);

        this.channel.reservedPoint = calculatePoint(this.channel.liveTotalTime, this.channel.joinLimitCount);
    }

    @action changeChannelSubScreenAvailableFlag = (flag) => {
        this.channel.subScreenAvailableFlag = flag;
    }

    @action changeChannelVersion = (version) => {
        this.channel.version = version;
    }

    @action removeGroup = (groupId) => {
        for(let i=0; i<this.channel.inviteGroupList.length; i++) {
            if(this.channel.inviteGroupList[i].groupId === groupId) {
                this.channel.inviteGroupList.splice(i, 1);
                break;
            }
        }

        this.updateJoinLimitCount();
    }

    @computed get filteredGroupList() {
        const channelInviteGroupList = toJS(this.channel.inviteGroupList);

        const filteredGroupList = _.filter(toJS(this.groupList), (group) => {
            const groupInInviteList = _.find(channelInviteGroupList, (inviteGroup) => inviteGroup.groupId === group.groupId);
            if(groupInInviteList) {
               return false;
            } else {
                return true;
            }
        });

        return filteredGroupList;
    }

    @computed get isOverMember() {
        return false;
    }

    @computed get memberCount() {
        let count = _.reduce(toJS(this.channel.inviteGroupList), (sum, n) => sum + n.groupUserCount, 0);
        count += this.channel.channelMemberList.length;

        return count;
    }

    @computed get isBeforeStartTime() {
        return moment(this.channel.liveStartDatetime).isBefore(moment());
    }

    @computed get isSummary() {
        return this.createState === CreateState.Summary || this.createState === CreateState.Creating || this.createState === CreateState.CreateFailed;
    }

    @computed get isCreatable() {
        const channel = toJS(this.channel);

        if(channel) {
            let count = _.reduce(channel.inviteGroupList, (sum, n) => sum + n.groupUserCount, 0);
            count += channel.channelMemberList.length;

            const creatable =
                (channel.channelName.length > 0) &&
                (channel.channelDesc.length > 0) &&
                (channel.userId) &&
                (channel.liveTotalTime) &&
                (channel.liveTotalTime >= MinTotalTime) &&
                (channel.joinLimitCount) &&
                (moment(channel.liveStartDatetime).isAfter(moment())) &&
                (channel.joinLimitCount >= count) &&
                (count > 0)
                ?
                true
                :
                false;

            return creatable;
        } else {
            return false;
        }
    }

    @computed get isCreating() {
        return this.createState === CreateState.Creating;
    }

    @computed get isCreated() {
        return this.createState === CreateState.Created;
    }

    @computed get isCreateFailed() {
        return this.createState === CreateState.CreateFailed;
    }

    createChannel = flow(function* createChannel() {
        this.createState = CreateState.Creating;

        try {
            const param = toJS(this.channel);
            param.inviteGroupList = toJS(this.channel.inviteGroupList).map((item) => item.groupId);
            param.inviteEmailList = toJS(this.channel.channelMemberList).map((item) => item.email);
            delete param.channelMemberList;
            delete param.channelStateList;
            delete param.channelBroadcastEndpointList;
            delete param.channelFileList;

            yield axios.post('/api/v1/channels', param);

            this.createState = CreateState.Created;
        } catch (error) {
            this.createState = CreateState.CreateFailed;
        }
    })

    getGroupList = flow(function* getGroupList(userId) {
        try {
            const params = {
                "user-id": userId,
                paging: "no"
            };

            const response = yield axios.get("/api/v1/groups", {params: params});
            const groupList = response.data;

            if(groupList.length < 1) {
                this.createGroupDialogOpen = true;
            }
            this.groupList = groupList;
        } catch(error) {
            this.createGroupDialogOpen = false;
            this.groupList = [];
        }
    })

    getUserPoint = flow(function* getUserPoint(userId) {
        this.channel.userId = userId;
        try {
            const params = {
                "user-id" : userId,
            };

            const response = yield axios.get("/api/v1/users/points", {params: params});
            this.availablePoint = response.data.availablePoint;
        } catch (e) {
        }
    });
}
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {Backdrop, Box, CircularProgress, Container, Typography, withStyles, withWidth} from "@material-ui/core";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {inject, observer} from "mobx-react";

import ConfirmDialog from "./ConfirmDialog";
import RoomControlComponent from "./RoomControlComponent";
import RoomChatComponent from "./RoomChatComponent";
import RoomBroadcastControlComponent from "./RoomBroadcastControlComponent";
import RoomLayerContentComponent from "./RoomLayerContentComponent";
import * as Params from "../../common/Params";
import ChannelAttendanceDialog from "../channel/ChannelAttendanceDialog";
import BroadcastQuizDialog from "./Dialog/BroadcastQuizDialog";
import BroadcastQuizResultDialog from "./Dialog/BroadcastQuizResultDialog";
import BroadcastAttendReqeuestDialog from "./Dialog/BroadcastAttendRequestDialog";
import BroadcastAttendDialog from "../broadcast/BroadcastAttendDialog";
import {
    PresentationType,
    PresentationStateType,
    PresentationEndType,
    PUBLISH_BUTTON_TIMEOUT
} from "../../stores/RoomStore";
import PollCreateDialog from "./poll/PollCreateDialog";
import PollSelectDialog from "./poll/PollSelectDialog";
import ChannelFileDialog from "../channel/ChannelFileDialog";
import {ChatState, RoomPresenter2, VideoMode} from "./api/RoomPresenter2";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import {toJS} from "mobx";
import TinyGroupDialog from "./tinyGroup/TinyGroupDialog";
import _ from "lodash";
import moment from "moment";
import fileDownload from "js-file-download";

const CHAT_WIDTH = 420;

const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: 0,
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        [theme.breakpoints.down('xs')]: {
            width: '100vw',
            height: "100%",
            padding: 0,
            backgroundColor: 'white',
        },
        width: '100vw',
        height: '100vh',
        padding: 0,
        backgroundColor: 'white',
    },
    mobileMainContent:{
        flexDirection:'column',
    },
    centerContainer: {
        backgroundColor: 'white',
    },
    videoContainer: {
        backgroundColor: 'black',
    },
    videoBottomContainer: {
        padding: theme.spacing(1),
        backgroundColor: 'white',
    },
    mobileVideoBottomContainer:{
        backgroundColor: 'white',
    },
    rightContainer: {
        backgroundColor: 'white',
        width: CHAT_WIDTH,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important',
        },
    },
    mobileRightContainer:{
        backgroundColor: 'white',
        width: '100%',
    },
    dialogTitle: {
        fontSize: 16,
        fontWeight: 600,
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },

    titleBox:{
        [theme.breakpoints.down('sm')]: {
            justifyContent:"center"
        },
        backgroundColor:'#f6f6f6',
        border: 'solid 1px #e5e5e5',
        // marginBottom:15
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            width: '100% !important',
        },
        width:'470px',
        color: '#333333',
        padding: theme.spacing(2),
        fontFamily: 'NotoSansCJKkr',
        fontSize: '20px',
        fontWeight: 'bold',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
});

const LogPrefix = '[Room] ';
// const SnackbarHideDuration = 6000;

@inject('roomStore', 'authStore', 'channelAttendanceStore', 'broadcastQuizStore', 'pollStore', 'channelFileStore', 'recordStore', 'monitoringStore', 'whiteBoardStore', 'tinyGroupStore', 'publishStore')
@observer
class Room2 extends React.Component {
    constructor(props) {
        super(props);

        this.deviceBrowserType = Params.getDeviceBrowserType(false);

        this.primaryAudioRef = React.createRef();
        this.primaryAudioRef = React.createRef();
        this.videoContainerRef = React.createRef();
        this.primaryVideoAreaRef = React.createRef();
        this.secondaryVideoAreaRef = React.createRef();
        this.whiteBoardRef = React.createRef();
        this.primaryLayerRef = React.createRef();
        this.secondaryLocatorRef = React.createRef();
        this.secondaryLayerRef = React.createRef();
        this.videoBottomRef = React.createRef();
        this.chatAreaRef = React.createRef();

        this.presenter = undefined;

        window.onresize = this.resizeVideoArea;
        document.onfullscreenchange = this.onFullScreenChanged;
        document.addEventListener('webkitfullscreenchange', this.onFullScreenChanged);
    }

    componentDidMount() {
        console.log(LogPrefix, 'Component did mount');
        this.props.roomStore.setStoreInitialized(false);

        if(!this.props.match.params.channelId) {
            console.log(LogPrefix, "Oops! I don't know channel id!!!");
            this.props.history.goBack();
            return;
        }

        this.props.roomStore.initializeStore(this.props.match.params.channelId, this.props.userId, Params.getDeviceBrowserType(false));
        this.props.publishStore.initialize();
        this.props.pollStore.setOnPollCreated(this.handleOnNewPollCreated);
        this.props.pollStore.setOnPollSelected(this.handleOnPollSelected);
        RoomPresenter2.getDevices(devices => this.props.publishStore.setDevices(devices));
        this.props.publishStore.setOwner(_.cloneDeep(this.props.roomStore.chatMembers.find(m => m.owner)));
        this.props.publishStore.setMembers(_.cloneDeep(this.props.roomStore.chatMembers.filter(m => !m.owner)));
        if(this.props.roomStore.storeInitialized && this.props.janusInitialized && (!this.presenter)) {

            const {server, roomId, role, userId, displayName} = this.props.roomStore;
            this.initializePresenter(server, undefined,
                roomId, role, userId, displayName);
            // this.initializePresenter();

            if(this.props.roomStore.role === 'publisher') {
                window.addEventListener('beforeunload', this.beforeunloadListener);
            }
        } else {
            console.log(LogPrefix, 'Not yet initialized store or janus');
        }
    }

    beforeunloadListener = e => {
        e.preventDefault();

        if(this.presenter) {
            // this.presenter.endXVideo();
            this.presenter.endXVideo();
            this.presenter.exitRoom();
        }

        this.props.roomStore.deactivateChannel();
    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        if(this.presenter) {
            this.presenter.initVideoArea();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(LogPrefix, 'Component did update');

        if(this.props.roomStore.roomEnded) {
            this.props.history.push('/channel/list');
            return;
        }

        if(this.props.roomStore.storeInitialized && (this.props.janusInitialized) && (!this.presenter)) {
            const {server, roomId, role, userId, displayName} = this.props.roomStore;
            this.initializePresenter(this.props.history, server, undefined,
                roomId, role, userId, displayName);
            // this.initializePresenter();

            if(this.props.roomStore.role === 'publisher') {
                window.addEventListener('beforeunload', this.beforeunloadListener);
            }
        }

        const {chatState} = this.props.roomStore;
        if(chatState === ChatState.AlreadyJoined) {
            this.props.roomStore.setKickDialogOpen(true);

            this.props.roomStore.setChatState(ChatState.None);
        } else if(chatState === ChatState.Error) {
            this.props.roomStore.setReconnectDialogOpen(true);

            this.props.roomStore.setChatState(ChatState.None);
        }

        if(this.presenter) {
            this.presenter.resizeVideoArea(false);
        }
    }

    componentWillUnmount() {
        console.log(LogPrefix, 'ComponentWillUnmount');

        if(this.presenter) {
            this.presenter.destroy();
        }

        window.removeEventListener('beforeunload', this.beforeunloadListener);
    }

    resizeVideoArea = () => {
        const landscape = window.innerWidth > window.innerHeight;
        const {isMobile} = this.props.roomStore;

        if(this.presenter) {
            if(this.props.roomStore.landscape !== landscape) {
                this.presenter.setLandscape(landscape);

                setTimeout(() => this.presenter.resizeVideoArea(true, isMobile), 500);
            }

            this.presenter.resizeVideoArea(true);
        }
    }

    onFullScreenChanged = () => {
        const fullEl = document.fullscreenElement;
        console.log(LogPrefix, 'onFullScreen', fullEl);

        if(fullEl) {
            this.presenter.setFullScreen(true);
        } else {
            if(document.webkitIsFullScreen) {
                this.presenter.setFullScreen(true);
            } else {
                this.presenter.setFullScreen(false);
            }
        }
    }

    initializePresenter = (history, server, iceServers, roomId, role, userId, displayName) => {
        console.log(LogPrefix, 'Initializing presenter');

        // const {role} = this.props.roomStore;

        const types = Params.getDeviceBrowserType(false);

        this.presenter = new RoomPresenter2(history,{
            server: server,
            iceServers: iceServers,
            roomId: roomId,
            role: role,
            userId: userId,
            displayName: displayName,
            deviceType: types.deviceType,
            browserType: types.browserType,

            subVideoSizePercent: 25,
            mainZIndex: 10,
            landscape: true,
            isMobile: toJS(this.props.roomStore.isMobile),

            primaryAudioRef: this.primaryAudioRef,
            videoContainerRef: this.videoContainerRef,
            primaryVideoAreaRef: this.primaryVideoAreaRef,
            whiteBoardRef: this.whiteBoardRef,
            primaryLayerRef: this.primaryLayerRef,
            secondaryVideoAreaRef: this.secondaryVideoAreaRef,
            secondaryLayerRef: this.secondaryLayerRef,
            secondaryLocatorRef: this.secondaryLocatorRef,
            additionalVideoRefs: [this.videoBottomRef],
            chatAreaRef: this.chatAreaRef,
        }, {
            roomStore : this.props.roomStore,
            monitoringStore : this.props.monitoringStore,
            whiteBoardStore : this.props.whiteBoardStore,
            tinyGroupStore: this.props.tinyGroupStore,
            publishStore : this.props.publishStore,
        }, {
            onRequestPublish: this.onRequestPublish,
            onConfirmPublish: this.onConfirmPublish,
            onReceivedAttendance: this.onReceivedAttendance,
            onReceivedQuiz: this.handleQuizRequestReceived,
            onReceivedStartPoll: this.handleOnStartPollReceived,
            onReceivedPollResponse: this.handleOnPollResponseReceived,
            onReceivedEndPoll: this.handleOnEndPollReceived,
            // onReceivedWhiteBoardData: this.handleOnReceivedWhiteBoardData,
        });

        const landscape = window.innerWidth > window.innerHeight;
        this.presenter.setLandscape(landscape);
        this.presenter.setMainVideo(VideoMode.Primary);
        // if(role === 'publisher') {
        //     this.presenter.endXVideo();
        // }
    }

    onRequestPublish = (member, data) => {
        this.props.roomStore.changePresentation(data);
        let msg = '';
        if(data.presentationType === PresentationType.QUESTION) {
            msg = `${member.user.userName} 의 질문 요청이 있습니다.`;
            this.props.roomStore.setChatMemberPublishType(member.user.userId, PresentationType.QUESTION);
        } else if(data.presentationType === PresentationType.PRESENTATION) {
            msg = `${member.user.userName} 의 발표 요청이 있습니다.`;
            this.props.roomStore.setChatMemberPublishType(data.userId, PresentationType.PRESENTATION);
            this.props.roomStore.setMemberPublishDialogOpen(true);
        }
        this.props.enqueueSnackbar(msg, {variant: 'info'});
    }

    onConfirmPublish = () => {

    }

    // Events for publish
    handleChangeMode = (mode) => {
        this.presenter.changeMode(mode);
    }

    // handleLookAround = () => {
    //     this.presenter.lookAround();
    // }

    handleGetDevices = (callback) => {
        RoomPresenter2.getDevices(callback);
    }

    handleChangeSoundPlay = () => {
        const {soundPlayed} = this.props.publishStore;
        if(soundPlayed) {
            if(this.presenter) {
                this.presenter.stopAudio();
            }
        } else {
            if(this.presenter) {
                this.presenter.playAudio();
            }
        }
    }

    handleChangeMemberMic = () => {
        if(this.presenter) {
            this.presenter.changeMemberMic();
        }
    }

    handleChangeAudioDevice = (device) => {
        if(this.presenter) {
            this.presenter.changeAudioDevice(device);
        }
    }

    handleAddVideoStream = (deviceId) => {
        const addVideoStream = () => {
            const {chatState} = this.props.roomStore;
            if(this.presenter && chatState === ChatState.Connected) {
                this.presenter.addLocalVideoStream(deviceId);
            } else {
                setTimeout(() => addVideoStream(), 1000);
            }
        }

        addVideoStream();
    }

    handleRemoveVideoStream = (streamId) => {
        this.presenter.removeVideoStream(streamId);
    }

    handleChangeVideoStream = (streamId, deviceId) => {
        this.presenter.changeVideoStream(streamId, deviceId);
    }

    handlePublishAudio = () => {
        const {audioMuted} = this.props.publishStore;

        if(audioMuted) {
            this.presenter.publishAudio();
        } else {
            this.presenter.unpublishAudio();
        }
    }

    // handleUnpublishAudio = () => {
    //     this.presenter.unpublishAudio();
    // }

    handlePublishVideo = (videoMode, stream) => {
        this.presenter.publishStream(videoMode, stream);
    }

    handleUnpublishVideo = (videoMode) => {
        this.props.roomStore.updatePresentationState(PresentationType.QUESTION, PresentationStateType.END, PresentationEndType.SELF);
        this.presenter.unpublish(videoMode);
    }

    handleUnsubscribe = (videoMode) => {
        this.presenter.unsubscribe(videoMode);
    }

    handleCloseMemberPublishDialog = () => {
        this.presenter.closeMemberPublishDialog();
    }

    handleMemberPublish = () => {
        this.props.roomStore.updatePresentationState(PresentationType.QUESTION, PresentationStateType.START);
        this.presenter.startMemberPublish();
    }

    handleStopMemberPublish = (memberId) => {
        this.props.roomStore.updatePresentationState(PresentationType.QUESTION, PresentationStateType.END, PresentationEndType.OWNER);
        this.presenter.stopMemberPublish(memberId);
    }

    handleChangeScreen = () => {
        this.presenter.changeMainVideo();
    }

    handleChangeSubScreenHighQualityValue = (newValue) => {
        this.presenter.setSubVideoHighQualityValue(newValue);
    }

    handlePrimaryHighQuality = () => {
        this.presenter.primaryHighQuality();
    }

    handleChangeSubScreenHidden = () => {
        const newHidden = !this.props.publishStore.subVideoHidden;

        this.presenter.setSubVideoHidden(newHidden);
    }

    handleChangeSubScreenSize = (size) => {
        const {subVideoHidden} = this.props.publishStore;

        if(!subVideoHidden) {
            this.presenter.setSubVideoSize(size);
        }
    }

    handleChangeVideoRotate = (isVideoRotateX, isVideoRotateY) => {
        this.props.roomStore.setVideoRotate(isVideoRotateX, isVideoRotateY, this.primaryVideoAreaRef);
        this.presenter.sendAnnounce();
    }


    // Events for play
    handlePlayPrimary = () => {
        if(this.presenter) {
            this.presenter.play(VideoMode.Primary);
        }
    }

    handlePlaySecondary = () => {
        if(this.presenter) {
            this.presenter.play(VideoMode.Secondary);
        }
    }

    handlePlayAudio = (play) => {
        if(play) {
            this.presenter.playAudio();
        }
        this.props.roomStore.setAudioPlayDialogOpen(false);

        // this.props.roomStore.getWhiteBoardHistory(this.primaryVideoAreaRef.current.getBoundingClientRect());
    }



    // Events for control
    handleChatting = () => {
        this.presenter.controlChattingPossibleSwitch();
    }

    handleExit = () => {
        if(this.presenter) {
            if(this.props.roomStore.role === 'publisher') {
                this.presenter.endXVideo();
            }
            this.props.publishStore.unpublish();
            this.presenter.exitRoom();
        }

        setTimeout(() => this.props.history.push('/channel/list'), 1000);
    }

    handleEnd = () => {
        this.presenter.openEndRoomDialog();
    }


    // Events for chat
    handleSendChatMessage = (msg) => {
        this.presenter.sendChatMessage(msg);
    }

    handleSendChatWhisperMessage = (msg, to) => {
        if(this.props.roomStore.isPublisher) {
            const member = _.find(this.props.roomStore.chatMembers, (m) => m.id === to);
            const message = `[${moment().format("YYYY-MM-DD a hh:mm")}]  ${this.props.authStore.loginUser.userName} -> ${member.user.userName} : [ 귓속말 : ${msg} ]\n`;
            this.props.roomStore.setMessages(message);
        }
        this.presenter.sendChatWhisperMessage(msg, to);
    }

    handleSendChatLink = (link) => {
        this.presenter.sendChatLink(link);
    }

    handleSendChatNotification = (notification) => {
        this.props.roomStore.setNotificationMsg(notification);
        this.presenter.sendAnnounce();
    }

    handleConfirmPublish = (userId) => {
        this.props.roomStore.updatePresentationState(PresentationType.QUESTION, PresentationStateType.ALLOW);
        this.presenter.sendConfirmPublishControlMessage(userId);
    }

    // Event for etc dialog
    handleReconnectDialogResponse = (reconnect) => {
        if(reconnect) {
            this.props.roomStore.setReconnectDialogOpen(false);

            window.location.reload();
        } else {
            this.props.roomStore.setReconnectDialogOpen(false);

            this.props.history.push('/channel/list');
        }
    }

    handleKickDialogResponse = (kick) => {
        this.props.roomStore.setKickDialogOpen(false);
        if(kick) {
            this.presenter.kickAndJoin();
        } else {
            this.props.history.replace('/channel/list');
        }
    }

    handleEndRoomDialogResponse = (end) => {
        if(end) {
            if(this.presenter) {
                this.presenter.endXVideo();
                this.presenter.endRoom();
            }

        } else {
            this.presenter.closeEndRoomDialog();
        }
    }

    handleChatSeperatorDragStart = (event) => {
        console.log(LogPrefix, 'onDragStart', event.clientX);

        this.dragPoint = event.clientX;
        this.dragWidth = this.chatAreaRef.current.getBoundingClientRect().width;
    }

    handleChatSeperatorDrag = (event) => {
        console.log(LogPrefix, 'onDrag', event.clientX);

        if(event.clientX > 0) {
            if(this.dragPoint !== event.clientX) {
                const dragOffset = event.clientX - this.dragPoint;
                console.log(LogPrefix, 'drag offset', dragOffset);

                const newWidth = this.dragWidth - dragOffset;
                this.presenter.setChatAreaWidth(newWidth);
            }
        }
    }

    handleChatSeperatorDragEnd = (event) => {
        event.dataTransfer.dropEffect = 'none';
    }

    handleChatAreaHidden = (hidden) => {
        if(this.presenter) {
            const roomTitle = document.getElementById("roomTitle");
            roomTitle.style.display = hidden ? 'none' : 'flex';
            this.presenter.setChatAreaHidden(hidden);
        }
    }

    handleChannelAttendance = (channelId) => {
        this.props.channelAttendanceStore.openAttendanceDialog(channelId);
    }

    handleAttendRequestDialog = isDialogOpen => {
        this.props.roomStore.changeAttendRequestDialogOpen(isDialogOpen)
    }

    handleChatAttendance = () => {
        this.props.roomStore.requestAttend(this.presenter.sendAttendanceControlMessage)
    }

    handleChatAttendanceLate = () => {
        this.props.roomStore.requestAttendLate(this.presenter.sendAttendanceControlMessage)
    }

    onReceivedAttendance = attendMsg => {
        this.props.roomStore.getAuthCode(attendMsg);
    }

    handleDoAttend = () => {
        this.props.roomStore.updateAttendResponse();
    }

    handleChangeAttendErrorMsg = value => this.props.roomStore.changeAttendDialogMsgError(value);

    handleQuizResultDialogOpen = () => {
        const { channel } = this.props.roomStore;
        this.props.broadcastQuizStore.getQuizResultQuestion(channel.channelId);
        this.props.broadcastQuizStore.changeQuizResultDialogOpen(true);
    }

    handleQuizDialogOpen = () => {
        const {userId} = this.props.roomStore;
        this.props.broadcastQuizStore.getQuizList(userId);
        this.props.broadcastQuizStore.changeDialogOpen(true);
    }

    handleSendQuiz = () => {
        const { channel, userId } = this.props.roomStore;
        const { publishedQuestion, changeIsSendingQuiz, initData } = this.props.broadcastQuizStore;

        const quizObj = {
            quizQuestionId: publishedQuestion.quizQuestionId,
            channelId: channel.channelId,
        }

        this.presenter.sendQuizControlMessage(userId, quizObj);
        changeIsSendingQuiz(false);
        initData();
    }

    handleQuizRequestReceived = (quizQuestion) => {
        this.props.broadcastQuizStore.changeQuizQuestionObj(quizQuestion);
        this.props.broadcastQuizStore.receivedRoomQuiz(quizQuestion.quizQuestionId);
    };

    handleOpenPollCreateDialog = () => {
        this.props.pollStore.openPollCreateDialog(this.props.roomStore.channel.channelId);
    }

    handleOnNewPollCreated = (pollId) => {
        console.log(LogPrefix, `New poll created : ${pollId}`);

        this.presenter.sendStartPollControlMessage(pollId);
        this.props.pollStore.getPollResult();
    }

    handleOnPollSelected = (channelId, pollId, itemId) => {
        console.log(LogPrefix, `Poll item selected : channelId=${channelId}, pollId=${pollId}, itemId=${itemId}`);

        this.presenter.sendPollResponseMessage(pollId);
    }

    handleFinishPoll = (pollId) => {
        this.presenter.sendEndPollControlMessage(pollId);
    }

    handleOnStartPollReceived = (pollId) => {
        const {channel} = this.props.roomStore;

        this.props.pollStore.openPollSelectDialog(channel.channelId, pollId);
    }

    handleOnPollResponseReceived = (pollId) => {
        this.props.pollStore.getPollResult(pollId);
    }

    handleOnEndPollReceived = (pollId) => {
        this.props.pollStore.closePollSelectDialog();
    }

    // handleOnSendWhiteBoardData = whiteBoardSignalData => {
    //     this.presenter.sendWhiteBoardDataMessage(whiteBoardSignalData);
    // }

    // handleOnReceivedWhiteBoardData =  whiteBoardData => {
    //     if(!this.props.roomStore.whiteBoard) this.props.roomStore.initWhiteBoard(this.whiteBoardRef);
    //     this.props.roomStore.getWhiteBoardHistory(this.primaryVideoAreaRef.current.getBoundingClientRect());
    // }

    handleFileDownload = () => {
        const {channel} = this.props.roomStore;
        if(!channel) {
            return null;
        }

        this.props.channelFileStore.openFileDialog(channel.channelId, channel.userId);
    };

    handleOnStartRecord = () => {
        const {roomName} = this.props.roomStore;
        if(this.props.recordStore) {
            this.props.recordStore.setRecordName(roomName);
            this.props.recordStore.startRecord();
        }
    }

    handleOnStopRecord = () => {
        if(this.props.recordStore) {
            this.props.recordStore.stopRecord();
        }
    }

    handleOnPauseRecord = () => {
        if(this.props.recordStore) {
            this.props.recordStore.pauseRecord();
        }
    }

    handleOnResumeRecord = () => {
        if(this.props.recordStore) {
            this.props.recordStore.resumeRecord();
        }
    }

    handleOnCloseTinyGroup = () => {
        this.props.publishStore.setPublishButtonClicked(true);
        setTimeout(() => this.props.publishStore.setPublishButtonClicked(false), PUBLISH_BUTTON_TIMEOUT + 3000);

        setTimeout(() => this.presenter.attachAudio(), 3000);
    }

    render() {
        const {classes} = this.props;
        const {storeInitialized, role, chatAreaHidden, chatState, audioPlayDialogOpen, landscape, memberPublishDialogOpen, reconnectDialogOpen, kickDialogOpen, endRoomDialogOpen, attendDialogStatus, attendDialogMsg, attendDialogMsgError, attendDialogMsgHelperText, attendMsg,isMobileLandscape} = this.props.roomStore;
        const {mode, primaryState} = this.props.publishStore;
        const { changeAttendDialogStatus, changeAttendDialogMsg, roomName } = this.props.roomStore;
        return (
            <Container component="main" maxWidth={false} className={classes.mainContainer}>
                <Box display="flex" flexDirection={landscape ? 'row' : 'column'} className={isMobileLandscape ? classes.mobileMainContent : classes.mainContent}>
                    <Box display="flex" flexDirection="column" flexGrow={landscape ? 1 : 0} className={classes.centerContainer} >
                        <Box ref={this.videoContainerRef} display="flex" flexDirection="column" flexGrow={1} justifyContent="center" alignItems="center" className={classes.videoContainer} >
                            <video ref={this.primaryVideoAreaRef} style={{background: 'black'}} playsInline />
                            <video ref={this.secondaryVideoAreaRef} style={{background: 'transparent'}} playsInline />

                            <canvas ref={this.whiteBoardRef} style={{background: 'transparent'}}/>

                            <Box ref={this.primaryLayerRef} display="flex" flexDirection="column" justifyContent="center" alignItems="stretch" style={{overflow: 'hidden'}}>
                                <RoomLayerContentComponent mode={VideoMode.Primary} role={role} onPlayPrimary={this.handlePlayPrimary} />
                            </Box>
                            <Box ref={this.secondaryLayerRef} display="flex" flexDirection="column" justifyContent="center" alignItems="stretch" style={{overflow: 'hidden'}}>
                                <RoomLayerContentComponent mode={VideoMode.Secondary} role={role} onPlaySecondary={this.handlePlaySecondary} />
                            </Box>
                            <div ref={this.secondaryLocatorRef} style={{backgroundColor: 'grey'}}>
                            </div>
                        </Box>

                        {/*<Box ref={this.videoBottomRef} display="flex" flexDirection="row" justifyContent="stretch" alignItems="center" className={this.props.roomStore.isMobile ? classes.mobileVideoBottomContainer : classes.videoBottomContainer}>*/}
                        <Box ref={this.videoBottomRef} className={this.props.roomStore.isMobile ? classes.mobileVideoBottomContainer : classes.videoBottomContainer}>
                            <audio ref={this.primaryAudioRef} />
                            <RoomBroadcastControlComponent isSupportScreenDevice={this.deviceBrowserType.deviceType === 'etc' && navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia}
                                                           getDevices={this.handleGetDevices}
                                                           onChangeMode={this.handleChangeMode}
                                                           onChangeSoundPlay={this.handleChangeSoundPlay}
                                                           onChangeMemberMic={this.handleChangeMemberMic}
                                                           onChangeAudioDevice={this.handleChangeAudioDevice}
                                                           onAddVideoStream={this.handleAddVideoStream}
                                                           onRemoveVideoStream={this.handleRemoveVideoStream}
                                                           onChangeVideoStream={this.handleChangeVideoStream}
                                                           onPublishAudio={this.handlePublishAudio}
                                                           onPublishVideo={this.handlePublishVideo}
                                                           onUnpublishVideo={this.handleUnpublishVideo}
                                                           onUnsubscribe={this.handleUnsubscribe}

                                                           // onStartXVideo={() => this.presenter.startXVideo()}
                                                           // onEndXVideo={() => this.presenter.endXVideo()}

                                                           onStartRecord={this.handleOnStartRecord}
                                                           onStopRecord={this.handleOnStopRecord}
                                                           onPauseRecord={this.handleOnPauseRecord}
                                                           onResumeRecord={this.handleOnResumeRecord}

                                                           onChangeScreen={this.handleChangeScreen}
                                                           onChangeSubScreenHighQuality={this.handleChangeSubScreenHighQualityValue}
                                                           onChangeSubScreenHidden={this.handleChangeSubScreenHidden}
                                                           onChangeSubScreenSize={this.handleChangeSubScreenSize}
                                                           onChangeSubPosition={(position) => this.presenter.setSubVideoPosition(position)}
                                                           onShowSecondaryLocator={(show, position) => this.presenter.showSubVideoLocator(show, position)}
                                                           onPrimaryHighQuality={this.handlePrimaryHighQuality}

                                                           onChangeVideoRotate={this.handleChangeVideoRotate}

                                                           onChangeFullScreen={this.props.roomStore.changeFullscreen}
                            />
                        </Box>
                    </Box>

                    <Box display ='flex' flexDirection='column' flexGrow={landscape ? 0 : 1}>
                        <Box id={"roomTitle"} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" className={classes.titleBox}>
                            <Typography className={classes.title} noWrap={true}>{roomName}</Typography>
                        </Box>
                        <Box display="flex" flexDirection="row" flexGrow={landscape ? 0 : 1} style={{paddingTop : 15, height: "100%"}}>
                             {/*style={{height:'100%'}}>*/}
                            {landscape ?
                                <React.Fragment>
                                    {/*<div style={{width: '3px', cursor: 'col-resize'}} draggable onDrag={this.handleChatSeperatorDrag} onDragStart={this.handleChatSeperatorDragStart} onDragEnd={event => event.dataTransfer.dropEffect = 'none'} />*/}

                                    <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
                                        <>
                                            {isMobileLandscape ||
                                            <Box>
                                                {chatAreaHidden ?
                                                    <ChevronLeftIcon style={{cursor: 'pointer'}}
                                                                     onClick={() => this.handleChatAreaHidden(false)}/>
                                                    :
                                                    <ChevronRightIcon style={{cursor: 'pointer'}}
                                                                      onClick={() => this.handleChatAreaHidden(true)}/>
                                                }
                                            </Box>
                                            }
                                        </>
                                    </Box>
                                </React.Fragment>
                                :
                                null
                            }

                        <Box ref={this.chatAreaRef} display="flex" flexDirection="column" flexGrow={1} className={isMobileLandscape ? classes.mobileRightContainer : classes.rightContainer} >
                            <RoomControlComponent
                                onEnd={this.handleEnd} onExit={this.handleExit}
                                                       onOpenFileDialog={this.handleFileDownload}
                                                       onRequestPublish={() => this.props.roomStore.requestPresentation(PresentationType.QUESTION, this.presenter.sendRequestPublishControlMessage, this.props.roomStore.userId)}

                            />

                            <RoomChatComponent onSendChatMessage={this.handleSendChatMessage}
                                               onSendChatWhisperMessage={this.handleSendChatWhisperMessage}
                                               onSendChatLink={this.handleSendChatLink}
                                               onSendChatNotification={this.handleSendChatNotification}
                                               onRequestPublish={() => this.props.roomStore.requestPresentation(PresentationType.QUESTION, this.presenter.sendRequestPublishControlMessage, this.props.roomStore.userId)}
                                               onRequestPresentationPublish={(userId) => this.props.roomStore.requestPresentation(PresentationType.PRESENTATION, this.presenter.sendRequestPresentationPublishControlMessage, userId)}
                                               onConfirmPublish={this.handleConfirmPublish}
                                               onStopMemberPublish={this.handleStopMemberPublish}
                                               onOpenAttendance={this.handleChannelAttendance}
                                               onOpenAttendRequestDialog={this.handleAttendRequestDialog}
                                               onOpenQuizDialog={this.handleQuizDialogOpen}
                                               onOpenQuizResult={this.handleQuizResultDialogOpen}
                                               onOpenPollDialog={this.handleOpenPollCreateDialog}
                                               onOpenFileDialog={this.handleFileDownload}
                                               onClosePoll={this.handleFinishPoll}
                                               whiteBoardRef={this.whiteBoardRef}
                                               onWhiteBoardVisibleChanged={(visible) => this.presenter && this.presenter.setWhiteBoardVisible(visible)}
                                               onWhiteBoardDataChanged={(data) => this.presenter && this.presenter.setWhiteBoardData(data)}
                                               onWhiteBoardCleared={() => this.presenter && this.presenter.clearWhiteBoard()}
                                               // getBoundingVideoRect={() => this.primaryVideoAreaRef.current.getBoundingClientRect()}
                                               // onSendWhiteBoardData={this.handleOnSendWhiteBoardData}
                                               onHandleChatting={this.handleChatting}

                                               onStartTinyGroup={() => this.presenter.startTinyGroup()}

                                               getJanus={() => this.presenter.janus}
                                               onSendTinyGroupStartMessage={() => this.presenter.sendTinyGroupRequestMessage(true)}
                            />
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <ConfirmDialog maxWidth="xs"
                               dialogOpen={memberPublishDialogOpen}
                               title={<Typography className={classes.dialogTitle}>방송 시작</Typography>}
                               content={
                                   <Box display="flex" flexDirection="column">
                                       <Typography>방송을 시작하려면 확인 버튼을 누르세요.</Typography>
                                   </Box>
                               }

                               onCancel={this.handleCloseMemberPublishDialog}
                               onOk={this.handleMemberPublish}
                />

                <ConfirmDialog maxWidth="xs"
                               dialogOpen={audioPlayDialogOpen}
                               title={<Typography className={classes.dialogTitle}>소리 켜짐</Typography>}
                               content={
                                   <Box display="flex" flexDirection="column">
                                       <Box display="flex" flexDirection="row" alignItems="flex-end">
                                           <Typography>확인을 누르면 소리가 켜집니다.</Typography>
                                       </Box>
                                       <Box display="flex" flexDirection="row" alignItems="flex-end">
                                           <Typography style={{paddingRight: 4}}>소리 조절은 화면 아래에 위치한</Typography>
                                           <VolumeUpIcon color="primary" />
                                           <Typography style={{paddingLeft: 4}}>버튼을 이용하세요.</Typography>
                                       </Box>
                                   </Box>
                               }

                               onOk={() => this.handlePlayAudio(true)}
                />

                <ConfirmDialog maxWidth="xs"
                               dialogOpen={reconnectDialogOpen}
                               title={<Typography className={classes.dialogTitle}>서버접속 에러</Typography>}
                               content={
                                   <Box display="flex" flexDirection="column">
                                       <Typography>서버 접속에 문제가 발생하였습니다. 다시 시도할까요?</Typography>
                                   </Box>
                               }

                               onCancel={() => this.handleReconnectDialogResponse(false)}
                               onOk={() => this.handleReconnectDialogResponse(true)}
                />

                <ConfirmDialog maxWidth="xs"
                               dialogOpen={kickDialogOpen}
                               title={<Typography className={classes.dialogTitle}>서버접속 에러</Typography>}
                               content={
                                   <Box display="flex" flexDirection="column">
                                       <Typography>이미 접속되어 있는 사용자 입니다. 이전 접속을 끊고, 새로 접속 할까요?</Typography>
                                   </Box>
                               }

                               onCancel={() => this.handleKickDialogResponse(false)}
                               onOk={() => this.handleKickDialogResponse(true)}
                />

                <ConfirmDialog maxWidth="xs"
                               dialogOpen={endRoomDialogOpen}
                               title={<Typography className={classes.dialogTitle}>수업 종료</Typography>}
                               content={
                                   <Box display="flex" flexDirection="column">
                                       <Typography>수업을 종료하면 선생님과 학생들 모두 수업에 다시 입장 할 수 없습니다.</Typography>
                                       <Typography>종료하시겠습니까?</Typography>
                                   </Box>
                               }

                               onCancel={() => this.handleEndRoomDialogResponse(false)}
                               onOk={() => this.handleEndRoomDialogResponse(true)}
                />

                <ChannelAttendanceDialog loginType={this.props.loginType} userId={this.props.userId} />
                <BroadcastAttendReqeuestDialog open={this.props.roomStore.isAttendRequestDialogOpen}
                                               isFirst={this.props.roomStore.isFirstAttendRequest}
                                               changeIsFirst={() => this.props.roomStore.changeIsFirstAttendRequest(false)}
                                               handleClose={() => this.props.roomStore.changeAttendRequestDialogOpen(false)}
                                               handleChatAttendance={this.handleChatAttendance}
                                               handleChatAttendanceLate={this.handleChatAttendanceLate}
                />
                <BroadcastAttendDialog open={attendDialogStatus}
                                       msg={attendDialogMsg}
                                       msgError={attendDialogMsgError}
                                       msgHelperText={attendDialogMsgHelperText}
                                       changeStatus={changeAttendDialogStatus}
                                       changeMsg={changeAttendDialogMsg}
                                       doAttend={this.handleDoAttend}
                                       attendMsg={attendMsg}
                                       changeMsgError={this.handleChangeAttendErrorMsg}
                />
                <BroadcastQuizDialog loginType={this.props.loginType} userId={this.props.userId} sendQuiz={this.handleSendQuiz} />
                <BroadcastQuizResultDialog loginType={this.props.loginType} userId={this.props.userId} />

                <TinyGroupDialog onSendEndMessage={() => {
                                        this.presenter.sendTinyGroupRequestMessage(false)
                                        setTimeout(() => this.presenter.sendTinyGroupRequestMessage(false), 1000);
                                 }}
                                 onSendChangeOwnerParticipationGroup={() => this.presenter.sendTinyGroupChangeOwnerParticipationGroupMessage()}
                                 onSendNotifyMessage={() => this.presenter.sendTinyGroupNotifyMessage()}
                                 onSendCallOwner={() => this.presenter.sendCallOwner()}
                                 onClose={this.handleOnCloseTinyGroup}
                />

                <PollCreateDialog />
                <PollSelectDialog />

                <ChannelFileDialog userId={this.props.userId} />

                <Backdrop className={classes.backdrop} open={(!this.props.janusInitialized) || (!storeInitialized) || (chatState === 'Connecting')}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container>
        );
    }
}

export default withSnackbar(withRouter (withWidth()(withStyles(styles, { withTheme: true }) (Room2))));
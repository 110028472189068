// /*global Janus,adapter, _CONST_TYPES*/
/*global Janus*/
import {janusRoomType, publishType} from "../../stores/BroadcastStore";
import {deviceType, getDeviceBrowserType} from "../../common/Params";

// let isDebug = null;
let serverAddr = null;
let channelId = null;
let userId = null;
let userName = null;
let pluginHandleObj = null;
let subPluginHandleObj = null;
// let broadcastVideoElType = null;
// let channelOwnerUserId = null;
let deviceId = null;
// let audioDeviceId = null;
// let recordFlag = false;
let subDeviceId = null;

let changePluginHandle = null;
let changeJanusObj = null;
let getMainVideoEl = null;
let getSubVideoEl = null;
// let getVideoElChangeStatus = null;
// let changeVideoElStatus = null;
let changeSubPluginHandle = null;
let getMainPublishType = null;
let getSubPublishType = null;
let handleWebRtcPublisherRoomJoined = null;
let handleMainSound = null;
let handleSubSound = null;
let changeIsVideoTrackOff = null;
let videoCheckSound = null;
let screenSharingVideoOffHandle = null;

let chatObj = null;
let presentObj = null;

let substream = 2;

export const janusRequestType = {
    exists: "exists",
    join: "join",
    create: "create",
    leave: "leave",
    kick: "kick",
    destroy: "destroy",
}

export const janusJoinPType = {
    publisher: "publisher",
    subscriber: "subscriber",
}

export const janusPluginHandlerType = {
    main: "main",
    sub: "sub",
}

let pType = janusJoinPType.subscriber;

export const initJanus = (options) => {
    if(!(options.isDebug !== null && options.serverAddr && options.channelId && options.userName && options.broadcastVideoElType && options.type && options.userId && options.channelOwnerUserId && options.handlerType !== null && options.feed)) {
        console.error('check options');
        return null;
    }

    if(typeof options.changePluginHandle !== "function") {
        console.error('options changePluginHandle must be function');
        return null;
    }

    if(typeof options.changeJanusObj !== "function") {
        console.error('options changeJanusObj must be function');
        return null;
    }

    if(typeof options.getMainVideoEl !== "function") {
        console.error('options getMainVideoEl must be function');
        return null;
    }

    if(typeof options.getVideoElChangeStatus !== "function") {
        console.error('options getVideoElChangeStatus must be function');
        return null;
    }

    if(typeof options.changeVideoElStatus !== "function") {
        console.error('options changeVideoElStatus must be function');
        return null;
    }

    if(typeof options.changeSubPluginHandle !== "function") {
        console.error('options changeSubPluginHandle must be function');
        return null;
    }

    if(typeof options.getSubVideoEl !== "function") {
        console.error('options getSubVideoEl must be function');
        return null;
    }

    if(typeof options.getMainPublishType !== "function") {
        console.error('options getMainPublishType must be function');
        return null;
    }

    if(typeof options.getSubPublishType !== "function") {
        console.error('options getSubPublishType must be function');
        return null;
    }

    if(typeof options.handleWebRtcPublisherRoomJoined !== "function") {
        console.error('options getSubPublishType must be function');
        return null;
    }

    if(typeof options.handleMainSound !== "function") {
        console.error('options getSubPublishType must be function');
        return null;
    }

    if(typeof options.handleSubSound !== "function") {
        console.error('options getSubPublishType must be function');
        return null;
    }

    // isDebug = options.IsDebug;
    serverAddr = options.serverAddr;
    channelId = options.channelId;
    userId = options.userId.toString();
    userName = options.userName;
    pType = options.type;
    // broadcastVideoElType = options.broadcastVideoElType;
    // channelOwnerUserId = options.channelOwnerUserId.toString();
    deviceId = options.deviceId;
    // recordFlag = options.recordFlag;
    subDeviceId = options.subDeviceId;

    changePluginHandle = options.changePluginHandle;
    changeJanusObj = options.changeJanusObj;
    getMainVideoEl = options.getMainVideoEl;
    // getVideoElChangeStatus = options.getVideoElChangeStatus;
    // changeVideoElStatus = options.changeVideoElStatus;
    changeSubPluginHandle = options.changeSubPluginHandle;
    getSubVideoEl = options.getSubVideoEl;
    getMainPublishType = options.getMainPublishType;
    getSubPublishType = options.getSubPublishType;
    handleWebRtcPublisherRoomJoined = options.handleWebRtcPublisherRoomJoined;
    handleMainSound = options.handleMainSound;
    handleSubSound = options.handleSubSound;
    changeIsVideoTrackOff = options.changeIsVideoTrackOff;
    videoCheckSound = options.videoCheckSound;
    screenSharingVideoOffHandle = options.screenSharingVideoOffHandle;

    chatObj = options.chatObj;
    presentObj = options.presentObj;

    if(options.handlerType === janusPluginHandlerType.sub) {
        options.changeJanusSubFeed(options.feed.toString());
    }

    initCallBack(options.handlerType, options.feed.toString());
}

export const doUnPublish = (pluginHandle) => {
    if(!pluginHandle) {
        return null;
    }

    pluginHandle.send({
        message: {
            request: 'unpublish',
        },
        success: () => {
        },
        error: (err) => {
            console.log('unpublish error');
            console.log(err);
        }
    });
    //pluginHandle.
    //unpublish
};

export const publishOwnFeed = (pluginHandle, isPlayAudio, isPlayVideo, janusPublishType, handlerType, feed, videoId) => {
    if(!pluginHandle) {
        return null;
    }

    const deviceBrowserType = getDeviceBrowserType();

    pluginHandle.createOffer({
        trickle: true,
        media: janusPublishType === publishType.SCREEN_SHARING ?
            { video: "screen", audioSend: isPlayAudio, videoRecv: false, audio: isPlayAudio, captureDesktopAudio: true, replaceAudio: true, screenshareWidth: 1280, screenshareHeight: 720 }
            :
            {video: videoId ? (deviceBrowserType.deviceType === deviceType.ios ? { deviceId: videoId } : { deviceId: videoId, width: 800, height: 450 }) : 'stdres-16:9' , audioRecv: false, videoRecv: false, audioSend: isPlayAudio, videoSend: isPlayVideo, replaceAudio: true },
        //simulcast: deviceBrowserType.deviceType === deviceType.ios,
        // simulcast: (janusPublishType !== publishType.SCREEN_SHARING) || (deviceBrowserType.deviceType === 'ios'),
        // simulcast: (janusPublishType !== publishType.SCREEN_SHARING),
        simulcast: (janusPublishType !== publishType.SCREEN_SHARING),
        success: function(jsep) {
            //BandwidthHandler
            let publish = { request: "configure", audio: isPlayAudio, video: isPlayVideo, bitrate: 768 * 1000 };
            if(deviceBrowserType.deviceType === deviceType.ios) {
                publish = { request: "configure", audio: isPlayAudio, video: isPlayVideo}
            }

            publish["videocodec"] = "vp8";
            // console.log('publish');
            // console.log(deviceBrowserType);
            // console.log(publish);
            pluginHandle.send({
                message: publish,
                jsep: jsep
            });

            handleWebRtcPublisherRoomJoined(JSON.stringify({
                roomType: janusRoomType.NORMAL,
                joinType: janusJoinPType.subscriber,
                handlerType: handlerType,
                feed: feed,
                publisherUserId: userId,
            }));
        },
        error: function(error) {
            if(chatObj !== null && presentObj !== null) {
                //chatObj.sendPresentationState(presentObj, _CONST_TYPES._PRESENTATION_STATUS.END, '', responseBody => {});
                chatObj.endPresentation(presentObj, "", () => {});
            }

            window.location.reload();
        }
    });
}

const initCallBack = (handlerType, feed) => {
    const janusObj = new Janus({
        server: serverAddr,
        success: () => {
            if(pType === janusJoinPType.publisher) {
                if(handlerType === janusPluginHandlerType.main) janusObjAttach(janusObj, janusPluginHandlerType.main, feed);
                else janusObjAttach(janusObj, janusPluginHandlerType.sub, feed);
            } else if(pType === janusJoinPType.subscriber) {
                if(handlerType === janusPluginHandlerType.main) newRemoteFeed(janusObj, channelId, userName, true, true, janusPluginHandlerType.main, feed);
                else newRemoteFeed(janusObj, channelId, userName, true, true, janusPluginHandlerType.sub, feed);
            }
        },
        error: (error) => {
        },
        destroyed: () => {
        },
        destroyOnUnload: true,
    });
}

export const janusObjAttach = (janusObj, handlerType, feed) => {
    attach(
        janusObj,
        (pluginHandle) => {
            if(handlerType === janusPluginHandlerType.main) {
                changePluginHandle(pluginHandle);
                pluginHandleObj = pluginHandle;
            } else if (handlerType === janusPluginHandlerType.sub) {
                changeSubPluginHandle(pluginHandle);
                subPluginHandleObj = pluginHandle;
            }

            joinRoomProc(pluginHandle, handlerType, feed);
        },
        (msg, jsep) => {
            let pluginHandle = null;
            let pbType = null;
            let device = null;

            if(handlerType === janusPluginHandlerType.main) {
                pluginHandle = pluginHandleObj;
                pbType = getMainPublishType();
                device = deviceId;
            } else if(handlerType === janusPluginHandlerType.sub) {
                pluginHandle = subPluginHandleObj;
                pbType = getSubPublishType();
                device = subDeviceId;
            }

            if(msg["videoroom"] === "joined" && pbType) {
                publishOwnFeed(pluginHandle, true, true, pbType, handlerType, feed, device);
            }

            if(jsep !== undefined && jsep !== null) {
                pluginHandle.handleRemoteJsep({jsep: jsep});
            }

            // if(msg["publishers"] !== undefined && msg["publishers"] !== null) {
            //     const list = msg["publishers"];
            //     for(let f in list) {
            //         let id = list[f]["id"];
            //         let display = list[f]["display"];
            //         let audio = list[f]["audio_codec"];
            //         let video = list[f]["video_codec"];
            //     }
            // }

            if(msg["unpublished"] !== undefined && msg["unpublished"] !== null) {
                // One of the publishers has unpublished?
                const unpublished = msg["unpublished"];
                if (unpublished === 'ok') {
                    // That's us
                    pluginHandle.hangup();
                    return null;
                }
            }
        },
        (stream) => {
            let handleSound = null;
            if(handlerType === janusPluginHandlerType.main) {
                handleSound = handleMainSound;
            } else if(handlerType === janusPluginHandlerType.sub) {
                handleSound = handleSubSound;
            }

            stream.onremovetrack = (event) => {
                console.log(event);
            }

            const targetElId = handlerType === janusPluginHandlerType.main ? getMainVideoEl() : getSubVideoEl();
            const targetEl = document.getElementById(targetElId);
            if(targetEl) {
                targetEl.muted = true;
                // targetEl.setAttribute("muted", "true");
                targetEl.setAttribute("autoplay", "true");
                targetEl.setAttribute("playsinline", "true");
                Janus.attachMediaStream(targetEl, stream);
            }

            if(handleSound) {
                handleSound(true);
            }

            // const videoTrack = stream.getVideoTracks();
            changeIsVideoTrackOff(false);
            videoCheckSound();
        },
        (stream) => {
        },
        (error) => {
            console.log('janus error');
            console.log(error);
        },
        (on) => {
            console.log(`web rtc state = ${on}`);
        },
        (type, on) => {
            let pbType = null;

            if(handlerType === janusPluginHandlerType.main) {
                pbType = getMainPublishType();
            } else if(handlerType === janusPluginHandlerType.sub) {
                pbType = getSubPublishType();
            }

            if(pbType === publishType.SCREEN_SHARING && type === "video") {
                if(on) {
                    screenSharingVideoOffHandle(false, handlerType);
                } else {
                    screenSharingVideoOffHandle(true, handlerType);
                }
            }
        },
        () => {
            console.log('on clean up');
        }
    );
}

export const newRemoteFeed = (janusObj, channelId, userName, audio, video, handlerType, feed) => {
    attach(
        janusObj,
        pluginHandle => {
            if(handlerType === janusPluginHandlerType.main) {
                changePluginHandle(pluginHandle);
                pluginHandleObj = pluginHandle;
            } else if (handlerType === janusPluginHandlerType.sub) {
                changeSubPluginHandle(pluginHandle);
                subPluginHandleObj = pluginHandle;
            }

            const subscribe = { "request": "join", "room": channelId, "ptype": janusJoinPType.subscriber, "display": userName, "feed": feed, "audio": audio, "video": video, "close_pc": true };
            if(Janus.webRTCAdapter.browserDetails.browser === "safari" && (video === "vp9" || (video === "vp8" && !Janus.safariVp8))) {
                if(video) video = video.toUpperCase();
                subscribe["offer_video"] = false;
            }

            //opus|g722|pcmu|pcma|isac32|isac16
            //pluginHandle.audiocodec = 'g722';
            pluginHandle.send({"message": subscribe});
        },
        (msg, jsep) => {
            const event = msg["videoroom"];
            let pluginHandle = null;

            if(handlerType === janusPluginHandlerType.main) {
                pluginHandle = pluginHandleObj;
            } else if (handlerType === janusPluginHandlerType.sub) {
                pluginHandle = subPluginHandleObj;
            }

            if(msg["error"] !== undefined && msg["error"] !== null) {
            } else if(event !== undefined) {
                if(event === "attached") {
                    pluginHandle.rfid = msg["id"];
                    pluginHandle.rfdisplay = msg["display"];
                } else if(event === "event") {
                    const sub = msg["substream"];
                    if((sub !== undefined) && (sub != null)) {
                        console.log(`substream type: ${handlerType}, substeam:  ${substream}, recieved substeam:  ${sub}`);
                        if(substream > sub) {
                            const configure = {
                                "request": "configure",
                                "substream": substream
                            }
                            setTimeout(() => {
                                pluginHandle.send({message: configure});
                                if(sub !== 0) substream = sub;
                            }, 1000);
                            // console.log(`substream type: ${handlerType}, send substeam:  ${substream}`);
                        } else if(substream < sub) {
                            substream = sub;
                            // console.log(`substream type: ${handlerType}, set substeam:  ${substream}`);
                        }
                    }
                    // const configured = msg["configured"];
                    // if(configured !== undefined && configured !== null && configured === "ok") {
                    //     console.log("configured");
                    //     const pause = { "request": "pause", "room": channelId };
                    //     pluginHandle.send({"message": pause});
                    //     // const start = { "request": "start", "room": channelId };
                    //     // pluginHandleObj.send({"message": start});
                    // }
                    //
                    // const paused = msg["paused"];
                    // if(paused !== undefined && paused !== null && paused === "ok") {
                    //     console.log("paused");
                    //     const start = { "request": "start", "room": channelId };
                    //     pluginHandle.send({"message": start});
                    // }
                    //
                    // const started = msg["started"];
                    // if(started !== undefined && started !== null && started === "ok") {
                    //     console.log("started");
                    // }
                } else {
                }
            }
            if(jsep !== undefined && jsep !== null) {
                createAnswer(pluginHandle, jsep, "start", false, false);
            }
        },
        stream => {
        },
        stream => {
            const targetElId = handlerType === janusPluginHandlerType.main ? getMainVideoEl() : getSubVideoEl();
            const target = document.getElementById(targetElId);
            stream.onremovetrack = (event) => {
                console.log(event);
            }

            if(target) {
                target.muted = true;
                target.setAttribute("autoplay", "true");
                target.setAttribute("playsinline", "true");
                Janus.attachMediaStream(target, stream);
            }

            const videoTracks = stream.getVideoTracks();
            if(videoTracks && videoTracks.length !== 0) {
                changeIsVideoTrackOff(false);
            } else if(videoTracks.length === 0) {
                changeIsVideoTrackOff(true);
            }

            let handleSound = null;
            if(handlerType === janusPluginHandlerType.main) {
                handleSound = handleMainSound;
            } else if(handlerType === janusPluginHandlerType.sub) {
                handleSound = handleSubSound;
            }

            if(handleSound) {
                handleSound(true);
            }

            videoCheckSound();
            //broadcastSwitch(stream, target);
        },
error => {
            console.log('janus error');
            console.log(error);
        },
        on => {
            console.log('webrtcState: ' + on);
        },
        (type, on) => {
            console.log(`mediaState: ${type}, ${on}`);
        },
        () => {
            console.log('onCleanup');
        }
    );
}

const attach = (janusObj, success, onMessage, onLocalStream, onRemoteStream, error, webrtcState, mediaState, onCleanup) => {
    janusObj.attach({
        plugin: "janus.plugin.videoroom",
        webrtcState: on => webrtcState(on),
        mediaState: (medium, on) => mediaState(medium, on),
        success: pluginHandle => success(pluginHandle) ,
        onmessage: (msg, jsep) => onMessage(msg, jsep),
        onlocalstream: stream => onLocalStream(stream),
        onremotestream: stream => onRemoteStream(stream),
        error: error => error(error),
        oncleanup: () => onCleanup(),
    });
    changeJanusObj(janusObj);
}

const joinRoomProc = (pluginHandle, handlerType, feed) => {
    pluginHandle.send({
        message: {request: "list"},
        success: (data) => {
        },
        error: (err) => {
        }
    })
    // const roomDestroyReq = {
    //     request: "destroy",
    //     room: channelId
    // }
    //
    // pluginHandle.send({
    //     message: roomDestroyReq,
    //     success: (data) => {
    //         console.log('room destroy');
    //         roomCreate(pluginHandle, handlerType, feed);
    //         //joinRoom(pluginHandle, handlerType, feed);
    //     }
    // });

    const reqMsgExistsRoom = {
        request : janusRequestType.exists,
        room : channelId
    }

    pluginHandle.send({
        message: reqMsgExistsRoom,
        success: (data) => {
            if(data.exists) {
                kickUserInRoom(pluginHandle, handlerType, feed);
                //roomLeave(pluginHandle);
                //joinRoom(pluginHandle, handlerType, feed);
            } else {
                roomCreate(pluginHandle, handlerType, feed);
            }
        }
    });
}

const joinRoom = (pluginHandle, handlerType, feed) => {
    const requestMsgJoin = {
        request: janusRequestType.join,
        room: channelId,
        id: feed,
        ptype: janusJoinPType.publisher,
        display: userName,
    };

    pluginHandle.send({message: requestMsgJoin});
};

const roomCreate = (pluginHandle, handlerType, feed) => {
    const create = {
        request : janusRequestType.create,
        room : channelId,
        videocodec: 'vp9,vp8,h264',
        audiocodec: 'opus,g722,pcmu,pcma,isac32,isac16',
        bitrate_cap: false,
        // record: recordFlag,
        // rec_dir: `/opt/janus/recs/${channelId}`,
    };

    pluginHandle.send({
        message: create,
        success: (data) => {
            joinRoom(pluginHandle, handlerType, feed);
        }
    });
};

export const roomLeave = (pluginHandle) => {
    const leave = {
        request: janusRequestType.leave,
    };

    pluginHandle.send({
        message: leave,
    });
}

export const kickUserInRoom = (pluginHandle, handlerType, feed) => {
    const kick = {
        request : janusRequestType.kick,
        room : channelId,
        id : (handlerType === janusPluginHandlerType.main) ? channelId : userId
    };

    pluginHandle.send({
        message: kick,
        success: (data) => {
            joinRoom(pluginHandle, handlerType, feed);
        }
    });
}

export const createAnswer = (pluginHandle, jsep, request, video, audio) => {
    pluginHandle.createAnswer(
        {
            jsep: jsep,
            media: { audioSend: video, videoSend: audio },
            success: jsep => {
                Janus.debug(jsep);
                const body = { "request": request, "room": channelId };
                pluginHandle.send({"message": body, "jsep": jsep});
            },
            error: error => {}
        }
    );
}

export const getCameraDeviceList = (callback) => {
    Janus.listDevices(devices => {
        const videoDeviceList = devices.filter(device => device.kind === "videoinput" && device.deviceId !== "default");
        if(videoDeviceList && videoDeviceList.length > 0) {
            callback(videoDeviceList);
        }
    });
};

export const roomDestroy = (pluginHandle) => {
    if(!pluginHandle || !channelId) {
        return null;
    }

    pluginHandle.send({
        message: {
            request: janusRequestType.destroy,
            room: channelId,
        },
        success: (data) => {
        },
        error: (err) => {
        }
    });
}
import _ from "lodash";

const LogPrefix = '[WhiteBoard] ';
const MaxDrawingHistoryLen = 50;

export const ModeType = {
    CANVAS_ALPHA: 'ALPHA',
    DRAWING: 'DRAWING',
    TYPING: 'TYPING',
    ERASER: 'ERASER',
    CLEAR: 'CLEAR',
}

export const DrawingType = {
    PEN: 'PEN',
    MAKA: 'MAKA',
    COLOR_PEN: 'COLOR_PEN',
}

const drawingInit = {
    mode: ModeType.DRAWING,

    drawingType: DrawingType.PEN,
    lineWidth: 5,

    isBold: false,
    isUnderline: false,
    isStroke: false,
    fontSize: 26,
    text: '',

    color: '#ffffff',
    points: [],

    boundingRect: {},
    canvasAlpha: 0,
}

const DefaultCallbacks = {
    onDataChanged: undefined,
    onDataMax: undefined,
}

export class WhiteBoard {
    constructor(canvas, canDraw, callbacks) {
        this.canvas = canvas;

        this.current = _.cloneDeep(drawingInit);
        this.currentDrawing = _.cloneDeep(drawingInit);
        this.drawingHistory = [];
        this.input = undefined;

        this.drawing = false;
        this.visible = false;

        this.callbacks = Object.assign({}, DefaultCallbacks, callbacks);

        if(canDraw) {
            this.setCanvasAlpha(0);
            this.initDrawHandler();

            console.log(LogPrefix, 'Whiteboard initialized with draw handler');
        } else {
            console.log(LogPrefix, 'Whiteboard initialized without draw handler');
        }
    }

    _handleCallback = (callbackName, ...args) => {
        const callback = this.callbacks[callbackName];
        if(callback) {
            try {
                callback(...args);
            } catch (error) {
                console.warn(LogPrefix, `Error in ${callbackName} callback`, error);
            }
        } else {
            console.warn(LogPrefix, `Can not found ${callbackName} callback`);
        }
    }

    destroy = () => {
        this.current = _.cloneDeep(drawingInit);
        this.currentDrawing = _.cloneDeep(drawingInit);
        this.drawingHistory = [];
        this.input = undefined;

        this.drawing = false;

        this.visible = false;
        this.setCanvasAlpha(0);
    }

    initDrawHandler = () => {
        if(this.canvas && this.canvas.current) {
            this.canvas.current.addEventListener('mousedown', this.onMouseDown, false);
            this.canvas.current.addEventListener('mouseup', this.onMouseUp, false);
            this.canvas.current.addEventListener('mouseout', this.onMouseUp, false);
            this.canvas.current.addEventListener('mousemove', this.throttle(this.onMouseMove, 10), false);

            //Touch support for mobile devices
            this.canvas.current.addEventListener('touchstart', this.onMouseDown, false);
            this.canvas.current.addEventListener('touchend', this.onMouseUp, false);
            this.canvas.current.addEventListener('touchcancel', this.onMouseUp, false);
            this.canvas.current.addEventListener('touchmove', this.throttle(this.onMouseMove, 10), false);
        } else {
            console.warn(LogPrefix, "WhiteBoard Init Failed. Canvas is not exist. : ", this.canvas.current);
        }
    }

    drawLine = (x0, y0, x1, y1, option) => {
        if(this.canvas && this.canvas.current) {
            const context = this.canvas.current.getContext('2d');
            context.beginPath();
            context.moveTo(x0, y0);
            context.lineTo(x1, y1);
            context.globalCompositeOperation = 'source-over';
            context.strokeStyle = option.color;
            context.lineCap = 'round';
            context.lineJoin = 'round';
            context.lineWidth = option.lineWidth;
            context.stroke();
            context.closePath();
        }
    }

    onEraser = (x0, y0, x1, y1) => {
        if(this.canvas && this.canvas.current) {
            const context = this.canvas.current.getContext('2d');
            context.beginPath();
            const radius = 20;
            const startAngle = 0;
            const endAngle = Math.PI * 2;
            const anticlockwise = false;

            const x = (x0 + x1)/2;
            const y = (y0 + y1)/2;
            context.arc(x, y, radius, startAngle, endAngle, anticlockwise);
            context.globalCompositeOperation = 'destination-out';
            context.fill();
            context.closePath();
        }
    }

    onMouseDown = (e) => {
        const point = this.getPoint(e);
        if(this.current.mode === ModeType.TYPING) {
            this.addInput(e);
        } else {
            this.drawing = true;
        }

        this.current.x = point.x;
        this.current.y = point.y;
    }

    onMouseUp = (e) => {
        if (!this.drawing) { return; }
        this.drawing = false;

        const point = this.getPoint(e);
        this.setCurrentDrawing(point);
        this.pushDrawingHistory();

        this.onMouseAction(this.current.mode, point);
    }

    onMouseMove = (e) => {
        if (!this.drawing) { return; }
        const point = this.getPoint(e);
        this.setCurrentDrawing(point);

        this.onMouseAction(this.current.mode, point);

        this.current.x = point.x;
        this.current.y = point.y;
    }

    onMouseAction = (mode, point) => {
        switch (mode) {
            case ModeType.DRAWING:
                this.drawLine(this.current.x, this.current.y, point.x, point.y, this.current);
                break;
            case ModeType.ERASER:
                this.onEraser(this.current.x, this.current.y, point.x, point.y);
                break;
            default:
                break;
        }
    }

    // limit the number of events per second
    throttle = (callback, delay) => {
        let previousCall = new Date().getTime();
        return function() {
            const time = new Date().getTime();
            if ((time - previousCall) >= delay) {
                previousCall = time;
                callback.apply(null, arguments);
            }
        };
    }

    //text input
    drawText = (text, x, y, option) => {
        if(this.canvas && this.canvas.current) {
            const context = this.canvas.current.getContext('2d');
            context.globalCompositeOperation = 'source-over';
            context.textBaseline = 'top';
            context.textAlign = 'left';
            context.font = option.fontSize + 'px Arial';
            if(option.isBold) {
                context.font = 'bold ' + option.fontSize + 'px Arial';
            } else {
                context.font = option.fontSize + 'px Arial';
            }
            context.fillStyle = option.color;
            context.fillText(text, x, y);

            if(option.isUnderline) this.textUnderline(context, text, x, y, option.color, option.fontSize, false);
            if(option.isStroke) this.textUnderline(context, text, x, y, option.color, option.fontSize, true);
        }
    }

    textUnderline = (context, text, x, y, color, textSize, isStroke) => {
        const measureText = context.measureText(text);
        const startX = x;
        const startY = isStroke ? y + (parseInt(textSize)/2) : y + (parseInt(textSize));
        const endX = x + measureText.width;
        const endY = startY;
        let underlineHeight = parseInt(textSize)/15;
        if(underlineHeight < 1) underlineHeight = 1;

        context.beginPath();
        context.strokeStyle = color;
        context.lineWidth = underlineHeight;
        context.moveTo(startX,startY);
        context.lineTo(endX,endY);
        context.stroke();
    }

    addInput = e => {
        this.removeInput();

        this.input = document.createElement('input');
        this.input.type = 'text';
        this.input.style.position = 'absolute';
        this.input.style.left = e.clientX + 'px';
        this.input.style.top = e.clientY + 'px';
        this.input.style.zIndex = 9999;

        this.input.onkeydown = this.handleEnter;
        document.body.appendChild(this.input);
        this.input.focus();
    }

    handleEnter = e => {
        const keyCode = e.keyCode;
        if(keyCode === 13) { //enter
            const curX = parseInt(this.current.x, 10);
            const curY = parseInt(this.current.y, 10);

            this.current.text = e.target.value;
            this.setCurrentDrawing({curX: curX, curY: curY});
            this.pushDrawingHistory();

            this.drawText(e.target.value, curX, curY, this.current);
            this.removeInput();
        } else if(keyCode === 27) { //esc
            this.removeInput();
        }
    }

    removeInput = () => {
        if(this.input) {
            document.body.removeChild(this.input);
            this.input = undefined;
        }
    }

    setVisible = (visible, rect) => {
        this.visible = visible;
        if(visible) {
            this.resizeCanvas(rect);
        } else {
            this.resizeCanvas({width: 1, height: 1});
        }
    }

    setData = (data) => {
        this.drawingHistory = data;
    }

    resizeCanvas = (boundingTargetRect) => {
        console.log(LogPrefix, 'Resize canvas', boundingTargetRect);

        if(this.canvas && this.canvas.current) {
            this.canvas.current.style.left = `${boundingTargetRect.left}px`;
            this.canvas.current.style.top = `${boundingTargetRect.top}px`;
            this.canvas.current.style.width = `${boundingTargetRect.width}px`;
            this.canvas.current.style.height = `${boundingTargetRect.height}px`;
            this.canvas.current.setAttribute('left', `${boundingTargetRect.left}px`);
            this.canvas.current.setAttribute('top', `${boundingTargetRect.top}px`);
            this.canvas.current.setAttribute('width', `${boundingTargetRect.width}px`);
            this.canvas.current.setAttribute('height', `${boundingTargetRect.height}px`);
        }
    }

    clearCanvas = () => {
        if(this.canvas && this.canvas.current) {
            const boundingCurrentRect = this.canvas.current.getBoundingClientRect();
            const context = this.canvas.current.getContext('2d');
            context.beginPath();
            context.clearRect(0, 0, boundingCurrentRect.width, boundingCurrentRect.height);
            context.closePath();
        }
    }

    redraw = (rect) => {
        if(this.canvas && this.canvas.current && this.visible) {
            if(rect) {
                this.resizeCanvas(rect);
            }
            const boundingCurrentRect = rect ? rect : this.canvas.current.getBoundingClientRect();
            this.clearCanvas();

            console.log(LogPrefix, "redraw", this.drawingHistory);
            this.drawingHistory.forEach(drawHist => {
                const boundingRect = drawHist.boundingRect;
                const scaleX = boundingCurrentRect.width / boundingRect.width;
                const scaleY = boundingCurrentRect.height / boundingRect.height;

                // console.log(LogPrefix, drawHist.menu, scaleX, scaleY);
                if(drawHist.mode === ModeType.CANVAS_ALPHA) {
                    this.setCanvasAlpha(drawHist.canvasAlpha);
                } else {
                    drawHist.points.forEach(point => {
                        const pointCurX = point.curX * scaleX;
                        const pointCurY = point.curY * scaleY;
                        const pointX = point.x * scaleX;
                        const pointY = point.y * scaleY;

                        switch (drawHist.mode) {
                            case ModeType.DRAWING:
                                this.drawLine(pointCurX, pointCurY, pointX, pointY, drawHist);
                                break;
                            case ModeType.ERASER:
                                this.onEraser(pointCurX, pointCurY, pointX, pointY);
                                break;
                            case ModeType.TYPING:
                                this.drawText(drawHist.text, pointCurX, pointCurY, drawHist);
                                break;
                            default:
                                break;
                        }
                    });
                }
            });
        }
    }

    //action
    getPoint(e) {
        if(this.canvas && this.canvas.current) {
            const boundingCurrentRect = this.canvas.current.getBoundingClientRect();
            const x = e.clientX || e.touches ? e.clientX || e.touches[0].clientX : 0;
            const y = e.clientY || e.touches ? e.clientY || e.touches[0].clientY : 0;
            return {curX: this.current.x, curY: this.current.y, x: x - boundingCurrentRect.left, y: y - boundingCurrentRect.top};
        } else return {curX: 0, curY: 0, x: 0, y: 0};
    }

    setCurrentDrawing = point => {
        if(this.canvas && this.canvas.current) {
            this.currentDrawing.mode = this.current.mode;

            this.currentDrawing.drawingType = this.current.drawingType;
            this.currentDrawing.lineWidth = this.current.lineWidth;

            this.currentDrawing.isBold = this.current.isBold;
            this.currentDrawing.isUnderline = this.current.isUnderline;
            this.currentDrawing.isStroke = this.current.isStroke;
            this.currentDrawing.fontSize = this.current.fontSize;
            this.currentDrawing.text = this.current.text;

            this.currentDrawing.color = this.current.color;
            this.currentDrawing.canvasAlpha = this.current.canvasAlpha;

            if (point) this.currentDrawing.points.push({...point});
            this.currentDrawing.boundingRect = this.canvas.current.getBoundingClientRect();
        }
    }

    setCanvasAlpha = alpha => {
        if(this.canvas && this.canvas.current) {
            this.canvas.current.style.backgroundColor = `RGBA(255, 255, 255, ${alpha})`;
        }
    }

    setBold = isBold => {
        this.current.mode = ModeType.TYPING;
        this.current.isBold = isBold;
    }

    setUnderline = isUnderline => {
        this.current.mode = ModeType.TYPING;
        this.current.isUnderline = isUnderline;
    }

    setStroke = isStroke => {
        this.current.mode = ModeType.TYPING;
        this.current.isStroke = isStroke;
    }

    initDrawingHistory = () => {
        this.drawingHistory = [];
        this.currentDrawing = _.cloneDeep(drawingInit);

        this._handleCallback('onDataMax', false);
        this._handleCallback('onDataChanged', []);
    }

    pushDrawingHistory = () => {
        if(this.currentDrawing.mode === ModeType.CANVAS_ALPHA) {
            this.removeAllCanvasAlphaInDrawingHistory();
            this.drawingHistory.push(_.cloneDeep(this.currentDrawing));
        } else if(this.currentDrawing.mode === ModeType.DRAWING || this.currentDrawing.mode === ModeType.TYPING) {
            const cnt = this.countDrawingOrTyping();
            if(cnt > MaxDrawingHistoryLen) {
                console.log(LogPrefix, 'Drawing count is full', cnt);

                this._handleCallback('onDataMax', true);

                return;
            } else {
                this.drawingHistory.push(_.cloneDeep(this.currentDrawing));
            }
        } else {
            this.drawingHistory.push(_.cloneDeep(this.currentDrawing));
        }

        this.currentDrawing = _.cloneDeep(drawingInit);
        this._handleCallback('onDataChanged', this.drawingHistory);
    }

    countDrawingOrTyping = () => {
        let cnt = 0;
        for(let i=0; i<this.drawingHistory.length; i++) {
            const d = this.drawingHistory[i];
            if(d.mode === ModeType.DRAWING || d.mode === ModeType.TYPING) {
                cnt++;
            }
        }
        return cnt;
    }

    removeAllCanvasAlphaInDrawingHistory = () => {
        const removeCanvasAlpha = () => {
            for(let i=0; i<this.drawingHistory.length; i++) {
                const d = this.drawingHistory[i];
                if(d.mode === ModeType.CANVAS_ALPHA) {
                    this.drawingHistory.splice(i, 1);
                    return true;
                }
            }

            return false;
        }

        while(removeCanvasAlpha());
    }

    changeColor = color => {
        if(this.current.mode !== ModeType.TYPING) this.removeInput();
        this.current.color = color;
    }

    changeModeType = modeType => {
        if(modeType !== ModeType.TYPING) this.removeInput();
        this.current.mode = modeType;
        console.log(LogPrefix, 'whiteBoard' ,this.current.mode);
    }

    changeDrawingType = drawingType => {
        this.current.mode = ModeType.DRAWING;
        this.removeInput();
        this.current.drawingType = drawingType;
    }

    changeLineWidth = lineWidth => {
        this.current.mode = ModeType.DRAWING;
        this.removeInput();
        this.current.lineWidth = lineWidth;
    }

    changeFontSize = fontSize => {
        this.current.mode = ModeType.TYPING;
        this.current.fontSize = fontSize;
    }

    changeCanvasAlpha = canvasAlpha => {
        this.current.mode = ModeType.CANVAS_ALPHA;
        this.removeInput();
        this.current.canvasAlpha = canvasAlpha/100;
        this.setCanvasAlpha(canvasAlpha/100);
        this.setCurrentDrawing();
        this.pushDrawingHistory();
    }
}
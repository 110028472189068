import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Chip, Grid, Typography} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    contentHeader: {
        padding: theme.spacing(2),
        backgroundColor: '#fafafa'
    },
    contentValue: {
        padding: theme.spacing(2),
    },
    chipContainer: {
        padding: theme.spacing(1),
    },
    chip: {
        backgroundColor: '#fafafa',
    },
    tipArea: {
        padding: '16px',
        margin: theme.spacing(5,0,3),
        '& div:nth-child(2) > div' :{
            color : theme.palette.primary.main,
            fontSize:'1rem'
        },
        '& div ol' :{
            padding:'0 0 0 1em',
        },
        '& div ol li' :{
            marginTop:theme.spacing(1)
        },
        '& div ol li:first-child' :{
            marginTop:0
        },
    },
    rightAlignTextBody: {
        paddingTop: 20,
        paddingRight: theme.spacing(2),
        textAlign: 'right',
        fontSize: 16,
        fontWeight: 700,
    },
    inlineValue: {
        display: 'inline',
    },
}));

export default function ChannelSummaryComponent(props) {
    const classes = useStyles();
    const {channel, memberCount} = props;

    return (
        <Grid container>
            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={3} className={classes.contentHeader}>
                <Typography>강의명</Typography>
            </Grid>
            <Grid item xs={4} className={classes.contentValue}>
                <Typography>{channel.channelName}</Typography>
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={3} className={classes.contentHeader}>
                <Typography>강의 UI</Typography>
                {/*<Typography>부화면 사용여부</Typography>*/}
            </Grid>
            <Grid item xs={2} className={classes.contentValue}>
                <Typography>{channel.version > 1 ? "v2.0" : "v1.0"}</Typography>
                {/*<Typography>사용</Typography>*/}
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={3} className={classes.contentHeader}>
                <Typography>강의 설명</Typography>
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
                <Typography>{channel.channelDesc}</Typography>
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={3} className={classes.contentHeader}>
                <Typography >시작 시각</Typography>
            </Grid>
            <Grid item xs={3} className={classes.contentValue}>
                <Typography>{moment(channel.liveStartDatetime).format("LLL")}</Typography>
            </Grid>
            <Grid item xs={3} className={classes.contentHeader}>
                <Typography>종료 시각</Typography>
            </Grid>
            <Grid item xs={3} className={classes.contentValue}>
                <Typography>{moment(channel.liveEndDatetime).format("LLL")}</Typography>
            </Grid>

            <Grid item xs={12}>
                <Divider />
            </Grid>

            <Grid item xs={3} className={classes.contentHeader} style={{paddingTop: 20}}>
                <Typography>{`참여 대상 (${memberCount} 명)`}</Typography>
            </Grid>
            <Grid item xs={9} className={classes.contentValue}>
                <div>
                    {channel.inviteGroupList.map((group, index) =>
                        <Chip key={`channel-group-chip-${index}-${group.groupId}`} className={classes.chip} label={`${group.groupName}(${group.groupUserCount}명)`} />
                    )}
                    {channel.channelMemberList.map((member, index) =>
                        <Chip key={`channel-member-chip-${index}-${member.email}`} className={classes.chip} label={member.member ? member.userName : member.email} />
                    )}
                </div>
            </Grid>

            <Grid item xs={12}>
                <Divider/>
            </Grid>
        </Grid>
    );
};
import React from "react";
import {withRouter} from "react-router-dom";
import AdminTemplates from "./AdminTemplates";
import UserManagementTable from "./UserManagementTable";
import {inject, observer} from "mobx-react";

@inject('adminStore', 'authStore')
@observer
class Admin extends React.Component {
    componentDidMount() {
        if(!this.props.authStore.isLoginTypeAdmin) {
            this.props.history.push("/");
        } else {
            this.props.adminStore.setUserId(this.props.userId);
        }
    }

    render() {
        const { isLoginTypeAdmin } = this.props;
        return (
            isLoginTypeAdmin && <AdminTemplates userManagementTable={<UserManagementTable history={this.props.history}/>}/>
        );
    }
}

export default withRouter(Admin);
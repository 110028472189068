import React from "react";
import {Breadcrumbs, Container, Divider, FormControl, Link, Tab, Tabs, Typography, withStyles} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {withRouter} from "react-router-dom";
import Footer from "../../components/Footer";

const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        marginTop: theme.spacing(2),
    },
    subTitle: {
        marginTop: theme.spacing(5),
    },
    content: {
        margin: theme.spacing(3),
    },
    contentTitle: {
        paddingTop: theme.spacing(3),

    },
    contentSubtitle: {
        paddingTop: theme.spacing(3),
    },
    contentBody1: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(5),
    },
    contentBody2: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(10),
    },
});

class Terms extends React.Component {
    handleChangeTab = (event, tabValue) => {
        this.props.history.push(`/terms/${tabValue}`)
    };

    render() {
        const { classes } = this.props;
        const tabValue = this.props.match.params.value ? this.props.match.params.value : 'terms';

        return (
            <React.Fragment>
                <Container component="main" maxWidth="lg" className={classes.mainContainer}>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.mainContent} >
                        <Typography variant="h4" component="h2">
                            이용약관
                        </Typography>
                        <FormControl margin="dense">
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                                <Link color="inherit" underline="none">Home</Link>
                                <Link color="inherit" href="#" onClick={(event) => {event.preventDefault()}}>이용약관</Link>
                            </Breadcrumbs>
                        </FormControl>

                        <Tabs value={tabValue} className={classes.subTitle}
                              indicatorColor="primary"
                              textColor="inherit"
                              onChange={this.handleChangeTab}>
                            <Tab value={'terms'} label={<Typography variant="subtitle1">서비스이용약관</Typography>} />
                            <Tab value={'privacy'} label={<Typography variant="subtitle1">개인정보처리방침</Typography>} />                            
                        </Tabs>
                        <Divider />

                        {tabValue === 'terms' ?
                        	<div className={classes.content}>
		                        <Typography className={classes.contentTitle} variant="h6">제1장 총칙</Typography>
		                        
		                        <Typography className={classes.contentSubtitle} variant="subtitle2">제1조 (목적)</Typography>
		                        <Typography className={classes.contentBody1} variant="body2">
		                        본 회원약관은 (주)아이테르정보기술(이하 '당사'라 한다)가 운영하는 인터넷 웹사이트 서비스 (이하 '라이브온에어'이라 한다)를 이용함에 있어 관리자와 이용자(이하 '회원'라 한다)의 권리, 의무 및 책임사항을 규정함을 목적으로 한다.
		                        </Typography>                            
		                        <Typography className={classes.contentSubtitle} variant="subtitle2">제2조 (용어의정의)</Typography>
		                        <Typography className={classes.contentBody1} variant="body2">이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</Typography>
		                        <Typography className={classes.contentBody2} variant="body2">1. '라이브온에어'란 PC와 모바일에서 카메라를 통해 서로의 공부하는 영상을 공유하고 강의를 준비하는 교수님과 수업을 듣는 학생이 학습할 수 있도록 제공하는 미디어 플랫폼입니다. </Typography>
		                        <Typography className={classes.contentBody2} variant="body2">2. 회원이란 본 약관에 동의하고 당사가 제공하는 라이브온에어 이용 자격을 부여 받은 자를 의미합니다. </Typography>
		                        <Typography className={classes.contentBody2} variant="body2">3. '방장'이란 스터디를 개설하고 운영하는 회원을 의미합니다. </Typography>
		                        <Typography className={classes.contentBody2} variant="body2">4. '결제 사업자'란 라이브온에어를 설치하고 결제할 수 있도록 제공하는 전자상거래 제공자 일체(라이브온에어내 결제를 제공하는 사업자 포함)를 말합니다. (예. 이니시스, 구글플레이스토어, 앱스토어, 카카오페이, 네이버페이, TOSS 등) </Typography>
		                        <Typography className={classes.contentBody2} variant="body2">5. '온더'란 회원이 라이브온에어내 아이템을 구매할 수 있는 라이브온에어내 재화를 의미합니다. </Typography>
		                        <Typography className={classes.contentBody2} variant="body2">6. '온더샵'이란 온더를 통해 회원이 아이템을 구매할 수 있는 라이브온에어의 기능을 의미합나다. </Typography>
		                        <Typography className={classes.contentBody2} variant="body2">7. '충전'이란 회원이 온더샵의 아이템을 구매하기 위해 당사에서 제공하는 결제수단으로 현금을 온더로 전환하는 행위를 말합니다. </Typography>
		                        <Typography className={classes.contentBody2} variant="body2">8. '환불'이란 회원이 충전한 온더를 현금으로 되돌려 받는 것을 말합니다. </Typography>
		                        <Typography className={classes.contentBody2} variant="body2">9. '탈퇴'란 회원이 라이브온에어 이용 계약을 종료하는 의사표시를 말합니다. </Typography>
		                        <Typography className={classes.contentBody2} variant="body2">10. '이메일 주소'란 회원 식별과 회원의 라이브온에어 이용을 위하여 회원이 선정하고 '당사'이 승인하는 이메일 형식에 준한 문자와 숫자의 조합 </Typography>
		                        
		                        <Typography className={classes.contentTitle} variant="h6">제2장 이용계약 체결</Typography> 
		                        
		                        <Typography className={classes.contentSubtitle} variant="subtitle2">제3조 (약관의 효력)</Typography>
		                        <Typography className={classes.contentBody1} variant="body2"></Typography>
		                        <Typography className={classes.contentBody1} variant="body2">1. 본 약관은 '당사'에 회원 가입 시 회원들에게 통지함으로써 효력을 발생합니다. </Typography>
		                        <Typography className={classes.contentBody1} variant="body2">2. '당사'는 이 약관의 내용을 변경할 수 있으며, 변경된 약관은 제1항과 같은 방법으로 공지 또는 통지함으로써 효력을 발생합니다.</Typography>
		                        <Typography className={classes.contentBody1} variant="body2">3. 약관의 변경사항 및 내용은 '라이브온에어'의 홈페이지에 게시하는 방법으로 공시합니다.</Typography>
		                        
		                        <Typography className={classes.contentSubtitle} variant="subtitle2">제4조 (약관 이외의 준칙)</Typography>
		                        <Typography className={classes.contentBody1} variant="body2">이 약관에 명시되지 않은 사항이 전기 통신 기본법, 전기통신 사업법, 기타 관련 법령에 규정되어 있는 경우 그 규정에 따릅니다.</Typography>
		                        
		                        <Typography className={classes.contentSubtitle} variant="subtitle2">제5조 (이용계약의 체결)</Typography>
		                        <Typography className={classes.contentBody1} variant="body2">회원 가입 시 회원 약관 동의버튼을 누르면 약관에 동의하여 이 계약이 체결된 것으로 간주한다.</Typography>
		
		                        <Typography className={classes.contentSubtitle} variant="subtitle2">제6조 (이용신청)</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    1.회원 가입은 온라인으로 가입신청 양식에 기록하여 '당사'에 제출함으로써 이용신청을 할 수 있습니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">2.가입희망 회원은 반드시 자신의 이메일 주소로 이용신청을 하여야 하며, 1개의 ID만 신청을 할 수 있습니다.</Typography>
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">제7조 (회원가입의 승낙)</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    당사'의 회원 가입 신청 양식에 가입 희망 회원이 인터넷으로 제6조와 같이 신청하면 '당사'는 바로 가입을 승인하여 라이브온에어를 이용할 수 있다.</Typography>
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">제8조(회원가입 신청거절 및 강제 탈퇴)</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    1. '당사'는 타인의 명의나 이메일을 도용하여 회원가입신청을 할 경우 회원가입신청을 거절할 수 있다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    2. 회원가입신청이 승인이 된 후에도 허위사실의 기재가 발각되거나 '당사'의 명예를 회손시키거나 음란물이나 불건전한 내용을 게재할 경우 회원의 자격을 강제 탈퇴시킬 수 있다.</Typography>
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">제9조 (서비스 제공의 중지)</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    당사'는 다음 각 호의 1에 해당하는 경우 라이브온에어 서비스의 제공을 중지할 수 있습니다</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">1.설비의 보수 등을 위하여 부득이한 경우</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">2.전기통신사업법에 규정된 기간통신사업자가 전기통신서비스를 중지하는 경우</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">3.기타 '당사'가 라이브온에어 서비스를 제공할 수 없는 사유가 발생한 경우.</Typography>
			                  
			                    <Typography className={classes.contentTitle} variant="h6">제3장 계약 당사자의 의무</Typography>
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">제10조 ('당사'의 의무)</Typography>    	                   
			                    <Typography className={classes.contentBody1} variant="body2">1. '당사'는 계속적, 안정적으로 라이브온에어 서비스를 제공할 수 있도록 최선의 노력을 다하여야 합니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">2.'당사'는 항상 회원의 신용정보를 포함한 개인신상정보의 보안에 대하여 관리에 만전을 기함으로서 회원의 정보보안에 최선을 다하여야 합니다.</Typography>
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">제11조 (개인정보보호)</Typography>
		 	                    <Typography className={classes.contentBody1} variant="body2">
			                    1.'당사'는 회원의 정보수집시 라이브온에어 서비스의 제공에 필요한 최소한의 정보를 수집합니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    2.제공된 개인정보는 당해 회원의 동의없이 목적외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 '당사'가 집니다. 다만, 다음의 경우에는 예외로 합니다.</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ①통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ②전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ③범죄에 대한 수사상의 목적이 있거나 정보통신윤리 위원회의 요청이 있는 경우</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ④기타 관계법령에서 정한 절차에 따른 요청이 있는 경우</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    3.회원은 언제든지 '당사'가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 '당사'는 이에 대해 지체없이 처리합니다.</Typography>
			                    
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">제12조 (회원의 의무)</Typography>
		  	                   
			                    <Typography className={classes.contentBody1} variant="body2">
			                    1.회원은 관계법령, 이 약관의 규정, 이용안내 및 주의사항 등 '당사'이 통지하는 사항을 준수하여야 하며, 기타 '당사'의 업무에 방해되는 행위를 하여서는 안됩니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    2.회원은 '당사'의 사전 승낙 없이 라이브온에어를 이용하여 어떠한 영리 행위도 할 수 없습니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    3.회원은 라이브온에어를 이용하여 얻은 정보를 '당사'의 사전 승낙 없이 복사, 복제, 변경, 번역, 출판,방송 기타의 방법으로 사용하거나 이를 타인에게 제공할 수 없습니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    4.회원은 이용신청서의 기재내용 중 변경된 내용이 있는 경우 라이브온에어를 통하여 그 내용을 '당사'에게 통지하여야 합니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    5.회원은 라이브온에어 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.</Typography>
			                    
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ①다른 회원의 아이디(ID)를 부정 사용하는 행위</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ②범죄행위를 목적으로 하거나 기타 범죄행위와 관련된 행위</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ③선량한 풍속, 기타 사회질서를 해하는 행위</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ④타인의 명예를 훼손하거나 모욕하는 행위</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ⑤타인의 지적재산권 등의 권리를 침해하는 행위</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ⑥해킹행위 또는 컴퓨터바이러스의 유포행위</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ⑦타인의 의사에 반하여 광고성 정보 등 일정한 내용을 지속적으로 전송 또는 타 사이트를 링크하는 행위</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ⑧서비스의 안전적인 운영에 지장을 주거나 줄 우려가 있는 일체의 행위</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ⑨기타 관계법령에 위배되는 행위</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ⑩게시판 등 커뮤니티를 통한 상업적 광고홍보 또는 상거래 행위</Typography>
			                    
			                    <Typography className={classes.contentTitle} variant="h6">제4장 서비스의 이용</Typography>
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">제13조 (게시물 또는 내용물의 삭제)</Typography>           	                   
			                    <Typography className={classes.contentBody1} variant="body2">
			                    당사'는 라이브온에어의 게시물 또는 내용물이 제12조의 규정에 위반되거나 '당사' 소정의 게시기간을 초과하는 경우 사전 통지나 동의 없이 이를 삭제할 수 있습니다.</Typography>
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">제14조 (게시물에 대한 권리·의무)</Typography>           	                   
			                    <Typography className={classes.contentBody1} variant="body2">
			                    게시물에 대한 저작권을 포함한 모든 권리 및 책임은 이를 게시한 회원에게 있습니다.</Typography>
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">제15조 (양도금지)</Typography>           	                   
			                    <Typography className={classes.contentBody1} variant="body2">
			                    회원이 라이브온에어의 이용권한, 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.</Typography>                 
			                    
			                    
			                    <Typography className={classes.contentTitle} variant="h6">제5장 충전 및 환불</Typography>
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">제16조 (구매 및 사용기간)</Typography>           	                   
			                    <Typography className={classes.contentBody1} variant="body2">
			                    회원은 결제 사업자의 결제 정책에 따라 온더를 구매할 수 있습니다. 이 경우, 결제 사업자의 결제 정책에 따라 결제 금액에 차이가 발생할 수 있습니다.
			                    회원이 구매한 온더의 유효기간은 온더는 구매한 날로부터 3년이며, 이 기간이 경과한 후에는 회원은 해당 온더에 대한 이용권한이 상실됩니다.
			                    회원은 온더를 회원 본인의 계정에서만 이용할 수 있으며, 제3자에게 양도, 대여, 매매할 수 없습니다.
			                    </Typography>
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">제17조 (환불)</Typography>           	                   
			                    <Typography className={classes.contentBody1} variant="body2">
			                    회원이 구매한 온더는 구매일로부터 7일 이내에 별도의 수수료 없이 구매 취소가 가능합니다.
			                    당사는 구매 취소 신청자로부터 제공받은 개인 정보 및 구매 내역을 확인하여 구매 취소를 진행합니다. 당사는 회원의 정당한 구매 취소 사유를 확인하기 위해 추가 증빙자료를 요구할 수 있습니다. (예. 플랫폼사업자에 등록된 개인정보, 결제 사업자의 구매 또는 결제 내역, 고객의 의도와 상이하게 타인을 통해 구매되었다는 것을 확인할 수 있는 서류(가족관계 증명) 등)
			                    다음 각 호의 경우 온더의 구매 취소가 제한될 수 있습니다.
			                    </Typography>
			                    
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ①구매 후 7일이 지난 라이브온에어의 경우</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ②라이브온에어에서 아이템을 구매하기 위해 이미 라이브온에어를 사용한 경우(회원의 의도에 반하여 타인에 의해 사용되었을 경우도 포함)</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ③구매 당시 지급받은 라이브온에어 수량에 차이가 있을 경우</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ④이벤트, 광고, 보상 등 무상으로 제공받은 라이브온에어의 경우</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ⑤미성년자가 사술 등을 통해 성년자로 믿게 하여 구매, 사용한 경우</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ⑥회원이 서비스를 탈퇴하여 구매 내역 등 정보 확인이 불가한 경우</Typography>
			                    
			                    <Typography className={classes.contentBody1} variant="body2">
			                    미성년자가 법정대리인의 동의 없이 결제한 경우, 미성년자 또는 법정대리인은 라이브온에어 구매를 취소할 수 있습니다. 이 경우 당사는 라이브온에어 구매가 진행된 단말기 또는 신용카드 등 결제 수단 명의자를 기준으로 미성년자 여부를 판단합니다. 당사는 필요한 경우 구매 취소 신청자에게 미성년자 및 법정대리인을 증명할 수 있는 서류를 요청할 수 있습니다.
			                    </Typography>
			           
			                    
			                    <Typography className={classes.contentTitle} variant="h6">제6장 계약 해지 및 이용 제한</Typography>
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">제18조 (계약해지 및 이용제한)</Typography>           	                   
			                    <Typography className={classes.contentBody1} variant="body2">
			                    1.회원이 이용계약을 해지하고자 하는 때에는 본인이 서비스 또는 전자 우편을 통하여 해지하고자 하는 날의 1일전까지 (단, 해지일이 법정 공휴일인 경우 공휴일 개시 2일전까지)이를 '당사'에게 신청하여야 합니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    2.'당사'는 해지 및 탈퇴를 한 회원이 다시 이용신청을 하는 경우 일정기간 그 승낙을 제한할 수 있습니다.</Typography>
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">제19조 (면책·배상)</Typography>           	                   
			                    <Typography className={classes.contentBody1} variant="body2">
			                    1.'당사'는 회원이 라이브온에어에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등 그 내용에 관하여는 어떠한 책임을 부담하지 아니하고, 회원은 자기의 책임아래 라이브온에어를 이용하며, 라이브온에어를 이용하여 게시 또는 전송한 자료 등에 관하여 손해가 발생하거나 자료의 취사 선택, 기타서비스 이용과 관련하여 어떠한 불이익이 발생 하더라도 이에 대한 모든 책임은 회원에게 있습니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    2.'당사'는 제12조의 규정에 위반하여 회원간 또는 회원과 제3자간에라이브온에어를 매개로 하여 물품거래 등과 관련하여 어떠한 책임도 부담하지 아니하고, 회원이 서비스의 이용과 관련하여 기대하는 이익에 관하여 책임을 부담하지 않습니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    3.회원 아이디(ID)와 비밀번호의 관리 및 이용상의 부주의로 인하여 발생 되는 손해 또는 제3자에 의한 부정사용 등에 대한 책임은 모두 회원에게 있습니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    4.회원이 제12조, 기타 이 약관의 규정을 위반함으로 인하여 '당사'이 회원 또는 제3자에 대하여 책임을 부담하게 되고, 이로써 '당사'에게 손해가 발생하게 되는 경우, 이 약관을 위반한 회원은 '당사'에게 발생하는 모든 손해를 배상하여야 하며, 동 손해로부터 '당사'를 면책시켜야 합니다.</Typography>
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">제20조 (분쟁의 해결)</Typography>           	                   
			                    <Typography className={classes.contentBody1} variant="body2">
			                    1.'당사'와 회원은 라이브온에어와 관련하여 발생한 분쟁을 원만하게 해결하기 위하여 필요한 모든 노력을 하여야 합니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    2.1항의 규정에도 불구하고 분쟁으로 인하여 소송이 제기될 경우 소송은 '당사'의 소재지를 관할하는 법원의 관할로 합니다.</Typography>
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle1">부 칙</Typography>           	                   
			                    <Typography className={classes.contentBody1} variant="body2">
			                    제 1 조 (시행일) 이 약관은 2020년 4월 1일부터 시행한다.</Typography>  		    	                    
		                    </div>
		                    :
		                    ''
                        }

                        {tabValue === 'privacy' ?
                            <div className={classes.content}>
		                        <Typography className={classes.contentTitle} variant="h6">개인정보처리방침</Typography>
			                    
		                        <Typography className={classes.contentBody1} variant="body2">(주)아이테르정보기술('www.onthe.live'이하 '라이브온에어')은(는) 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">(주)아이테르정보기술('라이브온에어') 은(는) 당사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.</Typography>
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">1. 개인정보의 처리 목적 (주)아이테르정보기술('www.onthe.live'이하 '라이브온에어')은(는) 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    가. 홈페이지 회원가입 및 관리
			                    </Typography>                    
			                    <Typography className={classes.contentBody2} variant="body2">회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    나. 재화 또는 서비스 제공
			                    </Typography>                    
			                    <Typography className={classes.contentBody2} variant="body2">서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증 등을 목적으로 개인정보를 처리합니다.</Typography>
			                    
			                    <Typography className={classes.contentBody1} variant="body2">
			                    다. 마케팅 및 광고에의 활용
			                    </Typography>                    
			                    <Typography className={classes.contentBody2} variant="body2">신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
			                    </Typography>	                    
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">2. 개인정보 파일 현황 ('www.onthe.live'이하 '라이브온에어')가 개인정보 보호법 제32조에 따라 등록․공개하는 개인정보파일의 처리목적은 다음과 같습니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    1. 개인정보 파일명 : 라이브온에어 개인정보처리방침
			                    </Typography>                    
			                    <Typography className={classes.contentBody2} variant="body2">- 개인정보 항목 : 로그인 ID(이메일주소), 이름, 비밀번호</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">- 수집방법 : 홈페이지</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">- 보유근거 : 홈페이지 이용약관</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">- 보유기간 : 5년</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">- 관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년, 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년, 계약 또는 청약철회 등에 관한 기록 : 5년</Typography>
			                  
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ※ 기타('www.onthe.live'이하 '라이브온에어')의 개인정보파일 등록사항 공개는 행정안전부 개인정보보호 종합지원 포털(www.privacy.go.kr) → 개인정보민원 → 개인정보열람등 요구 → 개인정보파일 목록검색 메뉴를 활용해주시기 바랍니다.
			                    </Typography> 
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">3. 개인정보의 처리 및 보유 기간</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ① (주)아이테르정보기술('라이브온에어')은(는) 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집시에 동의 받은 개인정보 보유,이용기간 내에서 개인정보를 처리,보유합니다.
			                    </Typography>       
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.
			                    </Typography>       
			                    
			                    <Typography className={classes.contentBody2} variant="body2">1)홈페이지 회원가입 및 관리</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">홈페이지 회원가입 및 관리와 관련한 개인정보는 수집.이용에 관한 동의일로부터5년까지 위 이용목적을 위하여 보유.이용됩니다.</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">-보유근거 : 홈페이지 이용약관</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">-관련법령 : 1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년</Typography>
			        
			                    <Typography className={classes.contentBody2} variant="body2">2) 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">3) 계약 또는 청약철회 등에 관한 기록 : 5년</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">-예외사유 :</Typography>		                   
			                        
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">4. 개인정보의 제3자 제공에 관한 사항</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ① (주)아이테르정보기술('www.onthe.live'이하 '라이브온에어')은(는) 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.
			                    </Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ② (주)아이테르정보기술('www.onthe.live')은(는) 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
			                    </Typography>
			                             
			                    <Typography className={classes.contentBody2} variant="body2">- 개인정보 항목 : 로그인 ID(이메일주소), 이름, 비밀번호</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">- 수집방법 : 홈페이지</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">- 보유근거 : 홈페이지 이용약관</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">- 보유기간 : 5년</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">- 관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년, 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년, 계약 또는 청약철회 등에 관한 기록 : 5년</Typography>
			                  
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ※ 기타('www.onthe.live'이하 '라이브온에어')의 개인정보파일 등록사항 공개는 행정안전부 개인정보보호 종합지원 포털(www.privacy.go.kr) → 개인정보민원 → 개인정보열람등 요구 → 개인정보파일 목록검색 메뉴를 활용해주시기 바랍니다.
			                    </Typography>
			                    
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">5. 개인정보처리 위탁</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ① (주)아이테르정보기술('라이브온에어')은(는) 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
			                    </Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                     - 위탁관리
			                    </Typography>
			                    <Typography className={classes.contentBody2} variant="body2">- 위탁받는 자 (수탁자) : 이니시스</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">- 위탁하는 업무의 내용 : 결제 대행 서비스</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">- 위탁기간 : 결제일을 포함하여 5년간 보유</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ② (주)아이테르정보기술('www.onthe.live'이하 '라이브온에어')은(는) 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
			                    </Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.
			                    </Typography>		                    
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">6. 정보주체의 권리,의무 및 그 행사방법 이용자는 개인정보주체로서 다음과 같은 권리를 행사할 수 있습니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ① 정보주체는 (주)아이테르정보기술(‘www.onthe.live’이하 ‘라이브온에어) 에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.
			                    </Typography>
			                    <Typography className={classes.contentBody2} variant="body2">1. 개인정보 열람요구</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">2. 오류 등이 있을 경우 정정 요구</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">3. 삭제요구</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">4. 처리정지 요구</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ② 제1항에 따른 권리 행사는(주)아이테르정보기술(‘www.onthe.live’이하 ‘라이브온에어) 에 대해 개인정보 보호법 시행규칙 별지 제8호 서식에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 기관/회사명(‘사이트URL’이하 ‘사이트명) 은(는) 이에 대해 지체 없이 조치하겠습니다.
			                    </Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 기관/회사명(‘사이트URL’이하 ‘사이트명) 은(는) 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
			                    </Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ③ 정보주체가 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 기관/회사명(‘사이트URL’이하 ‘사이트명) 은(는) 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.
			                    ④ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.
			                    </Typography>		                    
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">7. 처리하는 개인정보의 항목 작성</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ① (주)아이테르정보기술('www.onthe.live'이하 '라이브온에어')은(는) 다음의 개인정보 항목을 처리하고 있습니다.
			                    </Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    1. 홈페이지 회원가입 및 관리
			                    </Typography>
			                    <Typography className={classes.contentBody2} variant="body2">- 필수항목 : 로그인 ID(이메일주소), 이름, 비밀번호</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">- 선택항목 :</Typography>		                    
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">8. 개인정보의 파기(주)아이테르정보기술('라이브온에어')은(는) 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.</Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    -파기절차이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.-파기기한이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
			                    </Typography>
			                    <Typography className={classes.contentBody1} variant="body2">
			                    -파기방법
			                    </Typography>
			                    <Typography className={classes.contentBody2} variant="body2">전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.</Typography>
			                    <Typography className={classes.contentBody2} variant="body2">종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</Typography>	                    
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">9. 개인정보의 안전성 확보 조치 (주)아이테르정보기술('라이브온에어')은(는) 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.</Typography>
			                    
			                    <Typography className={classes.contentBody1} variant="body2">
			                    1. 정기적인 자체 감사 실시                    
			                    </Typography>                    
			                    <Typography className={classes.contentBody2} variant="body2">
			                    개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.
			                    </Typography>
			                    
			                    <Typography className={classes.contentBody1} variant="body2">
			                    2. 개인정보 취급 직원의 최소화 및 교육                    
			                    </Typography>                    
			                    <Typography className={classes.contentBody2} variant="body2">
			                    개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다.
			                    </Typography>
			                    
			                    <Typography className={classes.contentBody1} variant="body2">
			                    3. 해킹 등에 대비한 기술적 대책                   
			                    </Typography>                    
			                    <Typography className={classes.contentBody2} variant="body2">
			                    (주)아이테르정보기술('라이브온에어')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
			                    </Typography>
			                    
			                    <Typography className={classes.contentBody1} variant="body2">
			                    4. 개인정보의 암호화                    
			                    </Typography>                    
			                    <Typography className={classes.contentBody2} variant="body2">
			                    이용자의 개인정보 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다.
			                    </Typography>
			                    
			                    <Typography className={classes.contentBody1} variant="body2">
			                    5. 개인정보에 대한 접근 제한                    
			                    </Typography>                    
			                    <Typography className={classes.contentBody2} variant="body2">
			                    개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
			                    </Typography>                    
			                    
			
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">10. 개인정보 보호책임자 작성
			                    </Typography>                    
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ① (주)아이테르정보기술(‘www.onthe.live’이하 ‘라이브온에어) 은(는) 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.                    
			                    </Typography> 
			                    
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ▶ 개인정보 보호책임자                    
			                    </Typography> 
			                    <Typography className={classes.contentBody2} variant="body2">
			                    성명 :                   
			                    </Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    직책 :                   
			                    </Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    직급 :                   
			                    </Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    연락처 :02-6941-1535, support@aetherit.io                   
			                    </Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    ※ 개인정보 보호 담당부서로 연결됩니다.                   
			                    </Typography>
			                    
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ▶ 개인정보 보호 담당부서                   
			                    </Typography> 
			                    <Typography className={classes.contentBody2} variant="body2">
			                    부서명 :부설연구소                   
			                    </Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    담당자 :                 
			                    </Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    연락처 :02-6941-1535, support@aetherit.io                   
			                    </Typography>		                    
			                    
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ② 정보주체께서는 (주)아이테르정보기술(‘www.live.onair’이하 ‘라이브온에어) 의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. (주)아이테르정보기술(‘www.onthe.live’이하 ‘라이브온에어) 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.                    
			                    </Typography>  
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">11. 개인정보 처리방침 변경
			                    </Typography>                    
			                    <Typography className={classes.contentBody1} variant="body2">
			                    ①이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.                    
			                    </Typography> 
			                    
			                    <Typography className={classes.contentBody1} variant="body2">
			                    o 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.                    
			                    </Typography> 
			                    <Typography className={classes.contentBody2} variant="body2">
			                    개인정보침해신고센터 (privacy.kisa.or.kr / 국번 없이 118)                   
			                    </Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    대검찰청 사이버범죄수사단 (www.spo.go.kr / 02-3480-2000)                  
			                    </Typography>
			                    <Typography className={classes.contentBody2} variant="body2">
			                    경찰청 사이버안전국 (www.ctrc.go.kr/ 국번 없이 182)                   
			                    </Typography> 
			                    
			                    <Typography className={classes.contentSubtitle} variant="subtitle2">12. 고지의 의무
			                    </Typography>                    
			                    <Typography className={classes.contentBody1} variant="body2">
			                    본 개인정보취급방침은 볍령 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제 및 수정이 있을 시에는 변경이 되는 개인정보 처리방침을 시행하기 최소 7일전에 홈페이지의 '공지'를 통해 고지할 것입니다.                   
			                    </Typography>			                    
			                    <Typography className={classes.contentTitle} variant="subtitle2">○ 본 방침은부터 2020년 4월 1일부터 시행됩니다.</Typography>
                            </div>
                            :
                            ''
                        }
                        
                    </div>
                </Container>

                <Footer/>
            </React.Fragment>
        );
    }
}

export default withRouter(withStyles(styles)(Terms));
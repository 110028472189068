import {action, computed, flow, observable, toJS} from "mobx";
import axios from "axios";
import moment from "moment";
import _ from "lodash";

const LiveTimeFormat = "YYYY-MM-DDTHH:mm:ss";
const MinTotalTime = 600;
const DefaultTotalTime = 3000;
const DefaultLimitCount = 10;
const calculatePoint = (totalTime, limitCount) => totalTime * limitCount / 60;

const getCurrentTimeMoment = () => {
    const current = moment();
    const minute = current.minute();
    const addMinute = minute % 10;

    current.add(addMinute > 0 ? addMinute : 10, 'minutes');
    current.set('second', 0);
    current.set('millisecond', 0);

    // console.log('StartDate : ' + current.format(LiveTimeFormat));

    return current;
}

const EmtpyChannel = {
    channelId: '',
    userId: 0,
    channelName: '',
    channelDesc: '',
    liveStartDatetime: getCurrentTimeMoment().format(LiveTimeFormat),
    liveEndDatetime: getCurrentTimeMoment().add(MinTotalTime, 'seconds').format(LiveTimeFormat),
    liveTotalTime: DefaultTotalTime,
    joinLimitCount: DefaultLimitCount,
    reservedPoint: calculatePoint(DefaultTotalTime, DefaultLimitCount),
    durationTime: 0,
    typeCode: '',
    statusCode: '',
    subScreenAvailableFlag: false,
    version: 1,
    statusReason: '',
    thumbnailUrl: '',
    channelMemberList: [],
    channelStateList: [],
    channelBroadcastEndpointList: [],
    channelFileList: [],
    inviteGroupList: [],
};

const UpdateDialogState = {
    Closed: 'Closed',
    Loading : 'Loading',
    Loaded: 'Loaded',
    LoadFailed: 'LoadFailed',
    Summary: 'Summary',
    Updating: 'Updating',
    Updated: 'Updated',
    UpdateFailed: 'UpdateFailed',
};

export default class ChannelDetailStore {
    InviteType = {
        Personal: 'Personal',
        Group: 'Group',
    }
    @observable updateDialogState = UpdateDialogState.Closed;
    @observable inviteType = this.InviteType.Group;
    @observable groupList = [];
    @observable selectedGroupId = '';
    @observable newEmail = '';
    @observable channel = {...EmtpyChannel};
    @observable availablePoint = 0;


    @action clearUpdateDialogState = (open) => {
        if(open) {
            this.updateDialogState = UpdateDialogState.Loaded;
        } else {
            this.updateDialogState = UpdateDialogState.Closed;
            this.inviteType = this.InviteType.Personal;
            this.groupList = [];
            this.selectedGroupId = '';
            this.newEmail = '';
            this.channel = {...EmtpyChannel};
            this.availablePoint = 0;
        }
    }

    @action updateJoinLimitCount = () => {
        let count = _.reduce(toJS(this.channel.inviteGroupList), (sum, n) => sum + n.groupUserCount, 0);
        count += this.channel.channelMemberList.length;

        this.channel.joinLimitCount = count;
    }

    @action toSummary = () => {
        this.updateJoinLimitCount();
        this.channel.liveEndDatetime = moment(this.channel.liveStartDatetime).add(this.channel.liveTotalTime, 'seconds').format(LiveTimeFormat);
        // console.log(`Live time : ${this.channel.liveStartDatetime} ~ ${this.channel.liveEndDatetime}`);

        this.updateDialogState = UpdateDialogState.Summary;
    }

    @action toEdit = () => {
        this.updateDialogState = UpdateDialogState.Loaded;
    }

    @action changeSelectedGroup = (groupId) => {
        const selectedGroup = _.find(toJS(this.groupList), (group) => group.groupId === groupId);
        if(selectedGroup) {
            this.channel.inviteGroupList.push(selectedGroup);
        }

        this.updateJoinLimitCount();
    }

    @action changeChannelName = (name) => {
        this.channel.channelName = name;
    }

    @action changeChannelDesc = (desc) => {
        this.channel.channelDesc = desc;
    }

    @action changeChannelStartDate = (startDate) => {
        const startTime = moment(startDate);

        this.channel.liveStartDatetime = startTime.format(LiveTimeFormat);
        this.channel.liveEndDatetime = moment(this.channel.liveStartDatetime).add(this.channel.liveTotalTime, 'seconds').format(LiveTimeFormat);
        // console.log(`Live time : ${this.channel.liveStartDatetime} ~ ${this.channel.liveEndDatetime}`);
    }

    @action changeChannelStartAmPm = (isAM) => {
        const startTime = moment(this.channel.liveStartDatetime);

        if(isAM) {
            startTime.set('hour', 0);
            startTime.set('minute', 0);
            startTime.set('second', 0);
            startTime.set('millisecond', 0);
        } else {
            startTime.set('hour', 12);
            startTime.set('minute', 0);
            startTime.set('second', 0);
            startTime.set('millisecond', 0);
        }

        this.channel.liveStartDatetime = startTime.format(LiveTimeFormat);
        this.channel.liveEndDatetime = moment(this.channel.liveStartDatetime).add(this.channel.liveTotalTime, 'seconds').format(LiveTimeFormat);
        // console.log(`Live time : ${this.channel.liveStartDatetime} ~ ${this.channel.liveEndDatetime}`);
    }

    @action changeChannelStartHour = (hour) => {
        const time = moment(this.channel.liveStartDatetime);
        time.set('hour', hour);

        this.channel.liveStartDatetime = time.format(LiveTimeFormat);
        this.channel.liveEndDatetime = moment(this.channel.liveStartDatetime).add(this.channel.liveTotalTime, 'seconds').format(LiveTimeFormat);
        // console.log(`Live time : ${this.channel.liveStartDatetime} ~ ${this.channel.liveEndDatetime}`);
    }

    @action changeChannelStartMinute = (minute) => {
        const time = moment(this.channel.liveStartDatetime);
        time.set('minute', minute);

        this.channel.liveStartDatetime = time.format(LiveTimeFormat);
        this.channel.liveEndDatetime = moment(this.channel.liveStartDatetime).add(this.channel.liveTotalTime, 'seconds').format(LiveTimeFormat);
        // console.log(`Live time : ${this.channel.liveStartDatetime} ~ ${this.channel.liveEndDatetime}`);
    }

    @action changeChannelLiveTime = (liveTime) => {
        this.channel.liveTotalTime = liveTime;
        this.channel.liveEndDatetime = moment(this.channel.liveStartDatetime).add(this.channel.liveTotalTime, 'seconds').format(LiveTimeFormat);
        // console.log(`Live time : ${this.channel.liveStartDatetime} ~ ${this.channel.liveEndDatetime}`);

        this.channel.reservedPoint = calculatePoint(this.channel.liveTotalTime, this.channel.joinLimitCount);
    }

    @action changeChannelSubScreenAvailableFlag = (flag) => {
        this.channel.subScreenAvailableFlag = flag;
    }

    @action changeChannelVersion = (version) => {
        this.channel.version = version;
    }

    @action removeGroup = (groupId) => {
        for(let i=0; i<this.channel.inviteGroupList.length; i++) {
            if(this.channel.inviteGroupList[i].groupId === groupId) {
                this.channel.inviteGroupList.splice(i, 1);
                break;
            }
        }

        this.updateJoinLimitCount();
    }

    @action removeMember = (email) => {
        for(let i=0; i<this.channel.channelMemberList.length; i++) {
            if(this.channel.channelMemberList[i].email === email) {
                this.channel.channelMemberList.splice(i, 1);
                break;
            }
        }
    }

    @computed get filteredGroupList() {
        const channelInviteGroupList = toJS(this.channel.inviteGroupList);

        const filteredGroupList = _.filter(toJS(this.groupList), (group) => {
            const groupInInviteList = _.find(channelInviteGroupList, (inviteGroup) => inviteGroup.groupId === group.groupId);
            if(groupInInviteList) {
                return false;
            } else {
                return true;
            }
        });

        return filteredGroupList;
    }

    @computed get isOverMember() {
        return false;
    }

    @computed get memberCount() {
        let count = _.reduce(toJS(this.channel.inviteGroupList), (sum, n) => sum + n.groupUserCount, 0);
        count += this.channel.channelMemberList.length;

        return count;
    }

    @computed get isBeforeStartTime() {
        return moment(this.channel.liveStartDatetime).isBefore(moment());
    }

    @computed get isSummary() {
        return this.updateDialogState === UpdateDialogState.Summary || this.updateDialogState === UpdateDialogState.Updating || this.updateDialogState === UpdateDialogState.UpdateFailed;
    }

    @computed get isUpdatable() {
        const channel = toJS(this.channel);

        if(channel) {
            let count = _.reduce(channel.inviteGroupList, (sum, n) => sum + n.groupUserCount, 0);
            count += channel.channelMemberList.length;

            const updatable =
                (this.channel.channelId) &&
                (this.channel.userId) &&
                (this.channel.channelName.length > 0) &&
                (this.channel.channelDesc.length > 0) &&
                (this.channel.liveTotalTime) &&
                (this.channel.liveTotalTime >= MinTotalTime) &&
                (this.channel.joinLimitCount) &&
                (moment(this.channel.liveStartDatetime).isAfter(moment())) &&
                (this.channel.joinLimitCount >= count) &&
                (count > 0)
                ?
                true
                :
                false;

            return updatable;
        } else {
            return false;
        }
    }

    @computed get isOpenUpdateDialog() {
        return this.updateDialogState !== UpdateDialogState.Closed;
    }

    @computed get isLoadFailed() {
        return this.updateDialogState === UpdateDialogState.LoadFailed;
    }

    @computed get isUpdating() {
        return this.updateDialogState === UpdateDialogState.Updating;
    }

    @computed get isUpdated() {
        return this.updateDialogState === UpdateDialogState.Updated;
    }

    @computed get isUpdateFailed() {
        return this.updateDialogState === UpdateDialogState.UpdateFailed;
    }


    openUpdateDialog = flow(function* openUpdateDialog(channelId, userId) {
        this.channel = {...EmtpyChannel};
        this.updateDialogState = UpdateDialogState.Loading;

        try {
            const response = yield axios.get(`/api/v1/channels/channel-info?channel-id=${channelId}`);
            const channel = response.data;
            channel.channelMemberList = _.orderBy(channel.channelMemberList, ['email'], ['asc']);

            this.channel = Object.assign({}, EmtpyChannel, channel);
            this.updateDialogState = UpdateDialogState.Loaded;

            this.getUserPoint(userId);
            this.getGroupList(userId);
        } catch(error) {
            this.updateDialogState = UpdateDialogState.LoadFailed;
        }
    })

    updateChannel = flow(function* updateChannel() {
        this.updateDialogState = UpdateDialogState.Updating;

        try {
            const param = toJS(this.channel);
            param.inviteGroupList = toJS(this.channel.inviteGroupList).map((item) => item.groupId);
            param.inviteEmailList = toJS(this.channel.channelMemberList).map((item) => item.email);
            delete param.channelMemberList;
            delete param.channelStateList;
            delete param.channelBroadcastEndpointList;
            delete param.channelFileList;

            yield axios.put('/api/v1/channels', param);

            this.updateDialogState = UpdateDialogState.Updated;
        } catch (error) {
            this.updateDialogState = UpdateDialogState.UpdateFailed;
        }
    })

    getGroupList = flow(function* getGroupList(userId) {
        try {
            const params = {
                "user-id": userId,
                paging: "no"
            };

            const response = yield axios.get("/api/v1/groups", {params: params});
            const groupList = response.data;

            this.groupList = groupList;
        } catch(error) {
            this.groupList = [];
        }
    })

    getUserPoint = flow(function* getUserPoint(userId) {
        try {
            const params = {
                "user-id" : userId,
            };

            const response = yield axios.get("/api/v1/users/points", {params: params});
            this.availablePoint = response.data.availablePoint;
        } catch (e) {
        }
    });
}
import {flow} from "mobx";
import axios from "axios";

export default class TicketStore {
    buyTicket = flow(function* buyTicket(userId, amount) {
       try {
           const param = {
               userId: userId,
               typeCode: 'EARNED',
               spentAmount: amount,
           };
           yield axios.put(`/api/v1/users/points`, param);
       } catch (error) {
           console.log("Can't buy ticket");
       }
    });
}
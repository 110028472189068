import React from "react";
import {
    Breadcrumbs,
    Container,
    Divider,
    FormControl,
    Grid,
    Link,
    Tab,
    Tabs,
    Typography,
    withStyles
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Footer from "../../components/Footer";

const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        marginTop: theme.spacing(2),
    },
    subTitle: {
        marginTop: theme.spacing(5),
    },
    content: {
        marginTop: theme.spacing(2),
    },
    contentGrid: {
        marginTop: theme.spacing(3),
        textAlign: 'center',
    },
    itemGrid: {
        textAlign: 'center',
    },
    ticket: {
        display: 'inline-block',
        width: 225,
        textAlign: 'center',
        margin: theme.spacing(1),
        cursor: 'pointer',
    },
    ticketImgBox: {
        display: 'inline-block',
        textAlign: 'center',
        width: 225,
        height: 138,
        backgroundColor: '#fafafa',
        border: 'solid 1px #eeeeee',
    },
    ticketImg: {
        marginTop: 33,
        width: 113,
        height: 72,
    },
    ticketTextBox: {
        display: 'inline-block',
        textAlign: 'center',
        width: 225,
        paddingTop: 13,
        paddingBottom: 13,
        borderTop: 'none',
        borderLeft: 'solid 1px #eeeeee',
        borderRight: 'solid 1px #eeeeee',
        borderBottom: 'solid 1px #eeeeee',
    },
    inline: {
        display: 'inline',
    },
    notify:{
        marginTop:theme.spacing(4),
        backgroundColor:'#ffebed',
        padding:'20px 30px',
        display:'flex',
        alignItems:'center',
        borderRadius:'6px',
        '& img':{
            display:'block',
            margin:'0 auto'
        },
        '& p':{
            marginLeft:20,
            color:'#cf1a1a',
            lineHeight:'1.5',
            fontWeight:'800'
        },
    }
});


class TicketList extends React.Component {
    handleClickTicket = (amount) => {  
   
     	//this.props.history.push(`/Payment/payment/${amount}`);
        this.props.history.push(`/ticket/summary/${amount}`);
        
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Container component="main" maxWidth="lg" className={classes.mainContainer}>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.mainContent} >
                        <Typography variant="h4" component="h2">
                            이용권 구매
                        </Typography>
                        <FormControl margin="dense">
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                                <Link color="inherit" underline="none">Home</Link>
                                <Link color="inherit" href="#" onClick={(event) => {event.preventDefault()}}>이용권 구매</Link>
                            </Breadcrumbs>
                        </FormControl>

                        <Grid container className={classes.notify}>
                            <Grid item xs={12} sm={1} md={1}><img src="/images/img-mask.png" alt="마스크"/></Grid>
                            <Grid item xs={12} sm={11} md={11}><p>코로나19로 인하여 전 국민이 슬픔에 빠져있습니다. <br/>라이브온에어는 함께 극복하기 위해 현재 <u>무료로 서비스</u>를 이용하실 수 있습니다. 이용권을 선택하시면 별도의 결제 없이 구매 완료됩니다.</p></Grid>
                        </Grid>

                        <Tabs value={'prepaid'} className={classes.subTitle}
                              indicatorColor="primary"
                              textColor="inherit">
                            <Tab value={'prepaid'} label={<Typography variant="subtitle1">선불형 이용권 목록</Typography>} />
                        </Tabs>
                        <Divider />

                        <div className={classes.content}>
                            <Typography color="textSecondary" variant="body1">LIVEONAIR 이용권을 구매하고 LIVE 방송을 시작하세요. 친구들을 초대해서 그룹을 만들고 정기적인 모임을 함께해요.</Typography>
                            <Typography color="textSecondary" variant="body1">이용권은 <b>참여자 수 x 시간 x 단가</b>로 측정되어 사용됩니다.
                             </Typography>
                             {/* (단, 현재는 무료 체험 기간입니다.) */}
                        </div>

                        <Grid container className={classes.contentGrid}>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <div className={classes.ticket} onClick={() => this.handleClickTicket(10000)}>
                                    <div className={classes.ticketImgBox}>
                                        <img className={classes.ticketImg} src="/images/img-1.png" alt="ticket 10000" />
                                    </div>
                                    <div className={classes.ticketTextBox}>
                                        <Typography className={classes.inline} color="primary">10,000</Typography><Typography className={classes.inline}>원 권</Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <div className={classes.ticket} onClick={() => this.handleClickTicket(20000)}>
                                    <div className={classes.ticketImgBox}>
                                        <img className={classes.ticketImg} src="/images/img-2.png" alt="ticket 20000" />
                                    </div>
                                    <div className={classes.ticketTextBox}>
                                        <Typography className={classes.inline} color="primary">20,000</Typography><Typography className={classes.inline}>원 권</Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <div className={classes.ticket} onClick={() => this.handleClickTicket(50000)}>
                                    <div className={classes.ticketImgBox}>
                                        <img className={classes.ticketImg} src="/images/img-5.png" alt="ticket 50000" />
                                    </div>
                                    <div className={classes.ticketTextBox}>
                                        <Typography className={classes.inline} color="primary">50,000</Typography><Typography className={classes.inline}>원 권</Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                                <div className={classes.ticket} onClick={() => this.handleClickTicket(100000)}>
                                    <div className={classes.ticketImgBox}>
                                        <img className={classes.ticketImg} src="/images/img-10.png" alt="100000" />
                                    </div>
                                    <div className={classes.ticketTextBox}>
                                        <Typography className={classes.inline} color="primary">100,000</Typography><Typography className={classes.inline}>원 권</Typography>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>

                <Footer />
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(TicketList);
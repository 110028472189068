import {action, computed, flow, observable} from "mobx";
import axios from "axios";

const State = {
    Certificating: 'Certificating',
    Certificated: 'Certificated',
    CertificationFailed: 'CertificationFailed',

}

export default class MailCertificationStore {
    @observable state = State.Certificating;

    @action setCertificationFailed = () => {
        this.state = State.CertificationFailed;
    }

    @computed get isCertificated() {
        return this.state === State.Certificated;
    }

    @computed get isCertificationFailed() {
        return this.state === State.CertificationFailed;
    }

    certificate = flow(function* certificate(email, authKey) {
        this.state = State.Certificating;

        try {
            const param = {
                email: email,
                authKey: authKey
            };
            yield axios.put(`/api/v1/users/signup`, param);

            this.state = State.Certificated;
        } catch(error) {
            this.state = State.CertificationFailed;
        }
    })
}
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {
    Avatar,
    Button,
    Container,
    Grid,
    Typography,
    DialogContent,
    DialogActions
} from "@material-ui/core";

import axios from "axios";
import fileDownload from "js-file-download";
import {inject, observer} from "mobx-react";
import {JanusDemo} from "./JanusDemo";
import * as Params from "../common/Params";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog/Dialog";
import {browserType, deviceType} from "../common/Params";
import CommonDialog from "../components/CommonDialog";
import Box from "@material-ui/core/Box";

const ContentWidth = 1440;

const styles = theme => ({
    mainContainer: {
        width: '100%',
        textAlign: 'center',
        padding: 0,
        maxWidth: 'initial',
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    grow: {
        flexGrow: 1,
    },
    titleContainer: {
        height:'800px',
        width:'100%',
        display:'flex',
        backgroundImage: 'url(/images/bg.png)',
        //backgroundImage: 'url(/images/bg-ps.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition:'center',
        [theme.breakpoints.down("sm")]: {
            height:'auto',
            padding: 16,
        },
    },
    titleContainerSlogan:{
        maxWidth: ContentWidth,
        // margin:'0 auto',
        padding:'0 10vw',
        display:'flex',
        alignItems:'flex-start',
        justifyContent:'center',
        flexDirection:'column',
    },
    title1Container: {
      
    },
    title1: {
        fontSize: 52,
        fontWeight: 800,
        lineHeight: 1.2,
        color: '#333333',
        wordBreak: 'keep-all',
        [theme.breakpoints.down("sm")]: {
            fontSize:'2.3em',
        },
    },
    title2: {
        fontSize: 48,
        color: '#333333',
        [theme.breakpoints.down("sm")]: {
            fontSize:'2em',
        },
    },
    title3Container: {
        paddingTop: 52,
        textAlign : 'left'
    },
    title3: {
        fontSize: 16,
        color: 'rgba(0, 0, 0, 0.46)',
        [theme.breakpoints.down("sm")]: {
            fontSize:'0.8em',
        },
    },
    startButtonContainer: {
        paddingTop: 92,
        [theme.breakpoints.down("sm")]: {
            paddingTop: 60,
        },
    },
    startAdminButton: {
        margin: 12,
        width: 165,
        height: 48,
        fontSize: 16,
        borderRadius: 24,
        color: '#FFFFFF',
        backgroundColor: '#3c3c3c',
        '&:hover':{
            backgroundColor:'#1f1f1f'
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom:'1em',
        },
    },
    startTeacherButton: {
        margin: 12,
        width: 165,
        height: 48,
        fontSize: 16,
        borderRadius: 24,
        color: '#FFFFFF',
        backgroundColor: '#3c3c3c',
        '&:hover':{
            backgroundColor:'#1f1f1f'
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom:'1em',
        },
    },

    menuButton: {
        [theme.breakpoints.down('xs')]: {
            width: 100,
            fontSize: 13
        },
        width: 100,
        padding: '13px 0',
        background: '#3c3c3c',
        color: '#FFFFFF',
        borderRadius: 24,
        fontFamily: 'Noto Sans KR',
        marginRight: '10px',
        boxShadow: 'none',
        fontSize: 16,
        textDecoration : 'none',
        textAlign: 'center',
        fontWeight:'500',
        "&:hover": {
            background: '#1f1f1f',
            color: '#fff',
            boxShadow: 'none'
        }
    },
    startStudentButton: {
        margin:12,
        width: 165,
        height: 48,
        fontSize: 16,
        borderRadius: 24,
    },

    qrContainer: {
        paddingTop: 106,
        paddingRight: theme.spacing(3),
        textAlign: 'right',
    },

    contentContainer: {
        maxWidth: ContentWidth,
        width: '100%',
        margin:'0 auto',
        backgroundColor: '#fafafa',
    },
    content1Container: {
        paddingTop: 80,
        paddingBottom: 20,
    },
    content1PrevNextImg: {
        width: 61,
        height: 121,
        '@media screen and (max-width: 960px)': {
            width: 30,
            height: 60,
        },
        '@media screen and (max-width: 600px)': {
            width: 15,
            height: 30,
        },
    },
    content1: {
        fontSize: 32,
        fontWeight: 700,
        color: '#333333',
        [theme.breakpoints.down("sm")]: {
            fontSize:'2em',
            padding:'0 4vw'
        },
    },
    visibleContent2: {

    },
    noVisibleContent2: {
        display: 'none',
    },
    content2AvatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 32,
    },
    content2Avatar: {
        width: 30,
        height: 30,
        backgroundColor: '#3437CE',
    },
    content2TitleContainer: {
        marginTop: 22,
    },
    content2Title: {
        fontSize: 28,
        fontWeight: 700,
        color: '#333333',
    },
    content2ContentContainer: {
        marginTop: 16,
    },
    content2Content: {
        fontSize: 20,
        color: '#333333',
    },
    content2ImageContainer: {
        marginTop: 56,
        minHeight: 570,
        '@media screen and (max-width: 960px)': {
            minHeight: 405,
        },
        '@media screen and (max-width: 600px)': {
            minHeight: 252,
        },
    },
    content2Image: {
        width: '100%',
    },

    demo: {
        maxWidth: ContentWidth,
        margin:'0 auto',
        //backgroundColor: '#f4f4f4',
        paddingTop: 90,
        paddingBottom: 80,
    },
    demoTitleContainer: {
        paddingBottom: 60,
    },
    demoTitle: {
        fontSize: 32,
        fontWeight: 700,
        color: '#333333',
        [theme.breakpoints.down("sm")]: {
            fontSize:'2em',
            padding:'0 4vw'
        },
    },
    demoComment: {
        paddingTop: 16,
        paddingBottom: 8,
        fontSize: 22,
    },
    demoTeacher: {
        width: 500,
        height: 277,
        backgroundImage: 'url(/images/img-teacher-view-k.png)',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
    },
    demoTeacherVideo: {
        width: 384,
        height: 214,
        marginTop: 35,
        marginLeft: 66,
        backgroundColor: 'black',
    },
    demoStudent: {
        width: 500,
        height: 277,
        backgroundImage: 'url(/images/img-student-view-k.png)',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
        padding: 0,
    },
    demoStudentVideo: {
        width: 384,
        height: 214,
        marginTop: 35,
        marginLeft: 66,
        backgroundColor: 'black',
    },
    demoButtonContainer: {
        paddingTop: 16,
    },
    demoButton: {
        width: 330,
        height: 60,
        borderRadius: 40,
        fontSize: 26,
        color: '#3437CE',
        borderColor: '#3437CE'
    },

    body2: {
        paddingTop: 80,
        paddingBottom: 60,
        [theme.breakpoints.down("sm")]: {
            paddingTop: 40,
        },
    },
    body2TitleContainer: {
        
    },
    body2Avatar: {
        width: 100,
        height: 100,
        border: 'solid 1px #d4d4d4',
        backgroundColor: '#FFFFFF',
    },
    body2Title: {
        fontSize: 28,
        lineHeight: 1.71,
        color: '#3c3c3c',
        [theme.breakpoints.down("sm")]: {
            padding:'0 36px',
        },
    },
    body2CardContainer: {
        maxWidth: ContentWidth,
        margin:'0 auto',
        paddingTop: theme.spacing(6),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingBottom: theme.spacing(6),
    },
    body2CardTitle: {
        paddingLeft: theme.spacing(2),
        fontSize: 16,
        color: '#3c3c3c',
        textAlign: 'left',
        fontWeight:'800',
        '& b':{
            color:'#cf1a1b',
            fontSize:30,
        }
    },
    body2CardBody: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        width: '100%',
        fontSize: 14,
        color: '#9d9d9d',
        textAlign: 'left',
    },

    footerContainer: {
        backgroundImage: 'url(/images/bg-parttern.png)',
        backgroundRepeat: 'repeat-x',
        backgroundPosition: 'top 0',
        backgroundColor:'#fafafa',
        paddingBottom: 50,
        paddingTop: 60,

    },
    footerTitleContainer: {
        [theme.breakpoints.down("sm")]: {
            padding:'0 36px',
        },
    },
    footerTitle: {
        fontSize: 28,
        lineHeight: 1.71,
        letterSpacing: -0.56,
        color: '#3c3c3c',
        
    },
    footerBody1: {
        paddingTop: theme.spacing(3),
        fontSize: 14,
        lineHeight: 1.43,
        color: '#3c3c3c',
    },
    footerBody2: {
        fontSize: 14,
        lineHeight: 1.43,
        color: '#3c3c3c',
    },
    footerButton: {
        margin: theme.spacing(3,1,2,1),
        borderRadius: 50,
        padding: theme.spacing(1,0,1),
        minWidth:'192px',
        '&:first-of-type':{
            backgroundColor:'#fff'
        }
    },

    copyrightContainer: {
        paddingTop: 60,
        paddingBottom: theme.spacing(2),
        justifyContent: 'center',
        display:'flex',
    },
    copyright: {
        fontSize: 12,
        lineHeight: 1.01,
        color: '#3c3c3c',
    },
    download: {
        listStyle: 'none',
        margin: '0 auto',
        marginRight: '45px',
        '& li': {
            float: 'left',
            width: '50%',
        },
        '& li  a': {
            textDecoration: 'none',
            color: 'rgb(126, 126, 126)'
        },
        '& li img': {
            marginTop: '30px',
            width: '55px',
            height: '55px'
        }
    },
});

@inject('authStore')
@observer
class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            downloading: false,
            maxStep: 4,
            currentStep: 0,

            types: Params.getDeviceBrowserType(),
            janusDemo: undefined,
            demoStarted: false,

            demoDialogOpen: false,
            videoDevices: [],
            selectedVideoDevice: '',
            publishType: 'camera',

            openAndroid11Alert: false,
        };

        this.teacherVideoRef = React.createRef();
        this.studentVideoRef = React.createRef();
    }

    componentDidMount() {
        const deviceBrowserType = Params.getDeviceBrowserType(false);

        if((deviceBrowserType.deviceType === 'ios') && (deviceBrowserType.browserType !== 'safari')) {
            alert("아이폰이나 아이패드는 사파리가 아닐 경우 기능이 제대로 동작 하지 않을 수 있습니다.");
        }

        const userAgent = navigator.userAgent;
        console.log('UserAgent : ', userAgent);
        if(userAgent && (userAgent.indexOf('Android 11') > 0) && (userAgent.indexOf('Chrome') > 0) && (userAgent.indexOf('Whale') < 0)) {
            this.setState({openAndroid11Alert: true});
        }
    }

    componentWillUnmount() {
        if(this.state.janusDemo && this.state.demoStarted) {
            this.state.janusDemo.stop();
        }
    }


    handleClickStart = (userType) => {
        if(userType) {
            this.props.authStore.changeLoginType(userType);
        }

        this.props.history.push('/channel/list');
    }

    handlePlayStoreDownload = () => {
        axios.get('/api/v1/files/app/app-debug.apk', {responseType: 'blob'})
            .then(response => {
                // console.log(response);
                fileDownload(response.data, 'app-debug.apk');
            })
            .catch(error => {

            });
    }

    handleWindowsStoreDownload = () => {
        const fileName = 'Setup_OnTheLive_v1.0.6.zip';

        this.setState({downloading: true});

        axios.get(`/api/v1/files/app/${fileName}`, {responseType: 'blob'})
            .then(response => {
                fileDownload(response.data, fileName);

                this.setState({downloading: false});
            })
            .catch(error => {
                this.setState({downloading: false});
            });
    }

    handleClickPreviousStep = () => {
        if(this.state.currentStep > 0) {
            this.setState({
                currentStep: this.state.currentStep - 1,
                inDirection: 'left',
                outDirection: 'right',
            });
        }
    }

    handleClickNextStep = () => {
        if(this.state.currentStep < this.state.maxStep) {
            this.setState({
                currentStep: this.state.currentStep + 1,
                inDirection: 'right',
                outDirection: 'left',
            });
        }
    }

    handleClickDemo = () => {
        if(this.state.demoStarted) {
            this.state.janusDemo.stop();

            this.setState({janusDemo: undefined, demoStarted: false});
        } else {
            this.openDemoDialog();
        }
    }

    startDemo = () => {
        const janusDemo = new JanusDemo(this.teacherVideoRef, this.studentVideoRef);

        if(this.state.publishType === 'camera') {
            janusDemo.start({deviceId: this.state.selectedVideoDevice, width: 960, height: 540}, 1024);
        } else if(this.state.publishType === 'screen') {
            janusDemo.start('screen', 1024, false);
        }

        this.setState({demoDialogOpen: false, janusDemo: janusDemo, demoStarted: true});
    }

    openDemoDialog = () => {
        JanusDemo.listVideoDevices((videoDevices) => {
            if(videoDevices && videoDevices.length > 0) {
                this.setState({demoDialogOpen: true, videoDevices: videoDevices, selectedVideoDevice: videoDevices[0].deviceId});
            } else {
                window.alert('장치 검색을 할 수 없습니다.');
            }
        });
    }

    closeDemoDialog = () => {
        this.setState({demoDialogOpen: false});
    }

    handleChangePublishType = (event) => {
        this.setState({publishType: event.target.value});
    }

    handleChangeSelectedDevice = (event) => {
        this.setState({selectedVideoDevice: event.target.value});
    }


    render() {
        const { classes } = this.props;
        const { isLoggedIn, isLoginTypeUser, isLoginTypeMember} = this.props.authStore;
        const deviceBrowserType = Params.getDeviceBrowserType(false);

        const renderStartButton = () => {
            if(isLoggedIn) {
                if(isLoginTypeUser) {
                    return (
                        <div>
                            <Button variant="contained" color="default" className={classes.startTeacherButton} onClick={() => this.handleClickStart('USER')}>교수 시작하기</Button>
                        </div>
                    );
                } else if(isLoginTypeMember) {
                    return (
                        <div>
                            <Button variant="contained" color="secondary" className={classes.startStudentButton} onClick={() => this.handleClickStart('MEMBER')}>학생 시작하기</Button>
                        </div>
                    );
                } else {
                    return (
                        <div>
                            <Button variant="contained" color="primary" className={classes.startAdminButton} onClick={() => this.handleClickStart('ADMIN')}>관리자 시작하기</Button>
                        </div>
                    );
                }
            } else {
                return (
                    <div>
                        <Button variant="contained" color="default" className={classes.startTeacherButton} onClick={() => this.handleClickStart('USER')}>교수 시작하기</Button>
                        <Button variant="contained" color="secondary" className={classes.startStudentButton} onClick={() => this.handleClickStart('MEMBER')}>학생 시작하기</Button>
                        <Button variant="contained" className={classes.startTeacherButton} href={"http://www.onthelive.co.kr/bbs/board.php?bo_table=kpu_memual&wr_id=14"} target="_blank" rel="noopener noreferrer" >매뉴얼</Button>
                    </div>
                );
            }
        }

        const isSupportBrowser = () => {
            if(deviceBrowserType.deviceType === deviceType.android) {
                if(deviceBrowserType.browserType === browserType.chrome) {
                    return true;
                } else {
                    return false;
                }
            } else if(deviceBrowserType.deviceType === deviceType.ios) {
                if(deviceBrowserType.browserType === browserType.safari) {
                    return true;
                } else {
                    return false;
                }
            } else if(deviceBrowserType.deviceType === deviceType.etc) {
                if(deviceBrowserType.browserType === browserType.chrome) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }

        return (
            <Container component="main" className={classes.mainContainer}>
                <div className={classes.appBarSpacer} />
                <div className={classes.mainContent}>
                    <div className={classes.titleContainer}>
                        <div className={classes.titleContainerSlogan}>
                            <div className={classes.title1Container}>
                                <Typography className={classes.title1}>
                                    교수관리형 원격수업 클라우드
                                </Typography>
                                {/*<Typography className={classes.title1}>*/}
                                {/*    부산형 실시간 원격 수업 클라우드*/}
                                {/*</Typography>*/}
                            </div>
                            <div>
                                <Typography className={classes.title2}>
                                    라이브온에어
                                </Typography>
                            </div>
                            <div className={classes.title3Container}>
                                <Typography className={classes.title3}>
                                    {isSupportBrowser() ?
                                        <span><b>원격 교육의 시작</b><br/>
                                            라이브온에어와 끊김없이 실시간으로 소통할 수 있고<br/>
                                            현장에 있는 것처럼 생생하게 수업하세요.
                                        </span>
                                        :
                                        <span>죄송합니다. 저희 라이브온에어는<br/>
                                            <b>데스크탑, 랩탑, Android는 크롬, iOS는 사파리</b><br/>
                                            브라우저에 최적화 되있습니다.<br/>
                                            <b>서비스 이용이 원활하지 않을 수 있습니다.</b>
                                                <ul className={classes.download}>
                                                    <li><a href="https://www.google.com/intl/ko/chrome"><img src="images/chromeIcon.png" alt="크롬"/><p>Chrome</p></a></li>
                                                    <li><a href="https://support.apple.com/ko_KR/downloads/safari"><img src="images/safariIcon.png" alt="사파리"/><p>Safari<br/>(iOS에서만)</p></a></li>
                                                </ul>
                                        </span>
                                    }
                                </Typography>
                            </div>
                            <div className={classes.startButtonContainer}>
                                {renderStartButton()}
                            </div>
                            {/*<div className={classes.qrContainer}>*/}
                            {/*    <div>*/}
                            {/*        <img src="/api/v1/barcodes/qr" alt="Connection Information" style={{width: 100, height: 100}} />*/}
                            {/*    </div>*/}
                            {/*    <div style={{paddingTop: 3}}>*/}
                            {/*        <Typography variant="body2">안드로이드 및 아이폰 앱에서 QR을 스캔하여 접속 정보를 설정해 보세요.</Typography>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <div className={classes.demo}>
                        <div className={classes.demoTitleContainer}>
                            <Typography className={classes.demoTitle}>
                                라이브온에어 미리보기를 통해 수업을 체험해 보세요.
                            </Typography>
                        </div>

                        <Grid container>
                            <Grid container item xs={12} sm={12} md={6} >
                                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <div className={classes.demoTeacher}>
                                        <div ref={this.teacherVideoRef} className={classes.demoTeacherVideo} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Typography className={classes.demoComment}>교수 수업하기 화면</Typography>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} sm={12} md={6} >
                                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <div className={classes.demoStudent}>
                                        <div ref={this.studentVideoRef} className={classes.demoStudentVideo} />
                                    </div>
                                </Grid>
                                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Typography className={classes.demoComment}>학생 수업 화면</Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <div className={classes.demoButtonContainer}>
                            <Button variant="outlined" 
                            // color="primary" 
                            className={classes.demoButton} onClick={this.handleClickDemo}>{this.state.demoStarted ? '미리보기 중지' : '라이브온에어 미리보기'}</Button>
                        </div>
                    </div>

                    <div className={classes.contentContainer}>
                        <div className={classes.content1Container}>
                            <Typography className={classes.content1}>
                                프로그램 설치가 필요없는 웹기반 원격수업
                            </Typography>
                        </div>
                        <Grid container>
                            <Grid item xs={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Button onClick={this.handleClickPreviousStep} disabled={this.state.currentStep <= 0}><img src="/images/arrow-prev-big.png" alt="Previous" className={classes.content1PrevNextImg} /></Button>
                            </Grid>
                            <Grid item xs={8} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div className={this.state.currentStep === 0 ? classes.visibleContent2 : classes.noVisibleContent2}>
                                    <div className={classes.content2AvatarContainer}>
                                        <Avatar className={classes.content2Avatar}>1</Avatar>
                                    </div>
                                    <div className={classes.content2TitleContainer}>
                                        <Typography className={classes.content2Title}>로그인</Typography>
                                    </div>
                                    <div className={classes.content2ContentContainer}>
                                        <Typography className={classes.content2Content}>강의를 위해 교수 로그인을 해주세요.</Typography>
                                    </div>
                                    <div className={classes.content2ImageContainer}>
                                        <img src="/images/main-contents-step1.png" alt="Login" className={classes.content2Image} />
                                    </div>
                                </div>
                                <div className={this.state.currentStep === 1 ? classes.visibleContent2 : classes.noVisibleContent2}>
                                    <div className={classes.content2AvatarContainer}>
                                        <Avatar className={classes.content2Avatar}>2</Avatar>
                                    </div>
                                    <div className={classes.content2TitleContainer}>
                                        <Typography className={classes.content2Title}>출석부 등록 및 그룹생성</Typography>
                                    </div>
                                    <div className={classes.content2ContentContainer}>
                                        <Typography className={classes.content2Content}>학생 추가 및 그룹 추가를 해주세요.</Typography>
                                    </div>
                                    <div className={classes.content2ImageContainer}>
                                        <img src="/images/main-content-step2.png" alt="Login" className={classes.content2Image} />
                                    </div>
                                </div>
                                <div className={this.state.currentStep === 2 ? classes.visibleContent2 : classes.noVisibleContent2}>
                                    <div className={classes.content2AvatarContainer}>
                                        <Avatar className={classes.content2Avatar}>3</Avatar>
                                    </div>
                                    <div className={classes.content2TitleContainer}>
                                        <Typography className={classes.content2Title}>강의 만들기</Typography>
                                    </div>
                                    <div className={classes.content2ContentContainer}>
                                        <Typography className={classes.content2Content}>강의 만들기를 클릭하여 강의를 만들어 주세요.</Typography>
                                    </div>
                                    <div className={classes.content2ImageContainer}>
                                        <img src="/images/main-content-step3.png" alt="Login" className={classes.content2Image} />
                                    </div>
                                </div>
                                <div className={this.state.currentStep === 3 ? classes.visibleContent2 : classes.noVisibleContent2}>
                                    <div className={classes.content2AvatarContainer}>
                                        <Avatar className={classes.content2Avatar}>4</Avatar>
                                    </div>
                                    <div className={classes.content2TitleContainer}>
                                        <Typography className={classes.content2Title}>강의 시작하기</Typography>
                                    </div>
                                    <div className={classes.content2ContentContainer}>
                                        <Typography className={classes.content2Content}>플레이 버튼을 눌러 강의를 시작합니다.</Typography>
                                    </div>
                                    <div className={classes.content2ImageContainer}>
                                        <img src="/images/main-content-step4.png" alt="Login" className={classes.content2Image} />
                                    </div>
                                </div>
                                <div className={this.state.currentStep === 4 ? classes.visibleContent2 : classes.noVisibleContent2}>
                                    <div className={classes.content2AvatarContainer}>
                                        <Avatar className={classes.content2Avatar}>5</Avatar>
                                    </div>
                                    <div className={classes.content2TitleContainer}>
                                        <Typography className={classes.content2Title}>방송하기</Typography>
                                    </div>
                                    <div className={classes.content2ContentContainer}>
                                        <Typography className={classes.content2Content}>방송을 위해 방송 장비를 선택해 주세요.</Typography>
                                    </div>
                                    <div className={classes.content2ImageContainer}>
                                        <img src="/images/main-content-step5.png" alt="Login" className={classes.content2Image} />
                                    </div>
                                </div>
                            </Grid>
                            <Grid item xs={2} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <Button onClick={this.handleClickNextStep} disabled={this.state.currentStep >= this.state.maxStep}><img src="/images/arrow-next-big.png" alt="Previous" className={classes.content1PrevNextImg} /></Button>
                            </Grid>
                        </Grid>
                    </div>



                    <div className={classes.body2}>
                        <div className={classes.body2TitleContainer}>
                            <Typography className={classes.body2Title}>관리형 원격 강의를 통해</Typography>
                            <Typography className={classes.body2Title}>학생들에게 <b>교육 서비스를 제공</b>해주세요.</Typography>
                        </div>

                        <Grid container spacing={5} className={classes.body2CardContainer}>
                            <Grid item container spacing={3} xs={12} md={6} >
                                <Grid item xs={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Avatar className={classes.body2Avatar}><img src="/images/icon-1.png" alt="icon1"/></Avatar>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', justifyContent: 'center', alignItems: 'left', flexDirection: 'column'}}>
                                    <Typography className={classes.body2CardTitle}>실시간 영상 커뮤니케이션</Typography>
                                    <Typography className={classes.body2CardBody}>영상 커뮤니케이션 도중 언제든 메시지 전송 가능하고 다른 사람과 자유롭게 채팅 가능합니다.</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={3} xs={12} md={6} >
                                <Grid item xs={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Avatar className={classes.body2Avatar}><img src="/images/icon-2.png" alt="icon2"/></Avatar>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', justifyContent: 'center', alignItems: 'left', flexDirection: 'column'}}>
                                    <Typography className={classes.body2CardTitle}>언제나 어디서나 완벽한 강의</Typography>
                                    <Typography className={classes.body2CardBody}>핸드폰과 LIVEONAIR만 있다면 학생들을 가르칠 수 있습니다. 어디든 상관없습니다.</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={3} xs={12} md={6} >
                                <Grid item xs={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Avatar className={classes.body2Avatar}><img src="/images/icon-3.png" alt="icon3"/></Avatar>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', justifyContent: 'center', alignItems: 'left', flexDirection: 'column'}}>
                                    <Typography className={classes.body2CardTitle}>휼륭한 교육 서비스 구축</Typography>
                                    <Typography className={classes.body2CardBody}>강의자료 문서 공유, 최대 2개 화면 지원 서비스를 사용하여 최고의 교육 서비스를 제공하세요.</Typography>
                                </Grid>
                            </Grid>
                            <Grid item container spacing={3} xs={12} md={6} >
                                <Grid item xs={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <Avatar className={classes.body2Avatar}><img src="/images/icon-4.png" alt="icon4"/></Avatar>
                                </Grid>
                                <Grid item xs={8} style={{display: 'flex', justifyContent: 'center', alignItems: 'left', flexDirection: 'column'}}>
                                    <Typography className={classes.body2CardTitle}>한 번에 강의참석, 관리 제공</Typography>
                                    <Typography className={classes.body2CardBody}>클릭 한번으로 여러명에게 강의 초대를 보내세요. PC, 스마트폰을 통해 강의에 참여 하실 수 있습니다.</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <div className={classes.footerContainer}>
                        <div className={classes.footerTitleContainer}>
                            <Typography className={classes.footerTitle} display="inline">라이브온에어는</Typography>
                            <Typography className={classes.footerTitle} display="inline"><b> 교수 관리형 온라인 학습 시스템</b>입니다.</Typography>
                        </div>

                        <Typography className={classes.footerBody1}>라이브온에어 교수 관리형 온라인 학습 시스템에 대해 궁금한게 있으시면 support@aetherit.io 로 연락바랍니다.</Typography>
                    </div>

                    <div className={classes.copyrightContainer}>
                        <Typography className={classes.copyright}>
                            2020 © Opinion Live & AETHER IT CO., Ltd. All rights reserved.
                        </Typography>
                    </div>
               </div>

                <Dialog open={this.state.demoDialogOpen} onClose={this.closeDemoDialog} fullWidth={true} maxWidth={'xs'}>
                    <DialogTitle>
                        미리보기
                    </DialogTitle>
                    <DialogContent>
                        <FormControl component="fieldset">
                            <RadioGroup value={this.state.publishType} onChange={this.handleChangePublishType}>
                                <FormControlLabel value="camera" control={<Radio />} label="웹 캠" />
                                {this.state.publishType === 'camera' ?
                                        <Select value={this.state.selectedVideoDevice}
                                                onChange={this.handleChangeSelectedDevice}
                                                style={{marginBottom: '16px'}}
                                        >
                                            {this.state.videoDevices.map((device) =>
                                                <MenuItem key={`video-device-item-${device.deviceId}`} value={device.deviceId}>{device.label}</MenuItem>
                                            )}
                                        </Select>
                                    :
                                    ''
                                }
                                {this.state.types.deviceType === 'etc' && this.state.types.browserType === 'chrome' ?
                                    <FormControlLabel value={'screen'} control={<Radio />} label="화면 공유" />
                                    :
                                    ''
                                }
                            </RadioGroup>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button style={{color: 'rgba(0, 0, 0, 0.56)'}}
                                onClick={this.closeDemoDialog}
                        >
                            취소
                        </Button>
                        <Button style={{color: '#d64e4e'}}
                                onClick={this.startDemo}
                        >
                            확인
                        </Button>
                    </DialogActions>
                </Dialog>

                <CommonDialog maxWidth="xs"
                              open={this.state.openAndroid11Alert}
                              title={<Typography className={classes.dialogTitle}>[ 화상수업 사용 안내 ]</Typography>}
                              contentComponent={
                                  <Box display="flex" flexDirection="column">
                                      <Typography>&nbsp;&nbsp;현재 사용 중이신 스마트 기기는 Android 11버전으로 확인됩니다.</Typography>
                                      <Typography>&nbsp;&nbsp;Android 11버전으로 화상수업을 사용하시는 사용자 분들 중에 화면멈춤 증상이 나타나는 경우가 모니터링되고 있습니다.</Typography>
                                      <Typography>&nbsp;&nbsp;본 문제는 Android 11버전과 크롬브라우져 호환성의 문제로 파악되고 있습니다.</Typography>
                                      <Typography>&nbsp;&nbsp;화면멈춤 증상이 나타나는 분들께서는 웨일브라우져를 설치하여 사용하시면 해당 문제를 해결하실 수 있습니다.</Typography>
                                      <Typography>&nbsp;&nbsp;해당 문제의 업데이트가 완료되면 다시 관련하여 공지 드리도록 하겠습니다.</Typography>

                                      <ul>
                                          <li><Typography>오류 증상 : Android 11버전에서 크롬브라우져 사용시 화상 수업 화면 멈춤 증상</Typography></li>
                                          <li><Typography>해결 방안 : <a href='https://play.google.com/store/apps/details?id=com.naver.whale&hl=ko&gl=US'>웨일브라우져 설치</a> 후 화상 수업 사용</Typography></li>
                                      </ul>
                                  </Box>
                              }

                              onOk={() => this.setState({openAndroid11Alert: false})}
                />
            </Container>
        );
    }
};

export default withSnackbar(withRouter(withStyles(styles) (Home)));
import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {
    Avatar,
    Breadcrumbs,
    Button,
    Container,
    Dialog,
    DialogTitle,
    Divider,
    Drawer,
    FormControl,
    Grid,
    Link,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import AdjustIcon from "@material-ui/icons/Adjust";
import Brightness1Icon from "@material-ui/icons/Brightness1";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import MomentUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {inject, observer} from "mobx-react";
import moment from "moment";

import Footer from "../../components/Footer";
import ChannelDetailDialog from "./ChannelDetailDialog";
import ChannelUpdateDialog from "./ChannelUpdateDialog";
import ChannelAttendanceDialog from "./ChannelAttendanceDialog";
import ChannelFileDialog from "./ChannelFileDialog";
import CalendarChannelCardComponent from "./CalendarChannelCardComponent";
import * as paramsUtil from "../../common/Params";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import ChannelResultDialog from "./ChannelResultDialog";


const styles = theme => ({
    mainContainer: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        marginTop: theme.spacing(2),
    },

    createButtonContainer: {
        margin: theme.spacing(1),
        textAlign: 'right',
        justifyContent: 'right',
    },
    createButton: {
        fontSize: 14,
        borderRadius: 20,
    },
    createText: {
        color: theme.palette.primary.main,
        '@media screen and (max-width: 600px)': {
            display: 'none'
        },
    },


    dateContainer: {
        marginTop: theme.spacing(1),
        boxShadow: '2px 3px 3px #c1c1c1',
    },
    dateCommandGrid: {
        backgroundColor: '#3437CE',

    },
    dateNoCommandGrid: {
        backgroundColor: '#3437CE',
        textAlign: 'center',
    },
    datePicker: {
        '& input': {
            textAlign: 'center',
            fontSize: 20,
            fontWeight: 700,
            color: '#ffffff',
            backgroundColor: '#3437CE',
        },
        '@media screen and (max-width: 600px)': {
            '& input': {
                fontSize: 14,
            },
        },
    },
    dateTextField: {
        '&::before': {
            borderBottom: 'none !important',
        },
        '& > input': {
            cursor: 'pointer',
        }
    },
    weekButton: {
        width: '100%',
        height: '100%',
    },

    cardContainer: {
        backgroundColor: '#3437CE',
        display: 'flex',
        justifyContent: 'space-evenly',

    },
    card: {
        color: '#ffffff',
        backgroundColor: '#3437CE',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        cursor: 'pointer',
        borderRadius: 16,
    },
    cardWeekContainer: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 16,
    },
    cardAvatarContainer: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: 16,
    },
    cardTextWeek: {
        color: '#ffffff',
        fontSize: 14,
        '@media screen and (max-width: 600px)': {
            fontSize: 12,
        }
    },
    cardTextDay: {
        color: '#ffffff',
        backgroundColor: '#3437CE',
        fontSize: 14,
        width: 30,
        height: 30,
        '@media screen and (max-width: 600px)': {
            fontSize: 12,
        }
    },
    selectedCardTextWeek: {
        color: '#ffffff',
        fontSize: 14,
        fontWeight: 700,
    },
    selectedCardTextDay: {
        color: '#3437CE',
        backgroundColor: '#ffffff',
        fontSize: 14,
        fontWeight: 700,
        width: 30,
        height: 30,
    },



    channelListContainer: {
        position: 'relative',
        marginTop: 6,
        width: '100%',
        '&::after': {
            content: '""',
            position: 'absolute',
            width: 1,
            backgroundColor: '#c3c3c3',
            top: 0,
            bottom: 0,
            left: 250,
            '@media screen and (max-width: 600px)': {
                left: 92,
            }
        },
    },
    channelContainer: {
        position: 'relative',
        width: '100%',
    },
    channelContainerAlternative: {
        position: 'relative',
        width: '100%',
        backgroundColor: '#f5f5f5'
    },
    channelTitle: {
        position: 'absolute',
        width: 250,
        top: 0,
        left: 0,
        marginTop: 20,
        //border: '1px solid black',
        //borderRadius: 22,
        '@media screen and (max-width: 600px)': {
            width: 92,
        }
    },
    channelTitleWeek: {
        textAlign: 'center',
        fontSize: 16,
        '@media screen and (max-width: 600px)': {
            fontSize: 12,
        }
    },
    channelTitleTime: {
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 700,
        '@media screen and (max-width: 600px)': {
            fontSize: 14,
        }
    },
    timelinePastIcon: {
        position: 'absolute',
        top: 22,
        left: 237,
        color: '#a4a4a4',
        fontSize: 28,
        zIndex: 1,
        '@media screen and (max-width: 600px)': {
            fontSize: 24,
            left: 82,
        }
    },
    timelineCurrentIcon: {
        position: 'absolute',
        top: 22,
        left: 237,
        color: '#3437CE',
        fontSize: 28,
        zIndex: 1,
        '@media screen and (max-width: 600px)': {
            fontSize: 24,
            left: 82,
        }
    },
    timelineFutureIcon: {
        position: 'absolute',
        top: 22,
        left: 237,
        color: '#a4a4a4',
        fontSize: 28,
        zIndex: 1,
        '@media screen and (max-width: 600px)': {
            fontSize: 24,
            left: 82,
        }
    },

    noHistoryContainer: {
        display: 'flex',
        width: '100%',
        textAlign: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        paddingTop: theme.spacing(3),
    },
    noHistoryAvatar: {
        width: 160,
        height: 160,
        textAlign: 'center',
        border: 'solid 1px #d4d4d4',
        backgroundColor: '#FFFFFF',
    },
    noHistoryHeader: {
        fontSize: 16,
        color: '#333333',
        paddingTop: theme.spacing(5),
    },
    noHistoryBody: {
        fontSize: 14,
        color: '#b7b7b7',
        paddingTop: theme.spacing(1),
    },
    noHistoryEmptyBody: {
        fontSize: 14,
        color: '#b7b7b7',
        paddingTop: theme.spacing(1),
        marginBottom: theme.spacing(5),
    },
    noHistoryButton: {
        borderRadius: 24,
        fontSize: 16,
        width: 200,
        height: 48,
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(5),
    },

    lastDivider: {
        color: '#3437CE',
        backgroundColor: '#3437CE',
        height: 2,
        //boxShadow: '1px 1px 1px #c1c1c1',
    },

    helpContainer: {
        width: 350,
        padding: theme.spacing(4),
        boxSizing:'border-box'
    },
    helpLogoContainer: {
        display: 'flex',
        flexDirection:'column',
        textAlign:'center'

    },
    helpLogoImage: {
        padding: theme.spacing(2,0,1)
    },
    helpLogoText: {
        fontSize: 22,
        fontWeight: 800,
        margin:theme.spacing(2,0,1),
    },
    helpPageTitle:{
        textAlign:'center',
        fontSize:'1.1em',
    },
    helpTitleContainer: {
        paddingTop: theme.spacing(2),
        paddingLeft:'20px',
        lineHeight:'1.45',
        '& > li':{
            fontSize:'1em',
            marginBottom:'1.5em',
        },
        '& b':{
            borderBottom:'1px solid #333'
        }
    },
    helpBodyContainer: {
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        '& > li':{
            fontSize:'0.9em'
        }
    },
    helpIcon:{
        position: 'fixed',
        bottom: '35px',
        right: '35px',
        boxShadow: 'none',
        background:'none',
        width:'85px',
        fontSize: 15,
        '&:hover, &:focus, &:active':{
            background:'none',
            boxShadow:'none',
        },
        '& svg':{
            width:36,
            height:36,
            marginRight:4,
            boxShadow:'0 2px 3px #c1c1c1',
            borderRadius:'50px'
        },
        '& span':{
            color:'rgba(51,51,51,0.56)'
        }
    },
    helpCloseIcon: {
        position: 'absolute',
        right: '1rem',
        top: '1rem',
        color: 'rgba(51,51,51,0.37)',
        width:'40px',
        height:'40px',
        cursor: 'pointer',
    },
});

const SelectedDateParamFormat = "YYYY-MM-DDTHH:mm:ss";

@inject('calendarChannelStore', 'authStore', 'helpStore', 'channelDetailStore', 'channelUpdateStore', 'channelFileStore', 'channelAttendanceStore', 'roomStore')
@observer
class CalendarChannelList extends React.Component {
    componentDidMount() {
        const param = paramsUtil.getURLParams(this.props.location.search);
        if(param.date) {
            this.props.calendarChannelStore.initialize(this.props.userId, this.props.loginType, param.date);
        } else {
            this.props.calendarChannelStore.initialize(this.props.userId, this.props.loginType);
        }

        // console.log("start channel refresh timer");
        // if(this.props.loginType === 'MEMBER') {
            this.channelListRefreshTimer = setInterval(() => {
                // console.log('refresh channel list');
                this.props.calendarChannelStore.loadingChannelList();
            }, 1000 * 10);
        // }
    }

    componentWillUnmount() {
        if(this.channelListRefreshTimer) {
            // console.log("release channel refresh timer");
            clearInterval(this.channelListRefreshTimer);
        }
    }

    handleChangeSelectedDay = (day) => {
        this.props.calendarChannelStore.setSelectedDay(day);
    }

    handleChangePreviousWeek = () => {
        this.props.calendarChannelStore.toPreviousWeek();
    }

    handleChangeNextWeek = () => {
        this.props.calendarChannelStore.toNextWeek();
    }


    handleShowChannelInfo = (channelId) => {
        this.props.channelDetailStore.openDetailDialog(channelId);
    }

    handleEditChannelInfo = (channelId) => {
        this.props.channelUpdateStore.openUpdateDialog(channelId, this.props.userId);
    }

    handleShowChannelFile = (channelId, channelUserId) => {
        this.props.channelFileStore.openFileDialog(channelId, channelUserId);
    }

    handleChannelAttendance = (channel) => {
        this.props.channelAttendanceStore.openAttendanceDialog(channel);
    }

    handleChangeDeleteChannelId = (channelId) => {
        this.props.calendarChannelStore.changeDeleteChannelId(channelId);
    }

    handleChangeChannelResult = channel => {
        this.props.calendarChannelStore.changeChannelResult(channel);
    }

    handleDeleteChannel = () => {
        this.props.calendarChannelStore.deleteChannel();
    }

    handleEnterChannel = (channelId, version) => {
        if(version < 2) {
            this.props.history.push(`/broadcast/${channelId}`);
        } else {
            this.props.history.push(`/room/${channelId}`);
        }
    }

    handleMonitoring = (channelId) => {
        window.open(`/monitoring/${channelId}`);
    }

    render() {
        const {classes} = this.props;
        const {isLoginTypeUser, isLoginTypeAdmin} = this.props.authStore;
        const {selectedDay, weekDays, channelList, isOpenDeleteDialog} = this.props.calendarChannelStore;

        const dayCard = (day) => (
            <div key={`day-card-${day.format('YYYYMMDD')}`}
                  className={classes.card} onClick={() => this.handleChangeSelectedDay(day)}>
                <Grid container>
                    <Grid item xs={12} className={classes.cardWeekContainer}>
                        <Typography className={moment(day).isSame(moment(selectedDay), 'day') ? classes.selectedCardTextWeek : classes.cardTextWeek}>{day.format('dd')}</Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.cardAvatarContainer}>
                        <Avatar className={moment(day).isSame(moment(selectedDay), 'day') ? classes.selectedCardTextDay : classes.cardTextDay}>{day.format('DD')}</Avatar>
                    </Grid>
                </Grid>
            </div>
        );

        const isToday = moment().isSame(moment(selectedDay), 'days');
        // console.log('selectedDay', selectedDay);

        return (
            <React.Fragment>
                <Container component="main" maxWidth="lg" className={classes.mainContainer}>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.mainContent} >
                        <Typography variant="h4" component="h2">{isLoginTypeUser ? '내가 만든 강의 목록' : '내가 듣는 강의 목록'}</Typography>
                        <FormControl margin="dense">
                            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                                <Link color="inherit" underline="none">강의</Link>
                                <Link color="inherit" underline="none">{isLoginTypeUser ? '내가 만든 강의 목록' : '내가 듣는 강의 목록'}</Link>
                            </Breadcrumbs>
                        </FormControl>

                        <div className={classes.createButtonContainer}>
                            {this.props.loginType === 'USER' ?
                                <Button color="primary" variant="outlined" className={classes.createButton} onClick={() => this.props.history.push(`/channel/create/${selectedDay.format(SelectedDateParamFormat)}`)}><AddRoundedIcon/><Typography className={classes.createText}>강의만들기</Typography></Button>
                                :
                                <div style={{height: 14}}></div>
                            }
                        </div>

                        <Grid container className={classes.dateContainer}>
                            <Grid container item xs={12}>
                                <Grid item xs={3} className={classes.dateNoCommandGrid}>
                                    <Button onClick={this.handleChangePreviousWeek} className={classes.weekButton}>
                                        <NavigateBeforeIcon style={{color: '#ffffff'}} />
                                    </Button>
                                </Grid>
                                <Grid item xs={6} className={classes.dateCommandGrid}>
                                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="ko">
                                        <DatePicker format="LL"
                                                    variant="inline"
                                                    className={classes.datePicker}
                                                    value={selectedDay}
                                                    onChange={this.handleChangeSelectedDay}
                                                    TextFieldComponent={(props) => <TextField {...props} disabled InputProps={{className: classes.dateTextField}} />}
                                                    autoOk fullWidth disableToolbar
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={3} className={classes.dateNoCommandGrid}>
                                    <Button onClick={this.handleChangeNextWeek} className={classes.weekButton}>
                                        <NavigateNextIcon style={{color: '#ffffff'}} />
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} className={classes.cardContainer}>
                                {weekDays.map((day) =>
                                    dayCard(day)
                                )}
                            </Grid>
                        </Grid>


                        {channelList.length > 0 ?
                            <div className={classes.channelListContainer}>
                                {channelList.map((channel, index) =>
                                    <div key={`channel-${channel.channelId}`} className={index % 2 === 0 ? classes.channelContainer : classes.channelContainerAlternative}>
                                        <Grid container spacing={1} className={classes.channelTitle}>
                                            <Grid item xs={12}>
                                                <Typography className={classes.channelTitleWeek}>{moment(channel.liveStartDatetime).format('A')}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className={classes.channelTitleTime}>{moment(channel.liveStartDatetime).format('hh:mm')}</Typography>
                                            </Grid>
                                        </Grid>
                                        {channel.timeType === 'past' ?
                                            <Brightness1Icon className={classes.timelinePastIcon} style={index % 2 === 0 ? {backgroundColor: '#ffffff'} : {backgroundColor: '@f5f5f5'}} />
                                            :
                                        channel.timeType === 'current' ?
                                            <AdjustIcon className={classes.timelineCurrentIcon} style={index % 2 === 0 ? {backgroundColor: '#ffffff'} : {backgroundColor: '@f5f5f5'}} />
                                            :
                                            <RadioButtonUncheckedIcon className={classes.timelineFutureIcon} style={index % 2 === 0 ? {backgroundColor: '#ffffff'} : {backgroundColor: '@f5f5f5'}} />
                                        }
                                        <CalendarChannelCardComponent isAlternative={index % 2 === 1}
                                                                      userId={this.props.userId}
                                                                      channel={channel}
                                                                      isLoginTypeAdmin={isLoginTypeAdmin}
                                                                      onShowInfo={this.handleShowChannelInfo}
                                                                      onEditInfo={this.handleEditChannelInfo}
                                                                      onAttendance={this.handleChannelAttendance}
                                                                      onShowFile={this.handleShowChannelFile}
                                                                      onDelete={this.handleChangeDeleteChannelId}
                                                                      onShowResult={this.handleChangeChannelResult}
                                                                      onEnter={this.handleEnterChannel}
                                                                      onMonitoring={this.handleMonitoring}
                                        />
                                    </div>
                                )}
                            </div>
                            :
                            <div className={classes.noHistoryContainer}>
                                <Avatar className={classes.noHistoryAvatar}><img src="/images/nohistory.png" alt="no history"/></Avatar>
                                <Typography className={classes.noHistoryHeader}>아직 강의가 없습니다.</Typography>
                                {this.props.loginType === 'USER' ?
                                    <React.Fragment>
                                        <Typography className={classes.noHistoryBody}>강의가 처음이세요? liveonair와 쉽고 빠르게 강의를 만들어 교육서비스를 제공해 주세요.</Typography>
                                        <Button color="primary" variant="contained" className={classes.noHistoryButton}
                                                onClick={() => this.props.history.push(`/channel/create/${isToday ? selectedDay.add(selectedDay.minutes(15).isSameOrBefore(selectedDay) ? 2 : 3, 'hours') : selectedDay.hours(9).minutes(0).seconds(0)}`)}>
                                            강의 만들기
                                        </Button>
                                    </React.Fragment>
                                    :
                                    <Typography className={classes.noHistoryEmptyBody}></Typography>
                                }
                            </div>
                        }
                    </div>

                    <Divider className={classes.lastDivider} />
                </Container>

                <ChannelDetailDialog loginType={this.props.loginType} userId={this.props.userId} />
                <ChannelUpdateDialog loginType={this.props.loginType} userId={this.props.userId} />
                <ChannelAttendanceDialog loginType={this.props.loginType} userId={this.props.userId} />
                <ChannelFileDialog loginType={this.props.loginType} userId={this.props.userId} />
                <ChannelResultDialog loginType={this.props.loginType} userId={this.props.userId} />

                <Dialog fullWidth
                        maxWidth="xs"
                        open={isOpenDeleteDialog}
                        onClose={() => this.props.calendarChannelStore.closeDeleteChannelDialog()}
                        aria-labelledby="delete-dialog-title" >
                    <DialogTitle id="delete-dialog-title" className={classes.dialogTitle}>
                        강의 삭제
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="create-group-dialog-description">
                            강의를 삭제하면 관련된 정보가 모두 삭제되며, 복구 할 수 없습니다.<br/>
                            정말로 삭제하시겠습니까?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.props.calendarChannelStore.closeDeleteChannelDialog()} >
                            취소
                        </Button>
                        <Button onClick={this.handleDeleteChannel} color="primary" autoFocus>
                            확인
                        </Button>
                    </DialogActions>
                </Dialog>

                <Footer />

                <div>
                    {/*<Fab size="small" className={classes.helpIcon} onClick={() => this.props.helpStore.openHelp()} >*/}
                    {/*    <HelpIcon color="primary" viewBox="2 2 20 20" /> <span>도움말</span>*/}
                    {/*</Fab>*/}

                    <Drawer anchor="right" open={this.props.helpStore.open} onClose={() => this.props.helpStore.closeHelp()} >
                        <CloseRoundedIcon className={classes.helpCloseIcon} onClick={() => this.props.helpStore.closeHelp()}/>

                        <div className={classes.helpContainer}>
                            <div className={classes.helpLogoContainer}>
                                <div className={classes.helpLogoImage}><img src="/images/logo.png" alt="LIVEONAIR" /></div>
                                <Typography className={classes.helpLogoText}>도움말</Typography>
                            </div>

                            <div>
                                <Typography className={classes.helpPageTitle} variant="h5" >강의 목록</Typography>

                                {isLoginTypeUser ?
                                    <React.Fragment>
                                        <ol className={classes.helpTitleContainer}>
                                            <li>'교수용 (내가 만든 강의)'에서는 내가 만든 강의가 생성되어 확인할 수 있습니다.
                                                <ul className={classes.helpBodyContainer}>
                                                    <li>새로 강의를 만드려면 '강의 만들기' 버튼을 클릭하면 생성 할 수 있습니다.</li>
                                                </ul>
                                            </li>
                                            <li>날짜를 클릭하면 해당 날짜의 강의 목록을 볼 수 있습니다.
                                                <ul className={classes.helpBodyContainer}>
                                                    <li>오늘의 날짜는 자동으로 펼쳐 집니다.</li>
                                                </ul>
                                            </li>
                                            <li>해당 과목 '강의하기' 버튼 위의 <MoreVertIcon/>을 클릭하면 강의정보보기, 강의정보변경, 강의자료올리기, 출석부 보기의 기능이 있습니다.</li>
                                            <li>'강의정보보기' 메뉴를 사용하면 강의을 개설할때 입력한 정보를 확인 할 수 있습니다.
                                                <ul className={classes.helpBodyContainer}>
                                                    <li>'방송주소'의 용도는 <b>rtmp프로토콜 사용하는 장비 및 소프트웨어와 라이브온에어를 연동</b>하기 위한 내용입니다. 주화면 주소로 방송을 송출하면 라이브온에어 주화면으로 출력됩니다.</li>
                                                </ul>
                                            </li>
                                            <li>'강의정보변경'을 통해 강의을 시작하기 전까지 입력한 강의 정보들을 변경할 수 있습니다.
                                                <ul className={classes.helpBodyContainer}>
                                                    <li><b>이미 시작된 강의의 정보는 변경할 수 없습니다.</b></li>
                                                </ul>
                                            </li>
                                            <li>'강의자료올리기'를 통해 강의에 필요한 참고자료들을 업로드 할 수 있습니다
                                                <ul className={classes.helpBodyContainer}>
                                                    <li>'파일선택'을 클릭하여 업로드 할 파일을 선택하고, 자료 설명란에 해당 자료에 대한 간단한 설명을 입력합니다. 입력 후 '자료올리기' 버튼을 클릭합니다. 목록에 업로드한 자료가 보이는지 확인 합니다.</li>
                                                    <li>파일 업로드를 완료한 후 '확인' 버튼을 클릭하여 화면을 닫습니다.</li>
                                                </ul>
                                            </li>
                                            <li>'출석부'로 강의에 등록된 학생들의 수강 여부를 확인할 수 있습니다.
                                                <ul className={classes.helpBodyContainer}>
                                                    <li>'참여시간'에는 학생이 강의에 들어오고 나간 시각이 표시되며, '출석체크수'는 강의 중 출석체크에 성공한 횟수가 표시됩니다.</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </React.Fragment>
                                    :
                                    <React.Fragment>
                                        <ol className={classes.helpTitleContainer}>
                                            <li>'학생용 (내가 만든 강의)'에서는 내가 들을 수 있는 강의을 확인할 수 있습니다.</li>
                                            <li>날짜를 클릭하면 해당 날짜의 강의 목록을 볼 수 있습니다.
                                                <ul className={classes.helpBodyContainer}>
                                                    <li>오늘의 날짜는 자동으로 펼쳐 집니다.</li>
                                                </ul>
                                            </li>
                                            <li>해당 과목 '강의듣기' 버튼 위의 <MoreVertIcon/>을 클릭하면 강의자료보기 기능이 있습니다.
                                                <ul className={classes.helpBodyContainer}>
                                                    <li>'강의자료보기'를 통해 강의에 필요한 참고자료들을 다운로드 할 수 있습니다.</li>
                                                    <li>목록에서 다운로드 하고자 하는 파일의 이름을 선택하면 다운로드 됩니다.</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </Drawer>
                </div>
            </React.Fragment>
        );
    }
}

export default withSnackbar(withRouter(withStyles(styles) (CalendarChannelList)));
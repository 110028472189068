import React from "react";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {Button, CircularProgress, Container, Grid, Input, TextField, Typography} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import Avatar from '@material-ui/core/Avatar';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import Footer from "../../components/Footer";

const styles = (theme) => {
    return ({
        appBarSpacer: theme.mixins.toolbar,
        paper: {
            marginTop: theme.spacing(8),
            marginBottom:'40px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px solid #ddd',
            padding: '50px 80px 40px 80px',
        },
        signUpText: {
            marginTop: theme.spacing(2),
        },
        form: {
            width: '100%',
            marginTop: theme.spacing(1),
        },
        checkAgreeMargin: {
            marginTop: theme.spacing(3),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        titleArea:{
            position:'relative'
        },
        subTitleDesc:{
            position:'absolute',
            top:0,
            right:0,
            marginRight:0,
        },
        gutterMargin:{
            margin:theme.spacing(3, 0, 2),
            '& input[type=password]':{
                fontFamily:'initial',
            }
        },
        large: {
            cursor : "pointer",
            width: theme.spacing(15),
            height: theme.spacing(15),
            // boxShadow: '0px 3px 10px 0 rgba(165, 156, 156, 0.34)',
            border:'1px solid #eee',
            marginBottom:'20px'
        },
        avatarImg:{
            position:'relative',
            cursor:'pointer',
            '& svg':{
                position:'absolute',
                bottom:'23px',
                right:'0px',
                marginRight:0,
                fontSize:'1rem',
                color:'#676666',
                border:'1px solid #bfbfbf',
                backgroundColor:'#fff',
                borderRadius:'50px',
                padding:'8px',
                height:'24px',
                width:'24px',
            }
        }
    })
};

@inject("userStore")
@observer
class MyPage extends React.Component{
    constructor(props){
        super(props);
        this.fileUpload = React.createRef();
    }

    componentDidMount() {
        // console.log(" PROPS : ", this.props);
        // console.log('styles :', styles);
        this.props.userStore.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { userDataModifyResult } = this.props.userStore;
        const { changeUserDataModifyResult } = this.props.userStore;
        if( userDataModifyResult === true) {
            this.props.enqueueSnackbar('정보를 수정하였습니다.', {
                variant: 'info'
            });
            changeUserDataModifyResult(null);
        } else if(userDataModifyResult === false) {
            this.props.enqueueSnackbar('정보 수정에 실패하였습니다. 별칭 혹은 비밀번호를 확인해 주세요.', {
                variant: 'error'
            });
            changeUserDataModifyResult(null);
        }
    }

    handleChangeUserData = (e) => {
        const name = e.target.name;
        let value = e.target.value;

        if(e.target.type === "checkbox") {
            value = e.target.checked;
        }

        this.props.userStore.changeUserData(name, value);
    };

    // handleChangeExpansion = (date, isExpanded) => {
    //     console.log('changed : ' + date + ', isExpanded : ' + isExpanded);
    // };

    // handleChangeListTypeTab = (event, listType) => {
    // }

    // handleEnterChannel = (channelId) => {
    //     this.props.history.push(`/broadcast/${channelId}`);
    // }


    handleSubmitForm = (e) => {
        e.preventDefault();
        this.props.userStore.doUserDataModify();
    };

    handleClickProfileImage = (e) => {
        // this.fileUpload.current.onclick();
        // console.log('this.fileUpload :', this.fileUpload.current.onclick);
        // console.log('this.fileUpload :', this.fileUpload.current.click);
        // console.log('this.fileUpload :', this.fileUpload.current.onClick);
        // console.log('this.fileUpload.current.click() :', this.fileUpload.current.click());
        // console.log('this.fileUpload.current :', this.fileUpload.current.querySelector('input'));
        this.fileUpload.current.querySelector('input').click();
        
    }

    handleChangeProfileImage = async (e) =>{
        console.log('click :', e);
        console.log('click :', e.target.files[0]);
        await this.props.userStore.changeUserProfileImage(e.target.files[0]);
        await this.props.userStore.getUserData();
    }

    render() {
        const { classes } = this.props;
        const { userData, userDataModifyResult } = this.props.userStore;
        // console.log('userData :', userData);
        // console.log('loginType : ' + this.props.loginType);
        const profileUrl = userData.profileUrl || "/images/login-logo.png";
        return (
            <React.Fragment>
               <Container component="main" maxWidth="sm">
                    <form>
                    <div className={classes.appBarSpacer} />
                    <div className={classes.paper}>
                            <div className={classes.avatarImg} onClick={this.handleClickProfileImage}>
                                <Avatar alt="whoisthis" src={profileUrl} className={classes.large}>
                                </Avatar>
                                <PhotoCameraIcon/>
                            </div>

                            <Input type="file" ref={this.fileUpload} style={{ display: "none" }} onChange={this.handleChangeProfileImage}></Input>

                        <Typography className={classes.signUpText} component="h1" variant="h3" >
                            회원 정보 
                        </Typography>
                        <div className={classes.form}>
                                <div className={classes.titleArea}>
                                    <Typography variant="h5" component="h5">
                                        회원 정보 수정
                                    </Typography>
                                    <Typography variant="body2" align={"right"} className={classes.subTitleDesc}>* 필수입력</Typography>
                            </div>
                            {/* <Grid item xs={12}>
                                <List>
                                    <ListItem>
                                        <ListItemText primary="회원 정보 입력" />
                                        <ListItemText align={"right"} secondary="* 필수입력" />
                                    </ListItem>
                                </List>
                            </Grid>
                            <div style={{
                                height: '2px',
                                backgroundColor: '#333333'
                            }} /> */}
                            <TextField id="email"
                                       name="email"
                                       label="이메일 주소"
                                       margin="normal"
                                       disabled
                                       value={userData.email}
                                       variant="filled"
                                       required fullWidth />

                            <TextField id="oldPassword"
                                       type="password"
                                       name="oldPassword"
                                       label="기존 비밀번호"
                                       margin="normal"
                                       autoComplete={"off"}
                                       value={userData.oldPassword}
                                       onChange={this.handleChangeUserData}
                                       className={classes.gutterMargin}
                                       required
                                       fullWidth
                            />

                            <TextField id="password"
                                       type="password"
                                       name="password"
                                       label="새로운 비밀번호"
                                       margin="normal"
                                       autoComplete={"off"}
                                       value={userData.password}
                                       onChange={this.handleChangeUserData}
                                       className={classes.gutterMargin}
                                // onKeyUp={this.handleKeyUpPassword}
                                       required fullWidth/>
                            <TextField id="passwordConfirm"
                                       type="password"
                                       name="passwordConfirm"
                                       label="새로운 비밀번호 확인"
                                       margin="normal"
                                       autoComplete={"off"}
                                       value={userData.passwordConfirm}
                                       onChange={this.handleChangeUserData}
                                       className={classes.gutterMargin}
                                // onKeyUp={this.handleKeyUpPassword}
                                       required fullWidth/>

                            <TextField id="name"
                                       name="name"
                                       label="이름"
                                       margin="normal"
                                       disabled={this.props.loginType === 'MEMBER'}
                                       value={userData.name}
                                       onChange={this.handleChangeUserData}
                                       className={classes.gutterMargin}
                                       required fullWidth />

                            {this.props.loginType === 'MEMBER' ? "" :
                                <TextField id="nickName"
                                           name="nickName"
                                           label="별칭"
                                           margin="normal"
                                           value={userData.nickName}
                                           onChange={this.handleChangeUserData}
                                           className={classes.gutterMargin}
                                           required fullWidth/>
                            }

                            <Grid item xs={12} align={"center"}>
                                {userDataModifyResult === null ?
                                    <Button type="submit"
                                            style={{
                                                padding: '12px 0',
                                                borderRadius: '50px',
                                                backgroundColor: '#3c3c3c',
                                                color: '#ffffff',
                                                boxShadow: 'none',
                                            }}
                                            className={classes.submit}
                                            color="primary"
                                            variant="contained"
                                            onClick={this.handleSubmitForm}
                                            fullWidth>
                                        {/*{loginState === store.State.Pending ? <CircularProgress size={22}/> : '회원가입'}*/}
                                        수정하기
                                    </Button>
                                    : <CircularProgress size={22}/>}
                            </Grid>
                        </div>
                    </div>
                    </form>
                </Container>
                <Footer />
            </React.Fragment>
        )
    }
}

export default withSnackbar(withRouter(withStyles(styles)(MyPage)));

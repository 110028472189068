/*global _CONST_TYPES*/
import {action, computed, flow, observable} from "mobx";
import axios from "axios";
// import * as Player from "../views/broadcast/BroadcastNodePlayer";
import * as Chat from "../views/broadcast/BroadcastChat";
import * as BroadcastJanus from "../views/broadcast/BroadcastJanus";
import {janusJoinPType, janusPluginHandlerType} from "../views/broadcast/BroadcastJanus";
import * as BroadcastLookAroundJanus from "../views/broadcast/BroadcastLookAroundJanus";
import {getDeviceBrowserType} from "../common/Params";

// const axiosUrlPreFix = "/api/v1";

// export const nodePlayerSrc = {
//     TRIAL: "/lib/subNodePlayer/NodePlayer-full.min.js",
//     LICENSE: "/lib/nodePlayer/NodePlayer-full.min.js"
// };

export const chatTabSelectType = {
    CHAT: "chat",
    CHAT_USER_LIST: "chatUserList",
};

export const channelStatusType = {
    UNKNOWN: "UNKNOWN",
    WAIT: "WAIT",
    OPENED: "OPENED",
    ONAIR: "ONAIR",
    OFFAIR: "OFFAIR",
    FAILED: "FAILED",
    CLOSED: "CLOSED"
};

export const chatUserType = {
    USER: "USER",
    OWNER: "OWNER",
    MEMBER: "MEMBER",
};

export const broadcastType = {
    MAIN: "MAIN",
    SUB: "SUB"
};

export const broadcastVideoEl = {
    MAIN: "video",
    SUB: "secondVideo",
};

export const controlScreenType = {
    TURNON: "TURNON",
    TURNOFF: "TURNOFF",
};

export const joinMsgScreenType = {
    ON: "ON",
    OFF: "OFF",
};

export const joinMsgSoundType = {
    ON: "ON",
    OFF: "OFF",
};

export const subViewChangeType = {
    SHOW: "SHOW",
    HIDDEN: "HIDDEN",
};

export const videoOffLayer = {
    MAIN: "videoOff",
    SUB: "videoOffSub"
};

export const publishMethodType = {
    WEB_RTC: "WEBRTC",
    RTMP: "RTMP"
};

export const publishType = {
    SCREEN_SHARING: "SCREEN_SHARING",
    WEB_CAM: "WEB_CAM",
    RTMP: "RTMP"
};

export const janusRoomType = {
    NORMAL: "NORMAL",
    LOOK_AROUND: "LOOK_AROUND",
};

export default class BroadcastStore {
    @observable userId = null;
    @observable userInfo = null;
    @observable channel = null;
    // @observable nodePlayer = null;
    // @observable secondNodePlayer = null;
    @observable loading = false;
    @observable playStatus = false;
    @observable playBtnView = true;
    @observable playBtnMouseOver = false;
    @observable subPlayStatus = false;
    @observable chatObj = null;
    @observable chatMsgList = [];
    @observable chatMsg = "";
    @observable chatTabSelected = chatTabSelectType.CHAT;
    @observable chatUserList = null;
    @observable channelWsMainURL = null;
    @observable channelWsSubURL = null;
    @observable chatReady = false;
    @observable attendMsg = null;
    @observable attendDialogStatus = false;
    @observable attendDialogMsg = "";
    @observable attendDialogMsgError = false;
    @observable attendDialogMsgHelperText = "출석코드가 일치하지 않습니다";
    @observable mainWidth = 0;
    @observable mainVideoEl = broadcastVideoEl.MAIN;
    @observable mainVideoOffEl = videoOffLayer.MAIN;
    @observable mainVideoElSound = true;
    @observable subVideoEl = broadcastVideoEl.SUB;
    @observable subVideoOffEl = videoOffLayer.SUB;
    @observable subVideoElSound = false;
    @observable videoElChangeStatus = false;
    @observable channelEnded = false;
    @observable countDown = null;
    @observable countDownTimer = null;
    @observable channelExitDialogOpen = false;
    @observable channelEndDialogOpen = false;
    @observable chatIssueCount = 0;
    @observable chatPresentationObj = null;
    @observable chatQuestionObjList = [];
    @observable inPresentation = false;
    @observable inQuestion = false;
    @observable mainScreenView = false;
    @observable subScreenView = false;
    @observable subVideoElViewChange = false;
    @observable fullScreenStatus = false;
    @observable isFirstPlayCheck = true;
    @observable myScreenView = true;
    @observable mySubScreenView = true;
    @observable chatServerInfo = null;
    @observable publishMethod = publishMethodType.WEB_RTC;
    @observable subPublishDialogOpen = false;
    @observable subMemberPublishDialogOpen = false;
    @observable isQuestionReady = false;
    @observable selectedSubPublishType = null;
    @observable mainPublishDialogOpen = false;
    @observable selectedMainPublishType = null;
    @observable janusObj = null;
    @observable lookAroundJanusObj = null;
    @observable pluginHandle = null;
    @observable subPluginHandle = null;
    @observable lookAroundPluginHandle = null;
    @observable isDisablePresentation = true;
    @observable webRtcMainPublishType = null;
    @observable webRtcSubPublishType = null;
    @observable janusSubFeed = null;
    @observable mainPublishType = null;
    @observable subPublishType = null;
    @observable webRtcCameraList = null;
    @observable selectedWebRtcCamera = null;
    @observable selectedSubWebRtcCamera = null;
    @observable availableBroadcastSwitch = true;
    @observable resetDialogOpen = false;
    @observable isTeacherLookAroundOpen = false;
    @observable isStudentLookAroundOpen = false;
    @observable screenOffDialogOpen = false;
    @observable screenOffDialogTarget = null;
    @observable lookAroundRemotePluginHandleList = [];
    @observable isAttendRequestDialogOpen = false;
    @observable networkErrorDialogOpen = false;
    @observable alreadyUserDialogOpen = false;
    @observable duplicateUserDialogOpen = false;
    @observable lookAroundUnderstandList = [];
    @observable lookAroundPublishDialogOpen = false;
    @observable selectedLookAroundCamera = null;
    @observable isVideoTrackOff = false;
    @observable lookAroundErrorDialogOpen = false;
    @observable tempVideoId = "tempVideo";
    @observable presentationErrorDialogOpen = false;
    @observable IsScreenSharingVideoOff = false;
    @observable isLookAroundSoundOn = false;
    @observable isOpenBroadcastWebCamSettingDialog = false;

    @action initData = () => {
        this.userId = null;
        this.userInfo = null;
        this.channel = null;
        // this.nodePlayer = null;
        // this.secondNodePlayer = null;
        this.loading = false;
        this.playStatus = false;
        this.playBtnView = true;
        this.playBtnMouseOver = false;
        this.subPlayStatus = false;
        this.chatObj = null;
        this.chatMsgList = [];
        this.chatMsg = "";
        this.chatTabSelected = chatTabSelectType.CHAT;
        this.chatUserList = null;
        this.channelWsMainURL = null;
        this.channelWsSubURL = null;
        this.chatReady = false;
        this.attendMsg = null;
        this.mainWidth = 0;
        this.mainVideoEl = broadcastVideoEl.MAIN;
        this.mainVideoOffEl = videoOffLayer.MAIN;
        this.mainVideoElSound = true;
        this.subVideoEl = broadcastVideoEl.SUB;
        this.subVideoOffEl = videoOffLayer.SUB;
        this.subVideoElSound = false;
        this.videoElChangeStatus = false;
        this.channelEnded = false;
        this.countDown = null;
        this.countDownTimer = null;
        this.channelExitDialogOpen = false;
        this.channelEndDialogOpen = false;
        this.chatIssueCount = 0;
        this.chatPresentationObj = null;
        this.chatQuestionObjList = [];
        this.inPresentation = false;
        this.inQuestion = false;
        this.mainScreenView = false;
        this.subScreenView = false;
        this.subVideoElViewChange = false;
        this.fullScreenStatus = false;
        this.isFirstPlayCheck = true;
        this.myScreenView = true;
        this.mySubScreenView = true;
        this.chatServerInfo = null;
        this.publishMethod = publishMethodType.WEB_RTC;
        this.subPublishDialogOpen = false;
        this.subMemberPublishDialogOpen = false;
        this.isQuestionReady = false;
        this.selectedSubPublishType = null;
        this.mainPublishDialogOpen = false;
        this.selectedMainPublishType = null;
        this.janusObj = null;
        this.lookAroundJanusObj = null;
        this.pluginHandle = null;
        this.subPluginHandle = null;
        this.lookAroundPluginHandle = null;
        this.isDisablePresentation = true;
        this.webRtcMainPublishType = null;
        this.webRtcSubPublishType = null;
        this.janusSubFeed = null;
        this.mainPublishType = null;
        this.subPublishType = null;
        this.webRtcCameraList = null;
        this.selectedWebRtcCamera = null;
        this.selectedSubWebRtcCamera = null;
        this.availableBroadcastSwitch = true;
        this.resetDialogOpen = false;
        this.isTeacherLookAroundOpen = false;
        this.isStudentLookAroundOpen = false;
        this.screenOffDialogOpen = false;
        this.screenOffDialogTarget = null;
        this.lookAroundRemotePluginHandleList = [];
        this.isAttendRequestDialogOpen = false;
        this.networkErrorDialogOpen = false;
        this.alreadyUserDialogOpen = false;
        this.duplicateUserDialogOpen = false;
        this.lookAroundUnderstandList = [];
        this.lookAroundPublishDialogOpen = false;
        this.isVideoTrackOff = false;
        this.lookAroundErrorDialogOpen = false;
        this.tempVideoId = "tempVideo";
        this.presentationErrorDialogOpen = false;
        this.IsScreenSharingVideoOff = false;
        this.isOpenBroadcastWebCamSettingDialog = false;
    };

    @action initAttendDialog = () => {
        this.attendDialogStatus = false;
        this.attendDialogMsg = "";
        this.attendDialogMsgError = false;
        this.attendDialogMsgHelperText = "출석코드가 일치하지 않습니다";
    };

    @action initLookAroundUnderstandList = () => {
        this.lookAroundUnderstandList = [];
    }

    @action changeIsScreenSharingVideoOff = (value) => {
        this.IsScreenSharingVideoOff = value;
    }

    @action changePresentationErrorDialogOpen = (value) => {
        this.presentationErrorDialogOpen = value;
    }

    @action changeLookAroundErrorDialogOpen = (value) => {
        this.lookAroundErrorDialogOpen = value;
    }

    @action changeIsVideoTrackOff = (value) => {
        this.isVideoTrackOff = value
    }

    @action changeSelectedLookAroundCamera = (value) => {
        this.selectedLookAroundCamera = value;
    }

    @action changeLookAroundPublishDialogOpen = (value) => {
        this.isLookAroundSoundOn = false;
        this.lookAroundPublishDialogOpen = value;
        if(value) {
            this.selectedLookAroundCamera = null;
            this.getCameraDeviceList();
        }
    }

    @action addLookAroundUnderstandList = (userId, value) => {
        if(this.channel && this.userInfo && this.channel.userId !== this.userInfo.userId && this.userInfo.userId !== userId ) {
            return null;
        }

        let duplicateIndex = this.lookAroundUnderstandList.findIndex((item) => item.userId === userId);
        if(duplicateIndex !== -1) {
            this.lookAroundUnderstandList.splice(duplicateIndex);
        }

        this.lookAroundUnderstandList.push({
            userId: userId,
            value: value
        });
    };

    @action changeDuplicateUserDialogOpen = (value) => {
        this.duplicateUserDialogOpen = value;
    }

    @action changeAlreadyUserDialogOpen = (value) => {
        this.alreadyUserDialogOpen = value;
    }

    @action changeNetworkErrorDialogOpen = (value) => {
        this.networkErrorDialogOpen = value;
    }

    @action addLookAroundRemotePluginHandle = (feed, pluginHandle) => {
        let duplicateIndex = this.lookAroundRemotePluginHandleList.findIndex((item) => item.feed === feed);
        if(duplicateIndex !== -1) {
            this.lookAroundRemotePluginHandleList.splice(duplicateIndex);
        }

        this.lookAroundRemotePluginHandleList.push({
            feed: feed,
            pluginHandle: pluginHandle,
        });
    }

    @action changeScreenOffDialogTarget = (value) => {
        this.screenOffDialogTarget = value;
    }

    @action changeScreenOffDialogOpen = (value) => {
        this.screenOffDialogOpen = value
    }

    @action changeResetDialogOpen = (value) => {
        this.resetDialogOpen = value;
    }

    @action changeAvailableBroadcastSwitch = (value) => {
        this.availableBroadcastSwitch = value;
    }

    @action changeWebRtcCamera = (value) => {
        this.selectedWebRtcCamera = value;
    }

    @action changeSubWebRtcCamera = (value) => {
        this.selectedSubWebRtcCamera = value;
    }

    @action changeWebRtcCameraList = (list) => {
        this.webRtcCameraList = list;
        if(list && list.length > 0) {
            this.selectedWebRtcCamera = list[0].deviceId;
            this.selectedSubWebRtcCamera = list[0].deviceId;
            this.selectedLookAroundCamera = list[0].deviceId;
        }
    };

    @action changeJanusSubFeed = (value) => {
        this.janusSubFeed = value;
    };

    @action changeWebRtcMainPublishType = (value) => {
        this.webRtcMainPublishType = value;
        // if(value === publishMethodType.RTMP) {
        //     this.mainScreenView = true;
        //     this.mainPublishType = publishType.RTMP;
        //     Player.handlePlayStop();
        //     Player.handlePlayStart();
        //     //this.videoElChangeStatus = true;
        // }

        if(value !== publishMethodType.RTMP && this.channel && this.userInfo && this.channel.userId !== this.userInfo.userId) {
            this.mainPublishType = publishMethodType.WEB_RTC;
        }
    }

    @action changeWebRtcSubPublishType = (value) => {
        this.subPublishType = value;
    }

    @action changeSubPluginHandle = (obj) => {
        this.subPluginHandle = obj;
    }

    @action changeSelectedSubPublishType = (value) => {
        this.selectedSubPublishType = value;
    }

    @action changeSelectedMainPublishType = (value) => {
        this.selectedMainPublishType = value;
    }

    @action changeMainPublishDialogOpen = (value) => {
        this.mainPublishDialogOpen = value;
        if(value) {
            this.selectedMainPublishType = null;
            this.selectedWebRtcCamera = null;
            this.getCameraDeviceList();
        }
    };

    @action changeSubPublishDialogOpen = (value) => {
        this.subPublishDialogOpen = value;
        if(value) {
            this.selectedSubPublishType = null;
            this.selectedSubWebRtcCamera = null;
            this.getCameraDeviceList();
        }
    };

    @action changeSubMemberPublishDialogOpen = value => {
        this.subMemberPublishDialogOpen = value;

        if(value) {
            this.selectedSubWebRtcCamera = null;
            //this.selectedSubPublishType = null;
            this.getCameraDeviceList();
        }
    }

    @action changeMyScreenView = (value) => {
        if(!(this.channel && this.userInfo && this.channel.userId === this.userInfo.userId)) {
            return null;
        }

        // if(value) {
        //     Player.handlePlayStop();
        //     Player.handlePlayStart();
        // }

        this.myScreenView = value;
    };

    @action changeMySubScreenView = (value) => {
        if(!(this.channel && this.userInfo && this.channel.userId === this.userInfo.userId)) {
            return null;
        }

        // if(value) {
        //     Player.handlePlayStop();
        //     Player.handlePlayStart();
        // }

        this.mySubScreenView = value;
    };

    @action changeIsFirstPlayCheck = (value) => {
        if(this.isFirstPlayCheck && !value) {
            const mainTarget = document.getElementById(broadcastVideoEl.MAIN);
            const subTarget = document.getElementById(broadcastVideoEl.SUB);

            // if(this.mainVideoEl === broadcastVideoEl.MAIN) {
            //     if(this.mainScreenView) {
            //
            //     }
            // } else {
            //
            // }

            console.log(mainTarget);
            if(mainTarget && mainTarget.play) {
                // mainTarget.muted = false;
                mainTarget.play();
            }

            if(subTarget && subTarget.play) {
                // subTarget.muted = false;
                subTarget.play();
            }

            this.isFirstPlayCheck = value;
            this.videoCheckSound();
        }

        this.isFirstPlayCheck = value;
    }

    @action changeSubVideoElViewChange = (value) => {
        this.subVideoElViewChange = value;
        if(value === subViewChangeType.SHOW) {
            this.tempVideoId = "tempVideo";
            document.getElementById("broadcastDrawer").style.display = '';

            setTimeout(() => {
                if(this.subScreenView && document.getElementById(this.tempVideoId)) {
                    let stream = document.getElementById(this.tempVideoId).srcObject;
                    if(stream) {
                        window.Janus.reattachMediaStream(document.getElementById(broadcastVideoEl.SUB), document.getElementById(this.tempVideoId));
                        document.getElementById(this.tempVideoId).muted = true;
                        //document.getElementById(this.tempVideoId).srcObject = new MediaStream();
                        //setTimeout(() => document.getElementById(this.tempVideoId).srcObject = new MediaStream(), 1000);
                    }
                }
            }, 1000);
        }

        if(value === subViewChangeType.HIDDEN) {
            if(this.subScreenView && document.getElementById(broadcastVideoEl.SUB)) {
                let stream = document.getElementById(broadcastVideoEl.SUB).srcObject;
                if(stream) {
                    window.Janus.reattachMediaStream(document.getElementById(this.tempVideoId), document.getElementById(broadcastVideoEl.SUB));
                    //document.getElementById(broadcastVideoEl.SUB).srcObject = new MediaStream();
                }
            }

            this.tempVideoId = broadcastVideoEl.SUB;
            document.getElementById("broadcastDrawer").style.display = 'none';
        }

        setTimeout(() => this.videoCheckSound(),1000);
    };

    @action changeFullScreenStatus = (value) => this.fullScreenStatus = value;

    @action changeMainScreenView = (value) => {
        this.mainScreenView = value;
        const isWebRtc = this.selectedMainPublishType !== publishType.RTMP;
        if(isWebRtc) {
            if(value) {
                if(this.channel && this.userInfo && this.channel.userId !== this.userInfo.userId) {
                    //setTimeout(() => this.initJanus(janusJoinPType.subscriber, janusPluginHandlerType.main), 1500);
                }

                if(this.channel && this.userInfo && this.channel.userId === this.userInfo.userId) {
                    this.mainPublishType = this.selectedMainPublishType;
                    this.initJanus(janusJoinPType.publisher, janusPluginHandlerType.main, this.channel.channelId);
                }
            } else {
                if(this.channel && this.userInfo && this.channel.userId === this.userInfo.userId) {
                    if(this.pluginHandle) {
                        BroadcastJanus.doUnPublish(this.pluginHandle);
                    } else {
                        const videoEl = document.getElementById(this.mainVideoEl);
                        if(videoEl && videoEl.srcObject) {
                            videoEl.srcObject.getVideoTracks().forEach((item) => {
                                videoEl.srcObject.removeTrack(item);
                            })

                            videoEl.srcObject.getAudioTracks().forEach((item) => {
                                videoEl.srcObject.removeTrack(item);
                            });
                        }
                    }

                    this.mainPublishType = null;
                }

                this.IsScreenSharingVideoOff = false;
            }

            return null;
        }

        if(this.selectedMainPublishType !== null) {
            this.mainPublishType = this.selectedMainPublishType;
        }

        // if(value) {
        //     Player.handlePlayStart();
        // } else {
        //     Player.handlePlayStop();
        // }
    };

    @action changeSubScreenView = (value) => {
        this.subScreenView = value;
        // const isWebRtc = this.publishMethod === publishMethodType.WEB_RTC;

        // if(isWebRtc) {
            if(value) {
                if(this.channel && this.userInfo && this.channel.userId !== this.userInfo.userId) {
                    //setTimeout(() => this.initJanus(janusJoinPType.subscriber, janusPluginHandlerType.sub), 1500);
                }

                if(this.channel && this.userInfo && this.channel.userId === this.userInfo.userId) {
                    this.subPublishType = this.selectedSubPublishType;

                    if(!(this.inPresentation || this.inQuestion) && this.selectedSubPublishType) {
                        this.initJanus(janusJoinPType.publisher, janusPluginHandlerType.sub, this.userInfo.userId);
                    }
                    //BroadcastJanus.publishOwnFeed(this.subPluginHandle, true);
                }
            } else {
                if(this.chatObj && this.inPresentation) {
                    (this.chatPresentationObj) ? Chat.handleDoEndPresentation() : Chat.handleDoResetPresentation();
                }

                if(this.chatObj && this.inQuestion) {
                    if(this.janusSubFeed && this.chatQuestionObjList && this.chatQuestionObjList.length > 0) {
                        let qObj = this.chatQuestionObjList.find(item => item.userId.toString() === this.janusSubFeed.toString());
                        Chat.handleDoStopQuestion(qObj);
                    } else {
                        Chat.handleDoResetPresentation();
                    }
                }

                if(this.subPluginHandle) {
                    BroadcastJanus.doUnPublish(this.subPluginHandle);
                } else {
                    const videoEl = document.getElementById(this.subVideoEl);
                    if(videoEl && videoEl.srcObject) {
                        videoEl.srcObject.getVideoTracks().forEach((item) => {
                            videoEl.srcObject.removeTrack(item);
                        })

                        videoEl.srcObject.getAudioTracks().forEach((item) => {
                            videoEl.srcObject.removeTrack(item);
                        });
                    }
                }

                // if((this.inPresentation || this.inQuestion) && this.channel && this.userInfo && this.channel.userId !== this.userInfo.userId) {
                //     this.initJanus(janusJoinPType.publisher, janusPluginHandlerType.sub, this.userInfo.userId);
                // }

                if(this.channel && this.userInfo && this.channel.userId === this.userInfo.userId) {
                    this.subPublishType = null;
                }

                this.IsScreenSharingVideoOff = false;
            }

            // return null;
        // }

        // if(value) {
        //     Player.handlePlayStart();
        // } else {
        //     Player.handlePlayStop();
        // }
    };

    @action addChatQuestionObj = (obj) => {
        if(obj.statusCode === _CONST_TYPES._PRESENTATION_STATUS.RESET) {
            this.inQuestion = false;
            this.chatQuestionObjList = [];
            this.chatIssueCount = 0;
            return null;
        }

        if(this.chatQuestionObjList) {
            let isDuplicate = false;
            const newList = this.chatQuestionObjList.map((item, index) => {
                if(item.userId === obj.userId) {
                    isDuplicate = true;
                    return obj;
                } else {
                    return item;
                }
            });

            this.chatQuestionObjList = newList;
            if(!isDuplicate) {
                this.chatQuestionObjList.push(obj);
            }
        } else {
            this.chatQuestionObjList.push(obj);
        }

        this.inQuestion = false;
        this.chatIssueCount = 0;
        for(let i = 0; i < this.chatQuestionObjList.length; i++) {
            if(this.chatQuestionObjList[i].statusCode === _CONST_TYPES._PRESENTATION_STATUS.START) {
                this.inQuestion = true;
            }

            if(this.chatQuestionObjList[i].statusCode === _CONST_TYPES._PRESENTATION_STATUS.REQUEST) {
                this.chatIssueCount += 1;
            }
        }

        if(this.chatObj && this.channel && this.userInfo && this.channel.userId === this.userInfo.userId) {
            if(this.inQuestion) {
                this.chatObj.sendTurnOnScreen(broadcastType.SUB);
            }else{
                if(obj.statusCode === _CONST_TYPES._PRESENTATION_STATUS.ALLOW || obj.statusCode === _CONST_TYPES._PRESENTATION_STATUS.RESET || obj.statusCode === _CONST_TYPES._PRESENTATION_STATUS.END) {
                    this.chatObj.sendTurnOffScreen(broadcastType.SUB);
                }
            }
        }
    };

    @action changeChatPresentationObj = (obj) => {
        this.chatPresentationObj = obj;
        this.inPresentation = !((obj.statusCode === _CONST_TYPES._PRESENTATION_STATUS.RESET) || (obj.statusCode === _CONST_TYPES._PRESENTATION_STATUS.END));

        if(obj.statusCode === _CONST_TYPES._PRESENTATION_STATUS.REQUEST) {
            if(this.subScreenView && this.subPluginHandle && this.channel && this.userInfo && this.channel.userId === this.userInfo.userId) {
                BroadcastJanus.doUnPublish(this.subPluginHandle);
            }
        }

        if(this.chatObj && this.channel && this.userInfo && this.channel.userId === this.userInfo.userId) {
            if(this.inPresentation) {
                this.chatObj.sendTurnOnScreen(broadcastType.SUB);
            }else{
                this.chatObj.sendTurnOffScreen(broadcastType.SUB);
            }
        }
    };

    @action changeChatIssueCount = (value) => {
        this.chatIssueCount = value;
    };

    @action changeChannelEndDialogOpen = (value) => {
        this.channelEndDialogOpen = value;
    };

    @action changeChannelExitDialogOpen = (value) => {
        this.channelExitDialogOpen = value
    };

    @action changeCountDownTimer = (timer) => {
        this.countDownTimer = timer;
    };

    @action changeCountDown = (value) => {
        this.countDown = value;
    };

    @action changeMainWsUrl = (value) => {
        this.channelWsMainURL = value
    };

    @action changePlayBtnMouseOver = (value) => {
        this.playBtnMouseOver = value;
    };

    @action changePlayBtnView = (value) => {
        this.playBtnView = value;
    };

    @action changeMainVideoElSound = (value) => {
        // if(this.nodePlayer) {
        //     this.nodePlayer.setVolume(value ? 1.0 : 0.0);
        // }

        if(this.pluginHandle && this.mainScreenView && this.mainPublishType !== publishType.RTMP) {
            // BroadcastJanus.publishOwnFeed(this.pluginHandle, value, true, this.mainPublishType, BroadcastJanus.janusPluginHandlerType.main, this.channel.channelId);
            if(this.pluginHandle) {
                const publish = { request: "configure", audio: value, video: true };
                this.pluginHandle.send({
                    message: publish,
                    error: (err) => console.log(err)
                });
            }
        }
        this.mainVideoElSound = value;
    };

    @action changeSubVideoElSound = (value) => {
        if(this.secondNodePlayer) {
            this.secondNodePlayer.setVolume(value ? 1.0 : 0.0);
        }

        if(this.subScreenView && this.subPublishType !== publishType.RTMP) {
            // BroadcastJanus.publishOwnFeed(this.subPluginHandle, value, true, this.subPublishType, BroadcastJanus.janusPluginHandlerType.sub, this.janusSubFeed);
            if(this.subPluginHandle) {
                const publish = { request: "configure", audio: value, video: true };
                this.subPluginHandle.send({message: publish});
            }
        }
        this.subVideoElSound = value;
    };

    @action changeVideoElStatus = value => this.videoElChangeStatus = value;
    @action changeQuestionReady = value => this.isQuestionReady = value;
    @action changeTeacherLookAroundOpen = value => {
        this.isTeacherLookAroundOpen = value;
        this.lookAroundUnderstandList = [];
        this.lookAroundRemotePluginHandleList = [];
    };

    @action changeStudentLookAroundOpen = value => {
        // this.isStudentLookAroundOpen = value;
        this.isTeacherLookAroundOpen = value;
        this.lookAroundUnderstandList = [];
        this.lookAroundRemotePluginHandleList = [];
        if(value) {
            this.initLookAroundJanus(BroadcastLookAroundJanus.janusJoinPType.publisher, this.userInfo.userId, "lookAround-" + this.userInfo.userId);
            // window.open(`/lookaround/student/${this.channel.channelId}`);
        }
    };

    @action lookAroundMutedControl = isON => {
        this.lookAroundRemotePluginHandleList.forEach(data => {
            const lookAroundFeed = document.getElementById(`lookAround-${data.feed}`);
            if(lookAroundFeed) {
                lookAroundFeed.muted = isON;
            }
        });
        this.isLookAroundSoundOn = !isON;
    }

    @action changeMainVideoEl = (changeType) => {
        // const isWebRtc = this.publishMethod === publishMethodType.WEB_RTC;
        // const isWebRtc = this.mainPublishType !== publishType.RTMP;
        // let presentationUserId = null;
        // if(this.inPresentation) {
        //      presentationUserId = this.chatPresentationObj.userId;
        // } else if (this.inQuestion) {
        //     this.chatQuestionObjList.forEach((qObj) => {
        //         if(qObj.userId === this.userInfo.userId) presentationUserId = qObj.userId;
        //     });
        // }

        if(changeType === broadcastType.SUB) {
            this.mainVideoEl = broadcastVideoEl.SUB;
            this.mainVideoOffEl = videoOffLayer.SUB;
            //this.mainVideoElSound = false;
            this.subVideoEl = broadcastVideoEl.MAIN;
            this.subVideoOffEl = videoOffLayer.MAIN
            //this.subVideoElSound = true;
        } else if(changeType === broadcastType.MAIN) {
            this.mainVideoEl = broadcastVideoEl.MAIN;
            this.mainVideoOffEl = videoOffLayer.MAIN;
            //this.mainVideoElSound = true;
            this.subVideoEl = broadcastVideoEl.SUB;
            this.subVideoOffEl = videoOffLayer.SUB;
            //this.subVideoElSound = false;
        }

        document.getElementById(this.subVideoEl).muted = true;
        document.getElementById(this.mainVideoEl).muted = true;
        document.getElementById("tempVideoSwitch").muted = true;

        const oneVideoChange = (prevVideoEl, currVideoEl) => {
            window.Janus.reattachMediaStream(document.getElementById("tempVideoSwitch"), document.getElementById(prevVideoEl));
            document.getElementById(prevVideoEl).srcObject = new MediaStream();
            window.Janus.reattachMediaStream(document.getElementById(currVideoEl), document.getElementById("tempVideoSwitch"));
            document.getElementById("tempVideoSwitch").srcObject = new MediaStream();
            setTimeout(() => this.videoCheckSound(), 1000);
        }

        if(this.mainScreenView && this.subScreenView && document.getElementById(this.mainVideoEl).srcObject) {
            window.Janus.reattachMediaStream(document.getElementById("tempVideoSwitch"), document.getElementById(this.mainVideoEl));
            //window.Janus.reattachMediaStream(document.getElementById(this.subVideoEl), document.getElementById("tempVideoSwitch"));
            document.getElementById(this.mainVideoEl).srcObject = new MediaStream();
            if(document.getElementById(this.subVideoEl).srcObject) {
                window.Janus.reattachMediaStream(document.getElementById(this.mainVideoEl), document.getElementById(this.subVideoEl));
                document.getElementById(this.subVideoEl).srcObject = new MediaStream();
            }

            window.Janus.reattachMediaStream(document.getElementById(this.subVideoEl), document.getElementById("tempVideoSwitch"));
            document.getElementById("tempVideoSwitch").srcObject = new MediaStream();

            setTimeout(() => this.videoCheckSound(), 1000);
        }

        if(!(this.mainScreenView && this.subScreenView)) {
            if(this.mainScreenView && document.getElementById(this.subVideoEl).srcObject) {
                oneVideoChange(this.subVideoEl, this.mainVideoEl);
            } else if(this.subScreenView && document.getElementById(this.mainVideoEl).srcObject) {
                oneVideoChange(this.mainVideoEl, this.subVideoEl);
            }
        }
    };

    @action changeAttendDialogMsg = (value) => {
        this.attendDialogMsg = value;
    };

    @action changeAttendDialogStatus = (value) => {
        this.attendDialogStatus = value;
    };

    @action changeAttendDialogMsgError = (value) => {
        this.attendDialogMsgError = value;
    };

    @action changeData = (value) => {
        this.channel = value;
    };

    @action changeChatReady = (value) => {
        this.chatReady = value;
    };

    @action changeChatMsg = (value) => {
        this.chatMsg = value;
    };

    @action changeChatUserList = (data) => {
        let userIdList = [];
        let filterUserList = [];
        let subFilterUserList = [];
        if(data && data.length > 0) {
             filterUserList = data.filter((item) => {
                let userIdIndex = userIdList.indexOf(item.userId);
                if(userIdIndex === -1 && (item.presentationAvailableFlag === Chat.presentationAvailableFlag.Y || item.questionAvailableFlag === Chat.questionAvailableFlag.Y)) {
                    userIdList.push(item.userId);
                    return true;
                } else {
                    return false;
                }
             });

            subFilterUserList = data.filter((item) => {
                let userIdIndex = userIdList.indexOf(item.userId);
                if(userIdIndex === -1) {
                    userIdList.push(item.userId);
                    return true;
                } else {
                    return false;
                }
            });

            let totalUserList = filterUserList.concat(subFilterUserList);
            totalUserList.sort((a, b) => {
                if(this.channel && this.channel.userId === a.userId) {
                    return -1;
                }

                if(this.userInfo && this.channel && this.userInfo.userId === a.userId && this.channel.userId !== b.userId) {
                    return -1;
                }

                return 1;
            });

            this.chatUserList = totalUserList;
        }

        //presentationAvailableFlag
        //questionAvailableFlag
    };

    @action changeTabSelect = (value) => {
        this.chatTabSelected = value;
    };

    @action changePlayStatus = (value) => {
        this.playStatus = value;
    };

    @action changeSubPlayStatus = (value) => {
        this.subPlayStatus = value;
    };

    @action changeNodePlayer = (obj) => {
        this.nodePlayer = obj
    };

    @action changeSecondNodePlayer = (value) => {
        this.secondNodePlayer = value
    };

    @action changeChatObj = (obj) => {
        this.chatObj = obj
    };

    @action changeAttendMsg = (data) => {
        this.attendMsg = data;

        if(data && this.channel && this.userInfo && this.channel.userId !== this.userInfo.userId) {
            this.initAttendDialog();
            this.attendDialogStatus = true;
        }
    };

    @action changeMainWidth = (value) => {
        this.mainWidth = value;
    };

    @action changeChannelEnded = (value) => {
        this.channelEnded = value;
    };

    @action changePluginHandle = (obj) => {
        this.pluginHandle = obj;
    }

    @action changeLookAroundPluginHandle = (obj) => this.lookAroundPluginHandle = obj;


    @action changeJanusObj = (obj) => {
        this.janusObj = obj
    }

    @action changeLookAroundJanusObj = (obj) => {
        this.lookAroundJanusObj = obj
    }

    @action changeDisablePresentation = value => this.isDisablePresentation = value;

    @action changeAttendRequestDialogOpen = value => this.isAttendRequestDialogOpen = value;

    @action changeBroadcastWebCamSettingDialog = value =>  this.isOpenBroadcastWebCamSettingDialog = value;

    @action addChatMsgList = (sentUser, msg, msgType) => {
        if(this.chatMsgList.length > 1000) {
            const delLength = this.chatMsgList.length - 1000;
            for(let i = 0; i < delLength; i++) {
                this.chatMsgList.splice(i, 1);
            }
        }

        this.chatMsgList.push({
            sentUser: sentUser,
            msg: msg,
            msgType: msgType
        });
    };

    @action getScreenEl = (videoEl) => {
        if(this.mainVideoEl === videoEl) {
            return this.mainVideoEl;
        }

        if(this.subVideoEl === videoEl) {
            return this.subVideoEl;
        }

        return null;
    }

    @action handleWebRtcFullScreen = () => {
        const target = document.getElementById(this.getScreenEl(broadcastVideoEl.MAIN));
        // const deviceBrowserType = getDeviceBrowserType();
        // console.log("deviceBrowserType", deviceBrowserType);
        // deviceBrowserType.browserType === BroadcastJanus.browserType.safari
        //     ? target.webkitRequestFullScreen()
        //     : deviceBrowserType.browserType === BroadcastJanus.browserType.edge
        //         ? target.msRequestFullscreen()
        //         : target.requestFullscreen( { navigationUI: "hide" });

        if (target.requestFullscreen) {
            target.requestFullscreen({ navigationUI: "hide" });
        } else if (target.mozRequestFullScreen) { /* Firefox */
            target.mozRequestFullScreen();
        } else if (target.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            target.webkitRequestFullscreen();
        } else if (target.msRequestFullscreen) { /* Edge */
            target.msRequestFullscreen();
        }
    }

    @action getCameraDeviceList = () => {
        //this.selectedWebRtcCamera = null;
        BroadcastJanus.getCameraDeviceList(this.changeWebRtcCameraList);
        //getCameraDeviceList
    };

    @action initJanus = (janusJoinType, handlerType, feed) => {
        BroadcastJanus.initJanus({
            isDebug: false,
            // serverAddr: "https://bs010.onthe.live:8089/janus",
            // serverAddr: "wss://bs010.onthe.live:8989/janus",
            serverAddr: this.channelWsMainURL,
            channelId: this.channel.channelId,
            channelOwnerUserId: this.channel.userId,
            userId: this.userInfo.userId,
            userName: this.userInfo.userName,
            recordFlag: this.userInfo.recordFlag,
            type: janusJoinType,
            handlerType: handlerType,
            broadcastVideoElType: broadcastVideoEl,
            changePluginHandle: this.changePluginHandle,
            changeJanusObj: this.changeJanusObj,
            getMainVideoEl: () => this.mainVideoEl,
            getSubVideoEl: () => this.subVideoEl,
            getVideoElChangeStatus: () => this.videoElChangeStatus,
            getMainPublishType: () => this.mainPublishType,
            getSubPublishType: () => this.subPublishType,
            handleMainSound: Chat.handleMainSound,
            handleSubSound: Chat.handleSubSound,
            changeVideoElStatus: this.changeVideoElStatus,
            changeSubPluginHandle: this.changeSubPluginHandle,
            changeJanusSubFeed: this.changeJanusSubFeed,
            feed: feed,
            deviceId: this.selectedWebRtcCamera,
            subDeviceId: this.selectedSubWebRtcCamera,
            audioDeviceId: null,
            handleWebRtcPublisherRoomJoined: (obj) => Chat.handleWebRtcPublisherRoomJoined(obj),
            chatObj: this.chatObj,
            presentObj: this.inPresentation ? this.chatPresentationObj : this.isQuestionReady ? this.chatQuestionObjList.find(obj => {
                return obj.userId === feed;
            }) : null,
            changeIsVideoTrackOff: this.changeIsVideoTrackOff,
            videoCheckSound: this.videoCheckSound,
            screenSharingVideoOffHandle : this.screenSharingVideoOffHandle,
        });
    }

    @action initLookAroundJanus = (janusJoinType, feed, videoElId) => {
        //console.log('initJanus');
        BroadcastLookAroundJanus.initJanus({
            serverAddr: this.channelWsMainURL,
            channelId: this.channel.channelId,
            channelOwnerUserId: this.channel.userId,
            userId: this.userInfo.userId,
            userName: this.userInfo.userName,
            recordFlag: this.userInfo.recordFlag,
            type: janusJoinType,
            changePluginHandle: this.changeLookAroundPluginHandle,
            changeJanusObj: this.changeLookAroundJanusObj,
            videoElId: videoElId,
            feed: feed,
            handleWebRtcPublisherRoomJoined: (obj) => Chat.handleWebRtcPublisherRoomJoined(obj),
            chatObj: this.chatObj,
            lookAroundRemotePluginHandleList: this.lookAroundRemotePluginHandleList,
            addLookAroundRemotePluginHandle: this.addLookAroundRemotePluginHandle,
            maxPublishers: this.channel ? this.channel.channelMemberList.length + 2 : 100,
            cameraDeviceId: this.selectedLookAroundCamera,
        });
    }

    @action initLookAroundJanusPublisher = () => {
        this.initLookAroundJanus(BroadcastLookAroundJanus.janusJoinPType.publisher, this.userInfo.userId, "lookAround-" + this.userInfo.userId);
    };

    @action removeVideoTrack = () => {
        const mainEl = document.getElementById(broadcastVideoEl.MAIN);
        const subEl = document.getElementById(broadcastVideoEl.SUB);

        if(mainEl && mainEl.srcObject) {
            mainEl.srcObject.getTracks().forEach((item) => item.stop());
        }

        if(subEl && subEl.srcObject) {
            subEl.srcObject.getTracks().forEach((item) => item.stop());
        }
    }

    @action janusRoomDestroy = () => {
        if(this.pluginHandle) {
            BroadcastJanus.doUnPublish(this.pluginHandle);
        }

        if(this.subPluginHandle) {
            BroadcastJanus.doUnPublish(this.subPluginHandle);
        }

        this.removeVideoTrack();

        setTimeout(() => {
            if(this.pluginHandle) {
                BroadcastJanus.roomDestroy(this.pluginHandle);
            } else if(this.subPluginHandle) {
                BroadcastJanus.roomDestroy(this.subPluginHandle);
            }

            if(this.lookAroundPluginHandle) {
                BroadcastLookAroundJanus.roomDestroy(this.lookAroundPluginHandle);
            }

            if(this.janusObj) {
                this.janusObj.destroy();
            }

            if(this.lookAroundJanusObj) {
                this.lookAroundJanusObj.destroy();
            }

            this.initData();
        }, 1000);
    }

    @action janusDoUnPublish = () => {
        if(this.pluginHandle) {
            BroadcastJanus.doUnPublish(this.pluginHandle);
        }

        if(this.subPluginHandle) {
            BroadcastJanus.doUnPublish(this.subPluginHandle);
        }

        this.removeVideoTrack();

        if(this.janusObj) {
            this.janusObj.destroy();
        }
    }

    @action lookAroundJanusUnPublish = () => {
        if(this.lookAroundPluginHandle) {
            BroadcastLookAroundJanus.doUnPublish(this.lookAroundPluginHandle);
        }

        if(this.lookAroundJanusObj) {
            this.lookAroundJanusObj.destroy();
        }
    }

    @action chatStartWithPushOut = () => {
        if(this.chatObj) {
            this.chatObj.startWithPushOut();
        }
    }

    @action chatSendControlLookAroundUnderstand = (value) => {
        Chat.sendControlLookAroundUnderstand(value, this.userInfo.userId);
    }

    @action videoCheckSound = () => {
        if(this.isFirstPlayCheck) {
            return null;
        }

        if(this.mainScreenView) {
            document.getElementById(this.mainVideoEl).muted = !!(this.channel && this.userInfo && this.channel.userId === this.userInfo.userId);
        }

        if(this.subScreenView) {
            document.getElementById(this.subVideoEl).muted = !!(this.userInfo && this.janusSubFeed && this.janusSubFeed.toString() === this.userInfo.userId.toString());
        }
    }

    @action chatDoAnnounce = (userId) => {
        Chat.handleDoAnnounce(userId);
    }

    @action screenSharingVideoOffHandle = (value, videoElType) => {
        this.IsScreenSharingVideoOff = value;

        if(value) {
            if(this.channel.userId === this.userInfo.userId) {
                if(BroadcastJanus.janusPluginHandlerType.main === videoElType && this.mainScreenView) {
                    Chat.handleMainScreenView();
                }

                if(BroadcastJanus.janusPluginHandlerType.sub === videoElType && this.subScreenView) {
                    Chat.handleSubScreenView();
                }
            } else if (this.inPresentation) {
                (this.chatPresentationObj) ? Chat.handleDoEndPresentation() : Chat.handleDoResetPresentation();
            } else if (this.inQuestion && this.janusSubFeed && this.chatQuestionObjList && this.chatQuestionObjList.length > 0) {
                let qObj = this.chatQuestionObjList.find(item => item.userId.toString() === this.janusSubFeed.toString());
                Chat.handleDoStopQuestion(qObj);
            }
        }
    }

    @computed
    get deviceBrowserType() {
        return getDeviceBrowserType();
    }

    exitChannel = flow(function* (channel, userId) {
        try {
            if(channel && channel.userId === userId) {
                const putData = {
                    channelId: channel.channelId,
                    statusCode: channelStatusType.CLOSED,
                };

                yield axios.put("/api/v1/channels/status", putData);
                this.channelEnded = true;
            }
        } catch (e) {
            console.log('error');
            console.log(e);
        }
    });

    getUser = flow(function* (userId) {
        try{
            const response = yield axios.get(`/api/v1/users/user-info`, {params: {"user-id": userId}});
            this.userInfo = response.data;
        } catch (e) {
            console.log('get user error');
            console.log(e);
        }
    });

    getChannel = flow(function* (channelId, userId, loginToken) {
        this.loading = true;
        this.userId = userId;

        try {
            const response = yield axios.get(`/api/v1/channels/channel-info`, {params: {"channel-id": channelId}});

            if(response.status !== 200) {
                new Error("get channel fail");
            }

            if(response.data.statusCode === channelStatusType.WAIT && response.data.userId === userId) {
                const putData = {
                    channelId: response.data.channelId,
                    statusCode: channelStatusType.OPENED,
                };

                yield axios.put("/api/v1/channels/status", putData);
            }

            if(response.data) {
                response.data.channelBroadcastEndpointList.forEach((item) => {
                    if(item.typeCode === broadcastType.MAIN) {
                        this.channelWsMainURL = item.playbackEndpoint + "?sign=" + loginToken;
                    } else if(item.typeCode === broadcastType.SUB) {
                        this.channelWsSubURL = item.playbackEndpoint + "?sign=" + loginToken;
                    }
                });
            }

            this.channel = response.data;
            this.loading = false;
        } catch (e) {
            console.log('error');
            console.log(e);
            this.loading = false;
        }
    });

    getBarCodesInfo = flow(function* () {
        this.chatServerInfo = null;
        try {
            const response = yield axios.get(`/api/v1/barcodes/json`);
            this.chatServerInfo = response.data;
        } catch (e) {
            console.log('error');
            console.log(e);
        }
    });
}